import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Dashboard from "../../../components/Dashboard";
import MainHeader from "../../../components/MainHeader";
import { BsFillPersonLinesFill } from "react-icons/bs";
import { HiOutlineClipboardCheck } from "react-icons/hi";
import { MdCurrencyYen } from "react-icons/md";
import { MdOutlineFileUpload } from "react-icons/md";
import { AiFillBank } from "react-icons/ai";
import { BsFillJournalBookmarkFill } from "react-icons/bs";

export default function BamScreen() {
  const [subMenus, setSubMenus] = useState([]);
  const [moduleAssignments, setModuleAssignments] = useState([]);
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {

    // Fetch user ID from session storage
    const fetchUserId = () => {
      const userDetailString = sessionStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        setUserId(userDetail.id || null);
      }
    };

    fetchUserId();

    // Fetch sub-menu data
    axios.get(`${process.env.REACT_APP_BASE_URL}/get-sub-menu`)
      .then((response) => {
        const filteredMenus = response.data.data.filter(menu => menu.mainMenuId === 3);
        setSubMenus(filteredMenus);
      })
      .catch((error) => console.error("Error fetching sub-menus:", error));

    // Fetch module assignment data
    axios.get(`${process.env.REACT_APP_BASE_URL}/get-module-assignment`)
      .then((response) => {
        // Filter module assignments by userId
        const filteredAssignments = response.data.filter(
          (assignment) =>
            assignment.userId === String(userId) && assignment.isChecked === true
        );
        setModuleAssignments(filteredAssignments);
      })
      .catch((error) => console.error("Error fetching module assignments:", error));
  }, [userId]);

  const handleCardClick = (route) => {
    navigate(route);
  };

  // Filter subMenus based on moduleAssignments
  const filteredSections = subMenus.filter((menu) => {
    return moduleAssignments.some(
      (assignment) => assignment.subMenuId === menu.id && assignment.isChecked
    );
  });

  // Define an order for submenus to control their display order
  const submenuOrder = ["Set up", "Bank Master Data"];

  // Sort the filteredSections by the defined submenuOrder
  const orderedSections = filteredSections.sort((a, b) => {
    return submenuOrder.indexOf(a.name) - submenuOrder.indexOf(b.name);
  });

  const sections = orderedSections.map((menu) => {
    let cards;

    if (menu.name === "Set up") {
      cards = [
        { title: "Account Status", icon: <BsFillPersonLinesFill />, onClick: () => handleCardClick("/account-status") },
        { title: "Account Number Logic", icon: <HiOutlineClipboardCheck />, onClick: () => handleCardClick("/account-number-logic") },
        { title: "ZBA Types", icon: <MdCurrencyYen />, onClick: () => handleCardClick("/ZBA-types") },
        { title: "DB Area", icon: <MdOutlineFileUpload />, onClick: () => handleCardClick("/DB-area") },
        { title: "Account Types", icon: <AiFillBank />, onClick: () => handleCardClick("/account-types") },
      ];
    } else if (menu.name === "Bank Master Data") {
      cards = [
        { title: "Bank Account Master", icon: <BsFillJournalBookmarkFill />, onClick: () => handleCardClick("/account-master") },
      ];
    } else {
      // Optionally handle other cases or default cards
      cards = [];
    }

    return {
      title: menu.name,
      subMenuId: menu.id,
      cards: cards,
    };
  });

  return (
    <div>
      <MainHeader title={"BAM"} />
      <Dashboard sections={sections} />
    </div>
  );
}
