import React from 'react';
import { IoIosFlag } from "react-icons/io";

export default function MappingRankIDTable({ selectedRows, setSelectedRows }) {
  const handleCheckboxChange = (index) => {
    if (selectedRows.includes(index)) {
      setSelectedRows(selectedRows.filter(row => row !== index));
    } else {
      setSelectedRows([...selectedRows, index]);
    }
  };

  return (
    <div className='p-4'>
      <div className="overflow-x-auto max-w-full">
        <table className="table-fixed min-w-[800px] bg-white">
          <thead className="bg-sky-500 text-primary-foreground text-sm">
            <tr>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium"></th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">SL No</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Company Code</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Bank ID</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Account ID</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Bank Mapping Rule id</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Active</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Lock</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">B/S Format Type</th>
            </tr>
          </thead>
          <tbody className='text-sm'>
            {Array(50).fill().map((_, index) => (
              <tr key={index} className="border-b" style={{ backgroundColor: index % 2 === 0 ? 'white' : '#f3f4f6' }}>
                <td className="py-2 px-4">
                  <input
                    type="checkbox"
                    className="form-checkbox"
                    checked={selectedRows.includes(index)}
                    onChange={() => handleCheckboxChange(index)}
                  />
                </td>
                <td className="py-2 px-4">1</td>
                <td className="py-2 px-4">TAT0014</td>
                <td className="py-2 px-4">HDFC000234</td>
                <td className="py-2 px-4">HDFC0229828</td>
                <td className="py-2 px-4">Bank Mapping Rule ID+</td>
                <td className="py-2 px-4">
                  <IoIosFlag color='green' />
                </td>
                <td className="py-2 px-4">
                  <input type="checkbox" className="form-checkbox" />
                </td>
                <td className="py-2 px-4">MT940</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
