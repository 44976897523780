// buttonConfigs.js
import {  IoAddCircleOutline, IoSettingsOutline } from 'react-icons/io5';
import { GiSettingsKnobs } from 'react-icons/gi';
import { MdOutlineFileDownload} from 'react-icons/md';
import { IoMdRefresh } from "react-icons/io";
import { CiSearch } from 'react-icons/ci';
import { GiTwirlyFlower } from "react-icons/gi";

export const CashFlowbuttonsConfigs = {
  screen1: [
    { icon: IoAddCircleOutline, onClick: () => {}, rotate: true },

    { icon: GiSettingsKnobs, onClick: () => console.log('Settings Knobs'), rotate: true },
    
    { icon: IoSettingsOutline, onClick: () => console.log('Settings') },
    { icon: MdOutlineFileDownload, onClick: () => console.log('Download') },
    { icon: IoMdRefresh , onClick: () => console.log('Settings Knobs'), rotate: true },
  ],
  screen2: [
    
    { icon: CiSearch, onClick: () => {}, rotate: true },
    { icon: GiSettingsKnobs, onClick: () => console.log('Settings Knobs'), rotate: true },
    
    { icon: IoSettingsOutline, onClick: () => console.log('Settings') },
    { icon: MdOutlineFileDownload, onClick: () => console.log('Download') },
    { icon: IoMdRefresh , onClick: () => console.log('Settings Knobs'), rotate: true },
  ],
  screen3: [
    { icon: IoAddCircleOutline, onClick: () => {}, rotate: true },

    { icon: GiSettingsKnobs, onClick: () => console.log('Settings Knobs'), rotate: true },
    
    { icon: IoSettingsOutline, onClick: () => console.log('Settings') },
    { icon: MdOutlineFileDownload, onClick: () => console.log('Download') },
    { icon: IoMdRefresh , onClick: () => console.log('Settings Knobs'), rotate: true },
    { icon: GiTwirlyFlower , onClick: () => {} },
  ],
  // Add configurations for other screens
};

