// components/Header.js
import React from 'react';


const MainHeader = ({ title }) => {
  

  return (
    <header className="bg-white text-primary-foreground p-4 ml-2 flex items-center space-x-2 shadow-md  shadow-blue-200 mb-2">
    
      <h4 className="text-xl  text-customBlue  font-semibold" >
        {title}
      </h4>
    </header>
  );
};

export default MainHeader;
