import RestService from "./RestServices";

const LanguageServices = {
  getAllLanguages: async (path) => {
    try {
      const response = await RestService.GetAllData(path);
      return response;
    } catch (error) {
      console.error('Error fetching Languages data:', error);
      return { error: true, message: "Error fetching Languages data" };
    }
  },
  getAllCountrywithPage: (pageSize, page, searchQuery = "") => {
    const searchParam = searchQuery ? `&searchQuery=${encodeURIComponent(searchQuery)}` : "";
    return RestService.GetAllData(
      `/get-language-page?limit=${pageSize}&offset=${(page - 1) * pageSize}${searchParam}`
    );
  },

  getLanguageById: (id) => {
    return RestService.GetByIdData("/get-language-id", id);
  },
  deleteLanguage: (id) => {
    return RestService.DeleteData("/delete-language", id);
  },
  addLanguage: async (path, data) => {
    try {
      console.log('Sending data:', data);
      const response = await RestService.CreateData(path, data);
      console.log('Response from Server:', response);
      return response;
    } catch (error) {
      console.error('Error adding Languages:', error);
      return { error: true, message: "Error adding Languages" };
    }
  },
  checkDuplicateLanguagecode: async (path, roleData) => {
    try {
      const response = await RestService.CreateData(path, roleData);
      console.log("Response from server:", response);

      return response;
    } catch (error) {
      console.error("Error fetching Langauge code:", error);
      return { error: true, message: "Error fetching Language code" };
    }
  },
};



export default LanguageServices;
