import React from 'react';

export default function AccountPortalTable() {
  return (
    <div className="p-4">
      <div className="relative overflow-auto" style={{ maxHeight: '400px', maxWidth: '100%' }}>
        <table className="min-w-full bg-white">
          <thead className="bg-sky-500 text-primary-foreground text-left text-sm font-medium sticky top-0">
            <tr className="bg-primary">
              <th className="py-2 px-4 text-center text-white text-left font-medium">Transactions</th>
              <th className="py-2 px-4 text-center text-white text-left font-medium">Reference</th>
              <th className="py-2 px-4 text-center text-white text-left font-medium">Description</th>
              <th className="py-2 px-4 text-center text-white text-left font-medium">Currency</th>
              <th className="py-2 px-4 text-center text-white text-left font-medium">Debit</th>
              <th className="py-2 px-4 text-center text-white text-left font-medium">Credit</th>
              <th className="py-2 px-4 text-center text-white text-left font-medium">Cumulative Balance</th>
              <th className="py-2 px-4 text-center text-white text-left font-medium">Nt Code</th>
              <th className="py-2 px-4 text-center text-white text-left font-medium">Nt Code Description</th>
              
              
            </tr>
          </thead>
          <tbody className="text-sm">
            {Array(50).fill().map((_, index) => (
              <tr key={index} className="border-b" style={{ backgroundColor: index % 2 === 0 ? 'white' : '#f3f4f6' }}>
                <td className="py-2 px-4 text-center">Transactions</td>
                <td className="py-2 px-4 text-center">Reference</td>
                <td className="py-2 px-4 text-center">Description</td>
                <td className="py-2 px-4 text-center">Currency</td>
                <td className="py-2 px-4 text-center">Debit</td>
                <td className="py-2 px-4 text-center">Credit</td>
                <td className="py-2 px-4 text-center">Cumulative Balance</td>
                <td className="py-2 px-4 text-center">Nt Code</td>
                <td className="py-2 px-4 text-center">Nt Code Description</td>
                
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}


// import React from 'react';

// export default function AccountPortalTable() {
//   return (
//     <div className="p-4">
//       <div className="relative overflow-auto" style={{ maxHeight: '400px', maxWidth: '100%' }}>
//         <table className="w-auto bg-white">
//           <thead className="bg-sky-500 text-primary-foreground text-left text-sm font-medium sticky top-0">
//             <tr className="bg-primary">
//               <th className="py-2 px-4 text-center text-white font-medium">Transactions</th>
//               <th className="py-2 px-4 text-center text-white font-medium">Reference</th>
//               <th className="py-2 px-4 text-center text-white font-medium">Description</th>
//               <th className="py-2 px-4 text-center text-white font-medium">Currency</th>
//               {/* Reduced to 4 headers */}
//             </tr>
//           </thead>
//           <tbody className="text-sm">
//             {Array(50).fill().map((_, index) => (
//               <tr key={index} className="border-b" style={{ backgroundColor: index % 2 === 0 ? 'white' : '#f3f4f6' }}>
//                 <td className="py-2 px-4 text-center">Transactions</td>
//                 <td className="py-2 px-4 text-center">Reference</td>
//                 <td className="py-2 px-4 text-center">Description</td>
//                 <td className="py-2 px-4 text-center">Currency</td>
//                 {/* Reduced to 4 columns */}
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// }


// import React, { useEffect, useState } from 'react';

// export default function AccountPortalTable() {
//   const [tableData, setTableData] = useState([]);

//   // Fetch data from the backend when the component mounts
//   useEffect(() => {
//     const fetchTransactions = async () => {
//       try {
//         const response = await fetch('http://localhost:3000/api/transactions'); // Adjust the URL if necessary
//         const data = await response.json();
//         setTableData(data); // Set the state with fetched data
//       } catch (error) {
//         console.error('Error fetching transactions:', error);
//       }
//     };

//     fetchTransactions();
//   }, []); // Empty dependency array means this runs once when the component mounts

//   return (
//     <div className="p-4">
//       <div className="relative overflow-auto" style={{ maxHeight: '400px', maxWidth: '100%' }}>
//         <table className="min-w-full bg-white">
//           <thead className="bg-sky-500 text-primary-foreground text-left text-sm font-medium sticky top-0">
//             <tr className="bg-primary">
//               <th className="py-2 px-4 text-center text-white font-medium">Transactions</th>
//               <th className="py-2 px-4 text-center text-white font-medium">Reference</th>
//               <th className="py-2 px-4 text-center text-white font-medium">Description</th>
//               <th className="py-2 px-4 text-center text-white font-medium">Currency</th>
//               <th className="py-2 px-4 text-center text-white font-medium">Debit</th>
//               <th className="py-2 px-4 text-center text-white font-medium">Credit</th>
//               <th className="py-2 px-4 text-center text-white font-medium">Cumulative Balance</th>
//               <th className="py-2 px-4 text-center text-white font-medium">Nt Code</th>
//               <th className="py-2 px-4 text-center text-white font-medium">Nt Code Description</th>
//               {/* Add more headers as needed */}
//             </tr>
//           </thead>
//           <tbody className="text-sm">
//             {tableData.map((transaction, index) => (
//               <tr key={index} className="border-b" style={{ backgroundColor: index % 2 === 0 ? 'white' : '#f3f4f6' }}>
//                 <td className="py-2 px-4 text-center">{transaction.transaction}</td>
//                 <td className="py-2 px-4 text-center">{transaction.reference}</td>
//                 <td className="py-2 px-4 text-center">{transaction.description}</td>
//                 <td className="py-2 px-4 text-center">{transaction.currency}</td>
//                 <td className="py-2 px-4 text-center">{transaction.debit}</td>
//                 <td className="py-2 px-4 text-center">{transaction.credit}</td>
//                 <td className="py-2 px-4 text-center">{transaction.cumulativeBalance}</td>
//                 <td className="py-2 px-4 text-center">{transaction.ntCode}</td>
//                 <td className="py-2 px-4 text-center">{transaction.ntCodeDescription}</td>
//                 {/* Add more cells as needed */}
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// }
