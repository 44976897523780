import React from 'react'

import NoActionLayout from '../../../../components/NoActionLayout'
// import Actions from '../../../../components/Actions'
import BankWiseTable from '../Tables/CashInBank/BankWiseTablee'
// import Layout from '../../../../components/layout'
import { Backdrop } from '@mui/material'
import CashBalanceActions from '../Props/CashBalanceActions'
import { buttonConfigs } from '../Props/buttonConfig';


export default function BankWise() {
  const buttons=buttonConfigs.screen1;

  return (
    <div>
          <div>
      <NoActionLayout title="Cash Balance > Cash in Bank > bank Wise" />
</div>
<div>
<CashBalanceActions buttons={buttons}/>
</div>
<Backdrop/>
    
    <BankWiseTable/>
    </div>
  )
}
