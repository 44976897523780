import React from 'react'
import BSYettotable from '../Tables/ExceptionalTables/BSYettotable'

import NoActionLayout from '../../../../components/NoActionLayout'
import CashBalanceActions from '../Props/CashBalanceActions'
import { buttonConfigs } from '../Props/buttonConfig';

export default function Bsyetto() {
  const buttons=buttonConfigs.screen1;

  return (
    <div>
       <div>
      <NoActionLayout title="Cash Balance > Exceptions > BS-Yet to" />
</div>
<div>
<CashBalanceActions buttons={buttons}/>

</div>
<BSYettotable/>
    </div>
  )
}

