import React, { useEffect, useState } from 'react';
import FlowCodeServices from '../../../../../rest-services/FlowCodeServices';

export default function FlowCodeHeadsTable() {
  const [FlowCodeHeads, setFlowCodeheads] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await FlowCodeServices.getAllFlowCode(`${process.env.REACT_APP_BASE_URL}/get-flowcode`);
      setFlowCodeheads(response.data); // Access the 'data' property here
      console.log(response.data, 'the data');
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className='p-2'>
      <div className="overflow-x-auto max-w-full"> {/* Adjust max-w-full to control visible width */}
        <table className="table-fixed min-w-[800px] bg-white"> {/* Ensure table is wider than the container */}
          <thead className="bg-sky-500 text-primary-foreground text-sm">
            <tr>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium"></th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">SL No</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">NT Main head</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">NT Subheads</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Description</th>
            </tr>
          </thead>
          <tbody className='text-sm'>
            {Array.isArray(FlowCodeHeads) && FlowCodeHeads.length > 0
              ? FlowCodeHeads.map((data, index) => (
                <tr key={index} className="border-b" style={{ backgroundColor: index % 2 === 0 ? 'white' : '#f3f4f6' }}>
                  <td className="py-2 px-4">
                    <input type="checkbox" className="form-checkbox" />
                  </td>
                  <td className="py-2 px-4">{index + 1}</td>
                  <td className="py-2 px-4">{data.nt_main_head}</td>
                  <td className="py-2 px-4">{data.nt_subheads}</td>
                  <td className="py-2 px-4">{data.description}</td>
                </tr>
              ))
              : <tr><td colSpan="5" className="py-2 px-4 text-center">No data available</td></tr>}
          </tbody>
        </table>
      </div>
    </div>
  );
}
