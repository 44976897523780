import React from 'react';

const CashFlowActions = ({ buttons = [] }) => {
  return (
    <div className="flex items-center space-l-1 ml-4 p-2 -mb-4">
      {buttons.map((button, index) => (
        <button
          key={index}
          className="bg-secondary text-secondary-foreground p-2 rounded-lg"
          onClick={button.onClick}
        >
          <button.icon className={`bg-white text-3xl right-5 top-20 border border-dark-gray shadow-md cursor-pointer p-1.5 ${button.rotate ? 'rotate-90' : ''}`} />
        </button>
      ))}
    </div>
  );
};

export default CashFlowActions;
//#E7E7E7 table
