import React from "react";

const CustomInput = React.forwardRef(({ value, onClick, placeholder }, ref) => (
  <input
    className="border border-gray-300 p-1.5 rounded w-72 h-8 ml-4 text-customGray focus:outline-none hover:border-blue-400"
    value={value}
    onClick={onClick} // Opens calendar picker
    readOnly // Prevents manual typing
    placeholder={placeholder}
    ref={ref}
  />
));

export default CustomInput;
