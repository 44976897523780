// import React, { useState, useEffect } from 'react';
// import ReTable from '../../../../../components/Table';
// import Header from '../../../../../components/Header';
// import { useNavigate } from 'react-router-dom';
// import SetUpServices from '../../../../../rest-services/SetUpServices';
// import { BamButtonsConfigs } from '../../Components/BamButtonsConfig';
// import BamActions from '../../Components/BamActions';

// export default function AccountNumberLogic() {
//   const navigate = useNavigate();
//   const [data, setData] = useState([]);
//   const [selectedRows, setSelectedRows] = useState([]);

//   const buttons = BamButtonsConfigs.screen1.map(button => ({
//     ...button,
//   }));

//   useEffect(() => {
//     // Fetch data from the service when the component mounts
//     const fetchAccountNumberLogic = async () => {
//       try {
//         const response = await SetUpServices.getAccountNumberLogic();
//         const fetchedData = response.data.data.map(item => ({
//           ...item,
//           subRows: [] // Add empty subRows array to avoid undefined errors
//         }));
//         setData(fetchedData);
//         console.log(fetchedData);
//       } catch (error) {
//         console.error('Error fetching account number logic:', error);
//       }
//     };

//     fetchAccountNumberLogic();
//   }, []);

//   const columns = React.useMemo(
//     () => [
//       { Header: 'Account ID Generation Options', accessor: 'accountIdGenerationOptions', width: 300 },
//       { Header: 'Multiple', accessor: 'multiple', width: 200 },
//       {
//         Header: 'New Account Request',
//         accessor: 'newAccountRequest',
//         width: 150,
//         Cell: ({ row, value }) => (
//           row.original.accountIdGenerationOptions === "Bank(4)Curr(3)SLNO"
//             ? '--'
//             : (
//               <input
//                 type="radio"
//                 name="newAccountRequest"
//                 checked={Number(value) === 1}  // Check if value equals 1
//               />
//             )
//         )
//       },
//       {
//         Header: 'For Bank Account ID',
//         accessor: 'forBankAccountId',
//         width: 150,
//         Cell: ({ value }) => (
//           <input
//             type="radio"
//             name="forBankAccountId"
//             checked={Number(value) === 1}  // Check if value equals 1
//           />
//         )
//       }
//     ],
//     []
//   );

//   const handleSelectRow = (id) => {
//     setSelectedRows((prev) =>
//       prev.includes(id) ? prev.filter((row) => row !== id) : [...prev, id]
//     );
//   };

//   const handleSelectAll = () => {
//     setSelectedRows(selectedRows.length === data.length ? [] : data.map((d) => d.id));
//   };

//   const handleRowDoubleClick = (id) => {
//     console.log('Row double-clicked:', id);
//   };

//   const handleBreadcrumbClick = (crumb) => {
//     console.log("Breadcrumb clicked:", crumb.title);
//     if (crumb.title === 'BAM') {
//       navigate('/bam');
//     } else if (crumb.title === 'Set Up') {
//       navigate('/bam');
//     } else if (crumb.title === 'Account Number Logic') {
//       navigate('/account-number-logic');
//     } else if (crumb.path) {
//       navigate(crumb.path);
//     }
//   };

//   return (
//     <div>
//       <div>
//         <Header
//           title={"BAM > Set Up > Account Number Logic"}
//           onBreadcrumbClick={handleBreadcrumbClick}
//         />
//       </div>
//       <div>
//         <BamActions buttons={buttons} />
//       </div>
//       <ReTable
//         data={data}
//         columns={columns}
//         onSelectRow={handleSelectRow}
//         selectedRows={selectedRows}
//         onSelectAll={handleSelectAll}
//         onRowDoubleClick={handleRowDoubleClick}
//       />
//     </div>
//   );
// }

import React, { useState, useEffect } from "react";
import ReTable from "../../../../../components/Table";
import Header from "../../../../../components/Header";
import { useNavigate } from "react-router-dom";
import SetUpServices from "../../../../../rest-services/SetUpServices";
import { BamButtonsConfigs } from "../../Components/BamButtonsConfig";
import BamActions from "../../Components/BamActions";
import { useToast } from "../../../../../components/toast/toast";

export default function AccountNumberLogic() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedNewAccountRequest, setSelectedNewAccountRequest] =
    useState(null);
  const [selectedForBankAccountId, setSelectedForBankAccountId] =
    useState(null);

  const [assignee, setAssignee] = useState({
    clientId: "",
    id: "",
    assigner: "",
  });

  const buttons = BamButtonsConfigs.screen1.map((button) => ({
    ...button,
  }));

  const { toast } = useToast();
  const fetchAccountNumberLogic = async () => {
    try {
      if (!assignee.clientId) {
        console.log("Waiting for clientId...");
        return;
      }
      const response = await SetUpServices.getAccountNumberLogic(
        assignee.clientId
      );
      const fetchedData = response.data.data
        .map((item) => ({
          ...item,
          subRows: [],
        }))
        .sort((a, b) => a.id - b.id);

      setData(fetchedData);
    } catch (error) {
      console.error("Error fetching account number logic:", error);
    }
  };

  const handleRadioChange = async (id, field) => {
    const newValue =
      field === "newAccountRequest"
        ? selectedNewAccountRequest !== id
        : selectedForBankAccountId !== id;

    setData((prevData) =>
      prevData.map((item) => ({
        ...item,
        [field]: item.id === id ? 1 : 0,
      }))
    );

    try {
      const payload =
        field === "newAccountRequest"
          ? { newAccountRequest: newValue ? 1 : 0 }
          : { forBankAccountId: newValue ? 1 : 0 };

      const response = await SetUpServices.updateAccountNumberLogic(
        id,
        payload
      );

      if (response.status === 200) {
        field === "newAccountRequest"
          ? setSelectedNewAccountRequest(id)
          : setSelectedForBankAccountId(id);
        await fetchAccountNumberLogic();
      } else if (response.status === 204) {
        toast("error", "Can't Select Same Option For Both");
        fetchAccountNumberLogic();
      }
    } catch (error) {
      console.error("Error updating account number logic:", error);

      // Revert if the API call fails
      setData((prevData) =>
        prevData.map((item) => ({
          ...item,
          [field]:
            field === "newAccountRequest"
              ? item.id === selectedNewAccountRequest
                ? 1
                : 0
              : item.id === selectedForBankAccountId
              ? 1
              : 0,
        }))
      );
    }
  };

  useEffect(() => {
    fetchAssignee();
  }, []);

  useEffect(() => {
    fetchAccountNumberLogic();
  }, [assignee]);

  const fetchAssignee = async () => {
    try {
      const userDetailString = sessionStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          clientId: userDetail.clientId || "",
          id: userDetail.id || "",
          assigner: userDetail.username || "",
        };
        setAssignee(assigneeData);
      } else {
      }
    } catch (error) {}
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Account ID Generation Options",
        accessor: "accountIdGenerationOptions",
        width: 300,
      },
      { Header: "Multiple", accessor: "multiple", width: 200 },
      {
        Header: "New Account Request",
        accessor: "newAccountRequest",
        width: 150,
        Cell: ({ row }) =>
          row.original.accountIdGenerationOptions ===
          "Bank(4)Curr(3)Account(5)" ? (
            "--"
          ) : (
            <input
              type="radio"
              name="newAccountRequest"
              checked={row.original.newAccountRequest === 1}
              onChange={() =>
                handleRadioChange(row.original.id, "newAccountRequest")
              }
            />
          ),
      },
      {
        Header: "For Bank Account ID",
        accessor: "forBankAccountId",
        width: 150,
        Cell: ({ row }) => (
          <input
            type="radio"
            name="forBankAccountId"
            checked={row.original.forBankAccountId === 1}
            onChange={() =>
              handleRadioChange(row.original.id, "forBankAccountId")
            }
          />
        ),
      },
    ],
    [selectedNewAccountRequest, selectedForBankAccountId] // Update on changes to selected rows
  );

  return (
    <div>
      <Header
        title={"BAM > Set Up > Account Number Logic"}
        onBreadcrumbClick={(crumb) => {
          if (crumb.title === "BAM") navigate("/bam");
          else if (crumb.title === "Set Up") navigate("/bam");
          else if (crumb.title === "Account Number Logic")
            navigate("/account-number-logic");
        }}
      />
      {/* <BamActions buttons={buttons} /> */}
      <ReTable
        data={data}
        columns={columns}
        onSelectRow={setSelectedRows}
        selectedRows={selectedRows}
        onSelectAll={() => setSelectedRows(data.map((d) => d.id))}
        onRowDoubleClick={(id) => console.log("Row double-clicked:", id)}
      />
    </div>
  );
}
