import React, { useState, useEffect } from "react";
import NoActionLayout from "../../../../../components/NoActionLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CalendarServices from "../../../../../rest-services/CalendarServices";
import { useToast } from "../../../../../components/toast/toast";
import Header from "../../../../../components/Header";
import CustomButton from "../../../../../components/CustomButton";
import BuGroupServices from "../../../../../rest-services/BuGroupServices";
import { TbFlag3Filled } from "react-icons/tb";
import Footer from "../../../../../components/footer";
import ButtonGroup from "../../../../../components/FooterButton";

export default function BuGroupAdd() {
  const { buGroupId } = useParams();
  const location = useLocation();
  const { mode } = location.state || {}; // Get the mode from the state
  const isViewMode = mode === 'view';
  const [buGroup, setBuGroup] = useState("");
  const [bugroupDescription, setBugroupDescription] = useState("");
  const [errors, setErrors] = useState({});
  const [assignee, setAssignee] = useState({ id: "", assigner: "" });
  const [alert, setAlert] = useState(null);
  const{toast}=useToast();
  const[status,setStatus] = useState("");
  const [isCopyMode, setIsCopyMode] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const isEditMode = !!buGroupId;

  const navigate = useNavigate();
  useEffect(() => {
    if (location.state?.copiedData) {
      setIsCopyMode(true); 
      const { BU_Group, BU_Group_Description } = location.state.copiedData;
      setBuGroup(BU_Group);
      setBugroupDescription(BU_Group_Description);
    } else if (buGroupId) {
      getDataForEdit();
    }
    fetchAssignee();
  }, [buGroupId, location.state]);

  const getDataForEdit = async () => {
    try {
      const response = await BuGroupServices.getBuGroupById(buGroupId);
      setBuGroup(response.data.BU_Group);
      setBugroupDescription(response.data.BU_Group_Description);
      setStatus(response.data.status)
    } catch {}
  };

  const validateForm = () => {
    const newErrors = {};
    if (!buGroup) newErrors.buGroup = "BuGroup  is required.";
    if (!bugroupDescription) newErrors.bugroupDescription = "BuGroup Description is required.";

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      toast("error", "Missing Required Fields");
    }
    return Object.keys(newErrors).length === 0;
  };

  const fetchAssignee = async () => {
    try {
      const userDetailString = sessionStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.userId || "",
        };
        setAssignee(assigneeData);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  const handleSubmit = async (e, saveAndContinue = false) => {
    e.preventDefault();
  
    // Validate the form
    if (!validateForm()) return;
  
    // Check for duplicate BU Group code
    const duplicateCheck = await checkDuplicateBugroupcode(buGroup);
    if (duplicateCheck) {
      // If a duplicate is found, show the toast and exit early
      // toast("error", "Bu Group Code already exists.");
      return;
    }
  
    // Prepare the data for submission
    const data = {
      id: isCopyMode ? null : buGroupId,
      BU_Group: buGroup,
      BU_Group_Description: bugroupDescription,
      created_by: assignee.assigner,
      changed_by: assignee.assigner,
    };
  
    console.log("BuGroup data", data);
  
    try {
      let response;
      if (isCopyMode || !buGroupId) {
        // Create a new BU Group
        response = await BuGroupServices.addBuGroup(
          `${process.env.REACT_APP_BASE_URL}/create-bugroup`,
          data
        );
      } else {
        // Update an existing BU Group
        response = await BuGroupServices.addBuGroup(
          `${process.env.REACT_APP_BASE_URL}/update-bugroup`,
          data
        );
      }
  
      if (response && !response.error) {
        toast(
          "success",
          buGroupId && !isCopyMode
            ? "Bu Group Updated successfully"
            : "Bu Group Added successfully"
        );
  
        // Clear the form and navigate
        setBuGroup("");
        setBugroupDescription("");
  
        if (!saveAndContinue) {
          navigate("/bugroup"); // Redirect after saving, only if Save and Continue is false
        }
      } else {
        toast("error", "Error adding/updating Bu Group.");
      }
    } catch (error) {
      console.error("Request error:", error);
      setAlert({
        severity: "error",
        message: `Error adding/updating Bu Group: ${error.message}`,
      });
    }
  };
  

  const checkDuplicateBugroupcode = async (code) => {
    try {
      const response = await BuGroupServices.checkDuplicateBugroupcode(
        `/getduplicatebugroup`,
        { BU_Group: code }
      );
  
      if (response?.isDuplicate) {
        setErrors((prev) => ({
          ...prev,
          buGroup: "BU Group code already exists.",
        }));
        toast("error", "Bu Group already exists.");
        return true; // Return true if duplicate exists
      }
  
      return false; // Return false if no duplicate
    } catch (error) {
      console.error("Error checking duplicate BU Group code:", error);
      setErrors((prev) => ({
        ...prev,
        buGroup: "Error checking BU Group code",
      }));
      return false; // Return false on error to prevent blocking
    }
  };

const handleBugroupCodeChange = (e) => {
  const code = e.target.value;
  setBuGroup(code);
  setUnsavedChanges(true);
  // Clear previous errors when typing
  setErrors(prev => ({ ...prev, buGroup: "" }));

  // Check for duplicate LGE Code locally
  if (code.trim() !== "") {
    checkDuplicateBugroupcode(code);
  }
};
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'Company & Bank Data') {
      navigate('/master-data');
    } else if (crumb.title === 'Bu Group') {
      navigate('/bugroup');
    } 
    else if (crumb.title === 'Primary Data') {
      navigate('/master-data');
    
  }else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };
  const getApprovedIcon = () => {
    let color = "black"; // Default color
    // Determine the color based on the approved status
    if (status === 3) {
      color = "#29CB00"; // Green
    } else if (status === 0) {
      color = "gray"; // Gray
    } else if (status === 1) {
      color = "yellow"; // Yellow
    } else if (status === 2) {
      color = "orange"; // Orange
    } else if (status === 4) {
      color = "red"; // Red
    }
  
    return <TbFlag3Filled style={{ color, marginLeft: '8px' }} />; // Return the icon with the determined color
  };
  return (
    <div>
<Header
  title={`Primary Data > Company & Bank Data > Bu Group > ${isCopyMode ? 'Add' : (buGroupId ? (isViewMode ? 'View' : 'Edit') : 'Add')}`}
  onBreadcrumbClick={handleBreadcrumbClick} 
  extraContent={buGroupId && !isCopyMode ? getApprovedIcon() : null}
    hasUnsavedChanges={unsavedChanges}
  />
          <div className="m-6 border border-gray-200 p-5  rounded-lg">

      <div className="flex flex-col space-y-12 col-span-1 p-6">
        <label className="flex items-center">
          <span className="w-48 font-small text-customGray text-sm">
            Bu Group<span className="text-red-500 ml-1">*</span>
          </span>
          <span className="ml-4">:</span>
          <input
            type="text"
            value={buGroup}
            maxLength={10}

            onChange={handleBugroupCodeChange}

            className={`border font-small text-customGray text-sm ${
              errors.buGroup ? "border-red-500" : "border-gray-300"
            } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
            required
            disabled={isViewMode || isEditMode}
          />
        </label>
       

        <label className="flex items-center">
          <span className="w-48 font-small text-customGray text-sm">
            BuGroup Description<span className="text-red-500 ml-1">*</span>
          </span>
          <span className="ml-4">:</span>
          <input
            type="text"
            value={bugroupDescription}
            maxLength={30}

            onChange={(e) => {setBugroupDescription(e.target.value);
              setUnsavedChanges(true);
            if(errors.bugroupDescription){
              setErrors((prevErrors)=>({
                ...prevErrors,
                bugroupDescription:"",
              }))
            }}}
            className={`border font-small text-customGray text-sm ${
              errors.bugroupDescription ? "border-red-500" : "border-gray-300"
            } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
            required
            disabled={isViewMode}
          />
        </label>
        

      </div>
     
    </div>
    <Footer>
      {(!isViewMode && (  <ButtonGroup  onSave={handleSubmit} onSaveContinue={(e) => handleSubmit(e, true)} hasId={!!buGroupId}/>))}
      </Footer>
    </div>
  );
}
