import { IoAddCircleOutline, IoSettingsOutline } from "react-icons/io5";
import { GiSettingsKnobs } from "react-icons/gi";
import { MdOutlineFileDownload, MdOutlineFileUpload } from "react-icons/md";
import { IoMdRefresh } from "react-icons/io";
import { CiSearch } from "react-icons/ci";
import { GiTwirlyFlower } from "react-icons/gi";
import { PiUserSwitchLight } from "react-icons/pi";

// Define the custom SVG as a React functional component
// const CustomSVGIcon = () => (
//   <svg 
//     xmlns="http://www.w3.org/2000/svg" 
//     width="1em" 
//     height="1em" 
//     viewBox="0 0 32 32"
//   >
//     <path 
//       fill="currentColor" 
//       d="M19.307 3.21a2.91 2.91 0 1 0-.223 1.94a11.64 11.64 0 0 1 8.232 7.049l1.775-.698a13.58 13.58 0 0 0-9.784-8.291m-2.822 1.638a.97.97 0 1 1 0-1.939a.97.97 0 0 1 0 1.94m-4.267.805l-.717-1.774a13.58 13.58 0 0 0-8.291 9.784a2.91 2.91 0 1 0 1.94.223a11.64 11.64 0 0 1 7.068-8.233m-8.34 11.802a.97.97 0 1 1 0-1.94a.97.97 0 0 1 0 1.94m12.607 8.727a2.91 2.91 0 0 0-2.599 1.62a11.64 11.64 0 0 1-8.233-7.05l-1.774.717a13.58 13.58 0 0 0 9.813 8.291a2.91 2.91 0 1 0 2.793-3.578m0 3.879a.97.97 0 1 1 0-1.94a.97.97 0 0 1 0 1.94M32 16.485a2.91 2.91 0 1 0-4.199 2.599a11.64 11.64 0 0 1-7.05 8.232l.718 1.775a13.58 13.58 0 0 0 8.291-9.813A2.91 2.91 0 0 0 32 16.485m-2.91.97a.97.97 0 1 1 0-1.94a.97.97 0 0 1 0 1.94"
//     />
//     <path 
//       fill="currentColor" 
//       d="M19.19 16.35a3.879 3.879 0 1 0-5.42 0a4.85 4.85 0 0 0-2.134 4.014v1.939h9.697v-1.94a4.85 4.85 0 0 0-2.143-4.014m-4.645-2.774a1.94 1.94 0 1 1 3.88 0a1.94 1.94 0 0 1-3.88 0m-.97 6.788a2.91 2.91 0 1 1 5.819 0z" 
//       className="ouiIcon__fillSecondary"
//     />
//   </svg>
// );

export const AdminActionsConfig = {
  screen1: [
    { icon: IoAddCircleOutline, onClick: () => {}, rotate: true, className: "text-blue-500" },
    { icon: GiSettingsKnobs, onClick: () =>{}, rotate: true, className: "text-green-500" },
    { icon: IoSettingsOutline, onClick: () => {}, className: "text-red-500" },
    { icon: MdOutlineFileDownload, onClick: () => {}, className: "text-yellow-500" },
    { icon: MdOutlineFileUpload, onClick: () => {}, className: "text-purple-500" },
    { icon: IoMdRefresh, onClick: () => console.log("Refresh"), rotate: true, className: "text-pink-500" },
  ],
  screen2: [
    { icon: CiSearch, onClick: () => {}, rotate: true, className: "text-indigo-500" },
    {
      icon: GiSettingsKnobs,
      onClick: () => console.log("Settings Knobs"),
      rotate: true,
      className: "text-green-500",
    },

    { icon: IoSettingsOutline, onClick: () => console.log("Settings"), className: "text-red-500" },
    { icon: MdOutlineFileDownload, onClick: () => console.log("Download"), className: "text-yellow-500" },
    {
      icon: IoMdRefresh,
      onClick: () => console.log("Refresh"),
      rotate: true,
      className: "text-pink-500",
    },
  ],
  screen3: [
    // { icon: IoAddCircleOutline, onClick: () => {}, rotate: true, className: "text-blue-500" },

    // {
    //   icon: GiSettingsKnobs,
    //   onClick: () => console.log("Settings Knobs"),
    //   rotate: true,
    //   className: "text-green-500",
    // },

    // { icon: IoSettingsOutline, onClick: () => console.log("Settings"), className: "text-red-500" },
    {
      icon: IoMdRefresh,
      onClick: () => console.log("Refresh"),
      rotate: true,
      className: "text-pink-500",
    },
    { icon: MdOutlineFileDownload,  onClick: () => {}, className: "text-yellow-500" },
    
  ],

  screen4: [
    { icon: IoAddCircleOutline, onClick: () => {}, rotate: true, className: "text-blue-500" },

    {
      icon: GiSettingsKnobs,
      onClick: () => console.log("Settings Knobs"),
      rotate: true,
      className: "text-green-500",
    },

    { icon: IoSettingsOutline, onClick: () => console.log("Settings"), className: "text-red-500" },
  
    { icon: MdOutlineFileDownload, onClick: () => console.log("Download"), className: "text-yellow-500" },
    {
      icon: IoMdRefresh,
      onClick: () => console.log("Refresh"),
      rotate: true,
      className: "text-pink-500",
    },
    
    
  ],

  screen5: [
    { icon: IoAddCircleOutline, onClick: () => {}, rotate: true, className: "text-blue-500" },

    {
      icon: GiSettingsKnobs,
      onClick: () => console.log("Settings Knobs"),
      rotate: true,
      className: "text-green-500",
    },

    { icon: IoSettingsOutline, onClick: () => console.log("Settings"), className: "text-red-500" },
  
    {
      icon: IoMdRefresh,
      onClick: () => console.log("Refresh"),
      rotate: true,
      className: "text-pink-500",
    },
    
    
  ],


  // Add configurations for other screens
};
