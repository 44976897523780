import React from 'react'


import { CashFlowbuttonsConfigs } from '../../../Components/CashFlowbuttonsConfig'
import NoActionLayout from '../../../../../../components/NoActionLayout';
import CashFlowActions from '../../../Components/CashFlowActions';
import { useNavigate } from 'react-router-dom';
import { IoAddCircleOutline } from 'react-icons/io5';
import FlowCodeHeadsTable from '../../../Tables/SetUp/FlowCodeHeadsTable';

export default function FlowCodeHeads() {
    const navigate = useNavigate();

    const handleNavigate = (path) => {
      navigate(path);
    };
  
    const buttons = CashFlowbuttonsConfigs.screen1.map(button => ({
      ...button,
      onClick: button.icon === IoAddCircleOutline 
        ? () => handleNavigate("/flow-code-add") // Navigate to the desired screen
        : button.onClick // Use the existing onClick handler for other buttons
    }));
  return (
    <div>
      <div>
        <NoActionLayout title="Cash Flow > Set Up > Flow Code Heads" />
      </div>
      <div>
      <CashFlowActions buttons={buttons}/>
      </div>
      <FlowCodeHeadsTable/>
    </div>
  )
}
