
import Header from '../../../../../components/Header'
import { useNavigate } from "react-router-dom";
import UserTypesTable from '../../Tables/UserTypesTable';



export default function UserType() {




    const navigate = useNavigate();
    const handleBreadcrumbClick = (crumb) => {
        console.log("Breadcrumb clicked:", crumb.title);
        if (crumb.title === 'Admin') {
          navigate('/14/admin');
        } 
        else if (crumb.title === 'Licenses') {
            navigate('/14/admin');
        }

        else if (crumb.title === 'UserTypes') {
          navigate('/14/admin/user-type-screen/115');
      }
         else if (crumb.path) {
          navigate(crumb.path); // Navigate to the path specified in the crumb
        }
      };

      


      
    



    return (
        <div>
            <div>

        <Header title={"Admin > Licenses > Usertypes"} 
        onBreadcrumbClick={handleBreadcrumbClick}/>
        
      </div>
     <UserTypesTable/>
        </div>
    )
}
