import React, { useEffect, useState } from 'react'
import Header from '../../../../../../../components/Header'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Footer from '../../../../../../../components/footer';
import { IoAddCircleOutline, IoChevronDown, IoChevronForward, IoChevronUp, IoPrint, IoSettingsOutline } from 'react-icons/io5';
import { IoMdCheckmark, IoMdClose, IoMdCopy, IoMdCreate, IoMdRefresh, IoMdTime, IoMdTrash } from 'react-icons/io';
import { MdLocalPrintshop, MdOutlineFileDownload, MdOutlineFileUpload, MdOutlineLock, MdOutlineSaveAs } from 'react-icons/md';
import { LuKeyRound } from 'react-icons/lu';
import UserService from '../../../../../../../rest-services/UserServices';
import { useToast } from '../../../../../../../components/toast/toast';
import ButtonGroup from '../../../../../../../components/FooterButton';
import { RxDropdownMenu } from 'react-icons/rx';
import { GiSettingsKnobs } from 'react-icons/gi';
import { BiSave } from "react-icons/bi";
import { jsPDF } from 'jspdf';
import html2pdf from 'html2pdf.js';
import { VscVmActive } from 'react-icons/vsc';
import LabelWithButton from '../../../../../../../components/InputButtonField';
import ReusableModal from '../../../../../../../components/PopupComponent';

export default function RolesAdd() {
    const navigate = useNavigate();
    const { roleId } = useParams();
    const location = useLocation();
    const { mode } = location.state || {}; // Get the mode from the state
    const isViewMode = mode === 'view';
    const isCopyAddMode = mode === 'add';
    const isEditMode = mode === 'Edit';
    const { toast } = useToast();
    const [roleName, setRoleName] = useState("");
    const [mainMenu, setMainMenu] = useState("");
    const [subMenu, setSubMenu] = useState("");
    const [iconMenu, setIconMenu] = useState("");
    const [roleID, setRoleID] = useState("");
    const [roleDesc, setRoleDesc] = useState("");
    const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState(null);
  const [Icons, setIcons] = useState([]);
  const [expandedMainMenu, setExpandedMainMenu] = useState(null);
  const [expandedSubMenu, setExpandedSubMenu] = useState({});
  const [selectedSubMenu, setSelectedSubMenu] = useState(null); 
  const [permissionIcons, setPermissionIcons] = useState([]);
  const [selectedIcons, setSelectedIcons] = useState({});
  const [selectedPermissions, setSelectedPermissions] = useState({});
  const [userType, setUserType] = useState("");
  const [userTypes, setUserTypes] = useState([]);

  const [allExpanded, setAllExpanded] = useState(false);
  const [expandedIcon, setExpandedIcon] = useState([]);
const [filteredIcons, setFilteredIcons] = useState([]);
  const [searchTerm, setSearchTerm] = useState(''); // State for search input
  const [pdfMode, setPdfMode] = useState(false);
  const [pdfUrl, setPdfUrl] = React.useState(null); // Store the PDF URL
const [showViewer, setShowViewer] = React.useState(false); // Control viewer visibility
const [assignee, setAssignee] = useState({ id: "", username: "" });


  const [isModalOpenForModule, setIsModalOpenForModule] = useState(false);
  const [isModalOpenForSubModule, setIsModalOpenForSubModule] = useState(false);
  const [isModalOpenForIconModule, setIsModalOpenForIconModule] = useState(false);
  const [searchQueryState, setSearchQueryState] = useState(""); // State for search query
  const [searchQueryMainmodule, setSearchQueryMainmodule] = useState(""); // State for search query
  const [searchQuerySubmodule, setSearchQuerySubmodule] = useState(""); // State for search query
  const [searchQueryIconmodule, setSearchQueryIconmodule] = useState(""); // State for search query
  const [selectMainMenu, setselectMainMenu] = useState(mainMenu || "");
  const [selectSubMenu, setselectSubMenu] = useState("");
  const [selectIconMenu, setselectIconMenu] = useState("");
  const [checkboxState, setCheckboxState] = useState({}); 
    // Bread crumbs navigation
        const handleBreadcrumbClick = (crumb) => {
            console.log("Breadcrumb clicked:", crumb.title);
            if (crumb.title === 'User & SOD') {
              navigate('/14/admin');
            } else if (crumb.title === 'Admin') {
              navigate('/14/admin');
            }
            //  else if (crumb.title === 'Permissions') {
            //     navigate('/14/admin/109/roles-and-permission');
            //   } 
              else if (crumb.title === 'Roles') {
                navigate('/14/admin/111/roles-and-permission/roles');
              } 
            else if (crumb.path) {
              navigate(crumb.path); // Navigate to the specified path in the breadcrumb
            }
          };
 
      useEffect(() => {
        fetchIconsData();
        fetchGetPermissionsData();
        fetchUserTypes();
        fetchAssignee();
      }, []);

      
      useEffect(() => {
        if (roleId) {
          console.log("selected role",roleId);
          getDataForSelectedRole();
        }
      }, [roleId]);

      // Retrieve data from the database based on the role ID and Set the role name, description and permissions.
     
    const getDataForSelectedRole = async () => {
      try {
        const data = { roleId: roleId };
        const response = await UserService.saveContactDetails(
          `${process.env.REACT_APP_BASE_URL}/get-role-by-roleId`,
          data
        );
console.log("Roles Response data",response);
        const responseData = response.data;
  console.log("role id data",responseData);
        if (responseData) {
          if (!isCopyAddMode) {
            setRoleName(responseData.name);
            setRoleID(responseData.roleId);
            setRoleDesc(responseData.description);
            setUserType(responseData.userType);
          }

          const initialIcons = {};
          const initialPermissions = {};

          responseData.rolesPermissionsTables.forEach((rolePermission) => {
            const { iconId, permissionsId } = rolePermission;

            initialIcons[iconId] = true;
            if (!initialPermissions[iconId]) initialPermissions[iconId] = {};
            initialPermissions[iconId][permissionsId] = true;
          });

          setSelectedIcons(initialIcons);
          setSelectedPermissions(initialPermissions);
        }

        if (responseData.error) {
          console.error("Error fetching roles data:", responseData.message);
        }
      } catch (error) {
        console.error("Error fetching roles data", error);
      }
    };

    const fetchAssignee = async () => {
      try {
        const userDetailString = sessionStorage.getItem("userDetail");
        if (userDetailString) {
          const userDetail = JSON.parse(userDetailString);
          const assigneeData = {
            id: userDetail.id || "",
            clientId: userDetail.clientId || "",
            assigner: userDetail.username || "",
            userId: userDetail.userId || "",

          };
          
          setAssignee(assigneeData);
          
          // Log the id and assigner values to the console
          console.log("Assignee ID:", assigneeData.id);
          console.log("Assigner:", assigneeData.assigner);
          console.log("Assigner clientId:", assigneeData.clientId);
        console.log("Assigner userId:", assigneeData.userId);
          
        } else {
          setAlert({
            severity: "error",
            message: "No user detail found in session storage.",
          });
        }
      } catch (error) {
        setAlert({
          severity: "error",
          message: `Error fetching user details: ${error.message}`,
        });
      }
    };

  
      const fetchUserTypes = async () => {
        try {
          const response = await UserService.getUsers(
            `${process.env.REACT_APP_BASE_URL}/get-alluser-type`
            
          );
          setUserTypes(response.data.data);
        } catch (error) {
          console.error("Error fetching user types:", error);
        }
      };

      const fetchIconsData = async () => {
        try {
          const response = await UserService.getUsers(`${process.env.REACT_APP_BASE_URL}/get-icons-data`);      
          console.log("Response from server fetching Icons data:", response);
          const responseData = response.data.data;
          setIcons(responseData);
    
    
          console.log("double check users",response.data.data);
          if (responseData.error) {
            console.error("Error fetching Users data:", responseData.message);
            setAlert({ severity: "error", message: responseData.message });
            return;
          }
        } catch (error) {
          console.error("Error fetching Users data", error);
          setAlert({ severity: "error", message: `Error fetching Users data, kindly check the connection ${error.message}` });
        }
      };

      const fetchGetPermissionsData = async () => {
        try {
          const response = await UserService.getUsers(`${process.env.REACT_APP_BASE_URL}/get-permissions`);      
          console.log("Response from server fetching permissions data:", response);
          const responseData = response.data.data;
          setPermissionIcons(responseData);
    
    
          console.log("double check users permissons",responseData);
          if (responseData.error) {
            console.error("Error fetching Users data:", responseData.message);
            setAlert({ severity: "error", message: responseData.message });
            return;
          }
        } catch (error) {
          console.error("Error fetching Users data", error);
          setAlert({ severity: "error", message: `Error fetching Users data, kindly check the connection ${error.message}` });
        }
      };

      const toggleAllMenus = () => {
        setAllExpanded(!allExpanded);
      };
      
      const toggleModalForModule = () => {
        setIsModalOpenForModule(!isModalOpenForModule);
    
      }
      const handleSearchChangeState = (e) => {
        setSearchQueryState(e.target.value);
      };

      const handleSearchChangeSubmodule = (e) => {
        setSearchQuerySubmodule(e.target.value);

      }

      const handleSearchChangeMainmodule = (e) => {
        setSearchQueryMainmodule(e.target.value);

      }

      const handleSearchChangeIconmodule = (e) => {
        setSearchQueryIconmodule(e.target.value);

      }

      const toggleModalForSubModule = () => {
        setIsModalOpenForSubModule(!isModalOpenForSubModule);

      }
     
      const toggleModalForIconModule = () => {
        setIsModalOpenForIconModule(!isModalOpenForIconModule);
      }

      const toggleMainMenu = (mainMenuId) => {
        setExpandedMainMenu((prev) => (prev === mainMenuId ? null : mainMenuId));
      };
    
      const toggleSubMenu = (mainMenuId, subMenuId) => {
        setSelectedSubMenu(subMenuId);
        setExpandedSubMenu((prev) => ({
          ...prev,
          [mainMenuId]: prev[mainMenuId] === subMenuId ? null : subMenuId,
        }));
      };

      
      // const toggleSubMenu = (mainMenuId, subMenuId) => {
      //   // Always update the expandedSubMenu to include the currently clicked subMenuId
      //   setSelectedSubMenu(subMenuId);
      //   setExpandedSubMenu((prev) => ({
      //     ...prev,
      //     [mainMenuId]: subMenuId,  // This will always set the subMenuId for the mainMenuId, keeping it open
      //   }));
      // };
      
      
      const toggleIcon = (iconId) => {
        setExpandedIcon((prev) =>
          prev.includes(iconId) ? prev.filter((id) => id !== iconId) : [...prev, iconId]
        );
      };

      const handlePermissionChange = (iconId, permissionId) => {
        setSelectedPermissions((prev) => ({
          ...prev,
          [iconId]: {
            ...prev[iconId],
            [permissionId]: !prev[iconId]?.[permissionId] || false,
          },
        }));
      };

  
    const handleIconChange = (iconId) => {
      setSelectedIcons((prev) => ({
        ...prev,
        [iconId]: !prev[iconId],
      }));
    };


      const checkDuplicateRoleID = async (name) => {
              try {
                const response = await UserService.checkDuplicateRoleName(
                  `${process.env.REACT_APP_BASE_URL}/check-role-ID-duplicate`,
                  { roleID: name }  // Sending roleName to backend
                );
                
                console.log("Response from server:", response);
            
                if (response.message === "Role ID already exists") {
                  // Check if it's a duplicate
                console.log("Response from internal server:", response);
            setErrors({ roleID: ' ' });

            toast("error", "Role ID already exists.");
                
                }
                console.log("Response from external server:", response);

              } catch (error) {
                console.error("Error checking duplicate role ID:", error);
                // Handle potential errors when making the API call
                setErrors(prev => ({ ...prev, roleName: "Error checking role ID" }));
              }
      };

      const checkDuplicateRoleName = async (name) => {
        try {
          const response = await UserService.checkDuplicateRoleName(
            `${process.env.REACT_APP_BASE_URL}/check-role-duplicate`,
            { roleName: name }  // Sending roleName to backend
          );
          
          console.log("Response from server:", response);
      
          if (response.message === "Role name already exists") {
            // Check if it's a duplicate
          console.log("Response from internal server:", response);
      // setErrors({ roleName: ' ' });

      //  toast("warning", "Role name already exists.");
           
          }
          console.log("Response from external server:", response);

        } catch (error) {
          console.error("Error checking duplicate role name:", error);
          // Handle potential errors when making the API call
          setErrors(prev => ({ ...prev, roleName: "Error checking role name" }));
        }
      };

      const handleRoleIdChange = (e) => {
        const name = e.target.value;
        setRoleID(name);
        
        // Clear previous errors when typing
        setErrors(prev => ({ ...prev, roleID: "" }));
      
        // Check for duplicate role names when typing
        if (name.trim() !== "") {
          checkDuplicateRoleID(name);
        }
      };

      const handleRoleNameChange = (e) => {
        const name = e.target.value;
        setRoleName(name);
        
        // Clear previous errors when typing
        setErrors(prev => ({ ...prev, roleName: "" }));
      
        // Check for duplicate role names when typing
        if (name.trim() !== "") {
          checkDuplicateRoleName(name);
        }
      };
     
      const validateFields = () => {
        const newErrors = {};
        if (!roleID) newErrors.roleID = " ";
        if (!roleName) newErrors.roleName = " ";
        if (!userType) newErrors.userType = " ";

        setErrors(newErrors);
  
        // Show toast only if there are errors
        if (Object.keys(newErrors).length > 0) {
          toast("error", "Missing required fields.");
          return false;
        }

        return true;
      };


    const handleSaveContinueDetails = async () => {
      if (!validateFields()) return;
  
      if(isEditMode)
        {
  
            try {
              const roleData = {
                roleId,
                roleName,
                roleDesc,
                clientId:assignee.clientId,
                updatedBy: assignee.userId,
                userType: userType === "" ? null : parseInt(userType, 10) // Convert userType to integer or set to null

              };
            const roleResponse = await UserService.saveAllUserDetails(
              `${process.env.REACT_APP_BASE_URL}/update-role`, roleData
          );
          if (roleResponse.status === 200) {
            console.log("role data stored now storing permissions");
                const roleId = roleResponse.roleId; // Assuming the role ID is returned in the response
              console.log("stroed role id",roleId);
                // Prepare the selected permissions to include the new roleId
                const permissionsData = [];
                Object.keys(selectedIcons).forEach((iconId) => {
                  if (selectedIcons[iconId]) {
                    Object.keys(selectedPermissions[iconId] || {}).forEach((permissionId) => {
                      if (selectedPermissions[iconId][permissionId]) {
                        permissionsData.push({
                          role_id: roleId,
                        clientId: assignee.clientId,
                          icon_id: parseInt(iconId),
                          permission_id: parseInt(permissionId),
                        });
                      }
                    });
                  }
                });
                // Now submit the permissions with the correct role_id
                const permissionsResponse = await UserService.saveAllRolesDetails(
                    `${process.env.REACT_APP_BASE_URL}/update-role-permissions`, { permissions: permissionsData }
                );
    
                if (permissionsResponse.status === 200) {
                    // alert('Permissions and role saved successfully');
                  toast("success", "Roles Updated successfully");
  
                  navigate(`/14/admin/111/roles-and-permission/roles-edit/${roleId}`);
                } else {
                    // alert('Failed to save permissions');
                    toast("warning", "Failed to save permissions");
  
                }
  
          }
          else {
            toast("warning", "Failed to save role details");
          }  
            } catch (error) {
              console.error('Error submitting permissions and role details:', error);
              // alert('Error submitting permissions and role details');
              toast("warning", "Error submitting permissions and role details");
    
          }
  
        }
        else{

          try {
            // First, submit the role details
            const roleData = {
                roleID,
                roleName,
                roleDesc,
                clientId:assignee.clientId,
                createdBy: assignee.userId,
                userType: userType === "" ? null : parseInt(userType, 10) // Convert userType to integer or set to null
              };
    
            const roleResponse = await UserService.saveAllUserDetails(
                `${process.env.REACT_APP_BASE_URL}/insert-role`, roleData
            );
            console.log("check role status response",roleResponse);
            if (roleResponse.status === 200) {
              console.log("role data stored now storing permissions");
                const roleId = roleResponse.roleId; // Assuming the role ID is returned in the response
              console.log("stroed role id",roleId);
              const clientId = roleResponse.clientId; // Assuming the role ID is returned in the response
              console.log("stroed client id",clientId);

                // Prepare the selected permissions to include the new roleId
                const permissionsData = [];
                Object.keys(selectedIcons).forEach((iconId) => {
                  if (selectedIcons[iconId]) {
                    Object.keys(selectedPermissions[iconId] || {}).forEach((permissionId) => {
                      if (selectedPermissions[iconId][permissionId]) {
                        permissionsData.push({
                          role_id: roleId,
                        clientId: clientId,
                          icon_id: parseInt(iconId),
                          permission_id: parseInt(permissionId),
                        });
                      }
                    });
                  }
                });
                // Now submit the permissions with the correct role_id
                const permissionsResponse = await UserService.saveAllRolesDetails(
                    `${process.env.REACT_APP_BASE_URL}/insert-role-permissions`, { permissions: permissionsData }
                );
    
                if (permissionsResponse.status === 200) {
                    // alert('Permissions and role saved successfully');
                  toast("success", "Roles saved successfully");
  
                    navigate('/14/admin/111/roles-and-permission/roles/add');
                } else {
                    // alert('Failed to save permissions');
                    toast("warning", "Failed to save permissions");
  
                }
            } else {
              toast("warning", "Failed to save role details");
  
                // alert('Failed to save role details');
            }
        } catch (error) {
            console.error('Error submitting permissions and role details:', error);
            // alert('Error submitting permissions and role details');
            toast("warning", "Error submitting permissions and role details");
  
        }

        }
     
  };

    const handleSubmit = async () => {
      if (!validateFields()) return;
        console.log("Role id is ",roleId);
       if(isEditMode)
      {

          try {
            const roleData = {
              roleId,
              roleName,
              roleDesc,
              clientId:assignee.clientId,
              updatedBy: assignee.userId,
              userType: userType === "" ? null : parseInt(userType, 10) // Convert userType to integer or set to null

          };
          const roleResponse = await UserService.saveAllUserDetails(
            `${process.env.REACT_APP_BASE_URL}/update-role`, roleData
        );
        if (roleResponse.status === 200) {
          console.log("role data stored now storing permissions");
              const roleId = roleResponse.roleId; // Assuming the role ID is returned in the response
            console.log("stroed role id",roleId);
              // Prepare the selected permissions to include the new roleId
              const permissionsData = [];
              Object.keys(selectedIcons).forEach((iconId) => {
                if (selectedIcons[iconId]) {
                  Object.keys(selectedPermissions[iconId] || {}).forEach((permissionId) => {
                    if (selectedPermissions[iconId][permissionId]) {
                      permissionsData.push({
                        role_id: roleId,
                        clientId: assignee.clientId,
                        icon_id: parseInt(iconId),
                        permission_id: parseInt(permissionId),
                      });
                    }
                  });
                }
              });
              // Now submit the permissions with the correct role_id
              const permissionsResponse = await UserService.saveAllRolesDetails(
                  `${process.env.REACT_APP_BASE_URL}/update-role-permissions`, { permissions: permissionsData }
              );
  
              if (permissionsResponse.status === 200) {
                  // alert('Permissions and role saved successfully');
                toast("success", "Roles Updated successfully");

                  navigate('/14/admin/111/roles-and-permission/roles');
              } else {
                  // alert('Failed to save permissions');
                  toast("warning", "Failed to save permissions");
              }
        }
        else {
          toast("warning", "Failed to save role details");
        }
          } catch (error) {
            console.error('Error submitting permissions and role details:', error);
            // alert('Error submitting permissions and role details');
            toast("warning", "Error submitting permissions and role details");
          }

      }
      else{
        try {
          // First, submit the role details
          const roleData = {
              roleID,
              roleName,
              roleDesc,
              clientId:assignee.clientId,
              createdBy: assignee.userId,
              userType: userType === "" ? null : parseInt(userType, 10) // Convert userType to integer or set to null
          };
          console.log("store req data",roleData);
          const roleResponse = await UserService.saveAllUserDetails(
              `${process.env.REACT_APP_BASE_URL}/insert-role`, roleData
          );
          console.log("check role status response",roleResponse);
          if (roleResponse.status === 200) {

            console.log("role data stored now storing permissions");
              const roleId = roleResponse.roleId; // Assuming the role ID is returned in the response
              const clientId = roleResponse.clientId; // Assuming the role ID is returned in the response
            console.log("stroed role id",roleId);
            console.log("stroed client id",clientId);
              // Prepare the selected permissions to include the new roleId
             const permissionsData = [];
              Object.keys(selectedIcons).forEach((iconId) => {
                if (selectedIcons[iconId]) {
                  Object.keys(selectedPermissions[iconId] || {}).forEach((permissionId) => {
                    if (selectedPermissions[iconId][permissionId]) {
                      permissionsData.push({
                        role_id: roleId,
                        clientId: clientId,
                        icon_id: parseInt(iconId),
                        permission_id: parseInt(permissionId),
                      });
                    }
                  });
                }
              });
              console.log("Storing permissions data",permissionsData);
              // Submit permissions
              const permissionsResponse = await UserService.saveAllRolesDetails(
                `${process.env.REACT_APP_BASE_URL}/insert-role-permissions`, { permissions: permissionsData }
              );

              if (permissionsResponse.status === 200) {
                  // alert('Permissions and role saved successfully');
                toast("success", "Roles saved successfully");

                  navigate('/14/admin/111/roles-and-permission/roles');
              } else {
                  // alert('Failed to save permissions');
                  toast("warning", "Failed to save permissions");

              }
          } else {
            toast("warning", "Failed to save role details");
          }
      } catch (error) {
          console.error('Error submitting permissions and role details:', error);
          // alert('Error submitting permissions and role details');
          toast("warning", "Error submitting permissions and role details");

      }


      }
     
  };
  
  const iconMapping = {
    "Add": <IoAddCircleOutline size={18} />,  // Adjust size as needed
    "Edit": <IoMdCreate size={18} />,
    "View": <svg className="ml-2 text-customGray" xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.3em" viewBox="0 0 24 24"><path fill="currentColor" d="M6 23H3q-.825 0-1.412-.587T1 21v-3h2v3h3zm12 0v-2h3v-3h2v3q0 .825-.587 1.413T21 23zm-6-4.5q-3 0-5.437-1.775T3 12q1.125-2.95 3.563-4.725T12 5.5t5.438 1.775T21 12q-1.125 2.95-3.562 4.725T12 18.5m0-3q1.45 0 2.475-1.025T15.5 12t-1.025-2.475T12 8.5T9.525 9.525T8.5 12t1.025 2.475T12 15.5m0-2q-.625 0-1.062-.437T10.5 12t.438-1.062T12 10.5t1.063.438T13.5 12t-.437 1.063T12 13.5M1 6V3q0-.825.588-1.412T3 1h3v2H3v3zm20 0V3h-3V1h3q.825 0 1.413.588T23 3v3z"></path></svg>,
    "Copy": <IoMdCopy size={18} />,
    "Audit Trail": <IoMdTime size={18} />,
    "Approve": <IoMdCheckmark size={18} />,
    "Reject": <IoMdClose size={18} />,
    "Trigger OTP": <LuKeyRound size={18} />,
    "Upload": <MdOutlineFileUpload size={18} />,
    "Download": <MdOutlineFileDownload size={18} />,
    "Inactive": <VscVmActive size={18} />,
    "Lock": <MdOutlineLock size={18} />,
    "Delete": <IoMdTrash size={18} />,
    "Filter": <GiSettingsKnobs size={18} />,
    "Settings": <IoSettingsOutline size={18} />,
    "Refresh": <IoMdRefresh size={18} />,
    "Save": <BiSave size={18} />,
   "Save & Continue": <MdOutlineSaveAs size={18} />

    // Add other icons with size adjustments
  };


// Filtered Data Logic

useEffect(() => {
  const filtereddata = Icons.filter((mainMenuItem) => {
    const matchesMainMenu = mainMenu ? mainMenuItem.name === mainMenu : true;
 
    if (matchesMainMenu) {
      const filteredSubMenus = mainMenuItem.subMenus
        .filter((subMenuItem) => {
          const matchesSubMenu = subMenu ? subMenuItem.name === subMenu : true;
 
          if (matchesSubMenu) {
            const matchingIcons = subMenuItem.icons.filter((icon) =>
              icon.name.toLowerCase().includes(searchQueryState.toLowerCase())
            );
 
            return (
              matchingIcons.length > 0 ||
              subMenuItem.name.toLowerCase().includes(searchQueryState.toLowerCase())
            );
          }
          return false;
        })
        .map((subMenuItem) => ({
          ...subMenuItem,
          icons: subMenuItem.icons.filter((icon) =>
            icon.name.toLowerCase().includes(searchQueryState.toLowerCase())
          ),
        }));
 
      if (filteredSubMenus.length > 0) {
        return {
          ...mainMenuItem,
          subMenus: filteredSubMenus,
        };
      }
    }
 
    return null;
  }).filter(Boolean);
 
  // Update the state only if the filtered data changes
  setFilteredIcons(filtereddata);
}, [Icons, mainMenu, subMenu, searchQueryState]);



const handleCheckboxChangeForModule = (event) =>{
  const selectedCode = event.target.value;
  console.log("selected Main menu",selectedCode);
  setselectMainMenu(selectedCode);
  setMainMenu(selectedCode);

};


useEffect(() => {
  if (!subMenu) {
    console.error("No submenu selected");
    return;
  }

  const updatedFilteredIcons = filteredIcons.map((mainMenuItem) => {
    const filteredSubMenus = mainMenuItem.subMenus
      .filter((subMenuItem) => subMenuItem.name === subMenu)
      .map((matchingSubMenu) => ({
        ...matchingSubMenu,
        icons: matchingSubMenu.icons || [],
      }));

    if (filteredSubMenus.length > 0) {
      return {
        ...mainMenuItem,
        subMenus: filteredSubMenus,
      };
    }

    return null;
  }).filter(Boolean);

  // Only update state if data has changed
  if (JSON.stringify(updatedFilteredIcons) !== JSON.stringify(filteredIcons)) {
    if (updatedFilteredIcons.length > 0) {
      const matchingSubMenu = updatedFilteredIcons[0].subMenus[0];
      if (matchingSubMenu?.icons?.length > 0) {
        setselectSubMenu(matchingSubMenu.name); // Select the first matching submenu dynamically
      }
    }

    console.log("Filtered submenu with icons:", updatedFilteredIcons);
    setFilteredIcons(updatedFilteredIcons);
  }
}, [subMenu, filteredIcons]);



useEffect(() => {
  if (!iconMenu) {
    console.error("No iconMenu selected");
    return;
  }

  console.log("Icon selected", iconMenu);

  // Filter the main menu and submenus to update the filtered icons.
  const updatedFilteredIcons = filteredIcons.map((mainMenuItem) => {
    const updatedSubMenus = mainMenuItem.subMenus.map((subMenuItem) => {
      console.log("Current submenu being processed:", subMenuItem);

      // Check if the submenu contains the selected icon and filter accordingly
      if (subMenuItem.icons.some((icon) => icon.name === iconMenu)) {
        const filteredIcons = subMenuItem.icons.filter(
          (icon) => icon.name === iconMenu
        );
        return {
          ...subMenuItem,
          icons: filteredIcons,
        };
      }
      return subMenuItem;
    });

    return {
      ...mainMenuItem,
      subMenus: updatedSubMenus,
    };
  });

  // Only update state if data has changed
  if (JSON.stringify(updatedFilteredIcons) !== JSON.stringify(filteredIcons)) {
    setFilteredIcons(updatedFilteredIcons);
    
    // Dynamically set the icon selection if there are matching submenus
    const matchingSubMenu = updatedFilteredIcons.flatMap(mainMenuItem => 
      mainMenuItem.subMenus.filter(subMenu => subMenu.icons.some(icon => icon.name === iconMenu))
    )[0];

    if (matchingSubMenu?.icons?.length > 0) {
      setselectIconMenu(matchingSubMenu.icons[0].name); // Select the first matching icon dynamically
    }
  }
}, [iconMenu, filteredIcons]);



const handleCheckboxChangeForSubModule = (event) => {
  const selectedCode = event.target.value;
  console.log("selected sub module",selectedCode);
  setselectSubMenu(selectedCode);
  setSubMenu(selectedCode);
}

const handleCheckboxChangeForIconModule = (event) => {
  const selectedCode = event.target.value;
  console.log("selected Icons module",selectedCode);
  setselectIconMenu(selectedCode);
  setIconMenu(selectedCode);
}



const previewPDF = () => {
  setPdfMode(true); // Enable PDF mode with smaller fonts

  const element = document.querySelector(".max-w-8xl");

  const options = {
    margin: 10,
    filename: 'RoleDetails.pdf',
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
  };

  html2pdf()
    .from(element)
    .set(options)
    .outputPdf('blob') // Output as a Blob
    .then((pdfBlob) => {
      const pdfUrl = URL.createObjectURL(pdfBlob); // Create URL from Blob
      setPdfUrl(pdfUrl); // Update state with PDF URL
      setPdfMode(false); // Reset PDF mode after generation
      setShowViewer(true); // Show the viewer
    })
    .catch(() => {
      setPdfMode(false); // Reset PDF mode on error
    });
};


function toggleAllPermissions(iconId) {
  const allPermissions = permissionIcons.filter(
    (permission) => !isPermissionDisabled(iconId, permission.id)
  );

  const areAllSelected = allPermissions.every(
    (permission) => selectedPermissions[iconId]?.[permission.id]
  );

  // Toggle the state for each permission
  allPermissions.forEach((permission) => {
    handlePermissionChange(iconId, permission.id, !areAllSelected);
  });

  // Update the state of the checkbox explicitly if required.
  setCheckboxState((prevState) => ({
    ...prevState,
    [iconId]: !areAllSelected,
  }));
}


function isPermissionDisabled(iconId, permissionId) {
  return userType == 1 && (
    (iconId === 108 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permissionId)) ||
    (iconId === 109 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permissionId)) ||
    (iconId === 110 && permissionId === 17) ||
    (iconId === 111 && [1, 2, 4, 13, 10, 17, 18].includes(permissionId)) ||
    (iconId === 112 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permissionId)) ||
    (iconId === 113 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permissionId)) ||
    (iconId === 114 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permissionId))
  );
}



  return (
    <div>
      <Header
        title={`Admin > User & SOD > Roles > ${isViewMode ? 'View' : isEditMode ? 'Edit' : 'Add'}`}
        onBreadcrumbClick={handleBreadcrumbClick}
      />

<div className="px-5 py-5 mb-5">
<div className={`max-w-8xl ${pdfMode ? "text-[10px]" : "text-base"}`}>
    <div className="py-1 px-3 grid grid-cols-[1fr_1fr_1fr_auto] gap-x-4 items-center">
      {/* Role ID Field */}
      <label className="flex items-center">
        <span className="w-32 text-sm font-medium text-customGray">
          Role ID<span className="text-red-500 ml-1">*</span>
        </span>
        <span className="ml-2">:</span>
        <input
          type="text"
          value={roleID}
          onChange={handleRoleIdChange}
          className={`border p-1.5 rounded w-full h-8 text-customGray focus:outline-none hover:border-blue-400 ml-2 ${
            errors.roleID ? "border-red-500" : "border-gray-300"
          }`}
          placeholder="Enter role ID"
          required
          disabled={isViewMode || isEditMode}
        />
      </label>

      {/* Role Name Field */}
      <label className="flex items-center">
        <span className="w-32 text-sm font-medium text-customGray">
          Role Name<span className="text-red-500 ml-1">*</span>
        </span>
        <span className="ml-2">:</span>
        <input
          type="text"
          value={roleName}
          onChange={handleRoleNameChange}
          className={`border p-1.5 rounded w-full h-8 text-customGray focus:outline-none hover:border-blue-400 ml-2 ${
            errors.roleName ? "border-red-500" : "border-gray-300"
          }`}
          placeholder="Enter role name"
          required
          disabled={isViewMode}
        />
      </label>

      {/* User Type Field */}
      <label className="flex items-center border-r-2 pr-4">
        <span className="w-32 text-sm font-medium text-customGray">
          User Type<span className="text-red-500 ml-1">*</span>
        </span>
        <span className="ml-2">:</span>
        <select
          value={userType}
          onChange={(e) => {
            const value = e.target.value;
            setUserType(value == "0" ? null : value);
          }}
          disabled={isViewMode || isEditMode}
          className={`border p-1 rounded w-full h-8 text-customGray focus:outline-none hover:border-blue-400 ml-2 ${
            errors.userType ? "border-red-500" : "border-gray-300"
          } ${isViewMode|| isEditMode ? "bg-gray-100" : ""}`}
        >
          <option value="">Select user type</option>
          {userTypes.map((type) => (
            <option key={type.id} value={type.id}>
              {type.name}
            </option>
          ))}
        </select>
      </label>

      {/* Print Button */}
      <div className="flex justify-end">
      <button
              className="flex items-center justify-center bg-white text-sm border border-gray-300 text-customGray py-2 px-4 rounded hover:bg-gray-100 transition duration-200"
              onClick={() => {
                // toggleAllMenus(); // Toggle menu expansion
                previewPDF(); // Download the PDF
              }}
            >
              <IoPrint className="w-5 h-5 text-gray-500" />
            </button>
      </div>
    </div>

{/* Filters */}
<div className=" py-1.5 px-3 grid grid-cols-[1fr_1fr_1fr_auto] gap-x-4 gap-y-4 items-center shadow-sm mb-6">
  {/* Module Field */}
  {/* <div className="flex items-center"> 
     <label className="w-32 text-sm font-medium text-customGray">Module</label>
    <span className="ml-2">:</span>
    <button
      onClick={toggleModalForModule}
      className="border border-gray-300 p-1.5 rounded w-full h-8 focus:outline-none hover:border-blue-400 ml-2 flex items-center justify-between text-customGray"
    >
      <span>{mainMenu || 'Select Module Name'}</span>
      <RxDropdownMenu className="ml-2" />
    </button>


    {isModalOpenForModule && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                  <div className="bg-white p-0 w-[800px] h-[400px] flex flex-col rounded-2xl overflow-hidden">

                    <div className="flex justify-between items-center p-4 shadow shadow-blue-100 bg-white sticky top-0 z-10">
                      <h2 className="text-lg font-semibold text-customGray">Module</h2>
                      <input
                        type="text"
                        placeholder="Search Module Name..."
                        value={searchQueryMainmodule}
                        onChange={handleSearchChangeMainmodule}
                        className="border border-gray-300 p-1 rounded text-customGray w-80 ml-60 placeholder-gray-500 focus:outline-none focus:border-customBlue focus:ring-1 focus:ring-customBlue"
                      />
                      <button
                        onClick={() => {
                          toggleModalForModule();
                        }}
                        className="text-gray-500 hover:text-gray-700 text-2xl"
                      >
                        &times;
                      </button>
                    </div>
                    <div className="flex-1 overflow-auto p-4">

                      {Icons.length > 0 ? (
                        <div className="grid grid-cols-3 gap-4">
                          {Icons.
                          filter((Icon) => Icon.name.toLowerCase().includes(searchQueryMainmodule.toLowerCase()))
                            .map((Icon, index) => (
                              <div key={Icon.id} className={`flex items-center ${index % 2 === 0 ? 'bg-sky-blue-200' : ''}`}>
                                <input
                                  type="radio"
                                  name="country"
                                  value={Icon.name}
                                  checked={selectMainMenu === Icon.name}
                                  onChange={handleCheckboxChangeForModule}
                                  className="mr-2"
                                />
                                <label className="text-customGray">{Icon.name}</label>
                              </div>
                          ))}
                        </div>
                      ) : (
                        <p>Icons Not Found...</p>
                      )}
                    </div>
                    <div className="flex flex-row justify-end p-2 bg-white sticky bottom-0 border-t">
                    <button
                        onClick={() => {
                          setMainMenu('');
                          setselectMainMenu('');
                        }}
                        className="mt-2 bg-white text-sm border border-customBlue text-customBlue py-1 px-2 rounded hover:bg-customBlue hover:text-white transition duration-200 ease-in-out mr-5"
                      >
                        Clear
                      </button>

                      <button
                        onClick={toggleModalForModule}
                        className="mt-2 bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 mr-20"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              )} 
        </div> */}

<LabelWithButton
        label="Module"
        labelwidth='w-32'
        onClick={ ()=> {setIsModalOpenForModule(true);}   
        }
        buttonText={mainMenu || 'Select Module Name'}
        marginLeft="ml-2"   // Set custom margin-left
        width='w-full'
      />

<ReusableModal
  title="Select Module Name"
  isOpen={isModalOpenForModule}
  onClose={() => {
    setIsModalOpenForModule(false);
    setSearchQueryMainmodule('');
  }}
  onSave={() => {
    setIsModalOpenForModule(false);
    setSearchQueryMainmodule('');
  }}
  onReset={()=>{
    setMainMenu('');
    setselectMainMenu('');
    setSubMenu("");
    setselectSubMenu("");
    setIconMenu("");
    setselectIconMenu("");
  }}
  showReset={true} // Only show Reset button in this modal
  searchPlaceholder="Search Module Name..."
  searchQuery={searchQueryMainmodule}
  onSearchChange={handleSearchChangeMainmodule}
  isViewMode={isViewMode}
>
                      {Icons.length > 0 ? (
                        // <div className="grid grid-cols-3 gap-4">
                        //   {
                          Icons.
                          filter((Icon) => Icon.name.toLowerCase().includes(searchQueryMainmodule.toLowerCase()))
                            .map((Icon, index) => (
                              <div key={Icon.id} className={`flex items-center ${index % 2 === 0 ? 'bg-sky-blue-200' : ''}`}>
                                <input
                                  type="radio"
                                  name="country"
                                  value={Icon.name}
                                  checked={selectMainMenu === Icon.name}
                                  onChange={handleCheckboxChangeForModule}
                                  className="mr-2"
                                />
                                <label className="text-customGray">{Icon.name}</label>
                              </div>
                          ))
                        // }
                        // </div>
                      ) : (
                        <p>Icons Not Found...</p>
                      )}

</ReusableModal>


  {/* Sub Module Field */}
  {/* <div className="flex items-center">
    <label className="w-32 text-sm font-medium text-customGray">Sub Module</label>
    <span className="ml-2">:</span>
    <button
      onClick={toggleModalForSubModule}
      className="border border-gray-300 p-1.5 rounded w-full h-8 focus:outline-none hover:border-blue-400 ml-2 flex items-center justify-between text-customGray"
    >
      <span>{subMenu || 'Select Sub Module Name'}</span>
      <RxDropdownMenu className="ml-2" />
    </button>
    {isModalOpenForSubModule && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                      <div className="bg-white p-0 w-[800px] h-[400px] flex flex-col rounded-2xl overflow-hidden">
                        <div className="flex justify-between items-center p-4 shadow shadow-blue-100 bg-white sticky top-0 z-10">
                          <h2 className="text-lg font-semibold text-customGray">Sub Module</h2>
                          <input
                            type="text"
                            placeholder="Search Sub Module Name..."
                            value={searchQuerySubmodule}
                            onChange={handleSearchChangeSubmodule}
                            className="border border-gray-300 p-1 rounded text-customGray w-80 ml-60 placeholder-gray-500 focus:outline-none focus:border-customBlue focus:ring-1 focus:ring-customBlue"
                          />
                          <button
                            onClick={() => {
                              toggleModalForSubModule();
                            }}
                            className="text-gray-500 hover:text-gray-700 text-2xl"
                          >
                            &times;
                          </button>
                        </div>

                        <div className="flex-1 overflow-auto p-4">
                          {(() => {
                            const matchingMainMenu = filteredIcons.find(
                              (icon) => 
                                icon.name === mainMenu
                            );
                            const allSubMenus = matchingMainMenu?.subMenus || [];
                            console.log("all submenu", allSubMenus);

                            const filteredSubMenus = searchQuerySubmodule
                              ? allSubMenus.filter((sub) =>
                                  sub.name
                                    .toLowerCase()
                                    .includes(searchQuerySubmodule.toLowerCase())
                                )
                              : allSubMenus;

                            return allSubMenus.length > 0 ? (
                              <div className="grid grid-cols-3 gap-4">
                                {filteredSubMenus.map((subMenuItem) => (
                                  <div key={subMenuItem.id} className="flex items-center">
                                    <input
                                      type="radio"
                                      name="subMenu"
                                      value={subMenuItem.name}
                                      checked={selectSubMenu === subMenuItem.name}
                                      onChange={handleCheckboxChangeForSubModule}
                                      className="mr-2"
                                    />
                                    <label className="text-sm text-gray-600">
                                      {subMenuItem.name}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <p>Sub Modules Not Found...</p>
                            );
                          })()}
                        </div>

                        <div className="flex flex-row justify-end p-2 bg-white sticky bottom-0 border-t">
                      <button
                          onClick={() => {
                            setSubMenu("");
                            setselectSubMenu("");
                          }}
                          className="mt-2 bg-white text-sm border border-customBlue text-customBlue py-1 px-2 rounded hover:bg-customBlue hover:text-white transition duration-200 ease-in-out mr-5"
                        >
                          Clear
                        </button>

                        <button
                          onClick={toggleModalForSubModule}
                          className="mt-2 bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 mr-20"
                        >
                          Save
                        </button>
                      </div>
                      </div>
                    </div>
                  )}

  </div> */}

<LabelWithButton
        label="Sub Module"
        labelwidth='w-32'
        onClick={ ()=> {setIsModalOpenForSubModule(true);}   
        }
        buttonText={subMenu || 'Select Sub Module Name'}
        marginLeft="ml-2"   // Set custom margin-left
        width='w-full'
      />


<ReusableModal
  title="Select Sub Module Name"
  isOpen={isModalOpenForSubModule}
  onClose={() => {
    setIsModalOpenForSubModule(false);
    setSearchQuerySubmodule('');
  }}
  onSave={() => {
    setIsModalOpenForSubModule(false);
    setSearchQuerySubmodule('');
  }}
  onReset={()=>{
    setSubMenu("");
    setselectSubMenu("");
    setIconMenu("");
    setselectIconMenu("");
  }}
  showReset={true} // Only show Reset button in this modal
  searchPlaceholder="Search Sub Module Name..."
  searchQuery={searchQuerySubmodule}
  onSearchChange={handleSearchChangeSubmodule}
  isViewMode={isViewMode}
>
                        {(() => {
                            const matchingMainMenu = filteredIcons.find(
                              (icon) => 
                                icon.name === mainMenu
                            );
                            const allSubMenus = matchingMainMenu?.subMenus || [];
                            console.log("In submodule get all submenus data", allSubMenus);


                            
                            const filteredSubMenus = searchQuerySubmodule
                              ? allSubMenus.filter((sub) =>
                                  sub.name
                                    .toLowerCase()
                                    .includes(searchQuerySubmodule.toLowerCase())
                                )
                              : allSubMenus;

                            return allSubMenus.length > 0 ? (
                              // <div className="grid grid-cols-3 gap-4">
                              //   {
                                filteredSubMenus.map((subMenuItem) => (
                                  <div key={subMenuItem.id} className="flex items-center">
                                    <input
                                      type="radio"
                                      name="subMenu"
                                      value={subMenuItem.name}
                                      checked={selectSubMenu === subMenuItem.name}
                                      onChange={handleCheckboxChangeForSubModule}
                                      className="mr-2"
                                    />
                                    <label className="text-sm text-gray-600">
                                      {subMenuItem.name}
                                    </label>
                                  </div>
                                ))
                              // }
                              // </div>
                            ) : (
                              <p>Sub Modules Not Found...</p>
                            );
                          })()}

</ReusableModal>


  {/* Icons Field */}
  {/* <div className="flex items-center border-r-2 pr-4">
    <label className="w-32 text-sm font-medium text-customGray">Icons</label>
    <span className="ml-2">:</span>
    <button
      onClick={toggleModalForIconModule}
      className="border border-gray-300 p-1.5 rounded w-full h-8 focus:outline-none hover:border-blue-400 ml-2 flex items-center justify-between text-customGray"
    >
      <span>{iconMenu || 'Select Icon Name'}</span>
      <RxDropdownMenu className="ml-2" />
    </button>
    {isModalOpenForIconModule && (
                  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-0 w-[800px] h-[400px] flex flex-col rounded-2xl overflow-hidden">
                    
                      <div className="flex justify-between items-center p-4 shadow shadow-blue-100 bg-white sticky top-0 z-10">
                        <h2 className="text-lg font-semibold text-customGray">Icons</h2>
                        <input
                          type="text"
                          placeholder="Search Icon Name..."
                          value={searchQueryIconmodule}
                          onChange={handleSearchChangeIconmodule}
                          className="border border-gray-300 p-1 rounded text-customGray w-80 ml-60 placeholder-gray-500 focus:outline-none focus:border-customBlue focus:ring-1 focus:ring-customBlue"
                        />
                        <button
                          onClick={() => {
                            toggleModalForIconModule();
                          
                          }}
                          className="text-gray-500 hover:text-gray-700 text-2xl"
                        >
                          &times;
                        </button>
                      </div>

                     
                      <div className="flex-1 overflow-auto p-4">
                {(() => {
                  // Log filteredIcons and subMenu to verify the data
                  console.log("Filtered Icons:", filteredIcons);
                  console.log("Selected SubMenu:", subMenu);

                  const matchingMainMenu = filteredIcons.find(
                  (icon) => 
                    icon.subMenus && icon.subMenus.some(subMenuItem => subMenuItem.name === subMenu)
                );

                // Log the matched main menu
                console.log("icons menu:", matchingMainMenu);

                const matchingSubMenu = matchingMainMenu?.subMenus?.find(
                  (subMenuItem) => subMenuItem.name === subMenu
                );

                // Log the matched submenu
                console.log("Matching SubMenu:", matchingSubMenu);

                  const allIcons = matchingSubMenu?.icons || [];
                  
                  // Log all icons under the selected submenu
                  console.log("All Icons:", allIcons);

                  // Filter icons based on the search query
                  const filteredIconsList = searchQueryIconmodule
                    ? allIcons.filter((icon) =>
                        icon.name
                          .toLowerCase()
                          .includes(searchQueryIconmodule.toLowerCase())
                      )
                    : allIcons;

                  // Log the filtered icons list after search filtering
                  console.log("Filtered Icons List:", filteredIconsList);

                  // Check if there are any icons to display
                  return allIcons.length > 0 ? (
                    <div className="grid grid-cols-3 gap-4">
                      {filteredIconsList.map((iconItem) => (
                        <div key={iconItem.id} className="flex items-center">
                          <input
                            type="radio"
                            name="iconMenu"
                            value={iconItem.name}
                            checked={selectIconMenu === iconItem.name}
                            onChange={handleCheckboxChangeForIconModule}
                            className="mr-2"
                          />
                          <label className="text-sm text-gray-600">
                            {iconItem.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>Icons Not Found...</p>
                  );
                })()}
              </div>

              <div className="flex flex-row justify-end p-2 bg-white sticky bottom-0 border-t">
                        <button
                            onClick={() => {
                              // toggleModalForIconModule();
                              setIconMenu("");
                              setselectIconMenu("");
                            setSelectedSubMenu("");
                            }}
                            className="mt-2 bg-white text-sm border border-customBlue text-customBlue py-1 px-2 rounded hover:bg-customBlue hover:text-white transition duration-200 ease-in-out mr-5"
                          >
                            Clear
                          </button>

                          <button
                            onClick={toggleModalForIconModule}
                            className="mt-2 bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 mr-20"
                          >
                            Save
                          </button>
                        </div>
                    </div>
                  </div>
                )}

  </div> */}

<LabelWithButton
        label="Icons"
        className = "border-r-2 pr-4"
        labelwidth='w-32'
        onClick={ ()=> {setIsModalOpenForIconModule(true);}   
        }
        buttonText={iconMenu || 'Select Icon Name'}
        marginLeft="ml-2"   // Set custom margin-left
        width='w-full'
      />

<ReusableModal
  title="Select Icon Name"
  isOpen={isModalOpenForIconModule}
  onClose={() => {
    setIsModalOpenForIconModule(false);
    setSearchQueryIconmodule('');
  }}
  onSave={() => {
    setIsModalOpenForIconModule(false);
    setSearchQueryIconmodule('');
  }}
  onReset={()=>{
    // setSubMenu("");       // Clear the previously selected submenu
    // setselectSubMenu(""); // Clear submenu selection
    setIconMenu("");
    setselectIconMenu("");
  }}
  // showReset={true} // Only show Reset button in this modal
  searchPlaceholder="Search Icon Name..."
  searchQuery={searchQueryIconmodule}
  onSearchChange={handleSearchChangeIconmodule}
  isViewMode={isViewMode}
>
            {(() => {
                  // Log filteredIcons and subMenu to verify the data
                  console.log("Getting all / Selected Main Menu Icons:", filteredIcons);
                  console.log("Selected SubMenu:", subMenu);
                  console.log("Selected Icon Menu:", iconMenu);



                  const matchingMainMenu = filteredIcons.find(
                  (icon) => 
                   icon.name === mainMenu
                );

                // Log the matched main menu
                console.log("In Icon Module get all submenus data:", matchingMainMenu);


                const matchingSubMenu = matchingMainMenu?.subMenus?.find(
                  (subMenuItem) => subMenuItem.name === subMenu
                );

                // Log the matched submenu
                console.log("In Icon Module get all Icons data:", matchingSubMenu);

                

                  const allIcons = matchingSubMenu?.icons || [];
                  
                  // Log all icons under the selected submenu
                  console.log("In Icon module get All Icons:", allIcons);




                  // Filter icons based on the search query
                  const filteredIconsList = searchQueryIconmodule
                    ? allIcons.filter((icon) =>
                        icon.name
                          .toLowerCase()
                          .includes(searchQueryIconmodule.toLowerCase())
                      )
                    : allIcons;

                  // Log the filtered icons list after search filtering
                  console.log("Filtered Icons List:", filteredIconsList);

                  // Check if there are any icons to display
                  return allIcons.length > 0 ? (
                    // <div className="grid grid-cols-3 gap-4">
                    //   {
                      filteredIconsList.map((iconItem) => (
                        <div key={iconItem.id} className="flex items-center">
                          <input
                            type="radio"
                            name="iconMenu"
                            value={iconItem.name}
                            checked={selectIconMenu === iconItem.name}
                            onChange={handleCheckboxChangeForIconModule}
                            className="mr-2"
                          />
                          <label className="text-sm text-gray-600">
                            {iconItem.name}
                          </label>
                        </div>
                      ))
                    // }
                    // </div>
                  ) : (
                    <p>Icons Not Found...</p>
                  );
                })()}

</ReusableModal>


  {/* Expand Button */}
  <div className="flex justify-end">
  <button
    className="flex items-center justify-center bg-white text-sm border border-gray-300 text-customGray py-2 px-4 rounded hover:bg-gray-100 transition duration-200"
    onClick={toggleAllMenus} // Add this onClick to trigger the toggle
  >
    {allExpanded ? (
      <IoChevronForward className="w-5 h-5 text-gray-500" />
    ) : (
      <IoChevronDown className="w-5 h-5 text-gray-500" />
    )}
  </button>
  </div>

</div>



          {/* Permission Data started */}
          <div className="mb-6 bg-white rounded-lg shadow-sm">
  {filteredIcons.map((mainMenu) => (
    <div key={mainMenu.id} className="mb-4">
      {/* Main Menu Level */}
      <div
        className="bg-blue-50 px-2 py-1.5 flex items-center justify-start cursor-pointer rounded space-x-2"
        onClick={() => toggleMainMenu(mainMenu.id)}
      >
        {allExpanded || expandedMainMenu === mainMenu.id ? (
          <IoChevronDown className="w-5 h-5 text-gray-500" />
        ) : (
          <IoChevronForward className="w-5 h-5 text-gray-500" />
        )}
        <span className="text-md font-medium text-customGray">{mainMenu.name}</span>
      </div>

      {/* Submenus */}
      {(allExpanded || expandedMainMenu === mainMenu.id || searchTerm) &&
        mainMenu.subMenus.map((subMenu) => (
          <div key={subMenu.id} className="ml-4 mt-2">
            {/* Submenu Level */}
            <div
              className="bg-blue-50 px-2 py-1.5 flex items-center justify-start cursor-pointer rounded space-x-2"
              onClick={() => toggleSubMenu(mainMenu.id, subMenu.id)} // Handle submenu toggle
            >
              {allExpanded || expandedSubMenu[mainMenu.id] === subMenu.id ? (
                <IoChevronDown className="w-5 h-5 text-gray-500" />
              ) : (
                <IoChevronForward className="w-5 h-5 text-gray-500" />
              )}
              <span className="text-md font-medium text-customGray">{subMenu.name}</span>
            </div>

            {/* Icons and expanded submenu content */}
            {(allExpanded || expandedSubMenu[mainMenu.id] === subMenu.id || searchTerm) && (
              <div className="ml-8 mt-2">
                {/* Icons Level */}
                {subMenu.icons.map((icon) => (
                  <div key={icon.id} className="flex flex-col mt-2">
                    <div
                      className="bg-blue-50 px-2 py-1.5 flex items-center justify-start cursor-pointer rounded space-x-2"
                      onClick={() => toggleIcon(icon.id)} // Toggle icon
                    >
                      {allExpanded || expandedIcon.includes(icon.id) ? (
                        <IoChevronDown className="w-5 h-5 text-gray-500" />
                      ) : (
                        <IoChevronForward className="w-5 h-5 text-gray-500" />
                      )}
                      <span className="text-md font-medium text-customGray">
                        {icon.name}
                      </span>
                      <input
                        type="checkbox"
                        className="h-4 w-4 text-blue-600 border-gray-300 rounded"
                        checked={selectedIcons[icon.id] || false}
                        onClick={(e) => e.stopPropagation()}
                        disabled={isViewMode }
                        onChange={() => handleIconChange(icon.id)}
                      />

                       {/* "All" Checkbox */}
                       <div className="flex items-center space-x-2 px-5 ml-5">
                        
                      <span className="text-md font-medium text-customGray">
                        ALL
                      </span>
                       <input
                          type="checkbox"
                          className="h-4 w-4 text-blue-600 border-gray-300 rounded ml-4"
                          onClick={(e) => e.stopPropagation()} //stop expand or collapse
                          checked={
                            checkboxState[icon.id] !== undefined
                              ? checkboxState[icon.id] // Use explicit checkbox state if available
                              : Object.keys(selectedPermissions[icon.id] || {}).length ===
                                permissionIcons.filter(
                                  (permission) => !isPermissionDisabled(icon.id, permission.id)
                                ).length
                          }
                          onChange={() => {
                            toggleAllPermissions(icon.id);
                          }}
                          disabled={isViewMode || !selectedIcons[icon.id]}
                        />
                        </div>

                    </div>

                    {/* Expanded content for icons */}
                    {allExpanded || expandedIcon.includes(icon.id) ? (
                      <div className="p-4 grid grid-cols-4 gap-4 items-center bg-white rounded">
                        {permissionIcons.map((permission) => {
                          const isDisabled =
                            userType == 1 &&
                            (
                              (icon.id === 21 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                              (icon.id === 22 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id))||
                              (icon.id === 23 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                              (icon.id === 24 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                              (icon.id === 25 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||

                              (icon.id === 44 && [17].includes(permission.id)) ||
                              (icon.id === 45 && [1, 2, 4, 13, 17].includes(permission.id)) ||

                              (icon.id === 108 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id)) ||
                              (icon.id === 109 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id)) ||
                              (icon.id === 110 && permission.id === 17) ||
                              (icon.id === 111 && [1, 2, 4, 13 ,10 , 17, 18].includes(permission.id)) ||
                              (icon.id === 112 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id))||
                              (icon.id === 113 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id))||
                              (icon.id === 114 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id)) ||
                              (icon.id === 115 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id)) 

                            )||
                            (userType == 3 &&
                              (
                                // Country
                                (icon.id === 1 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                // State provice
                                (icon.id === 2 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                // Calender
                                (icon.id === 3 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                // Currency
                                (icon.id === 4 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                // Bank
                                (icon.id === 5 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                // Language
                                (icon.id === 6 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                // Legal entity
                                (icon.id === 7 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                // Bu group
                                (icon.id === 8 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                // Company
                                (icon.id === 9 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                // Bank Id
                                (icon.id === 10 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                // Branch
                                (icon.id === 11 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                // Account status
                                (icon.id === 21 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                // Account no logic
                                (icon.id === 22 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id))||
                                // ZBA type
                                (icon.id === 23 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                // DB area
                                (icon.id === 24 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                // Account types
                                (icon.id === 25 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                // Bank account master
                                (icon.id === 26 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                // Statement controls
                                (icon.id === 44 && [17].includes(permission.id)) ||
                                // Statement format
                                (icon.id === 45 && [1, 2, 4, 13, 17].includes(permission.id)) ||
                                // Statement identifier
                                (icon.id === 46 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                // Mt940 uploads
                                (icon.id === 47 && [17].includes(permission.id)) ||
                                // BTC
                                (icon.id === 48 && [1, 2, 10, 17].includes(permission.id)) ||
                                // Statement analyser
                                (icon.id === 49 && [3, 17, 18].includes(permission.id)) ||
                                // Statement analyser 2
                                (icon.id === 50 && [3, 17, 18].includes(permission.id)) ||

                                // User Icon
                                (icon.id === 108 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                // Key data Icon
                                (icon.id === 109 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                // Maker and checker Icons
                                (icon.id === 110 && permission.id === 17) ||
                                // Roles Icon
                                (icon.id === 111 && [1, 2, 4, 13 ,10 , 17, 18].includes(permission.id)) ||
                                // Permission Icon
                                (icon.id === 112 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                
                                (icon.id === 113 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id))||
                              (icon.id === 114 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id)) ||
                              (icon.id === 115 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id)) )

                            )||
                            (userType == 4 &&
                              (
                                // Country
                                (icon.id === 1 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                // State provice
                                (icon.id === 2 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                // Calender
                                (icon.id === 3 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                // Currency
                                (icon.id === 4 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                // Bank
                                (icon.id === 5 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                // Language
                                (icon.id === 6 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                // Legal entity
                                (icon.id === 7 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                // Bu group
                                (icon.id === 8 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                // Company
                                (icon.id === 9 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                // Bank Id
                                (icon.id === 10 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                // Branch
                                (icon.id === 11 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                // Account status
                                (icon.id === 21 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                // Account no logic
                                (icon.id === 22 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id))||
                                // ZBA type
                                (icon.id === 23 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                // DB area
                                (icon.id === 24 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                // Account types
                                (icon.id === 25 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                // Bank account master
                                (icon.id === 26 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                // Statement controls
                                (icon.id === 44 && [17].includes(permission.id)) ||
                                // Statement format
                                (icon.id === 45 && [1, 2, 4, 13, 17].includes(permission.id)) ||
                                // Statement identifier
                                (icon.id === 46 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                // Mt940 uploads
                                (icon.id === 47 && [17].includes(permission.id)) ||
                                // BTC
                                (icon.id === 48 && [1, 2, 17].includes(permission.id)) ||
                                // Statement analyser
                                (icon.id === 49 && [3, 17, 18].includes(permission.id)) ||
                                // Statement analyser 2
                                (icon.id === 50 && [3, 17, 18].includes(permission.id))
                              
                              )
                            )||
                            (userType == 8 &&
                              (
                                // Country
                                (icon.id === 1 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                // State provice
                                (icon.id === 2 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                // Calender
                                (icon.id === 3 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                // Currency
                                (icon.id === 4 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                // Bank
                                (icon.id === 5 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                // Language
                                (icon.id === 6 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                // Legal entity
                                (icon.id === 7 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                // Bu group
                                (icon.id === 8 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                // Company
                                (icon.id === 9 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                // Bank Id
                                (icon.id === 10 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                // Branch
                                (icon.id === 11 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                // Account status
                                (icon.id === 21 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                // Account no logic
                                (icon.id === 22 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id))||
                                // ZBA type
                                (icon.id === 23 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                // DB area
                                (icon.id === 24 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                // Account types
                                (icon.id === 25 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                // Bank account master
                                (icon.id === 26 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                // Statement controls
                                (icon.id === 44 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                // Statement format
                                (icon.id === 45 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                // Statement identifier
                                (icon.id === 46 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                // Mt940 uploads
                                (icon.id === 47 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                // BTC
                                (icon.id === 48 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                // Statement analyser
                                (icon.id === 49 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                // Statement analyser 2
                                (icon.id === 50 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||

                                 // Key data Icon
                                 (icon.id === 109 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                 // Maker and checker Icons
                                 (icon.id === 110 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                 // Roles Icon
                                 (icon.id === 111 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id))
                                
                              
                              )
                            )
                            ;

                          const isChecked = !isDisabled && (selectedPermissions[icon.id]?.[permission.id] || false);

                          const handleCheckboxChange = () => {
                            if (!isDisabled) {
                              handlePermissionChange(icon.id, permission.id, !isChecked);
                            }
                          };

                          return (
                            // Below the Action Icons are Not Needed, Permission table Id
                            (icon.id === 1 && [4, 5, 6, 7, 8, 9, 11, 12, 13, 15].includes(permission.id)) ||
                            (icon.id === 2 && [4, 5, 6, 7, 8, 9, 11, 12, 13, 15].includes(permission.id)) ||
                            (icon.id === 3 && [4, 5, 6, 7, 8, 9, 11, 12, 13, 15].includes(permission.id)) ||
                            (icon.id === 4 && [4, 5, 6, 7, 8, 9, 11, 12, 13, 15].includes(permission.id)) ||
                            (icon.id === 5 && [4, 5, 6, 7, 8, 9, 11, 12, 13, 15].includes(permission.id)) ||
                            (icon.id === 6 && [4, 5, 6, 7, 8, 9, 11, 12, 13, 15].includes(permission.id)) ||
                            (icon.id === 7 && [8, 12].includes(permission.id)) ||
                            (icon.id === 8 && [8, 12].includes(permission.id)) ||
                            (icon.id === 9 && [8, 12].includes(permission.id)) ||
                            (icon.id === 10 && [8, 12].includes(permission.id)) ||
                            (icon.id === 11 && [8, 12].includes(permission.id)) ||
                            (icon.id === 21 && [4, 5, 6, 7, 8, 9, 11, 12, 18].includes(permission.id)) ||
                            (icon.id === 22 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18].includes(permission.id)) ||
                            (icon.id === 23 && [4, 5, 6, 7, 8, 9, 11, 12].includes(permission.id)) ||
                            (icon.id === 24 && [4, 5, 6, 7, 8, 9, 11, 12].includes(permission.id)) ||
                            (icon.id === 25 && [4, 5, 6, 7, 8, 9, 11, 12].includes(permission.id)) ||
                            (icon.id === 26 && [8, 12].includes(permission.id)) ||
                            
                            (icon.id === 44 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 18].includes(permission.id)) ||
                            (icon.id === 45 && [4, 5, 6, 7, 8, 9, 11, 12, 18].includes(permission.id)) ||
                            (icon.id === 46 && [2, 4, 5, 6, 7, 8, 11, 12].includes(permission.id)) ||
                            (icon.id === 47 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18].includes(permission.id)) ||
                            (icon.id === 48 && [4, 5, 6, 7, 8, 9, 11, 12, 13, 18].includes(permission.id)) ||
                            (icon.id === 49 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].includes(permission.id)) ||
                            (icon.id === 50 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].includes(permission.id)) ||

                            (icon.id === 110 && [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 16, 18].includes(permission.id)) ||
                            (icon.id === 112 && [8, 9, 12].includes(permission.id)) ||
                            (icon.id === 113 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18].includes(permission.id)) ||
                            (icon.id === 114 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18].includes(permission.id)) ||
                            (icon.id === 115 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18].includes(permission.id)) ||

                            (icon.id === 109 && [8, 9, 12].includes(permission.id)) ||
                            (icon.id === 111 && [8, 9, 12].includes(permission.id)) ? null : (
                              <div key={`${icon.id}-${permission.id}`} className="flex items-center space-x-2">
                                <input
                                  type="checkbox"
                                  className="h-4 w-4 text-blue-600 border-gray-300 rounded"
                                  disabled={isDisabled || isViewMode || !selectedIcons[icon.id]}
                                  checked={isChecked}
                                  onChange={handleCheckboxChange}
                                />
                                <span className="text-md text-customGray">{permission.name}</span>
                                <span className="ml-2 text-customGray">{iconMapping[permission.name] || null}</span>
                              </div>
                            )
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
    </div>
  ))}
</div>



        </div>
      </div>


      <div>
    {/* Render other components */}
    {showViewer && (
      <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white rounded-lg p-4 shadow-lg w-11/12 h-5/6">
          <iframe
            src={pdfUrl}
            title="PDF Viewer"
            className="w-full h-full border-0"
          ></iframe>
          <div className="mt-2 flex justify-end">
            <button
              onClick={() => setShowViewer(false)}
              className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    )}
  </div>

    <Footer> 
    {(!isViewMode && (

      <ButtonGroup
        onSave={handleSubmit}
        onSaveContinue={handleSaveContinueDetails}
      /> 
      ))}

    </Footer>

    </div>
  )
}
