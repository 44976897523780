import RestService from "./RestServices";
const basePath = "/home";

const HomeService = {
  getexpired: (userId) => {
    return RestService.GetAllData(
      `${basePath}/get-password-expire?id=${userId}`
    );
  },
  getOtpVerify: (id, password) => {
    return RestService.GetAllData(
      `${basePath}/get-verify-otp?id=${id}&password=${password}`
    );
  },
  validateNewPassword: (password) => {
    console.log("Password:", password);
    return RestService.GetAllData(
      `${basePath}/verify-new-password?password=${encodeURIComponent(password)}`
    );
  },
};

export default HomeService;
