import RestService from "./RestServices";

const SetUpServices = {
  getAccountNumberLogic: (id) => {
    return RestService.GetAllData(`/get-account-number-logic?clientId=${id}`);
  },
  updateAccountNumberLogic: async (id, data) => {
    return RestService.UpdateData(`/account-number-logic/${id}`, data);
  },

  getAccountStatus: (clientId) => {
    return RestService.GetAllData(`/get-account-status?clientId=${clientId}`);
  },

  // Stores a new account status
  storeAccountStatus: (data) => {
    return RestService.CreateData(`/insert-account-status`, data); // Assuming CreateData sends a POST request
  },

  // Updates an existing account status by ID
  updateAccountStatus: (data) => {
    return RestService.UpdateData(`/update-account-status`, data); // Assuming UpdateData sends a PUT request
  },
};

export default SetUpServices;
