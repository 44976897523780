import React from 'react'

import { CashFlowbuttonsConfigs } from '../../../Components/CashFlowbuttonsConfig'
import NoActionLayout from '../../../../../../components/NoActionLayout';

export default function MappingRuleAdd() {
    const buttons=CashFlowbuttonsConfigs.screen1;

  return (
    <div>
       <div>
        <NoActionLayout title="Cash Flow > Set Up > Mapping Rule ID > Add" />
      </div>
      
<div className='p-12'>
      <div className="space-y-6  bg-blue-50 p-4 rounded-md border border-blue-200">
      <h5 className='font-medium text-gray-600'>Mapping Rule</h5>
<div className="grid grid-cols-2 gap-x-8 gap-y-6">
  <label className="flex items-center group">
    <span className="w-48 text-sm font-medium text-gray-500">Mapping Rule ID</span>
    <span className="ml-4">:</span>
    <input
      type="text"
      className="border border-gray-300 p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
      placeholder="Enter main head"
    />
  </label>
  
  <label className="flex items-center group">
    <span className="w-48 text-sm font-medium text-gray-500">Description</span>
    <span className="ml-4">:</span>
    <input
      type="text"
      className="border border-gray-300 p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
      placeholder="Enter sub heads" 
    />
  </label>
  
  
</div>


</div>

<div className="space-y-6  bg-blue-50 p-4 rounded-md mt-4  border border-blue-200">
      <h5 className='font-medium text-gray-600'>Applicability</h5>
<div className="grid grid-cols-2 gap-x-8 gap-y-6">
  <label className="flex items-center group">
    <span className="w-48 text-sm font-medium text-gray-500">Company Code</span>
    <span className="ml-4">:</span>
    <input
      type="text"
      className="border border-gray-300 p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
      placeholder="Enter main head"
    />
  </label>
  
  <label className="flex items-center group">
    <span className="w-48 text-sm font-medium text-gray-500">Bank ID</span>
    <span className="ml-4">:</span>
    <input
      type="text"
      className="border border-gray-300 p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
      placeholder="Enter sub heads"
    />
  </label>
  
  <label className="flex items-center group">
    <span className="w-48 text-sm font-medium text-gray-500">Account ID</span>
    <span className="ml-4">:</span>
    <input
      type="text"
      className="border border-gray-300 p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
      
    />
  </label>
  
  <label className="flex items-center group">
    <span className="w-48 text-sm font-medium text-gray-500">Formal Type</span>
    <span className="ml-4">:</span>
    <input
      type="text"
      className="border border-gray-300 p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
      placeholder="Enter additional info"
    />
  </label>
</div>


</div>
<div className="space-y-6  bg-blue-50 p-4 rounded-md border border-blue-200 mt-4">
      <h5 className='font-medium text-gray-600'>Controls</h5>
<div className="grid grid-cols-2 gap-x-8 gap-y-6">
  <label className="flex items-center group">
  <input
    type="checkbox"
    className="  form-checkbox   text-blue-500 border-blue-500 rounded focus:ring-0 checked:bg-blue-500 checked:border-blue-500"
  />
  <p className="ml-4 font-small text-gray-600">Active</p>

  <input
    type="checkbox"
    className=" ml-36 form-checkbox   text-blue-500 border-blue-500 rounded focus:ring-0 checked:bg-blue-500 checked:border-blue-500"
  />
  <p className="ml-4 font-small text-gray-600">Lock</p>
  </label>
  

  
  
</div>



</div>
<button className=" mt-12 bg-blue-500 text-sm text-white py-1 px-2   rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ">
    Save
  </button>

</div>
    </div>
  )
}
