import React from 'react';
import CustomButton from './CustomButton';
import HoverButton from './HoverButton';
 

export default function ButtonGroup({
  onSave,
  onSaveContinue,
  saveTitle = "Save",
  saveContinueTitle = "Save & Continue",
  hasId, // Pass the ID presence as a prop

}) {
  return (
    <div className="flex flex-row items-center">
     
        <CustomButton
          onClick={onSave}
          title={saveTitle}
          className="w-16 ml-6"
        />
        {!hasId && ( // Render the HoverButton only if `hasId` is falsy
        <HoverButton
          onClick={onSaveContinue}
          title={saveContinueTitle}
          className="ml-4 w-30"
        />
      )}
     
    </div>
  );
}
