import React, { useEffect, useState } from 'react'
import Header from '../../../../../../components/Header'
import { useNavigate, useParams } from 'react-router-dom';
import AdminActions from '../../../Components/AdminActions';
import { IoMdCheckmark, IoMdClose, IoMdCreate, IoMdRefresh, IoMdTime, IoMdTrash } from 'react-icons/io';
import { IoAddCircleOutline, IoSettingsOutline } from 'react-icons/io5';
import { GiSettingsKnobs } from 'react-icons/gi';
import ReTable from '../../../../../../components/Table';
import { AdminActionsConfig } from '../../../Components/ActionConfig';
import { PiUserSwitchLight } from 'react-icons/pi';
import UserService from '../../../../../../rest-services/UserServices';
import { useToast } from '../../../../../../components/toast/toast';
import ColumnSettingsPopup from '../../../../Masterdata/Components/ColumnSettingPopup';
import CustomeLoading from '../../../../../../components/CustomeLoading';
import { VscVmActive } from 'react-icons/vsc';
import { TbFlag3Filled } from 'react-icons/tb';
import SearchInput from '../../../../../../components/SearchInput';

export default function RolesAndPermission(props) {
    const navigate = useNavigate();
  const { iconId } = useParams(); 
  const [searchQuery, setSearchQuery] = useState(""); // Manage search input
  const [filteredUsers, setFilteredUsers] = useState([]);

    const[rolesAndPermission,setRolesAndPermission] = useState([]);
  const [alert, setAlert] = useState(null);
  const [users, setUsers] = useState([]);
  const [isSettingsPopupOpen, setIsSettingsPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();
  const [assignee, setAssignee] = useState({ id: "", username: "" });


  useEffect(() => { 
    console.log("check users filtered", rolesAndPermission);
    console.log("check users data", rolesAndPermission);
  
    if (rolesAndPermission && rolesAndPermission) {
      const filtered = rolesAndPermission.filter((user) =>
        user.id && user.id.toLowerCase().includes(searchQuery.toLowerCase()) 
      );
      setFilteredUsers(filtered);
    }
  }, [searchQuery, rolesAndPermission]);

  
  const handleNavigate = (path) => {
        navigate(path);
      };

      useEffect(() => {
        if (assignee.clientId) {
          fetchRolesAndPermissionDetails();
        }
      }, [assignee]);

      useEffect(() => {
        fetchUsers();
        fetchAssignee();
      }, []);

      
  const fetchAssignee = async () => {
    try {
      const userDetailString = sessionStorage.getItem("userDetail");

      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.username || "",
          assignerId: userDetail.userId || "",
          clientId: userDetail.clientId || "",
        };
        
        setAssignee(assigneeData);
        
        // Log the id and assigner values to the console
        console.log("Assignee ID:", assigneeData.id);
        console.log("Assigner:", assigneeData.assigner);
        console.log("Assigner userId:", assigneeData.assignerId);
        console.log("Assigner clientId:", assigneeData.clientId);
        
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

      const fetchUsers = async () => {
        try {
          const response = await UserService.getUsers(`${process.env.REACT_APP_BASE_URL}/user/get-user`);      
          console.log("Response from server fetching users:", response);
          const responseData = response.data.data;
          console.log('add last seen and users data',responseData);
          setUsers(responseData);
      
          console.log("double check users",response.data)
          if (responseData.error) {
            console.error("Error fetching Users data:", responseData.message);
            setAlert({ severity: "error", message: responseData.message });
            return;
          }
        } catch (error) {
          console.error("Error fetching Users data", error);
          setAlert({ severity: "error", message: `Error fetching Users data, kindly check the connection ${error.message}` });
        }
      };

      const handleUpdateColumnSettings = (updatedSettings) => {
        setColumnSettings(updatedSettings);
      };
      
      const handleEditClick = () => {
        if (selectedRows.length === 1) {
          console.log("entered user id");
          // console.log()
          // const selectedRole = users.find((user) => user.userId === selectedRows[0]);
          // console.log('get roles users', selectedRole);
          console.log('get role user id', selectedRows[0]);
          // console.log('single user id', selectedUser);
      
          if (selectedRows[0]) {
            console.log('selected role data', selectedRows[0]);
            navigate(`/14/admin/112/roles-and-permission/edit/${selectedRows[0]}`,  { state: { mode: 'edit' } } );
          } else {
            toast("warning", "Selected user not found.");
          }
        } else {
          toast("warning", "Please select exactly one user to edit.", {
            duration: 10000, // Set the toast duration to 10 seconds (5000ms)
          });
        }
      };

      const handleAuditTrail =() => {
        if (selectedRows.length === 1) {
          const selectedUser = rolesAndPermission.find((user) => user.userId === selectedRows[0]);
          console.log('user id',  selectedRows[0]);
      
          if ( selectedRows[0]) {
            // Show the selected user's details using toast
            // toast("info", `Selected User: \nID: ${selectedUser.userId}\nName: ${selectedUser.username}`);
            
            // Navigate to the edit screen, passing the selected user as state
            navigate(`/audit-trail-screen/${iconId}/${selectedRows[0]}`);
          } else {
            toast("warning", "Selected user not found.");
          }
        } else {
          toast("warning", "Please select exactly one user to edit.");
        }
      };

      const handleApproveClick = async () => {
        if (selectedRows.length > 0) {
          try {
            const response = await UserService.saveapproved(
              `${process.env.REACT_APP_BASE_URL}/edit-user-permission-approved`,
              { userIds: selectedRows,  createdBy: assignee.assignerId, }  // Pass selectedUserIds in the body
            );
            console.log("Response status:", response.status);
    
            if (response.status === 200) {
              toast("success", "Permission successfully Approved");
              // navigate("/userscreen");
              await fetchRolesAndPermissionDetails();
              // if (refreshTable.current) {
              //   refreshTable.current();  // Call the refresh function to reload the user data
              // }
            }  
            else if (response.status === 400) {
              toast("warning", "Permission is unable to approve.");
             } 
           
             else {
              console.log("Error updating Permission.");
            }
          } catch (error) {
            console.error("Error updating Permission:", error);
          }
        } else {
          console.log("Please select at least one user to Approve.");
        }
      };
      
      const handleCancelClick = async () => {
        if (selectedRows.length > 0) {
          try {
            const response = await UserService.saveapproved(
              `${process.env.REACT_APP_BASE_URL}/edit-rejected-user-permissions`,
              { userIds: selectedRows, createdBy: assignee.assignerId, }  // Pass selectedUserIds in the body
            );
      
            if (response.status === 200) {
              toast("error", "Permission successfully Rejected");
              // navigate("/userscreen");
              await fetchRolesAndPermissionDetails();
            }
            else if (response.status === 400){
              toast("warning", "User is unable to Reject.");
            } else {
              console.log("Error updating users.");
            }
          } catch (error) {
            console.error("Error updating users:", error);
          }
        } else {
          console.log("Please select at least one user to Reject.");
        }
      };

      
      const handleDeleteClick = async () => {
        if (selectedRows.length === 1) {
          try {
            console.log("selected rows :",selectedRows);
            const response = await UserService.saveapproved(
              `${process.env.REACT_APP_BASE_URL}/delete-user-permissions`,
              { userIds: selectedRows, createdBy: assignee.assigner}  // Pass selectedUserIds in the body
            );
      
            if (response.status === 200) {
              toast("error", "User Permissions successfully Deleted.");
    
              // Remove deleted users from the selectedRows
              setSelectedRows((prevSelectedRows) =>
                prevSelectedRows.filter((rowId) => !selectedRows.includes(rowId))
              );
              await fetchRolesAndPermissionDetails();
    
            } else {
              console.log("Error updating users.");
            }
          } catch (error) {
            console.error("Error updating users:", error);
          }
        } else {
          console.log("Please select at least one user to Delete.");
        }
      };

      const handleInactiveClick = async () => {
        if (selectedRows.length > 0) {
          try {
            const response = await UserService.saveapproved(
              `${process.env.REACT_APP_BASE_URL}/edit-inactive-user-permissions`,
              { userIds: selectedRows, createdBy: assignee.assignerId }  // Pass selectedUserIds in the body
            );
      
            if (response.status === 200) {
              toast("success", "User successfully Change Status.");
           
                await fetchRolesAndPermissionDetails();                
            } 
            else if (response.status === 400){
              toast("warning", "The user cannot be deactivated at this time.");
            } 
            else {
              console.log("Error updating users.");
            }
          } catch (error) {
            console.error("Error updating users:", error);
          }
        } else {
          console.log("Please select at least one user to Inactive.");
        }
      };

    const columns = [
      {
        Header: 'Status',
        accessor: 'status',
        visible: true,
        width: 50,
      
        Cell: ({ row }) => {
          const { status } = row.original;
          const colorMap = {
            0: "gray",
            1: "yellow",
            2: "orange",
            3: "#29CB00",
            4: "red"
          };
      
          return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <TbFlag3Filled style={{ color: colorMap[status] || "black" }} />
            </div>
          );
        }
      },
        { Header: 'User Id', accessor: 'id', visible: true, width: 200 },
        // { Header: 'User Type', accessor: 'userType', visible: true, width: 200 },
        { Header: 'User Type', accessor: 'userType', visible: true, width: 200, Cell: ({ row }) => { const user = row.original; return user.userType == 1 ? 'User' : user.userType == 2 ? 'Super User' : user.userType == 3 ? 'Approvals' : user.userType == 4 ? 'Admin' : user.userType == 5 ? 'Consultant' : user.userType == 6 ? 'Support' : user.userType == 7 ? 'System' : user.userType == 8 ? 'KICKSTART' : 'N/A'; } },
        { Header: 'Role ID', accessor: 'roleId', visible: true, width: 200 },
        { Header: 'Role Name', accessor: 'roleName', visible: true, width: 200 },
        // { Header: 'Role User Type', accessor: 'roleUserType', visible: true, width: 200 },
        { Header: 'Role User Type', accessor: 'roleUserType', visible: true, width: 200, Cell: ({ row }) => { const role = row.original; return role.roleUserType == 1 ? 'User' : role.roleUserType == 2 ? 'Super User' : role.roleUserType == 3 ? 'Approvals' : role.roleUserType == 4 ? 'Admin' : role.roleUserType == 5 ? 'Consultant' : role.roleUserType == 6 ? 'Support' : role.roleUserType == 7 ? 'System' : role.roleUserType == 8 ? 'KICKSTART' : 'N/A'; } },
        // { Header: 'Role Status', accessor: 'roleStatus', visible: true, width: 200 },
        {
          Header: 'Role Status',
          accessor: 'roleStatus',
          visible: true,
          width: 50,
        
          Cell: ({ row }) => {
            const { roleStatus } = row.original;
            const colorMap = {
              0: "gray",
              1: "yellow",
              2: "orange",
              3: "#29CB00",
              4: "red"
            };
        
            return (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <TbFlag3Filled style={{ color: colorMap[roleStatus] || "black" }} />
              </div>
            );
          }
        },

        { Header: 'Permissions', accessor: 'permissionsCount', visible: true, width: 200 },
        { Header: 'Created By', accessor: 'createdBy', visible: true, width: 200 },
        // { Header: 'Created Date', accessor: 'createdDate', visible: true, width: 200 },
        { Header: 'Created Date', accessor: 'createdDate' , visible: true, width: 200 , canResize: true, Cell: ({ value }) => { if (!value) return ""; const date = new Date(value); return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;  } },

        { Header: 'Created Time', accessor: 'createdTime', visible: true, width: 200 },
        { Header: 'Updated By', accessor: 'updatedBy', visible: true, width: 200 },
        // { Header: 'Updated Date', accessor: 'updatedDate', visible: true, width: 200 },
        { Header: 'Updated Date', accessor: 'updatedDate' , visible: true, width: 200 , canResize: true, Cell: ({ value }) => { if (!value) return ""; const date = new Date(value); return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;  } },

        { Header: 'Updated Time', accessor: 'updatedTime', visible: true, width: 200 },
       
        
      ];

  const [columnSettings, setColumnSettings] = useState(columns);
  const [selectedRows, setSelectedRows] = useState([]); // Track selected rows by IDs
  const [Icons, setIcons] = useState([]);

  const extraButtons = [
    {
      icon: IoMdCheckmark, // Pass the component itself
      onClick: handleApproveClick,
      title: "Approve",
      disabled: selectedRows.length === 0,
      
      
    },
    {
      icon: IoMdClose,
      onClick: handleCancelClick,
      title: "Reject",
      disabled: selectedRows.length === 0,
    },
    {
      icon: IoMdCreate,
      onClick: handleEditClick,
      title: "Edit",
      disabled: selectedRows.length === 0,
    },
    {
      icon: IoMdTime,
      onClick: handleAuditTrail,
      title: "Audit Trail",
      disabled: selectedRows.length === 0,
    },
    {
      icon: IoMdTrash,
      onClick: handleDeleteClick,
      title: "Delete",
      disabled: selectedRows.length === 0,
    },
    {
      icon: VscVmActive,
      onClick: handleInactiveClick,
      title: "Inactive/Active",
      disabled: selectedRows.length === 0,
    },
  ];

  const reload = async () => {
    setLoading(true);  // Start loading
    try {
      await fetchRolesAndPermissionDetails();  // Fetch users
    } catch (error) {
      console.error('Error reloading users:', error);
    } finally {
      setLoading(false);  // Stop loading
    }
  };

  const handleSettingsButtonClick = () => {
    setIsSettingsPopupOpen(true);
  };

    const existingButtons = AdminActionsConfig.screen5.map((button) => {
        let onClick;
        let title;
      
       
        if (button.icon === IoMdRefresh) {
          onClick = reload; // Reference the reload function, not invoking it
          title = "Refresh";
        }
        else if (button.icon === IoAddCircleOutline) {
            onClick = () => handleNavigate("/14/admin/112/roles-and-permission/add");
            title = "Add Permission";
        }
        else if(button.icon === IoSettingsOutline){
          onClick = handleSettingsButtonClick;
          title ="Table Settings";
        }
        // else if (button.icon === PiUserSwitchLight) {
        //     onClick = () => handleNavigate("/14/admin/112/roles-and-permission/roles");
        //     title = "Roles";
        // }
           else {
          onClick = button.onClick || (() => {}); // Provide a default no-op function if onClick is undefined
          title = button.title || ""; // Use existing title or default to an empty string
        }
      
        return {
          ...button,
          onClick, // Reference the function without invoking it
          title,   // Add the title property to the button configuration
        };
      });

    const actionButtons = existingButtons.concat(
      extraButtons.map((button) => ({
        ...button,
        disabled: selectedRows.length === 0, // Disable button if no users are selected
        onClick: selectedRows.length > 0 ? button.onClick : null, // Enable onClick only if users are selected
      }))
    );

   

    const modifiedColumns = columnSettings
    .filter(col => col.visible)
    .map(col => ({ ...col, width: col.width }));


   

      
    const handleSelectRow = (id) => {
        handleCheckboxChange(id);
      };

      const handleCheckboxChange = (id) => {
        console.log('checked user id',id);
        const updatedSelectedRows = selectedRows.includes(id)
          ? selectedRows.filter((rowId) => rowId !== id) // Deselect if already selected
          : [...selectedRows, id]; // Add to selection if not selected
    
        setSelectedRows(updatedSelectedRows);
        console.log('check box:', updatedSelectedRows);
      
      };

      const handleDoubleClick = (id) => {
    console.log("check rolesandpermission id",id);
    navigate(`/14/admin/112/roles-and-permission/view/${id}`, { state: { mode: 'view' } });  };
    
      


  const fetchRolesAndPermissionDetails = async () => {
    try {
      console.log("assignees data",assignee);
      const data = { clientId: assignee.clientId };
      console.log("Request Data for users:", data);
      
      // const response = await UserService.getUsers(`${process.env.REACT_APP_BASE_URL}/get-all-users-permissions`);      
      const response = await UserService.saveContactDetails(`${process.env.REACT_APP_BASE_URL}/get-all-users-permissions`, data);      
      console.log("Response from server fetching roles:", response);
      const responseData = response.data;
      console.log('set roles details',responseData);
      setRolesAndPermission(responseData);
      if (responseData.error) {
        console.error("Error fetching roles data:", responseData.message);
        setAlert({ severity: "error", message: responseData.message });
        return;
      }
    } catch (error) {
      console.error("Error fetching roles data", error);
      setAlert({ severity: "error", message: `Error fetching roles data, kindly check the connection ${error.message}` });
    }
  };

    const handleBreadcrumbClick = (crumb) => {
        console.log("Breadcrumb clicked:", crumb.title);
        if (crumb.title === 'User & SOD') {
          navigate('/14/admin');
        } else if (crumb.title === 'Admin') {
          navigate('/14/admin');
        } else if (crumb.path) {
          navigate(crumb.path); // Navigate to the specified path in the breadcrumb
        }
      };

  return (
    <div>
        
      <Header 
        title="Admin > User & SOD > Permissions"
        onBreadcrumbClick={handleBreadcrumbClick}
      />

<div>
            <div className="flex justify-between items-center -ml-1 pl-4">
            <div className="flex items-center">
            <SearchInput
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              placeholder="Search User Id"
            />

      <AdminActions
  icons={iconId}
  buttons={actionButtons} 
  // extraButtons={extraButtons}
  selectedCount={selectedRows.length} 
  selectUserIds={selectedRows}/>

</div>
            </div>
            </div>

    
      <div>
{loading && 
<div><CustomeLoading /></div>
}

        {/* Table Component */}
        <ReTable
          data={filteredUsers}
          columns={modifiedColumns} // Use modified columns based on visibility
          selectedRows={selectedRows}
          onSelectRow={handleSelectRow}
          onRowDoubleClick={handleDoubleClick}
          
        />
      </div>
      {isSettingsPopupOpen && (
        <ColumnSettingsPopup
          columns={columnSettings}
          onClose={() => setIsSettingsPopupOpen(false)}
          onUpdate={handleUpdateColumnSettings}
        />
      )}

    </div>
  )
}

