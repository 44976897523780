import React from 'react'
import Transactionstable from '../Tables/CashInBank/Transactionstable'

import NoActionLayout from '../../../../components/NoActionLayout'
import CashBalanceActions from '../Props/CashBalanceActions'
import { buttonConfigs } from '../Props/buttonConfig';

export default function Transactions() {
  const buttons=buttonConfigs.screen1;

  return (
    <div>
      <div>
      <NoActionLayout title="Cash Balance > Cash in Bank > Transactions" />
</div>
<div>
<CashBalanceActions buttons={buttons}/>
</div>
<Transactionstable/>
    </div>
  )
}
