import React from 'react'

export default function MinimumTable() {
  return (
    <div>
      
      <div className='p-4'>
      <table className="min-w-full bg-white">
          <thead className="bg-sky-500 text-primary-foreground text-sm">
            <tr className="bg-primary">
              
              <th className="py-2 px-4 text-center text-white  text-left font-medium">Company Code</th>
              <th className="py-2 px-4 text-center text-white  text-left font-medium">Company Short Name</th>
              <th className="py-2 px-4 text-center text-white  text-left font-medium">Company Full Name</th>
              <th className="py-2 px-4 text-center text-white  text-left font-medium">Bank ID</th>
              <th className="py-2 px-4 text-center text-white  text-left font-medium">Bank Account ID</th>
              <th className="py-2 px-4 text-center text-white  text-left font-medium">Bank Country</th>
              <th className="py-2 px-4 text-center text-white  text-left font-medium">Branch ID</th>
              <th className="py-2 px-4 text-center text-white  text-left font-medium">Branch Name</th>
              <th className="py-2 px-4 text-center text-white  text-left font-medium">Account No</th>
              <th className="py-2 px-4 text-center text-white  text-left font-medium">Currency</th>
              <th className="py-2 px-4 text-center text-white  text-left font-medium">Balance State</th>
              <th className="py-2 px-4 text-center text-white  text-left font-medium">Opening balance</th>
              <th className="py-2 px-4 text-center text-white  text-left font-medium">Debits</th>
              <th className="py-2 px-4 text-center text-white  text-left font-medium">Credits</th>
              <th className="py-2 px-4 text-center text-white  text-left font-medium">Closing Balance</th>
             
            </tr>
          </thead>
          <tbody className='text-sm'>
            {Array(10).fill().map((_, index) => (
              <tr key={index} className="border-b"  style={{ backgroundColor: index % 2 === 0 ? 'white' : '#f3f4f6' }}>
               
                <td className="py-2 px-4 text-center">x</td>
                <td className="py-2 px-4 text-center">Company Code</td>
                <td className="py-2 px-4 text-center">Description</td>
                <td className="py-2 px-4 text-center">Currency</td>
                <td className="py-2 px-4 text-center">Debit</td>
                <td className="py-2 px-4 text-center">Credit</td>
                <td className="py-2 px-4 text-center">Cumulative Balance</td>
         
                <td className="py-2 px-4 text-center">BN:_01</td>
                <td className="py-2 px-4 text-center">Branch Name</td>
                <td className="py-2 px-4 text-center">Currency</td>
                <td className="py-2 px-4 text-center">Balance State</td>
                <td className="py-2 px-4 text-center">Opening balance</td>
                <td className="py-2 px-4 text-center">Debits</td>
                <td className="py-2 px-4 text-center">Credits</td>
                <td className="py-2 px-4 text-center">Closing Balance</td>
                
              </tr>
            ))}
          </tbody>
        </table>
        </div>
    </div>
    
  )
}
