import React, { useEffect, useState } from 'react';
import UserService from '../../../../rest-services/UserServices'; // Assuming you have UserService for making API requests
import ReTable from '../../../../components/Table';
import Footer from '../../../../components/footer';
import { useToast } from "../../../../components/toast/toast";
import { Radio } from '@mui/material';


const UserTable = () => {
  const { toast } = useToast();

  const [users, setUsers] = useState([]);
  const [userTypes, setUserTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedUserTypes, setSelectedUserTypes] = useState({});
  const [purchaseData, setPurchaseData] = useState([]);
  const [assignee, setAssignee] = useState({ id: "", username: "" });
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    if (assignee.clientId) {
      fetchUsers();
    }
  }, [assignee]);

  const fetchAssignee = async () => {
    try {
      const userDetailString = sessionStorage.getItem("userDetail");

      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.username || "",
          clientId: userDetail.clientId || "",
          assignerId: userDetail.userId || "",
        };
        console.log("assigneeData is",assigneeData);

        setAssignee(assigneeData);
        
        // Log the id and assigner values to the console
        console.log("Assignee ID:", assigneeData.id);
        console.log("Assigner:", assigneeData.assigner);
        console.log("Assigner userId:", assigneeData.assignerId);
        console.log("Assigner clientId:", assigneeData.clientId);
        
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  // Fetch user types using fetchUserTypes method
  const fetchUserTypes = async () => {
    try {
      const response = await UserService.getUsers(
        `${process.env.REACT_APP_BASE_URL}/get-user-type`
      );
      const sortedUserTypes = response.data.data.sort((a, b) => a.id - b.id); // Sort user types by id in ascending order
      console.log("set user types",sortedUserTypes);
      setUserTypes(sortedUserTypes); // Update userTypes state with sorted data
    } catch (error) {
      console.error("Error fetching user types:", error);
    }
  };

  // Fetch users using fetchUsers method
  const fetchUsers = async () => {
    try {
      console.log("assignees data",assignee);
      const data = { clientId: assignee.clientId };
      console.log("Request Data for users:", data);

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/user/get-user/NotKickstart`,
        data
      );      
      console.log("Response from server fetching users:", response);

      const responseData = response.data;
      console.log('add last seen and users data',responseData);

      setUsers(responseData); // Assuming `data.data` contains users
  
      // Set initial selected user types based on fetched users
      const initialSelectedUserTypes = {};
      responseData.forEach(user => {
        initialSelectedUserTypes[user.id] = user.userType;
        console.log("foreach initialSelectedUserTypes",initialSelectedUserTypes);
      });
      setSelectedUserTypes(initialSelectedUserTypes);
      console.log("Initial selected user types:", initialSelectedUserTypes);
    } catch (error) {
      console.error("Error fetching Users data", error);
    }
  };

  // Fetch user purchase details types
  const fetchPurchaseUsertypes = async () => {
    try {
      const response = await UserService.getPurchaseUsertypes();
      // Sort purchaseData by userType in ascending order
      const sortedPurchaseTypesData = response.data.data.sort((a, b) => a.userType - b.userType);
      console.log("sort purchase",sortedPurchaseTypesData);
      setPurchaseData(sortedPurchaseTypesData); // Assuming `data.data` contains users
    } catch (error) {
      console.error("Error fetching purchase data", error);
    }
  };

  // Fetch both users and user types on component mount
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchUserTypes();
      await fetchAssignee();
      // await fetchUsers();
      await fetchPurchaseUsertypes();
      setLoading(false);
    };

    fetchData();
  }, []);

  // Handle local user type selection only, without API call
  const handleUserTypeSelect = (id, userTypeId) => {
    setSelectedUserTypes((prev) => ({ ...prev, [id]: userTypeId }));
  };
  const handleRowDoubleClick = (id) => {
    console.log(`Row with ID ${id} was double clicked.`);
  };


  // Submit all changes
  const onSubmit = async () => {
    try {
      const updates = Object.keys(selectedUserTypes).map(userId => ({
        userId,
        userTypeId: selectedUserTypes[userId]
      }));

      await UserService.batchUpdateUserTypes(updates);

      // Update local user and purchase data after successful submission
      await fetchUsers();
      await fetchPurchaseUsertypes();
      console.log("User types updated successfully for selected users.");
      toast("success", "User types updated successfully");
    } catch (error) {
      console.error("Error updating user types:", error);
    }
  };

  const columns = React.useMemo(() => {
    const userTypeColumns = userTypes.map((type) => ({
      Header: type.name,
      accessor: `userType_${type.id}`,
      Cell: ({ row }) => (
        <Radio
          type="radio"
          name={`usertype-${row.original.id}`}
          checked={selectedUserTypes[row.original.id] == type.id}
          onChange={() => handleUserTypeSelect(row.original.id, type.id)}
          style={{
            color: selectedUserTypes[row.original.id] == type.id ? '#29CB00' : '#5D5D5D',
            transform: 'scale(0.7)',
          }}
        />
      ),
    }));
  
    return [
      { Header: 'User ID', accessor: 'userId' },
      { Header: 'User Name', accessor: 'username' },
      {
        Header: 'Not Assigned',
        accessor: 'not_assigned',
        Cell: ({ row }) => {
          // Check if userType is 0 (Not Assigned)
          const isNotAssigned = selectedUserTypes[row.original.id] == 0;
  
          return (
            <Radio
              type="radio"
              name={`usertype-${row.original.id}`}
              checked={isNotAssigned}
              onChange={() => handleUserTypeSelect(row.original.id, 0)} // Set userType to 0
              style={{
                color: isNotAssigned ? 'red' : '#5D5D5D',
                transform: 'scale(0.7)',
              }}
            />
          );
        },
      },
      ...userTypeColumns,
    ];
  }, [userTypes, selectedUserTypes]);
  
  
  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <div>
            <div className="overflow-x-auto p-2 mb-6" style={{ maxHeight: '90vh', width: '100%', overflowY: 'auto' }}>
              <table className="table-fixed min-w-full bg-white border border-customBlue">
                <thead className="bg-customBlue text-primary-foreground text-left text-sm font-medium sticky top-0 z-20">
                  <tr className="z-10">
                    <th
                      className="text-center text-white font-medium border-r border-grey-400 relative whitespace-nowrap bg-customBlue h-[30px] sticky top-0 z-10"
                      style={{ width: '90px', minWidth: '90px', maxWidth: '90px' }} // Widen the first header
                    >
                    </th>
                    
                    {purchaseData.map((user) => (
                     
                      <th 
                        key={user.id} 
                        className="px-2 text-center text-white font-medium border-r border-grey-400 relative whitespace-nowrap bg-customBlue sticky top-0 z-10"
                      >
                        {user.userType === 1 ? "User" 
                        : user.userType === 2 ? "Super User"
                        : user.userType === 3 ? "Approver"
                        : user.userType === 4 ? "Admin"
                        : user.userType === 5 ? "Consultant"
                        : user.userType === 6 ? "Support"
                        : user.userType === 7 ? "System"
                        : "Unknown"}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="text-sm">
                  <tr style={{ backgroundColor: '#FFFDF0' }}> {/* Yellow color for rows */}
                    <td className=" text-center border-b border-r text-customGray border-grey-400 whitespace-nowrap px-16 h-[30px]">Purchased</td>
                    {purchaseData.map((user) => (
                      <td key={user.id} className=" text-center border-r text-customGray border-b border-grey-400 whitespace-nowrap">
                        {user.purchased}
                      </td>
                    ))}
                  </tr>
                  <tr style={{ backgroundColor: '#FFFDF0' }}> {/* Yellow color for rows */}
                    <td className=" text-center border-b text-customGray border-r border-grey-400 whitespace-nowrap px-16 h-[30px]">Assigned</td>
                    {purchaseData.map((user) => (
                      <td key={user.id} className=" text-center text-customGray border-b border-r border-grey-400 whitespace-nowrap">
                        {user.assigned}
                      </td>
                    ))}
                  </tr>
                  <tr style={{ backgroundColor: '#FFFDF0' }}> {/* Yellow color for rows */}
                    <td className=" text-center border-b text-customGray border-r border-grey-400 whitespace-nowrap px-16 h-[30px]">Available</td>
                    {purchaseData.map((user) => (
                      <td key={user.id} className=" text-center text-customGray border-b border-r border-grey-400 whitespace-nowrap">
                        {user.available}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div>
            <ReTable
              data={users}
              columns={columns}
              selectedRows={selectedRows}
              showCheckbox={false}
              showHeaderCheckbox={false}
              onRowDoubleClick={handleRowDoubleClick}
              className={""}
            />
          </div>
        </div>
      )}
          <Footer>
            <button
              onClick={onSubmit}
              className="bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ml-7"
            >
              Submit
            </button>
          </Footer>
    </div>
  );
};

export default UserTable;
