import React from "react";
import { useTable, useResizeColumns, useFlexLayout } from "react-table";
import { Checkbox } from "@mui/material";

const ReusableTable = ({
  data,
  columns,
  onSelectRow,
  selectedRows,
  onSelectAll,
  onRowDoubleClick,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useFlexLayout, // Flex layout for column resizing
      useResizeColumns // Enable column resizing
    );

  return (
    <div
      {...getTableProps()}
      className="overflow-x-auto p-2"
      style={{
        maxHeight: "600px",
        overflowY: "auto",
      }}
    >
      <div className="overflow-x-auto" style={{ maxWidth: "1600px" }}>
        <div className="overflow-y-auto" style={{ maxHeight: "590px" }}>
          <table className="table-fixed min-w-full bg-white border border-customBlue">
            <thead className="bg-customBlue text-primary-foreground text-left text-sm font-medium sticky top-0 z-20">
              <tr className="z-10">
                <th
                  className="text-center border-r border-customTableBorder"
                  style={{ width: "30px", minWidth: "30px", maxWidth: "30px" }} // Keep the width fixed
                >
                  <Checkbox
                    size="small"
                    checked={selectedRows.length === data.length}
                    onChange={onSelectAll}
                    sx={{ transform: "scale(0.8)", padding: "2px" }}
                  />
                </th>
                {headerGroups.map((headerGroup) => (
                  <React.Fragment key={headerGroup.id}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        className="px-2 text-center text-white font-medium border-r border-sky-100 relative whitespace-nowrap bg-customBlue sticky top-0 z-10"
                        style={{ width: `${column.width}px`, height: "30px" }}
                      >
                        {column.render("Header")}
                        {/* Resizer */}
                        {column.canResize && (
                          <div
                            {...column.getResizerProps()}
                            className="absolute top-0 right-0 w-2 h-full cursor-col-resize"
                            style={{ transform: "translateX(50%)" }}
                          />
                        )}
                      </th>
                    ))}
                  </React.Fragment>
                ))}
              </tr>
            </thead>
            <tbody className="text-sm" {...getTableBodyProps()}>
              {rows.length === 0 ? (
                <tr>
                  <td
                    colSpan={columns.length + 1}
                    className="py-1 px-2 text-center"
                  >
                    No data found.
                  </td>
                </tr>
              ) : (
                rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      key={row.original.id}
                      className={
                        selectedRows.includes(row.id)
                          ? "bg-blue-100"
                          : row.index % 2 === 0
                          ? "bg-white"
                          : "bg-sky-50"
                      }
                      onDoubleClick={() => onRowDoubleClick(row.original.id)}
                      style={{ height: "20px" }}
                    >
                      <td className=" text-center border-r border-customTableBorder whitespace-nowrap text-customGray">
                        <Checkbox
                          size="small"
                          checked={selectedRows.includes(row.original.id)}
                          onChange={() => onSelectRow(row.original.id)}
                          sx={{ transform: "scale(0.8)" }}
                        />
                      </td>
                      {row.cells.map((cell) => (
                        <td
                          key={cell.column.id}
                          {...cell.getCellProps()}
                          className="px-2 text-center border-r border-customTableBorder whitespace-nowrap h-4 text-customGray"
                          style={{
                            width: `${cell.column.width}px`,
                            height: "30px",
                          }}
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ReusableTable;

// import React from 'react';
// import { useTable, useResizeColumns, useFlexLayout } from 'react-table';

// const ReusableTable = ({ columns, data }) => {
//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     rows,
//     prepareRow,
//   } = useTable(
//     {
//       columns,
//       data,
//     },
//     useFlexLayout,    // Flex layout for column resizing
//     useResizeColumns  // Enable column resizing
//   );

//   return (
//     <div {...getTableProps()} className="table w-full border border-gray-300">
//       <div>
//         {/* Render Header */}
//         {headerGroups.map(headerGroup => (
//           <div {...headerGroup.getHeaderGroupProps()} className="bg-gray-200">
//             {headerGroup.headers.map(column => (
//               <div
//                 {...column.getHeaderProps()}
//                 className="py-2 px-4 border-r border-gray-300 relative"
//                 style={{ width: `${column.width}px` }}  // Set column width dynamically
//               >
//                 {column.render('Header')}
//                 {/* Resizer */}
//                 {column.canResize && (
//                   <div
//                     {...column.getResizerProps()}
//                     className="resizer absolute top-0 right-0 w-2 h-full cursor-col-resize"
//                   />
//                 )}
//               </div>
//             ))}
//           </div>
//         ))}
//       </div>
//       {/* Render Rows */}
//       <div {...getTableBodyProps()}>
//         {rows.map(row => {
//           prepareRow(row);
//           return (
//             <div {...row.getRowProps()} className="border-b border-gray-300">
//               {row.cells.map(cell => (
//                 <div
//                   {...cell.getCellProps()}
//                   className="py-2 px-4 border-r border-gray-300"
//                   style={{ width: `${cell.column.width}px` }}  // Dynamically apply column width
//                 >
//                   {cell.render('Cell')}
//                 </div>
//               ))}
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// };

// export default ReusableTable;

// import { Checkbox } from '@mui/material';

// const ReusableTable = ({ data, columns, onSelectRow, selectedRows, onSelectAll }) => {
//   return (
//     <div
//       className="overflow-x-auto p-2"
//       style={{
//         maxHeight: "600px", // Set a fixed max height
//         overflowY: "auto",  // Allow vertical scrolling
//       }}
//     >
//       <table className="table-fixed min-w-full bg-white border border-customBlue">
//         <thead className="bg-customBlue text-primary-foreground text-left text-xs font-medium sticky top-0 z-10">
//           <tr>
//             <th
//               className="py-1 px-2 w-200 text-center text-white font-medium border-r border-sky-100 whitespace-nowrap"
//               style={{ height: '40px' }} // Define height for the header
//             >
//               <Checkbox
//                 size="small"
//                 checked={selectedRows.length === data.length}
//                 onChange={onSelectAll}
//                 sx={{ transform: 'scale(0.8)' }}
//               />
//             </th>
//             {columns.map((column) => (
//               <th
//                 key={column.accessor}
//                 className="py-1 px-2 text-center text-white font-medium border-r border-sky-100 whitespace-nowrap"
//                 style={{ width: `${column.width}px`, height: '40px' }} // Corrected style usage
//               >
//                 {column.Header}
//               </th>
//             ))}
//           </tr>
//         </thead>
//         <tbody className="text-xs">
//           {data.length === 0 ? (
//             <tr>
//               <td colSpan={columns.length + 1} className="py-1 px-2 text-center">
//                 No data found.
//               </td>
//             </tr>
//           ) : (
//             data.map((item, index) => (
//               <tr key={item.id} className={selectedRows.includes(item.id) ? 'bg-blue-100' : index % 2 === 0 ? 'bg-white' : 'bg-sky-50'}>
//                 <td className="py-1 px-2 text-center border-r border-sky-100 whitespace-nowrap">
//                   <Checkbox
//                     size="small"
//                     checked={selectedRows.includes(item.id)}
//                     onChange={() => onSelectRow(item.id)}
//                     sx={{ transform: 'scale(0.8)' }}
//                   />
//                 </td>
//                 {columns.map((column) => (
//                   <td
//                     key={column.accessor}
//                     className="py-1 px-2 text-center border-r border-sky-100 whitespace-nowrap"
//                     style={{ width: `${column.width}px` }} // Corrected style usage
//                   >
//                     {item[column.accessor]}
//                   </td>
//                 ))}
//               </tr>
//             ))
//           )}
//         </tbody>
//       </table>
//     </div>
//   );
// }
// export default ReusableTable;
