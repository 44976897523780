import React, { useEffect, useState } from "react";
import NoActionLayout from "../../../components/NoActionLayout";
import { Alert } from "@mui/material";
import BtcServices from "../../../rest-services/BtcServices";

function AddBTC() {
  const [btcCode, setBtcCode] = useState("");
  const [description, setdescription] = useState("");
  const [statement, setstatement] = useState("");
  const [alert, setAlert] = useState(null);
  const [assignee, setAssignee] = useState({ id: "", assigner: "" });

  const fetchAssignee = async () => {
    try {
      const userDetailString = sessionStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.username || "",
        };
        setAssignee(assigneeData);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };
  useEffect(() => {
    fetchAssignee();
  }, []);

  const handleSave = async (e) => {
    e.preventDefault();
    const data = {
      btcCode,
      description,
      inflow: "0",
      outflow: "0",
      statement,
      createdBy: assignee.assigner,
      changedBy: "",
    };
    console.log(`this the date ${data}`);

    try {
      const response = await BtcServices.saveBtcCode(data);

      if (response.status === true) {
        // Check if the response is successful
        setAlert({
          severity: "success",
          message: response.message || "Btc Code saved successfully.",
        });
      } else {
        // Handle non-200 status codes
        setAlert({
          severity: "error",
          message: `Failed to save Btc Code and StatementType must be unique: ${
            response.message || "Unknown error"
          }`,
        });
      }
    } catch (error) {
      console.error("Error details:", error); // Log the entire error object
      setAlert({
        severity: "error",
        message: `Error saving Btc Code: ${error.message || "Unknown error"}`,
      });
    }
  };
  return (
    <div>
      <NoActionLayout title="Master Data > Country > Add" />
      <div className="p-6 bg-white dark:bg-card rounded-lg shadow-md">
        <form className="mt-4" onSubmit={handleSave}>
          <div className="mb-4 flex items-center">
            <label
              className="block text-zinc-700 dark:text-zinc-300 mr-2 w-1/4 text-left"
              htmlFor="btccode"
            >
              BTC Code <span className="text-red-500">*</span> :
            </label>
            <input
              type="text"
              id="btccoode"
              value={btcCode}
              onChange={(e) => setBtcCode(e.target.value)}
              className="mt-1 block w-100 border border-zinc-300 dark:border-zinc-600 rounded-md p-2 focus:outline-none focus:ring focus:ring-primary"
              required
            />
          </div>
          <div className="mb-4 flex items-center">
            <label
              className="block text-zinc-700 dark:text-zinc-300 mr-2 w-1/4 text-left"
              htmlFor="description"
            >
              BTC Description <span className="text-red-500">*</span> :
            </label>
            <input
              type="text"
              id="description"
              value={description}
              onChange={(e) => setdescription(e.target.value)}
              className="mt-1 block w-100 border border-zinc-300 dark:border-zinc-600 rounded-md p-2 focus:outline-none focus:ring focus:ring-primary"
              required
            />
          </div>
          <div className="mb-4 flex items-center">
            <label
              className="block text-zinc-700 dark:text-zinc-300 mr-2 w-1/4 text-left"
              htmlFor="statetype"
            >
              Statement Type <span className="text-red-500">*</span> :
            </label>
            <input
              type="text"
              id="statetype"
              value={statement}
              onChange={(e) => setstatement(e.target.value)}
              className="mt-1 block w-100 border border-zinc-300 dark:border-zinc-600 rounded-md p-2 focus:outline-none focus:ring focus:ring-primary"
              required
            />
          </div>
          <div className="flex justify-start space-x-4">
            <button
              type="submit"
              className="bg-blue-500 text-white rounded-md px-4 py-2 hover:bg-blue-600"
            >
              Save
            </button>
          </div>
        </form>
        {alert && (
          <Alert severity={alert.severity} onClose={() => setAlert(null)}>
            {alert.message}
          </Alert>
        )}
      </div>
    </div>
  );
}

export default AddBTC;
