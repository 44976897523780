import RestService from "./RestServices";
const basePath = "/btc";
const BtcServices = {
  saveBtcCode: (data) => {
    return RestService.CreateData(`${basePath}/create`, data);
  },
  getAllData: (pageSize, page) => {
    return RestService.GetAllData(
      `${basePath}/get-all-btc?limit=${pageSize}&offset=${
        (page - 1) * pageSize
      }`
    );
  },
};

export default BtcServices;
