import React from 'react';

export default function CustomButton({ 
  onClick, title, className,
  secondaryOnClick,
  secondaryTitle,
  secondaryClassName = "",
  showSecondaryButton = false, // A flag to show or hide the secondary button
}) {
  return (
    <div className="flex flex-row justify-end p-2 sticky bottom-0">

 {/* Secondary Button */}
 {showSecondaryButton && (
        <button
          onClick={secondaryOnClick}
          className={`w-16 bg-white text-sm border border-customBlue text-customBlue py-1 px-2 rounded hover:bg-customBlue hover:text-white transition duration-200 ease-in-out ${secondaryClassName}`}
        >
          {secondaryTitle}
        </button>
      )}

    <button
      onClick={onClick}
      className={`w-16 bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-[#0B5299] transition duration-200 ease-in-out ${className}`}

    >
      {title}
    </button>
    </div>
  );
}
