import RestService from "./RestServices";

const CompanyServices = {
  getAllCompany: async (path) => {
    try {
      const response = await RestService.GetAllData(path);
      return response;
    } catch (error) {
      console.error("Error fetching Company data:", error);
      return { error: true, message: "Error fetching Company data" };
    }
  },
  getCompanyById: (id) => {
    return RestService.GetByIdData(`/get-company`, id);
  },
  deleteCompany:(id)=>{
return RestService.DeleteData('/delete-company',id);
  },
  addCompany: async (path, data) => {
    try {
      console.log("Sending data:", data);
      const response = await RestService.CreateData(path, data);
      console.log("Response from Server:", response);
      return response;
    } catch (error) {
      console.error("Error adding Company:", error);
      return { error: true, message: "Error adding Company" };
    }
  },
  getAllCompanywithPage: (pageSize, page, searchQuery = "") => {
    const searchParam = searchQuery ? `&searchQuery=${encodeURIComponent(searchQuery)}` : "";
    return RestService.GetAllData(
      `/getallcompanybypage?limit=${pageSize}&offset=${(page - 1) * pageSize}${searchParam}`
    );
  },
  getAllbugroupwithPage: (pageSize, page, searchQuery = "") => {
    const searchParam = searchQuery ? `&searchQuery=${encodeURIComponent(searchQuery)}` : "";
    return RestService.GetAllData(
      `/getallbugroupbypage?limit=${pageSize}&offset=${(page - 1) * pageSize}${searchParam}`
    );
  },
  getFilterCompany: async (path, data) => {
    try {
      console.log("Sending data:", data);
      const response = await RestService.CreateData(path, data);
      console.log("Response from Server:", response);
      return response;
    } catch (error) {
      console.error("Error getting filter Company:", error);
      return { error: true, message: "Error getting filter Company" };
    }
  },
  checkDuplicateCompanycode: async (path, roleData) => {
    try {
      const response = await RestService.CreateData(path, roleData);
      console.log("Response from server:", response);

      return response;
    } catch (error) {
      console.error("Error fetching company code:", error);
      return { error: true, message: "Error fetching company code" };
    }
  },
  saveapproved: (path, data) => {
    try {
      console.log("Sending data:", data);
      const response = RestService.CreateData(path, data);
      console.log("Response from server:", response);
      return response;
    } catch (error) {
      console.error("Error updating users:", error);
      return { error: true, message: "Error updating users" };
    }
  },
  getAudittrail: (path, data) => {
    try {
      const response = RestService.CreateData(path, data);
      return response;
    } catch (error) {
      console.error("Error getting audittrail:", error);
      return { error: true, message: "Error getting audittrail" };
    }
  },

};

export default CompanyServices;
