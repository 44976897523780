import React from 'react'
// import { useNavigate } from 'react-router-dom';

export default function CashTransaction() {

  // const [SetupOpen, setSetupOpen] = useState(true);
  // const [transactionOpen, setTransactionOpen] = useState(true);
  // const [reportOpen, SetReportsOpen ] = useState(true);
  // const navigate = useNavigate();
  // const handleCardClick = (route) => {
  //   navigate(route);
  // };
  // const togglesetupOpen = () => {
  //   setSetupOpen(!SetupOpen);
  // };

  // const toggleGlobalmasterData = () => {
  //   setGlobalMasterDataOpen(!globalMasterDataOpen);
  // };
  // const ToggleCompany = () => {
  //   SetCompanyOpen(!CompanyOpen);
  // };
  return (
    <>
    <div>
   </div>
   </>
);
}
