import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Link } from "react-router-dom";
import { Switch } from "@mui/material";
import { FaCircleUser, FaScaleBalanced } from "react-icons/fa6";
import { MdOutlineLockReset, MdScreenshotMonitor, MdSearch } from "react-icons/md"; // Import screenshot icon
import styled from "styled-components";
import clogo from "../assets/Bird HD.jpg";
import { AuthContext } from "../context/AuthContext";
import { Alert } from "@mui/material";
import html2canvas from "html2canvas"; // Add this for screenshot functionality
import { BsCashCoin } from "react-icons/bs";
import { BiLogOut } from "react-icons/bi";
import {
  FaChevronCircleLeft,
  FaChevronCircleRight,
  FaSearch,
} from "react-icons/fa";
import { PiSlideshowBold } from "react-icons/pi";
import { LuClipboardList } from "react-icons/lu";
import { HiOutlineCash } from "react-icons/hi";
import { RiHome2Line } from "react-icons/ri";
import { HiArrowTrendingUp } from "react-icons/hi2";
import { IoIdCardOutline } from "react-icons/io5";
import { CiStar } from "react-icons/ci";
import { BsFileBarGraph } from "react-icons/bs";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { RiShieldUserLine } from "react-icons/ri";
import { BiCheckShield } from "react-icons/bi";
import { BsDatabase } from "react-icons/bs";
import { LiaFileSignatureSolid } from "react-icons/lia";
import UserService from "../rest-services/UserServices";
import { useToast } from "./toast/toast";

const ScrollContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  position: relative;

  /* WebKit-based browsers (Chrome, Safari, Edge) */
  ::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: transparent; /* No background for the track */
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.5); /* Semi-transparent white thumb */
    border-radius: 10px; /* Rounded corners for the scrollbar thumb */
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(
      255,
      255,
      255,
      0.7
    ); /* Slightly less transparent on hover */
  }

  /* For Firefox */
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: rgba(255, 255, 255, 0.5) transparent; /* Thumb color and track color */

  /* For Internet Explorer/Edge */
  -ms-overflow-style: none; /* Hide scrollbar in Internet Explorer/Edge */
  overflow: -moz-scrollbars-none; /* Hide scrollbar in older versions of Firefox */
`;

const FixedIcon = styled.div`
  position: absolute;
  top: 7%; /* Center vertically */
  transform: translateY(-50%); /* Adjust for half height */
  background-color: #0b60b0;
  border: 2px solid white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  cursor: pointer;

  &.left {
    left: -15px; /* Position half outside the sidebar */
  }

  &.right {
    right: -15px; /* Position half outside the sidebar */
  }
`;

const Sidebar = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [user, setUser] = useState({ id: "", email: "", userId: "" });
  const [selectedPath, setSelectedPath] = useState("/");
  const [currentTime, setCurrentTime] = useState(new Date());
  const [isExpanded, setIsExpanded] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  
  const [suggestions, setSuggestions] = useState([]);
  const predefinedSuggestions = ["bank", "bank statement", "currency", "cash flow" , 
     "Country", 
     "State", 
     "Calendar", 
     "Language", 
     "Legal Entity", 
     "BuGroup", 
     "Company", 
     "BankID", 
     "Branch",]; // Add your terms here

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {
    try {
      const userDetailString = sessionStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        setUser({
          id: userDetail.id || "",
          email: userDetail.email || "",
          userId: userDetail.userId || "",
        });
      } else {
        Alert.error("No user detail found in session storage.");
      }
    } catch (error) {
      Alert.error(`Error fetching user details: ${error.message}`);
    }
  };

  const { logout } = useContext(AuthContext);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000); // Update every second

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);
  const Menus = [
    // {
    //   title: "Search",
    //   icon: <FaSearch style={{ fontSize: "1.2rem" }} />,
    //   path: "/search",
    // },
    { title: "Home", path: "/" },
    {
      title: "Primary Data",
      icon: <BsDatabase style={{ fontSize: "1.2rem" }} />,
      path: "/master-data",
    },
    {
      title: "BAM",
      icon: <PiSlideshowBold style={{ fontSize: "1.2rem" }} />,
      path: "/bam",
    },
    {
      title: "Signatory",
      icon: <LiaFileSignatureSolid style={{ fontSize: "1.2rem" }} />,
      path: "",
    },

    {
      title: "Bank Statements",
      icon: <LuClipboardList style={{ fontSize: "1.2rem" }} />,
      path: "/bank-statements",
    },
    {
      title: "Imprest Cash",
      icon: <BsCashCoin style={{ fontSize: "1.2rem" }} />,
      path: "",
    },

    {
      title: "Cash Transactions",
      icon: <HiOutlineCash style={{ fontSize: "1.2rem" }} />,
      path: "/cash-transactions",
    },
    {
      title: "Cash Balance",
      icon: <FaScaleBalanced style={{ fontSize: "1.2rem" }} />,
      path: "/cash-balance",
    },
    {
      title: "Cash Flow",
      icon: <HiArrowTrendingUp style={{ fontSize: "1.2rem" }} />,
      path: "/cash-flow",
    },
    {
      title: "Accounting",
      icon: <IoIdCardOutline style={{ fontSize: "1.2rem" }} />,
      path: "/accounting",
    },
    {
      title: "Reconciliation",
      icon: <CiStar style={{ fontSize: "1.2rem" }} />,
      path: "/reconciliation",
    },
    {
      title: "Report",
      icon: <BsFileBarGraph style={{ fontSize: "1.2rem" }} />,
      path: "/reports",
    },
    {
      title: "Task Monitor",
      icon: <IoMdCheckmarkCircleOutline style={{ fontSize: "1.2rem" }} />,
      path: "/task-monitor",
    },
    {
      title: "Admin",
      icon: <RiShieldUserLine style={{ fontSize: "1.2rem" }} />,
      path: "/14/admin",
    },
    {
      title: "Approvals",
      icon: <BiCheckShield style={{ fontSize: "1.2rem" }} />,
      path: "/approvals",
    },
  ];

  let logoutTimer;

  // Function to reset the inactivity timer
  const resetInactivityTimer = () => {
    clearTimeout(logoutTimer);
    logoutTimer = setTimeout(handleSessionTimeout, 600000); // 10-minute inactivity timeout
  };

  // Function to handle session timeout
  const handleSessionTimeout = () => {
    toast("info", "Login Session expired due to inactivity.");
    handleLogout();
  };

  useEffect(() => {
    // Attach mousemove event listener
    window.addEventListener("mousemove", resetInactivityTimer);

    // Set the initial inactivity timer
    resetInactivityTimer();

    return () => {
      // Cleanup on component unmount
      window.removeEventListener("mousemove", resetInactivityTimer);
      clearTimeout(logoutTimer);
    };
  }, []);

  const handleLogout = async () => {
    try {
      const userDetail = JSON.parse(sessionStorage.getItem("userDetail"));
      const id = userDetail?.id;
      if (id) {
        const response = await UserService.saveapproved(
          `${process.env.REACT_APP_BASE_URL}/last-seen-update`,
          { id } // Pass the single userId directly
        );
      }
      sessionStorage.removeItem("lastPath");
      sessionStorage.removeItem("loginTime");
      logout();
      navigate("/", { replace: true });
    } catch (error) {
      console.error("Error updating last seen time during logout:", error);
    }
  };

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  // const handleIconClick = (path) => {
  //   setSelectedPath(path);
  //   navigate(path);
  // };
  useEffect(() => {
    fetchUser();
    const savedPath = sessionStorage.getItem("lastPath");
    if (savedPath) {
      setSelectedPath(savedPath);
      navigate(savedPath);
    }
  }, []);

  const handleIconClick = (path) => {
    setSelectedPath(path);
    sessionStorage.setItem("lastPath", path);
    navigate(path);
  };

  const handleForgotpassword = () => {
    navigate("/forgot-password");
  };

  const handleScreenshot = () => {
    html2canvas(document.body).then((canvas) => {
      const link = document.createElement("a");
      link.download = "screenshot.png";
      link.href = canvas.toDataURL("image/png");
      link.click();
    });
  };
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    

    // Filter suggestions based on input
    if (value) {
      const filtered = predefinedSuggestions.filter((term) =>
        term.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(filtered);
    } else {
      setSuggestions([]);
    }
  };
  const handleSuggestionClick = (suggestion) => {
    setSearchTerm(suggestion); // Set input to the selected suggestion
    setSuggestions([]); // Clear suggestions
    const routes = {
      bank: { route: '/bank', selectedPath: '/master-data' },
      currency: { route: '/currency', selectedPath: '/master-data' },
      'bank statement': { route: '/bank-statements', selectedPath: '/bank-statements' },
      'cash flow': { route: '/cash-flow', selectedPath: '/cash-flow' },
      country: { route: '/country', selectedPath: '/master-data' },
      state: { route: '/state-province', selectedPath: '/master-data' },
      calendar: { route: '/calendar', selectedPath: '/master-data' },
      language: { route: '/language', selectedPath: '/master-data' },
      "legal entity": { route: '/legalentity', selectedPath: '/master-data' },
      bugroup: { route: '/bugroup', selectedPath: '/master-data' },
      company: { route: '/company', selectedPath: '/master-data' },
      bankid: { route: '/bankidscreen', selectedPath: '/master-data' },
      branch: { route: '/2/bankbranch', selectedPath: '/master-data' },
      // Add other predefined suggestions and their mappings
    };
  
    const routeInfo = routes[suggestion.toLowerCase()]; // Use lowercase comparison
    if (routeInfo) {
      setSelectedPath(routeInfo.selectedPath); // Update selected path
      sessionStorage.setItem("lastPath", routeInfo.selectedPath); // Persist selection
      navigate(routeInfo.route); // Navigate to the correct route
    } else {
      alert('No matching route found.');
    }
  };
  const handleSearchSubmit = () => {
    const routes = {
      bank: { route: '/bank', selectedPath: '/master-data' },
      currency: { route: '/currency', selectedPath: '/master-data' },
      'bank statement': { route: '/bank-statements', selectedPath: '/bank-statements' },
      'cash flow': { route: '/cash-flow', selectedPath: '/cash-flow' },
      country: { route: '/country', selectedPath: '/master-data' },
      state: { route: '/state-province', selectedPath: '/master-data' },
      calendar: { route: '/calendar', selectedPath: '/master-data' },
      language: { route: '/language', selectedPath: '/master-data' },
      "legal entity": { route: '/legalentity', selectedPath: '/master-data' },
      bugroup: { route: '/bugroup', selectedPath: '/master-data' },
      company: { route: '/company', selectedPath: '/master-data' },
      bankid: { route: '/bankidscreen', selectedPath: '/master-data' },
      branch: { route: '/2/bankbranch', selectedPath: '/master-data' },
      // Add more mappings as needed
    };
  
    const route = routes[searchTerm.toLowerCase()];
    if (route) {
      setSelectedPath(route.selectedPath); // Update selected path
      sessionStorage.setItem("lastPath", route.selectedPath); // Persist selection
      navigate(route.route); // Navigate to the matched route
    } else {
      alert('No matching route found.');
    }
  };
  return (
    <div className="flex h-screen">
      <div
        className={`bg-[#0B60B0] border border-dark-gray shadow-md pt-5 transition-all duration-300 ${
          isExpanded ? "w-64" : "w-16"
        } flex flex-col relative`}
      >
        <FixedIcon className="right" onClick={handleToggle}>
          {isExpanded ? (
            <FaChevronCircleLeft className="text-white text-xl" />
          ) : (
            <FaChevronCircleRight className="text-white text-xl" />
          )}
        </FixedIcon>
        <div className="flex items-center justify-between px-4">
          <div
            className="flex items-center transition-all duration-300 "
            style={{ padding: "10px 0" }}
          >
            <img
              src={clogo}
              alt="Logo"
              className={`cursor-pointer block float-left transition-all duration-300 rounded-full ${
                isExpanded
                  ? "w-9 -ml-1 rotate-reverse-360"
                  : "w-10 -ml-2 rotate-360"
              }`}
            />
            {isExpanded ? (
              <h1 className="text-orange-200 font-medium text-xl transition-all duration-300 whitespace-nowrap ml-4">
                NRG PHOENIX
              </h1>
            ) : null}
          </div>
        </div>
        {isExpanded ? (    <div className="relative cursor-pointer px-4 py-2 font-medium mt-1">
        
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
          onKeyDown={(e) => e.key === 'Enter' && handleSearchSubmit(searchTerm)}
          className="px-4 py-2 h-8 focus:outline-none pl-12 -ml-4 text-white text-sm font-small bg-customBlue"
        />
        <MdSearch
          className="absolute left-3 top-2 text-white m-1"
          style={{ fontSize: '1.2rem' }}
          onClick={() => handleSearchSubmit(searchTerm)}
        />
        {suggestions.length > 0 && (
          <div className="absolute bg-gray-50 text-customGray mt-1 shadow-lg z-50 rounded-md">
          <ul className="w-[150px] text-center h-[100px] divide-y ">
    {suggestions.map((suggestion, index) => (
      <li
        key={index}
        onClick={() => handleSuggestionClick(suggestion)}
        className="cursor-pointer"
      >
        {suggestion}
      </li>
    ))}
  </ul>
          </div>
        )}
      </div>): null}
    
        <ScrollContainer>
          <ul>
            {Menus.map((menu, index) => (
              <li
                key={index}
                className={`flex items-center gap-x-4 cursor-pointer px-4 py-2 font-medium mt-1 relative mb-2 transition-all duration-300 ${
                  selectedPath === menu.path
                    ? `bg-white text-customBlue ${
                        isExpanded ? "text-customBlue" : "text-black"
                      }`
                    : `hover:bg-white hover:text-customBlue text-white ${
                        isExpanded ? "text-customBlue" : "text-white"
                      }`
                }`}
                onClick={() => handleIconClick(menu.path)}
              >
                <span
                  className={`text-2xl block float-left relative group ${
                    selectedPath === menu.path && !isExpanded
                      ? "text-customBlue"
                      : ""
                  }`}
                >
                  {menu.icon ? (
                    menu.icon
                  ) : (
                    <RiHome2Line style={{ fontSize: "1.2rem" }} />
                  )}
                </span>
                {isExpanded && (
                  <Link
                    to={menu.path}
                    className="text-sm font-medium flex-1 duration-300"
                  >
                    {menu.title}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </ScrollContainer>

        <div
  className={`border-t flex flex-col p-3 transition-all border  border-customBlue duration-300 ${
    isExpanded ? "w-70" : "0"
  }`}
  style={{ backgroundColor: "#0B5299", zIndex: 10 }}
>
  {isExpanded ? (
    <div className="ml-1 text-sm font-medium text-white">
      {/* Flex container to create a 2-column layout */}
      <div className="flex items-center w-full">
        {/* First Column: Text */}
        <div className="flex-grow">
          <div className="flex items-center whitespace-nowrap">
            <span>
              UserId <span className="ml-1">:</span>
            </span>
            <span className="ml-2">{user.userId}</span>
          </div>
          <div className="flex items-center mb-4 mt-4">
            <div className="whitespace-nowrap">
              <span>
                Date <span className="ml-3.5">:</span>
              </span>
              <span className="ml-2 whitespace-nowrap">
                {`${currentTime.getDate()}.${currentTime.getMonth() + 1}.${currentTime.getFullYear()}`}
              </span>
            </div>
          </div>
          <div className="flex items-center">
            <div>
              <span>
                Time <span className="ml-3">:</span>
              </span>
              <span
                style={{
                  width: "80px",
                  display: "inline-block",
                  textAlign: "left",
                }}
                className="ml-2 whitespace-nowrap"
              >
                {currentTime.toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                })}
              </span>{" "}
              {/* Real-time time */}
            </div>
          </div>
        </div>

        {/* Second Column: Icons */}
        <div className="flex flex-col items-center justify-start ml-4 space-y-3">
          <div className="flex items-center">
            <MdScreenshotMonitor
              className="cursor-pointer text-white text-xl"
              onClick={handleScreenshot}
              title="Take Screenshot"
            />
          </div>
          <div
            onClick={handleForgotpassword}
            className="text-blue-200 cursor-pointer underline  text-md"
          >
            <MdOutlineLockReset className="text-2xl" />
          </div>
          <div
            onClick={handleLogout}
            className="text-red-400 cursor-pointer underline flex gap-x-4"
          >
            <BiLogOut className="text-2xl" />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <FaCircleUser
      className="w-10 rounded-md text-red-400 -ml-2 text-xl"
      style={{ marginBottom: "8px" }}
    />
  )}
</div>


      </div>
    </div>
  );
};

export default Sidebar;
