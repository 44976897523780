import RestService from "./RestServices";
const ModuleService = {
    approvedUser: () => {
        return RestService.GetAllData(`/user/get-user/approved-for-module`);
    },

    sendData: (path, data) => {
        try {
          const response = RestService.CreateData(path, data);
          return response;
        } catch (error) {
          console.error("Error updating users:", error);
          return { error: true, message: "Error updating users" };
        }
      },

    userType: () => {
        return RestService.GetAllData('/get-user-type');
    },

    userAllType: () => {
        return RestService.GetAllData('/get-alluser-type');
    },

    getPurchaseDetails : () =>{
        return RestService.GetAllData('/purchase-details');
    },

    postModule : () =>{
        return RestService.CreateData('/post-module-assignment');
    },

    updateModule : () =>{
        return RestService.UpdateData('/update-module-assignment');
    },

    getModule : () =>{
        return RestService.GetAllData('/get-module-assignment');
    },

}

export default ModuleService;
