import React, { useCallback, useEffect, useState } from "react";
import Header from "../../../../../components/Header";
import { useNavigate } from "react-router-dom";
import SearchInput from "../../../../../components/SearchInput";
import PrimaryActions from "../../Components/ActionButton";
import { PrimaryActionsConfig } from "../../Components/ActionButtonConfig";
import { IoAddCircleOutline, IoSettingsOutline } from "react-icons/io5";
import { MdOutlineFileDownload, MdOutlineFileUpload } from "react-icons/md";
import {
  IoMdCheckmark,
  IoMdClose,
  IoMdCopy,
  IoMdCreate,
  IoMdRefresh,
  IoMdTime,
  IoMdTrash,
} from "react-icons/io";
import LegalEntityServices from "../../../../../rest-services/LegalEntityServices";
import CustomeUpLoader from "../../../../../components/CustomeLoader";
import ColumnSettingsPopup from "../../Components/ColumnSettingPopup";
import ReTable from "../../../../../components/Table";
import * as XLSX from "xlsx";
import CompanyServices from "../../../../../rest-services/CompanyServices";
import { TbDeviceDesktopCancel, TbFlag3Filled } from "react-icons/tb";
import { GiSettingsKnobs } from "react-icons/gi";
import { useToast } from "../../../../../components/toast/toast";
import HoverButton from "../../../../../components/HoverButton";
import { RxDropdownMenu } from "react-icons/rx";
import CustomButton from "../../../../../components/CustomButton";
import { VscVmActive } from "react-icons/vsc";
import ConfirmationDialog from "../../../../../components/Deletionpopup";
import FilterDropdown from "../../../../../components/FilterDropdown";
import CustomDropdown from "../../../../../components/CustomDropdown";
import Pagination from "../../../../../components/PageNation";
import { debounce } from "lodash";
export default function Company() {
  const columns = [
    {
      Header: <TbFlag3Filled style={{ color: "#ffff" }} />,
      accessor: "status",
      visible: true,
      width: 50,
      Cell: ({ value }) => {
        switch (value) {
          case 3:
            return <TbFlag3Filled style={{ color: "#29CB00" }} />; // Green for status 3
          case 0:
            return <TbFlag3Filled style={{ color: "gray" }} />; // Gray for status 0
          case 1:
            return <TbFlag3Filled style={{ color: "yellow" }} />; // Yellow for status 1
          case 2:
            return <TbFlag3Filled style={{ color: "orange" }} />; // Orange for status 2
          case 4:
            return <TbFlag3Filled style={{ color: "red" }} />; // Red for status 4
          default:
            return <TbFlag3Filled style={{ color: "black" }} />; // Default black flag
        }
      },
    },
    {
      Header: "Company Code",
      accessor: "companyCode",
      visible: true,
      width: 200,
      alignment: "left",
    },
    {
      Header: "Company Name",
      accessor: "companyName",
      visible: true,
      width: 200,
      alignment: "left",
    },
    {
      Header: "Currency",
      accessor: "currency",
      visible: true,
      width: 200,
      alignment: "left",
    },
    {
      Header: "DoorNo ",
      accessor: "addresses[0].doorNo",
      visible: true,
      width: 200,
      alignment: "left",
    },
    {
      Header: "FloorNo ",
      accessor: "addresses[0].floorNo",
      visible: true,
      width: 200,
      alignment: "left",
    },
    {
      Header: "Building No ",
      accessor: "addresses[0].buildingNo",
      visible: true,
      width: 200,
      alignment: "left",
    },
    {
      Header: "Building Name ",
      accessor: "addresses[0].buildingName",
      visible: true,
      width: 200,
      alignment: "left",
    },
    {
      Header: "LandMark ",
      accessor: "addresses[0].landMark",
      visible: true,
      width: 200,
      alignment: "left",
    },
    {
      Header: "Street No ",
      accessor: "addresses[0].streetNo",
      visible: true,
      width: 200,
      alignment: "left",
    },
    {
      Header: "Street Name ",
      accessor: "addresses[0].streetName",
      visible: true,
      width: 200,
      alignment: "left",
    },
    {
      Header: "Road No ",
      accessor: "addresses[0].roadNo",
      visible: true,
      width: 200,
      alignment: "left",
    },
    {
      Header: "Road Name ",
      accessor: "addresses[0].roadName",
      visible: true,
      width: 200,
      alignment: "left",
    },
    {
      Header: "Block ",
      accessor: "addresses[0].areaBlock",
      visible: true,
      width: 200,
      alignment: "left",
    },
    {
      Header: "Area Name ",
      accessor: "addresses[0].areaName",
      visible: true,
      width: 200,
      alignment: "left",
    },
    {
      Header: "Address 1 ",
      accessor: "addresses[0].addressLine1",
      visible: true,
      width: 200,
      alignment: "left",
    },
    {
      Header: "Address 2 ",
      accessor: "addresses[0].addressLine2",
      visible: true,
      width: 200,
      alignment: "left",
    },
    {
      Header: "Address 3 ",
      accessor: "addresses[0].addressLine3",
      visible: true,
      width: 200,
      alignment: "left",
    },
    {
      Header: "Country Code ",
      accessor: "addresses[0].countryCode",
      visible: true,
      width: 200,
      alignment: "left",
    },
    {
      Header: "State  ",
      accessor: "addresses[0].state",
      visible: true,
      width: 200,
      alignment: "left",
    },
    {
      Header: "District ",
      accessor: "addresses[0].district",
      visible: true,
      width: 200,
      alignment: "left",
    },
    {
      Header: "Postal Code ",
      accessor: "addresses[0].postalCode",
      visible: true,
      width: 200,
      alignment: "left",
    },
    {
      Header: "Zip Code ",
      accessor: "addresses[0].zipCode",
      visible: true,
      width: 200,
      alignment: "left",
    },
    {
      Header: "PO Box ",
      accessor: "addresses[0].poBox",
      visible: true,
      width: 200,
      alignment: "left",
    },
    {
      Header: "Created By",
      accessor: "created_by",
      visible: true,
      width: 200,
      alignment: "left",
    },
    {
      Header: "Created Date",
      accessor: "creationDate",
      visible: true,
      width: 200,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
      },
    },
    {
      Header: "Created Time",
      accessor: "creationTime",
      visible: true,
      width: 200,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return date.toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        });
      },
    },
    {
      Header: "Updated By",
      accessor: "changed_by",
      visible: true,
      width: 200,
      alignment: "left",
    },
    {
      Header: "Updated Date",
      accessor: "changedDate",
      visible: true,
      width: 200,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
      },
    },
    {
      Header: "Updated Time",
      accessor: "changedTime",
      visible: true,
      width: 200,
      // Cell: ({ value }) => {
      //   if (!value) return "";
      //   const date = new Date(value);
      //   return date.toLocaleTimeString("en-GB", {
      //     hour: "2-digit",
      //     minute: "2-digit",
      //     second: "2-digit",
      //     hour12: true,
      //   });
      // },
    },
  ];

  const [companies, setCompanies] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const navigate = useNavigate();
  // const { toast } = useToast();
  const [companyId, setCopmanyId] = useState(null);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [showExtraIcons, setShowExtraIcons] = useState(false);
  const [columnSettings, setColumnSettings] = useState(columns);
  const [isSettingsPopupOpen, setIsSettingsPopupOpen] = useState(false);
  const { toast } = useToast();
  const [assignee, setAssignee] = useState({ id: "", assigner: "" }); // State to store assignee
  const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false);
  const [isCompanyPopupOpen, setIsCompanyPopupOpen] = useState(false); // Controls popup visibility
  const [isUpdatedByPopupOpen, setIsUpdatedByPopupOpen] = useState(false);
  const [isCreatedByPopupOpen, setIsCreatedByPopupOpen] = useState(false);
  const [filterType, setFilterType] = useState("1"); // 'createdBy' or 'updatedBy'
  const [isCountryPopupOpen, setIsCountryPopupOpen] = useState(false); // Controls popup visibility
  const [filters, setFilters] = useState({
    status: "",
    companyCode: [],
    countryCode: [],
    updated_by: "",
    updated_date_from: "",
    updated_date_to: "",
    created_by: "",
    created_date_from: "",
    created_date_to: "",
  });
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedCreatedBy, setSelectedCreatedBy] = useState([]);
  const [selectedUpdatedBy, setSelectedUpdatedBy] = useState([]);
  const [companyCodes, setCompanyCodes] = useState([]); // For fetched company codes
  const [filteredCompanyCodes, setFilteredCompanyCodes] = useState([]);
  const [companycodesearchQuery, setCompanyCodeSearchQuery] = useState("");
  const [countryCodes, setcountryCodes] = useState([]); // For fetched company codes
  const [filteredCountryCodes, setFilteredCountryCodes] = useState([]);
  const [countryCodeSearchQuery, setCountryCodeSearchQuery] = useState("");
  const [updatedbydata, setupdatedbydata] = useState([]); // For fetched company codes
  const [filteredupdatedbydata, setfilteredupdatedbydata] = useState([]);
  const [updatedbysearchquery, setupdatedbysearchquery] = useState("");
  const [createdbydatas, setcreatedbydatas] = useState([]); // For fetched company codes
  const [filteredcreatedbydatas, setFilteredcreatedbydatas] = useState([]);
  const [createdbydatasearchQuery, setcreatedbydatasearchQuery] = useState("");
  const [tempSelectedCompanies, setTempSelectedCompanies] =
    useState(selectedCompanies);
  const [tempSelectedcountries, setTempSelectedcountries] =
    useState(selectedCountries);
  const [tempSelectedupdatedby, setTempSelectedupdatedby] =
    useState(selectedUpdatedBy);
  const [tempSelectedcreatedby, setTempSelectedcreatedby] =
    useState(selectedCreatedBy);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const modifiedColumns = columnSettings
    .filter((col) => col.visible)
    .map((col) => ({
      ...col,
      width: col.width,
    }));
  useEffect(() => {
    fetchCompany();
    fetchAssignee();
    fetchCompanyCodes();
    fetchCountryCodes();
    fetchCreatedbydata();
    fetchupdatedbydata();
  }, []);

 
  useEffect(() => {
    if (companycodesearchQuery.trim() === "") {
      setFilteredCompanyCodes(companyCodes); // Show all if search query is empty
    } else {
      const filtered = companyCodes.filter((code) =>
        code.companyCode
          .toLowerCase()
          .includes(companycodesearchQuery.toLowerCase())
      );
      setFilteredCompanyCodes(filtered);
    }
  }, [companycodesearchQuery, companyCodes]);

  useEffect(() => {
    if (countryCodeSearchQuery.trim() === "") {
      setFilteredCountryCodes(countryCodes); // Show all if search query is empty
    } else {
      const filtered = countryCodes.filter((code) =>
        code.toLowerCase().includes(countryCodeSearchQuery.toLowerCase())
      );
      setFilteredCountryCodes(filtered);
    }
  }, [countryCodeSearchQuery, countryCodes]);
  useEffect(() => {
    if (createdbydatasearchQuery.trim() === "") {
      setFilteredcreatedbydatas(createdbydatas); // Show all if search query is empty
    } else {
      const filtered = createdbydatas.filter((code) =>
        code.created_by
          .toLowerCase()
          .includes(createdbydatasearchQuery.toLowerCase())
      );
      setFilteredcreatedbydatas(filtered);
    }
  }, [createdbydatasearchQuery, createdbydatas]);
  useEffect(() => {
    if (updatedbysearchquery.trim() === "") {
      setfilteredupdatedbydata(updatedbydata); // Show all if search query is empty
    } else {
      const filtered = updatedbydata.filter((code) =>
        code.changed_by
          .toLowerCase()
          .includes(updatedbysearchquery.toLowerCase())
      );
      setfilteredupdatedbydata(filtered);
    }
  }, [updatedbysearchquery, updatedbydata]);
  
  const pageSize = 20;

  const fetchCompany = async (page, searchQuery = "") => {
    setLoading(true);
    try {
      const response = await CompanyServices.getAllCompanywithPage(
        pageSize,
        page,
        searchQuery
      );
      let { companyCode, totalCount } = response.data.data;
      if (!companyCode) {
        companyCode = [];
      } else if (!Array.isArray(companyCode)) {
        companyCode = [companyCode];
      }
      setCompanies(companyCode);
      setFilteredCompanies(companyCode);
      setTotalCount(totalCount);
      setTotalPages(Math.ceil(totalCount / pageSize));
    } catch (error) {
      console.error("Error fetching StateProvince data", error);
    } finally {
      setLoading(false);
    }
  };

  // Debounced fetch function (to avoid too many API calls)
  const debouncedFetchStateProvinces = useCallback(
    debounce((page, query) => {
      fetchCompany(page, query);
    }, 500), // 500ms debounce delay
    []
  );

  // UseEffect to trigger API call on query or page change
  useEffect(() => {
    debouncedFetchStateProvinces(currentPage, searchQuery);
    // Cleanup debounce on unmount
    return () => debouncedFetchStateProvinces.cancel();
  }, [currentPage, searchQuery, debouncedFetchStateProvinces]);
  const fetchCompanyCodes = async () => {
    setLoading(true);
    try {
      const response = await CompanyServices.getAllCompany(`/getcompanycodes`);
      const responseData = response.data;

      if (responseData.error) {
        setAlert({ severity: "error", message: responseData.message });
        return;
      }

      if (responseData && Array.isArray(responseData)) {
        setCompanyCodes(responseData); // assuming responseData contains an array of legal entities
        setFilteredCompanyCodes(responseData);
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching legal entities: ${error.message}`,
      });
    } finally {
      setLoading(false);
    }
  };
  const fetchCountryCodes = async () => {
    setLoading(true);
    try {
      const response = await CompanyServices.getAllCompany(
        `/getcompanycountrycodes`
      );
      const responseData = response.data;

      if (responseData.error) {
        setAlert({ severity: "error", message: responseData.message });
        return;
      }

      if (responseData && Array.isArray(responseData)) {
        setcountryCodes(responseData); // assuming responseData contains an array of legal entities
        setFilteredCountryCodes(responseData);
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching legal entities: ${error.message}`,
      });
    } finally {
      setLoading(false);
    }
  };
  const fetchCreatedbydata = async () => {
    setLoading(true);
    try {
      const response = await CompanyServices.getAllCompany(
        `/getcompanycreatedby`
      );
      const responseData = response.data;

      if (responseData.error) {
        setAlert({ severity: "error", message: responseData.message });
        return;
      }

      if (responseData && Array.isArray(responseData)) {
        setcreatedbydatas(responseData); // assuming responseData contains an array of legal entities
        setFilteredcreatedbydatas(responseData);
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching legal entities: ${error.message}`,
      });
    } finally {
      setLoading(false);
    }
  };
  const fetchupdatedbydata = async () => {
    setLoading(true);
    try {
      const response = await CompanyServices.getAllCompany(
        `/getcompanyupdatedby`
      );
      const responseData = response.data;

      if (responseData.error) {
        setAlert({ severity: "error", message: responseData.message });
        return;
      }

      if (responseData && Array.isArray(responseData)) {
        setupdatedbydata(responseData); // assuming responseData contains an array of legal entities
        setfilteredupdatedbydata(responseData);
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching legal entities: ${error.message}`,
      });
    } finally {
      setLoading(false);
    }
  };
  const fetchAssignee = async () => {
    try {
      const userDetailString = sessionStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.username || "",
        };
        setAssignee(assigneeData);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  const handleDelete = async () => {
    if (selectedRows.length === 0) return; // No rows selected to delete
    setShowConfirmation(true); // Show the confirmation dialog
  };

  const confirmDeletion = async () => {
    setShowConfirmation(false); // Close the dialog
    setLoading(true);
    try {
      for (const id of selectedRows) {
        const response = await CompanyServices.deleteCompany(id);
        
        if (response.status !== 200) {
          // Optionally notify the user about non-deletable statuses
          toast("error", `Company ID cannot be deleted due to its status.`);
        }
        if (response.status === 200) {
          // Optionally notify the user about non-deletable statuses
          toast("success", "Selected Company successfully");
        }
      }
      // Optionally show success toast
      setSelectedRows([]); // Clear selection
      fetchCompany(); // Refresh the list
    } catch (error) {
      console.error("Error deleting company(s):", error);
      // Optionally show error toast
      // toast("error", "Failed to delete BuGroup(s)");
    } finally {
      setLoading(false);
    }
  };

  const cancelDeletion = () => {
    setShowConfirmation(false); // Close the dialog
  };
  const handleApproveClick = async () => {
    if (selectedRows.length > 0) {
      try {
        // Assuming selectedRows contains the selected  Company IDs

        // Pass the array of selected IDs to the approval service
        const response = await CompanyServices.saveapproved(
          `${process.env.REACT_APP_BASE_URL}/approvecompany`,
          { id: selectedRows, created_by: assignee.assigner } // Pass selectedCompanyIds in the body
        );

        console.log("Response status:", response);

        if (response.status === 200) {
          toast("success", "Company successfully Approved");

          // Success logic, refresh the  Companyes
          fetchCompany();
        } else if (response.status === 400) {
          toast("warning", "Company is unable to approve.");
        } else if (response.status === 550) {
          toast("warning", "Company Email is Not Sent.");
        } else {
          console.log("Error updating Companys.");
        }
      } catch (error) {
        console.error("Error approving  Companyes:", error);
      }
    } else {
      console.log("Please select at least one  Company to approve.");
    }
  };

  const handleRejectClick = async () => {
    if (selectedRows.length > 0) {
      try {
        // Assuming selectedRows contains the selected  Company IDs

        // Pass the array of selected IDs to the approval service
        const response = await CompanyServices.saveapproved(
          `${process.env.REACT_APP_BASE_URL}/rejectcompany`,
          { id: selectedRows, created_by: assignee.assigner } // Pass selectedCompanyIds in the body
        );

        console.log("Response status:", response.status);

        if (response.status === 200) {
          toast("error", "Company successfully Rejected");
          fetchCompany();
        } else if (response.status === 400) {
          toast("warning", "Company is unable to Reject.");
        } else {
          console.log("Error updating Companys.");
        }
      } catch (error) {
        console.error("Error approving  Companyes:", error);
      }
    } else {
      console.log("Please select at least one  Company to approve.");
    }
  };
  const handleInactiveClick = async () => {
    if (selectedRows.length > 0) {
      try {
        // Assuming selectedRows contains the selected  Company IDs

        // Pass the array of selected IDs to the approval service
        const response = await CompanyServices.saveapproved(
          `${process.env.REACT_APP_BASE_URL}/inactivecompany`,
          { id: selectedRows, created_by: assignee.assigner } // Pass selectedCompanyIds in the body
        );

        console.log("Response status:", response);

        if (response.status === 200) {
          toast("success", "Company successfully Change Status.");
          fetchCompany();
        } else if (response.status === 400) {
          toast("warning", "The Company cannot be deactivated at this time.");
        } else {
          console.log("Error approving  Companyes.");
        }
      } catch (error) {
        console.error("Error approving  Companyes:", error);
      }
    } else {
      console.log("Please select at least one  Company to approve.");
    }
  };
  const handleAuditTrail = () => {
    if (selectedRows.length === 1) {
      const selectedUser = filteredCompanies.find(
        (com) => com.id === selectedRows[0]
      );
      console.log("seleted Companies", selectedUser);

      if (selectedUser) {
        // Show the selected user's details using toast
        // toast("info", `Selected User: \nID: ${selectedUser.userId}\nName: ${selectedUser.username}`);

        // Navigate to the edit screen, passing the selected user as state
        navigate(`/audit-trail-company/${9}/${selectedRows[0]}`);
      } else {
        toast("warning", "Selected company not found.");
      }
    } else {
      toast("warning", "Please select exactly one company to edit.");
    }
  };
  const handleAddcopyClick = () => {
    if (selectedRows.length === 1) {
      const selectedUser = filteredCompanies.find(
        (company) => company.id === selectedRows[0]
      );
      console.log("Selected Bu group to copy:", selectedUser);

      if (selectedUser) {
        // Navigate to the add page with the selected user data
        navigate(`/company-add/${companyId}`, {
          state: { copiedData: selectedUser },
        });
      } else {
        toast("warning", "Selected companyfound.");
      }
    } else {
      toast("warning", "Please select exactly one company to copy.");
    }
  };
  const handleApplyFilter = async () => {
    try {
      const response = await CompanyServices.getFilterCompany(
        "/getfiltercompany",
        filters
      );
      if (!response.error) {
        setFilteredCompanies(response.data);
      } else {
        console.error("Error:", response.message);
      }
    } catch (error) {
      console.error("Error fetching filtered companies:", error);
    }
  };

  const extraButtons = [
    {
      icon: IoMdCheckmark,
      onClick: handleApproveClick,
      title: "Approve",
      disabled: selectedRows.length === 0,
    },
    {
      icon: IoMdClose,
      onClick: handleRejectClick,
      title: "Reject",
      disabled: selectedRows.length === 0,
    },
    {
      icon: IoMdCreate,
      onClick: () => companyId && navigateToForm(companyId),
      title: "Edit Country",
      disabled: selectedRows.length === 0,
    },
    {
      icon: IoMdCopy,
      onClick: handleAddcopyClick,
      title: "Copy",
      disabled: selectedRows.length === 0,
    },
    {
      icon: IoMdTime,
      onClick: handleAuditTrail,
      title: "Audit Trail",
      disabled: selectedRows.length === 0,
    },
    {
      icon: IoMdTrash,
      onClick: handleDelete,
      title: "Delete",
      disabled: selectedRows.length === 0,
    },
    {
      icon: VscVmActive,
      onClick: handleInactiveClick,
      title: "Inactive/Active",
      disabled: selectedRows.length === 0,
    },
  ];
  const navigateToForm = (id) => {
    navigate(`/company-add/${id}`);
  };
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "Company & Bank Data") {
      navigate("/master-data");
    } else if (crumb.title === "Company") {
      navigate("/Company");
    } else if (crumb.title === "Primary Data") {
      navigate("/master-data");
    } else if (crumb.path) {
      navigate(crumb.path);
    }
  };
  const handleSettingsButtonClick = () => {
    setIsSettingsPopupOpen(true);
  };

  const handleDownload = () => {
    const dataToExport = filteredCompanies.map((company) => ({
      Status:
        company.status === 0
          ? "Created"
          : company.status === 1
          ? "Waiting for Approval"
          : company.status === 2
          ? "Modified"
          : company.status === 3
          ? "Approved"
          : company.status === 4
          ? "Rejected"
          : "Inactive",
      "Company Code": company.companyCode,
      "Legal Entity name": company.legalEntityCode,
      "Door No": company.addresses?.[0]?.doorNo || "", // Ensure addresses array exists
      "Floor No": company.addresses?.[0]?.floorNo || "",
      "Building No": company.addresses?.[0]?.buildingNo || "", // Ensure addresses array exists
      "Building Name": company.addresses?.[0]?.buildingName || "", // Ensure addresses array exists
      "Land Mark Name": company.addresses?.[0]?.landMark || "",
      "Street No": company.addresses?.[0]?.streetNo || "",
      "Street Name": company.addresses?.[0]?.streetName || "",
      "Road No": company.addresses?.[0]?.streetName || "",
      "Road Name": company.addresses?.[0]?.streetName || "",
      "Street Name": company.addresses?.[0]?.streetName || "",
      "Street Name": company.addresses?.[0]?.streetName || "",
      "Area Block": company.addresses?.[0]?.areaBlock || "", // Ensure addresses array exists
      "Area Name": company.addresses?.[0]?.areaName || "",
      "Address Line1": company.addresses?.[0]?.addressLine1 || "",
      "Address Line2": company.addresses?.[0]?.addressLine2 || "",
      "Address Line3": company.addresses?.[0]?.addressLine3 || "",
      "Country Code": company.identifier?.[0]?.country_Code || "",
      "State Name": company.addresses?.[0]?.stateName || "", // Ensure state name from addresses
      District: company.addresses?.[0]?.district || "",
      "Postal Code": company.addresses?.[0]?.postalCode || "",
      "Zip Code": company.addresses?.[0]?.zipCode || "",
      "Po Box": company.addresses?.[0]?.poBox || "",
      "Source System Company Code":
        company.account?.[0]?.sourceSystemCurrencyCode || "",
      "Chart of Accounting Code":
        company.account?.[0]?.chartOfAccountingCode || "",
      "Accounting Period Code":
        company.account?.[0]?.accountingPeriodCode || "",
      "Country Code": company.countryCode || "",
      "Currency Code": company.currency || "",
      "Created By": company.created_by,
      "Created Date": company.creationDate
        ? new Date(company.creationDate).toLocaleDateString()
        : "",
      "Created Time": company.creationTime
        ? new Date(company.creationTime).toLocaleDateString()
        : "",
      "Updated By": company.changed_by,
      "Updated Date": company.changedDate
        ? new Date(company.changedDate).toLocaleDateString()
        : "",
      "Updated Time": company.changedTime
        ? new Date(company.changedTime).toLocaleTimeString()
        : "",
    }));
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Companies");
    XLSX.writeFile(workbook, "Company_Data.xlsx");
  };
  const handleReload = async () => {
    setLoading(true); // Start loading
    try {
      await fetchCompany(); // Fetch users
    } catch (error) {
      console.error("Error reloading users:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };
  const toggleFilterPopup = () => {
    setIsFilterPopupOpen(!isFilterPopupOpen);
  };
  const existingButtons = PrimaryActionsConfig.screen2.map((button) => ({
    ...button,
    title:
      button.icon === IoAddCircleOutline
        ? "Add Country"
        : button.icon === GiSettingsKnobs
        ? "Filter"
        : button.icon === MdOutlineFileDownload
        ? "Download"
        : button.icon === MdOutlineFileUpload
        ? "Upload"
        : button.icon === IoSettingsOutline
        ? "Settings"
        : button.icon === IoMdRefresh
        ? "Refresh"
        : button.title || "",
    onClick:
      button.icon === IoAddCircleOutline
        ? () => handleNavigate("/company-add")
        : button.icon === MdOutlineFileDownload
        ? handleDownload
        : button.icon === IoSettingsOutline
        ? handleSettingsButtonClick
        : button.icon === IoMdRefresh
        ? handleReload
        : button.icon === GiSettingsKnobs
        ? toggleFilterPopup
        : button.onClick || (() => {}),
  }));
  const actionButtons = existingButtons.concat(
    extraButtons.map((button) => ({
      ...button,
      disabled: selectedRows.length === 0,
      onClick: selectedRows.length > 0 ? button.onClick : null,
    }))
  );

  const handleNavigate = (path) => {
    navigate(path);
  };
  const handleSelectRow = (id) => {
    handleCheckboxChange(id);
  };
  const handleSelectAll = () => {
    handleSelectAllChange();
  };
  const handleDoubleClick = (id) => {
    navigate(`/company-view/${id}`, { state: { mode: "view" } });
  };
  const handleCheckboxChange = (id) => {
    const updatedSelectedRows = selectedRows.includes(id)
      ? selectedRows.filter((rowId) => rowId !== id)
      : [...selectedRows, id];

    setSelectedRows(updatedSelectedRows);
    setCopmanyId(id);
    setShowExtraIcons(updatedSelectedRows.length > 0);
  };
  const handleUpdateColumnSettings = (updatedSettings) => {
    setColumnSettings(updatedSettings);
  };

  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedRows([]);
    } else {
      const allIds = companies.map((item) => item.id);
      setSelectedRows(allIds);
    }
    setIsAllSelected(!isAllSelected);
  };
  if (loading) return <div></div>;

  const handleFilterChange = (e) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      status: e.target.value,
    }));
  };

  const handleFilterDateFrom = (e) => {
    console.log(filterType);
    if (filterType === "1") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        created_date_from: e.target.value,
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        updated_date_from: e.target.value,
      }));
    }
  };
  const handleFilterDateto = (e) => {
    if (filterType === "1") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        created_date_to: e.target.value,
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        updated_date_to: e.target.value,
      }));
    }
  };

  const handleResetFilters = () => {
    setFilters({
      status: "",
      companyCode: [],
      countryCode: [],
      updated_by: "",
      updated_date_from: "",
      updated_date_to: "",
      created_by: "",
      created_date_from: "",
      created_date_to: "",
    });
    setSelectedCountries([]);
    setSelectedCompanies([]);
    setSelectedCreatedBy([]);
    setSelectedUpdatedBy([]);
    // ithu apply click panni than rest pannanum na intha function call theva illa
    fetchCompany();
  };

  const handleCompanySelection = (companyCode) => {
    setTempSelectedCompanies((prev) => {
      // Add or remove company from temporary selection list
      return prev.includes(companyCode)
        ? prev.filter((item) => item !== companyCode) // Remove if already selected
        : [...prev, companyCode]; // Add if not selected
    });
  };

  // Reset temporary selection when resetting popup
  const handleCompanyReset = () => {
    setTempSelectedCompanies([]); // Reset selection
  };

  // Apply filter and close the popup
  const handleApplyCompany = () => {
    setSelectedCompanies(tempSelectedCompanies); // Apply temporary selection to actual selection state
    setFilters((prevFilters) => ({
      ...prevFilters,
      companyCode: tempSelectedCompanies, // Set the filter
    }));
    setIsCompanyPopupOpen(false); // Close the popup
  };

  const handleCountrySelection = (countryCode) => {
    setTempSelectedcountries((prev) => {
      // Add or remove company from temporary selection list
      return prev.includes(countryCode)
        ? prev.filter((item) => item !== countryCode) // Remove if already selected
        : [...prev, countryCode]; // Add if not selected
    });
  };

  // Reset temporary selection when resetting popup
  const handleCountryReset = () => {
    setTempSelectedcountries([]); // Reset selection
  };

  // Apply filter and close the popup
  const handleApplyCountry = () => {
    setSelectedCountries(tempSelectedcountries); // Apply temporary selection to actual selection state
    setFilters((prevFilters) => ({
      ...prevFilters,
      countryCode: tempSelectedcountries, // Set the filter
    }));
    setIsCompanyPopupOpen(false); // Close the popup
  };

  const handleCreatedBySelection = (createdBy) => {
    setTempSelectedcreatedby((prev) => {
      // Add or remove company from temporary selection list
      return prev.includes(createdBy)
        ? prev.filter((item) => item !== createdBy) // Remove if already selected
        : [...prev, createdBy]; // Add if not selected
    });
  };

  // Reset temporary selection when resetting popup
  const handleCreatedbyReset = () => {
    setTempSelectedcreatedby([]); // Reset selection
  };

  // Apply filter and close the popup
  const handleApplyCreatedby = () => {
    setSelectedCreatedBy(tempSelectedcreatedby); // Apply temporary selection to actual selection state
    setFilters((prevFilters) => ({
      ...prevFilters,
      created_by: tempSelectedcreatedby, // Set the filter
    }));
    setIsCreatedByPopupOpen(false); // Close the popup
  };

  const handleUpdateBySelection = (changedby) => {
    setTempSelectedupdatedby((prev) => {
      // Add or remove company from temporary selection list
      return prev.includes(changedby)
        ? prev.filter((item) => item !== changedby) // Remove if already selected
        : [...prev, changedby]; // Add if not selected
    });
  };

  // Reset temporary selection when resetting popup
  const handleUpdatedbyReset = () => {
    setTempSelectedupdatedby([]); // Reset selection
  };

  // Apply filter and close the popup
  const handleApplyUpdatedby = () => {
    setSelectedUpdatedBy(tempSelectedupdatedby); // Apply temporary selection to actual selection state
    setFilters((prevFilters) => ({
      ...prevFilters,
      updated_by: tempSelectedupdatedby, // Set the filter
    }));
    setIsUpdatedByPopupOpen(false); // Close the popup
  };
  const statusOptions = [
    { value: "0", label: "Created", className: "text-gray-500" },
    { value: "2", label: "Modified", className: "text-orange-400" },
    { value: "1", label: "Change", className: "text-yellow-500" },
    { value: "3", label: "Approved", className: "text-[#29CB00]" },
    { value: "4", label: "Rejected", className: "text-red-500" },
    { value: "5", label: "Inactive", className: "text-black" },
  ];
  const handleToggle = (dropdownId) => {
    setOpenDropdown((prev) => (prev === dropdownId ? null : dropdownId)); // Toggle dropdown
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  return (
    <div>
      <Header
        title={"Primary Data > Company & Bank Data > Company"}
        onBreadcrumbClick={handleBreadcrumbClick}
      />
       <div>
        <div className="flex justify-between items-center -ml-1 pl-4">
        <div className="flex items-center">

        <SearchInput
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              placeholder="Search by Company"
            />

            <PrimaryActions
              buttons={actionButtons}
              selectedCount={selectedRows.length}
              isExtraButtonsVisible={showExtraIcons}
            />
          </div>
          <div className="flex items-center mr-4">

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            totalCount={totalCount}
          />
          
        </div>
        </div>

        <div></div>
        <ReTable
          data={filteredCompanies}
          columns={modifiedColumns} // Use modified columns based on visibility
          selectedRows={selectedRows}
          onSelectRow={handleSelectRow}
          onSelectAll={handleSelectAll}
          onRowDoubleClick={handleDoubleClick}
        />

        {isSettingsPopupOpen && (
          <ColumnSettingsPopup
            columns={columnSettings}
            onClose={() => setIsSettingsPopupOpen(false)}
            onUpdate={handleUpdateColumnSettings}
          />
        )}

        {isSettingsPopupOpen && (
          <ColumnSettingsPopup
            columns={columnSettings}
            onClose={() => setIsSettingsPopupOpen(false)}
            onUpdate={handleUpdateColumnSettings}
          />
        )}
        {isFilterPopupOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
            <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-3xl z-50">
              <div className="flex justify-between items-center mt-2">
                <h2 className="text-customGray font-semibold mb-5">
                  Apply Filters
                </h2>
                <HoverButton
                  onClick={handleResetFilters}
                  title={"Reset Filter"}
                />
              </div>

              {/* Grid Layout */}
              <div className="grid grid-cols-2 gap-4">
                {/* Left-Side Single Column Fields */}
                <div className="col-span-2 sm:col-span-1">
                  <div className="flex items-center mb-2">
                    <label className="text-customGray mr-2 w-[140px]">
                      Status:
                    </label>
                    <FilterDropdown
                      name="status"
                      value={filters.status}
                      onChange={handleFilterChange}
                      options={statusOptions}
                      placeholder="Select Status"
                      className="bg-white"
                    />
                    {/* <CustomDropdown
          value={filters.status}
          options={statusOptions}
          onChange={() => {
            handleFilterChange();
            // Mark as unsaved
            setOpenDropdown(false);
          }}
          isOpen={openDropdown === "filter"}
          onToggle={()=> handleToggle("filter")}
          className="ml-4"
          placeholder="Select Language"
        /> */}
                  </div>
                  <div className="flex items-center mb-2">
                    <label className="text-customGray mr-2 w-[140px]">
                      Company:
                    </label>
                    <button
                      className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer"
                      onClick={() => {
                        setTempSelectedCompanies(selectedCompanies);
                        setIsCompanyPopupOpen(true);
                        // Reset filteredBuGroup to show all groups when the popup is opened
                      }}
                    >
                      <span className="truncate">
                        {selectedCompanies.length > 0
                          ? selectedCompanies.join(", ")
                          : "Company"}
                      </span>
                      <RxDropdownMenu className="ml-2 text-lg" />
                    </button>
                  </div>

                  {isCompanyPopupOpen && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
                      <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-xl z-50">
                        <div className="flex justify-between items-center mb-4">
                          <h2 className="text-customGray font-semibold">
                            Select Company
                          </h2>
                          <HoverButton
                            // onClick={resetSelectedBuGroups}
                            title={"Reset"}
                            onClick={handleCompanyReset}
                          />
                        </div>

                        {/* Search Input */}
                        <input
                          type="text"
                          className="border border-gray-300 p-2 rounded-md mb-4 w-full"
                          placeholder="Search Company"
                          value={companycodesearchQuery}
                          onChange={(e) =>
                            setCompanyCodeSearchQuery(e.target.value)
                          } // Filter BU Groups
                        />

                        {/* List of BU Groups */}
                        <div className="space-y-2 max-h-80 overflow-y-auto">
                          {filteredCompanyCodes.map((companycode) => (
                            <div
                              key={companycode.id}
                              className="flex items-center"
                            >
                              <input
                                type="checkbox"
                                checked={tempSelectedCompanies.includes(
                                  companycode.companyCode
                                )} // Adjusted to use the correct variable
                                onChange={() =>
                                  handleCompanySelection(
                                    companycode.companyCode
                                  )
                                }
                              />
                              <label className="ml-2">
                                {companycode.companyCode}
                              </label>{" "}
                              {/* Adjusted to match `company` */}
                            </div>
                          ))}
                        </div>

                        {/* Apply Filter Button */}
                        <div className="flex justify-end mt-4">
                          <HoverButton
                            title={"Cancel"}
                            className={"mr-2"}
                            onClick={() => setIsCompanyPopupOpen(false)}
                          />
                          <CustomButton
                            onClick={() => {
                              handleApplyCompany();
                              setIsCompanyPopupOpen(false);
                            }}
                            title={"Apply"}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="flex items-center mb-2">
                    <label className="text-customGray mr-2 w-[140px]">
                      Country :
                    </label>
                    <button
                      className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer"
                      onClick={() => {
                        setTempSelectedcountries(selectedCountries);
                        setIsCountryPopupOpen(true);
                        // Reset filteredBuGroup to show all groups when the popup is opened
                      }}
                    >
                      <span className="truncate">
                        {selectedCountries.length > 0
                          ? selectedCountries.join(", ")
                          : "Country"}
                      </span>
                      <RxDropdownMenu className="ml-2 text-lg" />
                    </button>
                  </div>

                  {isCountryPopupOpen && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
                      <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-xl z-50">
                        <div className="flex justify-between items-center mb-4">
                          <h2 className="text-customGray font-semibold">
                            Select Country
                          </h2>
                          <HoverButton
                            onClick={handleCountryReset}
                            title={"Reset"}
                          />
                        </div>

                        {/* Search Input */}
                        <input
                          type="text"
                          className="border border-gray-300 p-2 rounded-md mb-4 w-full"
                          placeholder="Search Country"
                          value={countryCodeSearchQuery}
                          onChange={(e) =>
                            setCountryCodeSearchQuery(e.target.value)
                          } // Filter BU Groups
                        />

                        {/* List of BU Groups */}
                        <div className="space-y-2 max-h-80 overflow-y-auto">
                          {filteredCountryCodes.map((code) => (
                            <div key={code} className="flex items-center">
                              <input
                                type="checkbox"
                                checked={tempSelectedcountries.includes(code)}
                                onChange={() => handleCountrySelection(code)}
                              />
                              <label className="ml-2">{code}</label>
                            </div>
                          ))}
                        </div>

                        {/* Apply Filter Button */}
                        <div className="flex justify-end mt-4">
                          <HoverButton
                            title={"Cancel"}
                            className={"mr-2"}
                            onClick={() => setIsCountryPopupOpen(false)}
                          />
                          <CustomButton
                            onClick={() => {
                              handleApplyCountry();
                              setIsCountryPopupOpen(false);
                            }}
                            title={"Apply"}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="flex items-center">
                    {/* Dropdown for selecting 'Created By' or 'Updated By' */}
                    <select
                      name="filterType"
                      value={filterType} // State to track the selected filter option
                      onChange={(e) => {
                        setFilterType(e.target.value); // Update the selected filter
                      }}
                      className="text-customGray border border-gray-300 p-2 rounded-md w-[132px] h-[40px] focus:outline-none hover:border-blue-400"
                    >
                      <option value="1">Created By</option>
                      <option value="2">Updated By</option>
                    </select>

                    {/* Button to open the popup */}
                    <button
                      className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer ml-4"
                      onClick={() => {
                        if (filterType === "1") {
                          setIsCreatedByPopupOpen(true);
                          setTempSelectedcreatedby(selectedCreatedBy); // Open the Created By popup
                        } else if (filterType === "2") {
                          setIsUpdatedByPopupOpen(true); // Open the Updated By popup
                          setTempSelectedupdatedby(selectedUpdatedBy); // Set the selected data correctly before opening the popup
                        }
                      }}
                      disabled={!filterType} // Disable button if no filter is selected
                    >
                      <span className="truncate">
                        {filterType === "1"
                          ? selectedCreatedBy.length > 0
                            ? selectedCreatedBy.join(", ")
                            : "Created By"
                          : filterType === "2"
                          ? selectedUpdatedBy.length > 0
                            ? selectedUpdatedBy.join(", ")
                            : "Updated By"
                          : "Select Filter"}
                      </span>
                      <RxDropdownMenu className="ml-2 text-lg" />
                    </button>
                  </div>
                  {isCreatedByPopupOpen && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
                      <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-xl z-50">
                        <div className="flex justify-between items-center mb-4">
                          <h2 className="text-customGray font-semibold">
                            Select Created By
                          </h2>
                          <HoverButton
                            onClick={handleCreatedbyReset}
                            title={"Reset"}
                          />
                        </div>
                        {/* Search and Filter */}
                        <input
                          type="text"
                          className="border border-gray-300 p-2 rounded-md mb-4 w-full"
                          placeholder="Search Created By"
                          value={createdbydatasearchQuery}
                          onChange={(e) =>
                            setcreatedbydatasearchQuery(e.target.value)
                          }
                        />
                        <div className="space-y-2 max-h-80 overflow-y-auto">
                          {filteredcreatedbydatas.map((created) => (
                            <div key={created.id} className="flex items-center">
                              <input
                                type="checkbox"
                                checked={tempSelectedcreatedby.includes(
                                  created.created_by
                                )}
                                onChange={() =>
                                  handleCreatedBySelection(created.created_by)
                                }
                              />
                              <label className="ml-2">
                                {created.created_by}
                              </label>
                            </div>
                          ))}
                        </div>

                        {/* Actions */}
                        <div className="flex justify-end mt-4">
                          <HoverButton
                            title={"Cancel"}
                            className={"mr-2"}
                            onClick={() => setIsCreatedByPopupOpen(false)}
                          />
                          <CustomButton
                            onClick={() => {
                              setIsCreatedByPopupOpen(false);
                              handleApplyCreatedby(); // applyCreatedByFilter(); // Apply the selected filter
                            }}
                            title={"Apply"}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Updated By Popup */}
                  {isUpdatedByPopupOpen && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
                      <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-xl z-50">
                        <div className="flex justify-between items-center mb-4">
                          <h2 className="text-customGray font-semibold">
                            Select Updated By
                          </h2>
                          <HoverButton
                            onClick={handleUpdatedbyReset}
                            title={"Reset"}
                          />
                        </div>
                        {/* Search and Filter */}
                        <input
                          type="text"
                          className="border border-gray-300 p-2 rounded-md mb-4 w-full"
                          placeholder="Search Updated By"
                          value={updatedbysearchquery}
                          onChange={(e) =>
                            setupdatedbysearchquery(e.target.value)
                          }
                        />
                        <div className="space-y-2 max-h-80 overflow-y-auto">
                          {filteredupdatedbydata.map((updated) => (
                            <div key={updated.id} className="flex items-center">
                              <input
                                type="checkbox"
                                checked={tempSelectedupdatedby.includes(
                                  updated.changed_by
                                )} // Ensure it's checking the correct temporary selection state
                                onChange={() =>
                                  handleUpdateBySelection(updated.changed_by)
                                }
                              />
                              <label className="ml-2">
                                {updated.changed_by}
                              </label>
                            </div>
                          ))}
                        </div>
                        {/* Actions */}
                        <div className="flex justify-end mt-4">
                          <HoverButton
                            title={"Cancel"}
                            className={"mr-2"}
                            onClick={() => setIsUpdatedByPopupOpen(false)}
                          />
                          <CustomButton
                            onClick={() => {
                              handleApplyUpdatedby();
                              setIsUpdatedByPopupOpen(false);
                              // applyUpdatedByFilter(); // Apply the selected filter
                            }}
                            title={"Apply"}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {filterType && (
                  <div className="col-span-2 flex justify-between items-center">
                    <div className="flex items-center">
                      <label className="text-customGray mr-2 w-[140px]">
                        {filterType === "createdBy"
                          ? " Date From:"
                          : " Date From:"}
                      </label>
                      <input
                        type="date"
                        name="dateFrom"
                        value={
                          filterType === "1"
                            ? filters.created_date_from
                            : filters.updated_date_from
                        }
                        onChange={(e) => handleFilterDateFrom(e)}
                        className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400"
                      />
                    </div>
                    <div className="flex items-center">
                      <label className="text-customGray mr-2 w-[140px]">
                        {filterType === "createdBy" ? " Date To:" : " Date To:"}
                      </label>
                      <input
                        type="date"
                        name="dateTo"
                        value={
                          filterType === "1"
                            ? filters.created_date_to
                            : filters.updated_date_to
                        }
                        onChange={(e) => handleFilterDateto(e)}
                        className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400"
                      />
                    </div>
                  </div>
                )}
              </div>

              {/* Buttons */}
              <div className="mt-6 flex justify-end space-x-3">
                <HoverButton onClick={toggleFilterPopup} title={"Cancel"} />
                <CustomButton
                  title={"Apply"}
                  onClick={() => {
                    handleApplyFilter();
                    toggleFilterPopup();
                  }}
                />
              </div>
            </div>
          </div>
        )}
        <ConfirmationDialog
          open={showConfirmation}
          title="Confirm Deletion"
          message="Are you sure you want to delete the selected company ?"
          onConfirm={confirmDeletion}
          onCancel={cancelDeletion}
        />
      </div>
    </div>
  );
}
