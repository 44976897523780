import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CashFlowbuttonsConfigs } from "../../../CashFlow/Components/CashFlowbuttonsConfig";
import { IoAddCircleOutline } from "react-icons/io5";
import NoActionLayout from "../../../../../components/NoActionLayout";
import CashFlowActions from "../../../CashFlow/Components/CashFlowActions";
import BtcServices from "../../../../../rest-services/BtcServices";
import Pagination from "../../../../../components/PageNation";
import { IoMdRefresh } from "react-icons/io";
import { MdOutlineFileDownload } from "react-icons/md";
import ReTable from "../../../../../components/Table";
import Header from "../../../../../components/Header";

export default function BTC() {
  const navigate = useNavigate();

  const [data, setData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const pageSize = 10;

  useEffect(() => {
    fetchDataForTable();
  }, [currentPage]);

  const handleNavigate = (path) => {
    navigate(path);
  };

  const buttons = CashFlowbuttonsConfigs.screen1.map((button) => {
    let onClick;
    let title;
    if (button.icon === IoAddCircleOutline) {
      onClick = () => handleNavigate("/btc-add");
      title = "Navigate ";
    }
    //else if (button.icon === MdOutlineFileDownload) {
    //   onClick = () => downloadExcel;
    //   title = "download";
    // } else if (button.icon === IoMdRefresh) {
    //   onClick = () => fetchDataForTable(currentPage);
    //   title = "download";
    // }

    return {
      ...button,
      onClick,
      title,
    };
  });

  const fetchDataForTable = async () => {
    try {
      const response = await BtcServices.getAllData(pageSize, currentPage);
      if (response.status === 200) {
        setData(response.data.data.btcCodes);
        let totalCount = response.data.data.totalCount;
        setTotalCount(totalCount);
        setTotalPages(Math.ceil(totalCount / 10));
      } else {
        console.error("Failed to fetch data:", response.statusText);
      }
    } catch (e) {
      console.error("Error fetching data:", e);
    }
  };

  const columns = [
    {
      Header: "BTC Code",
      accessor: "btcCode",
      visible: true,
      width: 200,
    },
    {
      Header: "Description",
      accessor: "description",
      visible: true,
      width: 200,
    },
    {
      Header: "Inflow",
      accessor: "inflow",
      visible: true,
      width: 200,

      Cell: ({ value }) => (
        <input type="checkbox" checked={value === 1} readOnly />
      ),
    },
    {
      Header: "Outflow",
      accessor: "outflow",
      visible: true,
      width: 200,
      Cell: ({ value }) => (
        <input type="checkbox" checked={value === 1} readOnly />
      ),
    },

    {
      Header: "Statement Type",
      accessor: "statement",
      visible: true,
      width: 200,
    },

    { Header: "Created By", accessor: "createdBy", visible: true, width: 200 },
    {
      Header: "Created Date",
      accessor: "creationDate",
      visible: true,
      width: 15,
      canResize: true,
    },
    {
      Header: "Created Time",
      accessor: "creationTime",
      visible: true,
      width: 15,
      canResize: true,
    },
    {
      Header: "Updated By",
      accessor: "changedBy",
      visible: true,
      width: 15,
      canResize: true,
    },
    {
      Header: "Updated Date",
      accessor: "changedDate",
      visible: true,
      width: 15,
      canResize: true,
    },
    {
      Header: "Updated Time",
      accessor: "changedTime",
      visible: true,
      width: 15,
      canResize: true,
    },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getSerialNumber = (index) => {
    return (currentPage - 1) * 20 + index + 1;
  };

  const [columnSettings, setColumnSettings] = useState(columns);

  const modifiedColumns = columnSettings
    .filter((col) => col.visible)
    .map((col) => ({
      ...col,
      width: col.width,
    }));

  const handleSelectRow = (id) => {
    handleCheckboxChange(id);
  };

  const handleSelectAll = () => {
    handleSelectAllChange();
  };
  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedRows([]);
    } else {
      const allIds = data.map((item) => item.id);
      setSelectedRows(allIds);
    }
    setIsAllSelected(!isAllSelected);
  };

  const handleCheckboxChange = (id) => {
    const updatedSelectedRows = selectedRows.includes(id)
      ? selectedRows.filter((rowId) => rowId !== id)
      : [...selectedRows, id];

    setSelectedRows(updatedSelectedRows);
  };

  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "Global Master Data") {
      console.log("Navigating to /master-data");
      navigate("/master-data");
    } else if (crumb.title === "Bank") {
      console.log("Navigating to /country");
      navigate("/bank");
    } else if (crumb.title === "Primary Data") {
      console.log("Navigating to /country");
      navigate("/master-data");
    } else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };

  return (
    <div>
      <div>
        <Header
          title="Bank Statements > MT940 > BTC"
          onBreadcrumbClick={handleBreadcrumbClick}
        />
      </div>
      <div>
        <CashFlowActions buttons={buttons} />
      </div>
      <div className="p-8">
        <div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            totalCount={totalCount}
          />
        </div>
        <div>
          <ReTable
            data={data}
            columns={modifiedColumns}
            selectedRows={selectedRows}
            onSelectRow={handleSelectRow}
            onSelectAll={handleSelectAll}
            //onRowDoubleClick={handleDoubleClick}
          />
        </div>
      </div>
    </div>
  );
}
