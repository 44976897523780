import React from "react";
import HoverButton from "./HoverButton";
import CustomButton from "./CustomButton";

export default function ConfirmationDialog({ open, title, message, onConfirm, onCancel }) {
  if (!open) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-96">
        <h2 className="text-lg font-semibold  text-customBlue">{title}</h2>
        <p className="text-sm text-gray-600 mt-2">{message}</p>
        <div className="flex justify-end space-x-4 mt-4">
          <HoverButton
            onClick={onCancel}
            title={"Cancel"}
            // className="bg-gray-300 text-sm px-4 py-2 rounded hover:bg-gray-400"
          />
          
          <CustomButton
            onClick={onConfirm}
            title={"Ok"}
            className="w-16"
          />
           
        </div>
      </div>
    </div>
  );
}
