import RestService from "./RestServices";
const basePath = "/admin";
const AdminService = {
  createQuestion: (data) => {
    return RestService.CreateData(`${basePath}/createQuestion`, data);
  },

  getQuestion: () => {
    return RestService.GetAllData(`${basePath}/get-all-Questions`);
  },

  createOrUpdateOTPPolicy: (data) => {
    return RestService.UpdateData(`${basePath}/upsert-otp-policy`, data);
  },

  getOtpPolicy: () => {
    return RestService.GetAllData(`${basePath}/get-otp-policy`);
  },

  getPasswordPolicy: () => {
    return RestService.GetAllData(`${basePath}/get-password-policy`);
  },

  createOrUpdatePasswordPolicy: (data) => {
    return RestService.UpdateData(`${basePath}/upsert-password-policy`, data);
  },

  getAllQuestion: () => {
    return RestService.GetAllData(`${basePath}/get-all-Questions`);
  },
  getAdminStatus: (userid) => {
    return RestService.GetAllData(
      `${basePath}/get-admin-modified?userId=${userid}`
    );
  },
};

export default AdminService;
