import React, { useState } from 'react';
import CashTable from '../Tables/CashInHand/CashTable';
import NoActionLayout from '../../../../components/NoActionLayout';
import CashBalanceActions from '../Props/CashBalanceActions';
import { buttonConfigs } from '../Props/buttonConfig';
import { TbCash } from 'react-icons/tb';
// import { MdCancel } from "react-icons/md

export default function CashScreen() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const data = [
    { col1: '1', col2: '500', col3: '10', col4: '5000' },
    { col1: '2', col2: '200', col3: '8', col4: '1600' },
    { col1: '3', col2: '100', col3: '10', col4: '1000' },
    { col1: '4', col2: '200', col3: '8', col4: '1600' },
    { col1: '5', col2: '100', col3: '10', col4: '1000' },
    { col1: '6', col2: '200', col3: '8', col4: '1600' },
    { col1: '7', col2: '100', col3: '10', col4: '1000' },
    { col1: '8', col2: '200', col3: '8', col4: '1600' },
  ];

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const buttons = buttonConfigs.screen2.map(button => ({
    ...button,
    onClick: button.icon === TbCash ? togglePopup : button.onClick
  }));

  // Calculate the total amount
  const totalAmount = data.reduce((sum, item) => sum + parseFloat(item.col4), 0);

  return (
    <div>
      <div>
        <NoActionLayout title="Cash Balance > Cash in Hand > Cash" />
      </div>
      <div>
        <CashBalanceActions buttons={buttons} />
      </div>
      <CashTable />
      {isPopupOpen && <Popup data={data} onClose={togglePopup} totalAmount={totalAmount} />}
    </div>
  );
}

const Popup = ({ data, onClose, totalAmount }) => {
  return (
    <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-3/4 max-w-4xl relative">
        <button
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
          onClick={onClose}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-sky-500">Denomination</h2>
        </div>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-sky-500 ">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">SL No</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Denomination</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">No</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Amount</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
        
            {data.map((item, index) => (
              <tr key={index} style={{ backgroundColor: index % 2 === 0 ? 'white' : '#f3f4f6' }}>
                <td className="px-6 py-4 whitespace-nowrap">{item.col1}</td>
                <td className="px-6 py-4 whitespace-nowrap">{item.col2}</td>
                <td className="px-6 py-4 whitespace-nowrap">{item.col3}</td>
                <td className="px-6 py-4 whitespace-nowrap">{item.col4}</td>
              </tr>
            ))}
          </tbody>
          <tfoot className="bg-sky-100 mt-5">
            <tr>
              <td className="px-6 py-4 whitespace-nowrap" colSpan="3" style={{ textAlign: 'right', color:'grey' }}>Total Amount</td>
              <td className="px-6 py-4 whitespace-nowrap">{totalAmount}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};
