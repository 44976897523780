import RestService from "./RestServices";

const basePath = "/identifier";
const StatementServices = {
  getAllStatement: async (pageSize, page) => {
    return RestService.GetAllData(
      `${basePath}/get-all?limit=${pageSize}&offset=${(page - 1) * pageSize}`
    );
  },
};
export default StatementServices;
