import React from 'react'
import NoActionLayout from '../../../../../../components/NoActionLayout'
import CashFlowActions from '../../../Components/CashFlowActions'
import { useNavigate } from 'react-router-dom';
import { CashFlowbuttonsConfigs } from '../../../Components/CashFlowbuttonsConfig';
import { IoAddCircleOutline } from 'react-icons/io5';
import NTCodeAutomationTable from '../../../Tables/SetUp/NTCodeAutomationTable';

export default function NTCodeAutomation() {
    const navigate = useNavigate();
    const buttons = CashFlowbuttonsConfigs.screen1.map(button => ({
        ...button,
        onClick: button.icon === IoAddCircleOutline 
          ? () => handleNavigate("/ntcodeautomationadd")
          : button.onClick
      }));
      const handleNavigate = (path) => {
        navigate(path);
      };
  return (
    <div>
    <div>
      <NoActionLayout title={"Cash FLow > Mapping rule ID > NT Code Automation"}/>
      </div>
      <div>
        <CashFlowActions buttons={buttons} />
      </div>
      <NTCodeAutomationTable/>
    </div>
  )
}
