import React from 'react'
import DormantTable from '../Tables/ExceptionalTables/DormantTable'

import NoActionLayout from '../../../../components/NoActionLayout'
import CashBalanceActions from '../Props/CashBalanceActions'
import { buttonConfigs } from '../Props/buttonConfig';

export default function Dormant() {
  const buttons=buttonConfigs.screen1;

  return (
    <div>
       <div>
      <NoActionLayout title="Cash Balance > Exceptions > Dormant" />
</div>
<div>
<CashBalanceActions buttons={buttons}/>
</div>
<DormantTable/>
    </div>
  )
}
