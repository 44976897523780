import React from 'react'
import NegativeBalancesTable from '../Tables/ExceptionalTables/NegativeBalancesTable'

import NoActionLayout from '../../../../components/NoActionLayout'
import CashBalanceActions from '../Props/CashBalanceActions'
import { buttonConfigs } from '../Props/buttonConfig';
export default function Negativebalances() {
  const buttons=buttonConfigs.screen1;
  return (
    <div>
                <div>
      <NoActionLayout title="Cash Balance > Exceptions > Negative Balances" />
</div>
<div>
<CashBalanceActions buttons={buttons}/>
</div>
<NegativeBalancesTable/>
    </div>
  )
}
