import React from 'react'
import CountryWiseTable from '../Tables/CashInBank/CountryWiseTable'

import NoActionLayout from '../../../../components/NoActionLayout'
import CashBalanceActions from '../Props/CashBalanceActions'
import { buttonConfigs } from '../Props/buttonConfig';

export default function CountryWise() {
  const buttons=buttonConfigs.screen1;

  return (
    <div>
      <div>
      <NoActionLayout title="Cash Balance > Cash in Bank > Country Wise" />
</div>
<div>
<CashBalanceActions buttons={buttons}/>
</div>
<CountryWiseTable/>
    </div>
  )
}
