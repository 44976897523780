import React from 'react'

export default function NegativeBalancesTable() {
  return (
    <div>
      <div className='p-4'>
      <table className="min-w-full bg-white">
          <thead className="bg-sky-500 text-primary-foreground text-sm">
            <tr className="bg-primary">
              
              <th className="py-2 px-4 text-center text-white  text-left font-medium">Bank Account NO</th>
              <th className="py-2 px-4 text-center text-white  text-left font-medium">Currency</th>
              <th className="py-2 px-4 text-center text-white  text-left font-medium">Amount</th>
              <th className="py-2 px-4 text-center text-white  text-left font-medium">Amount in Local Currency Equ</th>
              <th className="py-2 px-4 text-center text-white  text-left font-medium">Amount in USD Equ</th>
              <th className="py-2 px-4 text-center text-white  text-left font-medium">Company Code</th>
              <th className="py-2 px-4 text-center text-white  text-left font-medium">Company Short Name</th>
              <th className="py-2 px-4 text-center text-white  text-left font-medium">Company Full Name</th>
              <th className="py-2 px-4 text-center text-white  text-left font-medium">Bank ID</th>
              <th className="py-2 px-4 text-center text-white  text-left font-medium">Bank Account ID</th>
              <th className="py-2 px-4 text-center text-white  text-left font-medium">Bank Country</th>
              <th className="py-2 px-4 text-center text-white  text-left font-medium">Branch ID</th>
              <th className="py-2 px-4 text-center text-white  text-left font-medium">Branch Name</th>

            </tr>
          </thead>
          <tbody className='text-sm'>
            {Array(50).fill().map((_, index) => (
              <tr key={index} className="border-b"  style={{ backgroundColor: index % 2 === 0 ? 'white' : '#f3f4f6' }}>
               
                <td className="py-2 px-4 text-center">Bank Account NO</td>
                <td className="py-2 px-4 text-center">Currency</td>
                <td className="py-2 px-4 text-center">Amount</td>
                <td className="py-2 px-4 text-center">Amount in Local Currency Equ</td>
                <td className="py-2 px-4 text-center">mount in USD Equ</td>
                <td className="py-2 px-4 text-center">Company Code</td>
                <td className="py-2 px-4 text-center">Company Short Name</td>
                <td className="py-2 px-4 text-center">Company Full Name</td>
                <td className="py-2 px-4 text-center">Bank ID</td>
                <td className="py-2 px-4 text-center">Bank Account ID</td>
                <td className="py-2 px-4 text-center">Bank Country</td>
                <td className="py-2 px-4 text-center">Branch ID</td>
                <td className="py-2 px-4 text-center">Branch Name</td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
    </div>
  )
}
