import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../../../../../../components/Header";
import Tabs from "../../../../../../components/Tabs";
import BankBranchServices from "../../../../../../rest-services/BankBranchServices";
import BamServices from "../../../../../../rest-services/BamServices";
import Footer from "../../../../../../components/footer";
import ReTable from "../../../../../../components/Table";
import { useToast } from "../../../../../../components/toast/toast";
import { TbFlag3Filled } from "react-icons/tb";
import CustomDropdown from "../../../../../../components/CustomDropdown";
import ReusableModal from "../../../../../../components/PopupComponent";
import { RxDropdownMenu } from "react-icons/rx";
import { use } from "react";
import { set } from "lodash";
import LabelWithButton from "../../../../../../components/InputButtonField";

export default function BankAccountMasterAdd() {
  const navigate = useNavigate();
  const { actionId, accountIds } = useParams();

  // State to track the active tab
  const [activeTab, setActiveTab] = useState("bankaccount");

  // Function to handle tab clicks
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const tabs = [
    { value: "bankaccount", label: "Bank Account" },
    { value: "bankaddress", label: "Bank Address" },
    { value: "identifiers", label: "Identifiers" },
    { value: "bankstatements", label: "Bank Statements" },
    { value: "validity", label: "Validity" },
    { value: "odcc", label: "OD/CC" },
    { value: "controls", label: "Controls" },
    { value: "textfields", label: "Text Fields" },
  ];

  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "BAM") {
      navigate("/bam");
    } else if (crumb.title === "Bank Master Data") {
      navigate("/account-master");
    } else if (crumb.title === "Account Master") {
      navigate("/account-master");
    } else if (crumb.title === "Add") {
      navigate("/account-master-add");
    } else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };

  //Bank Account
  const isAcNoDisabled = actionId === "1";
  const isAccontDetaislsDisabled = actionId === "2";
  const isCopyMode = actionId === "5";

  const location = useLocation();
  const { mode } = location.state || {};

  const isViewMode = mode === "view";
  const isEditMode = mode === "edit";

  const [unsavedChanges, setUnsavedChanges] = useState(false);

  //DropDown Values
  const [branch, setBranch] = useState([]);
  const [accountType, setAccountType] = useState([]);
  const [zbaType, setZbaType] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [legalEntity, setLegaleEntity] = useState([]);
  const [company, setCompany] = useState([]);
  const [mainAccount, setMainAccount] = useState([]);

  //To save the or assing value for Account details form
  const [reqNo, setReqNo] = useState("");
  const [status, setStatus] = useState();
  const [accountStatus, setAccountStatus] = useState("");
  const [accountStatusFor, setAccountStatusFor] = useState("");
  const [branchID, setBranchID] = useState("");
  const [bankID, setBankID] = useState("");
  const [branchName, setBranchName] = useState("");
  const [bankIDName, setBankIDName] = useState("");
  const [bankName, setBankName] = useState("");
  const [bank, setBank] = useState("");
  const [currencyid, setCurrencyid] = useState("");
  const [accountId, setAccountId] = useState("");
  const [zbaid, setZbaid] = useState("");
  const [overDraft, setOverDraft] = useState(0);
  const [notes, setNotes] = useState("");

  //For an Account NO
  const [accountNo, setAccountNo] = useState("");
  const [iBanNo, setIbanNo] = useState("");
  const [bankAccountId, setBankAccountId] = useState("");
  const [bankAccountDesc, setBankAccountDesc] = useState("");

  //For an Entity
  const [legalEntityID, setLegalEntityID] = useState("");
  const [legalEntityName, setLegalEntityName] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [buGroup, setBuGroup] = useState("");
  const [buGroupName, setBuGroupName] = useState("");

  //To save the or assing value for Address details form
  const [doorNo, setDoorNo] = useState("");
  const [floorNo, setFloorNo] = useState("");
  const [buildingNo, setBuildingNo] = useState("");
  const [buildingName, setBuildingName] = useState("");
  const [landMark, setLandMark] = useState("");
  const [streetNo, setStreetNo] = useState("");
  const [roadNo, setRoadNo] = useState("");
  const [roadName, setRoadName] = useState("");
  const [streetName, setStreetName] = useState("");
  const [areaBlock, setAreaBlock] = useState("");
  const [areaName, setAreaName] = useState("");
  const [country, setCountry] = useState("");
  const [addressLine1, setaddressLine1] = useState("");
  const [addressLine2, setaddressLine2] = useState("");
  const [addressLine3, setaddressLine3] = useState("");
  const [countriesName, setCountriesName] = useState("");
  const [stateProvinces, setStateProvinces] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [postalCode, setpostalCode] = useState("");
  const [district, setdistrict] = useState("");
  const [poBox, setpoBox] = useState("");
  const [state, setState] = useState("");
  const [stateName, setStateName] = useState("");

  //Identfiers
  const [bankBic, setBankBic] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [countryCodeType, setCountryCodeType] = useState("");
  const [micr, setMicr] = useState("");

  //Bank Statements
  const [statementId, setStatementId] = useState("");
  const [statementIdentifier, setStatementIdentifier] = useState("");
  const [lastStatementDate, setLastStatementDate] = useState("");
  const [lastStateClosingbalance, setLastStateClosingBalance] = useState("");
  const [lastStatementNo, setLastStatementNo] = useState("");

  //OD/CC
  const [odLimit, setOdLimit] = useState("");
  const [intrestIndicator, setIntrestIndicator] = useState("");
  const [odDate, setOdDate] = useState("");

  //Controls
  const [minimumBalance, setMinimumBalance] = useState("");
  const [dormant, setDormant] = useState("");
  const [isZbaType, setIsZbaType] = useState(0);
  const [zbaTypeControlls, setZbaTypeControlls] = useState("");
  const [mainBankAccount, setMainBankAccount] = useState("");

  //Text Fields
  const [textFields1, setTextFields1] = useState("");
  const [textFields2, setTextFields2] = useState("");
  const [textFields3, setTextFields3] = useState("");
  const [textFields4, setTextFields4] = useState("");
  const [textFields5, setTextFields5] = useState("");

  //New Pop up Things
  const [searchQuery, setSearchQuery] = useState("");
  const [isBranchModalOpen, setIsBranchModalOpen] = useState(false);
  const [isCurrencyModalOpen, setIsCurrencyModalOpen] = useState(false);
  const [isAccountTypeModalOpen, setIsAccountTypeModalOpen] = useState(false);
  const [isZbaTypeModalOpen, setIsZbaTypeModalOpen] = useState(false);
  const [isLegalEntityModalOpen, setIsLegalEntityModalOpen] = useState(false);
  const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false);

  const [isMainBankModalOpen, setIsMainBankModalOpen] = useState(false);
  //Validity Data
  const [validityId, setValidityId] = useState("");
  const isoToDateInput = (isoDate) => {
    return isoDate ? isoDate.split("T")[0] : ""; // Extracts the YYYY-MM-DD part
  };

  // Validitiy
  const [selectedRows, setSelectedRows] = useState([]);

  //Assingner
  const [assignee, setAssignee] = useState({
    clientId: "",
    id: "",
    assigner: "",
    userId: "",
  });

  //Error Thorwing
  const [errors, setErrors] = useState({});

  //Account No Validation
  const [accountMax, setAccountMax] = useState("");
  const [accountMin, setAccountMin] = useState("");
  const [isIbanRequired, setIsIbanRequired] = useState(0);

  useEffect(() => {
    fetchAssignee();
    fetchBranches();
    fetcAccountType();
    fetchZbaType();
    fetchCurrencies();
    fetchLegalEntity();
  }, []);

  useEffect(() => {
    if (accountIds) {
      handleFetchDataForFilled();
      getValidity(accountIds);
    }
  }, [accountIds]);

  const fetchAssignee = async () => {
    try {
      const userDetailString = sessionStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          clientId: userDetail.clientId || "",
          id: userDetail.id || "",
          assigner: userDetail.username || "",
          userId: userDetail.userId || "",
        };
        setAssignee(assigneeData);
      } else {
      }
    } catch (error) {}
  };

  const getValidity = async (accountIds) => {
    try {
      const response = await BamServices.getValidity(accountIds);

      setValidityId(response.data.data);
    } catch (error) {}
  };

  const checkLimits = () => {
    if (accountMin !== null && accountMax !== null) {
      if (accountNo.length > accountMax) {
        toast(
          "error",
          `Account number exceeds the maximum value of ${accountMax}`
        );
      }

      if (accountNo.length < accountMin) {
        toast(
          "error",
          `Account number is below the minimum value of ${accountMin}`
        );
      }
    }
  };
  const handleFetchDataForFilled = async () => {
    try {
      const response = await BamServices.getBankAccountById(accountIds);
      if (response && response.data) {
        const account = response.data.data;
        if (isCopyMode) {
          setStatus(0);
          setAccountStatus("Requested");
        } else {
          setStatus(account[0].status);
          setAccountStatus(account[0].accountStatus);
        }
        setAccountId(account[0].accountID);
        setReqNo(account[0].reqNo);

        setBranchID(account[0].branchID);
        setBankID(account[0].bankID);
        setBranchName(account[0].branchName);
        setBankIDName(account[0].bankIDName);
        setBankName(account[0].bankName);
        setBank(account[0].bank);
        setCurrencyid(account[0].currencyID);
        setZbaid(account[0].zbaID);
        setOverDraft(account[0].overDraft);
        setNotes(account[0].notes);
        setLegalEntityID(account[0].legalEntityID);
        setLegalEntityName(account[0].legalEntityName);
        setCompanyId(account[0].companyID);
        setCompanyName(account[0].companyName);
        setBuGroup(account[0].buGroup);
        setBuGroupName(account[0].buGroupName);
        if (actionId !== "5" && actionId !== "6") {
          setAccountNo(account[0].accountNo);
          setIbanNo(account[0].iBanNo);
          setBankAccountId(account[0].bankAccountId);
          setBankAccountDesc(account[0].bankAccountDescription);
        }
        setAccountMax(account[0].accountMax);
        setAccountMin(account[0].accountMin);
        setIsIbanRequired(account[0].isBanRequired);

        //Bank Statements
        setStatementId(account[1].statementId || "");
        setStatementIdentifier(account[1].statementIdentifier || "");
        setLastStatementDate(
          isoToDateInput(account[1].lastStatementDate) || ""
        );
        setLastStateClosingBalance(account[1].lastStateClosingbalance || "");
        setLastStatementNo(account[1].lastStatementNo || "");

        //Controlls
        setMinimumBalance(account[2].minimumBalance || "");
        setDormant(account[2].dormant || "");
        setZbaTypeControlls(account[2].zbaTypeControlls || "");
        setMainBankAccount(account[2].mainBankAccount || "");
        //Address
        setDoorNo(account[3].doorNo || "");
        setFloorNo(account[3].floorNo || "");
        setBuildingNo(account[3].buildingNo || "");
        setBuildingName(account[3].buildingName || "");
        setLandMark(account[3].landMark || "");
        setStreetNo(account[3].streetNo || "");
        setRoadNo(account[3].roadNo || "");
        setRoadName(account[3].roadName || "");
        setStreetName(account[3].streetName || "");
        setAreaBlock(account[3].areaBlock || "");
        setAreaName(account[3].areaName || "");
        setCountry(account[3].country || "");
        setaddressLine1(account[3].addressLine1 || "");
        setaddressLine2(account[3].addressLine2 || "");
        setaddressLine3(account[3].addressLine3 || "");
        setCountriesName(account[3].countriesName || "");
        setStateProvinces(account[3].stateProvinces || "");
        setZipCode(account[3].zipCode || "");
        setpostalCode(account[3].postalCode || "");
        setdistrict(account[3].district || "");
        setpoBox(account[3].poBox || "");
        setState(account[3].state || "");
        setStateName(account[3].stateName || "");
        //Indedifiers
        setBankBic(account[4].bankBic || "");
        setCountryCode(account[4].countryCode || "");
        setCountryCodeType(account[4].countryCodeType || "");
        setMicr(account[4].micr || "");
        //ODCC
        setOdLimit(account[5].odLimit || "");
        setIntrestIndicator(account[5].intrestIndicator || "");
        setOdDate(isoToDateInput(account[5].odDate) || "");
        //TextFields
        setTextFields1(account[6].textFields1 || "");
        setTextFields2(account[6].textFields2 || "");
        setTextFields3(account[6].textFields3 || "");
        setTextFields4(account[6].textFields4 || "");
        setTextFields5(account[6].textFields5 || "");
      }
    } catch (error) {}
  };

  const fetchBranches = async () => {
    try {
      const response = await BankBranchServices.getAllBankBranch(
        "/get-all-bankbranch"
      );
      if (response && response.data) {
        setBranch(response.data);
        if (isAcNoDisabled) {
          setAccountStatus("Requested");
          setAccountStatusFor("New Request");
        }
      }
    } catch (error) {
      console.error("Error fetching branches:", error);
    }
  };

  const fetcAccountType = async () => {
    try {
      const response = await BamServices.getAccountType();
      if (response && response.data) {
        setAccountType(response.data.data);
      }
    } catch (error) {}
  };

  const fetchZbaType = async () => {
    try {
      const response = await BamServices.getAllZBATypes();
      if (response && response.data) {
        setZbaType(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCurrencies = async () => {
    try {
      const response = await BamServices.getAllCurrency();
      if (response && response.data) {
        setCurrency(response.data.data);
      }
    } catch (error) {}
  };

  const fetchLegalEntity = async () => {
    try {
      const response = await BamServices.getAllLegalEntity();
      if (response && response.data) {
        setLegaleEntity(response.data);
      }
    } catch (error) {}
  };

  const handleBranchChange = async (value) => {
    setBranchID(value);
    setUnsavedChanges(true);
    fetchMainBankAccount();
    const selectedBranch = branch.find(
      (item) => item.id.toString() === value.toString()
    );
    setStatus(0);
    setBranchName(selectedBranch.branchName);
    setBankName(selectedBranch.BankName);
    setBank(selectedBranch.Bank);
    setBankID(selectedBranch.BankId);
    if (selectedBranch.addresses.length > 0) {
      const address = selectedBranch.addresses[0];
      setDoorNo(address.doorNo || "");
      setFloorNo(address.floorNo || "");
      setBuildingNo(address.buildingNo || "");
      setBuildingName(address.buildingName || "");
      setLandMark(address.landMark || "");
      setStreetNo(address.streetNo || "");
      setRoadNo(address.roadNo || "");
      setRoadName(address.roadName || "");
      setStreetName(address.streetName || "");
      setAreaBlock(address.areaBlock || "");
      setAreaName(address.areaName || "");
      setStateProvinces(address.state || "");
      setCountry(address.countryCode || "");
      setZipCode(address.zipCode || "");
      setaddressLine1(address.addressLine1 || "");
      setaddressLine2(address.addressLine2 || "");
      setaddressLine3(address.addressLine3 || "");
      setdistrict(address.district || "");
      setpostalCode(address.postalCode || "");
      setZipCode(address.zipCode || "");
      setpoBox(address.poBox || "");
    }
    if (selectedBranch.specificchecks.length > 0) {
      setIbanNo(selectedBranch.specificchecks[0].IBAN_required || "");
      setAccountMin(
        selectedBranch.specificchecks[0].bankAccountMinLength || ""
      );
      setAccountMax(
        selectedBranch.specificchecks[0].bankAccountMaxLength || ""
      );
    }
  };

  const handleLegalEntityChange = async (value) => {
    console.log(value);
    setLegalEntityID(value);
    setUnsavedChanges(true);
    const selectedEntity = legalEntity.find(
      (item) => item.id.toString() === value.toString()
    );
    setLegalEntityName(selectedEntity.LGE_Description);
    const response = await BamServices.getAssociatedCompany(
      selectedEntity.LGE_Code
    );

    if (response.data !== null) {
      console.log(response.data);
      setCompany(
        Array.isArray(response.data) ? response.data : [response.data]
      );
    }
  };

  useEffect(() => {
    fetchBankAccountCompany(legalEntityID);
  }, [legalEntityID]);
  /// Need to work on this tommorrow
  const fetchBankAccountCompany = async (entityCode) => {
    try {
      const selectedEntity = legalEntity.find((item) => item.id === entityCode);
      const response = await BamServices.getAssociatedCompany(
        selectedEntity.LGE_Code
      );
      if (response.data !== null) {
        setCompany(
          Array.isArray(response.data) ? response.data : [response.data]
        );
      }
    } catch (error) {}
  };

  const handleCompanyChange = async (event) => {
    setCompanyId(event.target.value);
    setUnsavedChanges(true);
    const selectedCompany = company.find(
      (item) => item.companyCode.toString() === event.target.value
    );
    setCompanyName(selectedCompany.companyShortName);
    setBuGroup(selectedCompany.buGroupCode);
    setBuGroupName(selectedCompany.bu_Description);
  };
  const columns = React.useMemo(() => [
    {
      Header: "SNo",
      width: 50,
      Cell: ({ row }) => row.index + 1,
    },
    {
      Header: "Bank Account Code",
      accessor: "bankAccountCode",
      width: 150,
    },
    {
      Header: "Company Code",
      accessor: "companyCode",
      width: 150,
    },
    {
      Header: "Status",
      accessor: "status",
      width: 200,
    },
    {
      Header: "Valid From",
      accessor: "validFrom",
      width: 200,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if day < 10
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Adds leading zero if month < 10
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
      },
    },
    {
      Header: "Valid To",
      accessor: "validTo",
      width: 200,
      Cell: ({ value }) => {
        if (!value) return "";
        if (typeof value === "string" && isNaN(Date.parse(value))) {
          return value;
        }
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if day < 10
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Adds leading zero if month < 10
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
      },
    },
    {
      Header: "Updated By",
      accessor: "updatedBy",
      width: 200,
    },
    {
      Header: "Updated Date",
      accessor: "updatedDate",
      width: 200,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if day < 10
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Adds leading zero if month < 10
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
      },
    },
    {
      Header: "Updated Time",
      accessor: "updateTime",
      width: 200,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return date.toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        });
      },
    },
  ]);

  // Function to handle row selection
  const onSelectRow = (rowId) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(rowId)
        ? prevSelectedRows.filter((id) => id !== rowId)
        : [...prevSelectedRows, rowId]
    );
  };

  // Function to handle double-click on a row
  const onRowDoubleClick = (rowId) => {
    console.log("Double clicked row with id:", rowId);
  };

  const { toast } = useToast();
  const validateForm = () => {
    const newErrors = {};
    //if (!reqNo) newErrors.reqNo = "Request Number is required.";
    if (!accountStatus) newErrors.accountStatus = "AccountStatus is required."; // Validate description
    if (!branchID) newErrors.branchID = "branchID is required.";
    if (!currencyid) newErrors.currencyid = "currencyid is required.";
    if (!legalEntityID) newErrors.legalEntityID = "legalEntityID is required.";

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      toast("warning", "Missing Required Fields");
    }
    return Object.keys(newErrors).length === 0;
  };

  const validateUpdateForm = () => {
    const newErrors = {};
    //if (!reqNo) newErrors.reqNo = "Request Number is required.";
    if (!companyId) newErrors.companyId = "CompanyId is required.";
    if (isIbanRequired === 1) {
      if (!iBanNo) {
        newErrors.iBanNo = "IBAN Number is required.";
      }
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      toast("warning", "Missing Required Fields");
    }
    return Object.keys(newErrors).length === 0;
  };
  const resetForm = () => {
    try {
      // Resetting general account details
      setStatus(0);
      setAccountStatus("");
      setAccountId("");
      setReqNo("");
      setBranchID("");
      setBankID("");
      setBranchName("");
      setBankIDName("");
      setBankName("");
      setBank("");
      setCurrencyid("");
      setZbaid("");
      setOverDraft("");
      setNotes("");
      setLegalEntityID("");
      setLegalEntityName("");
      setCompanyId("");
      setCompanyName("");
      setBuGroup("");
      setBuGroupName("");
      setAccountNo("");
      setIbanNo("");
      setBankAccountId("");
      setBankAccountDesc("");

      // Resetting bank statements
      setStatementId("");
      setStatementIdentifier("");
      setLastStatementDate("");
      setLastStateClosingBalance("");
      setLastStatementNo("");

      // Resetting controls
      setMinimumBalance("");
      setDormant("");
      setZbaTypeControlls("");
      setMainBankAccount("");

      // Resetting address fields
      setDoorNo("");
      setFloorNo("");
      setBuildingNo("");
      setBuildingName("");
      setLandMark("");
      setStreetNo("");
      setRoadNo("");
      setRoadName("");
      setStreetName("");
      setAreaBlock("");
      setAreaName("");
      setCountry("");
      setaddressLine1("");
      setaddressLine2("");
      setaddressLine3("");
      setCountriesName("");
      setStateProvinces("");
      setZipCode("");
      setpostalCode("");
      setdistrict("");
      setpoBox("");
      setState("");
      setStateName("");

      // Resetting identifiers
      setBankBic("");
      setCountryCode("");
      setCountryCodeType("");
      setMicr("");

      // Resetting ODCC fields
      setOdLimit("");
      setIntrestIndicator("");
      setOdDate("");

      // Resetting text fields
      setTextFields1("");
      setTextFields2("");
      setTextFields3("");
      setTextFields4("");
      setTextFields5("");
    } catch (error) {
      console.error("Error resetting form:", error);
    }
  };

  const handleSave = async (e, isContinue) => {
    try {
      e.preventDefault();

      if (!validateForm()) return;

      const payload = {
        odLimit,
        intrestIndicator,
        odDate,
        // Controls
        minimumBalance,
        dormant,
        isZbaType,
        zbaTypeControlls,
        mainBankAccount,
        // Text Fields
        textFields1,
        textFields2,
        textFields3,
        textFields4,
        textFields5,
        bankBic,
        countryCode,
        countryCodeType,
        micr,
        // Bank Statements
        statementId,
        statementIdentifier,
        lastStatementDate,
        lastStateClosingbalance,
        lastStatementNo,
        // Address Information
        doorNo,
        floorNo,
        buildingNo,
        buildingName,
        landMark,
        streetNo,
        roadNo,
        roadName,
        streetName,
        areaBlock,
        areaName,
        country,
        addressLine1,
        addressLine2,
        addressLine3,
        countriesName,
        stateProvinces,
        zipCode,
        postalCode,
        district,
        poBox,
        state,
        stateName,
        // Additional Information
        clientId: assignee.clientId,
        status: 0,
        accountStatus: accountStatus === "Requested" ? 0 : 1,
        accountStatusFor,
        branchID,
        bankID,
        branchName,
        bankIDName: bankName,
        bankName,
        bank,
        currencyid,
        accountId,
        zbaid,
        overDraft,
        notes,
        createdBy: assignee.userId,
        createdDate: new Date().toISOString(),

        // Legal Entity Information
        legalEntityID,
        legalEntityName,
        companyId,
        companyName,
        buGroup,
        buGroupName,
        accountMin,
        accountMax,
        isBanRequired: isIbanRequired,
        actionId,

        ...(actionId === "6" && {
          accountNo: accountNo,
          ibanNo: iBanNo,
          bankAccountDes: bankAccountDesc,
        }),
      };

      const response = await BamServices.addBankAccountMaster(payload);
      if (response.status === 200) {
        toast("success", "Data Saved Successfully");
        if (!isContinue) {
          navigate("/account-master");
        } else {
          resetForm();
        }
      }
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const handleBankAccoutIdUpdate = async () => {
    try {
      if (!validateUpdateForm()) return;
      const data = {
        id: accountIds,
        accountNo: accountNo,
        ibanNo: iBanNo,
        bankAccountDes: bankAccountDesc,
        companyId: companyId,
        companyName: companyName,
        buGroup: buGroup,
        buGroupName: buGroupName,
        changedBy: assignee.userId,
      };
      const response = await BamServices.updateBankId(data);
      if (response.status === 200) {
        toast("success", "Data Updated Successfully");
        navigate("/account-master");
      }
    } catch (error) {}
  };

  const handleSaveAndEdit = async () => {
    try {
      const data = {
        id: accountIds,
        accountId,
        zbaid,
        overDraft,
        notes,
        odLimit,
        intrestIndicator,
        odDate,
        minimumBalance,
        dormant,
        mainBankAccount,
        textFields1,
        textFields2,
        textFields3,
        textFields4,
        textFields5,
        changedBy: assignee.userId,
      };
      const reponse = await BamServices.editBankAccount(data);
      if (reponse.status === 200) {
        toast("success", "Data Edited Successfully");
        navigate("/account-master");
      }
    } catch (error) {}
  };

  const handleSaveClick = (actionId, e) => {
    if (actionId === "1") {
      handleSave(e);
    } else if (actionId === "2") {
      handleBankAccoutIdUpdate();
    } else if (actionId === "3") {
      handleSaveAndEdit();
    } else if (actionId === "5") {
      handleSave(e);
    } else if (actionId === "6") {
      handleSave(e);
    }
  };

  useEffect(() => {
    fetchMainBankAccount();
  }, [assignee]);
  // To:Do
  const fetchMainBankAccount = async () => {
    try {
      if (!assignee.clientId) {
        return;
      }
      const response = await BamServices.mainBankAccount(assignee.clientId);
      if (response.status === 200) {
        console.log(response.data.bankAccounts);
        setMainAccount(response.data.bankAccounts);
      } else {
        console.log(response);
      }
    } catch (error) {
      console.error("Error fetching main bank account:", error);
    }
  };

  const getApprovedIcon = () => {
    let color = "black"; // Default color
    // Determine the color based on the approved status
    if (status === 3) {
      color = "#29CB00"; // Green
    } else if (status === 0) {
      color = "gray"; // Gray
    } else if (status === 1) {
      color = "yellow"; // Yellow
    } else if (status === 2) {
      color = "orange"; // Orange
    } else if (status === 4) {
      color = "red"; // Red
    }

    return <TbFlag3Filled style={{ color, marginLeft: "8px" }} />; // Return the icon with the determined color
  };
  const [openDropdown, setOpenDropdown] = useState(null);
  const handleToggle = (dropdownId) => {
    setOpenDropdown((prev) => (prev === dropdownId ? null : dropdownId)); // Toggle dropdown
  };

  const statementTypeOptions = [
    { value: "1", label: "Opening" },
    { value: "2", label: "Closing" },
  ];

  const mainBankAccountOptions = Array.isArray(mainAccount)
    ? mainAccount.map((bank) => ({
        value: bank.bankAccountId,
        label: bank.bankAccountId,
      }))
    : [];
  const overDraftOptions = [
    { value: 0, label: "No" },
    { value: 1, label: "Yes" },
  ];

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const [isStatementTypeModalOpen, setIsStatementTypeModalOpen] =
    useState(false);

  return (
    <div>
      <div>
        <Header
          title={`BAM > Bank Master Data > Account Master > ${
            actionId === "1"
              ? "Add"
              : actionId === "2"
              ? "ID Update"
              : actionId === "5"
              ? "Copy"
              : actionId === "6"
              ? "Special Copy"
              : isEditMode
              ? "Edit"
              : "View"
          }`}
          onBreadcrumbClick={handleBreadcrumbClick}
          extraContent={actionId ? getApprovedIcon() : null}
          hasUnsavedChanges={unsavedChanges}
        />
      </div>
      <Tabs tabs={tabs} activeTab={activeTab} onTabClick={handleTabClick} />
      {/*Bank Account*/}
      {activeTab === "bankaccount" && (
        <div className="p-4 mb-40">
          {/** Account Details */}
          <div className="mb-6 mx-10 border border-gray-300 p-5 rounded-lg">
            <h3 className="text-lg font-semibold text-gray-500 mb-4">
              Account details
            </h3>
            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  New Req No{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  type="text"
                  value={reqNo}
                  // onClick={() => {
                  //   generateReqNo();
                  //   if (errors.reqNo) {
                  //     setErrors((prevErrors) => ({
                  //       ...prevErrors,
                  //       reqNo: "",
                  //     }));
                  //   }
                  // }}
                  readOnly={isViewMode || isEditMode}
                  disabled={isAccontDetaislsDisabled}
                  className={`border ${
                    errors.reqNo ? "border-red-500" : "border-gray-300"
                  } p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`}
                />
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Account Status{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  type="text"
                  value={accountStatus === 0 ? "Requested" : accountStatus}
                  onChange={() => {
                    if (errors.accountStatus) {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        accountStatus: "",
                      }));
                      setUnsavedChanges(true);
                    }
                  }}
                  readOnly={isViewMode || isEditMode}
                  disabled={isAccontDetaislsDisabled}
                  placeholder="4"
                  className={`border ${
                    errors.accountStatus ? "border-red-500" : "border-gray-300"
                  } rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`}
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Branch ID <span className="text-red-500">*</span>
                </label>
                <span className="ml-4">:</span>
                <button
                  onClick={() => setIsBranchModalOpen(true)}
                  disabled={
                    isAccontDetaislsDisabled || isViewMode || isEditMode
                  }
                  className={`border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between text-customGray ${
                    errors.branchID
                      ? "border-red-500 hover:border-red-500"
                      : "border-gray-300 hover:border-blue-400"
                  }`}
                >
                  <span className="font-small text-customGray text-sm">
                    {branch.find((branch) => branch.id == branchID)
                      ?.bankBranch || "Select Branch Id"}
                  </span>
                  <RxDropdownMenu className="ml-2" />
                </button>

                <ReusableModal
                  title="Select Branch ID"
                  isOpen={isBranchModalOpen}
                  onClose={() => {
                    setIsBranchModalOpen(false);
                    setSearchQuery("");
                  }}
                  onSave={() => {
                    setIsBranchModalOpen(false);
                    setSearchQuery("");
                  }}
                  searchPlaceholder="Search Branch..."
                  searchQuery={searchQuery}
                  onSearchChange={handleSearchChange}
                  isViewMode={isViewMode}
                >
                  {branch && branch.length > 0 ? (
                    branch
                      .filter((branch) =>
                        branch.id
                          ?.toString()
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase())
                      )
                      .map((branch, index) => (
                        <div
                          key={branch.id}
                          className={`flex items-center ${
                            index % 2 === 0 ? "bg-sky-blue-200" : ""
                          }`}
                        >
                          <input
                            type="radio"
                            name="BranchID"
                            value={branch.id}
                            checked={branchID == branch.id}
                            onChange={(e) => {
                              handleBranchChange(e.target.value);
                              if (errors.branchID) {
                                setErrors((prevErrors) => ({
                                  ...prevErrors,
                                  branchID: "",
                                }));
                              }
                              setUnsavedChanges(true);
                            }}
                            className="mr-2 accent-customBlue"
                          />
                          <label>{branch.bankBranch || "N/A"}</label>
                        </div>
                      ))
                  ) : (
                    <p>{branch ? "No Branch Found" : "Loading Branch..."}</p>
                  )}
                </ReusableModal>
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Bank ID <span className="text-red-500">*</span>
                </label>
                <span className="ml-4">:</span>
                <input
                  type="text"
                  placeholder="B name"
                  value={bankID}
                  readOnly={isViewMode || isEditMode}
                  disabled={isAccontDetaislsDisabled}
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Branch Name <span className="text-red-500">*</span>
                </label>
                <span className="ml-4">:</span>
                <input
                  type="text"
                  placeholder="2A"
                  value={branchName}
                  readOnly={isViewMode || isEditMode}
                  disabled={isAccontDetaislsDisabled}
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Bank ID Name <span className="text-red-500">*</span>
                </label>
                <span className="ml-4">:</span>
                <input
                  value={bankName}
                  readOnly={isViewMode || isEditMode}
                  type="text"
                  placeholder="B name"
                  disabled={isAccontDetaislsDisabled}
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Currency <span className="text-red-500">*</span>
                </label>
                <span className="ml-4">:</span>

                <button
                  onClick={() => setIsCurrencyModalOpen(true)}
                  disabled={
                    isAccontDetaislsDisabled || isViewMode || isEditMode
                  }
                  className={`border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between text-customGray ${
                    errors.currencyid
                      ? "border-red-500 hover:border-red-500"
                      : "border-gray-300 hover:border-blue-400"
                  }`}
                >
                  <span className="font-small text-customGray text-sm">
                    {currencyid || "Select Currency Code"}
                  </span>
                  <RxDropdownMenu className="ml-2" />
                </button>

                <ReusableModal
                  title="Select Currency Code"
                  isOpen={isCurrencyModalOpen}
                  onClose={() => {
                    setIsCurrencyModalOpen(false);
                    setSearchQuery("");
                  }}
                  onSave={() => {
                    setIsCurrencyModalOpen(false);
                    setSearchQuery("");
                  }}
                  searchPlaceholder="Search  Currency..."
                  searchQuery={searchQuery}
                  onSearchChange={handleSearchChange}
                  isViewMode={isViewMode}
                >
                  {currency && currency.length > 0 ? (
                    currency
                      .filter((currency) =>
                        currency.currencyCode
                          ?.toString()
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase())
                      )
                      .map((currency, index) => (
                        <div
                          key={currency.currencyCode}
                          className={`flex items-center ${
                            index % 2 === 0 ? "bg-sky-blue-200" : ""
                          }`}
                        >
                          <input
                            type="radio"
                            name="BranchID"
                            value={currency.currencyCode}
                            checked={currencyid == currency.currencyCode}
                            onChange={(e) => {
                              setCurrencyid(e.target.value);
                              if (errors.currencyid) {
                                setErrors((prevErrors) => ({
                                  ...prevErrors,
                                  currencyid: "",
                                }));
                              }
                              setUnsavedChanges(true);
                            }}
                            className="mr-2 accent-customBlue"
                          />
                          <label>{currency.currencyCode || "N/A"}</label>
                        </div>
                      ))
                  ) : (
                    <p>{branch ? "No Branch Found" : "Loading Branch..."}</p>
                  )}
                </ReusableModal>
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Bank <span className="text-red-500">*</span>
                </label>
                <span className="ml-4">:</span>
                <input
                  disabled={isAccontDetaislsDisabled}
                  type="text"
                  value={bank}
                  readOnly={isViewMode || isEditMode}
                  placeholder="B name"
                  className="border border-gray-300 p-1.5 rounded w-80 h-9 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Account Type{" "}
                </label>
                <span className="ml-4">:</span>
                <button
                  onClick={() => setIsAccountTypeModalOpen(true)}
                  disabled={isAccontDetaislsDisabled || isViewMode}
                  className={`border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between text-customGray ${"border-gray-300 hover:border-blue-400"}`}
                >
                  <span className="font-small text-customGray text-sm">
                    {accountId || "Select Account Type"}
                  </span>
                  <RxDropdownMenu className="ml-2" />
                </button>

                <ReusableModal
                  title="Select Account Type"
                  isOpen={isAccountTypeModalOpen}
                  onClose={() => {
                    setIsAccountTypeModalOpen(false);
                    setSearchQuery("");
                  }}
                  onSave={() => {
                    setIsAccountTypeModalOpen(false);
                    setSearchQuery("");
                  }}
                  searchPlaceholder="Search Account Type..."
                  searchQuery={searchQuery}
                  onSearchChange={handleSearchChange}
                  isViewMode={isViewMode}
                >
                  {accountType && accountType.length > 0 ? (
                    accountType
                      .filter((account) =>
                        account.Account_Type?.toString()
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase())
                      )
                      .map((account, index) => (
                        <div
                          key={account.Account_Type}
                          className={`flex items-center ${
                            index % 2 === 0 ? "bg-sky-blue-200" : ""
                          }`}
                        >
                          <input
                            type="radio"
                            name="AccountType"
                            value={account.Account_Type}
                            checked={accountId === account.Account_Type} // Compare with account.Account_Type
                            onChange={(e) => {
                              setAccountId(e.target.value); // Update state with selected Account_Type
                              setUnsavedChanges(true); // Mark changes as unsaved
                            }}
                            className="mr-2 accent-customBlue"
                          />
                          <label>{account.Account_Type || "N/A"}</label>{" "}
                        </div>
                      ))
                  ) : (
                    <p>{accountType}</p> // Adjusted error message
                  )}
                </ReusableModal>
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Bank Name <span className="text-red-500">*</span>
                </label>
                <span className="ml-4">:</span>
                <input
                  disabled={isAccontDetaislsDisabled}
                  value={bankName}
                  readOnly={isViewMode || isEditMode}
                  type="text"
                  placeholder="B name"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  ZBA Type{" "}
                </label>
                <span className="ml-4">:</span>
                <button
                  onClick={() => setIsZbaTypeModalOpen(true)}
                  disabled={isAccontDetaislsDisabled || isViewMode}
                  className={`border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between text-customGray ${"border-gray-300 hover:border-blue-400"}`}
                >
                  <span className="font-small text-customGray text-sm">
                    {zbaid || "Select Zba Type"}
                  </span>
                  <RxDropdownMenu className="ml-2" />
                </button>

                <ReusableModal
                  title="Select Account Type"
                  isOpen={isZbaTypeModalOpen}
                  onClose={() => {
                    setIsZbaTypeModalOpen(false);
                    setSearchQuery("");
                  }}
                  onSave={() => {
                    setIsZbaTypeModalOpen(false);
                    setSearchQuery("");
                  }}
                  searchPlaceholder="Search ZBA Type..."
                  searchQuery={searchQuery}
                  onSearchChange={handleSearchChange}
                  isViewMode={isViewMode}
                >
                  {zbaType && zbaType.length > 0 ? (
                    zbaType
                      .filter((zba) =>
                        zba.ZBA_ID?.toString()
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase())
                      )
                      .map((zba, index) => (
                        <div
                          key={zba.ZBA_ID}
                          className={`flex items-center ${
                            index % 2 === 0 ? "bg-sky-blue-200" : ""
                          }`}
                        >
                          <input
                            type="radio"
                            name="AccountType"
                            value={zba.ZBA_ID}
                            checked={zbaid == zba.ZBA_ID} // Compare with account.Account_Type
                            onChange={(e) => {
                              setZbaid(e.target.value); // Update state with selected Account_Type
                              setUnsavedChanges(true); // Mark changes as unsaved
                            }}
                            className="mr-2 accent-customBlue"
                          />
                          <label>{zba.ZBA_ID || "N/A"}</label>{" "}
                        </div>
                      ))
                  ) : (
                    <p>{"No Data Found"}</p>
                  )}
                </ReusableModal>
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Over Draft{" "}
                </label>
                <span className="ml-4">:</span>
                <CustomDropdown
                  value={
                    overDraftOptions.find(
                      (option) => option.value === overDraft
                    ) || null
                  }
                  options={overDraftOptions}
                  onChange={(value) => setOverDraft(value)} // Update the overdraft value
                  isOpen={openDropdown === "overDraft"}
                  onToggle={() => handleToggle("overDraft")}
                  isDisabled={isAccontDetaislsDisabled || isViewMode}
                  className="ml-4 border-gray-300"
                  placeholder="Select Overdraft"
                />
              </div>
            </div>

            <div className="flex items-center mt-4">
              <label className="w-52 2xl:w-48 text-sm font-medium text-gray-500">
                Notes
              </label>
              <span className="ml-4">:</span>
              <input
                disabled={isAccontDetaislsDisabled}
                type="text"
                value={notes}
                readOnly={isViewMode}
                maxLength={100}
                onChange={(e) => {
                  setNotes(e.target.value);
                  setUnsavedChanges(true);
                }}
                className="border border-gray-300 p-1.5 rounded 2xl:w-[72%] w-full h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 "
              />
            </div>
          </div>
          {/** Account No*/}
          <div className="mb-6 border mx-10 border-gray-300 p-5 rounded-lg">
            <h3 className="text-lg font-semibold text-gray-500 mb-4">
              Account No
            </h3>
            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Bank Account ID
                </label>
                <span className="ml-4">:</span>
                <input
                  type="text"
                  value={bankAccountId}
                  readOnly={
                    isAccontDetaislsDisabled ||
                    isViewMode ||
                    isEditMode ||
                    isCopyMode
                  }
                  // onChange={(e) => setBankAccountId(e.target.value)}
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                  disabled={isAcNoDisabled}
                />
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Bank Account ID Description{" "}
                  <span className="text-red-500">*</span>
                </label>
                <span className="ml-4">:</span>
                <input
                  type="text"
                  readOnly={isViewMode || isEditMode || isCopyMode}
                  value={bankAccountDesc}
                  onChange={(e) => {
                    setBankAccountDesc(e.target.value);
                    setUnsavedChanges(true);
                  }}
                  placeholder="4"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                  disabled={isAcNoDisabled}
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Account No <span className="text-red-500">*</span>
                </label>
                <span className="ml-4">:</span>
                <input
                  type="text"
                  value={accountNo}
                  onBlur={checkLimits}
                  readOnly={isViewMode || isEditMode || isCopyMode}
                  onChange={(e) => {
                    setAccountNo(e.target.value);
                    setUnsavedChanges(true);
                  }}
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                  disabled={isAcNoDisabled}
                />
              </div>
            </div>
            <div className="flex items-center mt-4">
              <label className="w-52 2xl:w-48 text-sm font-medium text-gray-500">
                IBAN No <span className="text-red-500">*</span>
              </label>
              <span className="ml-4">:</span>
              <input
                type="text"
                value={iBanNo}
                readOnly={isViewMode || isEditMode || isCopyMode}
                onChange={(e) => {
                  setIbanNo(e.target.value);
                  setUnsavedChanges(true);
                  if (errors.iBanNo) {
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      iBanNo: "",
                    }));
                  }
                }}
                className={`border ${
                  errors.iBanNo ? "border-red-500" : "border-gray-300"
                } p-1.5 rounded 2xl:w-[72%] w-full h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4`}
                disabled={isAcNoDisabled}
              />
            </div>
            {errors.iBanNo && (
              <span className="text-red-500 text-sm ml-4">{errors.iBanNo}</span>
            )}
          </div>
          {/* For An Entity */}
          <div className="mb-6 border border-gray-300 p-5 rounded-lg mx-10">
            <h3 className="text-lg font-semibold text-gray-500 mb-4">
              For an Entity
            </h3>

            <div className="grid grid-cols-2 gap-4 mb-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Legal Entity <span className="text-red-500">*</span>
                </label>
                <span className="ml-4">:</span>

                <button
                  onClick={() => setIsLegalEntityModalOpen(true)}
                  disabled={isAccontDetaislsDisabled || isViewMode}
                  className={`border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between text-customGray ${
                    errors.legalEntityID
                      ? "border-red-500 hover:border-red-500"
                      : "border-gray-300 hover:border-blue-400"
                  }`}
                >
                  <span className="font-small text-customGray text-sm">
                    {legalEntity.find(
                      (option) => option.id.toString() == legalEntityID
                    )?.LGE_Code || "Select Legal Entity"}
                  </span>
                  <RxDropdownMenu className="ml-2" />
                </button>

                <ReusableModal
                  title="Select Legal Entity"
                  isOpen={isLegalEntityModalOpen}
                  onClose={() => {
                    setIsLegalEntityModalOpen(false);
                    setSearchQuery("");
                  }}
                  onSave={() => {
                    setIsLegalEntityModalOpen(false);
                    setSearchQuery("");
                  }}
                  searchPlaceholder="Search ZBA Type..."
                  searchQuery={searchQuery}
                  onSearchChange={handleSearchChange}
                  isViewMode={isViewMode}
                >
                  {legalEntity && legalEntity.length > 0 ? (
                    legalEntity
                      .filter((entity) =>
                        entity.LGE_Code?.toString()
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase())
                      )
                      .map((entity, index) => (
                        <div
                          key={entity.id}
                          className={`flex items-center ${
                            index % 2 === 0 ? "bg-sky-blue-200" : ""
                          }`}
                        >
                          <input
                            type="radio"
                            name="AccountType"
                            value={entity.id}
                            checked={legalEntityID == entity.id}
                            onChange={(e) => {
                              handleLegalEntityChange(e.target.value);
                              setUnsavedChanges(true);
                              if (errors.legalEntityID) {
                                setErrors((prevErrors) => ({
                                  ...prevErrors,
                                  branchID: "",
                                }));
                              }
                            }}
                            className="mr-2 accent-customBlue"
                          />
                          <label>{entity.LGE_Code || "N/A"}</label>{" "}
                        </div>
                      ))
                  ) : (
                    <p>{"No Data Found"}</p>
                  )}
                </ReusableModal>
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  LE Name{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  disabled={isAccontDetaislsDisabled}
                  value={legalEntityName}
                  readOnly={isViewMode || isEditMode}
                  type="text"
                  placeholder="B name"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Company Code
                </label>
                <span className="ml-4">:</span>
                <button
                  onClick={() => setIsCompanyModalOpen(true)}
                  disabled={isAccontDetaislsDisabled || isViewMode}
                  className={`border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between text-customGray ${
                    errors.companyId
                      ? "border-red-500 hover:border-red-500"
                      : "border-gray-300 hover:border-blue-400"
                  }`}
                >
                  <span className="font-small text-customGray text-sm">
                    {companyId || "Select Company Code"}
                  </span>
                  <RxDropdownMenu className="ml-2" />
                </button>
                <ReusableModal
                  title="Select Company Code"
                  isOpen={isCompanyModalOpen}
                  onClose={() => {
                    setIsCompanyModalOpen(false);
                    setSearchQuery("");
                  }}
                  onSave={() => {
                    setIsCompanyModalOpen(false);
                    setSearchQuery("");
                  }}
                  searchPlaceholder="Search Company..."
                  searchQuery={searchQuery}
                  onSearchChange={handleSearchChange}
                  isViewMode={isViewMode}
                >
                  {company && company.length > 0 ? (
                    company
                      .filter((company) =>
                        company.companyCode
                          ?.toString()
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase())
                      )
                      .map((company, index) => (
                        <div
                          key={company.companyCode}
                          className={`flex items-center ${
                            index % 2 === 0 ? "bg-sky-blue-200" : ""
                          }`}
                        >
                          <input
                            type="radio"
                            name="AccountType"
                            value={company.companyCode}
                            checked={companyId == company.companyCode}
                            onChange={(e) => {
                              handleCompanyChange(e);
                              setUnsavedChanges(true);
                              if (errors.companyId) {
                                setErrors((prevErrors) => ({
                                  ...prevErrors,
                                  companyId: "",
                                }));
                              }
                            }}
                            className="mr-2 accent-customBlue"
                          />
                          <label>{company.companyCode || "N/A"}</label>{" "}
                        </div>
                      ))
                  ) : (
                    <p>{"No Data Found"}</p>
                  )}
                </ReusableModal>
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Company Short Name{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={companyName}
                  disabled={isAccontDetaislsDisabled}
                  readOnly={isViewMode || isEditMode}
                  type="text"
                  placeholder="4"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  BU Group{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={buGroup}
                  disabled={isAccontDetaislsDisabled}
                  readOnly={isViewMode || isEditMode}
                  type="text"
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  BU Group Description{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={buGroupName}
                  disabled={isAccontDetaislsDisabled}
                  readOnly={isViewMode || isEditMode}
                  type="text"
                  placeholder="B name"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {/*Bank Address*/}
      {activeTab === "bankaddress" && (
        <div className="p-4 mb-60">
          <div className="mb-6 border border-gray-300 p-5 mx-10 rounded-lg">
            <h3 className="text-lg font-semibold text-gray-500 mb-4">
              Building
            </h3>

            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Door No{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  type="text"
                  value={doorNo}
                  readOnly={isViewMode || isEditMode}
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Floor No{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={floorNo}
                  readOnly={isViewMode || isEditMode}
                  type="text"
                  placeholder="4"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Building No{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={buildingNo}
                  readOnly={isViewMode || isEditMode}
                  type="text"
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Building Name{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={buildingName}
                  readOnly={isViewMode || isEditMode}
                  type="text"
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Landmark{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={landMark}
                  readOnly={isViewMode || isEditMode}
                  type="text"
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>
          </div>
          {/** */}
          <div className="mb-6 border border-gray-300 p-5 mx-10 rounded-lg">
            <h3 className="text-lg font-semibold text-gray-500 mb-4">Street</h3>

            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Street No{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={streetNo}
                  readOnly={isViewMode || isEditMode}
                  type="text"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Street Name{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={streetName}
                  readOnly={isViewMode || isEditMode}
                  type="text"
                  placeholder="4"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Road No{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={roadNo}
                  readOnly={isViewMode || isEditMode}
                  type="text"
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Road Name{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={roadName}
                  readOnly={isViewMode || isEditMode}
                  type="text"
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>
          </div>
          <div className="mb-6 border border-gray-300 p-5 mx-10 rounded-lg">
            <h3 className="text-lg font-semibold text-gray-500 mb-4">Area</h3>

            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Block{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={areaBlock}
                  readOnly={isViewMode || isEditMode}
                  type="text"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Area Name{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={areaName}
                  readOnly={isViewMode || isEditMode}
                  type="text"
                  placeholder="4"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>
          </div>
          <div className="mb-6 border border-gray-300 p-5 mx-10 rounded-lg">
            <h3 className="text-lg font-semibold text-gray-500 mb-4">
              Address
            </h3>

            <div className="grid grid-cols-1 gap-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Address 1{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={addressLine1}
                  type="text"
                  readOnly={isViewMode || isEditMode}
                  className="border border-gray-300 p-1.5 rounded 2xl:w-[72%] w-full h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Address 2{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={addressLine2}
                  readOnly={isViewMode || isEditMode}
                  type="text"
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded 2xl:w-[72%] w-full h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Address 3{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={addressLine3}
                  readOnly={isViewMode || isEditMode}
                  type="text"
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded 2xl:w-[72%] w-full h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>
          </div>

          <div className="mb-6 border border-gray-300 p-5 mx-10 rounded-lg">
            <h3 className="text-lg font-semibold text-gray-500 mb-4">
              Country
            </h3>
            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Country Code <span className="text-red-500">*</span>
                </label>
                <span className="ml-4">:</span>
                <input
                  value={country}
                  readOnly={isViewMode || isEditMode}
                  type="text"
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
                {/* <select
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                /> */}
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Country Name{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={countriesName}
                  readOnly={isViewMode || isEditMode}
                  type="text"
                  placeholder="4"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  State / Province<span className="text-red-500">*</span>{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={stateProvinces}
                  readOnly={isViewMode || isEditMode}
                  type="text"
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
                {/* <select
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                /> */}
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  State / Province Name{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={stateName}
                  readOnly={isViewMode || isEditMode}
                  type="text"
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  District{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={district}
                  readOnly={isViewMode || isEditMode}
                  type="text"
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Postal Code{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={postalCode}
                  readOnly={isViewMode || isEditMode}
                  type="text"
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Zip Code{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={zipCode}
                  readOnly={isViewMode || isEditMode}
                  type="text"
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  PO Box{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={poBox}
                  readOnly={isViewMode || isEditMode}
                  type="text"
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {/*Identifiers*/}
      {activeTab === "identifiers" && (
        <div className="p-4 mb-40">
          <div className="flex flex-col gap-y-4 mt-4 ml-10">
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Bank BIC
              </span>
              <span className="ml-3">:</span>
              <input
                value={bankBic}
                onChange={(e) => {
                  setBankBic(e.target.value);
                  setUnsavedChanges(true);
                }}
                type="text"
                maxLength={30}
                readOnly={isViewMode || isEditMode}
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
              />
            </label>
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Country Code
              </span>
              <span className="ml-3">:</span>
              <input
                value={countryCode}
                onChange={(e) => {
                  setCountryCode(e.target.value);
                  setUnsavedChanges(true);
                }}
                type="text"
                maxLength={30}
                readOnly={isViewMode || isEditMode}
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
              />
            </label>
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Country Code Type
              </span>
              <span className="ml-3">:</span>
              <input
                value={countryCodeType}
                onChange={(e) => {
                  setCountryCodeType(e.target.value);
                  setUnsavedChanges(true);
                }}
                type="text"
                maxLength={30}
                readOnly={isViewMode || isEditMode}
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
              />
            </label>
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                MICR
              </span>
              <span className="ml-3">:</span>
              <input
                value={micr}
                onChange={(e) => {
                  setMicr(e.target.value);
                  setUnsavedChanges(true);
                }}
                type="text"
                maxLength={30}
                readOnly={isViewMode || isEditMode}
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
              />
            </label>
          </div>
        </div>
      )}
      {/*Bank Statements*/}
      {activeTab === "bankstatements" && (
        <div className="p-4 mb-40">
          <div className="flex flex-col gap-y-4 mt-4 ml-10">
            {/* <label className="flex items-center"> */}
            {/* <span className="w-60 text-sm font-medium text-gray-500">
              Statement Type<span className="text-red-500">*</span>
            </span>
            <span className="ml-3">:</span>

            <div>

              <button
                onClick={() => setIsStatementTypeModalOpen(true)}
                disabled={isAccontDetaislsDisabled || isViewMode}
                className={`border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between text-customGray ${"border-gray-300 hover:border-blue-400"}`}
              >
                <span className="font-small text-customGray text-sm">
                  {statementTypeOptions.find(
                    (option) => option.value === statementId
                  )?.label || "Select Statement Type"}
                </span>
                <RxDropdownMenu className="ml-2" />
              </button> */}
            <LabelWithButton
              label="Statement Type"
              isDisabled={isViewMode}
              onClick={() => {
                setIsStatementTypeModalOpen(true);
              }}
              buttonText={
                statementTypeOptions.find(
                  (option) => option.value === statementId
                )?.label || "Select Statement Type"
              }
              error={errors.companyCodeId}
              width="w-80"
              marginLeft="ml-4"
              spanMargin="ml-16"
            />

            <ReusableModal
              title="Select Statement Type"
              isOpen={isStatementTypeModalOpen}
              onClose={() => {
                setIsStatementTypeModalOpen(false);

                setSearchQuery("");
              }}
              onSave={() => {
                setIsStatementTypeModalOpen(false);

                setSearchQuery("");
              }}
              searchPlaceholder="Search Statement Type..."
              searchQuery={searchQuery}
              onSearchChange={handleSearchChange}
              isViewMode={isViewMode}
            >
              {statementTypeOptions && statementTypeOptions.length > 0 ? (
                statementTypeOptions
                  .filter((statement) =>
                    statement.label
                      ?.toLowerCase()
                      .includes(searchQuery.toLowerCase())
                  )
                  .map((statement, index) => (
                    <div
                      key={statement.value}
                      className={`flex items-center ${
                        index % 2 === 0 ? "bg-sky-blue-200" : ""
                      }`}
                    >
                      <input
                        type="radio"
                        name="StatementType"
                        value={statement.value}
                        checked={statementId === statement.value}
                        onChange={(e) => {
                          setStatementId(e.target.value);
                          setUnsavedChanges(true);
                        }}
                        className="mr-2 accent-customBlue"
                      />
                      <label>{statement.label || "N/A"}</label>
                    </div>
                  ))
              ) : (
                <p>{"No Statement Type Found"}</p>
              )}
            </ReusableModal>

            {/* </label> */}
            <label className="flex items-center">
              <span className="w-60 text-sm font-medium text-gray-500">
                Statement identifier
              </span>
              <span className="ml-3">:</span>
              <input
                onChange={(e) => {
                  setStatementIdentifier(e.target.value);
                  setUnsavedChanges(true);
                }}
                value={statementIdentifier}
                readOnly={isViewMode || isEditMode}
                type="number"
                maxLength={30}
                className="border p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
              />
            </label>
            <label className="flex items-center">
              <span className="w-60 text-sm font-medium text-gray-500">
                Last Statement date
              </span>
              <span className="ml-3">:</span>
              <input
                onChange={(e) => {
                  setLastStatementDate(e.target.value);
                  setUnsavedChanges(true);
                }}
                value={lastStatementDate}
                readOnly={isViewMode || isEditMode}
                type="date"
                className="border p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
              />
            </label>
            <label className="flex items-center">
              <span className="w-60 text-sm font-medium text-gray-500">
                Last statement Closing balance
              </span>
              <span className="ml-3">:</span>
              <input
                onChange={(e) => {
                  setLastStateClosingBalance(e.target.value);
                  setUnsavedChanges(true);
                }}
                value={lastStateClosingbalance}
                type="number"
                maxLength={30}
                readOnly={isViewMode || isEditMode}
                className="border p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
              />
            </label>
            <label className="flex items-center">
              <span className="w-60 text-sm font-medium text-gray-500">
                Last Statement No
              </span>
              <span className="ml-3">:</span>
              <input
                onChange={(e) => {
                  setLastStatementNo(e.target.value);
                  setUnsavedChanges(true);
                }}
                value={lastStatementNo}
                type="number"
                maxLength={30}
                className="border p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
                readOnly={isViewMode || isEditMode}
              />
            </label>
          </div>
        </div>
      )}
      {/*Validity*/}
      {activeTab === "validity" && (
        <div className="mt-10">
          <ReTable
            data={validityId}
            columns={columns}
            onSelectRow={onSelectRow}
            selectedRows={selectedRows}
            // onSelectAll={onSelectAll}
            showCheckbox={false}
            onRowDoubleClick={onRowDoubleClick}
          />
        </div>
      )}
      {/*OD/CC*/}
      {activeTab === "odcc" && (
        <div className="p-4 mb-40">
          <div className="flex flex-col gap-y-4 mt-4 ml-10">
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                OD limit
              </span>
              <span className="ml-3">:</span>
              <input
                value={odLimit}
                maxLength={30}
                onChange={(e) => {
                  setOdLimit(e.target.value);
                  setUnsavedChanges(true);
                }}
                type="number"
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
                readOnly={isViewMode}
              />
            </label>
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Intrest Indicator
              </span>
              <span className="ml-3">:</span>
              <input
                value={intrestIndicator}
                onChange={(e) => {
                  setIntrestIndicator(e.target.value);
                  setUnsavedChanges(true);
                }}
                maxLength={30}
                type="number"
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
                readOnly={isViewMode}
              />
            </label>
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                OD Date
              </span>
              <span className="ml-3">:</span>
              <input
                value={odDate}
                onChange={(e) => {
                  setOdDate(e.target.value);
                  setUnsavedChanges(true);
                }}
                type="Date"
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
                readOnly={isViewMode}
              />
            </label>
          </div>
        </div>
      )}
      {/*Controls*/}
      {activeTab === "controls" && (
        <div className="p-4 mb-40">
          <div className="flex flex-col gap-y-4 mt-4 ml-10">
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Minimum Balance
              </span>
              <span className="ml-3">:</span>
              <input
                value={minimumBalance}
                maxLength={30}
                onChange={(e) => {
                  setMinimumBalance(e.target.value);
                  setUnsavedChanges(true);
                }}
                type="number"
                className="border p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
                readOnly={isViewMode}
              />
            </label>
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Dormant
              </span>
              <span className="ml-3 ">:</span>
              <input
                value={dormant}
                maxLength={30}
                onChange={(e) => {
                  setDormant(e.target.value);
                  setUnsavedChanges(true);
                }}
                type="number"
                className="border p-1.5 rounded w-14 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
                readOnly={isViewMode}
              />
              <span className="ml-6 text-sm font-medium text-gray-500">
                Months
              </span>
            </label>
            {/* <label className="flex items-center mt-8 mb-4">
              <input
                type="checkbox"
                checked={isZbaType === 1}
                onChange={(e) => setIsZbaType(e.target.checked ? 1 : 0)}
                className="form-checkbox text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
              />
              <span className="ml-4 text-sm font-semibold text-gray-600">
                Zero Balance Account
              </span>
            </label> */}
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                ZBA Type
              </span>
              <span className="ml-3">:</span>
              <input
                value={zbaid}
                maxLength={30}
                //onChange={(e) => setDormant(e.target.value)}
                type="text"
                className="border p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
                readOnly={true}
                disabled={isAccontDetaislsDisabled || isViewMode}
              />
            </label>

            <LabelWithButton
              label="Main Bank Account"
              isDisabled={isAccontDetaislsDisabled || isViewMode}
              onClick={() => {
                setIsMainBankModalOpen(true);
              }}
              buttonText={mainBankAccount || "Select Main Bank"}
              error={errors.companyCodeId}
              width="w-80"
              marginLeft="ml-4"
              spanMargin="ml-3"
            />

            {/* <span className="w-48 text-sm font-medium text-gray-500">
              Main Bank Account
            </span>
            <span className="ml-3">:</span>

            <button
              onClick={() => {
                setIsMainBankModalOpen(true);
              }}
              disabled={isAccontDetaislsDisabled || isViewMode}
              className={`border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between text-customGray ${"border-gray-300 hover:border-blue-400"}`}
            >
              <span className="font-small text-customGray text-sm">
                {mainBankAccount || "Select Main Bank"}
              </span>
              <RxDropdownMenu className="ml-2" />
            </button> */}

            <ReusableModal
              title="Select Main Bank Account"
              isOpen={isMainBankModalOpen}
              onClose={() => {
                setIsMainBankModalOpen(false);
                console.log("Modal closed", isMainBankModalOpen);
              }}
              onSave={() => {
                setIsMainBankModalOpen(false);
              }}
              searchPlaceholder="Search Main Bank Account..."
              searchQuery={searchQuery}
              onSearchChange={handleSearchChange}
              isViewMode={isViewMode}
            >
              {mainBankAccountOptions && mainBankAccountOptions.length > 0 ? (
                mainBankAccountOptions
                  .filter((option) =>
                    option.label
                      ?.toLowerCase()
                      .includes(searchQuery.toLowerCase())
                  )
                  .map((option, index) => (
                    <div
                      key={option.value}
                      className={`flex items-center ${
                        index % 2 === 0 ? "bg-sky-blue-200" : ""
                      }`}
                    >
                      <input
                        type="radio"
                        name="MainBankAccount"
                        value={option.value}
                        checked={mainBankAccount === option.value}
                        onChange={(e) => {
                          setMainBankAccount(e.target.value);
                          setUnsavedChanges(true);
                        }}
                        className="mr-2 accent-customBlue"
                      />
                      <label>{option.label || "N/A"}</label>
                    </div>
                  ))
              ) : (
                <p>{"No Main Bank Account Found"}</p>
              )}
            </ReusableModal>
          </div>
        </div>
      )}
      {/*Textfields Contents*/}
      {activeTab === "textfields" && (
        <div className="p-4 mb-40">
          <div className="flex flex-col gap-y-4 mt-4 ml-10">
            <label className="flex items-center">
              <span className="w-24 text-sm font-medium text-gray-500">
                Text 1
              </span>
              <span className="ml-3">:</span>
              <input
                value={textFields1}
                type="text"
                maxLength={30}
                onChange={(e) => {
                  setTextFields1(e.target.value);
                  setUnsavedChanges(true);
                }}
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
                readOnly={isViewMode}
              />
            </label>
            <label className="flex items-center">
              <span className="w-24 text-sm font-medium text-gray-500">
                Text 2
              </span>
              <span className="ml-3">:</span>
              <input
                value={textFields2}
                type="text"
                maxLength={30}
                onChange={(e) => {
                  setTextFields2(e.target.value);
                  setUnsavedChanges(true);
                }}
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
                readOnly={isViewMode}
              />
            </label>
            <label className="flex items-center">
              <span className="w-24 text-sm font-medium text-gray-500">
                Text 3
              </span>
              <span className="ml-3">:</span>
              <input
                value={textFields3}
                type="text"
                maxLength={30}
                onChange={(e) => {
                  setTextFields3(e.target.value);
                  setUnsavedChanges(true);
                }}
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
                readOnly={isViewMode}
              />
            </label>
            <label className="flex items-center">
              <span className="w-24 text-sm font-medium text-gray-500">
                Text 4
              </span>
              <span className="ml-3">:</span>
              <input
                value={textFields4}
                type="text"
                maxLength={30}
                onChange={(e) => {
                  setTextFields4(e.target.value);
                  setUnsavedChanges(true);
                }}
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
                readOnly={isViewMode}
              />
            </label>
            <label className="flex items-center">
              <span className="w-24 text-sm font-medium text-gray-500">
                Text 5
              </span>
              <span className="ml-3">:</span>
              <input
                value={textFields5}
                type="text"
                maxLength={30}
                onChange={(e) => {
                  setTextFields5(e.target.value);
                  setUnsavedChanges(true);
                }}
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
                readOnly={isViewMode}
              />
            </label>
          </div>
        </div>
      )}
      <div className="ml-2"></div>
      {!isViewMode && (
        <Footer>
          <button
            onClick={(e) => handleSaveClick(actionId, e)}
            className="bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ml-7"
          >
            Save
          </button>
          {!(isEditMode || isCopyMode) && (
            <button
              onClick={(e) => handleSave(e, true)}
              className="bg-white text-sm border border-customBlue text-customBlue py-1 px-2 rounded hover:bg-customBlue hover:text-white transition duration-200 ease-in-out ml-4 w-30"
            >
              Save & Continue
            </button>
          )}
        </Footer>
      )}
    </div>
  );
}
