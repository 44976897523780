import React from "react";
import uploadGif from "../assets/upload.gif";
function CustomeUpLoader() {
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
      <img src={uploadGif} alt="Loading..." className="w-24 h-auto" />
    </div>
  );
}

export default CustomeUpLoader;
