import React, { useState, useEffect } from "react";
import FilterComponent from "../../Components/FilterComponent";
import { useNavigate } from "react-router-dom";
import BamActions from "../../Components/BamActions";
import { IoAddCircleOutline } from "react-icons/io5";
import { GiSettingsKnobs } from "react-icons/gi";
import { MdOutlineFileDownload } from "react-icons/md";
import { BamButtonsConfigs } from "../../Components/BamButtonsConfig";
import ReTable from "../../../../../components/Table";
import axios from "axios";
import * as XLSX from "xlsx";
import Header from "../../../../../components/Header";
import CustomeUpLoader from "../../../../../components/CustomeLoader";

export default function ZBAtypes() {
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchZBATypes = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/get-zba-types`
        );
        const zbaData = response.data.data.map((item) => ({
          id: item.id,
          zbaId: item.ZBA_ID,
          description: item.ZBA_Description,
          createdBy: item.created_by,
          createdDate: new Date(item.creationDate).toLocaleDateString(),
          createdTime: item.creationTime,
          changedBy: item.changed_by,
          changedDate: new Date(item.changed_date).toLocaleDateString(),
          changedTime: item.changedTime,
        }));
        setData(zbaData);
        setOriginalData(zbaData);
      } catch (error) {
        console.error("Error fetching ZBA types:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchZBATypes();
  }, []);

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleDownload = () => {
    const dataToExport = data.map((item) => ({
      "ZBA ID": item.zbaId,
      Description: item.description,
      "Created By": item.createdBy,
      "Created Date": item.createdDate || "",
      "Created Time": item.createdTime || "",
      "Updated By": item.changedBy,
      "Updated Date": item.changedDate || "",
      "Updated Time": item.changedTime || "",
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "ZBA Types");

    XLSX.writeFile(workbook, "ZBAtypes_Data.xlsx");
  };

  const buttons = BamButtonsConfigs.screen2.map((button) => ({
    ...button,
    onClick:
      button.icon === IoAddCircleOutline
        ? () => handleNavigate("/ZBA-types-add")
        : button.icon === GiSettingsKnobs
        ? () => setShowFilter((prev) => !prev)
        : button.icon === MdOutlineFileDownload
        ? handleDownload
        : button.onClick,
  }));

  const handleFilter = (filters) => {
    const filteredData = originalData.filter((item) => {
      const createdDate = item.creationDate
        ? new Date(item.creationDate).toISOString().slice(0, 10)
        : null;
      const changedDate = item.changed_date
        ? new Date(item.changed_date).toISOString().slice(0, 10)
        : null;
      const createdDateMatch = filters.createdTime
        ? createdDate === filters.createdTime
        : true;
      const changedByMatch = filters.changedBy
        ? item.changedBy.toLowerCase().includes(filters.changedBy.toLowerCase())
        : true;
      const changedDateMatch = filters.changeDate
        ? changedDate === filters.changeDate
        : true;
      const changedTimeMatch = filters.changeTime
        ? item.changedTime === filters.changeTime
        : true;

      return (
        createdDateMatch &&
        changedByMatch &&
        changedDateMatch &&
        changedTimeMatch
      );
    });

    setData(filteredData);
  };

  const handleReset = () => {
    setData(originalData);
  };

  const columns = React.useMemo(
    () => [
      { Header: "ZBA ID", accessor: "zbaId", width: 100 },
      { Header: "ZBA Description", accessor: "description", width: 200 },
      { Header: "Created By", accessor: "createdBy", width: 100 },
      { Header: "Created Date", accessor: "createdDate", width: 120 },
      { Header: "Created Time", accessor: "createdTime", width: 120 },
      { Header: "Updated By", accessor: "changedBy", width: 100 },
      { Header: "Updated Date", accessor: "changedDate", width: 120 },
      { Header: "Updated Time", accessor: "changedTime", width: 120 },
    ],
    []
  );

  const handleSelectRow = (id) => {
    setSelectedRows((prev) =>
      prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
    );
  };

  const handleSelectAll = () => {
    if (selectedRows.length === data.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(data.map((row) => row.id));
    }
  };

  const handleRowDoubleClick = (id) => {
    console.log(`Row ${id} double clicked`);
  };

  if (loading)
    return (
      <div>
        <CustomeUpLoader />
      </div>
    );

  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "BAM") {
      console.log("Navigating to /bam");
      navigate("/bam");
    } else if (crumb.title === "Set Up") {
      console.log("Navigating to /bam");
      navigate("/bam");
    } else if (crumb.title === "ZBA Types") {
      console.log("Navigating to /ZBA-types");
      navigate("/ZBA-types");
    } else if (crumb.path) {
      navigate(crumb.path);
    }
  };

  return (
    <div>
      <div>
        <Header
          title={"BAM > Set Up > ZBA Types"}
          onBreadcrumbClick={handleBreadcrumbClick}
        />
      </div>
      <div>
        <BamActions buttons={buttons} />
      </div>

      {showFilter && (
        <FilterComponent onFilter={handleFilter} onReset={handleReset} />
      )}

      <ReTable
        data={data}
        columns={columns}
        selectedRows={selectedRows}
        onSelectRow={handleSelectRow}
        onSelectAll={handleSelectAll}
        onRowDoubleClick={handleRowDoubleClick}
      />
    </div>
  );
}
