import React, { useState } from 'react';
import { CashFlowbuttonsConfigs } from '../../../Components/CashFlowbuttonsConfig';
import NoActionLayout from '../../../../../../components/NoActionLayout';
import CashFlowActions from '../../../Components/CashFlowActions';
import { useNavigate } from 'react-router-dom';
import { IoAddCircleOutline } from 'react-icons/io5'; // Import the new icon
import MappingRankIDTable from '../../../Tables/SetUp/MappingRankIDTable';
import { GiTwirlyFlower } from 'react-icons/gi';

export default function MappingRuleID() {
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState([]);

  const handleNavigate = (path) => {
    navigate(path);
  };

  const buttons = CashFlowbuttonsConfigs.screen1.map(button => ({
    ...button,
    onClick: button.icon === IoAddCircleOutline 
      ? () => handleNavigate("/mapping-rule-add")
      : button.onClick
  }));

  if (selectedRows.length > 0) {
    buttons.push({
      icon: GiTwirlyFlower, // New icon
      label: 'New Action',
      onClick: () => handleNavigate("/ntcodeautomation") // Your new action
    });
  }

  return (
    <div>
      <div>
        <NoActionLayout title="Cash Flow > Set Up > Mapping Rule ID " />
      </div>
      <div>
        <CashFlowActions buttons={buttons} />
      </div>
      <MappingRankIDTable selectedRows={selectedRows} setSelectedRows={setSelectedRows} />
    </div>
  );
}
