import React, { useEffect, useState } from "react";
import {
  IoMdCheckmark,
  IoMdClose,
  IoMdCopy,
  IoMdCreate,
  IoMdTime,
  IoMdTrash,
} from "react-icons/io";
import { LuKeyRound } from "react-icons/lu";
import {
  MdOutlineFileDownload,
  MdOutlineFileUpload,
  MdOutlineLock,
} from "react-icons/md";
import UserService from "../../../../rest-services/UserServices";
import { IoAddCircleOutline } from "react-icons/io5";
import { useParams } from "react-router-dom";
import AdminService from "../../../../rest-services/AdminServices";
import { VscVmActive } from "react-icons/vsc";

const AdminActions = ({
  buttons = [],
  extraButtons = [],
  selectedCount,
  selectUserIds,
  icons,
}) => {
  const iconId = parseInt(icons, 10);

  const [alert, setAlert] = useState(null);
  const [approved, setApproved] = useState("");
  const [userStatus, setUserStatus] = useState("");
  const [users, setUsers] = useState([]);
  const [rolesAndPermission, setRolesAndPermission] = useState([]);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userPreference, setUserPreference] = useState("");
  const [MakerCheker, setMakerChecker] = useState("");
  const [error, setError] = useState(null);
  const [assignee, setAssignee] = useState({ id: "", username: "" });
  const [rolesPermissions, setRolesPermissions] = useState([]);
  const [adminstatus, setAdminStatus] = useState("");
  const [ConsultantStatus, setConsultantStatus] = useState("");

  useEffect(() => {
    if (selectUserIds && selectUserIds.length > 0) {
      console.log("fetched aray users", selectUserIds);
      fetchArrayUsers(selectUserIds);
      fetchArrayRoles(selectUserIds);
      fetchUserPreferenceDetails(icons);
      fetchRolesAndPermissionDetails(selectUserIds);
    }
  }, [selectUserIds, icons]);

  useEffect(() => {
    fetchAssignee();
  }, []);

  const fetchAssignee = async () => {
    try {
      const userDetailString = sessionStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.username || "",
          assignerId: userDetail.userId || "",
          clientId: userDetail.clientId || "",
        };

        setAssignee(assigneeData);
        // Log the id and assigner values to the console
        console.log("Assignee ID:", assigneeData.id);
        console.log("Assigner:", assigneeData.assigner);
        console.log("Assigner userId:", assigneeData.assignerId);
        console.log("Assigner clientId:", assigneeData.clientId);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  useEffect(() => {
    if (assignee.assignerId) {
      fetchUserPermissionDetails();
      fetchAdminUserStatus();
    }
  }, [assignee.assignerId]);

  const fetchAdminUserStatus = async () => {
    try {
      const reponse = await AdminService.getAdminStatus(assignee.assignerId);
      console.log("assignee response data", reponse.data);
      setAdminStatus(reponse.data.modified);
      setConsultantStatus(reponse.data.consultantStatus);
    } catch (error) {}
  };

  const fetchUserPermissionDetails = async () => {
    try {
      const data = { userId: assignee.assignerId };
      console.log("Request Data:", data);

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-approved-users-permissions-by-userId`,
        data
      );

      const responseData = response.data;
      setRolesPermissions(responseData.usersPermissionsTables || []); // Save permission details
      console.log("Set roles & permission details", responseData);
    } catch (error) {
      setError(error.message); // Set error message
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  // const fetchConsultantPermissionDetails = async () => {
  //   try {
  //     const data = { userId: assignee.assignerId };
  //     console.log("Request Data:", data);

  //     const response = await UserService.saveContactDetails(
  //       `${process.env.REACT_APP_BASE_URL}/get-users-permissions-by-userId`,
  //       data
  //     );

  //     const responseData = response.data;
  //     setRolesPermissions(responseData.usersPermissionsTables || []); // Save permission details
  //     console.log("Set roles & permission details", responseData);
  //   } catch (error) {
  //     setError(error.message); // Set error message
  //   } finally {
  //     setLoading(false); // Reset loading state
  //   }
  // };

  // Function to check if permissions array is empty or not
  const areAllButtonsDisabled = rolesPermissions.length >= 0;
  // const isConsultant = '';
  console.log("All Buttons Disabled", areAllButtonsDisabled);

  const fetchUserPreferenceDetails = async (icons) => {
    try {
      const data = { icons }; // Assumes 'icons' is the correct field for the request
      console.log("Request Data:", data);

      setLoading(true); // Start loading indicator

      // Call UserService with the correct endpoint and data
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-Icons-data-by-IconId`,
        data
      );

      if (response.data) {
        console.log("Fetched user icons preference details:", response.data);
        setUserPreference(response.data); // Update state with the user preference details
        setMakerChecker(response.data.makeAndChecker);
        console.log(
          "Fetched user icons MakerCheker details:",
          response.data.makeAndChecker
        );
      } else {
        setError("User preference details not found.");
      }
    } catch (error) {
      console.error("Error fetching user preference details:", error);
      setError(error.message); // Handle error
    } finally {
      setLoading(false); // Stop loading indicator
    }
  };

  const fetchRolesAndPermissionDetails = async (userIds) => {
    try {
      const userId = userIds.map((id) => ({ id }));
      console.log("assignees data", assignee);
      const data = { clientId: assignee.clientId, userId };
      console.log("Request Data for users:", data);

      // const response = await UserService.getUsers(`${process.env.REACT_APP_BASE_URL}/get-all-users-permissions`);
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-all-users-permissions`,
        data
      );
      console.log("Response from server fetching roles:", response);
      const responseData = response.data;
      console.log("set only roles details", responseData);
      setRolesAndPermission(responseData);
      if (responseData.error) {
        console.error("Error fetching roles data:", responseData.message);
        setAlert({ severity: "error", message: responseData.message });
        return;
      }
    } catch (error) {
      console.error("Error fetching roles data", error);
      setAlert({
        severity: "error",
        message: `Error fetching roles data, kindly check the connection ${error.message}`,
      });
    }
  };

  const fetchArrayUsers = async (userIds) => {
    try {
      // Prepare the request data
      const requestData = userIds.map((id) => ({ id })); // Transforming to the desired format
      console.log("Request Data from admin actions:", requestData); // Debugging the request data

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-array-users`,
        requestData // Send the correctly formatted request data
      );

      console.log("API Response from admin actions data:", response.data);
      setUsers(response.data);
      // Handle the API response as needed, e.g., update state or notify user
    } catch (error) {
      console.error("Error fetching user details:", error);
      // Optionally handle the error state
    }
  };

  const fetchArrayRoles = async (roleIds) => {
    try {
      // Prepare the request data with single UserId
      const requestData = { UserId: roleIds };
      console.log("Request Data from admin actions:", requestData); // Debugging the request data

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-roles-permission-by-userId`,
        requestData // Send the correctly formatted request data
      );

      console.log("API Response from admin actions 11:", response.data);
      setRoles(response.data); // Assuming response data structure has `data` property for roles
      // Handle the API response as needed, e.g., update state or notify user
    } catch (error) {
      console.error("Error fetching user permissions:", error);
      // Optionally handle the error state
    }
  };

  const getIconColor = (icon, disabled) => {
    if (disabled) return "text-gray-300"; // Gray color for disabled state

    switch (icon) {
      case IoMdCheckmark:
        return "text-green-500";
      case IoMdClose:
        return "text-red-500";
      case IoMdTrash:
        return "text-red-500";
      case VscVmActive:
        return "text-green-500";
      default:
        return "text-sky-900";
    }
  };

  const isApproveButtonDisabled = (button) => {
    if (button.icon === IoMdCheckmark || button.icon === IoMdClose) {
      console.log("Is maker and checker are", MakerCheker);
      if (MakerCheker == 1) {
        // When MakerCheker is 1, check both conditions
        if (iconId == 108) {
          // return users.some(
          return (users || []).some(
            (user) =>
              user.approved === "3" ||
              (user.changedBy !== null &&
                assignee.assigner === user.changedBy) ||
              assignee.assigner === user.createdBy
          );
        }
        // Icon id 112 is permissions
        else if (iconId == 112) {
          return (rolesAndPermission || []).some(
            (permissions) =>
              String(permissions.roleUserType) === permissions.userType
          );
        } else {
          console.log("Is that maker and checker is 1");

          // return roles.some(role => role.status === "3");
          // return roles.some(
          return (roles || []).some(
            (role) =>
              role.status === 3 ||
              (role.updated_by !== null &&
                assignee.assigner === role.updated_by) ||
              assignee.assigner === role.created_by
          );
        }
      } else if (MakerCheker == 0) {
        console.log("Is that maker and checker is 0");
        // When MakerCheker is 0, check only the approved status
        if (iconId == 108) {
          // return users.some((user) => user.approved === "3");
          return (users || []).some((user) => user.approved === "3");
        }
        // Icon id 112 is permissions module
        else if (iconId == 112) {
          console.log("checking user type and role user type");
          // return (rolesAndPermission || []).some((permissions) => String(permissions.roleUserType) == "2");

          return (rolesAndPermission || []).some(
            (permissions) =>
              String(permissions.roleUserType) !== permissions.userType
          );
        } else {
          // return roles.some((role) => role.status === 3);
          return (roles || []).some((role) => role.status === 3);
        }
      }
    }
    return false;
  };

  const isCloseButtonDisabled = (button) => {
    if (button.icon === IoMdClose) {
      if (iconId == 108) {
        // return users.some((user) => user.approved === "4");
        return (users || []).some((user) => user.approved === "4");
      } else {
        return (roles || []).some((role) => role.status === 3);
        // return roles.some((role) => role.status === 3);
      }
    }
    return false;
  };

  const isTriggerOTPButtonDisabled = (button) => {
    if (button.icon === LuKeyRound) {
      if (iconId == 108) {
        // return users.some((user) => user.approved === "4");
        // return (users || []).some((user) => user.approved !== "3" );
        return (users || []).some(
          (user) =>
            user.approved !== "3" ||
            (user.approved === "3" && user.userStatus == 1)
        );
      }
    }
    return false;
  };

  console.log("Icons value:", icons);

  const shouldDisableDueToPermission = (button) => {
    const permissionCheck = (permissionsId) =>
      rolesPermissions.some(
        (permission) =>
          permission.permissionsId === permissionsId &&
          permission.iconId === iconId
      );

    console.log("checking roles and ", rolesPermissions);

    if (adminstatus === "5") {
      return (
        button.icon?.name === "IoMdCheckmark"
        // ||
        // button.icon?.name === "IoMdClose" ||
        // button.icon?.name === "IoMdTime" ||
        // button.icon?.name === "IoMdCreate" ||
        // button.icon?.name === "IoMdTrash" ||
        // button.icon?.name === "IoMdCopy" ||
        // button.icon?.name === "MdOutlineFileDownload" ||
        // button.icon?.name === "IoAddCircleOutline" ||
        // button.icon?.name === "MdOutlineFileUpload"
      );
    }
    // User type is admin if user status is orange or 2
    else if (adminstatus === "2") {
      return (
        button.icon?.name === "IoMdCheckmark"
        // ||
        // button.icon?.name === "IoMdClose" ||
        // button.icon?.name === "IoMdTime" ||
        // button.icon?.name === "IoMdCreate" ||
        // button.icon?.name === "IoMdTrash" ||
        // button.icon?.name === "IoMdCopy" ||
        // button.icon?.name === "MdOutlineFileDownload" ||
        // button.icon?.name === "IoAddCircleOutline" ||
        // button.icon?.name === "MdOutlineFileUpload"
      );
    } else if (ConsultantStatus === "3") {
      return (
        // button.icon?.name === "IoMdCheckmark" ||
        button.icon?.name === "IoMdClose" ||
        button.icon?.name === "IoMdTime" ||
        // button.icon?.name === "IoMdCreate" ||
        button.icon?.name === "IoMdTrash" ||
        button.icon?.name === "IoMdCopy" ||
        button.icon?.name === "VscVmActive" ||
        button.icon?.name === "LuKeyRound" ||
        button.icon?.name === "MdOutlineLock" ||
        button.icon?.name === "MdOutlineFileDownload" ||
        // button.icon?.name === "IoAddCircleOutline" ||
        button.icon?.name === "MdOutlineFileUpload"
      );
    }
    return (
      (areAllButtonsDisabled &&
        // Add Button
        button.icon?.name === "IoAddCircleOutline" &&
        !permissionCheck(1)) ||
      // Edit Button
      (button.icon?.name === "IoMdCreate" && !permissionCheck(2)) ||
      // Copy
      (button.icon?.name === "IoMdCopy" && !permissionCheck(4)) ||
      // Audit Trail
      (button.icon?.name === "IoMdTime" && !permissionCheck(5)) ||
      // Approve
      (button.icon?.name === "IoMdCheckmark" && !permissionCheck(6)) ||
      // Reject
      (button.icon?.name === "IoMdClose" && !permissionCheck(7)) ||
      // Trigger OTP
      (button.icon?.name === "LuKeyRound" && !permissionCheck(8)) ||
      // Upload
      (button.icon?.name === "MdOutlineFileUpload" && !permissionCheck(9)) ||
      // Download
      (button.icon?.name === "MdOutlineFileDownload" && !permissionCheck(10)) ||
      // Inactive
      (button.icon?.name === "VscVmActive" && !permissionCheck(11)) ||
      // Lock
      (button.icon?.name === "MdOutlineLock" && !permissionCheck(12)) ||
      // Delete
      (button.icon?.name === "IoMdTrash" && !permissionCheck(13)) ||
      // Settings
      (button.icon?.name === "IoSettingsOutline" && !permissionCheck(14)) ||
      //   // Filter
      (button.icon?.name === "GiSettingsKnobs" && !permissionCheck(15)) ||
      // Refresh
      (button.icon?.name === "IoMdRefresh" && !permissionCheck(16))
    );
  };

  // const shouldDisableDueToPermission = (button) => {
  //   const permissionCheck = (permissionsId) =>
  //     rolesPermissions.some(
  //       (permission) =>
  //         permission.permissionsId === permissionsId &&
  //         permission.iconId === iconId
  //     );
  //   console.log("checking roles and ", rolesPermissions);
  //   // Check each permission ID with the iconId as passed in
  //   const hasPermissionId1 = permissionCheck(1);
  //   const hasPermissionId2 = permissionCheck(2);
  //   const hasPermissionId3 = permissionCheck(3);
  //   const hasPermissionId4 = permissionCheck(4);
  //   const hasPermissionId5 = permissionCheck(5);
  //   const hasPermissionId6 = permissionCheck(6);
  //   const hasPermissionId7 = permissionCheck(7);
  //   const hasPermissionId8 = permissionCheck(8);
  //   const hasPermissionId9 = permissionCheck(9);
  //   const hasPermissionId10 = permissionCheck(10);
  //   const hasPermissionId11 = permissionCheck(11);
  //   const hasPermissionId12 = permissionCheck(12);
  //   const hasPermissionId13 = permissionCheck(13);

  //   console.log("Checking button:", button.icon?.name, "with icons:", icons);

  //   return (
  //    if( adminstatus === "5"){
  //     (button.icon?.name === "IoMdCheckmark" ) ||
  //     (button.icon?.name === "IoMdClose" ) ||
  //     (button.icon?.name === "IoMdTime" ) ||
  //     (button.icon?.name === "IoMdCreate"  ) ||
  //     (button.icon?.name === "IoMdTrash" ) ||
  //     (button.icon?.name === "IoMdCopy" ) ||
  //     (button.icon?.name === "MdOutlineFileDownload" ) ||
  //     (button.icon?.name === "IoAddCircleOutline" ) ||
  //     (button.icon?.name === "MdOutlineFileUpload" )
  //    }else{
  //     areAllButtonsDisabled &&
  //     (
  //       (button.icon?.name === "IoMdCheckmark" && !hasPermissionId6) ||
  //       (button.icon?.name === "IoMdClose" && !hasPermissionId7) ||
  //       (button.icon?.name === "IoMdTime" && !hasPermissionId5) ||
  //       (button.icon?.name === "IoMdCreate" && !hasPermissionId2) ||
  //       (button.icon?.name === "IoMdTrash" && !hasPermissionId13) ||
  //       (button.icon?.name === "IoMdCopy" && !hasPermissionId4) ||
  //       (button.icon?.name === "VscVmActive" && !hasPermissionId11) ||
  //       (button.icon?.name === "LuKeyRound" && !hasPermissionId8) ||
  //       (button.icon?.name === "MdOutlineLock" && !hasPermissionId12) ||
  //       (button.icon?.name === "MdOutlineFileDownload" && !hasPermissionId10) ||
  //       (button.icon?.name === "IoAddCircleOutline" && !hasPermissionId1) ||
  //       (button.icon?.name === "MdOutlineFileUpload" && !hasPermissionId9))
  //   );
  //    }

  // };

  const getButtonTitle = (button) => {
    if (
      shouldDisableDueToPermission(button) ||
      isApproveButtonDisabled(button) ||
      isCloseButtonDisabled(button) ||
      isTriggerOTPButtonDisabled(button)
    ) {
      return "You Don't have Permission";
    }
    return button.title;
  };

  return (
    <div className="px-4">
      {/* <div>
        {iconId}
      </div> */}
      <div className="flex justify-between items-center">
        {/* Existing buttons aligned to the start */}
        <div className="flex items-center space-x-2">
          {buttons.map((button, index) => {
            const shouldDisableDueToSelectedCount =
              selectedCount > 1 &&
              (button.icon === IoMdTime ||
                button.icon === IoMdCreate ||
                button.icon === IoMdTrash ||
                // button.icon === IoMdCheckmark ||
                // button.icon === IoMdClose ||
                button.icon === IoMdCopy ||
                button.icon === VscVmActive ||
                button.icon === LuKeyRound ||
                button.icon === MdOutlineLock);

            const shouldDisableDueToApproval =
              isApproveButtonDisabled(button) ||
              isCloseButtonDisabled(button) ||
              isTriggerOTPButtonDisabled(button);

            return (
              <button
                key={index}
                className={`p-1 rounded-lg ${
                  button.disabled ||
                  shouldDisableDueToSelectedCount ||
                  shouldDisableDueToApproval ||
                  shouldDisableDueToPermission(button)
                    ? ""
                    : "bg-secondary text-secondary-foreground"
                }`}
                onClick={
                  !shouldDisableDueToSelectedCount &&
                  !shouldDisableDueToApproval &&
                  !shouldDisableDueToPermission(button)
                    ? button.onClick
                    : null
                }
                disabled={
                  shouldDisableDueToSelectedCount ||
                  shouldDisableDueToApproval ||
                  shouldDisableDueToPermission(button)
                }
                title={getButtonTitle(button)}
              >
                <button.icon
                  className={`text-3xl border border-blue-100 rounded-lg cursor-pointer hover:border-customBlue hover:shadow-md p-1 ${getIconColor(
                    button.icon,
                    button.disabled ||
                      shouldDisableDueToSelectedCount ||
                      shouldDisableDueToApproval ||
                      shouldDisableDueToPermission(button)
                  )} ${button.rotate ? "rotate-90" : ""}`}
                />
              </button>
            );
          })}
        </div>

        {/* Extra buttons aligned to the end */}
        <div className="flex items-center space-x-2">
          {extraButtons.map((button, index) => (
            <button
              key={index}
              className={`p-1 rounded-lg ${
                button.disabled
                  ? "bg-gray-200 cursor-not-allowed"
                  : "bg-secondary text-secondary-foreground"
              }`}
              onClick={!button.disabled ? button.onClick : null}
              disabled={button.disabled}
              title={button.title}
            >
              <button.icon
                className={`text-3xl border border-dark-gray shadow-md cursor-pointer p-1 hover:border-customBlue ${getIconColor(
                  button.icon,
                  button.disabled
                )}`}
              />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdminActions;
