import RestService from "./RestServices";
const basePath = "/bam";

const BamServices = {
  getAccountType: () => {
    return RestService.GetAllData("/get-account-types");
  },
  getAllZBATypes: () => {
    return RestService.GetAllData("/get-zba-types");
  },
  getAllCurrency: () => {
    return RestService.GetAllData("/get-currency");
  },
  getAllLegalEntity: () => {
    return RestService.GetAllData("/get-all-legal-entity");
  },
  getAssociatedCompany: (entitCode) => {
    return RestService.GetAllData(
      `${basePath}/get-associate-company?id=${entitCode}`
    );
  },

  addBankAccountMaster: (data) => {
    return RestService.CreateData(`${basePath}/create-bank-account`, data);
  },

  getDataForTable: () => {
    return RestService.GetAllData(`${basePath}/get-bank-account`);
  },
  approveBankAccount: (id, changedBy, username) => {
    const data = { id: id, changedBy, username };
    return RestService.CreateData(`${basePath}/approve-bank-account`, data);
  },
  rejectBankAccount: (id, changedBy, username) => {
    const data = { id: id, changedBy, username };
    return RestService.UpdateData(`${basePath}/reject-bank-account?id=${id}`);
  },
  updateBankId: (data) => {
    return RestService.UpdateData(`${basePath}/update-bank-account-id`, data);
  },
  getBankAccountById: (id) => {
    return RestService.GetAllData(
      `${basePath}/get-bank-account-by-id?accountId=${id}`
    );
  },
  searchBankAccount: (data) => {
    return RestService.GetAllData(
      `${basePath}/search-by-account?accountId=${data}`
    );
  },
  editBankAccount: (data) => {
    return RestService.UpdateData(`${basePath}/edit-bank-account-id`, data);
  },
  getAuditTrail: (accountId, iconId) => {
    return RestService.GetAllData(
      `${basePath}/get-bank-audit-trail?accountId=${accountId}&iconId=${iconId}`
    );
  },
  getValidity: (accountId) => {
    return RestService.GetAllData(
      `${basePath}/get-validity?accountId=${accountId}`
    );
  },
  getCompnayCode: (accountId) => {
    return RestService.GetAllData(
      `${basePath}/get-company-code?id=${accountId}`
    );
  },
  assignNewCompanyCode: (data) => {
    return RestService.UpdateData(`${basePath}/assign-new-company-code`, data);
  },

  assignNewStatus: (data) => {
    return RestService.UpdateData(`${basePath}/assign-new-status`, data);
  },

  deleBankAccount: (id) => {
    return RestService.DeleteData(`${basePath}/delete-bank-account`, id);
  },
  mainBankAccount: (id) => {
    return RestService.GetAllData(
      `${basePath}/get-main-account?clientId=${id}`
    );
  },
  getFilterBankAccount: (filters) => {
    return RestService.CreateData(`${basePath}/get-filtered-account`, filters);
  },
  getExportData: (clientId, filters) => {
    return RestService.ExportData(
      `${basePath}/get-export-account?clientId=${clientId}`,
      filters
    );
  },
};
export default BamServices;
