import React from 'react'
import MinimumTable from '../Tables/ExceptionalTables/MinimumTable'

import NoActionLayout from '../../../../components/NoActionLayout'
import CashBalanceActions from '../Props/CashBalanceActions'
import { buttonConfigs } from '../Props/buttonConfig';
export default function Minimum() {
  const buttons=buttonConfigs.screen1;
  return (
    <div>
       <div>
      <NoActionLayout title="Cash Balance > Exceptions > Minimum" />
</div>
<div>
<CashBalanceActions buttons={buttons}/>
</div>
<MinimumTable/>
    </div>
  )
}
