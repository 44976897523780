import React from 'react'
import { IoIosFlag } from 'react-icons/io'

export default function NTCodeAutomationTable() {
  return (
    <div>
      <div className='p-4'>
      <div className="overflow-x-auto max-w-full">
        <table className="table-fixed min-w-[800px] bg-white">
          <thead className="bg-sky-500 text-primary-foreground text-sm">
            <tr>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium"></th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">MP ID</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Rule Sequence</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Map Type</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">INTLNUM</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">BTC Code</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Bank Account currency</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Bank Account</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Bank Reference</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Description</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Customer reference</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Complimentry Info</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">D/C Indicator</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Foreign currency Indicator</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Active</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Credit NT Code</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Threshold Limit</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Project Code</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Operator for threshold</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Debit NT Code</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Credit NT Code</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Locked</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Assigned</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Created BY</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Created On</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Updated by</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Updated On</th>

            </tr>
          </thead>
          <tbody className='text-sm'>
            {Array(50).fill().map((_, index) => (
              <tr key={index} className="border-b" style={{ backgroundColor: index % 2 === 0 ? 'white' : '#f3f4f6' }}>
                <td className="py-2 px-4">
                  <input
                    type="checkbox"
                    className="form-checkbox"
                    // checked={selectedRows.includes(index)}
                    // onChange={() => handleCheckboxChange(index)}
                  />
                </td>
                <td className="py-2 px-4">MP ID</td>
                <td className="py-2 px-4">Rule Sequence</td>
                <td className="py-2 px-4">Map Type</td>
                <td className="py-2 px-4">INTLNUM</td>
                <td className="py-2 px-4">BTC Code</td>
                <td className="py-2 px-4">Bank Account currency</td>
                <td className="py-2 px-4">Bank Account</td>
                <td className="py-2 px-4">Bank Reference</td>
                <td className="py-2 px-4">Description</td>
                <td className="py-2 px-4">Customer reference</td>
                <td className="py-2 px-4">Complimentry Info</td>
                <td className="py-2 px-4">D/C Indicator</td>
                <td className="py-2 px-4">Foreign currency Indicator</td>
                <td className="py-2 px-4">
                  <IoIosFlag color='green' />
                </td>
                <td className="py-2 px-4">Credit NT Code</td>
                <td className="py-2 px-4">Threshold Limit</td>
                <td className="py-2 px-4">Project Code</td>
                <td className="py-2 px-4">Operator for threshold</td>
                <td className="py-2 px-4">Debit NT Code</td>
                <td className="py-2 px-4">Credit NT Code</td>
                <td className="py-2 px-4">
                  <input type="checkbox" className="form-checkbox" />
                </td>
                <td className="py-2 px-4">
                  <input type="checkbox" className="form-checkbox" />
                </td>
                <td className="py-2 px-4">Created BY</td>
                <td className="py-2 px-4">Created On</td>
                <td className="py-2 px-4">Updated by</td>
                <td className="py-2 px-4">Updated On</td>

               
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </div>
  )
}
