import React from 'react'
import CurrencyWiseTable from '../Tables/CashInBank/CurrencyWiseTable'
import NoActionLayout from '../../../../components/NoActionLayout'

import CashBalanceActions from '../Props/CashBalanceActions'
import { buttonConfigs } from '../Props/buttonConfig';

export default function CurrencyWise() {
  const buttons=buttonConfigs.screen1;

  <CashBalanceActions buttons={buttons}/>

  return (
    <div>
       <div>
      <NoActionLayout title="Cash Balance > Cash in Bank > Currency Wise" />
</div>
<div>
<CashBalanceActions buttons={buttons}/>
</div>
<CurrencyWiseTable/>
    </div>
  )
}
