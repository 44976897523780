import RestService from "./RestServices";

const CountryServices = {
  getCountries: (id) => {
    return RestService.GetByIdData(`/get-country-id`, id);
  },
  deleteCountry:(id)=>{
    return RestService.DeleteData('/delele-country',id);
  },
  checkDuplicateCountrycode: async (path, roleData) => {
    try {
      const response = await RestService.CreateData(path, roleData);
      console.log("Response from server:", response);

      return response;
    } catch (error) {
      console.error("Error fetching Country code:", error);
      return { error: true, message: "Error fetching Country code" };
    }
  },
  getAllCountrywithPage: (pageSize, page, searchQuery = "") => {
    const searchParam = searchQuery ? `&searchQuery=${encodeURIComponent(searchQuery)}` : "";
    return RestService.GetAllData(
      `/get-country-page?limit=${pageSize}&offset=${(page - 1) * pageSize}${searchParam}`
    );
  },
};

export default CountryServices;
