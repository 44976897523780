import React, { useEffect, useState } from "react";
import Layout from "../../../../../components/layout";
import StatementServices from "../../../../../rest-services/StatementIdentiServices";
import BankStatementActions from "../../props/ActionButtons";
import { bankStatementActionsConfig } from "../../props/ActionButtonConfig";
import { IoMdRefresh } from "react-icons/io";
import Pagination from "../../../../../components/PageNation";
import { MdOutlineFileDownload } from "react-icons/md";
import ReTable from "../../../../../components/Table";

function StatementIdentifier() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const pageSize = 20;

  const fetchData = async (page) => {
    try {
      const response = await StatementServices.getAllStatement(pageSize, page);

      let { identifier, totalCount } = response.data.data;

      if (!identifier) {
        identifier = [];
      } else if (!Array.isArray(identifier)) {
        identifier = [identifier];
      }

      setData(identifier);
      setTotalCount(totalCount);
      setTotalPages(Math.ceil(totalCount / 20));
    } catch (error) {
      console.error("Error fetching statement identifier data:", error);
    }
  };

  const downloadExcel = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/identifier/export-data`,
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        throw new Error("Error downloading file");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "statement_identifier.xlsx");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };
  const buttons = bankStatementActionsConfig.screen1.map((button) => {
    let onClick;
    let title;
    if (button.icon === IoMdRefresh) {
      onClick = () => fetchData(currentPage);
      title = "fetch";
    } else if (button.icon === MdOutlineFileDownload) {
      onClick = () => downloadExcel();
      title = "download";
    }
    return {
      ...button,
      onClick,
      title,
    };
  });

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const columns = [
    {
      Header: "Statement Type",
      accessor: "statement_type",
      visible: true,
      width: 200,
    },
    {
      Header: "Statement Identifier",
      accessor: "statement_identifier",
      visible: true,
      width: 200,
    },
    {
      Header: "Corporate BIC",
      accessor: "corporate_bic",
      visible: true,
      width: 200,
    },
    { Header: "Bank BIC", accessor: "bank_bic", visible: true, width: 200 },
    {
      Header: "Active",
      accessor: "status",
      visible: true,
      width: 200,
      Cell: ({ value }) => (
        <input type="checkbox" checked={value === 1} readOnly />
      ),
    },

    {
      Header: "Bank account Id",
      accessor: "bank_account_id",
      visible: true,
      width: 200,
    },
    {
      Header: " Company Code",
      accessor: "statement_no1",
      visible: true,
      width: 200,
    },
    {
      Header: "Bank",
      accessor: "statement_no2",
      visible: true,
      width: 200,
    },
    {
      Header: "Bank Branch",
      accessor: "statement_o3",
      visible: true,
      width: 200,
    },
    {
      Header: "Bank Account No",
      accessor: "statement_no4",
      visible: true,
      width: 200,
    },
    {
      Header: "IBAN",
      accessor: "statement_no5",
      visible: true,
      width: 200,
    },
    {
      Header: "Bank Statement Updated",
      accessor: "statement_no6",
      visible: true,
      width: 200,
    },
    {
      Header: "Bank Branch",
      accessor: "statement_no7",
      visible: true,
      width: 200,
    },
    { Header: "Created By", accessor: "created_by", visible: true, width: 200 },
    {
      Header: "Created Date",
      accessor: "creationDate",
      visible: true,
      width: 15,
      canResize: true,
    },
    {
      Header: "Created Time",
      accessor: "creationTime",
      visible: true,
      width: 15,
      canResize: true,
    },
    {
      Header: "Updated By",
      accessor: "changed_by",
      visible: true,
      width: 15,
      canResize: true,
    },
    {
      Header: "Updated Date",
      accessor: "changedDate",
      visible: true,
      width: 15,
      canResize: true,
    },
    {
      Header: "Updated Time",
      accessor: "changedTime",
      visible: true,
      width: 15,
      canResize: true,
    },
  ];
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // const getSerialNumber = (index) => {
  //   return (currentPage - 1) * 20 + index + 1;
  // };

  const [columnSettings, setColumnSettings] = useState(columns);

  const modifiedColumns = columnSettings
    .filter((col) => col.visible)
    .map((col) => ({
      ...col,
      width: col.width,
    }));

  const handleSelectRow = (id) => {
    handleCheckboxChange(id);
  };

  const handleSelectAll = () => {
    handleSelectAllChange();
  };
  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedRows([]);
    } else {
      const allIds = data.map((item) => item.id);
      setSelectedRows(allIds);
    }
    setIsAllSelected(!isAllSelected);
  };

  const handleCheckboxChange = (id) => {
    const updatedSelectedRows = selectedRows.includes(id)
      ? selectedRows.filter((rowId) => rowId !== id)
      : [...selectedRows, id];

    setSelectedRows(updatedSelectedRows);
  };

  return (
    <div>
      <Layout title="Bank Statements > MT940 > Statement Identifier" />
      <div>
        <BankStatementActions buttons={buttons} />
      </div>
      <div className="p-8">
        <div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            totalCount={totalCount}
          />
        </div>
        <div>
          <ReTable
            data={data}
            columns={modifiedColumns}
            selectedRows={selectedRows}
            onSelectRow={handleSelectRow}
            onSelectAll={handleSelectAll}
            //onRowDoubleClick={handleDoubleClick}
          />
        </div>
        {/* <div className="overflow-x-auto" style={{ maxWidth: "1600px" }}>
          <div className="overflow-y-auto" style={{ maxHeight: "600px" }}>
            <table className="table-fixed min-w-[800px] bg-white">
              <thead className="bg-sky-500 text-primary-foreground text-left text-sm font-medium sticky top-0">
                <tr className="bg-primary">
                  <th className="py-2 px-4 text-left text-white font-medium"></th>
                  <th className="py-2 px-4 text-left text-white font-medium">
                    Statement Type
                  </th>
                  <th className="py-2 px-4 text-left text-white font-medium">
                    Statement Identifier
                  </th>
                  <th className="py-2 px-4 text-left text-white font-medium">
                    Corporate BIC
                  </th>
                  <th className="py-2 px-4 text-left text-white font-medium">
                    Bank BIC
                  </th>
                  <th className="py-2 px-4 text-left text-white font-medium">
                    Active
                  </th>
                  <th className="py-2 px-4 text-left text-white font-medium">
                    Bank account id
                  </th>
                  <th className="py-2 px-4 text-left text-white font-medium">
                    Company Code
                  </th>
                  <th className="py-2 px-4 text-left text-white font-medium">
                    Bank
                  </th>
                  <th className="py-2 px-4 text-left text-white font-medium">
                    Bank Branch
                  </th>
                  <th className="py-2 px-4 text-left text-white font-medium">
                    Bank Account No
                  </th>
                  <th className="py-2 px-4 text-left text-white font-medium">
                    IBAN
                  </th>
                  <th className="py-2 px-4 text-left text-white font-medium">
                    Bank Statement Updated
                  </th>
                  <th className="py-2 px-4 text-left text-white font-medium">
                    Creation Date
                  </th>
                  <th className="py-2 px-4 text-left text-white font-medium">
                    Creation Time
                  </th>
                  <th className="py-2 px-4 text-left text-white font-medium">
                    Creation By
                  </th>
                  <th className="py-2 px-4 text-left text-white font-medium">
                    Change Date
                  </th>
                  <th className="py-2 px-4 text-left text-white font-medium">
                    Change Time
                  </th>
                  <th className="py-2 px-4 text-left text-white font-medium">
                    Changed By
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm">
                {data.length > 0 ? (
                  data.map((item, index) => (
                    <tr
                      key={item.id}
                      className={`border-b ${
                        index % 2 === 0 ? "bg-[#FFFFFF]" : "bg-[#F6F6F6]"
                      }`}
                    >
                      <td className="py-2 px-4">
                        <input type="checkbox" className="form-checkbox" />
                      </td>
                      <td className="py-2 px-4 bg-blue-100">
                        {item.statement_type}
                      </td>
                      <td className="py-2 px-4 bg-blue-100">
                        {item.statement_identifier}
                      </td>
                      <td className="py-2 px-4 bg-blue-100">
                        {item.corporate_bic}
                      </td>
                      <td className="py-2 px-4 bg-blue-100">{item.bank_bic}</td>
                      <td className="py-2 px-4">
                        <input
                          type="checkbox"
                          className="form-checkbox"
                          checked={item.status === 1}
                          readOnly
                        />
                      </td>
                      <td className="py-2 px-4">{item.bank_account_id}</td>
                      {/* <td className="py-2 px-4">Company Code</td>{" "}
                      <td className="py-2 px-4">Bank</td>{" "}
                      <td className="py-2 px-4">Bank Branch</td>{" "}
                      <td className="py-2 px-4">Bank Account No</td>{" "}
                      <td className="py-2 px-4">IBAN</td>{" "} *
        <td className="py-2 px-4">
          <input
            type="checkbox"
            className="form-checkbox"
            //checked={item.bank_statement_updated}
            readOnly
          />
        </td>
        <td className="py-2 px-4">{item.created_at}</td>
        {/* <td className="py-2 px-4">Creation Time</td>{" "}
                      <td className="py-2 px-4">Creation By</td>{" "}
                      <td className="py-2 px-4">{item.updated_at}</td>
                      <td className="py-2 px-4">Change Time</td>{" "}
                      <td className="py-2 px-4">Changed By</td>{" "} 
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="19" className="py-2 px-4 text-center">
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default StatementIdentifier;
