import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TbFlag3Filled } from "react-icons/tb";
import Header from "../../../../../components/Header";
import BamServices from "../../../../../rest-services/BamServices";

const BankAuditTail = () => {
  const { bankAccountId } = useParams();
  const { iconId } = useParams();
  const [approved, setApproved] = useState("");

  const [auditData, setAuditData] = useState([]);
  const navigate = useNavigate();
  const getApprovedIcon = () => {
    let color = "black";
    if (approved == 3) {
      color = "#29CB00"; // Green
    } else if (approved == 0) {
      color = "gray"; // Gray
    } else if (approved == 1) {
      color = "yellow"; // Yellow
    } else if (approved == 2) {
      color = "orange"; // Orange
    } else if (approved == 4) {
      color = "red"; // Red
    }

    return <TbFlag3Filled style={{ color, marginLeft: "8px" }} />;
  };

  const extraContent = iconId === "26" ? getApprovedIcon() : null;

  const handleBreadcrumbClick = (crumb) => {
    if (crumb.title === "BAM") {
      navigate("/bam");
    } else if (crumb.title === "Bank Master Data") {
      navigate("/account-master");
    } else if (crumb.title === "Account Master") {
      navigate("/account-master");
    } else if (crumb.path) {
      navigate(crumb.path);
    }
  };

  useEffect(() => {
    fetchAccountAudit();
  }, []);

  const fetchAccountAudit = async () => {
    try {
      const response = await BamServices.getAuditTrail(bankAccountId, iconId);

      if (response.data) {
        console.log("response.data.data", response.data.data);
        setAuditData(response.data.data || "");
      }
    } catch (error) {
      console.error("Error fetching bank branch details:", error);
    }
  };

  return (
    <div>
      <Header
        title={`BAM > Bank Master Data > Account Master >Audit Trail`}
        onBreadcrumbClick={handleBreadcrumbClick}
        extraContent={extraContent}
      />

      <div className="p-4">
        <div className="overflow-x-auto" style={{ maxWidth: "1600px" }}>
          <div className="overflow-y-auto" style={{ maxHeight: "720px" }}>
            {auditData && auditData.length > 0 ? (
              <table className="table-fixed min-w-[800px] bg-white border border-customBlue">
                <thead className="bg-auditBlue text-primary-foreground text-left text-sm font-medium sticky top-0">
                  <tr className="bg-primary">
                    <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">
                      S.No
                    </th>
                    <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">
                      Module
                    </th>
                    <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">
                      Icon
                    </th>
                    <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">
                      Action
                    </th>
                    <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">
                      MD Code
                    </th>
                    <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">
                      Name
                    </th>
                    <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">
                      Field Name
                    </th>
                    <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">
                      Old Value
                    </th>
                    <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">
                      New Value
                    </th>
                    <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">
                      Performed By
                    </th>
                    <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">
                      User Type
                    </th>
                    <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">
                      Date
                    </th>
                    <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">
                      Time
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {auditData.map((audit, index) => (
                    <>
                      {/* Main audit row */}
                      <tr
                        key={index}
                        className={`border-b ${
                          index % 2 === 0 ? "bg-[#FFFFFF]" : "bg-sky-50"
                        }`}
                      >
                        <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                          {index + 1}
                        </td>
                        <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                          {audit.module}
                        </td>
                        <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                          {audit.iconDetails.name}
                        </td>
                        <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                          {audit.action}
                        </td>
                        <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                          {audit.md_code}
                        </td>
                        <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                          {audit.name}
                        </td>
                        <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                          {audit.auditTrailEdits[0]?.field_name || "-"}
                        </td>
                        <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                          {audit.auditTrailEdits[0]?.old_value || "-"}
                        </td>
                        <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                          {audit.auditTrailEdits[0]?.new_value || "-"}
                        </td>
                        <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                          {audit.performed_by}
                        </td>
                        <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                          {audit.performedByUser &&
                          audit.performedByUser.userTypeDetails
                            ? audit.performedByUser.userTypeDetails.name
                            : "N/A"}
                        </td>
                        <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                          {new Intl.DateTimeFormat("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          })
                            .format(new Date(audit.date))
                            .replace(/\//g, ".")}
                        </td>

                        <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                          {audit.time}
                        </td>
                      </tr>

                      {/* Rows for each auditTrailEdit */}
                      {audit.auditTrailEdits.slice(1).map((edit, editIndex) => (
                        <tr
                          key={`${index}-${editIndex}`}
                          className={`border-b ${
                            index % 2 === 0 ? "bg-[#FFFFFF]" : "bg-sky-50"
                          }`}
                        >
                          {/* <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap"></td> */}
                          <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                            {index + 1}
                          </td>
                          <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                            {audit.module}
                          </td>
                          <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                            {audit.iconDetails.name}
                          </td>
                          <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                            {audit.action}
                          </td>
                          <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                            {audit.md_code}
                          </td>
                          <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                            {audit.name}
                          </td>
                          <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                            {edit.field_name}
                          </td>
                          <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                            {edit.old_value}
                          </td>
                          <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                            {edit.new_value}
                          </td>
                          <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                            {audit.performed_by}
                          </td>
                          <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                            {audit.performedByUser &&
                            audit.performedByUser.userTypeDetails
                              ? audit.performedByUser.userTypeDetails.name
                              : "N/A"}
                          </td>
                          <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                            {new Intl.DateTimeFormat("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            })
                              .format(new Date(audit.date))
                              .replace(/\//g, ".")}
                          </td>

                          <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                            {audit.time}
                          </td>
                        </tr>
                      ))}
                    </>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No audit trail data available.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankAuditTail;
