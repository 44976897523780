import React, { useState, useEffect } from "react";
import NoActionLayout from "../../../../../components/NoActionLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CalendarServices from "../../../../../rest-services/CalendarServices";
import { useToast } from "../../../../../components/toast/toast";
import Header from "../../../../../components/Header";
import CustomButton from "../../../../../components/CustomButton";
import { RxDropdownMenu } from "react-icons/rx";
import CountryServices from "../../../../../rest-services/CountryServices";
import UserService from "../../../../../rest-services/UserServices";
import Footer from "../../../../../components/footer";
import ButtonGroup from "../../../../../components/FooterButton";
import ReusableModal from "../../../../../components/PopupComponent";

export default function CalendarAdd() {
  const { calenderId } = useParams();
  const location = useLocation();
  const { mode } = location.state || {}; // Get the mode from the state
  const isViewMode = mode === 'view';
  const [calendarCode, setCalendarCode] = useState("");
  const [calendarName, setCalendarName] = useState("");
  const [errors, setErrors] = useState({});
  const [Country, setCountry] = useState(""); // Country Code
  const [countryId, setCountryId] = useState('');
  const [countries, setCountries] = useState([]);
  const[ selectedCountry,setSelectedCountry]=useState("");
  const [isCountryModalOpen, setIsCountryModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const isEditMode = !!calenderId;

  const [assignee, setAssignee] = useState({ id: "", assigner: "" });
  const [alert, setAlert] = useState(null);
  const{toast}=useToast();
  const navigate = useNavigate();
  useEffect(() => {
    fetchcountry();
  
  }, []); 
  useEffect(() => {
    if (calenderId) {
      getDataForEdit();
    }
    fetchAssignee();
  }, [calenderId,countries]);
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const getDataForEdit = async () => {
    try {
      const response = await CalendarServices.getCalendarById(calenderId);
      setCalendarCode(response.data.Calendar);
      setCalendarName(response.data.Calendar_Name);
      setCountry(response.data.Country);
      const country = countries.find(c => c.country === response.data.Country);
      
      if (country) {
        setCountry(country.country);
        setSelectedCountry(country.country); // Set the selected country based on countryName
      } else {
        console.error("Country not found for the provided country code.");
      }
    } catch (error) {
      console.error("Error fetching state/province data:", error);
    }
  };
  const fetchcountry = async () => {
    try {
      const response = await CalendarServices.getAllCalendar(`/get-country`);
      console.log("Fetched countries:", response); // Add this log
      setCountries(response.data.data); // Ensure this is correct
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  const validateForm = () => {
    const newErrors = {};
    if (!calendarCode) newErrors.calendarCode = "Calendar Code is required.";
    if (!calendarName) newErrors.calendarName = "Calendar Name is required.";

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      toast("error", "Missing Required Fields");
    }
    return Object.keys(newErrors).length === 0;
  };

  const fetchAssignee = async () => {
    try {
      const userDetailString = sessionStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.userId || "",
        };
        setAssignee(assigneeData);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const data = {
      id: calenderId || null,
      calendarField: calendarCode,
      Calendar_Name: calendarName,
      Country,
      created_by: assignee.assigner,
      changed_by: assignee.assigner,
    };
console.log('calendr data',data);
try {
  let response;
  if (calenderId) {
    // Updating the existing currency
    response = await CalendarServices.addCalendar(
      `/create-calendar`, // Ensure this endpoint is for updates
      data
    );
  } else {
    // Creating a new currency
    response = await CalendarServices.addCalendar(
      `/create-calendar`,
      data
    );
  }
  if (response && !response.error) {
    toast("success", calenderId ? "Calendar Updated successfully" : "Calendar Added successfully");
    // Clear the form and navigate
    setCalendarCode("");
    setCalendarName("");
    navigate("/calendar"); // Redirect after saving
  } else {
    toast("error", "Error adding/updating Calendar");
  }
} catch (error) {
  console.error("Request error:", error);
  setAlert({
    severity: "error",
    message: `Error adding/updating Calendar: ${error.message}`,
  });
}
};
const handleSaveAndContinue = async (e) => {
  e.preventDefault();
  if (!validateForm()) return;

  const data = {
    id: calenderId || null,
    calendarField: calendarCode,
    Calendar_Name: calendarName,
    Country,
    created_by: assignee.assigner,
    changed_by: assignee.assigner,
  };
console.log('calendr data',data);
try {
let response;
if (calenderId) {
  // Updating the existing currency
  response = await CalendarServices.addCalendar(
    `/create-calendar`, // Ensure this endpoint is for updates
    data
  );
} else {
  // Creating a new currency
  response = await CalendarServices.addCalendar(
    `/create-calendar`,
    data
  );
}
if (response && !response.error) {
  toast("success", calenderId ? "Calendar Updated successfully" : "Calendar Added successfully");
  // Clear the form and navigate
  setCalendarCode("");
  setCalendarName("");
  setSelectedCountry("");
  setCountry("");
} else {
  toast("error", "Error adding/updating Calendar");
}
} catch (error) {
console.error("Request error:", error);
setAlert({
  severity: "error",
  message: `Error adding/updating Calendar: ${error.message}`,
});
}
};
const checkDuplicateCalendarcode = async (code) => {
  try {
    const response = await CalendarServices.checkDuplicateCalendarcode(
      `/checkduplicatecalendar`,
      { calendar: code }  // Sending roleName to backend
    );
    
    console.log("Response from server:", response);

    if (response?.isDuplicate) {
      // Check if it's a duplicate
      setErrors((prev) => ({
        ...prev,
        calendarCode: "Calendar code already exists.",
      }));

 toast("error", "Calendar already exists.");
     
    }
    console.log("Response from external server:", response);

  } catch (error) {
    console.error("Error checking duplicate Lgecode:", error);
    // Handle potential errors when making the API call
    setErrors(prev => ({ ...prev, calendarCode: "Error checking role name" }));
  }
};

const handleCalendarChange = (e) => {
  const code = e.target.value.toUpperCase(); // Ensure input is uppercase
setUnsavedChanges(true);
  // Limit input to 3 letters
  if (errors.calendarCode) {
    setErrors((prevErrors) => ({
      ...prevErrors,
      calendarCode: "",
    }));
    return; // Stop further processing
  }

  // Clear errors if any
 

  // Update the calendar code
  setCalendarCode(code);

  // Check for duplicate Calendar Code locally
  if (code.trim() !== "") {
    checkDuplicateCalendarcode(code);
  }
};

const handleCountryCheckBoxChange = (event) => {
  console.log(`this is the Legal Entity ${countries}`)
setUnsavedChanges(true);
  const selectedName = event.target.value; // Get the selected country name
  setSelectedCountry(selectedName);

  const selectedCountryData = countries.find(country => country.country === selectedName);
  if (selectedCountryData) {
    setCountry(selectedCountryData.country); // Set the country code

    // Filter states by the selected country code
    // const filteredStates = stateProvinces.filter(state => state.Country_Code === selectedCountryData.country);
    // setFilteredStateProvinces(filteredStates); // Assuming you have state to hold the filtered states
  }
};
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'Global Master Data') {
      console.log("Navigating to /master-data");
      navigate('/master-data');
    } else if (crumb.title === 'Calendar') {
      console.log("Navigating to /country");
      navigate('/calendar');
    } 
    else if (crumb.title === 'Primary Data') {
      console.log("Navigating to /country");
      navigate('/master-data');
    
  }else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };
  return (
    <div>
   <Header
        title={`Primary Data > Global Master Data > Calendar > ${calenderId ?  (isViewMode ? 'View' : 'Edit') : 'Add'}`}
        onBreadcrumbClick={handleBreadcrumbClick} 
        hasUnsavedChanges={unsavedChanges}
      />
     
     <div className="m-6 border border-gray-200 p-5  rounded-lg">

      <div className="flex flex-col space-y-12 col-span-1 p-6">
        <label className="flex items-center">
          <span className="w-48 font-small text-sm text-customGray">
            Calendar Code<span className="text-red-500 ml-1">*</span>
          </span>
          <span className="ml-4">:</span>
          <input
            type="text"
            value={calendarCode}
     onChange={handleCalendarChange}
            
            className={`border font-small text-sm text-customGray ${
              errors.calendarCode ? "border-red-500" : "border-gray-300"
            } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
            required
            disabled={isViewMode || isEditMode}
          />
        </label>
       

        <label className="flex items-center">
          <span className="w-48 font-small text-sm text-customGray">
            Calendar Name<span className="text-red-500 ml-1">*</span>
          </span>
          <span className="ml-4">:</span>
          <input
            type="text"
            value={calendarName}
            maxLength={30}

            onChange={(e) => {setCalendarName(e.target.value);
            setUnsavedChanges(true);
              if (errors.calendarName) {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  calendarName: "",
                }));
              }
           }}
            className={`border font-small text-sm text-customGray ${
              errors.calendarName ? "border-red-500" : "border-gray-300"
            } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
            required
            disabled={isViewMode}
          />
        </label>
         <div className="flex items-center">
          <label className="w-48 font-small text-sm text-customGray" disabled={isViewMode}>Country <span className="text-red-500 ml-1">*</span></label>
          <span className="ml-4">:</span>
          <button
              onClick={() => setIsCountryModalOpen(true)}
              disabled={isViewMode}

            className={`border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-left flex items-center justify-between font-small text-sm text-customGray ${errors.countryName ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
          >
            <span className="font-small text-sm text-customGray">{selectedCountry || 'Select Country '}</span>
            <RxDropdownMenu className="ml-2" />
          </button>
          <ReusableModal
  title="Select Country Code"
  isOpen={isCountryModalOpen}
  onClose={() => {
    setIsCountryModalOpen(false);
    setSearchQuery('');
  }}
  onSave={() => {
    setIsCountryModalOpen(false);
    setSearchQuery('');
  }}
  searchPlaceholder="Search country..."
  searchQuery={searchQuery}
  onSearchChange={handleSearchChange}
  isViewMode={isViewMode}
>
  {countries.length > 0 ? (
    countries
      .filter((country) =>
        country.country.toLowerCase().includes(searchQuery.toLowerCase())
    
      )
      .map((country) => (
        <div key={country.id} className="flex items-center">
          <input
            type="radio"
            name="country"
            value={country.country}
            checked={selectedCountry === country.country}
            onChange={handleCountryCheckBoxChange}
            className="mr-2 accent-customBlue"
            disabled={isViewMode}
          />
          <label>{country.country}</label>
        </div>
      ))
  ) : (
    <p>Loading countries...</p>
  )}
</ReusableModal>
        </div>

      

      </div>
    
    </div>
    <Footer>
        {/* Footer */}
        {!isViewMode && (
          <ButtonGroup
            onSave={handleSubmit}
            onSaveContinue={handleSaveAndContinue}
            hasId={!!calenderId}
          />
        )}
      </Footer>
    </div>
  );
}
