import React, { useEffect, useRef, useState } from "react";
import Header from "../../../../components/Header";
import { FiWind } from "react-icons/fi";
import { Checkbox } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BiDroplet } from "react-icons/bi";
import { RiDropdownList } from "react-icons/ri";
import { IoIosList } from "react-icons/io";
import axios from "axios";
import { useToast } from "../../../../components/toast/toast";
import Mt940Service from "../../../../rest-services/Mt940Services";
import CustomeUpLoader from "../../../../components/CustomeLoader";

export default function AddMT940() {
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [processName, setProcessName] = useState("");
  const [statementType, setStatementType] = useState("");
  const [accountIdCheck, setAccountIdCheck] = useState(0);
  const [ttoBsCheck, setTtoBsCheck] = useState(0);
  const [runFlowCheck, setRunFlowCheck] = useState(0);
  const [runAccountCheck, setRunAccountCheck] = useState(0);
  const [assignee, setAssignee] = useState({ id: "", assigner: "" });
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);
  const [alert, setAlert] = useState(null);
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const [path, setPath] = useState("");

  useEffect(() => {
    fetchAssignee();
  }, []);

  const handleFileChange = (e) => {
    e.preventDefault();

    const selectedFiles = Array.from(e.target.files);
    selectedFiles.forEach((file) => {
      console.log(
        `File: ${file.name}, Relative Path: ${file.webkitRelativePath}`
      );
    });

    if (selectedFiles.length > 0) {
      const firstFile = selectedFiles[0];
      const folderPath = firstFile.webkitRelativePath.split("/")[0];

      setPath(folderPath);
      const finFiles = selectedFiles.filter((file) =>
        file.name.endsWith(".fin")
      );

      if (finFiles.length === 0) {
        alert("No .fin files found in the folder.");
        return;
      }

      setFiles(finFiles);
    }
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;
    setLoading(true);
    const formData = new FormData();

    files.forEach((file) => {
      formData.append("files", file);

      const modifiedPath = `application/downloads/${file.webkitRelativePath}`;
      formData.append("filePaths", modifiedPath);
    });

    formData.append("processName", processName);
    formData.append("statementType", statementType);
    formData.append("accountIdCheck", accountIdCheck);
    formData.append("ttoBsCheck", ttoBsCheck);
    formData.append("runFlowCheck", runFlowCheck);
    formData.append("runAccountCheck", runAccountCheck);
    formData.append("createdBy", assignee.assigner);
    formData.append("changedBy", assignee.assigner);

    try {
      const response = await Mt940Service.uploadMt940(formData);
      console.log(response);
      if (response.includes("Files uploaded")) {
        setLoading(false);
        toast("success", "Files uploaded successfully");
      }
    } catch (err) {
      console.error("Upload failed: ", err);
      setLoading(false);
      toast("error", "File upload failed");
    }
  };

  const handleClick = (e) => {
    fileInputRef.current.click();
  };
  const handleCheckboxChange = (setter) => (e) => {
    setter(e.target.checked ? 1 : 0);
  };

  const handleIconClick = () => {
    navigate("/mt940");
  };

  const handleButtonClick = (path) => {
    console.log(`Button clicked: ${path}`);
  };
  const fetchAssignee = async () => {
    try {
      const userDetailString = sessionStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.username || "",
        };
        setAssignee(assigneeData);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };
  const validateForm = () => {
    const newErrors = {};
    if (!processName) newErrors.processName = "Process Name is required.";
    if (!statementType)
      newErrors.statementType = "Statement Format is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  return (
    <div>
      <Header title={"Bank Statements > MT940 > MT940 Uploads > Add"} />
      <div className="p-8 pr-24">
        <div className="flex flex-col space-y-8 col-span-1 p-10 border  rounded-md">
          <label className="flex items-center">
            <span className="w-48 font-small text-gray-500">Process Name</span>
            <span className="ml-4">:</span>
            <input
              type="text"
              onChange={(e) => setProcessName(e.target.value)}
              className={`border ${
                errors.ntMainHead ? "border-red-500" : "border-gray-300"
              } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
              required
            />
            <button onClick={handleIconClick}>
              <IoIosList className="ml-6 border  h-6 w-6  hover hover:border-customBlue" />
            </button>
          </label>
          {errors.ntMainHead && (
            <p className="text-red-500 text-sm mt-1">{errors.ntMainHead}</p>
          )}

          <label className="flex items-center mt-4">
            <span className="w-48 font-small text-left text-gray-500">
              Statement Format
            </span>
            <span className="ml-4">:</span>
            <input
              type="text"
              value={statementType}
              onChange={(e) => {
                setStatementType(e.target.value);
                if (errors.statementType) {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    statementType: "",
                  }));
                }
              }}
              className={`border ${
                errors.statementType ? "border-red-500" : "border-gray-300"
              } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
              required
            />
          </label>

          <div className="flex">
            <label className="flex items-center">
              <span className="w-48 font-small text-gray-500">File path</span>
              <span className="ml-4">:</span>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
                webkitdirectory="true"
                directory="true"
                multiple
              />
              <button
                type="button"
                onClick={() => handleClick()}
                className={`border ${
                  errors.ntMainHead ? "border-red-500" : "border-gray-300"
                } p-2 w-80 h-10 focus:outline-none hover:border-blue-400 ml-8 rounded-full text-grey-200 cursor-pointer text-center`}
              >
                {files.length === 0
                  ? "System Downloads"
                  : `application/downloads/${path}`}
              </button>
            </label>
            {errors.ntMainHead && (
              <p className="text-red-500 text-sm mt-1">{errors.ntMainHead}</p>
            )}

            <label className="flex items-center ml-4">
              <button
                type="button"
                onClick={() => handleButtonClick("Application Downloads")}
                className="border border-gray-300  p-2 w-80 h-10 focus:outline-none hover:border-blue-400 ml-8 rounded-full text-black cursor-pointer text-center"
              >
                Application Downloads
              </button>
            </label>
          </div>
        </div>

        {loading === true ? <CustomeUpLoader /> : null}

        <div className="col-span-1 border p-4 rounded-md mt-2">
          <div className="flex items-center">
            <p className="w-64 ml-4 font-small text-gray-600">
              Assign Bank Account ID <span className="ml-6">:</span>
            </p>
            <Checkbox
              onChange={handleCheckboxChange(setAccountIdCheck)}
              checked={accountIdCheck === 1}
              className="form-checkbox text-blue-500 border-blue-500 rounded transform scale-75"
            />
          </div>

          <div className="flex items-center">
            <p className="w-64 ml-4 font-small text-gray-600">
              <span className="mr-1">Transfer to BS</span>{" "}
              <span className="ml-24">:</span>
            </p>
            <Checkbox
              onChange={handleCheckboxChange(setTtoBsCheck)}
              checked={ttoBsCheck === 1}
              className="form-checkbox text-blue-500 border-blue-500 rounded transform scale-75"
            />
          </div>

          <div className="flex items-center">
            <p className="w-64 ml-4 font-small text-gray-600">
              Run Flow Mapping <span className="ml-16">:</span>
            </p>
            <Checkbox
              onChange={handleCheckboxChange(setRunFlowCheck)}
              checked={runFlowCheck === 1}
              className="form-checkbox text-blue-500 border-blue-500 rounded transform scale-75"
            />
          </div>

          <div className="flex items-center">
            <p className="w-64 ml-4 font-small text-gray-600">
              Run Account Mapping <span className="ml-8">:</span>
            </p>
            <Checkbox
              onChange={handleCheckboxChange(setRunAccountCheck)}
              checked={runAccountCheck === 1}
              className="form-checkbox text-blue-500 border-blue-500 rounded transform scale-75"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center">
        <div className="mt-50px flex w-full bg-gray-50 dark:bg-zinc-800 p-4 fixed bottom-0 ">
          <button
            onClick={handleSubmit}
            className=" mt-4 ml-2 bg-customBlue text-sm text-white py-1 px-2   rounded hover:bg-blue-600 transition duration-200 ease-in-out w-28  whitespace-nowrap"
          >
            Upload & run{" "}
          </button>
        </div>
      </div>
    </div>
  );
}
