import React from 'react'
import ODBalancesTable from '../Tables/CashInBank/ODBalancesTable'
import NoActionLayout from '../../../../components/NoActionLayout'

import CashBalanceActions from '../Props/CashBalanceActions'
import { buttonConfigs } from '../Props/buttonConfig';

export default function ODBalances() {
  const buttons=buttonConfigs.screen1;

  return (
    <div>
       <div>
      <NoActionLayout title="Cash Balance > Cash in Bank > OD Balances" />
</div>
<div>
<CashBalanceActions buttons={buttons}/>
</div>
<ODBalancesTable/>
    </div>
  )
}
