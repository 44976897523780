import React, { useCallback, useEffect, useState } from 'react'
import Header from '../../../../../components/Header'
import { useNavigate } from 'react-router-dom';
import SearchInput from '../../../../../components/SearchInput';
import PrimaryActions from '../../Components/ActionButton';
import { PrimaryActionsConfig } from '../../Components/ActionButtonConfig';
import { IoAddCircleOutline, IoSettingsOutline } from 'react-icons/io5';
import { MdOutlineFileDownload, MdOutlineFileUpload } from 'react-icons/md';
import { IoMdCheckmark, IoMdClose, IoMdCopy, IoMdCreate, IoMdRefresh, IoMdTime, IoMdTrash } from 'react-icons/io';

import ColumnSettingsPopup from '../../Components/ColumnSettingPopup';
import ReTable from '../../../../../components/Table';
import * as XLSX from 'xlsx';
import { TbDeviceDesktopCancel, TbFlag3Filled } from 'react-icons/tb';
import { GiSettingsKnobs } from 'react-icons/gi';
import { useToast } from "../../../../../components/toast/toast";
import HoverButton from '../../../../../components/HoverButton';
import CustomButton from '../../../../../components/CustomButton';
import { RxDropdownMenu } from 'react-icons/rx';
import BankIdServices from '../../../../../rest-services/BankIdServices';
import { VscVmActive } from "react-icons/vsc";
import ConfirmationDialog from '../../../../../components/Deletionpopup';
import Pagination from '../../../../../components/PageNation';
import { debounce } from 'lodash';
export default function BankID() {
    const columns = [
      { Header: <TbFlag3Filled style={{ color: "#ffff" }} />, 
      accessor: "status", 
      visible: true, 
      width: 50,
      Cell: ({ value }) => {
        switch (value) {
          case 3:
            return <TbFlag3Filled style={{ color: "#29CB00" }} />; // Green for status 3
          case 0:
            return <TbFlag3Filled style={{ color: "gray" }} />; // Gray for status 0
          case 1:
            return <TbFlag3Filled style={{ color: "yellow" }} />; // Yellow for status 1
          case 2:
            return <TbFlag3Filled style={{ color: "orange" }} />; // Orange for status 2
          case 4:
            return <TbFlag3Filled style={{ color: "red" }} />; // Red for status 4
          default:
            return <TbFlag3Filled style={{ color: "black" }} />; // Default black flag
        }
      },
    },
        { Header: "Bank ID", accessor: "bankID", visible: true, width: 200    ,   alignment: "left"
        },
        { Header: "BAnk Name", accessor: "bankName", visible: true, width: 200 ,       alignment: "left"
        },
        { Header: "Bank", accessor: "bank", visible: true, width: 200 ,       alignment: "left"
        },
        { Header: "DoorNo ", accessor: "addresses[0].doorNo", visible: true, width: 200 ,alignment: "left"},
        { Header: "FloorNo ", accessor: "addresses[0].floorNo", visible: true, width: 200 ,alignment: "left"},
        { Header: "Building No ", accessor: "addresses[0].buildingNo", visible: true, width: 200 ,alignment: "left"},
        { Header: "Building Name ", accessor: "addresses[0].buildingName", visible: true, width: 200 ,alignment: "left" },
        { Header: "LandMark ", accessor: "addresses[0].landMark", visible: true, width: 200 ,alignment: "left"},
        { Header: "Street No ", accessor: "addresses[0].streetNo", visible: true, width: 200 ,alignment: "left"},
        { Header: "Street Name ", accessor: "addresses[0].streetName", visible: true, width: 200 ,alignment: "left"},
        { Header: "Road No ", accessor: "addresses[0].roadNo", visible: true, width: 200 ,alignment: "left" },
        { Header: "Road Name ", accessor: "addresses[0].roadName", visible: true, width: 200 ,alignment: "left"},
        { Header: "Block ", accessor: "addresses[0].areaBlock", visible: true, width: 200 ,alignment: "left"},
        { Header: "Area Name ", accessor: "addresses[0].areaName", visible: true, width: 200 ,alignment: "left"},
        { Header: "Address 1 ", accessor: "addresses[0].addressLine1", visible: true, width: 200  ,alignment: "left"},
        { Header: "Address 2 ", accessor: "addresses[0].addressLine2", visible: true, width: 200 ,alignment: "left"},
        { Header: "Address 3 ", accessor: "addresses[0].addressLine3", visible: true, width: 200 ,alignment: "left"},
        { Header: "Country Code ", accessor: "addresses[0].countryCode", visible: true, width: 200  ,alignment: "left"},
        { Header: "State  ", accessor: "addresses[0].state", visible: true, width: 200  ,alignment: "left"},
        { Header: "District ", accessor: "addresses[0].district", visible: true, width: 200 ,alignment: "left"},
        { Header: "Postal Code ", accessor: "addresses[0].postalCode", visible: true, width: 200 ,alignment: "left"},
        { Header: "Zip Code ", accessor: "addresses[0].zipCode", visible: true, width: 200 ,alignment: "left"},
        { Header: "PO Box ", accessor: "addresses[0].poBox", visible: true, width: 200 ,alignment: "left"},
        { Header: "Created By", accessor: "created_by", visible: true, width: 200  ,      alignment: "left"
        },
        {
          Header: "Created Date",
          accessor: "creationDate",
          visible: true,
          width: 200,
          Cell: ({ value }) => {
            if (!value) return "";
            const date = new Date(value);
            return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
          },
        },
        {
          Header: "Created Time",
          accessor: "creationTime",
          visible: true,
          width: 200,
          Cell: ({ value }) => {
            if (!value) return "";
            const date = new Date(value);
            return date.toLocaleTimeString("en-GB", {
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            });
          },
        },
        { Header: "Updated By", accessor: "changed_by", visible: true, width: 200    ,    alignment: "left"
        },
        {
          Header: "Updated Date",
          accessor: "changedDate",
          visible: true,
          width: 200,
          Cell: ({ value }) => {
            if (!value) return "";
            const date = new Date(value);
            return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
          },
        },
        {
          Header: "Updated Time",
          accessor: "changedTime",
          visible: true,
          width: 200,
          // Cell: ({ value }) => {
          //   if (!value) return "";
          //   const date = new Date(value);
          //   return date.toLocaleTimeString("en-GB", {
          //     hour: "2-digit",
          //     minute: "2-digit",
          //     second: "2-digit",
          //     hour12: true,
          //   });
          // },
        },
      ];
      
    const [companies, setCompanies] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(null);
    const navigate = useNavigate();
    // const { toast } = useToast();
    const [bankIdId, setBankIdId] = useState(null);
    const [bankIdies, setBankIdies] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [showExtraIcons, setShowExtraIcons] = useState(false);
    const [columnSettings, setColumnSettings] = useState(columns);
    const [isSettingsPopupOpen, setIsSettingsPopupOpen] = useState(false);
const[filteredBankIdies,setFilteredBankIdies]=useState([]);
const { toast } = useToast();
const [assignee, setAssignee] = useState({ id: "", assigner: "" }); // State to store assignee
const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false);
  const [isBankIdPopupOpen, setIsBankIdPopupOpen] = useState(false); // Controls popup visibility
  const [isBankIdbankPopupOpen, setIsBankIdbankPopupOpen] = useState(false); // Controls popup visibility
  const [isUpdatedByPopupOpen, setIsUpdatedByPopupOpen] = useState(false);
  const [isCreatedByPopupOpen, setIsCreatedByPopupOpen] = useState(false);
  const [filterType, setFilterType] = useState("1"); // 'createdBy' or 'updatedBy'
  const [isCountryPopupOpen, setIsCountryPopupOpen] = useState(false); // Controls popup visibility
  const [filters, setFilters] = useState({
    status: "",
    bankID: [],
    bank: [],
    countryCode: [],
    updated_by: "",
    updated_date_from: "",
    updated_date_to: "",
    created_by: "",
    created_date_from: "",
    created_date_to: "",
  });
  const [selectedBankId, setSelectedBankId] = useState([]);
  const [selectedBankIdbank, setSelectedBankIdbank] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedCreatedBy, setSelectedCreatedBy] = useState([]);
  const [selectedUpdatedBy, setSelectedUpdatedBy] = useState([]);
  const [BankIdAll, setBankIdAll] = useState([]); // For fetched company codes
  const [filteredBankIdAll, setFilteredBankIdAll] = useState([]); 
  const [BankIdAllearchQuery, setBankIdAllearchQuery] = useState("");
  const [BankIdbankAll, setBankIdbankAll] = useState([]); // For fetched company codes
  const [filteredBankIdbankAll, setFilteredBankIdbankAll] = useState([]); 
  const [BankIdAllsearchbankQuery, setBankIdAllsearchbankQuery] = useState("");
  const [countryCodes, setcountryCodes] = useState([]); // For fetched company codes
  const [filteredCountryCodes, setFilteredCountryCodes] = useState([]); 
  const [countryCodeSearchQuery, setCountryCodeSearchQuery] = useState("");
  const [updatedbydata, setupdatedbydata] = useState([]); // For fetched company codes
  const [filteredupdatedbydata, setfilteredupdatedbydata] = useState([]); 
  const [updatedbysearchquery, setupdatedbysearchquery] = useState("");
    const [createdbydatas, setcreatedbydatas] = useState([]); // For fetched company codes
  const [filteredcreatedbydatas, setFilteredcreatedbydatas] = useState([]); 
  const [createdbydatasearchQuery, setcreatedbydatasearchQuery] = useState("");
  const [tempSelectedBankId, setTempSelectedBankId] = useState(selectedBankId);
  const [tempSelectedBankIdbank, setTempSelectedBankIdbank] = useState(selectedBankId);
  const [tempSelectedcountries, setTempSelectedcountries] = useState(selectedCountries);
  const [tempSelectedupdatedby, setTempSelectedupdatedby] = useState(selectedUpdatedBy);
  const [tempSelectedcreatedby, setTempSelectedcreatedby] = useState(selectedCreatedBy);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);


 
      const modifiedColumns = columnSettings
      .filter((col) => col.visible)
      .map((col) => ({
        ...col,
        width: col.width,
      }));
      useEffect(() => {
        fetchbankid();
        fetchAssignee();
        fetchBankIdAll();
        fetchBankIdbankAll();
        fetchCountryCodes();
        fetchCreatedbydata();
        fetchupdatedbydata();
      }, []);
      
    
      useEffect(() => {
        if (BankIdAllearchQuery.trim() === "") {
          setFilteredBankIdAll(BankIdAll); // Show all if search query is empty
        } else {
          const filtered = BankIdAll.filter((code) =>
            code.bankID
              .toLowerCase()
              .includes(BankIdAllearchQuery.toLowerCase())
          );
          setFilteredBankIdAll(filtered);
        }
      }, [BankIdAllearchQuery, BankIdAll]);
      useEffect(() => {
        if (BankIdAllsearchbankQuery.trim() === "") {
          setFilteredBankIdbankAll(BankIdbankAll); // Show all if search query is empty
        } else {
          const filtered = BankIdbankAll.filter((code) =>
            code.bank
              .toLowerCase()
              .includes(BankIdAllsearchbankQuery.toLowerCase())
          );
          setFilteredBankIdbankAll(filtered);
        }
      }, [BankIdAllsearchbankQuery, BankIdbankAll]);
      
      useEffect(() => {
        if (countryCodeSearchQuery.trim() === "") {
          setFilteredCountryCodes(countryCodes); // Show all if search query is empty
        } else {
          const filtered = countryCodes.filter((code) =>
            code.toLowerCase().includes(countryCodeSearchQuery.toLowerCase())
          );
          setFilteredCountryCodes(filtered);
        }
      }, [countryCodeSearchQuery, countryCodes]);
      useEffect(() => {
        if (createdbydatasearchQuery.trim() === "") {
          setFilteredcreatedbydatas(createdbydatas); // Show all if search query is empty
        } else {
          const filtered = createdbydatas.filter((code) =>
            code.created_by
              .toLowerCase()
              .includes(createdbydatasearchQuery.toLowerCase())
          );
          setFilteredcreatedbydatas(filtered);
        }
      }, [createdbydatasearchQuery, createdbydatas]);
      useEffect(() => {
        if (updatedbysearchquery.trim() === "") {
          setfilteredupdatedbydata(updatedbydata); // Show all if search query is empty
        } else {
          const filtered = updatedbydata.filter((code) =>
            code.changed_by
              .toLowerCase()
              .includes(updatedbysearchquery.toLowerCase())
          );
          setfilteredupdatedbydata(filtered);
        }
      }, [updatedbysearchquery, updatedbydata]);
      const pageSize = 20;

      const fetchbankid = async (page, searchQuery = "") => {
        setLoading(true);
        try {
          const response = await BankIdServices.getAllbankidwithPage(
            pageSize,
            page,
            searchQuery
          );
          let { bankID, totalCount } = response.data.data;
          if (!bankID) {
            bankID = [];
          } else if (!Array.isArray(bankID)) {
            bankID = [bankID];
          }
          setFilteredBankIdies(bankID);
          setTotalCount(totalCount);
          setTotalPages(Math.ceil(totalCount / pageSize));
        } catch (error) {
          console.error("Error fetching StateProvince data", error);
        } finally {
          setLoading(false);
        }
      };
    
      // Debounced fetch function (to avoid too many API calls)
      const debouncedFetchbankidies = useCallback(
        debounce((page, query) => {
          fetchbankid(page, query);
        }, 500), // 500ms debounce delay
        []
      );
    
      // UseEffect to trigger API call on query or page change
      useEffect(() => {
        debouncedFetchbankidies(currentPage, searchQuery);
        // Cleanup debounce on unmount
        return () => debouncedFetchbankidies.cancel();
      }, [currentPage, searchQuery, debouncedFetchbankidies]);
      const fetchBankIdAll = async () => {
        setLoading(true);
        try {
          const response = await BankIdServices.getAllBankId(`/getallbankidies`);
          const responseData = response.data;
    
          if (responseData.error) {
            setAlert({ severity: "error", message: responseData.message });
            return;
          }
    
          if (responseData && Array.isArray(responseData)) {
            setBankIdAll(responseData); // assuming responseData contains an array of legal entities
            setFilteredBankIdAll(responseData);
    
          }
        } catch (error) {
          setAlert({
            severity: "error",
            message: `Error fetching bank id All: ${error.message}`,
          });
        } finally {
          setLoading(false);
        }
      };
      const fetchBankIdbankAll = async () => {
        setLoading(true);
        try {
          const response = await BankIdServices.getAllBankId(`/getallbankidbanks`);
          const responseData = response.data;
    
          if (responseData.error) {
            setAlert({ severity: "error", message: responseData.message });
            return;
          }
    
          if (responseData && Array.isArray(responseData)) {
            setBankIdbankAll(responseData); // assuming responseData contains an array of legal entities
            setFilteredBankIdbankAll(responseData);
    
          }
        } catch (error) {
          setAlert({
            severity: "error",
            message: `Error fetching bank id All: ${error.message}`,
          });
        } finally {
          setLoading(false);
        }
      };
      const fetchCountryCodes = async () => {
        setLoading(true);
        try {
          const response = await BankIdServices.getAllBankId(`/getallbankidcountrycode`);
          const responseData = response.data;
    
          if (responseData.error) {
            setAlert({ severity: "error", message: responseData.message });
            return;
          }
    
          if (responseData && Array.isArray(responseData)) {
            setcountryCodes(responseData); // assuming responseData contains an array of legal entities
            setFilteredCountryCodes(responseData);
          }
        } catch (error) {
          setAlert({
            severity: "error",
            message: `Error fetching legal entities: ${error.message}`,
          });
        } finally {
          setLoading(false);
        }
      };
      const fetchCreatedbydata = async () => {
        setLoading(true);
        try {
          const response = await BankIdServices.getAllBankId(`/getallbankidcreatedby`);
          const responseData = response.data;
    
          if (responseData.error) {
            setAlert({ severity: "error", message: responseData.message });
            return;
          }
    
          if (responseData && Array.isArray(responseData)) {
            setcreatedbydatas(responseData); // assuming responseData contains an array of legal entities
            setFilteredcreatedbydatas(responseData);
    
          }
        } catch (error) {
          setAlert({
            severity: "error",
            message: `Error fetching legal entities: ${error.message}`,
          });
        } finally {
          setLoading(false);
        }
      };
      const fetchupdatedbydata = async () => {
        setLoading(true);
        try {
          const response = await BankIdServices.getAllBankId(`/getallbankidupdatedby`);
          const responseData = response.data;
    
          if (responseData.error) {
            setAlert({ severity: "error", message: responseData.message });
            return;
          }
    
          if (responseData && Array.isArray(responseData)) {
            setupdatedbydata(responseData); // assuming responseData contains an array of legal entities
            setfilteredupdatedbydata(responseData);
    
          }
        } catch (error) {
          setAlert({
            severity: "error",
            message: `Error fetching legal entities: ${error.message}`,
          });
        } finally {
          setLoading(false);
        }
      };
      const fetchAssignee = async () => {
        try {
          const userDetailString = sessionStorage.getItem("userDetail");
          if (userDetailString) {
            const userDetail = JSON.parse(userDetailString);
            const assigneeData = {
              id: userDetail.id || "",
              assigner: userDetail.username || "",
            };
            setAssignee(assigneeData);
          } else {
            setAlert({
              severity: "error",
              message: "No user detail found in session storage.",
            });
          }
        } catch (error) {
          setAlert({
            severity: "error",
            message: `Error fetching user details: ${error.message}`,
          });
        }
      };
      const handleDelete = async () => {
        if (selectedRows.length === 0) return; // No rows selected to delete
        setShowConfirmation(true); // Show the confirmation dialog
      };
    
      const confirmDeletion = async () => {
        setShowConfirmation(false); // Close the dialog
        setLoading(true);
        try {
          for (const id of selectedRows) {
           const response =  await BankIdServices.deleteBankId(id);
           
           if (response.status !== 200) {
            toast("error", `Bank ID cannot be deleted due to its status.`);
          }
          if (response.status === 200) {
            toast("success", "Selected BankId deleted successfully");
          }
          }
          // Optionally show success toast
          setSelectedRows([]); // Clear selection
          fetchbankid(); // Refresh the list
        } catch (error) {
          console.error("Error deleting bankid(s):", error);
          // Optionally show error toast
          // toast("error", "Failed to delete BuGroup(s)");
        } finally {
          setLoading(false);
        }
      };
    
      const cancelDeletion = () => {
        setShowConfirmation(false); // Close the dialog
      };
      const handleApproveClick = async () => {
        if (selectedRows.length > 0) {
          try {
             // Assuming selectedRows contains the selected bank Bank Id IDs
            
            // Pass the array of selected IDs to the approval service
            const response = await BankIdServices.saveapproved(
              `${process.env.REACT_APP_BASE_URL}/approvebankid`,
              { id: selectedRows ,created_by: assignee.assigner }  // Pass selectedBankBank IdIds in the body
            );
            
            console.log("Response status:", response);
      
            if (response.status === 200) {
              
              toast("success", "Bank Id successfully Approved");

              // Success logic, refresh the bank Bank Id
               fetchbankid();
            }  else if (response.status === 400) {
              toast("warning", "Bank Id is unable to approve.");
             } 
             else if (response.status === 550) {
              toast("warning", "Bank Id Email is Not Sent.");
             } 
             else {
              console.log("Error updating Bank Ids.");
            }
          } catch (error) {
            console.error("Error approving bank Bank Id:", error);
          }
        } else {
          console.log("Please select at least one bank Bank Id to approve.");
        }
      };
      const handleRejectClick = async () => {
        if (selectedRows.length > 0) {
          try {
             // Assuming selectedRows contains the selected bank Bank Id IDs
            
            // Pass the array of selected IDs to the approval service
            const response = await BankIdServices.saveapproved(
              `${process.env.REACT_APP_BASE_URL}/rejectbankid`,
              { id: selectedRows ,created_by: assignee.assigner }  // Pass selectedBankBank IdIds in the body
            );
            
            console.log("Response status:", response.status);
      
            if (response.status === 200) {
              toast("error", "Bank Id successfully Rejected");
              fetchbankid();
            }  else if (response.status === 400){
              toast("warning", "Bank Id is unable to Reject.");
            } else {
              console.log("Error updating Bank Ids.");
            }
          } catch (error) {
            console.error("Error approving bank Bank Id:", error);
          }
        } else {
          console.log("Please select at least one bank Bank Id to approve.");
        }
      };
      const handleInactiveClick = async () => {
        if (selectedRows.length > 0) {
          try {
             // Assuming selectedRows contains the selected bank Bank Id IDs
            
            // Pass the array of selected IDs to the approval service
            const response = await BankIdServices.saveapproved(
              `${process.env.REACT_APP_BASE_URL}/inactivebankid`,
              { id: selectedRows ,created_by: assignee.assigner }  // Pass selectedBankBank IdIds in the body
            );
            
            console.log("Response status:", response);
      
            if (response.status ===  200) {
              toast("success", "Bank Id successfully Change Status.");
              fetchbankid();
            } 
            else if (response.status === 400){
              toast("warning", "The Bank Id cannot be deactivated at this time.");
            } else {
              console.log("Error approving bank Bank Id.");
            }
          } catch (error) {
            console.error("Error approving bank Bank Id:", error);
          }
        } else {
          console.log("Please select at least one bank Bank Id to approve.");
        }
      };
      const handleAuditTrail =() => {
        if (selectedRows.length === 1) {
          const selectedUser = filteredBankIdies.find((bank) => bank.id === selectedRows[0]);
          console.log('selected bank id', selectedUser);
     
          if (selectedUser) {
            // Show the selected user's details using toast
            // toast("info", `Selected User: \nID: ${selectedUser.userId}\nName: ${selectedUser.username}`);
           
            // Navigate to the edit screen, passing the selected user as state
            navigate(`/audit-trail-bankid/${10}/${selectedRows[0]}`);
          } else {
            toast("warning", "Selected bankid not found.");
          }
        } else {
          toast("warning", "Please select exactly one bankid to edit.");
        }
      };
      const handleAddcopyClick = () => {
        if (selectedRows.length === 1) {
          const selectedUser = filteredBankIdies.find((bankid) => bankid.id === selectedRows[0]);
          console.log('Selected Bu group to copy:', selectedUser);
      
          if (selectedUser) {
            // Navigate to the add page with the selected user data
            navigate(`/bankidscreen-add/${bankIdId}`, { state: { copiedData: selectedUser } });
          } else {
            toast("warning", "Selected user not found.");
          }
        } else {
          toast("warning", "Please select exactly one user to copy.");
        }
      };
      const handleApplyFilter = async () => {
        try {
          const response = await BankIdServices.getFilterBankid(
            "/getfilterbankid",
            filters
          );
          if (!response.error) {
            setFilteredBankIdies(response.data);
          } else {
            console.error("Error:", response.message);
          }
        } catch (error) {
          console.error("Error fetching filtered companies:", error);
        }
      };
    const extraButtons = [
      {
        icon: IoMdCheckmark,
        onClick: handleApproveClick,
        title: "Approve",
        disabled: selectedRows.length === 0,
        
      },
      {
        icon: IoMdClose,
        onClick: handleRejectClick,
        title: "Reject",
        disabled: selectedRows.length === 0,
      },
        {
          icon: IoMdCreate,
          onClick: () => bankIdId && navigateToForm(bankIdId),
          title:"Edit Country",
          disabled: selectedRows.length === 0,  
    
        },
        {
          icon: IoMdCopy,
          onClick: handleAddcopyClick,
          title: "Copy",
          disabled: selectedRows.length === 0,
        },
        {
          icon: IoMdTime,
          onClick: handleAuditTrail,
          title: "Audit Trail",
          disabled: selectedRows.length === 0,
        },
        {
          icon: IoMdTrash,
          onClick: handleDelete,
          title: "Delete",
          disabled: selectedRows.length === 0,
        },
        {
          icon: VscVmActive,
          onClick: handleInactiveClick,
          title: "Inactive/Active",
          disabled: selectedRows.length === 0,
        },
      ];
      const navigateToForm = (id) => {
        navigate(`/bankidscreen-edit/${id}`);
      };
    const handleBreadcrumbClick = (crumb) => {
        console.log("Breadcrumb clicked:", crumb.title);
        if (crumb.title === "Company & Bank Data") {
          navigate("/master-data");
        } else if (crumb.title === "Bank ID") {
          navigate("/bankidscreen");
        } else if (crumb.title === "Primary Data") {
          navigate("/master-data");
        } else if (crumb.path) {
          navigate(crumb.path); 
        }
      };
      const handleSettingsButtonClick = () => {
        setIsSettingsPopupOpen(true);
      };
      const handleDownload = () => {
        const dataToExport = filteredBankIdies.map((bankid) => ({
          "Bank ID": bankid.bankID,
          "Bank Name": bankid.bankName,
          "Bank": bankid.bank,
          "Door No": bankid.addresses?.[0]?.doorNo || "", // Ensure addresses array exists
          "Floor No": bankid.addresses?.[0]?.floorNo || "",
          "Building No": bankid.addresses?.[0]?.buildingNo || "", // Ensure addresses array exists
          "Building Name": bankid.addresses?.[0]?.buildingName || "", // Ensure addresses array exists
          "Land Mark Name": bankid.addresses?.[0]?.landMark || "",
          "Street No": bankid.addresses?.[0]?.streetNo || "",
          "Street Name": bankid.addresses?.[0]?.streetName || "",
          "Road No": bankid.addresses?.[0]?.streetName || "",
          "Road Name": bankid.addresses?.[0]?.streetName || "",
          "Street Name": bankid.addresses?.[0]?.streetName || "",
          "Street Name": bankid.addresses?.[0]?.streetName || "",
          "Area Block": bankid.addresses?.[0]?.areaBlock || "", // Ensure addresses array exists
          "Area Name": bankid.addresses?.[0]?.areaName || "",
          "Address Line1": bankid.addresses?.[0]?.addressLine1 || "",
          "Address Line2": bankid.addresses?.[0]?.addressLine2 || "",
          "Address Line3": bankid.addresses?.[0]?.addressLine3 || "",
          "Country Code": bankid.identifier?.[0]?.country_Code || "", 
          "State Name": bankid.addresses?.[0]?.stateName || "", // Ensure state name from addresses
          "District": bankid.addresses?.[0]?.district || "",
          "Postal Code": bankid.addresses?.[0]?.postalCode || "",
          "Zip Code": bankid.addresses?.[0]?.zipCode || "",
          "Po Box": bankid.addresses?.[0]?.poBox || "",
          "Created By": bankid.created_by,
          "Created Date": bankid.creationDate ? new Date(bankid.creationDate).toLocaleDateString()
          : "",
          "Created Time": bankid.creationTime  ? new Date(bankid.creationTime).toLocaleDateString()
          : "",
          "Updated By": bankid.changed_by,
          "Updated Date": bankid.changedDate
            ? new Date(bankid.changedDate).toLocaleDateString()
            : "",
          "Updated Time": bankid.changedTime
            ? new Date(bankid.changedTime).toLocaleTimeString()
            : "",
         
        }));
        const worksheet = XLSX.utils.json_to_sheet(dataToExport);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "BankId");
        XLSX.writeFile(workbook, "BankID_Data.xlsx");
      };
      const handleReload = async () => {
        setLoading(true);  // Start loading
        try {
          await fetchbankid();  // Fetch users
        } catch (error) {
          console.error('Error reloading users:', error);
        } finally {
          setLoading(false);  // Stop loading
        }
      };
      const toggleFilterPopup = () => {
        setIsFilterPopupOpen(!isFilterPopupOpen);
      };
      const existingButtons = PrimaryActionsConfig.screen2.map((button) => ({
        ...button,
        title:
          button.icon === IoAddCircleOutline
            ? "Add Country"
            : button.icon === GiSettingsKnobs
            ? "Filter"
            : button.icon === MdOutlineFileDownload
            ? "Download"
             : button.icon === MdOutlineFileUpload
            ? "Upload"
            : button.icon === IoSettingsOutline
            ? "Settings"
             : button.icon === IoMdRefresh
      ? "Refresh"
            : button.title || "", 
        onClick:
          button.icon === IoAddCircleOutline
            ? () => handleNavigate("/bankidscreen-add")
            : button.icon === MdOutlineFileDownload 
            ? handleDownload
            : button.icon === IoSettingsOutline
            ? handleSettingsButtonClick
            : button.icon === IoMdRefresh
            ? handleReload
            : button.icon === GiSettingsKnobs
            ?toggleFilterPopup
            : button.onClick || (() => {}),
      }));
      const actionButtons = existingButtons.concat(
        extraButtons.map((button) => ({
          ...button,
          disabled: selectedRows.length === 0, 
          onClick: selectedRows.length > 0 ? button.onClick : null, 
        }))
      );
 
      
      const handleNavigate = (path) => {
        navigate(path);
      };
      const handleSelectRow = (id) => {
        handleCheckboxChange(id);
      };
      const handleSelectAll = () => {
        handleSelectAllChange();
      };
      const handleDoubleClick = (id) => {
        navigate(`/bankidscreen-view/${id}`, { state: { mode: "view" } });
      };
      const handleCheckboxChange = (id) => {
        const updatedSelectedRows = selectedRows.includes(id)
          ? selectedRows.filter((rowId) => rowId !== id)
          : [...selectedRows, id];
    
        setSelectedRows(updatedSelectedRows);
        setBankIdId(id);
        setShowExtraIcons(updatedSelectedRows.length > 0);
      };
    const handleUpdateColumnSettings = (updatedSettings) => {
        setColumnSettings(updatedSettings);
      };
    
   
    const handleSelectAllChange = () => {
        if (isAllSelected) {
          setSelectedRows([]);
        } else {
          const allIds = companies.map((item) => item.id);
          setSelectedRows(allIds);
        }
        setIsAllSelected(!isAllSelected);
      };
      if (loading)
        return (
          <div>
          </div>
        );
        const handleFilterChange = (e) => {
          setFilters((prevFilters) => ({
            ...prevFilters,
            status: e.target.value,
          }));
        };
      
        const handleFilterDateFrom = (e) => {
          console.log(filterType);
          if (filterType === "1") {
            setFilters((prevFilters) => ({
              ...prevFilters,
              created_date_from: e.target.value,
            }));
          } else {
            setFilters((prevFilters) => ({
              ...prevFilters,
              updated_date_from: e.target.value,
            }));
          }
        };
        const handleFilterDateto = (e) => {
          if (filterType === "1") {
            setFilters((prevFilters) => ({
              ...prevFilters,
              created_date_to: e.target.value,
            }));
          } else {
            setFilters((prevFilters) => ({
              ...prevFilters,
              updated_date_to: e.target.value,
            }));
          }
        };
       
      
      
        const handleResetFilters = () => {
          setFilters({
            status: "",
            bankid: [],
            bank:[],
            countryCode: [],
            updated_by: "",
            updated_date_from: "",
            updated_date_to: "",
            created_by: "",
            created_date_from: "",
            created_date_to: "",
          });
          setSelectedCountries([]);
          setSelectedBankId([]);
          setSelectedBankIdbank([]);
          setSelectedCreatedBy([]);
          setSelectedUpdatedBy([]);
          // ithu apply click panni than rest pannanum na intha function call theva illa
          fetchbankid();
        };
      
        const handleBankidSelection = (Bankid) => {
          setTempSelectedBankId((prev) => {
            // Add or remove Bankid from temporary selection list
            return prev.includes(Bankid)
              ? prev.filter((item) => item !== Bankid) // Remove if already selected
              : [...prev, Bankid]; // Add if not selected
          });
        };
      
        // Reset temporary selection when resetting popup
        const handleBankidReset = () => {
          setTempSelectedBankId([]); // Reset selection
        };
      
        // Apply filter and close the popup
        const handleApplyBankid = () => {
          setSelectedBankId(tempSelectedBankId); // Apply temporary selection to actual selection state
          setFilters((prevFilters) => ({
            ...prevFilters,
            bankID: tempSelectedBankId, // Set the filter
          }));
          setIsBankIdPopupOpen(false); // Close the popup
        };
      
        const handleBankidbankSelection = (bank) => {
          setTempSelectedBankIdbank((prev) => {
            // Add or remove Bankid from temporary selection list
            return prev.includes(bank)
              ? prev.filter((item) => item !== bank) // Remove if already selected
              : [...prev, bank]; // Add if not selected
          });
        };
      
        // Reset temporary selection when resetting popup
        const handleBankidbankReset = () => {
          setTempSelectedBankIdbank([]); // Reset selection
        };
      
        // Apply filter and close the popup
        const handleApplyBankidbank = () => {
          setSelectedBankIdbank(tempSelectedBankIdbank); // Apply temporary selection to actual selection state
          setFilters((prevFilters) => ({
            ...prevFilters,
            bank: tempSelectedBankIdbank, // Set the filter
          }));
          setIsBankIdbankPopupOpen(false); // Close the popup
        };
      
        const handleCountrySelection = (countryCode) => {
          setTempSelectedcountries((prev) => {
            // Add or remove company from temporary selection list
            return prev.includes(countryCode)
              ? prev.filter((item) => item !== countryCode) // Remove if already selected
              : [...prev, countryCode]; // Add if not selected
          });
        };
      
        // Reset temporary selection when resetting popup
        const handleCountryReset = () => {
          setTempSelectedcountries([]); // Reset selection
        };
      
        // Apply filter and close the popup
        const handleApplyCountry = () => {
          setSelectedCountries(tempSelectedcountries); // Apply temporary selection to actual selection state
          setFilters((prevFilters) => ({
            ...prevFilters,
            countryCode: tempSelectedcountries, // Set the filter
          }));
          setIsCountryPopupOpen(false); // Close the popup
        };
      
        const handleCreatedBySelection = (createdBy) => {
          setTempSelectedcreatedby((prev) => {
            // Add or remove company from temporary selection list
            return prev.includes(createdBy)
              ? prev.filter((item) => item !== createdBy) // Remove if already selected
              : [...prev, createdBy]; // Add if not selected
          });
        };
      
        // Reset temporary selection when resetting popup
        const handleCreatedbyReset = () => {
          setTempSelectedcreatedby([]); // Reset selection
        };
      
        // Apply filter and close the popup
        const handleApplyCreatedby = () => {
          setSelectedCreatedBy(tempSelectedcreatedby); // Apply temporary selection to actual selection state
          setFilters((prevFilters) => ({
            ...prevFilters,
            created_by: tempSelectedcreatedby, // Set the filter
          }));
          setIsCreatedByPopupOpen(false); // Close the popup
        };
        
        
      
      
        const handleUpdateBySelection = (changedby) => {
          setTempSelectedupdatedby((prev) => {
            // Add or remove company from temporary selection list
            return prev.includes(changedby)
              ? prev.filter((item) => item !== changedby) // Remove if already selected
              : [...prev, changedby]; // Add if not selected
          });
        };
      
        // Reset temporary selection when resetting popup
        const handleUpdatedbyReset = () => {
          setTempSelectedupdatedby([]); // Reset selection
        };
      
        // Apply filter and close the popup
        const handleApplyUpdatedby = () => {
          setSelectedUpdatedBy(tempSelectedupdatedby); // Apply temporary selection to actual selection state
          setFilters((prevFilters) => ({
            ...prevFilters,
            updated_by: tempSelectedupdatedby, // Set the filter
          }));
          setIsUpdatedByPopupOpen(false); // Close the popup
        };
        const handlePageChange = (page) => {
          setCurrentPage(page);
        };
  return (
    <div>
        <Header
        title={"Primary Data > Company & Bank Data > Bank ID"}
        onBreadcrumbClick={handleBreadcrumbClick}
      />
    <div>
        <div className="flex justify-between items-center -ml-1 pl-4">
        <div className="flex items-center">

        <SearchInput
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              placeholder="Search by Bank id"
            />


          <PrimaryActions
            buttons={actionButtons}
            selectedCount={selectedRows.length}
            isExtraButtonsVisible={showExtraIcons}
          />
          </div>
          <div className="flex items-center mr-4">

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            totalCount={totalCount}
          />
          
        </div>
        </div>

        <div>
         
        </div>
        <ReTable
          data={filteredBankIdies}
          columns={modifiedColumns} // Use modified columns based on visibility
          selectedRows={selectedRows}
          onSelectRow={handleSelectRow}
          onSelectAll={handleSelectAll}
          onRowDoubleClick={handleDoubleClick}
        />
     
      {isSettingsPopupOpen && (
        <ColumnSettingsPopup
          columns={columnSettings}
          onClose={() => setIsSettingsPopupOpen(false)}
          onUpdate={handleUpdateColumnSettings}
        />
      )}
      {isFilterPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
          <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-3xl z-50">
            <div className="flex justify-between items-center mt-2">
              <h2 className="text-customGray font-semibold mb-5">
                Apply Filters
              </h2>
              <HoverButton
                onClick={handleResetFilters}
                title={"Reset Filter"}
              />
            </div>

            {/* Grid Layout */}
            <div className="grid grid-cols-2 gap-4">
              {/* Left-Side Single Column Fields */}
              <div className="col-span-2 sm:col-span-1">
                <div className="flex items-center mb-2">
                  <label className="text-customGray mr-2 w-[140px]">
                    Status:
                  </label>
                  <select
                    name="status"
                    value={filters.status}
                    onChange={handleFilterChange}
                    className="text-customGray border border-gray-300 pl-1 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400 "
                  >
                    <option value="" className=" text-blue-500">
                      Select Status
                    </option>
                    <option value="0" className=" text-gray-500">
                      Created
                    </option>
                    <option value="2" className=" text-orange-400">
                     Modified
                    </option>
                    <option value="1 " className=" text-yellow-500">
                      Change
                    </option>
                    <option value="3" className=" text-[#29CB00]">
                      Approved
                    </option>
                    <option value="4" className=" text-red-500">
                      Rejected
                    </option>
                    <option value="5" className=" text-black">
                      Inactive
                    </option>
                  </select>
                </div>
                <div className="flex items-center mb-2">
                  <label className="text-customGray mr-2 w-[140px]">
                    Bank Id:
                  </label>
                  <button
                    className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer"
                    onClick={() => {
                      setTempSelectedBankId(selectedBankId);
                      setIsBankIdPopupOpen(true);
                      // Reset filteredBuGroup to show all groups when the popup is opened
                    }}
                  >
                    <span className="truncate">
                      {selectedBankId.length > 0
                        ? selectedBankId.join(", ")
                        : "Bank Id"}
                    </span>
                    <RxDropdownMenu className="ml-2 text-lg" />
                  </button>
                </div>

                {isBankIdPopupOpen && (
                  <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
                    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-xl z-50">
                      <div className="flex justify-between items-center mb-4">
                        <h2 className="text-customGray font-semibold">
                          Select Bank Id
                        </h2>
                        <HoverButton
                          // onClick={resetSelectedBuGroups}
                          title={"Reset"}
                          onClick={handleBankidReset}
                        />
                      </div>

                      {/* Search Input */}
                      <input
                        type="text"
                        className="border border-gray-300 p-2 rounded-md mb-4 w-full"
                        placeholder="Search Bank Id"
                        value={BankIdAllearchQuery}
                        onChange={(e) => setBankIdAllearchQuery(e.target.value)} // Filter BU Groups
                      />

                      {/* List of BU Groups */}
                      <div className="space-y-2 max-h-80 overflow-y-auto">
                        {filteredBankIdAll.map((bankid) => (
                          <div key={bankid.id} className="flex items-center">
                            <input
                              type="checkbox"
                              checked={tempSelectedBankId.includes(
                                bankid.bankID
                              )} // Adjusted to use the correct variable
                              onChange={() =>
                                handleBankidSelection(bankid.bankID)
                              }
                            />
                            <label className="ml-2">
                              {bankid.bankID}
                            </label>{" "}
                            {/* Adjusted to match `company` */}
                          </div>
                        ))}
                      </div>

                      {/* Apply Filter Button */}
                      <div className="flex justify-end mt-4">
                        <HoverButton
                          title={"Cancel"}
                          className={"mr-2"}
                          onClick={() => setIsBankIdPopupOpen(false)}
                        />
                        <CustomButton
                          onClick={() => {
                            handleApplyBankid();
                            setIsBankIdPopupOpen(false);
                          }}
                          title={"Apply"}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="flex items-center mb-2">
                  <label className="text-customGray mr-2 w-[140px]">
                    Bank :
                  </label>
                  <button
                    className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer"
                    onClick={() => {
                      setTempSelectedBankIdbank(selectedBankIdbank);
                      setIsBankIdbankPopupOpen(true);
                      // Reset filteredBuGroup to show all groups when the popup is opened
                    }}
                  >
                    <span className="truncate">
                      {selectedBankIdbank.length > 0
                        ? selectedBankIdbank.join(", ")
                        : "Bank "}
                    </span>
                    <RxDropdownMenu className="ml-2 text-lg" />
                  </button>
                </div>

                {isBankIdbankPopupOpen && (
                  <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
                    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-xl z-50">
                      <div className="flex justify-between items-center mb-4">
                        <h2 className="text-customGray font-semibold">
                          Select Bank 
                        </h2>
                        <HoverButton
                          // onClick={resetSelectedBuGroups}
                          title={"Reset"}
                          onClick={handleBankidbankReset}
                        />
                      </div>

                      {/* Search Input */}
                      <input
                        type="text"
                        className="border border-gray-300 p-2 rounded-md mb-4 w-full"
                        placeholder="Search Bank Id"
                        value={BankIdAllsearchbankQuery}
                        onChange={(e) => setBankIdAllsearchbankQuery(e.target.value)} // Filter BU Groups
                      />

                      {/* List of BU Groups */}
                      <div className="space-y-2 max-h-80 overflow-y-auto">
                        {filteredBankIdbankAll.map((bank) => (
                          <div key={bank.id} className="flex items-center">
                            <input
                              type="checkbox"
                              checked={tempSelectedBankIdbank.includes(
                                bank.bank
                              )} // Adjusted to use the correct variable
                              onChange={() =>
                                handleBankidbankSelection(bank.bank)
                              }
                            />
                            <label className="ml-2">
                              {bank.bank}
                            </label>{" "}
                            {/* Adjusted to match `company` */}
                          </div>
                        ))}
                      </div>

                      {/* Apply Filter Button */}
                      <div className="flex justify-end mt-4">
                        <HoverButton
                          title={"Cancel"}
                          className={"mr-2"}
                          onClick={() => setIsBankIdbankPopupOpen(false)}
                        />
                        <CustomButton
                          onClick={() => {
                            handleApplyBankidbank();
                            setIsBankIdbankPopupOpen(false);
                          }}
                          title={"Apply"}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="flex items-center mb-2">
                  <label className="text-customGray mr-2 w-[140px]">
                    Country :
                  </label>
                  <button
                    className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer"
                    onClick={() => {
                      setTempSelectedcountries(selectedCountries);
                      setIsCountryPopupOpen(true);
                      // Reset filteredBuGroup to show all groups when the popup is opened
                    }}
                  >
                    <span className="truncate">
                      {selectedCountries.length > 0
                        ? selectedCountries.join(", ")
                        : "Country"}
                    </span>
                    <RxDropdownMenu className="ml-2 text-lg" />
                  </button>
                </div>

                {isCountryPopupOpen && (
                  <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
                    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-xl z-50">
                      <div className="flex justify-between items-center mb-4">
                        <h2 className="text-customGray font-semibold">
                          Select Country
                        </h2>
                        <HoverButton
                          onClick={handleCountryReset}
                          title={"Reset"}
                        />
                      </div>

                      {/* Search Input */}
                      <input
                        type="text"
                        className="border border-gray-300 p-2 rounded-md mb-4 w-full"
                        placeholder="Search Country"
                        value={countryCodeSearchQuery}
                        onChange={(e) => setCountryCodeSearchQuery(e.target.value)} // Filter BU Groups
                      />

                      {/* List of BU Groups */}
                      <div className="space-y-2 max-h-80 overflow-y-auto">
  {filteredCountryCodes.map((code) => (
    <div key={code} className="flex items-center">
      <input
        type="checkbox"
        checked={tempSelectedcountries.includes(code)}
        onChange={() => handleCountrySelection(code)}
      />
      <label className="ml-2">{code}</label>
    </div>
  ))}
</div>


                      {/* Apply Filter Button */}
                      <div className="flex justify-end mt-4">
                        <HoverButton
                          title={"Cancel"}
                          className={"mr-2"}
                          onClick={() => setIsCountryPopupOpen(false)}
                        />
                        <CustomButton
                          onClick={() => {
                            handleApplyCountry();
                            setIsCountryPopupOpen(false);
                          }}
                          title={"Apply"}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="flex items-center">
                  {/* Dropdown for selecting 'Created By' or 'Updated By' */}
                  <select
                    name="filterType"
                    value={filterType} // State to track the selected filter option
                    onChange={(e) => {
                      setFilterType(e.target.value); // Update the selected filter
                    }}
                    className="text-customGray border border-gray-300 p-2 rounded-md w-[132px] h-[40px] focus:outline-none hover:border-blue-400"
                  >
                    <option value="1">Created By</option>
                    <option value="2">Updated By</option>
                  </select>

                  {/* Button to open the popup */}
                  <button
                    className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer ml-4"
                    onClick={() => {
                      if (filterType === "1") {
                        setIsCreatedByPopupOpen(true); 
                        setTempSelectedcreatedby(selectedCreatedBy);// Open the Created By popup
                      } else if (filterType === "2") {
                        setIsUpdatedByPopupOpen(true); // Open the Updated By popup
                        setTempSelectedupdatedby(selectedUpdatedBy); // Set the selected data correctly before opening the popup
                      }
                     
                    }}
                    disabled={!filterType} // Disable button if no filter is selected
                  >
                    <span className="truncate">
                      {filterType === "1"
                        ? selectedCreatedBy.length > 0
                          ? selectedCreatedBy.join(", ")
                          : "Created By"
                        : filterType === "2"
                        ? selectedUpdatedBy.length > 0
                          ? selectedUpdatedBy.join(", ")
                          : "Updated By"
                        : "Select Filter"}
                    </span>
                    <RxDropdownMenu className="ml-2 text-lg" />
                  </button>
                </div>
                {isCreatedByPopupOpen && (
                  <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
                    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-xl z-50">
                      <div className="flex justify-between items-center mb-4">
                        <h2 className="text-customGray font-semibold">
                          Select Created By
                        </h2>
                        <HoverButton
                           onClick={handleCreatedbyReset}
                          title={"Reset"}
                        />
                      </div>
                      {/* Search and Filter */}
                      <input
                        type="text"
                        className="border border-gray-300 p-2 rounded-md mb-4 w-full"
                        placeholder="Search Created By"
                        value={createdbydatasearchQuery}
                        onChange={(e) => setcreatedbydatasearchQuery(e.target.value)}
                      />
                      <div className="space-y-2 max-h-80 overflow-y-auto">
                        {filteredcreatedbydatas.map((created) => (
                          <div key={created.id} className="flex items-center">
                            <input
                              type="checkbox"
                              checked={tempSelectedcreatedby.includes(
                                created.created_by
                              )}
                              onChange={() =>
                                handleCreatedBySelection(created.created_by)
                              }
                            />
                            <label className="ml-2">{created.created_by}</label>
                          </div>
                        ))}
                      </div>

                      {/* Actions */}
                      <div className="flex justify-end mt-4">
                        <HoverButton
                          title={"Cancel"}
                          className={"mr-2"}
                          onClick={() => setIsCreatedByPopupOpen(false)}
                        />
                        <CustomButton
                          onClick={() => {
                            setIsCreatedByPopupOpen(false);
                             handleApplyCreatedby()  ;                          // applyCreatedByFilter(); // Apply the selected filter
                          }}
                          title={"Apply"}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {/* Updated By Popup */}
                {isUpdatedByPopupOpen && (
                  <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
                    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-xl z-50">
                      <div className="flex justify-between items-center mb-4">
                        <h2 className="text-customGray font-semibold">
                          Select Updated By
                        </h2>
                        <HoverButton
                           onClick={handleUpdatedbyReset}
                          title={"Reset"}
                        />
                      </div>
                      {/* Search and Filter */}
                      <input
                        type="text"
                        className="border border-gray-300 p-2 rounded-md mb-4 w-full"
                        placeholder="Search Updated By"
                        value={updatedbysearchquery}
                        onChange={(e) => setupdatedbysearchquery(e.target.value)}
                      />
                      <div className="space-y-2 max-h-80 overflow-y-auto">
                        {filteredupdatedbydata.map((updated) => (
                          <div key={updated.id} className="flex items-center">
                            <input
                              type="checkbox"
                              checked={tempSelectedupdatedby.includes(updated.changed_by)} // Ensure it's checking the correct temporary selection state
                              onChange={() => handleUpdateBySelection(updated.changed_by)}
                            />
                            <label className="ml-2">{updated.changed_by}</label>
                          </div>
                        ))}
                      </div>
                      {/* Actions */}
                      <div className="flex justify-end mt-4">
                        <HoverButton
                          title={"Cancel"}
                          className={"mr-2"}
                          onClick={() => setIsUpdatedByPopupOpen(false)}
                        />
                        <CustomButton
                          onClick={() => {
                            handleApplyUpdatedby();
                            setIsUpdatedByPopupOpen(false);
                            // applyUpdatedByFilter(); // Apply the selected filter
                          }}
                          title={"Apply"}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {filterType && (
                <div className="col-span-2 flex justify-between items-center">
                  <div className="flex items-center">
                    <label className="text-customGray mr-2 w-[140px]">
                      {filterType === "createdBy"
                        ? " Date From:"
                        : " Date From:"}
                    </label>
                    <input
                      type="date"
                      name="dateFrom"
                      value={
                        filterType === "1"
                          ? filters.created_date_from
                          : filters.updated_date_from
                      }
                      onChange={(e) => handleFilterDateFrom(e)}
                      className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400"
                    />
                  </div>
                  <div className="flex items-center">
                    <label className="text-customGray mr-2 w-[140px]">
                      {filterType === "createdBy" ? " Date To:" : " Date To:"}
                    </label>
                    <input
                      type="date"
                      name="dateTo"
                      value={
                        filterType === "1"
                          ? filters.created_date_to
                          : filters.updated_date_to
                      }
                      onChange={(e) => handleFilterDateto(e)}
                      className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400"
                    />
                  </div>
                </div>
              )}
            </div>

            {/* Buttons */}
            <div className="mt-6 flex justify-end space-x-3">
              <HoverButton
                onClick={toggleFilterPopup}
                title={"Cancel"}
              />
              <CustomButton
                title={"Apply"}
                onClick={() => {
      handleApplyFilter(); 
      toggleFilterPopup(); 
    }}
              
              />
            </div>
          </div>
        </div>
      )}
      <ConfirmationDialog
        open={showConfirmation}
        title="Confirm Deletion"
        message="Are you sure you want to delete the selected Bank Id?"
        onConfirm={confirmDeletion}
        onCancel={cancelDeletion}
      />
    </div>
    </div>
  )
}
