import React, { useState, useEffect } from 'react';
import Header from '../../../../components/Header';
import { useNavigate } from 'react-router-dom';
import { Checkbox, MenuItem, Select } from '@mui/material';
import Footer from '../../../../components/footer';
import axios from 'axios';
import ModuleService from '../../../../rest-services/ModuleServices';
import { useToast } from '../../../../components/toast/toast';

export default function ModuleAssignment() {
    const navigate = useNavigate();
    const [selectedValue, setSelectedValue] = useState('');
    const [groupedData, setGroupedData] = useState({});
    const [filteredData, setFilteredData] = useState({});
    const [userData, setUserData] = useState([]);
    const { toast } = useToast();
    const [checkedStates, setCheckedStates] = useState({});
    const [pendingCheckedStates, setPendingCheckedStates] = useState({}); 
    const [userTypes, setUserTypes] = useState({});
  const [alert, setAlert] = useState(null);

    const [assignee, setAssignee] = useState({ id: "", username: "" });

    useEffect(() => {
        fetchAssignee();
    },[]);

    const fetchAssignee = async () => {
        try {
          const userDetailString = sessionStorage.getItem("userDetail");
    
          if (userDetailString) {
            const userDetail = JSON.parse(userDetailString);
            const assigneeData = {
              id: userDetail.id || "",
              assigner: userDetail.username || "",
              clientId: userDetail.clientId || "",
              assignerId: userDetail.userId || "",
            };
            console.log("assigneeData is",assigneeData);
    
            setAssignee(assigneeData);
            
            // Log the id and assigner values to the console
            console.log("Assignee ID:", assigneeData.id);
            console.log("Assigner:", assigneeData.assigner);
            console.log("Assigner userId:", assigneeData.assignerId);
            console.log("Assigner clientId:", assigneeData.clientId);
            
          } else {
            setAlert({
              severity: "error",
              message: "No user detail found in session storage.",
            });
          }
        } catch (error) {
          setAlert({
            severity: "error",
            message: `Error fetching user details: ${error.message}`,
          });
        }
      };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const purchaseResponse = await axios.get(`${process.env.REACT_APP_BASE_URL}/purchase-details`);
                const grouped = {};
                purchaseResponse.data.forEach(item => {
                    const mainMenuId = item.mainMenuId;
                    const subMenuDetails = {
                        id: item.subMenu.id,
                        name: item.subMenu.name,
                        purchased: item.purchased,
                        assigned: item.assigned,
                        available: item.available,
                    };
                    if (!grouped[mainMenuId]) {
                        grouped[mainMenuId] = {
                            mainMenuName: item.mainMenu.name,
                            subMenus: [],
                        };
                    }
                    grouped[mainMenuId].subMenus.push(subMenuDetails);
                });
                setGroupedData(grouped);
    
                const response = await ModuleService.approvedUser();


                // console.log("assignees data",assignee);
                // const data = { clientId: assignee.clientId };
                // console.log("Request Data for users:", data);
                
                // const response = await ModuleService.sendData(`${process.env.REACT_APP_BASE_URL}/user/get-user`,
                //   data
                // );    

                setUserData(response.data.data);

                // Fetch user types
                const userTypeResponse = await ModuleService.userAllType();
                const userTypeMap = {};
                userTypeResponse.data.data.forEach(type => {
                    userTypeMap[type.id] = type.name;
                });
                setUserTypes(userTypeMap);
    
                const assignmentResponse = await axios.get(`${process.env.REACT_APP_BASE_URL}/get-module-assignment`);
                const initialCheckedStates = {};
                assignmentResponse.data.forEach(assignment => {
                    initialCheckedStates[`${assignment.userId}-${assignment.subMenuId}`] = {
                        isChecked: assignment.isChecked,
                        id: assignment.id,
                    };
                });
                setCheckedStates(initialCheckedStates);
                setPendingCheckedStates(initialCheckedStates); // Initialize pending state as well
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        fetchData();
    }, []);
    

    const handleMenuChange = (event) => {
        const selectedMenuId = event.target.value;
        setSelectedValue(selectedMenuId);
    
        if (selectedMenuId && groupedData[selectedMenuId]) {
            // Show only the selected menu's submenus
            setFilteredData({
                [selectedMenuId]: groupedData[selectedMenuId],
            });
        } else {
            // Clear filtered data to show no submenus
            setFilteredData({});
        }
    };    

    // const handleCheckboxChange = (userId, subMenuId, isChecked) => {
    //     const key = `${userId}-${subMenuId}`;
    //     setPendingCheckedStates(prevStates => ({
    //         ...prevStates,
    //         [key]: { ...prevStates[key], isChecked }
    //     }));
    // };

    const handleCheckboxChange = (userId, subMenuId, isChecked) => {
        // Simply update the pending state without validation for now
        setPendingCheckedStates(prevStates => ({
          ...prevStates,
          [`${userId}-${subMenuId}`]: { ...prevStates[`${userId}-${subMenuId}`], isChecked }
        }));
    };
      
    const onSubmit = async () => {
        // Check if at least one user has the checkbox checked for subMenuId: 30
        const isSubMenu30Valid = Object.entries(pendingCheckedStates).some(
            ([key, value]) => value.isChecked && key.endsWith('-30')
        );

        if (!isSubMenu30Valid) {            
            toast("error", "At least one user must have access to licenses");
            return; 
        }

        const updatePromises = Object.entries(pendingCheckedStates).map(async ([key, value]) => {
            const [userId, subMenuId] = key.split('-');
            const isChecked = value.isChecked;
            const assignment = checkedStates[key];
    
            if (assignment && assignment.id) {
                // Update existing assignment
                return axios.put(`${process.env.REACT_APP_BASE_URL}/update-module-assignment/${assignment.id}`, {
                    isChecked: isChecked
                });
            } else {
                // Create new assignment
                return axios.post(`${process.env.REACT_APP_BASE_URL}/post-module-assignment`, {
                    userId: userId,
                    subMenuId: subMenuId,
                    isChecked: isChecked
                });
            }
        });
    
        try {
            await Promise.all(updatePromises);
            toast("success", "Updated successfully");
            setCheckedStates(pendingCheckedStates); // Update checked states after successful submission
        } catch (error) {
            console.error("Error updating assignments:", error);
            toast("error", "Failed to update");
        }
    };
    
    const handleBreadcrumbClick = (crumb) => {
        if (crumb.title === 'Admin') {
            navigate('/14/admin');
        } else if (crumb.title === 'Licenses') {
            navigate('/14/admin');
        } else if (crumb.title === 'Module Assignments') {
            navigate('/14/admin/module-assignment/114');
        } else if (crumb.path) {
            navigate(crumb.path);
        }
    };

    return (
        <div>
            <Header 
                title={"Admin > Licenses > Module Assignments"}
                onBreadcrumbClick={handleBreadcrumbClick}
            />
            
            <div className="p-2 overflow-x-auto">
                <table className="min-w-[300px] border-collapse border border-customBlue">
                    <thead>
                        <tr className="bg-customBlue text-white text-center">
                            <th rowSpan="2" className="p-2 border-t border-white font-normal" style={{ width: '150px' }}>Module Name</th>
                            <th rowSpan="2" className="p-2 border-t border-white font-normal" style={{ width: '200px' }}>:</th>         
                            <th rowSpan="2" className="p-2 border-t border-white font-normal" style={{ width: '200px' }}>
                            <Select
    labelId="dropdown-label"
    value={selectedValue}
    onChange={handleMenuChange}
    displayEmpty
    className="min-w-[180px] h-[36px] bg-white border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-300 focus:border-blue-500 transition duration-200 ease-in-out"
>
    <MenuItem value="" className="font-normal">Select</MenuItem>
    {Object.entries(groupedData).map(([menuId, menu]) => (
        <MenuItem key={menuId} value={menuId}>
            {menu.mainMenuName}
        </MenuItem>
    ))}
</Select>

                </th>                            
                        </tr>
                       
                        <tr className="bg-customBlue text-white text-center" >
                            {Object.values(filteredData).flatMap(mainMenu => 
                                mainMenu.subMenus.map((subMenu, subIndex) => (
                                    <th key={subIndex} className="border border-gray-300 font-normal" style={{ width: '200px' }}>
                                        {subMenu.name}
                                    </th>
                                ))
                            )}
                        </tr>
                    </thead>

                    <tbody>
                        {/* Display Purchased, Assigned, Available rows */}
                        <tr className="bg-yellow-50 text-center">
                            <td colSpan="3" className="p-2 border border-gray-300">Purchased</td>
                            {Object.values(filteredData).flatMap(mainMenu => 
                                mainMenu.subMenus.map(subMenu => (
                                    <td key={subMenu.name} className="p-2 border border-gray-300">{subMenu.purchased}</td>
                                ))
                            )}
                        </tr>
                        <tr className="bg-yellow-50 text-center">
                            <td colSpan="3" className="p-2 border border-gray-300">Assigned</td>
                            {Object.values(filteredData).flatMap(mainMenu => 
                                mainMenu.subMenus.map(subMenu => (
                                    <td key={subMenu.name} className="p-2 border border-gray-300">{subMenu.assigned}</td>
                                ))
                            )}
                        </tr>
                        <tr className="bg-yellow-50 text-center">
                            <td colSpan="3" className="p-2 border border-gray-300">Available</td>
                            {Object.values(filteredData).flatMap(mainMenu => 
                                mainMenu.subMenus.map(subMenu => (
                                    <td key={subMenu.name} className="p-2 border border-gray-300">{subMenu.available}</td>
                                ))
                            )}
                        </tr>

                        {/* New Row for User ID and Username */}
                        <tr className="bg-white text-center">
                            <td className="p-2 border border-gray-300 text-customBlue font-semibold">User ID</td>
                            <td className="p-2 border border-gray-300 text-customBlue font-semibold">Username</td>
                            <td className="p-2 border border-gray-300 text-customBlue font-semibold">User Type</td>
                            {Object.values(filteredData).flatMap(mainMenu => 
                                mainMenu.subMenus.map(subMenu => (
                                    <td key={subMenu.name} className="p-2 border border-gray-300"></td>
                                ))
                            )}
                        </tr>

                        {userData.map(user => (
                            <tr key={user.id} className="bg-blue-50 text-center">
                                <td className="p-2 border border-gray-300">{user.userId}</td>
                                <td className="p-2 border border-gray-300">{user.username}</td>
                                <td className="border border-gray-300">{userTypes[user.userType] || "Unknown"}</td>
                                {Object.values(filteredData).flatMap(mainMenu => 
                                    mainMenu.subMenus.map(subMenu => (
                                        <td key={`${user.id}-${subMenu.id}`} className="border border-gray-300">
                                            <Checkbox 
                                                checked={pendingCheckedStates[`${user.id}-${subMenu.id}`]?.isChecked || false}
                                                onChange={(event) => handleCheckboxChange(user.id, subMenu.id, event.target.checked)}
                                                sx={{color: 'green', '&.Mui-checked': {color: '#29CB00;'},}}
                                            />
                                        </td>
                                    ))
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>        

            <Footer>
            <button
              onClick={onSubmit}
              className="bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ml-7"
            >
              Submit
            </button>
          </Footer>
        </div>
    );
}
