import React, { useEffect, useState } from "react";
import Tabs from "../../../../../components/Tabs";
import Footer from "../../../../../components/footer";
import ButtonGroup from "../../../../../components/FooterButton";
import Header from "../../../../../components/Header";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LegalEntityServices from "../../../../../rest-services/LegalEntityServices";
import { useToast } from "../../../../../components/toast/toast";
import { RxDropdownMenu } from "react-icons/rx";
import CurrencyServicess from "../../../../../rest-services/CurrencyServices";
import CountryServices from "../../../../../rest-services/CountryServices";
import StateProvinceServices from "../../../../../rest-services/StateProvinceServices";
import CompanyServices from "../../../../../rest-services/CompanyServices";
import { Checkbox } from "@mui/material";
import CustomButton from "../../../../../components/CustomButton";
import BuGroupServices from "../../../../../rest-services/BuGroupServices";
import { TbFlag3Filled } from "react-icons/tb";
import ReusableModal from "../../../../../components/PopupComponent";
import LabelWithInput from "../../../../../components/InputFiledComponent";
import LabelWithButton from "../../../../../components/InputButtonField";
import TableWrapper from "../../../../../components/ScrollBarComponent";

export default function CompanyAdd() {
  const [activeTab, setActiveTab] = useState("addcompany");
  const navigate = useNavigate();
  const { companyId } = useParams();
  const location = useLocation();
  const { mode } = location.state || {};
  const isViewMode = mode === "view";
  const [errors, setErrors] = useState({}); // Validation errors
  const [assignee, setAssignee] = useState({ id: "", assigner: "" }); // State to store assignee
  const { toast } = useToast();
  const [alert, setAlert] = useState(null);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [companyCode, setCompanyCode] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyShortName, setCompanyShortName] = useState("");
  const [legalEntities, setLegalEntities] = useState([]);
  const [buGroups, setBugroups] = useState([]);
  const [sourceSystemCurrencyCode, setSourceSystemCurrencyCode] = useState("");
  const [chartOfAccountingCode, setChartOfAccountingCode] = useState("");
  const [accountingSystem, setAccountingSystem] = useState("");
  const [accountingPeriodCode, setAccountingPeroidCode] = useState("");
  const [contactPersonName, setContactPersonName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [faxNo, setFaxNo] = useState("");
  const [status, setStatus] = useState("");
  const [lgeCode, setLgeCode] = useState("");
  const [lgeDescription, setLgeDescription] = useState("");
  const [buGroupCode, setBuGroupCode] = useState("");
  const [bugroupName, setBuGroupName] = useState("");
  const isEditMode = !!companyId;

  const [currencyCode, setCurrencyCode] = useState("");
  const [currencyName, setCurrencyName] = useState("");
  const [currencies, setCurrencies] = useState([]);
  const [countryCode, setCountryCode] = useState(""); // Country Code
  const [countryName, setCountryName] = useState("");
  const [district, setdistrict] = useState("");
  const [poBox, setpoBox] = useState("");
  const [state, setstate] = useState("");
  const [stateName, setStateName] = useState("");
  // const [countryCode, setcountryCode] = useState("");
  const [countryId, setCountryId] = useState("");
  const [areaBlock, setareaBlock] = useState("");
  const [roadNo, setroadNo] = useState("");
  const [roadName, setRoadName] = useState("");
  const [streetNo, setstreetNo] = useState("");
  const [buildingName, setbuildingName] = useState("");
  const [buildingNo, setbuildingNo] = useState("");
  const [doorNo, setdoorNo] = useState("");
  const [floorNo, setfloorNo] = useState("");
  const [addressLine1, setaddressLine1] = useState("");
  const [addressLine2, setaddressLine2] = useState("");
  const [addressLine3, setaddressLine3] = useState("");
  const [countries, setCountries] = useState([]);
  const [stateProvinces, setStateProvinces] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(countryCode || "");
  const [postalCode, setpostalCode] = useState("");
  const [searchQueryState, setSearchQueryState] = useState(""); // State for search query
  const [selectedState, setSelectedState] = useState(state || "");
  const [selectedBuGroup, setSelectedBugroup] = useState(state || "");

  const [streetName, setstreetName] = useState("");
  const [areaName, setareaName] = useState("");
  // const [cityName, setcityName] = useState("");
  const [landMark, setlandMark] = useState("");
  const [zipCode, setzipCode] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [isModalOpenForState, setIsModalOpenForState] = useState(false);
  const [isCountryModalOpen, setIsCountryModalOpen] = useState(false);
  const [isCurrencyModalOpen, setIsCurrencyModalOpen] = useState(false);
  const [isStateModalOpen, setIsStateModalOpen] = useState(false);
  const [filteredStateProvinces, setFilteredStateProvinces] = useState([]);
  const [isLgeModalOpen, setIsLgeModalOpen] = useState(false);
  const [isBuGroupModalOpen, setIsBuGroupModalOpen] = useState(false);
  const [selectedLge, setSelectedLge] = useState(false);
  const [isAddressChecked, setIsAddressChecked] = useState(false);
  const [isValidationTriggered, setIsValidationTriggered] = useState(false);
  const [isCopyMode, setIsCopyMode] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  useEffect(() => {
    fetchcountry();
    fetchcurrency();
    fetchStateProvinces();
   fetchLegalEntity();
   fetchBuGroups();
    fetchAssignee();
  }, []);

  useEffect(() => {
    if (location.state?.copiedData) {
      setIsCopyMode(true);
      const copiedData = location.state.copiedData;
      // Set address fields
      if (copiedData.addresses && copiedData.addresses.length > 0) {
        const address = copiedData.addresses[0]; // Use the first address
        setdoorNo(address.doorNo);
        setfloorNo(address.floorNo);
        setbuildingNo(address.buildingNo);
        setbuildingName(address.buildingName);
        setlandMark(address.landMark);
        setstreetNo(address.streetNo);
        setstreetName(address.streetName);
        setroadNo(address.roadName);
        setRoadName(address.roadName);
        setareaBlock(address.areaBlock);
        setareaName(address.areaName);
        setaddressLine1(address.addressLine1);
        setaddressLine2(address.addressLine2);
        setaddressLine3(address.addressLine3);
        setstate(address.state);
        setdistrict(address.district);
        setpostalCode(address.postalCode);
        setzipCode(address.zipCode);
        setpoBox(address.poBox);
        // Map country
        const selectedCountryData = countries.find(
          (country) => country.country === address.countryCode
        );
        if (selectedCountryData) {
          setSelectedCountry(selectedCountryData.country);
          setCountryCode(selectedCountryData.country);
          setCountryName(selectedCountryData.countryName);
        } else {
          console.warn('Country not found for code:', address.countryCode);
        }
  
        // Map state
        const selectedStateData = stateProvinces.find(
          (state) => state.state === address.state
        );
        if (selectedStateData) {
          setSelectedState(selectedStateData.state);
          setStateName(selectedStateData.State_Name);
          setstate(selectedStateData.state);
        } else {
          console.warn('State not found for code:', address.state);
        }
      } else {
        console.warn('No addresses found in copiedData.');
      }
      if (copiedData.account && copiedData.account.length > 0) {
        const address = copiedData.account[0]; // Use the first address
        setSourceSystemCurrencyCode(address.sourceSystemCurrencyCode);
        setChartOfAccountingCode(address.chartOfAccountingCode);
        setAccountingPeroidCode(address.accountingPeriodCode);
        setAccountingSystem(address.accountingSystem);

      }
      if (copiedData.contact && copiedData.contact.length > 0) {
        const address = copiedData.contact[0]; // Use the first address
        setContactPersonName(address.contactPersonName);
        setMobileNo(address.mobileNo);
        setEmail(address.email);
        setTelephone(address.telephone);
        setFaxNo(address.faxNo);


      }
      // Set other copied fields
      setCompanyCode(copiedData.companyCode || "");
      setCompanyName(copiedData.companyName || "");
      setCompanyShortName(copiedData.companyShortName || "")
      if (copiedData.legalEntityCode) {
        const selectedLegalEntity = legalEntities.find(
          (entity) => entity.LGE_Code === copiedData.legalEntityCode
        );
  
        if (selectedLegalEntity) {
          setLgeCode(selectedLegalEntity.LGE_Code); // Set the legal entity code
          setLgeDescription(selectedLegalEntity.LGE_Description); // Set the legal entity description
          setSelectedLge(selectedLegalEntity.LGE_Code); // Update UI-selected value
        } else {
          console.warn('Legal entity not found for code:', copiedData.legalEntityCode);
        }
      }
      if (copiedData.buGroupCode) {
        const selectedBugroup = buGroups.find(
          (bugroup) => bugroup.BU_Group === copiedData.buGroupCode
        );
  
        if (selectedBugroup) {
          setBuGroupCode(selectedBugroup.BU_Group); // Set the legal entity code
          setBuGroupName(selectedBugroup.BU_Group_Description); // Set the legal entity description
          setSelectedBugroup(selectedBugroup.BU_Group); // Update UI-selected value
        } else {
          console.warn('bugroupnot found for code:', copiedData.buGroupCode);
        }
      }
      if (copiedData.currency) {
        const selectedCurrency = currencies.find(
          (cur) => cur.currencyCode === copiedData.currency
        );
  
        if (selectedCurrency) {
          setCurrencyCode(selectedCurrency.currencyCode); // Set the legal entity code
          setCurrencyName(selectedCurrency.Currency_Name); // Set the legal entity description
          setSelectedCurrency(selectedCurrency.currencyCode); // Update UI-selected value
        } else {
          console.warn('bugroupnot found for code:', copiedData.currencyCode);
        }
      }
    }
  else if (companyId) {
      getDataForEdit();
    }
    fetchAssignee();
  }, [companyId, currencies, countries, stateProvinces,legalEntities,buGroups, location.state]);
  // useEffect(() => {
  //   if (companyId) {
  //     getDataForEdit();
  //   }
  // }, [companyId, currencies, countries, stateProvinces,legalEntities,buGroups]);

  // const handleCheckboxChange = (event) => {
  //   const selectedName = event.target.value; // Change from value to country name
  //   setSelectedCountry(selectedName);

  //   // Find the selected country data by country name instead of code
  //   const selectedCountryData = countries.find(country => country.countryName === selectedName);
  //   if (selectedCountryData) {
  //     setCountryCode(selectedCountryData.country); // Set the country code based on the selected country name
  //     setCountryName(selectedCountryData.countryName);
  //   }
  // };
 
  
  const getDataForEdit = async () => {
    try {
      const response = await CompanyServices.getCompanyById(companyId);
      console.log("Response data:", response);
      const address = response.addresses[0];
      const account = response.account[0];
      const contact =response.contact[0];

      // Existing data handling
      setCompanyCode(response.companyCode);
      setCompanyName(response.companyName);
      setCompanyShortName(response.companyShortName);
setBuGroupCode(response.buGroupCode);
      setLgeCode(response.legalEntityCode); // Adjust according to API response structure
      setCountryCode(address.countryCode); // Set country code
      setStatus(response.status);
      setCurrencyCode(response.currency); // Set currency code
      setdoorNo(address.doorNo);
      setfloorNo(address.floorNo);
      setbuildingNo(address.buildingNo);
      setbuildingName(address.buildingName);
      setlandMark(address.landMark);
      setstreetNo(address.streetNo);
      setstreetName(address.streetName);
      setroadNo(address.roadName);
      setRoadName(address.roadName);
      setareaBlock(address.areaBlock);
      setareaName(address.areaName);
      setaddressLine1(address.addressLine1);
      setaddressLine2(address.addressLine2);
      setaddressLine3(address.addressLine3);
      setstate(address.state);
      setdistrict(address.district);
      setpostalCode(address.postalCode);
      setzipCode(address.zipCode);
      setpoBox(address.poBox);
      setSourceSystemCurrencyCode(account.sourceSystemCurrencyCode);
      setChartOfAccountingCode(account.chartOfAccountingCode);
      setAccountingSystem(account.accountingSystem);
      setAccountingPeroidCode(account.accountingPeriodCode);
      setContactPersonName(contact.contactPersonName);
      setMobileNo(contact.mobileNo);
      setEmail(contact.email);
      setTelephone(contact.telephone);
      setFaxNo(contact.faxNo);

      // Country lookup based on country code from response
      const country = countries.find(
        (c) => c.country === address.countryCode
      );
      console.log("country code from response:", address.countryCode);
      console.log("Available countries:", countries);
      if (countries) {
        setCountryName(country.countryName); // Set currency name for display
        setSelectedCountry(country.countryName); // Set the selected currency based on currency name
        // Set the currency code// Set the selected country based on countryName
      } else {
        console.error("Country not found for the provided country code.");
      }

      // for state
      const fetchedState = stateProvinces.find(
        (c) => c.state === address.state
      );
      console.log("State code from response:", address.state);
      console.log("Available states:", stateProvinces);
      console.log("the fetched console", fetchedState.State_Name);
      if (fetchedState) {
        setStateName(fetchedState.State_Name); // Set state name for display
      } else {
        console.error(
          "State not found for the provided state code:",
          response.state
        );
      }

      // Currency lookup based on currency code from response
      const currency = currencies.find(
        (c) => c.currencyCode === response.currency
      );
      console.log("Currency code from response:", response.Currency);
      console.log("Available currencies:", currencies);
      if (currencies) {
        setCurrencyName(currency.Currency_Name); // Set currency name for display
        setSelectedCurrency(currency.currencyCode); // Set the selected currency based on currency name
        setCurrencyCode(currency.currencyCode); // Set the currency code// Set the selected country based on countryName
      } else {
        console.error("Country not found for the provided country code.");
      }
      if (response.legalEntityCode) {
        const selectedLegalEntity = legalEntities.find(
          (entity) => entity.LGE_Code === response.legalEntityCode
        );
  
        if (selectedLegalEntity) {
          setLgeCode(selectedLegalEntity.LGE_Code); // Set the legal entity code
          setLgeDescription(selectedLegalEntity.LGE_Code); // Set the legal entity description
          setSelectedLge(selectedLegalEntity.LGE_Description); // Update UI-selected value
        } else {
          console.warn('Legal entity not found for code:', response.legalEntityCode);
        }
      }
      const bu = buGroups.find(
        (c) => c.BU_Group === response.buGroupCode
      );
      console.log("bu code from response:", response.buGroupCode);
      console.log("Available bu:", buGroups);
      if (bu) {
        setBuGroupName(bu.BU_Group_Description); // Set currency name for display
        setSelectedBugroup(bu.BU_Group); // Set the selected currency based on currency name
        setBuGroupCode(bu.BU_Group); // Set the currency code// Set the selected country based on countryName
      } else {
        console.error("bu not found for the provided bu code.");
      }
    }
     catch (error) {
      console.error("Error fetching legal entity data:", error);
      setAlert({
        severity: "error",
        message: "Error fetching legal entity data.",
      });
    }
    
  };

  const fetchAssignee = async () => {
    try {
      const userDetailString = sessionStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.userId || "",
        };
        setAssignee(assigneeData);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  const fetchcountry = async () => {
    try {
      const response = await CurrencyServicess.getAllCalendar(
        `${process.env.REACT_APP_BASE_URL}/get-country`
      );
      console.log("Fetched countries:", response); // Add this log
      setCountries(response.data.data); // Ensure this is correct
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  const fetchStateProvinces = async () => {
    try {
      const response = await StateProvinceServices.getAllStateprovince(
        `${process.env.REACT_APP_BASE_URL}/get-stateprovince`
      );
      console.log("Fetched StateProvinces:", response); // Add this log
      setStateProvinces(response.data.data); // Ensure this is correct
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  const fetchcurrency = async () => {
    try {
      const response = await CurrencyServicess.getAllCalendar(
        `${process.env.REACT_APP_BASE_URL}/get-currency`
      );
      console.log("Fetched Currencies:", response); // Add this log
      setCurrencies(response.data.data); // Ensure this is correct
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  const fetchLegalEntity = async () => {
    try {
      const response = await LegalEntityServices.getAllLegalEntity(
        `${process.env.REACT_APP_BASE_URL}/get-all-legal-entity`
      );
      console.log("Fetched Legal Entity:", response); // Add this log
      setLegalEntities(response.data); 
      console.log("helooooo",legalEntities)// Ensure this is correct
    } catch (error) {
      console.error("Error fetching LGE:", error);
    }
  };
  const fetchBuGroups = async () => {
    try {
      const response = await BuGroupServices.getAllBuGroup(
        `${process.env.REACT_APP_BASE_URL}/get-bugroup`
      );
      console.log("Fetched BuGROUPS:", response); // Add this log
      setBugroups(response.data.data); 
      console.log("helooooo",buGroups);// Ensure this is correct
    } catch (error) {
      console.error("Error fetching BuGroups:", error);
    }
  };

  const handleSubmit = async (e , saveAndContinue = false) => {
    e.preventDefault();
    if (!validateForm()) return;
    const duplicateCheck = await checkDuplicateCompanyCode(companyCode);
    if (duplicateCheck) {
      // If a duplicate is found, exit early
      toast("error", "Company Code already exists.");
      return;
    }
    const data = {
      id: isCopyMode? null : companyId, // Use 'id' for updates
      legalEntityCode: lgeCode,
      buGroupCode,
      companyCode,
      companyName,
      companyShortName,
      currency: currencyCode,
      created_by: assignee.assigner,
      changed_by: assignee.assigner,
      doorNo,
      floorNo,
      buildingNo,
      buildingName,
      landMark,
      streetNo,
      streetName,
      roadNo,
      roadName,
      areaBlock,
      areaName,
      addressLine1,
      addressLine2,
      addressLine3,
      countryCode,
      state,
      district,
      postalCode,
      zipCode,
      poBox,
      sourceSystemCurrencyCode,
      chartOfAccountingCode,
      accountingSystem,
      accountingPeriodCode,
      contactPersonName,
      mobileNo,
      email,
      telephone,
      faxNo,
    };
console.log("helooooo",data);
    try {
      let response;
      if (isCopyMode || !companyId) {
        // Updating the existing currency
        response = await CompanyServices.addCompany(
          `/create-company`, // Ensure this endpoint is for updates
          data
        );
      } else {
        // Creating a new currency
        response = await CompanyServices.addCompany(
          `/update-company`,
          data
        );
      }

      if (response && !response.error) {
        toast(
          "success",
          companyId && !isCopyMode
            ? "Company Updated successfully"
            : "Company Added successfully"
        );
        // Clear the form and navigate
        setCompanyCode("");
        setCompanyName("");
        setCompanyShortName("");
        setLgeCode("");
        setLgeDescription("");
        setSelectedLge("");
        setBuGroupCode("");
        setBuGroupName("");
        setSelectedBugroup("");
        setCurrencyCode("");
        setCurrencyName("");
        setSelectedCurrency("");
        setSelectedCountry("");
        setSelectedState("");
        setSourceSystemCurrencyCode("");
        setChartOfAccountingCode("");
        setAccountingSystem("");
        setAccountingPeroidCode("");
        setContactPersonName("");
        setMobileNo("");
        setEmail("");
        setTelephone("");
        setFaxNo("");
        setdoorNo("");
        setfloorNo("");
        setbuildingNo("");
        setbuildingName("");
        setlandMark("");
        setstreetNo("");
        setstreetName("");
        setroadNo("");
        setRoadName("");
        setareaBlock("");
        setareaName("");
        setaddressLine1("");
        setaddressLine2("");
        setaddressLine3("");
        setstate("");
        setdistrict("");
        setpostalCode("");
        setpoBox("");
        setzipCode("");
        setStateName("");
        setCountryCode("");
        setCountryName("");
if(saveAndContinue){
  navigate("/company");
}
         // Redirect after saving
      } else {
        // toast("error", "Error adding/updating Company");
      }
    } catch (error) {
      console.error("Request error:", error);
      setAlert({
        severity: "error",
        message: `Error adding/updating Company: ${error.message}`,
      });
    }
  };
 
  const checkDuplicateCompanyCode = async (code) => {
    try {
      const response = await CompanyServices.checkDuplicateCompanycode(
        `/getduplicatecompany`,
        { companyCode: code }  // Sending roleName to backend
      );
      
      console.log("Response from server:", response);
  
      if (response?.isDuplicate) {
        // Check if it's a duplicate
        setErrors((prev) => ({
          ...prev,
          companyCode: "Company code already exists.",
        }));
  
   toast("error", "Company already exists.");
       
      }
      console.log("Response from external server:", response);
  
    } catch (error) {
      console.error("Error checking duplicate Lgecode:", error);
      // Handle potential errors when making the API call
      setErrors(prev => ({ ...prev, buGroup: "Error checking role name" }));
    }
  };
  const handleCompanyCodeChange = (e) => {
    const code = e.target.value;
    setUnsavedChanges(true);
    setCompanyCode(code);
  
    // Clear previous errors when typing
    setErrors(prev => ({ ...prev, companyCode: "" }));
  
    
    if (code.trim() !== "") {
      checkDuplicateCompanyCode(code);
    }
  };
  // Separate handler for country selection
  const handleCountryCheckBoxChange = (event) => {
    console.log(`this is the Legal Entity ${countries}`);

    const selectedName = event.target.value; // Get the selected country name
    setSelectedCountry(selectedName);
    setUnsavedChanges(true);
    const selectedCountryData = countries.find(
      (country) => country.country === selectedName
    );
    if (selectedCountryData) {
      setCountryCode(selectedCountryData.country); // Set the country code
      setCountryName(selectedCountryData.countryName); // Set the country name

      // Filter states by the selected country code
      const filteredStates = stateProvinces.filter(
        (state) => state.Country_Code === selectedCountryData.country
      );
      setFilteredStateProvinces(filteredStates); // Assuming you have state to hold the filtered states
    }
  };

  
  // Separate handler for Legal Entity selection
  const handleCurrencyCheckBoxChange = (event) => {
    currencies.forEach((currency) => {
      console.log(currency);
    });
    const selectedName = event.target.value; // Get the selected currency name
    setSelectedCurrency(selectedName);
    setUnsavedChanges(true);
    const selectedCurrencyData = currencies.find(
      (currency) => currency.currencyCode === selectedName
    );

    if (selectedCurrencyData) {
      setSelectedCurrency(selectedCurrencyData.currencyCode); // Set the currency code

      setCurrencyCode(selectedCurrencyData.currencyCode); // Set the currency code
      setCurrencyName(selectedCurrencyData.Currency_Name);
      // Set the currency name
    }
  };
  const handleLgeCheckBoxChange = (event) => {
 
    const selectedName = event.target.value; // Get the selected currency name
    setSelectedLge(selectedName);
    setUnsavedChanges(true);
    const selectedlgeData = legalEntities.find(
      (lge) => lge.LGE_Code === selectedName
    );

    if (selectedlgeData) {
      setLgeCode(selectedlgeData.LGE_Code); // Set the currency code
      setSelectedLge(selectedlgeData.LGE_Code);
      setLgeDescription(selectedlgeData.LGE_Description);
      // Set the currency name
    }
  };
  const handleBuGroupCheckBoxChange = (event) => {
    buGroups.forEach((buGroup) => {
      console.log(buGroup);
    });
    
    const selectedName = event.target.value; // Get the selected currency name
    setSelectedBugroup(selectedName);
    setUnsavedChanges(true);
    const selectedBugroupData = buGroups.find(
      (buGroup) => buGroup.BU_Group === selectedName
    );

    if (selectedBugroupData) {
      setSelectedBugroup(selectedBugroupData.BU_Group); // Set the currency code

      setBuGroupCode(selectedBugroupData.BU_Group); // Set the currency code
      setBuGroupName(selectedBugroupData.BU_Group_Description);
      // Set the currency name
    }
  };
  const handleCheckboxChangeForState = (event) => {
    const selectedCode = event.target.value;
    console.log("selected State code", selectedCode);
    setSelectedState(selectedCode);
    setstate(selectedCode);
    setUnsavedChanges(true);

    const selectedCountryData = stateProvinces.find(
      (stateP) => stateP.state === selectedCode
    );
    console.log("selected state country data", selectedCountryData);
    if (selectedCountryData) {
      console.log(
        "check selected country data",
        selectedCountryData.State_Name
      );
      setStateName(selectedCountryData.State_Name);
    }
  };
  const handleCheckBoxForAddress = (event) => { 
    if (event.target.checked) {
      setUnsavedChanges(true);
      console.log("Selected lgeCode:", lgeCode); // Check if lgeCode is being set properly
  
      // Find the legal entity by its LGE_Code
      const targetLge = legalEntities.find((lge) => lge.LGE_Code === lgeCode);
  
      console.log("Matching legal entity:", targetLge);
  
      if (targetLge) {
        // Ensure you are accessing the addresses array
        if (targetLge.addresses && targetLge.addresses.length > 0) {
          
          const address = targetLge.addresses[0]; // Access the first address
  
          console.log("Address found:", address); // Log the address object
  
          // Set the address fields
          setdoorNo(address.doorNo || "");
          setfloorNo(address.floorNo || "");
          setbuildingNo(address.buildingNo || "");
          setbuildingName(address.buildingName || "");
          setlandMark(address.landMark || "");
          setstreetNo(address.streetNo || "");
          setstreetName(address.streetName || "");
          setroadNo(address.roadNo || "");
          setRoadName(address.roadName || "");
          setareaBlock(address.areaBlock || "");
          setareaName(address.areaName || "");
          setaddressLine1(address.addressLine1 || "");
          setaddressLine2(address.addressLine2 || "");
          setaddressLine3(address.addressLine3 || "");
          setCountryCode(address.countryCode || "");
          setstate(address.state || "");
          setdistrict(address.district || "");
          setpostalCode(address.postalCode || "");
          setzipCode(address.zipCode || "");
          setpoBox(address.poBox || "");
  
          // Fetch and set country details
          const selectedCountryData = countries.find(
            (country) => country.country === address.countryCode
          );
  
          if (selectedCountryData) {
            setCountryCode(selectedCountryData.country);
            setCountryName(selectedCountryData.countryName);
  
            // Filter states by the selected country code
            const filteredStates = stateProvinces.filter(
              (state) => state.Country_Code === selectedCountryData.country
            );
            setFilteredStateProvinces(filteredStates);
          }
  
          // Fetch and set state details
          const selectedStateData = stateProvinces.find(
            (state) => state.state === address.state
          );
  
          if (selectedStateData) {
            setStateName(selectedStateData.State_Name);
          }
  
        } else {
          console.error("No address available for the selected legal entity.");
        }
      } else {
        console.error("No matching legal entity found for the provided lgeCode");
      }
    } else {
      // Clear address fields if unchecked
      setdoorNo("");
      setfloorNo("");
      setbuildingNo("");
      setbuildingName("");
      setlandMark("");
      setstreetNo("");
      setstreetName("");
      setCountryCode("");
      setCountryName("");
      setStateName("");
      setRoadName("");
      setareaBlock("");
      setareaName("");
      setaddressLine1("");
      setaddressLine2("");
      setaddressLine3("");
      setCountryCode("");
      setstate("");
      setdistrict("");
      setpostalCode("");
      setzipCode("");
      setpoBox("");


    }
  };
  
  
  
  
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSearchChangeState = (e) => {
    setSearchQueryState(e.target.value);
  };

  const validateForm = () => {
    setIsValidationTriggered(true);

    let newErrors = {}; 
    const isAddressSectionFilled = addressLine1 || addressLine2 || addressLine3;
  const isBuildingSectionFilled = doorNo || floorNo || buildingNo || buildingName || landMark;
  const isStreetSectionFilled = streetNo || streetName || roadNo || roadName;
  const isAreaSectionFilled = areaBlock || areaName;
  const isBuildingStreetAreaSectionFilled = isBuildingSectionFilled || isStreetSectionFilled || isAreaSectionFilled;
  if (!isAddressSectionFilled && !isBuildingStreetAreaSectionFilled) {
    newErrors.addressSection = "Either Address Section or Building, Street, and Area Section must be entered";
  }
    if (!companyCode) newErrors.companyCode = "lge Code Code is required.";
    if (!companyName) newErrors.companyName = "lge Code Code is required.";
    if (!companyShortName)
      newErrors.companyShortName = "lge Code Code is required.";
    if (!bugroupName) newErrors.bugroupName = "lge Code Code is required.";
    if (!lgeDescription)
      newErrors.lgeDescription = "lge Description is required.";
    if (!currencyName) newErrors.currencyName = " Currency Code is required.";
    if (filteredStateProvinces.length > 0 && !state) {
      newErrors.state = "state is required.";
    }
  
    if (!countryCode) newErrors.countryCode = "Country Code   is required.";

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      toast("error", "Missing Required Fields");
    }
    return Object.keys(newErrors).length === 0;
  };
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "Company & Bank Data") {
      navigate("/master-data");
    } else if (crumb.title === "Company") {
      navigate("/company");
    } else if (crumb.title === "Primary Data") {
      navigate("/master-data");
    } else if (crumb.path) {
      navigate(crumb.path);
    }
  };
  const toggleModalForState = () => {
    setIsModalOpenForState(!isModalOpenForState);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const tabs = [
    { value: "addcompany", label: "Basic Details" },
    { value: "companyaddress", label: "Company Address" },
    { value: "account", label: "Account" },
    { value: "contact", label: "Contact" },
  ];
  const errorss = {
    addcompany: !companyCode || !companyName || !companyShortName || !lgeDescription || !bugroupName || !currencyName  , // Replace with your validation logic
    companyaddress: !addressLine1,
   
  };
  const nextToAddress = () => {
    setActiveTab("companyaddress")
  
}
const nextToAccount = () => {
  setActiveTab("account")

}
const nextToContact= () => {
  setActiveTab("contact")

}
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{3,}$/;

const handleEmailChange = (e) => {
  setEmail(e.target.value);
  setUnsavedChanges(true);
  if (!emailRegex.test(e.target.value)) {
    setEmailError('Please enter a valid email address.');
  } else {
    setEmailError('');
  }
};
const getApprovedIcon = () => {
  let color = "black"; // Default color
  // Determine the color based on the approved status
  if (status === 3) {
    color = "#29CB00"; // Green
  } else if (status === 0) {
    color = "gray"; // Gray
  } else if (status === 1) {
    color = "yellow"; // Yellow
  } else if (status === 2) {
    color = "orange"; // Orange
  } else if (status === 4) {
    color = "red"; // Red
  }

  return <TbFlag3Filled style={{ color, marginLeft: '8px' }} />; // Return the icon with the determined color
};
  return (
    <div>
      <Header
        title={`Primary Data > Company & Bank Data > Company > ${ isCopyMode ? 'Add' : ( companyId ? (isViewMode ? "View" : "Edit") : "Add" )}`}
        onBreadcrumbClick={handleBreadcrumbClick}
        extraContent={companyId && !isCopyMode ? getApprovedIcon() : null}
        hasUnsavedChanges={unsavedChanges}
      />
      <Tabs tabs={tabs} activeTab={activeTab} onTabClick={handleTabClick} isValidationTriggered={isValidationTriggered} errors={errorss} />
      <div className="flex-grow">
        {activeTab === "addcompany" && (
          <div className="m-6 border border-gray-200 p-5  rounded-lg">

          <div className="flex flex-col space-y-8 col-span-1 p-6">
            <div className=" space-y-8">
              {/* First row: Single field (LGE) */}
              <div className="grid grid-cols-2 gap-8">
              <div className="flex items-center">
              <LabelWithInput
        label="Company Code "
        value={companyCode}
        onChange={handleCompanyCodeChange}
        maxLength={10}
        error={errors.companyCode}
        isRequired={true}
        isDisabled={isViewMode || isEditMode}
      />
              </div>
              {!isViewMode && (
            <div className="flex items-center">
            <input 
                type="checkbox"

    checked={isAddressChecked} // Control the checkbox with state
    onChange={(event) => {
      setIsAddressChecked(event.target.checked); // Update the checkbox state
      handleCheckBoxForAddress(event); // Call your existing function
    }} 
    className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded accent-customBlue"
  />

              <p className="p-4 text-sm font-small text-customGray">
                Copy the Legal Entity Address
              </p>
            </div>
            )}
</div>
              {/* Second row: Two fields (LGE Code and LGE Description) */}
              <div className="grid grid-cols-2 gap-8">
                <div className="flex items-center">
                <LabelWithInput
        label="Company Name"
        value={companyName}
        onChange={(e) => {
                        setCompanyName(e.target.value);
                        setUnsavedChanges(true);
                        if (errors.companyName) {
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            companyName: "",
                          }));
                        }
                      }}   
                     maxLength={100}
        error={errors.companyName}
        isRequired={true}
        isDisabled={isViewMode}
      />
                </div>

                <div className="flex items-center">
                <LabelWithInput
        label="Company Short Name"
        value={companyShortName}
        onChange={(e) => {
                        setCompanyShortName(e.target.value);
                        setUnsavedChanges(true);
                        if (errors.companyShortName) {
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            companyShortName: "",
                          }));
                        }
                      }}    
                           maxLength={40}
        error={errors.companyShortName}
        isRequired={true}
        isDisabled={isViewMode}
      />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <div className="flex items-center">
              <LabelWithButton
        label="Legal Entity Code"
        isRequired={true}
        isDisabled={isViewMode}
        onClick={ ()=> {setIsLgeModalOpen(true);}   
        }
        buttonText={selectedLge || 'Select LGE Code'}
        error={errors.lgeDescription}
      />

                <ReusableModal
  title="Select Lge Code"
  isOpen={isLgeModalOpen}
  onClose={() => {
    setIsLgeModalOpen(false);
    setSearchQuery('');
  }}
  onSave={() => {
    setIsLgeModalOpen(false);
    setSearchQuery('');
  }}
  searchPlaceholder="Search lge..."
  searchQuery={searchQuery}
  onSearchChange={handleSearchChange}
  isViewMode={isViewMode}
>
 {legalEntities && legalEntities.length > 0 ? (
                            legalEntities
                              .filter(
                                (lge) =>
                                  (lge.LGE_Code &&
                                    lge.LGE_Code.trim() &&
                                    lge.LGE_Code
                                      .toLowerCase()
                                      .includes(searchQuery.toLowerCase()))
                                 
                              )
                              .map((lge, index) => (
                                <div
                                  key={lge.id}
                                  className={`flex items-center ${
                                    index % 2 === 0 ? "bg-sky-blue-200" : ""
                                  }`}
                                >
                                  <input
                                    type="radio"
                                    name="Lge Code"
                                    value={lge.LGE_Code}
                                    checked={
                                      selectedLge === lge.LGE_Code
                                    }
                                    onChange={handleLgeCheckBoxChange}
                                    className="mr-2 accent-customBlue"
                                    disabled={isViewMode}
                                  />
                                  <label>{lge.LGE_Code}</label>
                                </div>
                              ))
                          
                        ) : (
                          <p>Loading lge...</p>
                        )}
</ReusableModal>
              </div>

              <div className="flex items-center">
              <LabelWithInput
        label="Legal Entity Name"
        value={lgeDescription}
        error={errors.lgeDescription}
        isRequired={true}
        isreadonly={true}
        isDisabled={isViewMode}
      />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <div className="flex items-center">
              <LabelWithButton
        label="Bu Group Code"
        isRequired={true}
        isDisabled={isViewMode}
        onClick={ ()=> {setIsBuGroupModalOpen(true);}   
        }
        buttonText={selectedBuGroup || 'Select Bugroup Code'}
        error={errors.buGroupCode}
      />

                
          <ReusableModal
  title="Select Bugroup Code"
  isOpen={isBuGroupModalOpen}
  onClose={() => {
    setIsBuGroupModalOpen(false);
    setSearchQuery('');
  }}
  onSave={() => {
    setIsBuGroupModalOpen(false);
    setSearchQuery('');
  }}
  searchPlaceholder="Search bugroup..."
  searchQuery={searchQuery}
  onSearchChange={handleSearchChange}
  isViewMode={isViewMode}
>
  {buGroups.length > 0 ? (
                            buGroups
                              .filter(
                                (bugroup) =>
                                  (bugroup.BU_Group &&
                                    bugroup.BU_Group.trim() &&
                                    bugroup.BU_Group
                                      .toLowerCase()
                                      .includes(searchQuery.toLowerCase())) 
                                 
                              )
                              .map((bugroup, index) => (
                                <div
                                  key={bugroup.id}
                                 
                                >
                                  <input
                                    type="radio"
                                    name="Bugroup Code"
                                    value={bugroup.BU_Group}
                                    checked={
                                      selectedBuGroup === bugroup.BU_Group
                                    }
                                    onChange={handleBuGroupCheckBoxChange}
                                    className="mr-2 accent-customBlue"
                                    disabled={isViewMode}
                                  />
                                  <label>{bugroup.BU_Group}</label>
                                </div>
                              ))
                        ) : (
                          <p>Loading Bugroups...</p>
                        )}
</ReusableModal>
              </div>

              <div className="flex items-center">
              <LabelWithInput
        label="Bu group name"
        value={bugroupName}
        error={errors.bugroupName}
        isRequired={true}
        isreadonly={true}
        isDisabled={isViewMode}
      />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <div className="flex items-center">
              <LabelWithButton
        label="Currency Code"
        isRequired={true}
        isDisabled={isViewMode}
        onClick={ ()=> {setIsCurrencyModalOpen(true);}   
        }
        buttonText={selectedCurrency || 'Select Currency Code'}
        error={errors.currencyCode}
      />

                <ReusableModal
  title="Select Currency Code"
  isOpen={isCurrencyModalOpen}
  onClose={() => {
    setIsCurrencyModalOpen(false);
    setSearchQuery('');
  }}
  onSave={() => {
    setIsCurrencyModalOpen(false);
    setSearchQuery('');
  }}
  searchPlaceholder="Search currency..."
  searchQuery={searchQuery}
  onSearchChange={handleSearchChange}
  isViewMode={isViewMode}
>
           {currencies.length > 0 ? (
                            currencies
                              .filter(
                                (currency) =>
                                  currency.currencyCode &&
                                  currency.currencyCode.toLowerCase().includes(
                                    searchQuery.toLowerCase()
                                  )
                              )
                              .map((currency, index) => (
                                <div
                                  key={currency.id}
                                  className={`flex items-center ${
                                    index % 2 === 0 ? "bg-sky-blue-200" : ""
                                  }`}
                                >
                                  <input
                                    type="radio"
                                    name="Currency"
                                    value={currency.currencyCode}
                                    checked={
                                      selectedCurrency ===
                                      currency.currencyCode
                                    }
                                    onChange={handleCurrencyCheckBoxChange}
                                    className="mr-2 accent-customBlue"
                                    disabled={isViewMode}
                                  />
                                  <label>{currency.currencyCode}</label>
                                </div>
                              ))
                          
                        ) : (
                          <p>Loading Currencies...</p>
                        )}
</ReusableModal>
              </div>

              <div className="flex items-center">
              <LabelWithInput
        label="Currency Name"
        value={currencyName}
        error={errors.currencyName}
        isRequired={true}
        isreadonly={true}
        isDisabled={isViewMode}
      />
              </div>
              {/* {(!isViewMode && (
  <button
          onClick={handleSubmit}
          className="mt-4 bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16"
        >
          Save
        </button>
))} */}
            </div>
         
            {/* <CustomButton onClick={nextToAddress} title={"Next"}/> */}
          </div>
          </div>
        )}

        {activeTab === "companyaddress" && (
          <div className="m-6 border border-gray-200 p-5  rounded-lg">
<TableWrapper
 maxHeight="460px">
          <div className="p-1">
          {/* Building Section */}
          <div className="mb-6 border border-gray-300 p-5 rounded-lg">
            <h3 className="text-md font-medium text-customGray mb-4">Building</h3>
           
            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center">
                <label className="w-48 font-small text-customGray text-sm">Door No </label>
                <span className="ml-4">:</span>
                <input type="text"
                 value={doorNo}
                 maxLength={30}

                 onChange={(e) => {setdoorNo(e.target.value)
                  setUnsavedChanges(true);}}
                 disabled={isViewMode}
                className="border border-gray-300 p-1.5 rounded w-80 h-8  focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray font-small text-customGray text-sm" />
              </div>
              <div className="flex items-center">
                <label className="w-48 font-small text-customGray text-sm">Floor No </label>
                <span className="ml-4">:</span>
                <input type="text"
                value={floorNo}
                maxLength={30}

                disabled={isViewMode}
                onChange={(e) => {setfloorNo(e.target.value)
                  setUnsavedChanges(true);}}
                placeholder="4" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm font-small text-customGray text-sm " />
              </div>
            </div>
 
           
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 font-small text-customGray text-sm">Building No </label>
                <span className="ml-4">:</span>
                <input type="text"
                value={buildingNo}
                maxLength={30}

                disabled={isViewMode}
                onChange={(e) => {setbuildingNo(e.target.value)
                  setUnsavedChanges(true);}}
                 placeholder="2A" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm font-small text-customGray text-sm" />
              </div>
              <div className="flex items-center">
                <label className="w-48 font-small text-customGray text-sm">Building Name </label>
                <span className="ml-4">:</span>
                <input type="text"
                value={buildingName}
                maxLength={30}

                disabled={isViewMode}
                onChange={(e) => {setbuildingName(e.target.value)
                  setUnsavedChanges(true);}}
                 placeholder="B name" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm font-small text-customGray text-sm" />
              </div>
            </div>

            <div className="flex items-center mt-4">
              <label className="w-48 font-small text-customGray text-sm">Landmark</label>
              <span className="ml-4">:</span>
              <input type="text" 
               value={landMark}
               maxLength={30}

               onChange={(e) => {setlandMark(e.target.value)
                setUnsavedChanges(true);}}
               disabled={isViewMode}
              placeholder="" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm" />
            </div>
          </div>
 
          {/* Street Section */}
          <div className="mb-6 border border-gray-300 p-5 rounded-lg">
            <h3 className="text-md font-medium text-customGray  mb-4">Street</h3>
           
            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center">
                <label className="w-48 font-small text-customGray text-sm">Street No </label>
                <span className="ml-4">:</span>
                <input
                  value={streetNo}
                  maxLength={30}

                  onChange={(e) => {setstreetNo(e.target.value)
                    setUnsavedChanges(true);}}  
                  disabled={isViewMode}              

                type="text" placeholder="2/626" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
              </div>
              <div className="flex items-center">
                <label className="w-48 font-small text-customGray text-sm">Street Name</label>
                <span className="ml-4">:</span>
                <input type="text"  value={streetName}             maxLength={30}
disabled={isViewMode} onChange={(e) => {setstreetName(e.target.value)
  setUnsavedChanges(true);}}
              className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
                {/* {errors.streetName && (
                    <p className="text-red-500 text-sm ml-4">{errors.streetName}</p>
                  )} */}
              </div>
            </div>
 
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 font-small text-customGray text-sm">Road No </label>
                <span className="ml-4">:</span>
                <input type="text"
                 value={roadNo}
                 maxLength={30}

                 onChange={(e) => {setroadNo(e.target.value)
                  setUnsavedChanges(true);}}
                 disabled={isViewMode}
                placeholder="2A" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
              </div>
              <div className="flex items-center">
                <label className="w-48 font-small text-customGray text-sm">Road Name </label>
                <span className="ml-4">:</span>
                <input
                  type="text"
                  value={roadName}
                  maxLength={30}

                  onChange={(e) => {setRoadName(e.target.value)
                    setUnsavedChanges(true);}}
                  placeholder="B name"
                  disabled={isViewMode}
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray"
                />
              </div>
            </div>
          </div>
 
            {/* Area Section */}
          <div className="mb-6 border border-gray-300 p-5 rounded-lg">
            <h3 className="text-md font-medium text-customGray  mb-4">Area</h3>
           
            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center">
                <label className="w-48 font-small text-customGray text-sm">Block </label>
                <span className="ml-4">:</span>
                <input type="text"
                 value={areaBlock}
                 maxLength={30}

                 onChange={(e) => {setareaBlock(e.target.value)
                  setUnsavedChanges(true);}}
                placeholder="2/626" disabled={isViewMode} className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
              </div>
              <div className="flex items-center">
                <label className="w-48 font-small text-customGray text-sm">Area Name </label>
                <span className="ml-4">:</span>
                <input type="text"
                 value={areaName}
                 maxLength={30}

                 onChange={(e) => {setareaName(e.target.value)
                  setUnsavedChanges(true);}}
                placeholder="4"   disabled={isViewMode} className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
              </div>
            </div>
          </div>
            {/* Display the error message for address section */}
            {errors.addressSection && (
                <p className="text-red-500 text-sm mb-4">{errors.addressSection}</p>
              )}
 
            {/* Address Section */}
            <div className="mb-6 border border-gray-300 p-5 rounded-lg">
            <h3 className="text-md font-medium text-customGray  mb-4">Address</h3>
            <div className="grid grid-cols-1 gap-4">
              <div className="flex items-center">
                <label className="w-56 font-small text-customGray text-sm">Address 1 </label>
                <span className="ml-4">:</span>
                <input type="text" value={addressLine1}  onChange={(e) => {setaddressLine1(e.target.value)
                setUnsavedChanges(true);}}
                placeholder="2/626"              maxLength={30}
                disabled={isViewMode}        className="border border-gray-300 p-1.5 rounded w-full h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray"
                />
 
                {/* {errors.addressLine1 && (
                    <p className="text-red-500 text-sm ml-4">
                      {errors.addressLine1}
                    </p>
                  )} */}
              </div>
              {/* <div className="flex items-center">
                <label className="w-48 font-small text-customGray text-sm">Address 2 </label>
                <span className="ml-4">:</span>
                <input type="text"  value={addressLine2}  onChange={(e) => setaddressLine2(e.target.value)}
                placeholder="4" className="border border-gray-300 p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm" />
              </div> */}
            </div>
 
            <div className="grid grid-cols-1 gap-4 mt-4">
            <div className="flex items-center">
                <label className="w-56 font-small text-customGray text-sm">Address 2 </label>
                <span className="ml-4">:</span>
                <input type="text"  value={addressLine2}  onChange={(e) => {setaddressLine2(e.target.value)
                setUnsavedChanges(true);}}
                placeholder="4"             maxLength={30}
                disabled={isViewMode} className="border border-gray-300 p-1.5 rounded w-full h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
              </div>
             
            </div>
 
            <div className="grid grid-cols-1 gap-4 mt-4">
              <div className="flex items-center">
 
 <label className="w-56 font-small text-customGray text-sm">Address 3 </label>
                <span className="ml-4">:</span>
                <input type="text"  value={addressLine3}  onChange={(e) => {setaddressLine3(e.target.value)
                setUnsavedChanges(true);}}
                placeholder="2A"             maxLength={30}
                disabled={isViewMode} className="border border-gray-300 p-1.5 rounded w-full h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
              </div>
             
            </div>
          </div>
 
 
            {/* Country Section */}
          <div className="mb-6 border border-gray-300 p-5 rounded-lg">
            <h3 className="text-md font-medium text-customGray  mb-4">Country</h3>
           
      <div className="grid grid-cols-2 gap-4">
        <div className="flex items-center">
          <label className="w-48 font-small text-customGray text-sm">Country Code <span className="text-red-500 ml-1">*</span></label>
          <span className="ml-4">:</span>
          <button
              onClick={() => setIsCountryModalOpen(true)}
              disabled={isViewMode}
              className={`border border-gray-300 p-1 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 text-left text-customGray flex items-center justify-between ${errors.countryCode ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
          >
            <span className='font-small text-customGray text-sm'>{countryCode || 'Select Country Code'}</span>
            <RxDropdownMenu className="ml-2" />
          </button>
 
          <ReusableModal
  title="Select Country Code"
  isOpen={isCountryModalOpen}
  onClose={() => {
    setIsCountryModalOpen(false);
    setSearchQuery('');
  }}
  onSave={() => {
    setIsCountryModalOpen(false);
    setSearchQuery('');
  }}
  searchPlaceholder="Search country..."
  searchQuery={searchQuery}
  onSearchChange={handleSearchChange}
  isViewMode={isViewMode}
>
  {countries.length > 0 ? (
    countries
      .filter((country) =>
        country.country.toLowerCase().includes(searchQuery.toLowerCase())
    
      )
      .map((country) => (
        <div key={country.id} className="flex items-center">
          <input
            type="radio"
            name="country"
            value={country.country}
            checked={selectedCountry === country.country}
            onChange={handleCountryCheckBoxChange}
            className="mr-2 accent-customBlue"
            disabled={isViewMode}
          />
          <label>{country.country}</label>
        </div>
      ))
  ) : (
    <p>Loading countries...</p>
  )}
</ReusableModal>
        </div>
        <div className="flex items-center">
          <label className="w-48 font-small text-customGray text-sm">Country Name</label>
          <span className="ml-4">:</span>
          <input
            type="text"
            value={countryName}
            // readOnly
            disabled={isViewMode}    
                    className="border border-gray-300 p-1 rounded w-80 h-8 text-gray-400 focus:outline-none hover:border-blue-400 ml-4 font-small text-customGray text-sm"
          />
        </div>
      </div>
       
      <div className="grid grid-cols-2 gap-4 mt-4">
        <div className="flex items-center">
          <label className="w-48 font-small text-customGray text-sm">State / Province <span className="text-red-500 ml-1">*</span></label>
          <span className="ml-4">:</span>
          <button
              onClick={() => setIsStateModalOpen(true)}
              disabled={isViewMode}
              className={`border border-gray-300 p-1 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between text-customGray ${errors.state ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
          >
            <span className='font-small text-customGray text-sm'>{state || 'Select State / Province'}</span>
            <RxDropdownMenu className="ml-2" />
          </button>
 
          <ReusableModal
  title="Select State Code"
  isOpen={isStateModalOpen}
  onClose={() => {
    setIsStateModalOpen(false);
    setSearchQueryState('');
  }}
  onSave={() => {
    setIsStateModalOpen(false);
    setSearchQueryState('');
  }}
  searchPlaceholder="Search state..."
  searchQuery={searchQueryState}
  onSearchChange={handleSearchChangeState}
  isViewMode={isViewMode}
>

{filteredStateProvinces.length > 0 ? (
                   
                      filteredStateProvinces
                      .filter((stateP) =>
                        stateP.State_Name.toLowerCase().includes(searchQueryState.toLowerCase()) ||
                      stateP.state.toLowerCase().includes(searchQueryState.toLowerCase())
                      )
                      .map((stateP, index) => (
                        <div key={stateP.id} className={`flex items-center ${index % 2 === 0 ? 'bg-sky-blue-200' : ''}`}>
                          <input
                            type="radio"
                            name="country"
                            value={stateP.state}
                            checked={selectedState === stateP.state}
                            onChange={handleCheckboxChangeForState}
                            className="mr-2 accent-customBlue"
                            disabled={isViewMode}
                          />
                          <label>{stateP.state}</label>
                        </div>
                      ))
                   
                  ) : (
                    <p>Loading State...</p>
                  )}
</ReusableModal>
        </div>
         
        <div className="flex items-center">
          <label className="w-48 font-small text-customGray text-sm">State / Province Name</label>
          <span className="ml-4">:</span>
          <input
            type="text"
            value={stateName}
            disabled={isViewMode}
            // readOnly
           
            className="border border-gray-300 p-1 rounded w-80 h-8 text-gray-400 focus:outline-none hover:border-blue-400 ml-4 font-small text-customGray text-sm"
          />
                  {/* <select
                  disabled
                    value={state}
                    onChange={(e) => setstate(e.target.value)}
                    className="border border-gray-300 p-1 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4"
                  >
                    <option value="" disabled>Select State / Province</option>
                    {stateProvinces.map((statePName) => (
                      <option key={statePName.id} value={statePName.id}>
                        {statePName.State_Name}
                      </option>
                    ))}
                  </select> */}
          {/* {errors.countryId && <p className="text-red-500 text-sm ml-4">{errors.countryId}</p>} */}
        </div>
      </div>
 
 
 
{/* <div className="grid grid-cols-2 gap-4 mt-2">
            </div> */}
 
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 font-small text-customGray text-sm">District</label>
                <span className="ml-4">:</span>
                <input type="text"
                 value={district}
                 maxLength={30}

                 onChange={(e) => {setdistrict(e.target.value)
                  setUnsavedChanges(true);}}
                 disabled={isViewMode}
                 placeholder="2A"
                 className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
              </div>
              <div className="flex items-center">
                <label className="w-48 font-small text-customGray text-sm">Postal Code</label>
                <span className="ml-4">:</span>
                <input type="text"
                value={postalCode}
                maxLength={30}

                onChange={(e) => {setpostalCode(e.target.value)
                  setUnsavedChanges(true);}}
                disabled={isViewMode}
                 placeholder="B name" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
              </div>
            </div>
 
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 font-small text-customGray text-sm">Zip Code</label>
                <span className="ml-4">:</span>
                <input type="text"
                value={zipCode}
                maxLength={30}

                onChange={(e) => {setzipCode(e.target.value)
                  setUnsavedChanges(true);}}
                disabled={isViewMode}
                placeholder="2A" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
              </div>
             
              <div className="flex items-center">
                <label className="w-48 font-small text-customGray text-sm">PO Box</label>
                <span className="ml-4">:</span>
                <input type="text"
                value={poBox}
                maxLength={30}

                onChange={(e) => {setpoBox(e.target.value)
                  setUnsavedChanges(true);}}
                disabled={isViewMode}
 
                placeholder="B name" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray" />
              </div>
 
            </div>
 
          </div>
 
          {/* <CustomButton onClick={nextToIdentifiers} title={"Next"} className={"mb-10"}/> */}
 </div>
 </TableWrapper>
        </div>
        )}
        {activeTab === "account" && (
          <div className="m-6 border border-gray-200 p-5  rounded-lg">

          <div className="flex flex-col space-y-8 col-span-1 p-6">
            <div className="grid grid-cols-2 gap-8">
              <div className="flex items-center">
              <LabelWithInput
        label="Source System Currency Code"
        value={sourceSystemCurrencyCode}
        onChange={(e) => {
                      setSourceSystemCurrencyCode(e.target.value);
                      setUnsavedChanges(true);
                    }}
        isRequired={true}
        isDisabled={isViewMode}
      />
              </div>

              <div className="flex items-center">
              <LabelWithInput
        label="Chart Of Accounting Code"
        value={chartOfAccountingCode}
        onChange={(e) => {
                      setChartOfAccountingCode(e.target.value);
                      setUnsavedChanges(true);
                    }}
        isRequired={true}
        isDisabled={isViewMode}
      />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <div className="flex items-center">
              <LabelWithInput
        label="Accounting System"
        value={accountingSystem}
        onChange={(e) => {
                      setAccountingSystem(e.target.value);
                      setUnsavedChanges(true);
                    }}
        isRequired={true}
        isDisabled={isViewMode}
      />
              </div>

              <div className="flex items-center">
              <LabelWithInput
        label="Accounting Period code"
        value={accountingPeriodCode}
        onChange={(e) => {
                      setAccountingPeroidCode(e.target.value);
                      setUnsavedChanges(true);
                    }}
        isRequired={true}
        isDisabled={isViewMode}
      />
              </div>
            </div>
            {/* <CustomButton onClick={nextToContact} title={"Next"}/> */}
</div>
          </div>
        )}
        {activeTab === "contact" && (
          <div className="m-6 border border-gray-200 p-5  rounded-lg">

          <div className="flex flex-col space-y-8 col-span-1 p-6">
             <LabelWithInput
        label="Contact Person Number"
        value={contactPersonName}
        onChange={(e) => {
                      setContactPersonName(e.target.value);
                      setUnsavedChanges(true);
                    }}
        isRequired={true}
        isDisabled={isViewMode}
      />
                  <LabelWithInput
        label="Mobile No"
        value={mobileNo}
        onChange={(e) => {
                      setMobileNo(e.target.value);
                      setUnsavedChanges(true);
                    }}
        isRequired={true}
        isDisabled={isViewMode}
      />
                  <LabelWithInput
        label="email"
        value={email}
        onChange={(e) => {
                      setEmail(e.target.value);
                      setUnsavedChanges(true);
                    }}
        isRequired={true}
        isDisabled={isViewMode}
      />
                  <LabelWithInput
        label="Telephone"
        value={telephone}
        onChange={(e) => {
                      setTelephone(e.target.value);
                      setUnsavedChanges(true);
                    }}
        isRequired={true}
        isDisabled={isViewMode}
      />
                 <LabelWithInput
        label="fax No"
        value={faxNo}
        onChange={(e) => {
                      setFaxNo(e.target.value);
                      setUnsavedChanges(true);
                    }}
        isRequired={true}
        isDisabled={isViewMode}
      />
      </div>
          </div>
        )}
      </div>
      <Footer>
        {/* Footer */}
        {!isViewMode && (
          <ButtonGroup
            onSave={handleSubmit}
            onSaveContinue={(e) => handleSubmit(e, true)}      
                  hasId={!!companyId}
          />
        )}
      </Footer>
    </div>
  );
}
