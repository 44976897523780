import React from 'react'
import NoActionLayout from '../../../../components/NoActionLayout'

import CompanyWiseTable from '../Tables/CashInBank/CompanyWiseTablee'

import { buttonConfigs } from '../Props/buttonConfig';
import CashBalanceActions from '../Props/CashBalanceActions';

export default function CompanyWise() {
  const buttons=buttonConfigs.screen1;

  return (
    <div>
      <div>
      <NoActionLayout title="Cash Balance > Cash in Bank > Company Wise" />
</div>
<div>
<CashBalanceActions buttons={buttons}/>
</div>
<div>
  <CompanyWiseTable/>
</div>
    </div>
  )
}
