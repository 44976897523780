import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, parse } from "date-fns";
import CustomInput from "./CustomInput";

const DatePickerComponent = ({ selectedDate, onChange, placeholder, disabled, maxDate }) => {
  const parseDate = (dateStr) => parse(dateStr, "dd.MM.yyyy", new Date());

  const handleDateChange = (date) => {
    if (date) {
      onChange(format(date, "dd.MM.yyyy"));
    }
  };

  return (
    <DatePicker
      selected={selectedDate ? parseDate(selectedDate) : null}
      onChange={handleDateChange}
      dateFormat="dd.MM.yyyy"
      placeholderText={placeholder}
      disabled={disabled}
      maxDate={maxDate} // Pass maxDate to DatePicker
      showYearDropdown
      showMonthDropdown
      scrollableYearDropdown
      yearDropdownItemNumber={100}
      shouldCloseOnSelect
      customInput={<CustomInput />}
    />
  );
};

export default DatePickerComponent;
