import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; 
import Header from '../../../../../components/Header';
import { useToast } from '../../../../../components/toast/toast';

export default function ZBAtypesAdd() {
  const navigate = useNavigate();

  const [id, setId] = useState('');
  const [description, setDescription] = useState('');
  const [errors, setErrors] = useState({});
  const { toast } = useToast();
  const [assignee, setAssignee] = useState({ assigner: '' });

  useEffect(() => {
    const fetchAssignee = async () => {
      try {
        const userDetailString = sessionStorage.getItem("userDetail");
        if (userDetailString) {
          const userDetail = JSON.parse(userDetailString);
          const assigneeData = {
            id: userDetail.id || "",
            assigner: userDetail.username || "",
          };
          setAssignee(assigneeData);
        } else {
          setErrors(prevErrors => ({
            ...prevErrors,
            api: "No user detail found in session storage.",
          }));
        }
      } catch (error) {
        setErrors(prevErrors => ({
          ...prevErrors,
          api: `Error fetching user details: ${error.message}`,
        }));
      }
    };

    fetchAssignee();
  }, []);

  const handleNavigate = (path) => {
    navigate(path);
  };

  // const handleSaveContinueDetails = async () => {
  //   setErrors({});

  //   if (!id || !description) {
  //     const newErrors = {};
  //     if (!id) newErrors.id = 'ZBA ID is required.';
  //     if (!description) newErrors.description = 'Description is required.';
  //     setErrors(newErrors);
  //     return;
  //   }

  //   const payload = {
  //     ZBA_ID: id,
  //     ZBA_Description: description,
  //     created_by: assignee.assigner || "Unknown",
  //     changed_by: assignee.assigner || "Unknown"
  //   };

  //   try {
  //     const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/create-zba-types`, payload, {
  //       headers: {
  //         "Content-Type": "application/json"
  //       }
  //     });

  //     console.log(response.data); 
  //     toast("success", "Added successfully");

  //     setId('');
  //     setDescription('');
  //   } catch (error) {
  //     console.error("Error saving ZBA Type:", error);
  //     setErrors(prevErrors => ({
  //       ...prevErrors,
  //       api: 'Failed to create ZBA Type. Please try again later.'
  //     }));
  //   }
  // };

  // const handleSaveBasicDetails = async () => {
  //   setErrors({});

  //   if (!id || !description) {
  //     const newErrors = {};
  //     if (!id) newErrors.id = 'ZBA ID is required.';
  //     if (!description) newErrors.description = 'Description is required.';
  //     setErrors(newErrors);
  //     return;
  //   }

  //   const payload = {
  //     ZBA_ID: id,
  //     ZBA_Description: description,
  //     created_by: assignee.assigner || "Unknown",
  //     changed_by: assignee.assigner || "Unknown"
  //   };

  //   try {
  //     const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/create-zba-types`, payload, {
  //       headers: {
  //         "Content-Type": "application/json"
  //       }
  //     });

  //     console.log(response.data); 
  //     toast("success", "Added successfully");

  //     setId('');
  //     setDescription('');
  //     handleNavigate('/ZBA-types');
  //   } catch (error) {
  //     console.error("Error saving ZBA Type:", error);
  //     setErrors(prevErrors => ({
  //       ...prevErrors,
  //       api: 'Failed to create ZBA Type. Please try again later.'
  //     }));
  //   }
  // };

  const handleSaveDetails = async (navigateAfterSave = false) => {  
    setErrors({});

    if (!id || !description) {
      const newErrors = {};
      if (!id) newErrors.id = 'ZBA ID is required.';
      if (!description) newErrors.description = 'Description is required.';
      setErrors(newErrors);
      return;
    }

    const payload = {
      ZBA_ID: id,
      ZBA_Description: description,
      created_by: assignee.assigner || "Unknown",
      changed_by: assignee.assigner || "Unknown"
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/create-zba-types`, payload, {
        headers: {
          "Content-Type": "application/json"
        }
      });

      console.log(response.data); 
      toast("success", "Added successfully");

      setId('');
      setDescription('');
  
      if (navigateAfterSave) {
        navigate('/ZBA-types', { replace: true });
      }
    } catch (error) {
      console.error("Error saving ZBA Type:", error);
      setErrors(prevErrors => ({
        ...prevErrors,
        api: 'Failed to create ZBA Type. Please try again later.'
      }));
    }
  };

  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'BAM') {
      console.log("Navigating to /bam");
      navigate('/bam');
    } else if (crumb.title === 'Set Up') {
      console.log("Navigating to /bam");
      navigate('/bam');
    } 
    else if (crumb.title === 'ZBA Types') {
      console.log("Navigating to /ZBA-types");
      navigate('/ZBA-types');
    }
    else if (crumb.title === 'Add') {
      console.log("Navigating to /ZBA-types-add");
      navigate('/ZBA-types-add');
    }
    else if (crumb.path) {
        navigate(crumb.path); 
    }
  };

  return (
    <div className="flex flex-col h-screen">
      <div>        
        <Header title={"BAM > Set Up > ZBA Types > Add"}
        onBreadcrumbClick={handleBreadcrumbClick}
        />
      </div>

      <div className='flex-grow p-10'>
        <label className="flex items-center mb-4 ">          
          <span className="w-48 text-sm font-medium text-gray-500">ZBA ID <span className="text-red-500 ml-1">*</span></span>
          <span className="ml-3">:</span>
          <input
            type="text"
            value={id}
            onChange={(e) => setId(e.target.value)}
            required
            placeholder='ZBA ID'
            className="border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-5"
          />
        </label>

        <label className="flex items-center mb-4 py-3">
          <span className="w-48 text-sm font-medium text-gray-500">
            ZBA Description <span className="text-red-500 ml-1">*</span>
          </span>
          <span className="ml-3">:</span>
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
            placeholder='Description'
            className={`border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-5
            ${errors.description ? 'border-red-500 hover:border-red-500' : ''}`}
          />
          {errors.description && (
            <p className="text-red-500 text-sm ml-4">{errors.description}</p>
          )}
          {errors.api && (
            <p className="text-red-500 text-sm ml-4">{errors.api}</p>
          )}
        </label>
      </div>

      <footer className="flex justify-start p-4 border-t border-gray-300">
        <button
          onClick={() => handleSaveDetails(true)}
          className="bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16"
        >
          Save
        </button>
        <button
          onClick={() => handleSaveDetails(false)}
          className="ml-4 border border-blue-500 text-sm text-blue-500 py-1 px-2 rounded hover:bg-blue-100 transition duration-200 ease-in-out w-30"
          >
            Save & Continue
          </button>
      </footer>
    </div>
  );
}
