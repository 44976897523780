import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SimpleBackdrop from '../SimpleBackdrop';
const ToastContext = createContext();
export function useToast() {
    return useContext(ToastContext);
}
export function ToastProvider({ children }) {
 
    return (
      <ToastContext.Provider 
      value={
        { 
          toast: (type,message) => toast[type](message),
          toastPromise:(newToastPromise) => toast.promise(newToastPromise, {
            pending: {
              render(){
                return <SimpleBackdrop></SimpleBackdrop>
              },
              icon: false,
            },
            success: {
              render({data}){
                return `${data.message}`
              },
              // other options
              icon: "🟢",
              
            },
            error: {
              render({data}){
                // When the promise reject, data will contains the error
                return `${data}`
              }
            }
          })  // Add toast.promise to the context value
        }
        }>
        {children}
        <ToastContainer autoClose={8000} position="bottom-right" />
      </ToastContext.Provider>
    );
}

ToastProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };
