import React, { useEffect, useState } from 'react'



import NoActionLayout from '../../../../../../components/NoActionLayout';
import FlowCodeServices from '../../../../../../rest-services/FlowCodeServices';


export default function FlowCodeAdd() {
  
  const [ntmainHead,setNtMainHead] = useState('');
  const [ntSubheads,setNtSubheads] = useState('');
  const [description,setDescription] = useState('');
  const [errors, setErrors] = useState({});
  const validateForm = () => {
    const newErrors = {};
    if (!ntmainHead) newErrors.ntMainHead = 'NT Main Head is required.';
    

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    const data = {
      nt_main_head: ntmainHead,
      nt_subheads:ntSubheads,
      description:description
    };

    try{
      const response = await FlowCodeServices.addFlowCode(`${process.env.REACT_APP_BASE_URL}/create-flowcode`, data);
      console.log('flow code added sucessfully:', response);
      setNtMainHead('');
      setNtSubheads('');
      setDescription('');
      alert("added");
    } catch (error){
      console.error('Error adding Flow code :' , error) ;
    }
  }
  return (
    <div>
       <div>
        <NoActionLayout title="Cash Flow > Set Up > Flow Code Heads > Add" />
      </div>
      <div>
      <div className="flex flex-col space-y-12 col-span-1 p-10">
  <label className="flex items-center">
    <span className="w-48 font-small text-gray-500">NT Main Head<span className="text-red-500 ml-1">*</span></span>
    <span className="ml-4">:</span>
    <input
                type="text"
                value={ntmainHead}
                onChange={(e) => setNtMainHead(e.target.value)}
                className={`border ${errors.ntMainHead ? 'border-red-500' : 'border-gray-300'} p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
                required
              />
            </label>
            {errors.ntMainHead && <p className="text-red-500 text-sm mt-1">{errors.ntMainHead}</p>}
  <label className="flex items-center">
    <span className="w-48 font-small text-left text-gray-500">NT Sub Heads</span>
    <span className="ml-4">:</span>
    <input
      type="text"
      className="border border-gray-300 p-1.5 rounded w-80 h-8 focus:outline-none   hover:border-blue-400 ml-8"
      value={ntSubheads}
      onChange={(e)=>setNtSubheads(e.target.value)}
      required
    />
  </label>
  
  <label className="flex items-center">
    <span className="w-48 font-small text-gray-500">Description</span>
    <span className="ml-4">:</span>
    <input
      type="text"
      className="border border-gray-300 p-1.5 rounded w-80 h-8 focus:outline-none  hover:border-blue-400 ml-8"
      value={description}
      onChange={(e)=>setDescription(e.target.value)}
      required
    />
  </label>
  
  <button onClick ={handleSubmit} className=" mt-4 bg-blue-500 text-sm text-white py-1 px-2   rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ">
    Save  
  </button>
</div>




      </div>
    </div>
  )
}
