import RestService from "./RestServices";

const BankBranchServices = {
  getAllBankBranch: async (path) => {
    try {
      const response = await RestService.GetAllData(path);
      return response;
    } catch (error) {
      console.error("Error fetching BankBranch data:", error);
      return { error: true, message: "Error fetching BankBranch data" };
    }
  },
  getBankBranchById: (id) => {
    return RestService.GetByIdData(`/get-bankbranch`, id);
  },
  deleteBankBranch:(ids)=>{
return RestService.CreateData('/delete-bankbranch',{ids});
  },
  addBankBranch: async (path, data) => {
    try {
      console.log("Sending data:", data);
      const response = await RestService.CreateData(path, data);
      console.log("Response from Server:", response);
      return response;
    } catch (error) {
      console.error("Error adding BankBranch:", error);
      return { error: true, message: "Error adding BankBranch" };
    }
  },
  checkDuplicatebankBranch: async (path, roleData) => {
    try {
      const response = await RestService.CreateData(path, roleData);
      console.log("Response from server:", response);

      return response;
    } catch (error) {
      console.error("Error fetching Bank Branch:", error);
      return { error: true, message: "Error fetching Bank Branch " };
    }
  },
  getAllgetallbankbranchbypagewithPage: (pageSize, page, searchQuery = "") => {
    const searchParam = searchQuery ? `&searchQuery=${encodeURIComponent(searchQuery)}` : "";
    return RestService.GetAllData(
      `/getallbankbranchbypage?limit=${pageSize}&offset=${(page - 1) * pageSize}${searchParam}`
    );
  },
  getFilterBankbranch: async (path, data) => {
    try {
      console.log("Sending data:", data);
      const response = await RestService.CreateData(path, data);
      console.log("Response from Server:", response);
      return response;
    } catch (error) {
      console.error("Error getting filter Bank Branch:", error);
      return { error: true, message: "Error getting filter Bank Branch" };
    }
  },
  saveapproved: (path, data) => {
    try {
      console.log("Sending data:", data);
      const response = RestService.CreateData(path, data);
      console.log("Response from server:", response);
      return response;
    } catch (error) {
      console.error("Error updating users:", error);
      return { error: true, message: "Error updating users" };
    }
  },
  getAudittrail: (path, data) => {
    try {
      const response = RestService.CreateData(path, data);
      return response;
    } catch (error) {
      console.error("Error getting audittrail:", error);
      return { error: true, message: "Error getting audittrail" };
    }
  },
};


export default BankBranchServices;
