import React from 'react'

import { CashFlowbuttonsConfigs } from '../../../Components/CashFlowbuttonsConfig'
import NoActionLayout from '../../../../../../components/NoActionLayout';
import CashFlowActions from '../../../Components/CashFlowActions';

export default function NTCodesAdd() {
    const buttons=CashFlowbuttonsConfigs.screen1;

  return (
    <div>
       <div>
        <NoActionLayout title="Cash Flow > Set Up > NT Code > Add" />
      </div>
      <div>
      <CashFlowActions buttons={buttons}/>      </div>
     
      <div className="flex flex-col space-y-8 col-span-1 p-10">
      <label className="flex items-center">
  <span className="w-48 font-small text-gray-500">
    NT Code<span className="text-red-500 ml-1">*</span>
  </span>
  <span className="ml-4">:</span>
  <input
    type="text"
    className="border border-gray-300 p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8"
  />
  <input
    type="checkbox"
    className="form-checkbox ml-32  text-blue-500 border-blue-500 rounded focus:ring-0 checked:bg-blue-500 checked:border-blue-500"
  />
  <p className="ml-8 font-small text-gray-600">Allow to assign manually</p>
</label>
 
  
  <label className="flex items-center">
    <span className="w-48 font-small text-left text-gray-500">Nature of Transactions</span>
    <span className="ml-4">:</span>
    <input
      type="text"
      className="border border-gray-300 p-1.5 rounded w-80 h-8 focus:outline-none   hover:border-blue-400 ml-8"
      
    />
     <input  type="checkbox" className="form-checkbox  ml-32 text-blue-500 border-blue-300 rounded  checked:bg-blue-500 checked:border-blue-500" />
     <p className='ml-8 font-small text-gray-600'>Relevant for cash flow analysis</p>
  </label>
  
  <label className="flex items-center">
    <span className="w-48 font-small text-gray-500">NT Main Head</span>
    <span className="ml-4">:</span>
    <input
      type="text"
      className="border border-gray-300 p-1.5 rounded w-80 h-8 focus:outline-none  hover:border-blue-400 ml-8"
      
    />
  </label> <label className="flex items-center">
    <span className="w-48 font-small text-gray-500">NT Sub Head</span>
    <span className="ml-4">:</span>
    <input
      type="text"
      className="border border-gray-300 p-1.5 rounded w-80 h-8 focus:outline-none  hover:border-blue-400 ml-8"
      
    />
  </label>
  <label className="flex items-center">
    <span className="w-48 font-small text-gray-500">Notes</span>
    <span className="ml-4">:</span>
    <input
      type="text"
      className="border border-gray-300 p-1.5 rounded w-80 h-32 focus:outline-none  hover:border-blue-400 ml-8"
      
    />
  </label>

  
  <button className=" mt-4 bg-blue-500 text-sm text-white py-1 px-2   rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ">
    Save
  </button>
</div>




      </div>
   
  )
}





//wait for mapping
