import React, { useState } from 'react';
import NoActionLayout from '../../../../../../components/NoActionLayout';

export default function NTCodeAutomationAdd() {
  const [activeTab, setActiveTab] = useState('ntCode');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const tabStyle = (tab) => ({
    backgroundColor: activeTab === tab ? 'white' : 'transparent',
    color: activeTab === tab ? 'grey' : 'black',
    padding: '2px 10px',
    cursor: 'pointer',
    borderRadius: '5px',
    border: 'none',
  });

  return (
    <div>
      <div>
        <NoActionLayout title={"Cash FLow > Mapping rule ID > NT Code Automation > Add"} />
      </div>
      <div>
        <div className='ml-2 pl-8 pt-2 flex flex-row space-x-6 bg-gray-300'>
          <h6
            className="font-small"
            style={tabStyle('ntCode')}
            onClick={() => handleTabClick('ntCode')}
          >
            NT Code Selection Parameters
          </h6>
          <h6
            className="font-small"
            style={tabStyle('adminDetails')}
            onClick={() => handleTabClick('adminDetails')}
          >
            Admin details
          </h6>
        </div>
      </div>

      {activeTab === 'ntCode' && (
        <div className='p-8'>
        <div className="space-y-6  bg-blue-50 p-4 rounded-md border border-blue-200">
      
<div className="grid grid-cols-2 gap-x-8 gap-y-6">
  <label className="flex items-center group">
    <span className="w-48 text-sm font-medium text-gray-500">Mapping Rule ID</span>
    <span className="ml-4">:</span>
    <input
      type="text"
      className="border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
      placeholder="Enter main head"
    />
  </label>
  
  <label className="flex items-center group">
    <span className="w-48 text-sm font-medium text-gray-500">Description</span>
    <span className="ml-4">:</span>
    <input
      type="text"
      className="border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
      placeholder="Enter sub heads" 
    />
  </label>
  
  
</div>

</div>

<div className="space-y-6 bg-blue-50 p-4 rounded-md mt-4 border border-blue-200">
  <div className='flex justify-between'>
    <h5 className='font-medium text-gray-600 flex'>Assignment of Flow Code <h6 className=' ml-2 text-red-500'>*</h6></h5>
    <h5 className='mr-12 font-medium text-gray-600'>Rule sequence No:783382893</h5>
  </div>
  <div className="grid grid-cols-2 gap-x-8 gap-y-6">
    {/* First Row */}
    <label className="flex items-center group">
      <span className="w-48 text-sm font-medium text-gray-500">Flow Code Debit</span>
      <span className="ml-4">:</span>
      <input
        type="text"
        className="border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
        placeholder="Enter main head"
      />
    </label>
    
    <label className="flex items-center group">
      <span className="w-48 text-sm font-medium text-gray-500">Flow Code Credit</span>
      <span className="ml-4">:</span>
      <input
        type="text"
        className="border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
        placeholder="Enter sub heads"
      />
    </label>
    
    {/* Second Row */}
    <label className="flex items-center group col-span-2">
      <span className="w-48 text-sm font-medium text-gray-500">Threshold limit </span>
      <span className="ml-3.5 ">:</span>
      <input
        type="text"
        className="border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0  "
      />
    </label>
    
    {/* Third Row */}
    <label className="flex items-center group">
      <span className="w-48 text-sm font-medium text-gray-500">Flow code Debit </span>
      <span className="ml-4">:</span>
      <input
        type="text"
        className="border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
        placeholder="Enter main head"
      />
    </label>
    
    <label className="flex items-center group">
      <span className="w-48 text-sm font-medium text-gray-500">Flow Code Credit</span>
      <span className="ml-4">:</span>
      <input
        type="text"
        className="border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
        placeholder="Enter sub heads"
      />
    </label>
  </div>
</div>
<div className="space-y-6  bg-blue-50 p-4 rounded-md mt-4  border border-blue-200">
<div className='flex justify-between'>
    <h5 className='font-medium text-gray-600'>Master Data</h5>
    <h5 className='mr-12 font-medium text-gray-600'>Rule sequence No:783382893</h5>
  </div>
<div className="grid grid-cols-2 gap-x-8 gap-y-6">
  <label className="flex items-center group">
    <span className="w-48 text-sm font-medium text-gray-500">BTC</span>
    <span className="ml-4">:</span>
    <input
      type="text"
      className="border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
      placeholder="Enter main head"
    />
  </label>
  
  <label className="flex items-center group">
    <span className="w-48 text-sm font-medium text-gray-500">Format Type</span>
    <span className="ml-4">:</span>
    <input
      type="text"
      className="border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
      placeholder="Enter sub heads"
    />
  </label>
  
  <label className="flex items-center group">
    <span className="w-48 text-sm font-medium text-gray-500">Foreign Currency</span>
    <span className="ml-4">:</span>
    <input
      type="text"
      className="border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
      
    />
  </label>
  
  <label className="flex items-center group">
    <span className="w-48 text-sm font-medium text-gray-500">Bank Account Currency </span>
    <span className="ml-4">:</span>
    <input
      type="text"
      className="border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
      placeholder="Enter additional info"
    />
  </label>
  <label className="flex items-center group">
    <span className="w-48 text-sm font-medium text-gray-500">Dr/Cr</span>
    <span className="ml-4">:</span>
    <input
      type="text"
      className="border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
      
    />
  </label>
  
  <label className="flex items-center group">
    <span className="w-48 text-sm font-medium text-gray-500">Bank Account ID</span>
    <span className="ml-4">:</span>
    <input
      type="text"
      className="border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
      placeholder="Enter additional info"
    />
  </label>
</div>


</div>

<div className="space-y-6  bg-blue-50 p-4 rounded-md mt-4  border border-blue-200">
<div className='flex justify-between'>
    <h5 className='font-medium text-gray-600'>Key Word</h5>
    <h5 className='mr-12 font-medium text-gray-600'>Rule sequence No:783382893</h5>
  </div>
<div className="flex flex-col  gap-x-8 gap-y-6">
  
  <label className="flex items-center">
    <span className="w-48 text-sm font-medium text-gray-500">Bank Description</span>
    <span className="ml-3">:</span>
    <input
      type="text"
      className="border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 "
      
    />
     <input
      type="text"
      className="border border-gray-300 p-1.5 rounded w-24 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
      
    />
  </label>
  
  <label className="flex items-center">
    <span className="w-48 text-sm font-medium text-gray-500">Bank Reference</span>
    <span className="ml-3">:</span>
    <input
      type="text"
      className="border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
      
    />
     <input
      type="text"
      className="border border-gray-300 p-1.5 rounded w-24 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
      
    />
  </label>
  
  <label className="flex items-center">
    <span className="w-48 text-sm font-medium text-gray-500">Customer Reference</span>
    <span className="ml-3">:</span>
    <input
      type="text"
      className="border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
      
    />
     <input
      type="text"
      className="border border-gray-300 p-1.5 rounded w-24 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
      
    />
  </label>
  <label className="flex items-center">
    <span className="w-48 text-sm font-medium text-gray-500">Additional info</span>
    <span className="ml-3">:</span>
    <input
      type="text"
      className="border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
      
    />
    
  </label>
</div>


</div>

<div className='flex flex-row '>
<button className=" mt-4 bg-blue-500 text-sm text-white py-1 px-2   rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ">
    Save
  </button>
  <button className=" mt-4 ml-4 border border-blue-500 text-sm text-blue-500 py-1 px-2   rounded hover:bg-blue-100 transition duration-200 ease-in-out w-30 ">
    Save & Continue
  </button>
</div>

</div>
      )}

      {activeTab === 'adminDetails' && (
        <div className='p-8'>
        <div className="space-y-6 bg-blue-50 p-4 rounded-md border border-blue-200 mt-4">
  <div className="grid grid-cols-3 gap-x-4 gap-y-6">
 
    <div className="col-span-1 space-y-4">
      <p className="ml-4 font-small text-gray-600">Created By<span className='ml-9 mr-4'>:</span> <span> Admin1</span></p>
      <p className="ml-4 font-small text-gray-600">Created On<span className='ml-8 mr-4'>:</span> <span> 21.05.2024</span></p>
      <p className="ml-4 font-small text-gray-600">Created Time<span className='ml-4 mr-4'>:</span> <span> 10.00 AM</span></p>
    </div>
    
    
    <div className="col-span-1 space-y-4">
      <p className="ml-4 font-small text-gray-600">Updated By<span className='ml-5 mr-4'>:</span> <span> Admin2</span></p>
      <p className="ml-4 font-small text-gray-600">Updated On<span className='ml-4 mr-4'>:</span> <span> 22.07.2024</span></p>
    </div>
    
    
    <div className="col-span-1 space-y-4">
      <p className="ml-4 font-small text-gray-600">Map Type<span className='ml-4 mr-4'>:</span> <span> Automatic</span></p>
      <p className="ml-4 font-small text-gray-600">INTLNUM<span className='ml-7 mr-4'>:</span> <span> Automatic</span></p>
    </div>
  </div>
</div>
</div>



      )}
    </div>
  );
}
