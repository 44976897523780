import React from 'react'


import { CashFlowbuttonsConfigs } from '../../../Components/CashFlowbuttonsConfig'
import NoActionLayout from '../../../../../../components/NoActionLayout';
import CashFlowActions from '../../../Components/CashFlowActions';
import { IoAddCircleOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import NTCodeTable from '../../../Tables/SetUp/NTCodeTable';

export default function NTCodes() {
    const navigate = useNavigate();

    const handleNavigate = (path) => {
      navigate(path);
    };
  
    const buttons = CashFlowbuttonsConfigs.screen1.map(button => ({
      ...button,
      onClick: button.icon === IoAddCircleOutline 
        ? () => handleNavigate("/nt-codes-add") // Navigate to the desired screen
        : button.onClick // Use the existing onClick handler for other buttons
    }));
  return (
    <div>
       <div>
        <NoActionLayout title="Cash Flow > Set Up > NT Codes" />
      </div>
      <div>
      <CashFlowActions buttons={buttons}/>      </div>
      <NTCodeTable/>
    </div>
  )
}
