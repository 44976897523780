import React from 'react'

export default function InterCompanyFTTable() {
  return (
    <div>
       <div className='p-4'>
                {/* Set the container to be scrollable */}
                <div className="overflow-x-auto max-w-full"> {/* Adjust max-w-full to control visible width */}
                    <table className="table-fixed min-w-[800px] bg-white"> {/* Ensure table is wider than the container */}
                        <thead className="bg-sky-500 text-primary-foreground text-sm">
                            <tr>
                                <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Description</th>
                                <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Reference</th>
                                <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Description2</th>
                                <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Additional info1</th>
                                <th className="py-2 px-4 w-[200px] text-left text-white font-medium">SWITCH BTC</th>
                                <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Debit amount</th>
                                <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Credit amount</th>
                                <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Cumulative balances</th>
                                <th className="py-2 px-4 w-[200px] text-left text-white font-medium">cash Flow Code</th>
                                <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Bank Statement mapping rule no</th>
                                <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Manually Changed</th>

                            </tr>
                        </thead>
                        <tbody className='text-sm'>
                            {Array(50).fill().map((_, index) => (
                                <tr key={index} className="border-b" style={{ backgroundColor: index % 2 === 0 ? 'white' : '#f3f4f6' }}>
                                    
                                    <td className="py-2 px-4">Description</td>
                                    <td className="py-2 px-4">Reference</td>
                                    <td className="py-2 px-4">Description2</td>
                                    <td className="py-2 px-4">Additional info1</td>
                                    <td className="py-2 px-4">SWITCH BTC</td>
                                    <td className="py-2 px-4">Debit amount  </td>
                                    <td className="py-2 px-4">  Credit amount   </td>
                                    <td className="py-2 px-4">Cumulative balances</td>
                                    <td className="py-2 px-4">cash Flow Code</td>
                                    <td className="py-2 px-4">Bank Statement mapping rule no</td>
                                    <td className="py-2 px-4">
                                        <input type="checkbox" className="form-checkbox" />
                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
    </div>
  )
}
