import React, { useState, useEffect } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import HoverButton from './HoverButton';
import CustomButton from './CustomButton';

const Header = ({ title = "", onBreadcrumbClick, extraContent, hasUnsavedChanges }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [nextPath, setNextPath] = useState(null);
  const [clientId, setClientId] = useState(null);

  useEffect(() => {
    const fetchAssignee = () => {
      try {
        const userDetailString = sessionStorage.getItem("userDetail");
        if (userDetailString) {
          const userDetail = JSON.parse(userDetailString);
          setClientId(userDetail.clientId || null); // Set clientId from session storage
        } else {
          console.error("No user detail found in session storage.");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchAssignee();
  }, []); // Fetch clientId on component mount

  const breadcrumbs = title ? title.split(' > ') : [];

  const getBreadcrumbPath = (breadcrumb) => {
    const routes = {
      // Add your custom routes here
    };
    return routes[breadcrumb] || location.pathname; // Fallback to current path if no match
  };

  const handleBreadcrumbClick = (breadcrumb) => {
    const crumb = {
      title: breadcrumb,
      path: getBreadcrumbPath(breadcrumb),
    };

    if (hasUnsavedChanges) {
      setNextPath(crumb.path);
      setIsModalOpen(true);
    } else {
      onBreadcrumbClick(crumb);
    }
  };

  const handleNavigateBack = () => {
    if (hasUnsavedChanges) {
      setNextPath(-1); // Navigate back
      setIsModalOpen(true);
    } else {
      navigate(-1);
    }
  };

  const confirmNavigation = () => {
    setIsModalOpen(false);
    if (nextPath === -1) {
      navigate(-1);
    } else if (nextPath) {
      navigate(nextPath);
    }
  };

  const cancelNavigation = () => {
    setIsModalOpen(false);
    setNextPath(null);
  };

  return (
    <>
      <header className="bg-white text-primary-foreground p-4 flex items-center space-x-2 shadow-md shadow-blue-100 mb-2 ml-2">
        <FaArrowLeft
          style={{ color: '#0B5299' }}
          onClick={handleNavigateBack}
          className="cursor-pointer text-customBlue mr-4"
        />
        <div className="flex space-x-2 items-center flex-grow">
          {breadcrumbs.map((breadcrumb, index) => {
            const isLast = index === breadcrumbs.length - 1;
  
            return (
              <span key={breadcrumb} className="flex items-center">
      <span
        className={`text-xl font-small cursor-pointer-center transition-all duration-200 hover:text-customBlue hover:text-lg ${
          isLast ? 'text-customBlue' : 'text-[#0B5299]'
        }`}
        onClick={() => handleBreadcrumbClick(breadcrumb)}
      >
        {breadcrumb}
      </span>
      {!isLast && <span className="mx-2 text-gray-500">{'>'}</span>}
    </span>
  );
})}
          {/* Render any extra content after breadcrumbs */}
          {extraContent && <span>{extraContent}</span>}
        </div>
        {/* Render Client ID at the far end, outside the breadcrumb section */}
        {clientId && (
          <div className="ml-4 text-md  font-small text-customBlue">
            Client ID : {clientId}
          </div>
        )}
      </header>
  
      {/* Confirmation Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-md w-96 text-center">
            <h2 className="text-lg font-bold mb-4 text-customBlue">Unsaved Changes</h2>
            <p className="mb-6 text-customGray">You have unsaved changes. Are you sure you want to leave?</p>
            <div className="flex justify-center space-x-4">
              <HoverButton
                className="w-16"
                title={"No"}
                onClick={cancelNavigation}
              />
              <CustomButton
                title={"Yes"}
                onClick={confirmNavigation}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
  

}
export default Header ;