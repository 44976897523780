import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import UserService from "../../rest-services/UserServices";
import {} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainHeader from "../../components/MainHeader";
import CustomButton from "../../components/CustomButton";
import AdminService from "../../rest-services/AdminServices";
import PasswordField from "../../components/Textfield";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { useToast } from "../../components/toast/toast";

function ForgotPasswordScreen() {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [buttonText, setButtonText] = useState("Change Password");
  const [passwordPolicy, setPasswordPolicy] = useState(null);
  const { toast } = useToast();

  const validateForm = () => {
    let valid = true;

    if (!currentPassword) {
      toast("error", "Current password is required");
      valid = false;
    }
    if (!newPassword) {
      toast("error", "New password is required");
      valid = false;
    }
    if (!confirmPassword) {
      toast("error", "Confirm password is required");
      valid = false;
    } else if (newPassword !== confirmPassword) {
      toast("error", "Passwords do not match");
      valid = false;
    }

    return valid;
  };

  useEffect(() => {
    handleGetPolicy();
  }, []);
  const handleGetPolicy = async () => {
    try {
      const response = await AdminService.getPasswordPolicy();
      setPasswordPolicy(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setIsLoading(true);
    setButtonText("Changing...");
    try {
      let userid = "";
      const userDetailString = sessionStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        userid = userDetail.id;
      } else {
        toast("error", "No user detail found in session storage.");
        return;
      }

      const response = await UserService.forgotPassword(
        currentPassword,
        newPassword,
        userid
      );

      if (response.status === 200) {
        setIsLoading(false);
        setButtonText("Change Password");
        toast("success", response.message);
        window.location.href = "/home";
      } else {
        setIsLoading(false);
        setButtonText("Change Password");
        toast("error", response.message);
      }
    } catch (error) {
      toast("error", `Error changing password: ${error.message}`);
    }
  };
  const [isPasswordPolicyOpen, setIsPasswordPolicyOpen] = useState(false);
  const onOpenPasswordPolicy = () => {
    setIsPasswordPolicyOpen(true);
  };

  const onClosePasswordPolicy = () => {
    setIsPasswordPolicyOpen(false);
  };

  return (
    <div className="min-h-screen flex flex-col">
      <MainHeader title={"Reset Password"} />
      <div className="flex-grow">
        <div className="p-8 w-full max-w-md">
          <form>
            <div className="mb-6 flex items-center">
              <button
                type="button" // This prevents the button from triggering form submission
                className="absolute top-5 right-16 text-gray-600 hover:text-gray-800"
                onClick={onOpenPasswordPolicy}
              >
                <BsFillQuestionCircleFill />
              </button>

              {isPasswordPolicyOpen && (
                <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center z-50">
                  <div className="bg-white p-6 rounded-lg shadow-lg w-1/2 max-w-lg">
                    <h3 className="text-lg font-semibold mb-4">
                      Password Policy
                    </h3>
                    <p>
                      - Minimum length: {passwordPolicy.password_length_min}{" "}
                      <br />- Maximum length:{" "}
                      {passwordPolicy.password_length_max} <br />- Requires
                      capital letters: {passwordPolicy.capital_letters} <br />-
                      Requires numerical characters:{" "}
                      {passwordPolicy.numerical_characters} <br />- Requires
                      special characters: {passwordPolicy.special_characters}{" "}
                      <br />
                    </p>
                    <button
                      className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg"
                      onClick={onClosePasswordPolicy}
                    >
                      Close
                    </button>
                  </div>
                </div>
              )}
              <label
                className="block text-card-foreground text-lg mb-2 w-40"
                htmlFor="currentPassword"
              >
                Current Password:
              </label>

              <PasswordField
                onChange={(e) => setCurrentPassword(e.target.value)}
                placeholder={"Current password"}
                className={
                  "shadow appearance-none border rounded w-full py-2 px-3 text-card-foreground leading-tight focus:outline-none focus:ring focus:border-primary"
                }
              />
            </div>
            <div className="mb-6 flex items-center">
              <label
                className="block text-card-foreground text-sm mb-2 w-40"
                htmlFor="newPassword"
              >
                Enter New Password:
              </label>

              <PasswordField
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder={"New password"}
                className={
                  "shadow appearance-none border rounded w-full py-2 px-3 text-card-foreground leading-tight focus:outline-none focus:ring focus:border-primary"
                }
              />
            </div>
            <div className="mb-6 flex items-center">
              <label
                className="block text-card-foreground text-sm  mb-2 w-40"
                htmlFor="confirmPassword"
              >
                Confirm New Password:
              </label>

              <PasswordField
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder={"Confirm password"}
                className={
                  "shadow appearance-none border rounded w-full py-2 px-3 text-card-foreground leading-tight focus:outline-none focus:ring focus:border-primary"
                }
              />
            </div>
            <CustomButton
              onClick={handleClick}
              title={isLoading ? "Changing..." : buttonText}
              className="px-4 py-2 w-auto h-18 ml-32 whitespace-nowrap"
            />
          </form>
        </div>
      </div>
    </div>
  );
}

export default ForgotPasswordScreen;
