import React, { useState } from "react";
import styled from "styled-components";

const TableWrapper = styled.ul`
  /* Styling for the scrollbar */
  overflow-y: auto;
  max-height: 240px; /* You can adjust the height based on your needs */

  /* For WebKit-based browsers (Chrome, Edge, Safari) */
  ::-webkit-scrollbar {
    width: 6px; /* Vertical scrollbar width */
    height: 6px; /* Horizontal scrollbar height */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #8aa6c1;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #6b8db2; /* Slightly darker for hover */
  }

  ::-webkit-scrollbar-track {
    background-color: #f0f4f8;
    border-radius: 6px;
  }

  /* For Firefox */
  scrollbar-width: thin; /* Applies to both horizontal and vertical */
  scrollbar-color: #8aa6c1 #f0f4f8; /* Thumb and track colors */
`;

const CustomDropdown = ({
  value = "",
  options = [],
  onChange,
  isOpen,
  onToggle, // Prop for toggling dropdown
  isDisabled = false,
  className = "",
  placeholder = "Select an option",
  width = "w-80",
}) => {
  const handleOptionClick = (optionValue) => {
    onChange(optionValue); // Update the selected value
  if(isOpen){
    onToggle(!isOpen);
  }
       // Close the dropdown immediately
    
  };

  const selectedLabel =
    typeof value === "object" && value !== null ? value.label : value;

  return (
    <div className={`relative ${className}`}>
      <button
        onClick={onToggle}
        disabled={isDisabled}
        className={`border border-gray-300 text-customGray p-1 rounded ${width} h-8 focus:outline-none hover:border-blue-400 text-left flex items-center justify-between`}
      >
        <span className="text-sm font-small text-customGray">{selectedLabel || placeholder}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`w-4 h-4 transition-transform ${
            isOpen ? "rotate-180" : ""
          }`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      {isOpen && (
        <ul className="absolute z-10 bg-white border border-gray-300 rounded w-72 mt-1 shadow-md">
          <TableWrapper>
            {options.map((option, index) => (
              <li
                key={index}
                onClick={() => handleOptionClick(option.value)}
                className="px-3 py-1 hover:bg-customBlue hover:text-white text-customGray cursor-pointer text-sm font-small text-customGray"
              >
                {option.label}
              </li>
            ))}
          </TableWrapper>
        </ul>
      )}
    </div>
  );
};

export default CustomDropdown;
