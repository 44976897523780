import React, { useEffect, useState } from "react";
import Tabs from "../../../../../components/Tabs";
import Footer from "../../../../../components/footer";
import ButtonGroup from "../../../../../components/FooterButton";
import Header from "../../../../../components/Header";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LegalEntityServices from "../../../../../rest-services/LegalEntityServices";
import { useToast } from "../../../../../components/toast/toast";
import { RxDropdownMenu } from "react-icons/rx";
import CurrencyServicess from "../../../../../rest-services/CurrencyServices";
import CountryServices from "../../../../../rest-services/CountryServices";
import StateProvinceServices from "../../../../../rest-services/StateProvinceServices";
import CustomButton from "../../../../../components/CustomButton";
import { TbFlag3Filled } from "react-icons/tb";
import ReusableModal from "../../../../../components/PopupComponent";
import TableWrapper from "../../../../../components/ScrollBarComponent";

export default function LegalEntityAdd() {
  const [activeTab, setActiveTab] = useState("addLegalEntity");
  const navigate = useNavigate();
  const { legalentityId } = useParams();
  const location = useLocation();
  const { mode } = location.state || {};
  const isViewMode = mode === "view";
  const [errors, setErrors] = useState({}); // Validation errors
  const [assignee, setAssignee] = useState({ id: "", assigner: "" }); // State to store assignee
  const { toast } = useToast();
  const [alert, setAlert] = useState(null);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [lgeCode, setLgeCode] = useState("");
  const [lgeDescription, setLgeDescription] = useState("");

  const [currencyCode, setCurrencyCode] = useState("");
  const [currencyName, setCurrencyName] = useState("");
  const [currencies, setCurrencies] = useState([]);
  const [countryCode, setCountryCode] = useState(""); // Country Code
  const [countryName, setCountryName] = useState("");
  const [district, setdistrict] = useState("");
  const [poBox, setpoBox] = useState("");
  const [state, setstate] = useState("");
  const [stateName, setStateName] = useState("");
  // const [countryCode, setcountryCode] = useState("");
  const [countryId, setCountryId] = useState("");
  const [areaBlock, setareaBlock] = useState("");
  const [roadNo, setroadNo] = useState("");
  const [roadName, setRoadName] = useState("");
  const [streetNo, setstreetNo] = useState("");
  const [buildingName, setbuildingName] = useState("");
  const [buildingNo, setbuildingNo] = useState("");
  const [doorNo, setdoorNo] = useState("");
  const [floorNo, setfloorNo] = useState("");
  const [addressLine1, setaddressLine1] = useState("");
  const [addressLine2, setaddressLine2] = useState("");
  const [addressLine3, setaddressLine3] = useState("");
  const [countries, setCountries] = useState([]);
  const [stateProvinces, setStateProvinces] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(countryCode || "");
  const [postalCode, setpostalCode] = useState("");
  const [searchQueryState, setSearchQueryState] = useState(""); // State for search query
  const [selectedState, setSelectedState] = useState(state || "");

  const [streetName, setstreetName] = useState("");
  const [areaName, setareaName] = useState("");
  // const [cityName, setcityName] = useState("");
  const [landMark, setlandMark] = useState("");
  const [zipCode, setzipCode] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [isModalOpenForState, setIsModalOpenForState] = useState(false);
  const [isCountryModalOpen, setIsCountryModalOpen] = useState(false);
  const [isCurrencyModalOpen, setIsCurrencyModalOpen] = useState(false);
  const [isStateModalOpen, setIsStateModalOpen] = useState(false);
  const [filteredStateProvinces, setFilteredStateProvinces] = useState([]);
  const [isValidationTriggered, setIsValidationTriggered] = useState(false);
  const [legalEntity, setLegalEntity] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [isCopyMode, setIsCopyMode] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  useEffect(() => {
    if (location.state?.copiedData) {
      setIsCopyMode(true);
      const copiedData = location.state.copiedData;
      // Set address fields
      if (copiedData.addresses && copiedData.addresses.length > 0) {
        const address = copiedData.addresses[0]; // Use the first address
        setdoorNo(address.doorNo);
        setfloorNo(address.floorNo);
        setbuildingNo(address.buildingNo);
        setbuildingName(address.buildingName);
        setlandMark(address.landMark);
        setstreetNo(address.streetNo);
        setstreetName(address.streetName);
        setroadNo(address.roadName);
        setRoadName(address.roadName);
        setareaBlock(address.areaBlock);
        setareaName(address.areaName);
        setaddressLine1(address.addressLine1);
        setaddressLine2(address.addressLine2);
        setaddressLine3(address.addressLine3);
        setstate(address.state);
        setdistrict(address.district);
        setpostalCode(address.postalCode);
        setzipCode(address.zipCode);
        setpoBox(address.poBox);
        // Map country
        const selectedCountryData = countries.find(
          (country) => country.country === address.countryCode
        );
        if (selectedCountryData) {
          setSelectedCountry(selectedCountryData.country);
          setCountryCode(selectedCountryData.country);
          setCountryName(selectedCountryData.countryName);
        } else {
          console.warn("Country not found for code:", address.countryCode);
        }

        // Map state
        const selectedStateData = stateProvinces.find(
          (state) => state.state === address.state
        );
        if (selectedStateData) {
          setSelectedState(selectedStateData.state);
          setStateName(selectedStateData.State_Name);
          setstate(selectedStateData.state);
        } else {
          console.warn("State not found for code:", address.state);
        }
      } else {
        console.warn("No addresses found in copiedData.");
      }

      // Set other copied fields
      setLgeCode(copiedData.LGE_Code || "");
      setLgeDescription(copiedData.LGE_Description || "");
      if (copiedData.Currency) {
        const selectedCurrency = currencies.find(
          (cur) => cur.currencyCode === copiedData.Currency
        );

        if (selectedCurrency) {
          setCurrencyCode(selectedCurrency.currencyCode); // Set the legal entity code
          setCurrencyName(selectedCurrency.Currency_Name);
          setSelectedCurrency(selectedCurrency.currencyCode); // Update UI-selected value
        } else {
          console.warn(
            "Legal entity not found for code:",
            copiedData.legalEntityCode
          );
        }
      }
    } else if (legalentityId) {
      getDataForEdit();
    }
    fetchAssignee();
  }, [legalentityId, currencies, countries, stateProvinces, location.state]);
  useEffect(() => {
    fetchcountry();
    fetchcurrency();
    fetchStateProvinces();
    fetchAssignee();
    fetchLegalEntity();
  }, []);

  // const handleCheckboxChange = (event) => {
  //   const selectedName = event.target.value; // Change from value to country name
  //   setSelectedCountry(selectedName);

  //   // Find the selected country data by country name instead of code
  //   const selectedCountryData = countries.find(country => country.countryName === selectedName);
  //   if (selectedCountryData) {
  //     setCountryCode(selectedCountryData.country); // Set the country code based on the selected country name
  //     setCountryName(selectedCountryData.countryName);
  //   }
  // };
  const getDataForEdit = async () => {
    try {
      const response = await LegalEntityServices.getLegalEntityById(
        legalentityId
      );
      console.log("Response data:", response);
      const address = response.addresses[0];
      // Existing data handling
      setLgeCode(response.LGE_Code); // Adjust according to API response structure
      setLgeDescription(response.LGE_Description);
      setCountryCode(response.Country); // Set country code
      setCurrencyCode(response.Currency);
      setStatus(response.status); // Set currency code
      setdoorNo(address.doorNo);
      setfloorNo(address.floorNo);
      setbuildingNo(address.buildingNo);
      setbuildingName(address.buildingName);
      setlandMark(address.landMark);
      setstreetNo(address.streetNo);
      setstreetName(address.streetName);
      setroadNo(address.roadName);
      setRoadName(address.roadName);
      setareaBlock(address.areaBlock);
      setareaName(address.areaName);
      setaddressLine1(address.addressLine1);
      setaddressLine2(address.addressLine2);
      setaddressLine3(address.addressLine3);
      setstate(address.state);
      setdistrict(address.district);
      setpostalCode(address.postalCode);
      setzipCode(address.zipCode);
      setpoBox(address.poBox);

      // Country lookup based on country code from response
      if (!response.Country) {
        console.error("Country code is missing in the response.");
      } else {
        const countryCode = response.Country.trim().toUpperCase();
        console.log("Searching for country code:", countryCode);
        const country = countries.find(
          (c) => c.country.trim().toUpperCase() === countryCode
        );
        console.log("Country found:", country);

        if (country) {
          setCountryName(country.countryName); // Set country name for display
          setSelectedCountry(country.countryName); // Set the selected country based on country name
        } else {
          console.error(
            "Country not found for the provided country code:",
            countryCode
          );
          console.log(
            "Available countries:",
            countries.map((c) => c.country)
          );
        }
      }

      // for state
      const fetchedState = stateProvinces.find(
        (c) => c.state === address.state
      );
      console.log("State code from response:", address.state);
      console.log("Available states:", stateProvinces);
      console.log("the fetched console", fetchedState.State_Name);
      if (fetchedState) {
        setStateName(fetchedState.State_Name); // Set state name for display
      } else {
        console.error(
          "State not found for the provided state code:",
          response.state
        );
      }

      // Currency lookup based on currency code from response
      const currency = currencies.find(
        (c) => c.currencyCode === response.Currency
      );
      console.log("Currency code from response:", response.Currency);
      console.log("Available currencies:", currencies);
      if (currencies) {
        setCurrencyName(currency.Currency_Name); // Set currency name for display
        setSelectedCurrency(currency.currencyCode); // Set the selected currency based on currency name
        setCurrencyCode(currency.currencyCode); // Set the currency code// Set the selected country based on countryName
      } else {
        console.error("Country not found for the provided country code.");
      }
    } catch (error) {
      console.error("Error fetching legal entity data:", error);
      setAlert({
        severity: "error",
        message: "Error fetching legal entity data.",
      });
    }
  };

  const fetchAssignee = async () => {
    try {
      const userDetailString = sessionStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.userId || "",
        };
        setAssignee(assigneeData);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  const fetchcountry = async () => {
    try {
      const response = await CurrencyServicess.getAllCalendar(
        `${process.env.REACT_APP_BASE_URL}/get-country`
      );
      console.log("Fetched countries:", response); // Add this log
      setCountries(response.data.data); // Ensure this is correct
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  const fetchStateProvinces = async () => {
    try {
      const response = await StateProvinceServices.getAllStateprovince(
        `${process.env.REACT_APP_BASE_URL}/get-stateprovince`
      );
      console.log("Fetched StateProvinces:", response); // Add this log
      setStateProvinces(response.data.data); // Ensure this is correct
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  const fetchcurrency = async () => {
    try {
      const response = await CurrencyServicess.getAllCalendar(
        `${process.env.REACT_APP_BASE_URL}/get-currency`
      );
      console.log("Fetched Currencies:", response); // Add this log
      setCurrencies(response.data.data); // Ensure this is correct
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  const fetchLegalEntity = async () => {
    setLoading(true);
    try {
      const response = await LegalEntityServices.getAllLegalEntity(
        `/get-all-legal-entity`
      );
      const responseData = response.data;

      if (responseData.error) {
        setAlert({ severity: "error", message: responseData.message });
        return;
      }

      if (responseData && Array.isArray(responseData)) {
        setLegalEntity(responseData); // assuming responseData contains an array of legal entities
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching legal entities: ${error.message}`,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const data = {
      id: isCopyMode ? null : legalentityId, // Use 'id' for updates
      LGE_Code: lgeCode,
      LGE_Description: lgeDescription,
      Country: countryCode,
      Currency: currencyCode,
      created_by: assignee.assigner,
      changed_by: assignee.assigner,
      doorNo,
      floorNo,
      buildingNo,
      buildingName,
      landMark,
      streetNo,
      streetName,
      roadNo,
      roadName,
      areaBlock,
      areaName,
      addressLine1,
      addressLine2,
      addressLine3,
      countryCode,
      state,
      district,
      postalCode,
      zipCode,
      poBox,
    };

    try {
      let response;
      if (legalentityId && !isCopyMode) {
        // Updating the existing currency
        response = await LegalEntityServices.addLegalEntity(
          `${process.env.REACT_APP_BASE_URL}/update-legal-entity`, // Ensure this endpoint is for updates
          data
        );
      } else {
        // Creating a new currency
        response = await LegalEntityServices.addLegalEntity(
          `${process.env.REACT_APP_BASE_URL}/create-legal-entity`,
          data
        );
      }

      if (response && !response.error) {
        toast(
          "success",
          legalentityId && !isCopyMode
            ? "Legal Entity Updated successfully"
            : "Legal Entity Added successfully"
        );
        // Clear the form and navigate
        setCurrencyCode("");
        setCurrencyName("");
        navigate("/legalentity"); // Redirect after saving
      } else {
        toast("error", "Error adding/updating Legal Entity");
      }
    } catch (error) {
      console.error("Request error:", error);
      setAlert({
        severity: "error",
        message: `Error adding/updating Legal Entity: ${error.message}`,
      });
    }
  };
  const handleSaveAndContinue = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const data = {
      id: isCopyMode ? null : legalentityId, // Use 'id' for updates
      LGE_Code: lgeCode,
      LGE_Description: lgeDescription,
      Country: countryCode,
      Currency: currencyCode,
      created_by: assignee.assigner,
      changed_by: assignee.assigner,
      doorNo,
      floorNo,
      buildingNo,
      buildingName,
      landMark,
      streetNo,
      streetName,
      roadNo,
      roadName,
      areaBlock,
      areaName,
      addressLine1,
      addressLine2,
      addressLine3,
      countryCode,
      state,
      district,
      postalCode,
      zipCode,
      poBox,
    };

    try {
      let response;
      if (legalentityId && !isCopyMode) {
        // Updating the existing currency
        response = await LegalEntityServices.addLegalEntity(
          `${process.env.REACT_APP_BASE_URL}/update-legal-entity`, // Ensure this endpoint is for updates
          data
        );
      } else {
        // Creating a new currency
        response = await LegalEntityServices.addLegalEntity(
          `${process.env.REACT_APP_BASE_URL}/create-legal-entity`,
          data
        );
      }

      if (response && !response.error) {
        toast(
          "success",
          legalentityId && !isCopyMode
            ? "Legal Entity Updated successfully"
            : "Legal Entity Added successfully"
        );
        // Clear the form and navigate
        setLgeCode("");
        setLgeDescription("");
        setSelectedCountry("");
        setSelectedState("");
        setStateName("");
        setstate("");
        setCountryName("");
        setCurrencyCode(""); // Set the legal entity code
        setCurrencyName("");
        setSelectedCurrency("");
        setCountryCode("");
        setdoorNo("");
        setfloorNo("");
        setbuildingNo("");
        setbuildingName("");
        setlandMark("");
        setstreetNo("");
        setstreetName("");
        setroadNo("");
        setRoadName("");
        setareaBlock("");
        setareaName("");
        setaddressLine1("");
        setaddressLine2("");
        setaddressLine3("");
        setstate("");
        setdistrict("");
        setpostalCode("");
        setzipCode("");
        setpoBox("");
        setErrors("");
      } else {
        toast("error", "Error adding/updating Legal Entity");
      }
    } catch (error) {
      console.error("Request error:", error);
      setAlert({
        severity: "error",
        message: `Error adding/updating Legal Entity: ${error.message}`,
      });
    }
  };
  // Separate handler for country selection
  const handleCountryCheckBoxChange = (event) => {
    console.log(`this is the Legal Entity ${countries}`);

    const selectedName = event.target.value; // Get the selected country name
    setSelectedCountry(selectedName);
    setUnsavedChanges(true);
    const selectedCountryData = countries.find(
      (country) => country.country === selectedName
    );
    if (selectedCountryData) {
      setCountryCode(selectedCountryData.country); // Set the country code
      setCountryName(selectedCountryData.countryName); // Set the country name

      // Filter states by the selected country code
      const filteredStates = stateProvinces.filter(
        (state) => state.Country_Code === selectedCountryData.country
      );
      setFilteredStateProvinces(filteredStates); // Assuming you have state to hold the filtered states
    }
  };

  // Separate handler for Legal Entity selection
  const handleCurrencyCheckBoxChange = (event) => {
    currencies.forEach((currency) => {
      console.log(currency);
    });
    const selectedName = event.target.value; // Get the selected currency name
    setSelectedCurrency(selectedName);
    setUnsavedChanges(true);
    const selectedCurrencyData = currencies.find(
      (currency) => currency.currencyCode === selectedName
    );

    if (selectedCurrencyData) {
      setCurrencyCode(selectedCurrencyData.currencyCode); // Set the currency code
      setCurrencyName(selectedCurrencyData.Currency_Name);
      // Set the currency name
    }
  };
  const handleCheckboxChangeForState = (event) => {
    const selectedCode = event.target.value;
    console.log("selected State code", selectedCode);
    setSelectedState(selectedCode);
    setstate(selectedCode);
    setUnsavedChanges(true);
    const selectedCountryData = stateProvinces.find(
      (stateP) => stateP.state === selectedCode
    );
    console.log("selected state country data", selectedCountryData);
    if (selectedCountryData) {
      console.log(
        "check selected country data",
        selectedCountryData.State_Name
      );
      setStateName(selectedCountryData.State_Name);
    }
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSearchChangeState = (e) => {
    setSearchQueryState(e.target.value);
  };

  const validateForm = () => {
    setIsValidationTriggered(true);

    const isAddressSectionFilled = addressLine1 || addressLine2 || addressLine3;
    const isBuildingSectionFilled =
      doorNo || floorNo || buildingNo || buildingName || landMark;
    const isStreetSectionFilled = streetNo || streetName || roadNo || roadName;
    const isAreaSectionFilled = areaBlock || areaName;
    const isBuildingStreetAreaSectionFilled =
      isBuildingSectionFilled || isStreetSectionFilled || isAreaSectionFilled;
    const newErrors = {};
    if (!lgeCode) newErrors.lgeCode = "lge Code Code is required.";
    if (!lgeDescription)
      newErrors.lgeDescription = "lge Description is required.";
    if (!countryName) newErrors.countryName = "Country Code   is required.";
    if (!currencyName) newErrors.currencyName = " Currency Code is required.";
    if (filteredStateProvinces.length > 0 && !state) {
      newErrors.state = "state is required.";
    }
    if (!isAddressSectionFilled && !isBuildingStreetAreaSectionFilled) {
      newErrors.addressSection =
        "Either Address Section or Building, Street, and Area Section must be entered";
    }
    if (!countryCode) newErrors.countryCode = "Country Code   is required.";

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      toast("error", "Missing Required Fields");
    }
    return Object.keys(newErrors).length === 0;
  };

  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "Bank&Company masterData") {
      navigate("/master-data");
    } else if (crumb.title === "Legal Entity") {
      navigate("/legalentity");
    } else if (crumb.title === "Primary Data") {
      navigate("/master-data");
    } else if (crumb.path) {
      navigate(crumb.path);
    }
  };
  const toggleModalForState = () => {
    setIsModalOpenForState(!isModalOpenForState);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const tabs = [
    { value: "addLegalEntity", label: "Basic Details" },
    { value: "lgeAddresses", label: "LGE Address" },
  ];
  const errorss = {
    addLegalEntity:
      !lgeCode || !lgeDescription || !countryName || !currencyName, // Replace with your validation logic
    lgeAddresses: !addressLine1,
  };
  const nextToAddress = () => {
    setActiveTab("lgeAddresses");
  };
  const checkDuplicatelgecode = async (code) => {
    try {
      const response = await LegalEntityServices.checkDuplicatelgecode(
        `${process.env.REACT_APP_BASE_URL}/lgeduplicate`,
        { LGE_Code: code } // Sending roleName to backend
      );

      console.log("Response from server:", response);

      if (response.message === "lge code   already exists") {
        // Check if it's a duplicate
        console.log("Response from internal server:", response);
        setErrors({ lgecode: " " });

        toast("error", "Lge Code already exists.");
      }
      console.log("Response from external server:", response);
    } catch (error) {
      console.error("Error checking duplicate Lgecode:", error);
      // Handle potential errors when making the API call
      setErrors((prev) => ({ ...prev, lgeCode: "Error checking role name" }));
    }
  };
  const handleLgeCodeChange = (e) => {
    const code = e.target.value;
    setUnsavedChanges(true);
    setLgeCode(code);

    // Clear previous errors when typing
    setErrors((prev) => ({ ...prev, lgeCode: "" }));

    if (code.trim() !== "") {
      checkDuplicatelgecode(code);
    }
  };
  const getApprovedIcon = () => {
    let color = "black"; // Default color
    // Determine the color based on the approved status
    if (status === 3) {
      color = "#29CB00"; // Green
    } else if (status === 0) {
      color = "gray"; // Gray
    } else if (status === 1) {
      color = "yellow"; // Yellow
    } else if (status === 2) {
      color = "orange"; // Orange
    } else if (status === 4) {
      color = "red"; // Red
    }

    return <TbFlag3Filled style={{ color, marginLeft: "8px" }} />; // Return the icon with the determined color
  };
  return (
    <div>
      <Header
        title={`Primary Data > Bank&Company masterData > Legal Entity > ${
          isCopyMode
            ? "Add"
            : legalentityId
            ? isViewMode
              ? "View"
              : "Edit"
            : "Add"
        }`}
        onBreadcrumbClick={handleBreadcrumbClick}
        extraContent={legalentityId && !isCopyMode ? getApprovedIcon() : null}
        hasUnsavedChanges={unsavedChanges}
      />
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        onTabClick={handleTabClick}
        isValidationTriggered={isValidationTriggered}
        errors={errorss}
      />
      <div className="flex-grow">
        {activeTab === "addLegalEntity" && (
          <div className="m-6 border border-gray-200 p-5  rounded-lg">
            <div className="flex flex-col space-y-8 col-span-1 p-6">
              <label className="flex items-center">
                <span className="w-48 font-small text-customGray text-sm">
                  LGE Code<span className="text-red-500 ml-1">*</span>
                </span>
                <span className="ml-4">:</span>
                <input
                  type="text"
                  value={lgeCode}
                  maxLength={30}
                  onChange={handleLgeCodeChange}
                  className={`border font-small text-customGray text-sm ${
                    errors.lgeCode ? "border-red-500" : "border-gray-300"
                  } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
                  required
                  disabled={isViewMode}
                />
              </label>

              <label className="flex items-center">
                <span className="w-48 font-small text-customGray text-sm">
                  LGE Description<span className="text-red-500 ml-1">*</span>
                </span>
                <span className="ml-4">:</span>
                <input
                  type="text"
                  value={lgeDescription}
                  maxLength={30}
                  onChange={(e) => {
                    setLgeDescription(e.target.value);
                    setUnsavedChanges(true);
                    if (errors.lgeDescription) {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        lgeDescription: "",
                      }));
                    }
                  }}
                  className={`border font-small text-customGray text-sm ${
                    errors.lgeDescription ? "border-red-500" : "border-gray-300"
                  } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
                  required
                  disabled={isViewMode}
                />
              </label>

              <div className="flex items-center">
                <label
                  className="w-48 font-small text-customGray text-sm"
                  disabled={isViewMode}
                >
                  Currency Code <span className="text-red-500 ml-1">*</span>
                </label>
                <span className="ml-4">:</span>
                <button
                  onClick={() => setIsCurrencyModalOpen(true)}
                  disabled={isViewMode}
                  className={`border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-left flex items-center justify-between text-customGray ${
                    errors.currencyName
                      ? "border-red-500 hover:border-red-500"
                      : "border-gray-300 hover:border-blue-400"
                  }`}
                >
                  <span className="font-small text-customGray text-sm">
                    {selectedCurrency || "Select Currency Code"}
                  </span>
                  <RxDropdownMenu className="ml-2" />
                </button>

                <ReusableModal
                  title="Select Currency Code"
                  isOpen={isCurrencyModalOpen}
                  onClose={() => {
                    setIsCurrencyModalOpen(false);
                    setSearchQuery("");
                  }}
                  onSave={() => {
                    setIsCurrencyModalOpen(false);
                    setSearchQuery("");
                  }}
                  searchPlaceholder="Search currency..."
                  searchQuery={searchQuery}
                  onSearchChange={handleSearchChange}
                  isViewMode={isViewMode}
                >
                  {currencies.length > 0 ? (
                    currencies
                      .filter(
                        (currency) =>
                          currency.currencyCode &&
                          currency.currencyCode
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase())
                      )
                      .map((currency, index) => (
                        <div
                          key={currency.id}
                          className={`flex items-center ${
                            index % 2 === 0 ? "bg-sky-blue-200" : ""
                          }`}
                        >
                          <input
                            type="radio"
                            name="Currency"
                            value={currency.currencyCode}
                            checked={selectedCurrency === currency.currencyCode}
                            onChange={handleCurrencyCheckBoxChange}
                            className="mr-2 accent-customBlue"
                            disabled={isViewMode}
                          />
                          <label>{currency.currencyCode}</label>
                        </div>
                      ))
                  ) : (
                    <p>Loading Currencies...</p>
                  )}
                </ReusableModal>
              </div>

              <div className="flex items-center">
                <label className="w-48 font-small text-customGray text-sm">
                  Currency Name
                </label>
                <span className="ml-4">:</span>
                <input
                  type="text"
                  value={currencyName}
                  readOnly
                  className="border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none ml-8 font-small text-customGray text-sm"
                />
              </div>
            </div>
          </div>
        )}

        {activeTab === "lgeAddresses" && (
          <div className="m-6 border border-gray-200 p-5  rounded-lg">
            <TableWrapper maxHeight="460px">
              <div className="p-1">
                {/* Building Section */}
                <div className="mb-6 border border-gray-300 p-5 rounded-lg">
                  <h3 className="text-md font-medium text-customGray mb-4">
                    Building
                  </h3>

                  <div className="grid grid-cols-2 gap-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">
                        Door No{" "}
                      </label>
                      <span className="ml-4">:</span>
                      <input
                        type="text"
                        value={doorNo}
                        maxLength={30}
                        onChange={(e) => {
                          setdoorNo(e.target.value);
                          setUnsavedChanges(true);
                        }}
                        disabled={isViewMode}
                        className="border border-gray-300 p-1.5 rounded w-80 h-8  focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray font-small text-customGray text-sm"
                      />
                    </div>
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">
                        Floor No{" "}
                      </label>
                      <span className="ml-4">:</span>
                      <input
                        type="text"
                        value={floorNo}
                        maxLength={30}
                        disabled={isViewMode}
                        onChange={(e) => {
                          setfloorNo(e.target.value);
                          setUnsavedChanges(true);
                        }}
                        placeholder="4"
                        className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm font-small text-customGray text-sm "
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-4 mt-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">
                        Building No{" "}
                      </label>
                      <span className="ml-4">:</span>
                      <input
                        type="text"
                        value={buildingNo}
                        maxLength={30}
                        disabled={isViewMode}
                        onChange={(e) => {
                          setbuildingNo(e.target.value);
                          setUnsavedChanges(true);
                        }}
                        placeholder="2A"
                        className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm font-small text-customGray text-sm"
                      />
                    </div>
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">
                        Building Name{" "}
                      </label>
                      <span className="ml-4">:</span>
                      <input
                        type="text"
                        value={buildingName}
                        maxLength={30}
                        disabled={isViewMode}
                        onChange={(e) => {
                          setbuildingName(e.target.value);
                          setUnsavedChanges(true);
                        }}
                        placeholder="B name"
                        className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm font-small text-customGray text-sm"
                      />
                    </div>
                  </div>

                  <div className="flex items-center mt-4">
                    <label className="w-48 font-small text-customGray text-sm">
                      Landmark
                    </label>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      value={landMark}
                      maxLength={30}
                      onChange={(e) => {
                        setlandMark(e.target.value);
                        setUnsavedChanges(true);
                      }}
                      disabled={isViewMode}
                      placeholder=""
                      className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm"
                    />
                  </div>
                </div>

                {/* Street Section */}
                <div className="mb-6 border border-gray-300 p-5 rounded-lg">
                  <h3 className="text-md font-medium text-customGray  mb-4">
                    Street
                  </h3>

                  <div className="grid grid-cols-2 gap-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">
                        Street No{" "}
                      </label>
                      <span className="ml-4">:</span>
                      <input
                        value={streetNo}
                        maxLength={30}
                        onChange={(e) => {
                          setstreetNo(e.target.value);
                          setUnsavedChanges(true);
                        }}
                        disabled={isViewMode}
                        type="text"
                        placeholder="2/626"
                        className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray"
                      />
                    </div>
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">
                        Street Name
                      </label>
                      <span className="ml-4">:</span>
                      <input
                        type="text"
                        value={streetName}
                        maxLength={30}
                        disabled={isViewMode}
                        onChange={(e) => {
                          setstreetName(e.target.value);
                          setUnsavedChanges(true);
                        }}
                        className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray"
                      />
                      {/* {errors.streetName && (
                    <p className="text-red-500 text-sm ml-4">{errors.streetName}</p>
                  )} */}
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-4 mt-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">
                        Road No{" "}
                      </label>
                      <span className="ml-4">:</span>
                      <input
                        type="text"
                        value={roadNo}
                        maxLength={30}
                        onChange={(e) => {
                          setroadNo(e.target.value);
                          setUnsavedChanges(true);
                        }}
                        disabled={isViewMode}
                        placeholder="2A"
                        className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray"
                      />
                    </div>
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">
                        Road Name{" "}
                      </label>
                      <span className="ml-4">:</span>
                      <input
                        type="text"
                        value={roadName}
                        maxLength={30}
                        onChange={(e) => {
                          setRoadName(e.target.value);
                          setUnsavedChanges(true);
                        }}
                        placeholder="B name"
                        disabled={isViewMode}
                        className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray"
                      />
                    </div>
                  </div>
                </div>

                {/* Area Section */}
                <div className="mb-6 border border-gray-300 p-5 rounded-lg">
                  <h3 className="text-md font-medium text-customGray  mb-4">
                    Area
                  </h3>

                  <div className="grid grid-cols-2 gap-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">
                        Block{" "}
                      </label>
                      <span className="ml-4">:</span>
                      <input
                        type="text"
                        value={areaBlock}
                        maxLength={30}
                        onChange={(e) => {
                          setareaBlock(e.target.value);
                          setUnsavedChanges(true);
                        }}
                        placeholder="2/626"
                        disabled={isViewMode}
                        className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray"
                      />
                    </div>
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">
                        Area Name{" "}
                      </label>
                      <span className="ml-4">:</span>
                      <input
                        type="text"
                        value={areaName}
                        maxLength={30}
                        onChange={(e) => {
                          setareaName(e.target.value);
                          setUnsavedChanges(true);
                        }}
                        placeholder="4"
                        disabled={isViewMode}
                        className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray"
                      />
                    </div>
                  </div>
                </div>
                {/* Display the error message for address section */}
                {errors.addressSection && (
                  <p className="text-red-500 text-sm mb-4">
                    {errors.addressSection}
                  </p>
                )}

                {/* Address Section */}
                <div className="mb-6 border border-gray-300 p-5 rounded-lg">
                  <h3 className="text-md font-medium text-customGray  mb-4">
                    Address
                  </h3>
                  <div className="grid grid-cols-1 gap-4">
                    <div className="flex items-center">
                      <label className="w-56 font-small text-customGray text-sm">
                        Address 1{" "}
                      </label>
                      <span className="ml-4">:</span>
                      <input
                        type="text"
                        value={addressLine1}
                        onChange={(e) => {
                          setaddressLine1(e.target.value);
                          setUnsavedChanges(true);
                        }}
                        placeholder="2/626"
                        maxLength={30}
                        disabled={isViewMode}
                        className="border border-gray-300 p-1.5 rounded w-full h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray"
                      />

                      {/* {errors.addressLine1 && (
                    <p className="text-red-500 text-sm ml-4">
                      {errors.addressLine1}
                    </p>
                  )} */}
                    </div>
                    {/* <div className="flex items-center">
                <label className="w-48 font-small text-customGray text-sm">Address 2 </label>
                <span className="ml-4">:</span>
                <input type="text"  value={addressLine2}  onChange={(e) => setaddressLine2(e.target.value)}
                placeholder="4" className="border border-gray-300 p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm" />
              </div> */}
                  </div>

                  <div className="grid grid-cols-1 gap-4 mt-4">
                    <div className="flex items-center">
                      <label className="w-56 font-small text-customGray text-sm">
                        Address 2{" "}
                      </label>
                      <span className="ml-4">:</span>
                      <input
                        type="text"
                        value={addressLine2}
                        onChange={(e) => {
                          setaddressLine2(e.target.value);
                          setUnsavedChanges(true);
                        }}
                        placeholder="4"
                        maxLength={30}
                        disabled={isViewMode}
                        className="border border-gray-300 p-1.5 rounded w-full h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray"
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-1 gap-4 mt-4">
                    <div className="flex items-center">
                      <label className="w-56 font-small text-customGray text-sm">
                        Address 3{" "}
                      </label>
                      <span className="ml-4">:</span>
                      <input
                        type="text"
                        value={addressLine3}
                        onChange={(e) => {
                          setaddressLine3(e.target.value);
                          setUnsavedChanges(true);
                        }}
                        placeholder="2A"
                        maxLength={30}
                        disabled={isViewMode}
                        className="border border-gray-300 p-1.5 rounded w-full h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray"
                      />
                    </div>
                  </div>
                </div>

                {/* Country Section */}
                <div className="mb-6 border border-gray-300 p-5 rounded-lg">
                  <h3 className="text-md font-medium text-customGray  mb-4">
                    Country
                  </h3>

                  <div className="grid grid-cols-2 gap-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">
                        Country Code{" "}
                        <span className="text-red-500 ml-1">*</span>
                      </label>
                      <span className="ml-4">:</span>
                      <button
                        onClick={() => setIsCountryModalOpen(true)}
                        disabled={isViewMode}
                        className={`border border-gray-300 p-1 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 text-left text-customGray flex items-center justify-between ${
                          errors.countryCode
                            ? "border-red-500 hover:border-red-500"
                            : "border-gray-300 hover:border-blue-400"
                        }`}
                      >
                        <span className="font-small text-customGray text-sm">
                          {countryCode || "Select Country Code"}
                        </span>
                        <RxDropdownMenu className="ml-2" />
                      </button>

                      <ReusableModal
                        title="Select Country Code"
                        isOpen={isCountryModalOpen}
                        onClose={() => {
                          setIsCountryModalOpen(false);
                          setSearchQuery("");
                        }}
                        onSave={() => {
                          setIsCountryModalOpen(false);
                          setSearchQuery("");
                        }}
                        searchPlaceholder="Search country..."
                        searchQuery={searchQuery}
                        onSearchChange={handleSearchChange}
                        isViewMode={isViewMode}
                      >
                        {countries.length > 0 ? (
                          countries
                            .filter((country) =>
                              country.country
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase())
                            )
                            .map((country) => (
                              <div
                                key={country.id}
                                className="flex items-center"
                              >
                                <input
                                  type="radio"
                                  name="country"
                                  value={country.country}
                                  checked={selectedCountry === country.country}
                                  onChange={handleCountryCheckBoxChange}
                                  className="mr-2 accent-customBlue"
                                  disabled={isViewMode}
                                />
                                <label>{country.country}</label>
                              </div>
                            ))
                        ) : (
                          <p>Loading countries...</p>
                        )}
                      </ReusableModal>
                    </div>
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">
                        Country Name
                      </label>
                      <span className="ml-4">:</span>
                      <input
                        type="text"
                        value={countryName}
                        // readOnly
                        disabled={isViewMode}
                        className="border border-gray-300 p-1 rounded w-80 h-8 text-gray-400 focus:outline-none hover:border-blue-400 ml-4 font-small text-customGray text-sm"
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-4 mt-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">
                        State / Province{" "}
                        <span className="text-red-500 ml-1">*</span>
                      </label>
                      <span className="ml-4">:</span>
                      <button
                        onClick={() => setIsStateModalOpen(true)}
                        disabled={isViewMode}
                        className={`border border-gray-300 p-1 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between text-customGray ${
                          errors.state
                            ? "border-red-500 hover:border-red-500"
                            : "border-gray-300 hover:border-blue-400"
                        }`}
                      >
                        <span className="font-small text-customGray text-sm">
                          {state || "Select State / Province"}
                        </span>
                        <RxDropdownMenu className="ml-2" />
                      </button>

                      <ReusableModal
                        title="Select State Code"
                        isOpen={isStateModalOpen}
                        onClose={() => {
                          setIsStateModalOpen(false);
                          setSearchQueryState("");
                        }}
                        onSave={() => {
                          setIsStateModalOpen(false);
                          setSearchQueryState("");
                        }}
                        searchPlaceholder="Search state..."
                        searchQuery={searchQueryState}
                        onSearchChange={handleSearchChangeState}
                        isViewMode={isViewMode}
                      >
                        {filteredStateProvinces.length > 0 ? (
                          filteredStateProvinces
                            .filter(
                              (stateP) =>
                                stateP.State_Name.toLowerCase().includes(
                                  searchQueryState.toLowerCase()
                                ) ||
                                stateP.state
                                  .toLowerCase()
                                  .includes(searchQueryState.toLowerCase())
                            )
                            .map((stateP, index) => (
                              <div
                                key={stateP.id}
                                className={`flex items-center ${
                                  index % 2 === 0 ? "bg-sky-blue-200" : ""
                                }`}
                              >
                                <input
                                  type="radio"
                                  name="country"
                                  value={stateP.state}
                                  checked={selectedState === stateP.state}
                                  onChange={handleCheckboxChangeForState}
                                  className="mr-2 accent-customBlue"
                                  disabled={isViewMode}
                                />
                                <label>{stateP.state}</label>
                              </div>
                            ))
                        ) : (
                          <p>Loading State...</p>
                        )}
                      </ReusableModal>
                    </div>

                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">
                        State / Province Name
                      </label>
                      <span className="ml-4">:</span>
                      <input
                        type="text"
                        value={stateName}
                        disabled={isViewMode}
                        // readOnly

                        className="border border-gray-300 p-1 rounded w-80 h-8 text-gray-400 focus:outline-none hover:border-blue-400 ml-4 font-small text-customGray text-sm"
                      />
                      {/* <select
                  disabled
                    value={state}
                    onChange={(e) => setstate(e.target.value)}
                    className="border border-gray-300 p-1 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4"
                  >
                    <option value="" disabled>Select State / Province</option>
                    {stateProvinces.map((statePName) => (
                      <option key={statePName.id} value={statePName.id}>
                        {statePName.State_Name}
                      </option>
                    ))}
                  </select> */}
                      {/* {errors.countryId && <p className="text-red-500 text-sm ml-4">{errors.countryId}</p>} */}
                    </div>
                  </div>

                  {/* <div className="grid grid-cols-2 gap-4 mt-2">
            </div> */}

                  <div className="grid grid-cols-2 gap-4 mt-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">
                        District
                      </label>
                      <span className="ml-4">:</span>
                      <input
                        type="text"
                        value={district}
                        maxLength={30}
                        onChange={(e) => {
                          setdistrict(e.target.value);
                          setUnsavedChanges(true);
                        }}
                        disabled={isViewMode}
                        placeholder="2A"
                        className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray"
                      />
                    </div>
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">
                        Postal Code
                      </label>
                      <span className="ml-4">:</span>
                      <input
                        type="text"
                        value={postalCode}
                        maxLength={30}
                        onChange={(e) => {
                          setpostalCode(e.target.value);
                          setUnsavedChanges(true);
                        }}
                        disabled={isViewMode}
                        placeholder="B name"
                        className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray"
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-4 mt-4">
                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">
                        Zip Code
                      </label>
                      <span className="ml-4">:</span>
                      <input
                        type="text"
                        value={zipCode}
                        maxLength={30}
                        onChange={(e) => {
                          setzipCode(e.target.value);
                          setUnsavedChanges(true);
                        }}
                        disabled={isViewMode}
                        placeholder="2A"
                        className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray"
                      />
                    </div>

                    <div className="flex items-center">
                      <label className="w-48 font-small text-customGray text-sm">
                        PO Box
                      </label>
                      <span className="ml-4">:</span>
                      <input
                        type="text"
                        value={poBox}
                        maxLength={30}
                        onChange={(e) => {
                          setpoBox(e.target.value);
                          setUnsavedChanges(true);
                        }}
                        disabled={isViewMode}
                        placeholder="B name"
                        className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray"
                      />
                    </div>
                  </div>
                </div>

                {/* <CustomButton onClick={nextToIdentifiers} title={"Next"} className={"mb-10"}/> */}
              </div>
            </TableWrapper>
          </div>
        )}
      </div>
      <Footer>
        {/* Footer */}
        {!isViewMode && (
          <ButtonGroup
            onSave={handleSubmit}
            onSaveContinue={handleSaveAndContinue}
            hasId={!!legalentityId}
          />
        )}
      </Footer>
    </div>
  );
}
