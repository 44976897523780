import RestService from "./RestServices";


const FlowCodeServices={
    getAllFlowCode: async (path)=>{
        return RestService.GetAllData(path);
    },
    addFlowCode: async(path,data)=>{
        try{
            console.log('sending data:',data);
            const response = RestService.CreateData(path,data);
            console.log('Response from Server :', response);
            return response;
        }catch (error){
            console.error('Error Updating users:', error);
            return { error:true ,message:"Error updating users"};
        }
    }
}

export  default FlowCodeServices;