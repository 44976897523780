import React, { useState } from 'react';

export default function PopupScreenn() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const Popup = ({ title, onClose }) => {
    return (
      <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center z-50">
        <div className=" ml-16 bg-white p-6 rounded-lg shadow-lg w-3/4 max-w-3xl h-half relative">
        <button
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
          onClick={onClose}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
          <h2 className="text-xl font-semibold mb-4 ml-72 font-md text-md text-blue-600">{title}</h2>
          <div>
          <div className="p-2">
            <div className="flex flex-col   gap-y-4 ml-8 ml-">
           
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                One Time Password
              </span>
              <span className="ml-28">:</span>
              <input
                type="text"
              
                className="border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
              />
             
            </label>
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
              Enter New Passoword
              </span>
              <span className="ml-28">:</span>
              <input
                type="text"
              
                className="border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
              />
             
            </label>
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
              Confirm new Password
              </span>
              <span className="ml-28">:</span>
              <input
                type="text"
              
                className="border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
              />
             
            </label>
            <label className="flex items-center">
            <input
                type="text"
              placeholder='Security Question 1'
              className="border border-gray-600 p-2 pl-4 rounded w-72 h-8  mr-4  "              />
              <input
                type="text"
              
                className="border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-5 peer placeholder-transparent peer-focus:placeholder-opacity-0"
              />
             
            </label>
            <label className="flex items-center">
            <input
                type="option"
              placeholder='Security Question 2'
              className="border border-gray-600 p-2 pl-4 rounded w-72 h-8  mr-4  "              />
              
              <input
                type="text"
              
                className="border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-5 peer placeholder-transparent peer-focus:placeholder-opacity-0"
              />
             
            </label>
            <label className="flex items-center">
            <input
                type="text"
              placeholder='Security Question 3'
                className="border border-gray-600 p-2 pl-4 rounded w-72 h-8  mr-4  "
              />
              <input
                type="text"
              
                className="border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-5 peer placeholder-transparent peer-focus:placeholder-opacity-0"
              />
             
            </label>
           
            <div className="flex flex-row mt-2 ml-60">
          
          <button
         
            className=" mt-4  border border-blue-500 text-sm text-white py-1 px-2   rounded hover:bg-blue-100 transition duration-200 ease-in-out bg-blue-500 "
          >
            Change Password
          </button>
        </div>

          
          </div>
          </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="p-6">
      <button
        className="bg-blue-500 text-white px-4 py-2 rounded"
        onClick={handleOpenPopup}
      >
        Open hiii
      </button>

      {isPopupOpen && (
        <Popup
          title="Popup Title"
          
          onClose={handleClosePopup}
        />
      )}
    </div>
  );
}
