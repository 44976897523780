import RestService from "./RestServices";

const CalendarServices = {
  getAllCalendar: async (path) => {
    try {
      const response = await RestService.GetAllData(path);
      return response;
    } catch (error) {
      console.error("Error fetching Calendar data:", error);
      return { error: true, message: "Error fetching Calendar data" };
    }
  },
  getCalendarById: (id) => {
    return RestService.GetByIdData(`/get-calender-id`, id);
  },
  deleteCalendar:(id)=>{
return RestService.DeleteData('/delete-calendar',id);
  },
  getAllCalendarwithPage: (pageSize, page, searchQuery = "") => {
    const searchParam = searchQuery ? `&searchQuery=${encodeURIComponent(searchQuery)}` : "";
    return RestService.GetAllData(
      `/get-calendar-page?limit=${pageSize}&offset=${(page - 1) * pageSize}${searchParam}`
    );
  },
  addCalendar: async (path, data) => {
    try {
      console.log("Sending data:", data);
      const response = await RestService.CreateData(path, data);
      console.log("Response from Server:", response);
      return response;
    } catch (error) {
      console.error("Error adding Calendar:", error);
      return { error: true, message: "Error adding Calendar" };
    }
  },
  checkDuplicateCalendarcode: async (path, roleData) => {
    try {
      const response = await RestService.CreateData(path, roleData);
      console.log("Response from server:", response);

      return response;
    } catch (error) {
      console.error("Error fetching Calendar code:", error);
      return { error: true, message: "Error fetching Calendar code" };
    }
  },
};

export default CalendarServices;
