import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { CiViewTable } from "react-icons/ci";
import { BsFileEarmarkBarGraph } from "react-icons/bs";
import { LuUpload } from "react-icons/lu";
import { TbAtom2 } from "react-icons/tb";
import { TbDatabaseExport } from "react-icons/tb";
import { RiFolderShield2Line } from "react-icons/ri";
import { GoArrowSwitch } from "react-icons/go";
import { SlGlobe } from "react-icons/sl";
import { HiOutlineWrench } from "react-icons/hi2";

import MainHeader from "../../../components/MainHeader";
import Dashboard from "../../../components/Dashboard";

export default function BankStatement() {
  const [subMenus, setSubMenus] = useState([]);
  const [moduleAssignments, setModuleAssignments] = useState([]);
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {

    // Fetch user ID from session storage
    const fetchUserId = () => {
      const userDetailString = sessionStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        setUserId(userDetail.id || null);
      }
    };

    fetchUserId();

    // Fetch sub-menu data
    axios.get(`${process.env.REACT_APP_BASE_URL}/get-sub-menu`)
      .then((response) => {
        const filteredMenus = response.data.data.filter(menu => menu.mainMenuId === 5);
        setSubMenus(filteredMenus);
      })
      .catch((error) => console.error("Error fetching sub-menus:", error));

    // Fetch module assignment data
    axios.get(`${process.env.REACT_APP_BASE_URL}/get-module-assignment`)
      .then((response) => {
        // Filter module assignments by userId
        const filteredAssignments = response.data.filter(
          (assignment) =>
            assignment.userId === String(userId) && assignment.isChecked === true
        );
        setModuleAssignments(filteredAssignments);
      })
      .catch((error) => console.error("Error fetching module assignments:", error));
  }, [userId]);

  const handleCardClick = (route) => {
    navigate(route);
  };

  // Filter subMenus based on moduleAssignments
  const filteredSections = subMenus.filter((menu) => {
    return moduleAssignments.some(
      (assignment) => assignment.subMenuId === menu.id && assignment.isChecked
    );
  });

  // Define an order for submenus to control their display order
  const submenuOrder = ["Setups", "MT940", "Portal", "Reports"];

  // Sort the filteredSections by the defined submenuOrder
  const orderedSections = filteredSections.sort((a, b) => {
    return submenuOrder.indexOf(a.name) - submenuOrder.indexOf(b.name);
  });

  const sections = orderedSections.map((menu) => {
    let cards;

    if (menu.name === "Setups") {
      cards = [
        {
          title: "Statement Controls",
          icon: <HiOutlineWrench />,
          onClick: () => handleCardClick("/cashscreen"),
        },
        {
          title: "Statement Format",
          icon: <CiViewTable />,
          onClick: () => handleCardClick("/statement-formatt"),
        },
      ];
    } else if (menu.name === "MT940") {
      cards = [
        {
          title: "Statement identifier",
          icon: <BsFileEarmarkBarGraph />,
          onClick: () => handleCardClick("/statement-identifire"),
        },
        {
          title: "MT940 Uploads",
          icon: <LuUpload />,
          onClick: () => handleCardClick("/mt940-add"),
        },
        {
          title: "BTC",
          icon: <SlGlobe />,
          onClick: () => handleCardClick("/btc"),
        },
        {
          title: "Statement Analyzer",
          icon: <TbAtom2 />,
          onClick: () => handleCardClick("/statement-analyser"),
        },
        {
          title: "Statement Analyzer New",
          icon: <TbAtom2 />,
          onClick: () => handleCardClick("/statement-analyser-bs"),
        },
        {
          title: "Transfer To BS",
          icon: <TbDatabaseExport />,
          onClick: () => handleCardClick("/transactions"),
        },
      ];
    } else if (menu.name === "Portal") {
      cards = [
        {
          title: "Uploads",
          icon: <LuUpload />,
          onClick: () => handleCardClick("/negative-balances"),
        },
      ];
    }else if (menu.name === "Reports") {
      cards = [
        {
          title: "Statement Changes",
          icon: <GoArrowSwitch />,
          onClick: () => handleCardClick("/negative-balances"),
        },
        {
          title: "Statement Tracker",
          icon: <RiFolderShield2Line />,
          onClick: () => handleCardClick("/negative-balances"),
        },
      ];
    } else {
      // Optionally handle other cases or default cards
      cards = [];
    }

    return {
      title: menu.name,
      subMenuId: menu.id,
      cards: cards,
    };
  });
    
  // const sections = [
  //   {
  //     title: "Setups",
  //     cards: [
  //       {
  //         title: "Statement Controls",
  //         icon: <HiOutlineWrench />,
  //         onClick: () => handleCardClick("/cashscreen"),
  //       },
  //       {
  //         title: "Statement Format",
  //         icon: <CiViewTable />,
  //         onClick: () => handleCardClick("/statement-formatt"),
  //       },
  //     ],
  //   },
  //   {
  //     title: "MT940",
  //     cards: [
  //       {
  //         title: "Statement identifier",
  //         icon: <BsFileEarmarkBarGraph />,
  //         onClick: () => handleCardClick("/statement-identifire"),
  //       },
  //       {
  //         title: "MT940 Uploads",
  //         icon: <LuUpload />,
  //         onClick: () => handleCardClick("/mt940-add"),
  //       },
  //       {
  //         title: "BTC",
  //         icon: <SlGlobe />,
  //         onClick: () => handleCardClick("/btc"),
  //       },
  //       {
  //         title: "Statement Analyzer",
  //         icon: <TbAtom2 />,
  //         onClick: () => handleCardClick("/statement-analyser"),
  //       },
  //       {
  //         title: "Statement Analyzer New",
  //         icon: <TbAtom2 />,
  //         onClick: () => handleCardClick("/statement-analyser-bs"),
  //       },
  //       {
  //         title: "Transfer To BS",
  //         icon: <TbDatabaseExport />,
  //         onClick: () => handleCardClick("/transactions"),
  //       },
  //     ],
  //   },
  //   {
  //     title: "Portal",
  //     cards: [
  //       {
  //         title: "Uploads",
  //         icon: <LuUpload />,
  //         onClick: () => handleCardClick("/negative-balances"),
  //       },
  //     ],
  //   },
  //   {
  //     title: "Reports",
  //     cards: [
  //       {
  //         title: "Statement Changes",
  //         icon: <GoArrowSwitch />,
  //         onClick: () => handleCardClick("/negative-balances"),
  //       },
  //       {
  //         title: "Statement Tracker",
  //         icon: <RiFolderShield2Line />,
  //         onClick: () => handleCardClick("/negative-balances"),
  //       },
  //     ],
  //   },
  // ];
  
  return (
    <>
      <div>
        <MainHeader title={"BankStatement"} />
        <Dashboard sections={sections} />
      </div>
    </>
  );
}
