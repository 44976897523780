import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CashFlowbuttonsConfigs } from "../../../CashFlow/Components/CashFlowbuttonsConfig";
import NoActionLayout from "../../../../../components/NoActionLayout";
import CashFlowActions from "../../../CashFlow/Components/CashFlowActions";
import StatementFormatServices from "../../../../../rest-services/StatementFormatServices";

export default function StatementFormat() {
  const [statementFormat, setStatementFormat] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  const buttons = CashFlowbuttonsConfigs.screen1.map((button) => ({
    ...button,
    // onClick: button.icon === IoAddCircleOutline
    //   ? () => handleNavigate("/flow-code-add") // Navigate to the desired screen
    //   : button.onClick // Use the existing onClick handler for other buttons
  }));
  useEffect(() => {
    fetchdata();
  }, []);
  const fetchdata = async () => {
    setLoading(true);
    try {
      const response = await StatementFormatServices.getAllStatementFormat(
        "/get-statement-format"
      );
      setStatementFormat(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      console.error("Error fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  return (
    <div>
      <div>
        <NoActionLayout title="Bank Statement > Setups > Statement format" />
      </div>
      <div>
        <CashFlowActions buttons={buttons} />
      </div>
      <div className="p-8">
        <div className="overflow-x-auto" style={{ maxWidth: "1200px" }}>
          <div className="overflow-y-auto" style={{ maxHeight: "600px" }}>
            <table className="table-fixed min-w-[800px] bg-white">
              <thead className="bg-sky-500 text-primary-foreground text-left text-sm font-medium sticky top-0">
                <tr className="bg-primary">
                  <th className="py-2 px-4 w-[200px] text-left text-white font-medium"></th>

                  <th className="py-2 px-4 w-[200px] text-left text-white font-medium">
                    Statement Type
                  </th>
                  <th className="py-2 px-4 w-[200px] text-left text-white font-medium">
                    Statement Identifier
                  </th>
                  <th className="py-2 px-4 w-[200px] text-left text-white font-medium">
                    Statement Format
                  </th>
                  <th className="py-2 px-4 w-[200px] text-left text-white font-medium">
                    Format Description
                  </th>
                  <th className="py-2 px-4 w-[200px] text-left text-white font-medium">
                    Country
                  </th>
                  <th className="py-2 px-4 w-[200px] text-left text-white font-medium">
                    Created by
                  </th>
                  <th className="py-2 px-4 w-[200px] text-left text-white font-medium">
                    Created date
                  </th>
                  <th className="py-2 px-4 w-[200px] text-left text-white font-medium">
                    Created time
                  </th>
                  <th className="py-2 px-4 w-[200px] text-left text-white font-medium">
                    Changed by
                  </th>
                  <th className="py-2 px-4 w-[200px] text-left text-white font-medium">
                    Changed date
                  </th>
                  <th className="py-2 px-4 w-[200px] text-left text-white font-medium">
                    Changed time
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm">
                {Array.isArray(statementFormat) &&
                statementFormat.length > 0 ? (
                  statementFormat.map((data, index) => (
                    <tr
                      key={index}
                      className="border-b"
                      style={{
                        backgroundColor: index % 2 === 0 ? "white" : "#f3f4f6",
                      }}
                    >
                      <td className="py-2 px-4 text-center">
                        <input type="checkbox" className="form-checkbox" />
                      </td>
                      <td className="py-2 px-4 text-center">
                        {data.statement_type}
                      </td>
                      <td className="py-2 px-4 text-center">
                        {data.statement_identifier}
                      </td>
                      <td className="py-2 px-4 text-center">
                        {data.statement_format}
                      </td>
                      <td className="py-2 px-4 text-center">
                        {data.format_description}
                      </td>
                      <td className="py-2 px-4 text-center">{data.country}</td>
                      <td className="py-2 px-4 text-center">
                        {data.created_by}
                      </td>
                      <td className="py-2 px-4 text-center">
                        {data.changedDate
                          ? new Date(data.changedDate).toLocaleDateString()
                          : "N/A"}
                      </td>
                      <td className="py-2 px-4 text-center">
                        {data.creationTime
                          ? new Date(data.creationTime).toLocaleTimeString()
                          : "N/A"}
                      </td>
                      <td className="py-2 px-4 text-center">
                        {data.changed_by}
                      </td>
                      <td className="py-2 px-4 text-center">
                        {data.changedDate
                          ? new Date(data.changedDate).toLocaleDateString()
                          : "N/A"}
                      </td>
                      <td className="py-2 px-4 text-center">
                        {data.changedTime
                          ? new Date(data.changedTime).toLocaleTimeString()
                          : "N/A"}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="py-2 px-4 text-center">
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
