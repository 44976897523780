import React from 'react';
import Footer from '../../../../../../components/footer';

const BankAddressContent = () => {
  return (
    <div className="p-4 mb-60">
      <div className="mb-6 border border-gray-300 p-5 mx-10 rounded-lg">
        <h3 className="text-lg font-semibold text-gray-500 mb-4">Building</h3>

        <div className="grid grid-cols-2 gap-4">
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">Door No </label>
            <span className="ml-4">:</span>
            <input
              type="text"
              className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
            />
          </div>
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">Floor No </label>
            <span className="ml-4">:</span>
            <input
              type="text"
              placeholder="4"
              className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
            />
          </div>
        </div>


        <div className="grid grid-cols-2 gap-4 mt-4">
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">Building No </label>
            <span className="ml-4">:</span>
            <input
              type="text"
              placeholder="2A"
              className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
            />
          </div>
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">Building Name </label>
            <span className="ml-4">:</span>
            <input
              type="text"
              placeholder="2A"
              className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
            />
          </div>
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">Landmark </label>
            <span className="ml-4">:</span>
            <input
              type="text"
              placeholder="2A"
              className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
            />
          </div>
        </div>
      </div>
      {/** */}
      <div className="mb-6 border border-gray-300 p-5 mx-10 rounded-lg">
        <h3 className="text-lg font-semibold text-gray-500 mb-4">Street</h3>

        <div className="grid grid-cols-2 gap-4">
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">Street No </label>
            <span className="ml-4">:</span>
            <input
              type="text"
              className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
            />
          </div>
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">Street Name </label>
            <span className="ml-4">:</span>
            <input
              type="text"
              placeholder="4"
              className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
            />
          </div>
        </div>


        <div className="grid grid-cols-2 gap-4 mt-4">
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">Road No </label>
            <span className="ml-4">:</span>
            <input
              type="text"
              placeholder="2A"
              className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
            />
          </div>
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">Road Name </label>
            <span className="ml-4">:</span>
            <input
              type="text"
              placeholder="2A"
              className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
            />
          </div>
        </div>
      </div>
      <div className="mb-6 border border-gray-300 p-5 mx-10 rounded-lg">
        <h3 className="text-lg font-semibold text-gray-500 mb-4">Area</h3>

        <div className="grid grid-cols-2 gap-4">
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">Block </label>
            <span className="ml-4">:</span>
            <input
              type="text"
              className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
            />
          </div>
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">Area Name </label>
            <span className="ml-4">:</span>
            <input
              type="text"
              placeholder="4"
              className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
            />
          </div>
        </div>
      </div>
      <div className="mb-6 border border-gray-300 p-5 mx-10 rounded-lg">
        <h3 className="text-lg font-semibold text-gray-500 mb-4">Address</h3>

        <div className="grid grid-cols-1 gap-4">
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">Address 1 </label>
            <span className="ml-4">:</span>
            <input
              type="text"
              className="border border-gray-300 p-1.5 rounded 2xl:w-[72%] w-full h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
            />
          </div>
        </div>


        <div className="grid grid-cols-1 gap-4 mt-4">
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">Address 2 </label>
            <span className="ml-4">:</span>
            <input
              type="text"
              placeholder="2A"
              className="border border-gray-300 p-1.5 rounded 2xl:w-[72%] w-full h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
            />
          </div>
        </div>
        
        <div className="grid grid-cols-1 gap-4 mt-4">
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">Address 3 </label>
            <span className="ml-4">:</span>
            <input
              type="text"
              placeholder="2A"
              className="border border-gray-300 p-1.5 rounded 2xl:w-[72%] w-full h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
            />
          </div>
        </div>
      </div>
      
      <div className="mb-6 border border-gray-300 p-5 mx-10 rounded-lg">
        <h3 className="text-lg font-semibold text-gray-500 mb-4">Country</h3>

        <div className="grid grid-cols-2 gap-4">
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">Country Code <span className='text-red-500'>*</span></label>
          <span className="ml-4">:</span>
          <select 
            placeholder="2A" 
            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          />
        </div>
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">Country Name </label>
            <span className="ml-4">:</span>
            <input
              type="text"
              placeholder="4"
              className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
            />
          </div>
        </div>


        <div className="grid grid-cols-2 gap-4 mt-4">
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">State / Province<span className='text-red-500'>*</span> </label>
          <span className="ml-4">:</span>
          <select 
            placeholder="2A" 
            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          />
        </div>
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">State / Province Name </label>
            <span className="ml-4">:</span>
            <input
              type="text"
              placeholder="2A"
              className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 mt-4">
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">District </label>
            <span className="ml-4">:</span>
            <input
              type="text"
              placeholder="2A"
              className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
            />
          </div>
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">Postal Code </label>
            <span className="ml-4">:</span>
            <input
              type="text"
              placeholder="2A"
              className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 mt-4">
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">Zip Code </label>
            <span className="ml-4">:</span>
            <input
              type="text"
              placeholder="2A"
              className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
            />
          </div>
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">PO Box </label>
            <span className="ml-4">:</span>
            <input
              type="text"
              placeholder="2A"
              className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
            />
          </div>
        </div>
        
      </div>
        <button
          className="bg-customBlue text-sm text-white py-1 px-2 ml-10 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 "
        >
          Next 
        </button>
        <Footer>
        <button
          className="bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ml-7"
        >
          Save
        </button>
        <button
          className="ml-4 border border-blue-500 text-sm text-blue-500 py-1 px-2 rounded hover:bg-blue-100 transition duration-200 ease-in-out w-30"
        >
          Save & Continue
        </button>
        </Footer>
    </div>
  );
};

export default BankAddressContent;
