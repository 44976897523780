import React, { useState } from 'react';
import NoActionLayout from '../../../../components/NoActionLayout';
import Hdfc from '../../../../assets/Hdfc.jpeg';
import { BiPlus, BiMinus } from 'react-icons/bi';
// import Actions from '../../../../components/Actions';
import AccountPortalTable from '../Tables/CashInBank/AccountPortalTable';
import CashBalanceActions from '../Props/CashBalanceActions';
import { buttonConfigs } from '../Props/buttonConfig';

export default function AccountPortal() {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleLayout = () => {
    setIsExpanded(!isExpanded);
  };

  const buttons = buttonConfigs.screen1;

  return (
    <div>
      <div>
        <NoActionLayout title="Cash Balance > Cash in Bank > Account Portal" />
      </div>
      <div className='p-2'>
        <div className="p-4 rounded-md bg-blue-50">
          <div className="flex flex-col space-y-4">
            <div className={`grid ${isExpanded ? 'grid-cols-1' : 'grid-cols-3'} gap-4`}>
              {!isExpanded && (
                <>
                  <div className="flex flex-col space-y-2">
                    <label className="flex items-center">
                      <span className="w-36">CompanyCode</span> <span className='mr-2'>:</span> 
                      <input className="border p-1 flex-grow rounded" />
                    </label>
                    <label className="flex items-center">
                      <span className="w-36">BankID</span> <span className='mr-2'>:</span> 
                      <input className="border p-1 flex-grow rounded" />
                    </label>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <label className="flex items-center">
                      <span className="w-36">BankAccountID</span> <span className='mr-2'>:</span> 
                      <input className="border p-1 flex-grow rounded" />
                    </label>
                    <label className="flex items-center">
                      <span className="w-36">DateRange</span> <span className='mr-2'>:</span> 
                      <input className="border p-1 w-28 ml-auto rounded" /> 
                      <input className="border p-1 w-28 ml-2 rounded" />
                    </label>
                  </div>
                </>
              )}
              <div className="flex justify-end items-start">
                <button onClick={toggleLayout} className="flex items-center space-x-1 text-blue-500 p-1 mr-8  border">
                  {isExpanded ? <BiMinus size={20} /> : <BiPlus size={20} />}
                </button>
              </div>
            </div>

            {isExpanded && (
              <div className="grid grid-cols-3 gap-4">
                <div className="flex flex-col space-y-2 col-span-1">
                  <label className="flex items-center">
                    <span className="w-36">Company Code</span> <span className='mr-2'>:</span> 
                    <input className="border p-1 flex-grow ml-auto rounded" />
                  </label>
                  <label className="flex items-center">
                    <span className="w-36">Bank ID</span> <span className='mr-2'>:</span> 
                    <input className="border p-1 flex-grow ml-auto rounded" />
                  </label>
                  <label className="flex items-center">
                    <span className="w-36">Bank Account ID</span> <span className='mr-2'>:</span> 
                    <input className="border p-1 flex-grow ml-auto rounded" />
                  </label>
                  <label className="flex items-center">
                    <span className="w-36">Date Range</span> <span className='mr-2'>:</span> 
                    <input className="border p-1 w-28 ml-auto rounded" /> 
                    <input className="border p-1 w-28 ml-2 rounded" />
                  </label>
                </div>
                <div className="flex flex-col space-y-2 col-span-1 p-0 gap-3 ml-5">
                  <p>Company name <span className='ml-20'>: </span>TATA Motors</p>
                  <p>Bank name<span className='ml-32'>: </span> TATA Motors</p>
                  <p>Bank Account Description<span className='ml-4'>: </span> TATA Motors</p>
                  <p>Account No<span className='ml-32'>: </span>TATA Motors</p>
                  <p>Swift Code<span className='ml-36'>: </span>TATA Motors</p>
                  <p>Country code Type<span className='ml-20'>: </span> TATA Motors</p>
                </div>
                <div className="flex flex-col items-center col-span-1">
                  <img src={Hdfc} alt="Hdfc" className="h-32 w-36 border mt-20" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <CashBalanceActions buttons={buttons} />
        <AccountPortalTable />
      </div>
    </div>
  );
}
