import React, { useState } from "react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { MdMergeType } from "react-icons/md";
import { IoIosGitMerge } from "react-icons/io";
import { IoIdCardOutline } from "react-icons/io5";
import { HiOutlineCash } from "react-icons/hi";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { FaRegAddressCard } from "react-icons/fa";
import { CiBank } from "react-icons/ci";
import { PiUserList } from "react-icons/pi";

import { useNavigate } from "react-router-dom";
import Card from "../../../components/Card";
import { FaBalanceScaleLeft } from "react-icons/fa";
import { GiBanknote } from "react-icons/gi";
import { LuMinimize2 } from "react-icons/lu";
import MainHeader from "../../../components/MainHeader";

export default function CashBalance() {
  const [CashInBankDataOpen, setCashInBankDataOpen] = useState(true);
  const [ExceptionalDataOpen, setExceptionalDataOpen] = useState(true);
  const [CashInHandOpen, setCashInHandataOpen] = useState(true);
  const navigate = useNavigate();
  const togglebankData = () => {
    setCashInBankDataOpen(!CashInBankDataOpen);
  };

  const ToggleExceptionaldata = () => {
    setExceptionalDataOpen(!ExceptionalDataOpen);
  };

  const handleCardClick = (route) => {
    navigate(route);
  };
  const ToggleCashInHand = () => {
    setCashInHandataOpen(!CashInHandOpen);
  };

  return (
    <>
      <div>
        <MainHeader title={"Cash Balance"} />
        <section>
          <div className="mt-2 ml-2 ">
            <div className="bg-muted  rounded-lg mb-12">
              <div
                className="flex justify-between cursor-pointer border bg-sky-50 w-full  px-4 py-1"
                onClick={ToggleCashInHand}
              >
                <h2 className="text-left text-md font-medium  text-muted-foreground text-muted-foreground">
                  Cash in Hand
                </h2>
                <span className="p-1">
                  {CashInHandOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </span>
              </div>
              <div
                className={`overflow-hidden transition-opacity duration-900 ${
                  CashInHandOpen ? "opacity-100 max-h-96" : "opacity-0 max-h-0"
                }`}
              >
                <div className="my-2 -mt-4">
                  {CashInHandOpen && (
                    <div className="mt-4 flex flex-wrap gap-6  p-3">
                      <Card
                        title="Cash"
                        icon={<HiOutlineCash />}
                        onClick={() => handleCardClick("/cashscreen")}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="-mt-12 ml-2">
            <div className="bg-muted  rounded-lg mb-16">
              <div
                className="flex justify-between cursor-pointer border bg-sky-50 w-full  px-4 py-1"
                onClick={togglebankData}
              >
                <h2 className="text-left text-md font-medium  text-muted-foreground text-muted-foreground">
                  Cash in Bank
                </h2>
                <span className="p-1">
                  {CashInBankDataOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </span>
              </div>
              <div
                className={`overflow-hidden transition-opacity duration-900 ${
                  CashInBankDataOpen
                    ? "opacity-100 max-h-96"
                    : "opacity-0 max-h-0"
                }`}
              >
                <div className="my-2 -mt-4 mb-6">
                  {CashInBankDataOpen && (
                    <div className="mt-4 flex flex-wrap gap-6  mt-1  p-3">
                      <Card
                        title="Account Portal"
                        icon={<HiOutlineBuildingOffice2 />}
                        onClick={() => handleCardClick("/account-portal")}
                      />
                      <Card
                        title="Company Wise"
                        icon={<IoIosGitMerge />}
                        onClick={() => handleCardClick("/company-wise")}
                      />
                      <Card
                        title="Bank Wise"
                        icon={<IoIdCardOutline />}
                        onClick={() => handleCardClick("/bank-wise")}
                      />
                      <Card
                        title="Country Wise"
                        icon={<CiBank />}
                        onClick={() => handleCardClick("/country-wise")}
                      />
                      <Card
                        title="Transactions"
                        icon={<PiUserList />}
                        onClick={() => handleCardClick("/transactions")}
                      />
                      <Card
                        title="OD Balances"
                        icon={<FaRegAddressCard />}
                        onClick={() => handleCardClick("/od-balances")}
                      />
                      <Card
                        title="Currency Wise"
                        icon={<IoIosGitMerge />}
                        onClick={() => handleCardClick("/currency-wise")}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className=" -mt-20">
            {" "}
            {/* Added margin top to separate sections */}
            <div className="bg-muted  rounded-lg mb-4 ml-2">
              <div
                className="flex justify-between cursor-pointer border bg-sky-50 w-full  px-4 py-1"
                onClick={ToggleExceptionaldata}
              >
                <h2 className=" text-left text-md font-medium  text-muted-foreground">
                  Exceptional
                </h2>
                <span className="p-1">
                  {ExceptionalDataOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </span>
              </div>
              <div
                className={`overflow-hidden transition-opacity duration-900 ${
                  ExceptionalDataOpen
                    ? "opacity-100 max-h-96"
                    : "opacity-0 max-h-0"
                }`}
              >
                <div className="my-2 -mt-4">
                  {ExceptionalDataOpen && (
                    <div className="mt-4 flex flex-wrap gap-6  mt-1  p-3">
                      <Card
                        title="Negative Balances"
                        icon={<FaBalanceScaleLeft />}
                        onClick={() => handleCardClick("/negative-balances")}
                      />
                      <Card
                        title="Dormant"
                        icon={<MdMergeType />}
                        onClick={() => handleCardClick("/dormant")}
                      />
                      <Card
                        title="Minimum"
                        icon={<LuMinimize2 />}
                        onClick={() => handleCardClick("/minimum")}
                      />
                      <Card
                        title="BS-Yet to"
                        icon={<GiBanknote />}
                        onClick={() => handleCardClick("/bs-yetto")}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
