import React from 'react';

export default function CashTable() {
  return (
    <div className='p-4'>
      {/* Set the container to be scrollable */}
      <div className="overflow-x-auto max-w-full"> {/* Adjust max-w-full to control visible width */}
        <table className="table-fixed min-w-[800px] bg-white"> {/* Ensure table is wider than the container */}
          <thead className="bg-sky-500 text-primary-foreground text-sm">
            <tr>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium"></th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Transactions</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Reference</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Description</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Currency</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Debit</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Credit</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Cumulative Balance</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Nt Code</th>
              <th className="py-2 px-4 w-[200px] text-left text-white font-medium">Nt Code Description</th>
            </tr>
          </thead>
          <tbody className='text-sm'>
            {Array(50).fill().map((_, index) => (
              <tr key={index} className="border-b" style={{ backgroundColor: index % 2 === 0 ? 'white' : '#f3f4f6' }}>
                <td className="py-2 px-4">
                  <input type="checkbox" className="form-checkbox" />
                </td>
                <td className="py-2 px-4">Transactions</td>
                <td className="py-2 px-4">Reference</td>
                <td className="py-2 px-4">Description</td>
                <td className="py-2 px-4">Currency</td>
                <td className="py-2 px-4">Debit</td>
                <td className="py-2 px-4">Credit</td>
                <td className="py-2 px-4">Cumulative Balance</td>
                <td className="py-2 px-4">Nt Code</td>
                <td className="py-2 px-4">Nt Code Description</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
