import React from 'react';

const SearchInput = ({ searchQuery, setSearchQuery, placeholder }) => {
  return (
    <input
      type="text"
      placeholder={placeholder}
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      className="p-2 border border-gray-300 rounded-lg w-48 h-8 mr-2 mt-0.5 placeholder-gray-500 hover hover:border-customBlue focus:outline-none font-small text-sm text-customGray "
    />
  );
};

export default SearchInput;
