import RestService from "./RestServices";

const BankIdServices = {
  getAllBankId: async (path) => {
    try {
      const response = await RestService.GetAllData(path);
      return response;
    } catch (error) {
      console.error("Error fetching BankId data:", error);
      return { error: true, message: "Error fetching BankId data" };
    }
  },
  getBankIdById: (id) => {
    return RestService.GetByIdData(`/get-bankid`, id);
  },
  deleteBankId:(id)=>{
return RestService.DeleteData('/delete-bankid',id);
  },
  addBankId: async (path, data) => {
    try {
      console.log("Sending data:", data);
      const response = await RestService.CreateData(path, data);
      console.log("Response from Server:", response);
      return response;
    } catch (error) {
      console.error("Error adding BankId:", error);
      return { error: true, message: "Error adding BankId" };
    }
  },
  getFilterBankid: async (path, data) => {
    try {
      console.log("Sending data:", data);
      const response = await RestService.CreateData(path, data);
      console.log("Response from Server:", response);
      return response;
    } catch (error) {
      console.error("Error getting filter bank id:", error);
      return { error: true, message: "Error getting filter bank id" };
    }
  },
  getAllbankidwithPage: (pageSize, page, searchQuery = "") => {
    const searchParam = searchQuery ? `&searchQuery=${encodeURIComponent(searchQuery)}` : "";
    return RestService.GetAllData(
      `/getallbankidbypage?limit=${pageSize}&offset=${(page - 1) * pageSize}${searchParam}`
    );
  },
  checkDuplicateBankid: async (path, roleData) => {
    try {
      const response = await RestService.CreateData(path, roleData);
      console.log("Response from server:", response);

      return response;
    } catch (error) {
      console.error("Error fetching bankid:", error);
      return { error: true, message: "Error fetching bankid " };
    }
  },
  saveapproved: (path, data) => {
    try {
      console.log("Sending data:", data);
      const response = RestService.CreateData(path, data);
      console.log("Response from server:", response);
      return response;
    } catch (error) {
      console.error("Error updating users:", error);
      return { error: true, message: "Error updating users" };
    }
  },
  getAudittrail: (path, data) => {
    try {
      const response = RestService.CreateData(path, data);
      return response;
    } catch (error) {
      console.error("Error getting audittrail:", error);
      return { error: true, message: "Error getting audittrail" };
    }
  },
};

export default BankIdServices;
