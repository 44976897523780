// components/NoActionLayout.js
import React from 'react';
import Header from './Header';
import Actions from './Actions';

const NoActionLayout = ({ title, onAddCountry }) => {
  return (
    <div className="flex overflow-hidden">
      <div className="flex-1 bg-gray-50 dark:bg-zinc-800 overflow-auto">
        <Header title={title} />
      </div>
      
    </div>
  );
};

export default NoActionLayout;
