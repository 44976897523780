import React from 'react';

export default function HoverButton({ onClick, title, className }) {
  return (
    <button
      onClick={onClick}
      className={`bg-white text-sm border border-customBlue text-customBlue py-1 px-2 rounded hover:bg-customBlue hover:text-white transition duration-200 ease-in-out ${className}`}
    >
      {title}
    </button>
  );
}
