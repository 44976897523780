import React from 'react';
import { IoMdCheckmark, IoMdClose, IoMdCreate, IoMdTrash } from 'react-icons/io';
import { VscVmActive } from "react-icons/vsc";
const PrimaryActions = ({ buttons = [], extraButtons = [],selectedCount, isExtraButtonsVisible = false }) => {
  const getIconColor = (icon, disabled) => {
    if (disabled)  return 'text-gray-300';

    switch (icon) {
      case IoMdCheckmark:
        return 'text-green-500';
      case IoMdClose:
        return 'text-red-500';
        case IoMdTrash:
          return 'text-red-500';
          case VscVmActive:
          return  'text-green-500';
      default:
        return 'text-sky-900';
    }
  };

  // const getIconSize = (isVisible) => {
  //   return isVisible ? 'text-3xl' : 'text-2xl'; // Reduced size for disabled state
  // };

  return (
    <div className="px-4">
      <div className="flex justify-between items-center">
        {/* Existing buttons aligned to the start */}
        <div className="flex items-center space-x-2">

          {buttons.map((button, index) => {

            // const isApproveButton = button.icon === IoMdCheckmark;
            const shouldDisableDueToSelectedCount = selectedCount > 1 && (
               button.icon === IoMdCreate 
            );


           
            // const shouldDisableDueToApproval = shouldDisableDueToSelectedCount;


            return (
              <button
                key={index}
                className={`p-1 rounded-lg ${
                  button.disabled || shouldDisableDueToSelectedCount ? '' : 'bg-secondary text-secondary-foreground'
                }`}
                onClick={!button.disabled && !shouldDisableDueToSelectedCount ? button.onClick : null}
                disabled={button.disabled || shouldDisableDueToSelectedCount}
                title={button.title}
              >
                <button.icon
                  className={`text-3xl border border-blue-100 rounded-lg cursor-pointer hover:border-customBlue hover:shadow-md p-1 ${
                    getIconColor(button.icon, button.disabled ||shouldDisableDueToSelectedCount)
                  } ${button.rotate ? 'rotate-90' : ''}`}
                />
              </button>
            );
          })}
        </div>
        <div className="flex items-center space-x-2">
        {extraButtons.map((button, index) => (
            <button
              key={index}
              className={`p-1 rounded-lg ${
                button.disabled ? 'bg-gray-200 cursor-not-allowed' : 'bg-secondary text-secondary-foreground'
              }`}
              onClick={!button.disabled ? button.onClick : null}
              disabled={button.disabled}
              title={button.title}
            >
              <button.icon
                className={`text-3xl border border-dark-gray shadow-md cursor-pointer p-1 hover:border-customBlue ${
                  getIconColor(button.icon, button.disabled)
                }`}
              />
            </button>
         ))}

        </div>

        {/* Extra buttons always visible but in light grey */}
        {/* <div className="flex items-center space-x-2 ml-2">
          {extraButtons.map((button, index) => (
            <button
              key={index}
              className={`text-secondary-foreground p-1 rounded-lg ${isExtraButtonsVisible ? 'bg-secondary rounded-lg' : 'bg-gray-200 cursor-not-allowed '}`}
              onClick={isExtraButtonsVisible ? button.onClick : undefined}
              disabled={!isExtraButtonsVisible}
              title={button.title}
            >
              <button.icon
                className={`border border-dark-gray p-1 rounded-lg ${isExtraButtonsVisible ? 'hover:border-customBlue shadow hover:shadow-md' : 'text-2xl'} ${getIconColor(button.icon, isExtraButtonsVisible)} ${getIconSize(isExtraButtonsVisible)}`}
              />
            </button>
          ))}
        </div> */}
      </div>
    </div>
  );
};

export default PrimaryActions;




// import React from 'react';
// import { IoMdCheckmark, IoMdClose } from 'react-icons/io';

// const PrimaryActions = ({ buttons = [], extraButtons = [], isExtraButtonsVisible = false }) => {
//   const getIconColor = (icon, disabled) => {
//     if (disabled) return 'text-gray-400'; // Grey color for disabled icons
//     switch (icon) {
//       case IoMdCheckmark:
//         return 'text-green-500';
//       case IoMdClose:
//         return 'text-red-500';
//       default:
//         return 'text-sky-900';
//     }
//   };

//   const getIconSize = (disabled) => {
//     return disabled ? 'text-2xl' : 'text-3xl'; // Reduced size for disabled icons
//   };

//   return (
//     <div className="px-4">
//       <div className="flex items-center space-x-2">
//         {/* Existing buttons aligned to the start */}
//         <div className="flex items-center space-x-2">
//           {buttons.map((button, index) => {
//             const shouldDisable = button.disabled; // Handle button disabling logic here
//             return (
//               <button
//                 key={index}
//                 className={`p-1 rounded-lg ${shouldDisable ? '' : 'bg-secondary text-secondary-foreground'}`}
//                 onClick={!shouldDisable ? button.onClick : null}
//                 title={button.title}
//               >
//                 <button.icon
//                   className={`border border-blue-100 rounded-lg cursor-pointer hover:border-customBlue hover:shadow-md p-1 ${getIconColor(button.icon, shouldDisable)} ${getIconSize(shouldDisable)} ${button.rotate ? 'rotate-90' : ''}`}
//                 />
//               </button>
//             );
//           })}
//         </div>

//         {/* Extra buttons with the same logic applied */}
//         <div className="flex items-center space-x-2 ml-2">
//           {extraButtons.map((button, index) => {
//             const shouldDisable = !isExtraButtonsVisible || button.disabled; // Handle disabling extra buttons here
//             return (
//               <button
//                 key={index}
//                 className={`p-1 rounded-lg ${shouldDisable ? 'bg-gray-200 cursor-not-allowed' : 'bg-secondary text-secondary-foreground'}`}
//                 onClick={!shouldDisable ? button.onClick : null}
//                 title={button.title}
//               >
//                 <button.icon
//                   className={`border border-dark-gray p-1 rounded-lg ${getIconColor(button.icon, shouldDisable)} ${getIconSize(shouldDisable)} ${button.rotate ? 'rotate-90' : ''}`}
//                 />
//               </button>
//             );
//           })}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PrimaryActions;

