import React from 'react'

export default function BSYettotable() {
  return (
    <div>
           <div className='p-4'>
      <table className="min-w-full bg-white">
          <thead className="bg-sky-500 text-primary-foreground text-sm">
            <tr className="bg-primary">
              
              <th className="py-2 px-4 text-center text-white  text-left font-medium">Bank Account NO</th>
              <th className="py-2 px-4 text-center text-white  text-left font-medium">Company Code</th>
              <th className="py-2 px-4 text-center text-white  text-left font-medium">Company Name</th>
              <th className="py-2 px-4 text-center text-white  text-left font-medium">Bank</th>
              <th className="py-2 px-4 text-center text-white  text-left font-medium">Bank ID Description</th>
              <th className="py-2 px-4 text-center text-white  text-left font-medium">Branch name</th>
              <th className="py-2 px-4 text-center text-white  text-left font-medium">Bank Account No</th>
              <th className="py-2 px-4 text-center text-white  text-left font-medium">IBAN</th>
              <th className="py-2 px-4 text-center text-white  text-left font-medium">Statement Type</th>
            </tr>
          </thead>
          <tbody className='text-sm'>
            {Array(50).fill().map((_, index) => (
              <tr key={index} className="border-b"  style={{ backgroundColor: index % 2 === 0 ? 'white' : '#f3f4f6' }}>
               
                <td className="py-2 px-4 text-center">Bank Account NO</td>
                <td className="py-2 px-4 text-center">Company Code</td>
                <td className="py-2 px-4 text-center">Company Name</td>
                <td className="py-2 px-4 text-center">Bank</td>
                <td className="py-2 px-4 text-center">Bank ID Description</td>
                <td className="py-2 px-4 text-center">Branch name</td>
                <td className="py-2 px-4 text-center">Bank Account No</td>
                <td className="py-2 px-4 text-center">IBAN</td>
                <td className="py-2 px-4 text-center">Statement Type</td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
    </div>
  )
}
