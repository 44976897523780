import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { BamButtonsConfigs } from "../../Components/BamButtonsConfig";
import BamActions from "../../Components/BamActions";
import ReTable from "../../../../../components/Table";
import { Checkbox, Button } from "@mui/material";
import Header from "../../../../../components/Header";
import SetUpServices from "../../../../../rest-services/SetUpServices";
import Footer from "../../../../../components/footer";
import { useToast } from "../../../../../components/toast/toast";
import { IoAddCircleOutline } from "react-icons/io5";
import CustomButton from "../../../../../components/CustomButton";
import { set } from "lodash";
export default function AccountStatus() {
  const { toast } = useToast();
  const navigate = useNavigate();
  const buttons = BamButtonsConfigs.screen1.map((button) => ({
    ...button,
    onClick:
      button.icon === IoAddCircleOutline
        ? () => handleAddRow()
        : button.onClick,
  }));

  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modifiedRows, setModifiedRows] = useState([]);
  const [assignee, setAssignee] = useState({
    id: "",
    assigner: "",
    clientId: "",
  });
  const [tableData, setTableData] = useState([]);
  const [newRowIndex, setNewRowIndex] = useState(null);
  const [isStsatusPopupOpen, setStatusPopupOpen] = useState(false);
  // Handle adding a new row
  const handleAddRow = () => {
    setStatusPopupOpen(true);
  };
  const closeStatusPopup = () => {
    setStatusPopupOpen(false);
  };

  useEffect(() => {
    // Fetch user details for assignee
    const fetchAssignee = async () => {
      try {
        const userDetailString = sessionStorage.getItem("userDetail");
        if (userDetailString) {
          const userDetail = JSON.parse(userDetailString);
          setAssignee({
            id: userDetail.id || "",
            assigner: userDetail.username || "",
            clientId: userDetail.clientId || "",
          });
        } else {
          toast("error", "No user detail found in session storage.");
        }
      } catch (error) {
        toast("error", `Error fetching user details: ${error.message}`);
      }
    };

    fetchAssignee();
  }, []);

  useEffect(() => {
    const fetchAccountStatusData = async () => {
      try {
        setLoading(true);
        const response = await SetUpServices.getAccountStatus(
          assignee.clientId
        );
        const sortedData = response.data.data.sort((a, b) => a.id - b.id);
        setTableData(sortedData);
      } catch (error) {
        setError("Failed to fetch account statuses");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchAccountStatusData();
  }, [assignee]);

  const AssignNewStatusModal = ({ isOpen, onClose }) => {
    const [status, setStatus] = useState("");
    const [options, setOptions] = useState({
      receiveMT940: false,
      ftout: false,
      ftin: false,
      cashAccounting: false,
      reconciliation: false,
      odCalculation: false,
      loanRepayment: false,
      lcRepayment: false,
    });
    const handleOptionChange = (key) => {
      setOptions((prev) => ({ ...prev, [key]: !prev[key] }));
    };

    const handleSubmit = async () => {
      const newRow = {
        status,
        clientId: assignee.clientId,
        receiveMt940: options.receiveMT940 ? 1 : 0,
        ftOut: options.ftout ? 1 : 0,
        ftIn: options.ftin ? 1 : 0,
        cashAccounting: options.cashAccounting ? 1 : 0,
        reconciliation: options.reconciliation ? 1 : 0,
        odCalculation: options.odCalculation ? 1 : 0,
        loanRepayments: options.loanRepayment ? 1 : 0,
        lcRepayments: options.lcRepayment ? 1 : 0,
        created_by: "admin",
        created_date: new Date().toLocaleDateString(),
        created_time: new Date().toLocaleTimeString(),
      };

      setTableData((prevData) => {
        const updatedData = [...prevData, newRow];
        setNewRowIndex(updatedData.length - 1);
        return updatedData;
      });
      setModifiedRows((prev) => [...prev, newRow]);
      onClose();
    };

    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center z-50">
        <div className="ml-16 bg-white p-6 rounded-lg shadow-lg w-3/4 max-w-3xl h-half relative">
          <h2 className="text-lg font-semibold mb-4 text-blue-600 p-4">
            Assigning New Status
          </h2>

          <div className="display-flex mb-4 flex items-center p-4">
            <label className="mr-6">
              Status <span className="text-red-600">*</span>
            </label>
            <span className="ml-3">:</span>
            <input
              type="text"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
            />
          </div>

          <div className="mb-6 mt-6 p-4">
            <div className="grid grid-cols-4 gap-4">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={options.receiveMT940}
                  onChange={() => handleOptionChange("receiveMT940")}
                  className="mr-2 border-2 border-blue-500 focus:ring-blue-500"
                />
                Receive MT940
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={options.ftout}
                  onChange={() => handleOptionChange("ftout")}
                  className="mr-2"
                />
                FTOUT
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={options.ftin}
                  onChange={() => handleOptionChange("ftin")}
                  className="mr-2"
                />
                FTIN
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={options.cashAccounting}
                  onChange={() => handleOptionChange("cashAccounting")}
                  className="mr-2"
                />
                Cash Accounting
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={options.reconciliation}
                  onChange={() => handleOptionChange("reconciliation")}
                  className="mr-2"
                />
                Reconciliation
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={options.odCalculation}
                  onChange={() => handleOptionChange("odCalculation")}
                  className="mr-2"
                />
                OD Calculation
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={options.loanRepayment}
                  onChange={() => handleOptionChange("loanRepayment")}
                  className="mr-2"
                />
                Loan Repayment
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={options.lcRepayment}
                  onChange={() => handleOptionChange("lcRepayment")}
                  className="mr-2"
                />
                LC Repayment
              </label>
            </div>
          </div>

          {/* <div className="display-flex mb-4 flex items-center p-4">
            <label className="mr-6">
              Valid From <span className="text-red-600">*</span>
            </label>
            <input
              value={validFrom}
              onChange={(e) => setValidFrom(e.target.value)}
              type="Date"
              className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
              required
            />
          </div> */}

          <div className="flex justify-between items-center mt-4 p-4">
            <CustomButton
              title={"Cancel"}
              className={"h-10 w-40"}
              onClick={onClose}
            />
            <CustomButton
              title={"Save"}
              className={"h-10 w-40"}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    );
  };
  const handleSelectRow = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAll = () => {
    setSelectedRows(
      selectedRows.length === tableData.length
        ? []
        : tableData.map((row) => row.id)
    );
  };

  const handleRowDoubleClick = (id) => {
    console.log(`Row with ID ${id} was double clicked.`);
  };

  const handleCheckboxChange = (id, field) => {
    const updatedValue =
      tableData.find((row) => row.id === id)[field] === 1 ? 0 : 1;

    const updatedRow = {
      ...tableData.find((row) => row.id === id),
      [field]: updatedValue,
    };

    // Update table data
    setTableData((prevData) =>
      prevData.map((row) => (row.id === id ? updatedRow : row))
    );

    // Update modified rows
    setModifiedRows((prev) => {
      const exists = prev.some((row) => row.id === id);

      if (exists) {
        // Replace existing row
        return prev.map((row) => (row.id === id ? updatedRow : row));
      } else {
        // Add new row
        return [...prev, updatedRow];
      }
    });

    console.log("Modified Rows after change:", modifiedRows); // Debugging log
  };

  const handleSubmitChanges = async () => {
    if (modifiedRows.length === 0) {
      console.log("No modified rows to submit");
      toast("info", "No changes to save");
      return;
    }

    try {
      console.log("Submitting Modified Rows:", modifiedRows);

      await Promise.all(
        modifiedRows.map((row) =>
          SetUpServices.updateAccountStatus({
            ...row,
            id: row.id,
            created_by: assignee.assigner,
            changed_by: assignee.assigner,
            clientId: assignee.clientId,
          })
        )
      );

      toast("success", "Status updated successfully");

      // Clear modified rows
      setModifiedRows([]);
    } catch (error) {
      console.error("Failed to update account statuses:", error);
      toast("error", "Failed to update statuses");
    }
  };

  const columns = [
    {
      Header: "SL No",
      accessor: "slNo",
      width: 50,
      Cell: ({ row }) => row.index + 1,
    },
    {
      Header: "Status",
      accessor: "status",
      width: 100,
    },
    {
      Header: "Receive MT940",
      accessor: "receiveMt940",
      width: 100,
      Cell: ({ row }) => (
        <Checkbox
          size="small"
          checked={row.original.receiveMt940 === 1}
          onChange={() => handleCheckboxChange(row.original.id, "receiveMt940")}
        />
      ),
    },
    {
      Header: "FTOUT",
      accessor: "ftOut",
      width: 100,
      Cell: ({ row }) => (
        <Checkbox
          size="small"
          checked={row.original.ftOut === 1} // Assuming the API returns 0 or 1
          onChange={() => handleCheckboxChange(row.original.id, "ftOut")}
        />
      ),
    },
    {
      Header: "FTIN",
      accessor: "ftIn",
      width: 100,
      Cell: ({ row }) => (
        <Checkbox
          size="small"
          checked={row.original.ftIn === 1} // Assuming the API returns 0 or 1
          onChange={() => handleCheckboxChange(row.original.id, "ftIn")}
        />
      ),
    },
    {
      Header: "Cash Accounting",
      accessor: "cashAccounting",
      width: 100,
      Cell: ({ row }) => (
        <Checkbox
          size="small"
          checked={row.original.cashAccounting === 1} // Assuming the API returns 0 or 1
          onChange={() =>
            handleCheckboxChange(row.original.id, "cashAccounting")
          }
        />
      ),
    },
    {
      Header: "Reconciliation",
      accessor: "reconciliation",
      width: 100,
      Cell: ({ row }) => (
        <Checkbox
          size="small"
          checked={row.original.reconciliation === 1} // Assuming the API returns 0 or 1
          onChange={() =>
            handleCheckboxChange(row.original.id, "reconciliation")
          }
        />
      ),
    },
    {
      Header: "OD Calculation",
      accessor: "odCalculation",
      width: 100,
      Cell: ({ row }) => (
        <Checkbox
          size="small"
          checked={row.original.odCalculation === 1} // Assuming the API returns 0 or 1
          onChange={() =>
            handleCheckboxChange(row.original.id, "odCalculation")
          }
        />
      ),
    },
    {
      Header: "Loan Repayments",
      accessor: "loanRepayments",
      width: 100,
      Cell: ({ row }) => (
        <Checkbox
          size="small"
          checked={row.original.loanRepayments === 1} // Assuming the API returns 0 or 1
          onChange={() =>
            handleCheckboxChange(row.original.id, "loanRepayments")
          }
        />
      ),
    },
    {
      Header: "LC Repayments",
      accessor: "lcRepayments",
      width: 100,
      Cell: ({ row }) => (
        <Checkbox
          size="small"
          checked={row.original.lcRepayments === 1} // Assuming the API returns 0 or 1
          onChange={() => handleCheckboxChange(row.original.id, "lcRepayments")}
        />
      ),
    }, // other columns...
  ];

  const handleBreadcrumbClick = (crumb) => {
    if (crumb.title === "BAM") navigate("/bam");
    else if (crumb.title === "Set Up") navigate("/bam");
    else if (crumb.title === "Account Status") navigate("/account-status");
    else if (crumb.path) navigate(crumb.path);
  };

  return (
    <div>
      <Header
        title={"BAM > Set Up > Account Status"}
        onBreadcrumbClick={handleBreadcrumbClick}
      />
      <div>
        {isStsatusPopupOpen && (
          <AssignNewStatusModal
            isOpen={isStsatusPopupOpen}
            onClose={closeStatusPopup}
          />
        )}
      </div>

      <div>
        <BamActions buttons={buttons} />
      </div>

      <ReTable
        data={tableData}
        columns={columns}
        onSelectRow={handleSelectRow}
        selectedRows={selectedRows}
        onSelectAll={handleSelectAll}
        onRowDoubleClick={handleRowDoubleClick}
      />

      <Footer>
        <button
          onClick={handleSubmitChanges}
          className="bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ml-7"
        >
          Submit
        </button>
      </Footer>
    </div>
  );
}
