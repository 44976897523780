import React, { useState } from "react";

const Pagination = ({ currentPage, totalPages, onPageChange, totalCount }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(currentPage);

  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && value >= 1 && value <= totalPages) {
      setInputValue(value); // Replace the input value directly
    }
  };

  const handleInputBlur = () => {
    setIsEditing(false);
    const newPage = parseInt(inputValue, 10);
    if (newPage && newPage !== currentPage) {
      onPageChange(newPage);
    } else {
      setInputValue(currentPage); // Reset to current page if invalid
    }
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter") {
      handleInputBlur();
    }
  };

  const handleInputFocus = () => {
    setInputValue(""); // Clear the input when focused
  };

  return (
    <div className="flex items-center">
      <div className="text-customGray">
        {totalCount} Records
      </div>
      <div className="flex items-center ml-4">
        <button
          onClick={handlePrevious}
          disabled={currentPage === 1}
          className="mx-1 px-3 py-1 border rounded bg-white text-customGray"
        >
          &lt;
        </button>
        <span className="mx-2 text-customGray">
          {isEditing ? (
            <input
              type="number"
              value={inputValue}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              onKeyDown={handleInputKeyDown}
              onFocus={handleInputFocus}
              className="w-12 border rounded text-center"
              min={1}
              max={totalPages}
              autoFocus
            />
          ) : (
            <span
              onClick={() => setIsEditing(true)}
              className="cursor-pointer"
            >
              {currentPage}
            </span>
          )}{" "}
          of {totalPages}
        </span>
        <button
          onClick={handleNext}
          disabled={currentPage === totalPages}
          className="mx-1 px-3 py-1 border rounded bg-white text-customGray"
        >
          &gt;
        </button>
      </div>
    </div>
  );
};

export default Pagination;
