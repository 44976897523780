import React, { useEffect, useState } from "react";
import Header from "../../../../../../components/Header";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import UserService from "../../../../../../rest-services/UserServices";
import {
  IoMdCheckmark,
  IoMdClose,
  IoMdCopy,
  IoMdCreate,
  IoMdRefresh,
  IoMdTime,
  IoMdTrash,
} from "react-icons/io";
import {
  IoAddCircleOutline,
  IoChevronDown,
  IoChevronForward,
  IoSettingsOutline,
} from "react-icons/io5";
import { LuKeyRound } from "react-icons/lu";
import {
  MdOutlineFileDownload,
  MdOutlineFileUpload,
  MdOutlineLock,
  MdOutlineSaveAs,
} from "react-icons/md";
import Footer from "../../../../../../components/footer";
import ButtonGroup from "../../../../../../components/FooterButton";
import { useToast } from "../../../../../../components/toast/toast";
import { RxDropdownMenu } from "react-icons/rx";
import { GiSettingsKnobs } from "react-icons/gi";
import { BiSave } from "react-icons/bi";
import { VscVmActive } from "react-icons/vsc";

export default function RolesAndPermissionAdd() {
  const navigate = useNavigate();
  const { UserId } = useParams();
  const location = useLocation();
  const { mode } = location.state || {}; // Get the mode from the state
  const isViewMode = mode === "view";
  const isEditMode = mode === "edit";
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState(null);
  // const[roles,setRoles] = useState([]);
  const { toast } = useToast();
  const [selectedUserTypeName, setSelectedUserTypeName] = useState("");
  const [selectedUserTypeId, setSelectedUserTypeId] = useState("");
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [userTypes, setUserTypes] = useState([]);
  const [userType, setUserType] = useState("");
  const [selectedUserId, setSelectedUserId] = useState("");
  const [users, setUsers] = useState([]);
  const [assignee, setAssignee] = useState({ id: "", username: "" });
  const [searchTerm, setSearchTerm] = useState("");
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedPermissions, setSelectedPermissions] = useState({});
  const [filteredIcons, setFilteredIcons] = useState([]);
  const [Icons, setIcons] = useState([]);

  const [allExpanded, setAllExpanded] = useState(false);
  const [isModalOpenForModule, setIsModalOpenForModule] = useState(false);
  const [isModalOpenForSubModule, setIsModalOpenForSubModule] = useState(false);
  const [isModalOpenForIconModule, setIsModalOpenForIconModule] =
    useState(false);

  const [searchQueryState, setSearchQueryState] = useState(""); // State for search query
  const [searchQuerySubmodule, setSearchQuerySubmodule] = useState(""); // State for search query
  const [searchQueryIconmodule, setSearchQueryIconmodule] = useState(""); // State for search query

  const [expandedMainMenu, setExpandedMainMenu] = useState(null);
  const [selectedSubMenu, setSelectedSubMenu] = useState(null);
  const [expandedSubMenu, setExpandedSubMenu] = useState({});
  const [expandedIcon, setExpandedIcon] = useState([]);
  const [selectedIcons, setSelectedIcons] = useState({});
  const [permissionIcons, setPermissionIcons] = useState([]);

  const [mainMenu, setMainMenu] = useState("");
  const [subMenu, setSubMenu] = useState("");

  useEffect(() => {
    fetchUserTypes();
    fetchAssignee();
  }, []);

  useEffect(() => {
    if (assignee.clientId) {
      fetchUsers();
      fetchIconsData();
      fetchGetPermissionsData();
    }
  }, [assignee]);

  useEffect(() => {
    if (selectedRole) {
      console.log("selected role", selectedRole);
      getDataForSelectedRole();
    }
  }, [selectedRole]);


  useEffect(() => {
    if (selectedUserTypeId) {
      fetchallRoles();
    }
  }, [selectedUserTypeId]);

  useEffect(() => {
    if (UserId) {
      getDataForEdit();
    }
  }, [UserId]);

  const getDataForEdit = async () => {
    try {
      const data = { userId: UserId };
      console.log("Request Data:", data);

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-users-permissions-by-userId`,
        data
      );

      console.log(
        "Response from server fetching roles & Permissions:",
        response
      );
      const responseData = response.data;
      console.log("set roles & permission details", responseData);
      // setRoles(responseData);
      if (responseData) {
        setUserId(responseData.userId);
        setUserType(responseData.userType);
        setSelectedRole(responseData.role_id);
        console.log("set roles Id", responseData.role_id);
      }

      if (responseData.error) {
        console.error("Error fetching roles data:", responseData.message);
        setAlert({ severity: "error", message: responseData.message });
        return;
      }
    } catch (error) {
      console.error("Error fetching roles data", error);
      setAlert({
        severity: "error",
        message: `Error fetching roles data, kindly check the connection ${error.message}`,
      });
    }
  };

  const getDataForSelectedRole = async () => {
    try {
      const data = { roleId: selectedRole };
      console.log("Request Data:", data);

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-role-by-roleId`,
        data
      );

      console.log("Response from server fetching roles:", response);
      const responseData = response.data;
      console.log("set roles details", responseData);
      // setRoles(responseData);
      if (responseData) {
        console.log("set roles details name", responseData.name);
        console.log("set roles details desc", responseData.description);

        const initialIcons = {};
        const initialPermissions = {};

        responseData.rolesPermissionsTables.forEach((rolePermission) => {
          const { iconId, permissionsId } = rolePermission;

          initialIcons[iconId] = true;
          if (!initialPermissions[iconId]) initialPermissions[iconId] = {};
          initialPermissions[iconId][permissionsId] = true;
        });

        setSelectedIcons(initialIcons);
        setSelectedPermissions(initialPermissions);
      }

      if (responseData.error) {
        console.error("Error fetching roles data:", responseData.message);
        setAlert({ severity: "error", message: responseData.message });
        return;
      }
    } catch (error) {
      console.error("Error fetching roles data", error);
      setAlert({
        severity: "error",
        message: `Error fetching roles data, kindly check the connection ${error.message}`,
      });
    }
  };

  const fetchAssignee = async () => {
    try {
      const userDetailString = sessionStorage.getItem("userDetail");

      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.username || "",
          assignerId: userDetail.userId || "",
          clientId: userDetail.clientId || "",
        };

        setAssignee(assigneeData);

        // Log the id and assigner values to the console
        console.log("Assignee ID:", assigneeData.id);
        console.log("Assigner:", assigneeData.assigner);
        console.log("Assigner userId:", assigneeData.assignerId);
        console.log("Assigner clientId:", assigneeData.clientId);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  const fetchIconsData = async () => {
    try {
      const response = await UserService.getUsers(
        `${process.env.REACT_APP_BASE_URL}/get-icons-data`
      );
      console.log("Response from server fetching Icons data:", response);
      const responseData = response.data.data;
      setIcons(responseData);

      console.log("double check users", response.data.data);
      if (responseData.error) {
        console.error("Error fetching Users data:", responseData.message);
        setAlert({ severity: "error", message: responseData.message });
        return;
      }
    } catch (error) {
      console.error("Error fetching Users data", error);
      setAlert({
        severity: "error",
        message: `Error fetching Users data, kindly check the connection ${error.message}`,
      });
    }
  };

  const fetchGetPermissionsData = async () => {
    try {
      const response = await UserService.getUsers(
        `${process.env.REACT_APP_BASE_URL}/get-permissions`
      );
      console.log("Response from server fetching permissions data:", response);
      const responseData = response.data.data;
      setPermissionIcons(responseData);

      console.log("double check users permissons", responseData);
      if (responseData.error) {
        console.error("Error fetching Users data:", responseData.message);
        setAlert({ severity: "error", message: responseData.message });
        return;
      }
    } catch (error) {
      console.error("Error fetching Users data", error);
      setAlert({
        severity: "error",
        message: `Error fetching Users data, kindly check the connection ${error.message}`,
      });
    }
  };

  const fetchUsers = async () => {
    try {
      console.log("assignees data", assignee);
      const data = { clientId: assignee.clientId };
      console.log("Request Data for users:", data);

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/user/get-user/approved`,
        data
      );
      console.log("Response from server fetching users:", response);
      const responseData = response.data;
      console.log("add last seen and users data", responseData);
      setUsers(responseData);

      console.log("double check users", response.data);
      if (responseData.error) {
        console.error("Error fetching Users data:", responseData.message);
        setAlert({ severity: "error", message: responseData.message });
        return;
      }
    } catch (error) {
      console.error("Error fetching Users data", error);
      setAlert({
        severity: "error",
        message: `Error fetching Users data, kindly check the connection ${error.message}`,
      });
    }
  };

  const fetchallRoles = async () => {
    try {
      // const data = { };
      // console.log("Request selectedUserTypeName Data:", data);

      console.log("assignees data 2", assignee);
      const data = { clientId: assignee.clientId, userTypeId: selectedUserTypeId  };
      console.log("Request Data for users 2:", data);

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-roles-approved`,
        data
      );
      console.log("response data for language", response.data);
      setRoles(response.data);
    } catch (error) {
      console.error("Error fetching Language data", error);
    }
  };

  const handleUserFilterPopup = () => {
    console.log("entered");
    setIsUserModalOpen(true);
  };

  const closeUserModal = () => {
    setIsUserModalOpen(false);
  };

  const fetchUserTypes = async () => {
    try {
      const response = await UserService.getUsers(
        `${process.env.REACT_APP_BASE_URL}/get-user-type`
      );
      setUserTypes(response.data.data);
      console.log("Set user types data", response.data.data);
    } catch (error) {
      console.error("Error fetching user types:", error);
    }
  };

    // const selectedUserTypeName = userTypes.find(type => type.id === userType)?.name || "";
    // const selectedUserTypeName = userTypes?.find((type) => Number(type.id) === Number(userType))?.name || "";

    useEffect(() => {
      const selectedType = userTypes?.find((type) => Number(type.id) === Number(userType)) || {};
      const selectedTypeId = selectedType.id || "";
      const selectedTypeName = selectedType.name || "";

     setSelectedUserTypeId(selectedTypeId);
  setSelectedUserTypeName(selectedTypeName);
  console.log("Selected user type ID is", selectedTypeId);
  console.log("Selected user type name is", selectedTypeName);
    }, [userType, userTypes]);
    

  const handleCheckboxChangeForUser = (event) => {
    const selectedUserId = event.target.value; // Get the selected user's ID
    console.log("selected user id data", selectedUserId);
    setSelectedUserId(selectedUserId); // Update the state to reflect the selected user

    const selectedUserData = users.find(
      (user) => user.userId === selectedUserId
    );
    if (selectedUserData) {
      // setUserName(selectedUserData.userId);
      setUserId(selectedUserData.userId);
      setUserType(selectedUserData.userType);
    }
  };

  const handlePermissionChange = (iconId, permissionId) => {
    setSelectedPermissions((prev) => ({
      ...prev,
      [iconId]: {
        ...prev[iconId],
        [permissionId]: !prev[iconId]?.[permissionId] || false,
      },
    }));
  };

  const toggleIcon = (iconId) => {
    setExpandedIcon((prev) =>
      prev.includes(iconId)
        ? prev.filter((id) => id !== iconId)
        : [...prev, iconId]
    );
  };

  const toggleAllMenus = () => {
    setAllExpanded(!allExpanded);
  };

  const toggleModalForModule = () => {
    setIsModalOpenForModule(!isModalOpenForModule);
  };
  const handleSearchChangeState = (e) => {
    setSearchQueryState(e.target.value);
  };

  const handleSearchChangeSubmodule = (e) => {
    setSearchQuerySubmodule(e.target.value);
  };

  const handleSearchChangeIconmodule = (e) => {
    setSearchQueryIconmodule(e.target.value);
  };

  const toggleModalForSubModule = () => {
    setIsModalOpenForSubModule(!isModalOpenForSubModule);
  };

  const toggleModalForIconModule = () => {
    setIsModalOpenForIconModule(!isModalOpenForIconModule);
  };

  const toggleMainMenu = (mainMenuId) => {
    setExpandedMainMenu((prev) => (prev === mainMenuId ? null : mainMenuId));
  };

  const toggleSubMenu = (mainMenuId, subMenuId) => {
    setSelectedSubMenu(subMenuId);
    setExpandedSubMenu((prev) => ({
      ...prev,
      [mainMenuId]: prev[mainMenuId] === subMenuId ? null : subMenuId,
    }));
  };

  const handleIconChange = (iconId) => {
    setSelectedIcons((prev) => ({
      ...prev,
      [iconId]: !prev[iconId],
    }));
  };

  const handleRoleChange = (e) => {
    const newRole = e.target.value;
    setSelectedRole(newRole);
  };

  const validateFields = () => {
    const newErrors = {};
    if (!userId) newErrors.userId = " ";
    if (!selectedRole) newErrors.selectedRole = " ";

    setErrors(newErrors);

    // Show toast only if there are errors
    if (Object.keys(newErrors).length > 0) {
      toast("warning", "Missing required fields.");
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (!validateFields()) return;
    if (UserId) {
      console.log("its comes on user id console");
      try {
        const permissionsData = [];
        Object.keys(selectedIcons).forEach((iconId) => {
          if (selectedIcons[iconId]) {
            Object.keys(selectedPermissions[iconId] || {}).forEach(
              (permissionId) => {
                if (selectedPermissions[iconId][permissionId]) {
                  permissionsData.push({
                    role_id: selectedRole,
                    icon_id: parseInt(iconId),
                    permission_id: parseInt(permissionId),
                    user_id: UserId, // Use the new roleId from the role submission
                    updatedby: assignee.assignerId,
                    clientId: assignee.clientId,
                  });
                }
              }
            );
          }
        });
        console.log("update permission array", permissionsData);

        const permissionsResponse = await UserService.saveAllRolesDetails(
          `${process.env.REACT_APP_BASE_URL}/update-user-permissions`,
          { permissions: permissionsData }
        );
        console.log("console permissions Response", permissionsResponse);
        if (permissionsResponse.status === 200) {
          // alert('Permissions and role saved successfully');
          toast("success", "User Permissions Updated Successfully");

          navigate("/14/admin/112/roles-and-permission");
        } else {
          // alert('Failed to save permissions');
          toast("warning", "Failed to save permissions");
        }
      } catch (error) {
        console.error("Error submitting permissions and role details:", error);
        // alert('Error submitting permissions and role details');
        toast("warning", "Error submitting permissions and role details");
      }
    } else {
      try {
        // First, submit the role details
        console.log("selected role also needed", selectedRole);
        console.log("selected user Id also needed", userId);
        console.log("selected user Type also needed", userType);

        // Prepare the selected permissions to include the new roleId

        const permissionsData = [];
        Object.keys(selectedIcons).forEach((iconId) => {
          if (selectedIcons[iconId]) {
            Object.keys(selectedPermissions[iconId] || {}).forEach(
              (permissionId) => {
                if (selectedPermissions[iconId][permissionId]) {
                  permissionsData.push({
                    role_id: selectedRole,
                    icon_id: parseInt(iconId),
                    permission_id: parseInt(permissionId),
                    user_id: userId, // Use the new roleId from the role submission
                    createdBy: assignee.assignerId,
                    clientId: assignee.clientId,
                  });
                }
              }
            );
          }
        });

        //   const selectedPermissions = Object.keys(permissions)
        //       .filter(permission => permissions[permission])
        //       .map(permission => ({
        //           permission_id: permissionValues[permission],
        //           icon_id: 108, // Manually setting icon_id to 1
        //           role_id: selectedRole,
        //         user_id: userId, // Use the new roleId from the role submission
        //       }));
        console.log("now stored in permissions data", permissionsData);

        const permissionsResponse = await UserService.saveAllRolesDetails(
          `${process.env.REACT_APP_BASE_URL}/insert-user-permissions`,
          { permissions: permissionsData }
        );

        if (permissionsResponse.status === 200) {
          // alert('Permissions and role saved successfully');
          toast("success", "User Permissions Saved Successfully");

          navigate("/14/admin/112/roles-and-permission");
        } else {
          // alert('Failed to save permissions');
          toast("warning", "Failed to save permissions");
        }
      } catch (error) {
        console.error("Error submitting permissions and role details:", error);
        // alert('Error submitting permissions and role details');
        toast("warning", "Error submitting permissions and role details");
      }
    }
  };

  const handleSaveContinueDetails = async () => {
    if (!validateFields()) return;
    if (UserId) {
      console.log("its comes on user id console");
      try {
        const permissionsData = [];
        Object.keys(selectedIcons).forEach((iconId) => {
          if (selectedIcons[iconId]) {
            Object.keys(selectedPermissions[iconId] || {}).forEach(
              (permissionId) => {
                if (selectedPermissions[iconId][permissionId]) {
                  permissionsData.push({
                    role_id: selectedRole,
                    icon_id: parseInt(iconId),
                    permission_id: parseInt(permissionId),
                    user_id: UserId, // Use the new roleId from the role submission
                    updatedby: assignee.assignerId,
                    clientId: assignee.clientId,
                  });
                }
              }
            );
          }
        });
        console.log("update permission array", permissionsData);

        const permissionsResponse = await UserService.saveAllRolesDetails(
          `${process.env.REACT_APP_BASE_URL}/update-user-permissions`,
          { permissions: permissionsData }
        );
        console.log("console permissions Response", permissionsResponse);
        if (permissionsResponse.status === 200) {
          // alert('Permissions and role saved successfully');
          toast("success", "User Permissions Updated Successfully");

          navigate("/14/admin/112/roles-and-permission");
        } else {
          // alert('Failed to save permissions');
          toast("warning", "Failed to save permissions");
        }
      } catch (error) {
        console.error("Error submitting permissions and role details:", error);
        // alert('Error submitting permissions and role details');
        toast("warning", "Error submitting permissions and role details");
      }
    } else {
      try {
        // First, submit the role details
        console.log("selected role also needed", selectedRole);
        console.log("selected user Id also needed", userId);
        console.log("selected user Type also needed", userType);

        // Prepare the selected permissions to include the new roleId

        const permissionsData = [];
        Object.keys(selectedIcons).forEach((iconId) => {
          if (selectedIcons[iconId]) {
            Object.keys(selectedPermissions[iconId] || {}).forEach(
              (permissionId) => {
                if (selectedPermissions[iconId][permissionId]) {
                  permissionsData.push({
                    role_id: selectedRole,
                    icon_id: parseInt(iconId),
                    permission_id: parseInt(permissionId),
                    user_id: userId, // Use the new roleId from the role submission
                    createdBy: assignee.assignerId,
                    clientId: assignee.clientId,
                  });
                }
              }
            );
          }
        });

        //   const selectedPermissions = Object.keys(permissions)
        //       .filter(permission => permissions[permission])
        //       .map(permission => ({
        //           permission_id: permissionValues[permission],
        //           icon_id: 108, // Manually setting icon_id to 1
        //           role_id: selectedRole,
        //         user_id: userId, // Use the new roleId from the role submission
        //       }));
        console.log("now stored in permissions data", permissionsData);

        const permissionsResponse = await UserService.saveAllRolesDetails(
          `${process.env.REACT_APP_BASE_URL}/insert-user-permissions`,
          { permissions: permissionsData }
        );

        if (permissionsResponse.status === 200) {
          // alert('Permissions and role saved successfully');
          toast("success", "User Permissions Saved Successfully");

          navigate("/14/admin/112/roles-and-permission");
        } else {
          // alert('Failed to save permissions');
          toast("warning", "Failed to save permissions");
        }
      } catch (error) {
        console.error("Error submitting permissions and role details:", error);
        // alert('Error submitting permissions and role details');
        toast("warning", "Error submitting permissions and role details");
      }
    }
  };


  const iconMapping = {
    Add: <IoAddCircleOutline size={18} />, // Adjust size as needed
    Edit: <IoMdCreate size={18} />,
    View: (
      <svg
        className="ml-2 text-customGray"
        xmlns="http://www.w3.org/2000/svg"
        width="1.2em"
        height="1.3em"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M6 23H3q-.825 0-1.412-.587T1 21v-3h2v3h3zm12 0v-2h3v-3h2v3q0 .825-.587 1.413T21 23zm-6-4.5q-3 0-5.437-1.775T3 12q1.125-2.95 3.563-4.725T12 5.5t5.438 1.775T21 12q-1.125 2.95-3.562 4.725T12 18.5m0-3q1.45 0 2.475-1.025T15.5 12t-1.025-2.475T12 8.5T9.525 9.525T8.5 12t1.025 2.475T12 15.5m0-2q-.625 0-1.062-.437T10.5 12t.438-1.062T12 10.5t1.063.438T13.5 12t-.437 1.063T12 13.5M1 6V3q0-.825.588-1.412T3 1h3v2H3v3zm20 0V3h-3V1h3q.825 0 1.413.588T23 3v3z"
        ></path>
      </svg>
    ),
    Copy: <IoMdCopy size={18} />,
    "Audit Trail": <IoMdTime size={18} />,
    Approve: <IoMdCheckmark size={18} />,
    Reject: <IoMdClose size={18} />,
    "Trigger OTP": <LuKeyRound size={18} />,
    Upload: <MdOutlineFileUpload size={18} />,
    Download: <MdOutlineFileDownload size={18} />,
    Inactive: <VscVmActive size={18} />,
    Lock: <MdOutlineLock size={18} />,
    Delete: <IoMdTrash size={18} />,
    Filter: <GiSettingsKnobs size={18} />,
    Settings: <IoSettingsOutline size={18} />,
    Refresh: <IoMdRefresh size={18} />,
    Save: <BiSave size={18} />,
    "Save & Continue": <MdOutlineSaveAs size={18} />,

    // Add other icons with size adjustments
  };

  useEffect(() => {
    const filtereddata = Icons.filter((mainMenuItem) => {
      const matchesMainMenu = mainMenu ? mainMenuItem.name === mainMenu : true;

      if (matchesMainMenu) {
        const filteredSubMenus = mainMenuItem.subMenus
          .filter((subMenuItem) => {
            const matchesSubMenu = subMenu
              ? subMenuItem.name === subMenu
              : true;

            if (matchesSubMenu) {
              const matchingIcons = subMenuItem.icons.filter((icon) =>
                icon.name.toLowerCase().includes(searchQueryState.toLowerCase())
              );

              return (
                matchingIcons.length > 0 ||
                subMenuItem.name
                  .toLowerCase()
                  .includes(searchQueryState.toLowerCase())
              );
            }
            return false;
          })
          .map((subMenuItem) => ({
            ...subMenuItem,
            icons: subMenuItem.icons.filter((icon) =>
              icon.name.toLowerCase().includes(searchQueryState.toLowerCase())
            ),
          }));

        if (filteredSubMenus.length > 0) {
          return {
            ...mainMenuItem,
            subMenus: filteredSubMenus,
          };
        }
      }

      return null;
    }).filter(Boolean);

    // Update the state only if the filtered data changes
    setFilteredIcons(filtereddata);
  }, [Icons, mainMenu, subMenu, searchQueryState]);

  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "User & SOD") {
      navigate("/14/admin");
    } else if (crumb.title === "Admin") {
      navigate("/14/admin");
    } else if (crumb.title === "Permissions") {
      navigate("/14/admin/112/roles-and-permission");
    } else if (crumb.path) {
      navigate(crumb.path); // Navigate to the specified path in the breadcrumb
    }
  };

  return (
    <div>
      <Header
        title={`Admin > User & SOD > Permissions > ${
          UserId ? (isViewMode ? "View" : "Edit") : "Add"
        }`}
        onBreadcrumbClick={handleBreadcrumbClick}
      />
      <div className="px-5 py-5 mb-5">
        <div className="max-w-8xl">
          <div className="py-1 px-3 grid grid-cols-[1fr_1fr_1fr_auto] gap-x-4 items-center shadow-sm mb-6">
            <label className="flex items-center">
              <span className="w-32 text-sm font-medium text-customGray">
                User ID<span className="text-red-500 ml-1">*</span>
              </span>
              <span className="ml-2">:</span>
              <button
                onClick={handleUserFilterPopup} // Trigger modal or selection dropdown
                className={`border p-1.5 rounded w-full h-8 focus:outline-none ml-4 hover:border-blue-400 text-left flex items-center justify-between 
              ${
                isViewMode || isEditMode
                  ? "text-gray-500 bg-gray-100"
                  : "text-customGray"
              } 
               ${
                 errors.userId
                   ? "border-red-500 hover:border-red-500"
                   : "border-gray-300 hover:border-blue-400"
               }`}
                disabled={isViewMode || isEditMode}
              >
                <span>{userId || "Select User ID"} </span>
                <RxDropdownMenu className="ml-2" />
              </button>
            </label>

            <label className="flex items-center">
              <span className="w-32 text-sm font-medium text-customGray">
                User Type
              </span>
              <span className="ml-2">:</span>
              <input
                type="text"
                value={selectedUserTypeName}
                className="border border-gray-300 p-1.5 rounded w-full h-8 text-customGray focus:outline-none ml-4"
                disabled
              />
            </label>

            <label className="flex items-center border-r-2 pr-4">
              <span className="w-32 text-sm font-medium text-customGray">
                Roles
              </span>
              <span className="ml-2">:</span>
              {/* <input
                type="text"
                value={selectedUserTypeName}
                className="border border-gray-300 p-1.5 rounded w-72 h-8 text-customGray focus:outline-none ml-4"
                disabled
              /> */}
              <select
                value={selectedRole}
                disabled={isViewMode}
                onChange={handleRoleChange}
                className={`border border-gray-300 p-1 rounded w-full h-8 ${
                  isViewMode ? "text-gray-500 bg-gray-100" : "text-customGray"
                } focus:outline-none hover:border-blue-400 ml-4 ${
                  errors.selectedRole
                    ? "border-red-500 hover:border-red-500"
                    : "border-gray-300 hover:border-blue-400"
                }`}
              >
                <option value="">Select Role</option>
                {roles.map((type) => (
                  <option key={type.roleId} value={type.roleId}>
                    {type.roleId}
                  </option>
                ))}
              </select>

              {errors.selectedRole && (
                <p className="text-red-500 text-sm ml-4">
                  {errors.selectedRole}
                </p>
              )}
            </label>

            <div className="flex justify-end">
              <button
                className="flex items-center justify-center bg-white text-sm border border-gray-300 text-customGray py-1.5 px-4 rounded hover:bg-gray-100 transition duration-200"
                onClick={toggleAllMenus} // Add this onClick to trigger the toggle
              >
                {allExpanded ? (
                  <IoChevronForward className="w-5 h-5 text-gray-500" />
                ) : (
                  <IoChevronDown className="w-5 h-5 text-gray-500" />
                )}
              </button>
            </div>
          </div>

          <div className="mb-6 bg-white rounded-lg shadow-sm">
            {filteredIcons.map((mainMenu) => (
              <div key={mainMenu.id} className="mb-4">
                {/* Main Menu Level */}
                <div
                  className="bg-blue-50 px-2 py-1.5 flex items-center justify-start cursor-pointer rounded space-x-2"
                  onClick={() => toggleMainMenu(mainMenu.id)}
                >
                  {allExpanded || expandedMainMenu === mainMenu.id ? (
                    <IoChevronDown className="w-5 h-5 text-gray-500" />
                  ) : (
                    <IoChevronForward className="w-5 h-5 text-gray-500" />
                  )}
                  <span className="text-md font-medium text-customGray">
                    {mainMenu.name}
                  </span>
                </div>

                {/* Submenus */}
                {(allExpanded ||
                  expandedMainMenu === mainMenu.id ||
                  searchTerm) &&
                  mainMenu.subMenus.map((subMenu) => (
                    <div key={subMenu.id} className="ml-4 mt-2">
                      {/* Submenu Level */}
                      <div
                        className="bg-blue-50 px-2 py-1.5 flex items-center justify-start cursor-pointer rounded space-x-2"
                        onClick={() => toggleSubMenu(mainMenu.id, subMenu.id)} // Handle submenu toggle
                      >
                        {allExpanded ||
                        expandedSubMenu[mainMenu.id] === subMenu.id ? (
                          <IoChevronDown className="w-5 h-5 text-gray-500" />
                        ) : (
                          <IoChevronForward className="w-5 h-5 text-gray-500" />
                        )}
                        <span className="text-md font-medium text-customGray">
                          {subMenu.name}
                        </span>
                      </div>

                      {/* Icons and expanded submenu content */}
                      {(allExpanded ||
                        expandedSubMenu[mainMenu.id] === subMenu.id ||
                        searchTerm) && (
                        <div className="ml-8 mt-2">
                          {/* Icons Level */}
                          {subMenu.icons.map((icon) => (
                            <div key={icon.id} className="flex flex-col mt-2">
                              <div
                                className="bg-blue-50 px-2 py-1.5 flex items-center justify-start cursor-pointer rounded space-x-2"
                                onClick={() => toggleIcon(icon.id)} // Toggle icon
                              >
                                {allExpanded ||
                                expandedIcon.includes(icon.id) ? (
                                  <IoChevronDown className="w-5 h-5 text-gray-500" />
                                ) : (
                                  <IoChevronForward className="w-5 h-5 text-gray-500" />
                                )}
                                <span className="text-md font-medium text-customGray">
                                  {icon.name}
                                </span>
                                <input
                                  type="checkbox"
                                  className="h-4 w-4 text-blue-600 border-gray-300 rounded"
                                  checked={selectedIcons[icon.id] || false}
                                  onClick={(e) => e.stopPropagation()}
                                  disabled
                                  onChange={() => handleIconChange(icon.id)}
                                />
                              </div>

                              {/* Expanded content for icons */}
                              {allExpanded || expandedIcon.includes(icon.id) ? (
                                <div className="p-4 grid grid-cols-4 gap-4 items-center bg-white rounded">
                                  {permissionIcons.map((permission) => {
                                    const isDisabled =
                                    userType == 1 &&
                                    (
                                      (icon.id === 21 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                      (icon.id === 22 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id))||
                                      (icon.id === 23 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                      (icon.id === 24 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                      (icon.id === 25 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
        
                                      (icon.id === 44 && [17].includes(permission.id)) ||
                                      (icon.id === 45 && [1, 2, 4, 13, 17].includes(permission.id)) ||
        
                                      (icon.id === 108 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                      (icon.id === 109 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                      (icon.id === 110 && permission.id === 17) ||
                                      (icon.id === 111 && [1, 2, 4, 13 ,10 , 17, 18].includes(permission.id)) ||
                                      (icon.id === 112 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id))||
                                      (icon.id === 113 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id))||
                                      (icon.id === 114 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                      (icon.id === 115 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id)) 
        
                                    )||
                                    (userType == 3 &&
                                      (
                                        // Country
                                        (icon.id === 1 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                        // State provice
                                        (icon.id === 2 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                        // Calender
                                        (icon.id === 3 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                        // Currency
                                        (icon.id === 4 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                        // Bank
                                        (icon.id === 5 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                        // Language
                                        (icon.id === 6 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                        // Legal entity
                                        (icon.id === 7 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                        // Bu group
                                        (icon.id === 8 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                        // Company
                                        (icon.id === 9 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                        // Bank Id
                                        (icon.id === 10 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                        // Branch
                                        (icon.id === 11 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                        // Account status
                                        (icon.id === 21 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                        // Account no logic
                                        (icon.id === 22 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id))||
                                        // ZBA type
                                        (icon.id === 23 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                        // DB area
                                        (icon.id === 24 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                        // Account types
                                        (icon.id === 25 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                        // Bank account master
                                        (icon.id === 26 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                        // Statement controls
                                        (icon.id === 44 && [17].includes(permission.id)) ||
                                        // Statement format
                                        (icon.id === 45 && [1, 2, 4, 13, 17].includes(permission.id)) ||
                                        // Statement identifier
                                        (icon.id === 46 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                        // Mt940 uploads
                                        (icon.id === 47 && [17].includes(permission.id)) ||
                                        // BTC
                                        (icon.id === 48 && [1, 2, 10, 17].includes(permission.id)) ||
                                        // Statement analyser
                                        (icon.id === 49 && [3, 17, 18].includes(permission.id)) ||
                                        // Statement analyser 2
                                        (icon.id === 50 && [3, 17, 18].includes(permission.id)) ||
        
                                        // User Icon
                                        (icon.id === 108 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                        // Key data Icon
                                        (icon.id === 109 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                        // Maker and checker Icons
                                        (icon.id === 110 && permission.id === 17) ||
                                        // Roles Icon
                                        (icon.id === 111 && [1, 2, 4, 13 ,10 , 17, 18].includes(permission.id)) ||
                                        // Permission Icon
                                        (icon.id === 112 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                        
                                        (icon.id === 113 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id))||
                                      (icon.id === 114 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                      (icon.id === 115 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id)) )
        
                                    )||
                                    (userType == 4 &&
                                      (
                                        // Country
                                        (icon.id === 1 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                        // State provice
                                        (icon.id === 2 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                        // Calender
                                        (icon.id === 3 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                        // Currency
                                        (icon.id === 4 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                        // Bank
                                        (icon.id === 5 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                        // Language
                                        (icon.id === 6 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                        // Legal entity
                                        (icon.id === 7 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                        // Bu group
                                        (icon.id === 8 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                        // Company
                                        (icon.id === 9 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                        // Bank Id
                                        (icon.id === 10 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                        // Branch
                                        (icon.id === 11 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                        // Account status
                                        (icon.id === 21 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                        // Account no logic
                                        (icon.id === 22 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id))||
                                        // ZBA type
                                        (icon.id === 23 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                        // DB area
                                        (icon.id === 24 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                        // Account types
                                        (icon.id === 25 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                        // Bank account master
                                        (icon.id === 26 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                        // Statement controls
                                        (icon.id === 44 && [17].includes(permission.id)) ||
                                        // Statement format
                                        (icon.id === 45 && [1, 2, 4, 13, 17].includes(permission.id)) ||
                                        // Statement identifier
                                        (icon.id === 46 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                        // Mt940 uploads
                                        (icon.id === 47 && [17].includes(permission.id)) ||
                                        // BTC
                                        (icon.id === 48 && [1, 2, 17].includes(permission.id)) ||
                                        // Statement analyser
                                        (icon.id === 49 && [3, 17, 18].includes(permission.id)) ||
                                        // Statement analyser 2
                                        (icon.id === 50 && [3, 17, 18].includes(permission.id))
                                      
                                      )
                                    )||
                                    (userType == 8 &&
                                      (
                                        // Country
                                        (icon.id === 1 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                        // State provice
                                        (icon.id === 2 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                        // Calender
                                        (icon.id === 3 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                        // Currency
                                        (icon.id === 4 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                        // Bank
                                        (icon.id === 5 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                        // Language
                                        (icon.id === 6 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                        // Legal entity
                                        (icon.id === 7 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                        // Bu group
                                        (icon.id === 8 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                        // Company
                                        (icon.id === 9 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                        // Bank Id
                                        (icon.id === 10 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                        // Branch
                                        (icon.id === 11 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                        // Account status
                                        (icon.id === 21 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                        // Account no logic
                                        (icon.id === 22 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id))||
                                        // ZBA type
                                        (icon.id === 23 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                        // DB area
                                        (icon.id === 24 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                        // Account types
                                        (icon.id === 25 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                        // Bank account master
                                        (icon.id === 26 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                        // Statement controls
                                        (icon.id === 44 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                        // Statement format
                                        (icon.id === 45 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                        // Statement identifier
                                        (icon.id === 46 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                        // Mt940 uploads
                                        (icon.id === 47 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                        // BTC
                                        (icon.id === 48 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                        // Statement analyser
                                        (icon.id === 49 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                        // Statement analyser 2
                                        (icon.id === 50 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
        
                                         // Key data Icon
                                         (icon.id === 109 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                         // Maker and checker Icons
                                         (icon.id === 110 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                         // Roles Icon
                                         (icon.id === 111 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id))
                                        
                                      
                                      )
                                    )
                                    ;

                                    const isChecked =
                                      !isDisabled &&
                                      (selectedPermissions[icon.id]?.[
                                        permission.id
                                      ] ||
                                        false);

                                    const handleCheckboxChange = () => {
                                      if (!isDisabled) {
                                        handlePermissionChange(
                                          icon.id,
                                          permission.id,
                                          !isChecked
                                        );
                                      }
                                    };

                                    return (
                                      (icon.id === 1 && [4, 5, 6, 7, 8, 9, 11, 12, 13, 15].includes(permission.id)) ||
                            (icon.id === 2 && [4, 5, 6, 7, 8, 9, 11, 12, 13, 15].includes(permission.id)) ||
                            (icon.id === 3 && [4, 5, 6, 7, 8, 9, 11, 12, 13, 15].includes(permission.id)) ||
                            (icon.id === 4 && [4, 5, 6, 7, 8, 9, 11, 12, 13, 15].includes(permission.id)) ||
                            (icon.id === 5 && [4, 5, 6, 7, 8, 9, 11, 12, 13, 15].includes(permission.id)) ||
                            (icon.id === 6 && [4, 5, 6, 7, 8, 9, 11, 12, 13, 15].includes(permission.id)) ||
                            (icon.id === 7 && [8, 12].includes(permission.id)) ||
                            (icon.id === 8 && [8, 12].includes(permission.id)) ||
                            (icon.id === 9 && [8, 12].includes(permission.id)) ||
                            (icon.id === 10 && [8, 12].includes(permission.id)) ||
                            (icon.id === 11 && [8, 12].includes(permission.id)) ||
                            (icon.id === 21 && [4, 5, 6, 7, 8, 9, 11, 12, 18].includes(permission.id)) ||
                            (icon.id === 22 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18].includes(permission.id)) ||
                            (icon.id === 23 && [4, 5, 6, 7, 8, 9, 11, 12].includes(permission.id)) ||
                            (icon.id === 24 && [4, 5, 6, 7, 8, 9, 11, 12].includes(permission.id)) ||
                            (icon.id === 25 && [4, 5, 6, 7, 8, 9, 11, 12].includes(permission.id)) ||
                            (icon.id === 26 && [8, 12].includes(permission.id)) ||
                            
                            (icon.id === 44 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 18].includes(permission.id)) ||
                            (icon.id === 45 && [4, 5, 6, 7, 8, 9, 11, 12, 18].includes(permission.id)) ||
                            (icon.id === 46 && [2, 4, 5, 6, 7, 8, 11, 12].includes(permission.id)) ||
                            (icon.id === 47 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18].includes(permission.id)) ||
                            (icon.id === 48 && [4, 5, 6, 7, 8, 9, 11, 12, 13, 18].includes(permission.id)) ||
                            (icon.id === 49 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].includes(permission.id)) ||
                            (icon.id === 50 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].includes(permission.id)) ||

                            (icon.id === 110 && [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 16, 18].includes(permission.id)) ||
                            (icon.id === 112 && [8, 9, 12].includes(permission.id)) ||
                            (icon.id === 113 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18].includes(permission.id)) ||
                            (icon.id === 114 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18].includes(permission.id)) ||
                            (icon.id === 115 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18].includes(permission.id)) ||

                            (icon.id === 109 && [8, 9, 12].includes(permission.id)) ||
                            (icon.id === 111 && [8, 9, 12].includes(permission.id)) ? null : (
                                      <div
                                        key={`${icon.id}-${permission.id}`}
                                        className="flex items-center space-x-2"
                                      >
                                        <input
                                          type="checkbox"
                                          className="h-4 w-4 text-blue-600 border-gray-300 rounded"
                                          disabled
                                          checked={isChecked}
                                          onChange={handleCheckboxChange}
                                        />
                                        <span className="text-md text-customGray">
                                          {permission.name}
                                        </span>
                                        <span className="ml-2 text-customGray">
                                          {iconMapping[permission.name] || null}
                                        </span>
                                      </div>
                                    )
                                    );
                                  })}
                                </div>
                              ) : null}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            ))}
          </div>

          {isUserModalOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white p-0 w-[800px] h-[400px] flex flex-col rounded-2xl overflow-hidden">
                <div className="flex justify-between items-center p-4 shadow shadow-blue-100 bg-white sticky top-0 z-10">
                  <h2 className="text-lg font-semibold text-CustomBlue">
                    Select User ID
                  </h2>
                  <input
                    type="text"
                    placeholder="Search User ID..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="border border-gray-300 p-1 rounded w-80 ml-60 placeholder-gray-500 focus:outline-none focus:border-customBlue focus:ring-1 focus:ring-customBlue"
                  />
                  <button
                    onClick={closeUserModal}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    &times;
                  </button>
                </div>

                <div className="flex-1 overflow-auto p-4">
                  {users.length > 0 ? (
                    <div className="grid grid-cols-3 gap-4">
                      {users
                        .filter(
                          (user) =>
                            user.userId
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())
                          // || user.username.toLowerCase().includes(searchTerm.toLowerCase())
                        )
                        .map((user, index) => (
                          <div
                            key={user.userId}
                            className={`flex items-center ${
                              index % 2 === 0 ? "bg-sky-blue-200" : ""
                            }`}
                          >
                            <input
                              type="radio"
                              name="user Id"
                              value={user.userId}
                              checked={selectedUserId === user.userId} // Check if the user is selected
                              onChange={handleCheckboxChangeForUser}
                              className="mr-2 text-blue-200"
                            />
                            <label>{user.userId}</label>
                          </div>
                        ))}
                    </div>
                  ) : (
                    <p>No Users Found</p>
                  )}
                </div>

                <div className="flex justify-end p-2 bg-white sticky bottom-0 border-t">
                  <button
                    onClick={closeUserModal}
                    className="mt-2 bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 mr-20"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <Footer>
        {!isViewMode && (
          <ButtonGroup
            onSave={handleSubmit}
            onSaveContinue={handleSaveContinueDetails}
          />
        )}
      </Footer>
    </div>
  );
}
