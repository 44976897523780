// import * as XLSX from 'xlsx';
// import { saveAs } from 'file-saver';

// const exportTableToExcel = (users) => {
//   // Create a new workbook and a worksheet
//   const workbook = XLSX.utils.book_new();
//   const worksheet = XLSX.utils.json_to_sheet(users);

//   // Add the worksheet to the workbook
//   XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');

//   // Write the workbook to a binary string
//   const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

//   // Create a blob from the binary string and save it
//   const file = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
//   saveAs(file, 'Users.xlsx');
// };

//  export default exportTableToExcel;