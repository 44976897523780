// buttonConfigs.js
import {  IoSettingsOutline, } from 'react-icons/io5';
import { GiSettingsKnobs } from 'react-icons/gi';
import { MdOutlineFileDownload} from 'react-icons/md';
import { IoMdRefresh } from "react-icons/io";
import { TbCash } from 'react-icons/tb';


export const buttonConfigs = {
  screen1: [
    { icon: GiSettingsKnobs, onClick: () => console.log('Settings Knobs'), rotate: true },
    
    { icon: IoSettingsOutline, onClick: () => console.log('Settings') },
    { icon: MdOutlineFileDownload, onClick: () => console.log('Download') },
    { icon: IoMdRefresh , onClick: () => console.log('Settings Knobs'), rotate: true },
    
  ],
  screen2: [
    { icon: GiSettingsKnobs, onClick: () => console.log('Settings Knobs'), rotate: true },
    
    { icon: IoSettingsOutline, onClick: () => console.log('Settings') },
    { icon: MdOutlineFileDownload, onClick: () => console.log('Download') },
    { icon: IoMdRefresh , onClick: () => console.log('Settings Knobs'), rotate: true },
    { icon: TbCash, onClick: () => {},},
    
  ],
  // Add configurations for other screens
};


// buttonConfigs.js
