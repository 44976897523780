import React, { useEffect, useState } from "react";
import Header from "../../../../../components/Header";
import {
  IoAddCircleOutline,
  IoMdCheckmark,
  IoMdClose,
  IoMdCopy,
  IoMdCreate,
  IoMdTime,
  IoMdTrash,
} from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { bankStatementActionsConfig } from "../../props/ActionButtonConfig";
import BankStatementActions from "../../props/ActionButtons";
import {
  IoMdCheckmarkCircle,
  IoMdCloseCircle,
  IoMdTimer,
} from "react-icons/io";
import Mt940Service from "../../../../../rest-services/Mt940Services";
import Pagination from "../../../../../components/PageNation";
import { Checkbox } from "@mui/material";
import ReTable from "../../../../../components/Table";

export default function Mt940Table() {
  const [showExtraIcons, setShowExtraIcons] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const pageSize = 20;

  const [data, setData] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    fetchDataForTable(currentPage);
  }, [currentPage]);

  const extraButtons = [
    {
      icon: IoMdCheckmarkCircle,
      className: "text-green-500",
      onClick: () => console.log("Approve"),
    },
    { icon: IoMdCloseCircle, onClick: () => console.log("Cancel") },
    // { icon: IoMdCreate, onClick: () => {} },
    // { icon: IoMdCopy, onClick: () => console.log("Copy") },
    { icon: IoMdTimer, onClick: () => console.log("Time") },
    // { icon: IoMdTrash, onClick: () => console.log("Delete") },
  ];

  const existingButtons = bankStatementActionsConfig.screen4.map((button) => ({
    ...button,
  }));

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleCheckboxChange = (index) => {
    const updatedSelectedRows = selectedRows.includes(index)
      ? selectedRows.filter((row) => row !== index)
      : [...selectedRows, index];

    setSelectedRows(updatedSelectedRows);
    setShowExtraIcons(updatedSelectedRows.length > 0);
  };

  const fetchDataForTable = async (page) => {
    try {
      const response = await Mt940Service.getMt940MetaData(
        pageSize,
        (page - 1) * pageSize
      );

      let { mt940Meta, totalCount } = response.data.data;

      if (!mt940Meta) {
        mt940Meta = [];
      } else if (!Array.isArray(mt940Meta)) {
        mt940Meta = [mt940Meta];
      }

      setData(mt940Meta);
      setTotalCount(totalCount);
      setTotalPages(Math.ceil(totalCount / 20));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getSerialNumber = (index) => {
    return (currentPage - 1) * 20 + index + 1;
  };

  const columns = [
    {
      Header: "Process Name",
      accessor: "process_name",
      visible: true,
      width: 200,
    },
    {
      Header: "Statement Type",
      accessor: "statement_type",
      visible: true,
      width: 200,
    },
    {
      Header: "File Path",
      accessor: "file_path",
      visible: true,
      width: 200,
    },
    {
      Header: "Assign Bank Account ID",
      accessor: "account_id",
      visible: true,
      width: 200,
      Cell: ({ value }) => (
        <input type="checkbox" checked={value === "1"} readOnly />
      ),
    },
    {
      Header: "Transfer to BS",
      accessor: "transfer_to_bs",
      visible: true,
      width: 200,
      Cell: ({ value }) => (
        <input type="checkbox" checked={value === "1"} readOnly />
      ),
    },
    {
      Header: "Run Flow Mapping",
      accessor: "run_flow_mapping",
      visible: true,
      width: 200,
      Cell: ({ value }) => (
        <input type="checkbox" checked={value === "1"} readOnly />
      ),
    },

    {
      Header: "Run Account Mapping",
      accessor: "run_account_mapping",
      visible: true,
      width: 200,
      Cell: ({ value }) => (
        <input type="checkbox" checked={value === "1"} readOnly />
      ),
    },
    { Header: "Created By", accessor: "created_by", visible: true, width: 200 },
    {
      Header: "Created Date",
      accessor: "creationDate",
      visible: true,
      width: 15,
      canResize: true,
    },
    {
      Header: "Created Time",
      accessor: "creationTime",
      visible: true,
      width: 15,
      canResize: true,
    },
    {
      Header: "Updated By",
      accessor: "changed_by",
      visible: true,
      width: 15,
      canResize: true,
    },
    {
      Header: "Updated Date",
      accessor: "changedDate",
      visible: true,
      width: 15,
      canResize: true,
    },
    {
      Header: "Updated Time",
      accessor: "changedTime",
      visible: true,
      width: 15,
      canResize: true,
    },
  ];

  const [columnSettings, setColumnSettings] = useState(columns);

  const modifiedColumns = columnSettings
    .filter((col) => col.visible)
    .map((col) => ({
      ...col,
      width: col.width,
    }));

  const handleSelectRow = (id) => {
    handleCheckboxChange(id);
  };

  const handleSelectAll = () => {
    handleSelectAllChange();
  };
  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedRows([]);
    } else {
      const allIds = data.map((item) => item.id);
      setSelectedRows(allIds);
    }
    setIsAllSelected(!isAllSelected);
  };

  const handleCheckboxChanges = (id) => {
    const updatedSelectedRows = selectedRows.includes(id)
      ? selectedRows.filter((rowId) => rowId !== id)
      : [...selectedRows, id];

    setSelectedRows(updatedSelectedRows);
  };
  return (
    <div>
      <Header title={"Bank Statements > MT940 > MT940 Uploads"} />
      <BankStatementActions
        buttons={[...existingButtons, ...(showExtraIcons ? extraButtons : [])]}
      />
      <div className="p-4">
        <div className="p-2">
          <div>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              totalCount={totalCount}
            />
          </div>
          <div>
            <ReTable
              data={data}
              columns={modifiedColumns}
              selectedRows={selectedRows}
              onSelectRow={handleSelectRow}
              onSelectAll={handleSelectAll}
              //onRowDoubleClick={handleDoubleClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
