import React, { useState } from 'react'
import NoActionLayout from '../../../../../components/NoActionLayout';
import CashFlowActions from '../../Components/CashFlowActions';
import { CashFlowbuttonsConfigs } from '../../Components/CashFlowbuttonsConfig';
import NotAssignedtable from '../../Tables/FlowAnalysis/NotAssignedtable';
import { Checkbox } from '@mui/material';
import { CiSearch } from 'react-icons/ci';

export default function NotAssigned() {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleLayout = () => {
    setIsExpanded(prevState => !prevState);
  };

  const buttons = CashFlowbuttonsConfigs.screen2.map(button => ({
    ...button,
    onClick: button.icon === CiSearch
      ? toggleLayout // Invoke the toggleLayout function directly
      : button.onClick
  }));

    return (
      <div>
        <div>
          <NoActionLayout title="Cash Flow > Flow Analysis > Cash Flows " />
        </div>
        <div className='flex justify-between'>
        <div>
          <CashFlowActions buttons={buttons} 
           
          />
        </div>
        {isExpanded && (<div className='flex mr-16 p-4'>
          <button className=' mr-4 border border-blue-500 text-sm text-blue-500 py-1 px-2   rounded hover:bg-blue-100 transition duration-200 ease-in-out w-16 h-8 '>Reset</button>
          <button className='mr-4 bg-blue-500 text-sm text-white py-1 px-2   rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 h-8 '>Save</button>
          
        </div>)}
        
        </div>
        <div  
          className={`transition-opacity duration-900 ease-in-out ${
            isExpanded ? 'opacity-100' : 'opacity-0'
          }`}
        >
        {isExpanded && (
          <div className="grid grid-cols-2 gap-2 w-full  p-4">
   
    <div className="grid grid-cols-2 gap-2 col-span-2 h-48 ">
      <div className="border p-4 rounded-md bg-[#f3f4f6] ">
      <div className="grid grid-cols-2 gap-x-8 gap-y-6">
      <label className="flex items-center group">
      <span className="w-48 text-sm font-medium text-gray-500">CompanyCode</span>
      <span className="ml-2">:</span>
      <input
        type="text"
        className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
        placeholder="Enter main head"
      />
    </label>
    
    <label className="flex items-center group">
      <span className="w-48 text-sm font-medium text-gray-500">Bank ID</span>
      <span className="ml-4">:</span>
      <input
        type="text"
        className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
        placeholder="Enter sub heads"
      />
    </label>
    
    <label className="flex items-center group">
      <span className="w-48 text-sm font-medium text-gray-500">Country</span>
      <span className="ml-4">:</span>
      <input
        type="text"
        className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
        
      />
    </label>
    
    <label className="flex items-center group">
      <span className="w-48 text-sm font-medium text-gray-500">Branch </span>
      <span className="ml-4">:</span>
      <input
        type="text"
        className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
        placeholder="Enter additional info"
      />
    </label>
    <label className="flex items-center group">
      <span className="w-48 text-sm font-medium text-gray-500">Bank Country</span>
      <span className="ml-4">:</span>
      <input
        type="text"
        className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
        
      />
    </label>
    
    <label className="flex items-center group">
      <span className="w-48 text-sm font-medium text-gray-500">Account ID</span>
      <span className="ml-4">:</span>
      <input
        type="text"
        className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
        placeholder="Enter additional info"
      />
    </label>
    </div>
      </div>
      <div className="border p-4 rounded-md bg-[#f3f4f6] ">
      <div className="grid grid-cols-2 gap-x-8 gap-y-6">
      <label className="flex items-center group">
      <span className="w-48 text-sm font-medium text-gray-500">Value Date</span>
      <span className="ml-4">:</span>
      <input
        type="text"
        className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
        placeholder="Enter main head"
      />
    </label>
    
    <label className="flex items-center group">
      <span className="w-48 text-sm font-medium text-gray-500">Statement Imported Date</span>
      <span className="ml-4">:</span>
      <input
        type="text"
        className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
        placeholder="Enter sub heads"
      />
    </label>
    
    <label className="flex items-center group">
      <span className="w-48 text-sm font-medium text-gray-500">Uploaded By</span>
      <span className="ml-4">:</span>
      <input
        type="text"
        className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
        
      />
    </label>
    </div>
      </div>
    </div>
   
    <div className="grid grid-cols-2 gap-2 col-span-1 h-48 rounded ">
      <div className="border p-4 rounded-md bg-[#f3f4f6]">
      <label className="flex items-center group">
      <span className="w-48 text-sm font-medium text-gray-500">Cash Flow </span>
      <span className="ml-4">:</span>
      <input
        type="text"
        className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
        
      />
    </label>
      </div>
      <div className="border p-4 rounded-md bg-[#f3f4f6]">
      <label className="flex items-center group">
      <span className="w-48 text-sm font-medium text-gray-500">Company Code</span>
      <span className="ml-4">:</span>
      <input
        type="text"
        className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
        
      />
    </label>
      </div>
    </div>
   
    <div className="col-span-1 border p-4 rounded-md bg-[#f3f4f6]">
    <div className='flex '>
    <Checkbox className='form-checkbox text-blue-500 border-blue-500 rounded transform scale-75'/>
    <p className='ml-4 mt-2 font-small text-gray-600'>Manually Changed</p>
  </div>
   <div className='flex '>
    <Checkbox className='form-checkbox text-blue-500 border-blue-500 rounded transform scale-75'/>
    <p className='ml-4 mt-2 font-small text-gray-600'>Not Assigned</p>
  </div>
    </div>
  </div>
  
        )}
        </div>
        <NotAssignedtable/>
      </div>
    )
  }
