import React from 'react';
import { IoAddCircleOutline, IoSettingsOutline } from 'react-icons/io5';
import { GiSettingsKnobs } from 'react-icons/gi';
import { MdOutlineFileDownload, MdOutlineFileUpload } from 'react-icons/md';
import { IoMdRefresh } from "react-icons/io";
import { MdEdit, MdContentCopy, MdAccessTime, MdDelete } from 'react-icons/md'; // Import new icons

const Actions = ({ onAddCountry, showActionIcons }) => {
  return (
    <div className="px-9">
      <div className="flex justify-between items-center"> {/* Distribute space between start and end icons */}
        
       
        <div className="flex items-center space-x-2">
          {[
            { Component: IoAddCircleOutline, onClick: onAddCountry },
            { Component: GiSettingsKnobs, extraClass: "rotate-90" },
            { Component: IoSettingsOutline },
            { Component: MdOutlineFileDownload },
            { Component: MdOutlineFileUpload },
            { Component: IoMdRefresh }
          ].map(({ Component, onClick, extraClass }, index) => (
            <button key={index} className="bg-secondary text-secondary-foreground p-2 rounded-lg" onClick={onClick}>
              <Component className={`bg-white text-3xl border border-dark-gray cursor-pointer p-1.5 ${extraClass || ''}`} />
            </button>
          ))}
        </div>
        
     
        {showActionIcons && (
          <div className="flex items-center space-x-2">
            {[
              MdEdit,
              MdContentCopy,
              MdAccessTime,
              MdDelete
            ].map((IconComponent, index) => (
              <button key={index} className="bg-secondary text-secondary-foreground p-2 rounded-lg">
                <IconComponent className="bg-white text-3xl border border-dark-gray cursor-pointer p-1.5" />
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Actions;
