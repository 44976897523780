import React, { useState, useEffect } from "react";
import NoActionLayout from "../../../../../../components/NoActionLayout";
import { Alert } from "@mui/material";
import UserService from "../../../../../../rest-services/UserServices";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useToast } from "../../../../../../components/toast/toast";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ButtonGroup from "../../../../../../components/FooterButton";
import Header from "../../../../../../components/Header";
import Tabs from "../../../../../../components/Tabs";
import { RxDropdownMenu } from "react-icons/rx";
import Footer from "../../../../../../components/footer";
import LanguageServices from "../../../../../../rest-services/LanguagesServices";
import { TbFlag3Filled } from "react-icons/tb";
import LabelWithButton from "../../../../../../components/InputButtonField";
import ReusableModal from "../../../../../../components/PopupComponent";
import CustomDropdown from "../../../../../../components/CustomDropdown";
import CustomDatePicker from "../../../../../../components/DatePicker";
import DatePickerComponent from "../../../../../../components/DatePicker";
import TableWrapper from "../../../../../../components/ScrollBarComponent";
export default function UserAdd() {
  const navigate = useNavigate();
    const { UserId } = useParams();
    const location = useLocation();
    const { mode } = location.state || {}; // Get the mode from the state
    const isViewMode = mode === 'view';
    const isEditMode = mode === 'Edit';
   const isCopyAddMode = mode === 'add';

  const { toast } = useToast();
  const [activeTab, setActiveTab] = useState("basicDetail");
  const [assignee, setAssignee] = useState({ id: "", username: "" });
  const [userId, setUserId] = useState("");
  const [username, setUserName] = useState("");
  const [accountingSystemId, setAccountingSystemId] = useState("");
  const [salute, setSalute] = useState("");
  const [userType, setUserType] = useState(0);
  const [userTypes, setUserTypes] = useState([]);

  const [firstName, setFirstName] = useState("");
  const [oneTimePassword, setOneTimePassword] = useState("");
  const [receiveSystemMail, setreceiveSystemMail] = useState("");
  const [downloadData, setdownloadData] = useState("");
  const [changesInCashflow, setchangesInCashflow] = useState("");
  const [changeVarient, setchangeVarient] = useState("");
  const [deleteAllRecords, setdeleteAllRecords] = useState("");
  const [changeAccountingCode, setchangeAccountingCode] = useState("");
  const [updateCountry, setupdateCountry] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [surname, setSurname] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [whatsappNo, setWhatsappNo] = useState("");
  const [officeNo, setOfficeNo] = useState("");
  const [extension, setExtension] = useState("");
  const [passportNo, setPassportNo] = useState("");
  const [drivingLicenseNo, setDrivingLicenseNo] = useState("");
  const [localId, setLocalId] = useState("");
  const [nationality, setNationality] = useState("");
  const [employeeNo, setemployeeNo] = useState("");
  const [district, setdistrict] = useState("");
  const [poBox, setpoBox] = useState("");
  const [state, setstate] = useState("");
  const [stateName, setStateName] = useState(''); 
  // const [countryCode, setcountryCode] = useState("");
  const [countryCode, setCountryCode] = useState('');
  const [countryId, setCountryId] = useState('');
  const [areaBlock, setareaBlock] = useState("");
  const [roadNo, setroadNo] = useState("");
  const [roadName, setRoadName] = useState("");
  const [streetNo, setstreetNo] = useState("");
  const [buildingName, setbuildingName] = useState("");
  const [buildingNo, setbuildingNo] = useState("");
  const [doorNo, setdoorNo] = useState("");
  const [floorNo, setfloorNo] = useState("");
  const [department, setdepartment] = useState("");
  const [validFrom, setvalidFrom] = useState("");
  const [validTo, setvalidTo] = useState("");
  const [joiningDate, setjoiningDate] = useState("");
  const [resignationDate, setresignationDate] = useState("");
  const [addressLine1, setaddressLine1] = useState("");
  const [addressLine2, setaddressLine2] = useState("");
  const [addressLine3, setaddressLine3] = useState("");
  const [language, setlanguage] = useState("");
  const [languages, setlanguages] = useState([]);

  const [dateFormat, setdateFormat] = useState("");
  const [timeFormat, settimeFormat] = useState("");
  const [timeZone, settimeZone] = useState("");
  // const [countryId, setcountryId] = useState("");
  // const [countries, setCountry] = useState([]);
  const [countries, setCountries] = useState([]);
  const [stateProvinces, setStateProvinces] = useState([]);
  const [companyCodes, setCompanyCodes] = useState([]);
  
  const [approved, setApproved] = useState("");

  const [streetName, setstreetName] = useState("");
  const [areaName, setareaName] = useState("");
  // const [cityName, setcityName] = useState("");
  const [landMark, setlandMark] = useState("");
  const [zipCode, setzipCode] = useState("");
  const [amountFormat, setamountFormat] = useState("");
  const [postalCode, setpostalCode] = useState("");
  const [thousandSeparator, setthousandSeparator] = useState("");
  const [decimalSeparator, setdecimalSeparator] = useState("");

  const [acceleratorForThousands, setacceleratorThousands] = useState("");

   // Initialize these states only if UserId is not present
  //  const [suffixForThousands, setSuffixForThousands] = useState(
  //   !UserId ? 'K' : ''
  // );
  const [suffixForThousands, setSuffixForThousands] = useState('');

  const [errorThousands, setErrorThousands] = useState(
    !UserId ? false : null
  );
  
  // const [suffixForThousands, setSuffixForThousands] = useState('K'); // Holds the suffix ('K' or 'T')
  // const [errorThousands, setErrorThousands] = useState(false);

  const [acceleratorForLakhs, setacceleratorLakhs] = useState("");
  // const [suffixForLakhs, setSuffixForLakhs] = useState(
  //   !UserId ? 'L' : '');

    const [suffixForLakhs, setSuffixForLakhs] = useState('');

  const [errorLakhs, setErrorLakhs] = useState( !UserId ? false : null);

  const [acceleratorForMillions, setacceleratorMillions] = useState("");
  // const [suffixForMillions, setSuffixForMillions] = useState(
  //   !UserId ? 'M' : ''); 
    const [suffixForMillions, setSuffixForMillions] = useState(''); 
  const [errorMillions, setErrorMillions] = useState( !UserId ? false : null);

  const [loginMethod, setLoginMethod] = useState(0);

  const [alert, setAlert] = useState(null);
  const [errors, setErrors] = useState({});
  const [error, setError] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(countryCode || "");
  const [selectedState, setSelectedState] = useState(state || "");
  const [companyCodeId, setcompanyCodeId] = useState("");
  const [selectedCompany, setSelectedCompany] = useState(companyCodeId || "");
  const[ selectedlanguage,setSelectedLanguae]=useState("");

  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [searchQueryState, setSearchQueryState] = useState(""); // State for search query
  const [searchQueryCompanyCode, setSearchQueryCompanyCode] = useState(""); // State for search query
  const [isValidationTriggered, setIsValidationTriggered] = useState(false);

  const [openDropdown, setOpenDropdown] = useState(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleLoginMethodChange = (e) => {
    setLoginMethod(Number(e.target.value));
  };

  useEffect(() => {
    fetchAssignee();
    fetchCompanyCode();
    fetchUserTypes();
    fetchcountry();
    fetchLanguages();
  }, []);
  
  useEffect(() => {
    if (UserId) {
      fetchAddressDetails();
      fetcheditUserDetails();
      fetchUserPrefernceDetails();
    }
  }, [UserId]);

  useEffect(() => {
    if (countryCode) {
      if (!UserId) {
      fetchUserPrefernceDetailsByCountry();
      }
      fetchstateprovice();
    }
  }, [countryCode]);



  const fetchAddressDetails = async () => {
    try {
      const data = { userId: UserId };
      console.log("Request Data:", data);
      
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-address-details`,
        data
      );
      
      console.log("API Response:", response);
      console.log("Response Data user address details:", response.data);
    
      if (response.data) {
        // Update state with fetched address details
        setdoorNo(response.data.doorNo || '');
        setfloorNo(response.data.floorNo || '');
        setbuildingNo(response.data.buildingNo || '');
        setbuildingName(response.data.buildingName || '');
        setlandMark(response.data.landMark || '');
        setstreetNo(response.data.streetNo || '');
        setstreetName(response.data.streetName || '');
        setroadNo(response.data.roadNo || '');
        setRoadName(response.data.roadName || '');
        setareaBlock(response.data.areaBlock || '');
        setareaName(response.data.areaName || '');
        setaddressLine1(response.data.addressLine1 || '');
        setaddressLine2(response.data.addressLine2 || '');
        setaddressLine3(response.data.addressLine3 || '');
        setCountryCode(response.data.countryCode || '');
        setCountryName(response.data.Country.country_name || '');
        setCountryId(response.data.countryId || '');
        setstate(response.data.state || '');
        setStateName(response.data.StateProvince.state_name || '');
        setpoBox(response.data.poBox || '');
        setdistrict(response.data.district || '');
        setpostalCode(response.data.postalCode || '');
        setzipCode(response.data.zipCode || '');
      }
    } catch (error) {
      console.error("Error fetching address details:", error);
      setAlert({
        severity: "error",
        message: "An error occurred while fetching address details.",
      });
    }
  };
  const fetcheditUserDetails = async () => {

    try {
        const data = { userId: UserId };
        console.log("Request Data:", data);
        
        const response = await UserService.saveContactDetails(
          `${process.env.REACT_APP_BASE_URL}/get-edit-user-details`,
          data
        );
        
        console.log("API Response:", response);
        console.log("Response Data:", response.data);
      
        if (response.data) {
          if (!isCopyAddMode) {
            setUserId(response.data.userId || '');
            setUserName(response.data.username || '');
            setApproved(response.data.approved || '');
            setSalute(response.data.salute || '');
            setUserType(response.data.userType || '');
            setAccountingSystemId(response.data.accountingSystemId || '');
            setSurname(response.data.surname || '');
            setFirstName(response.data.firstName || '');
            setLastName(response.data.lastName || '');
            setMiddleName(response.data.middleName || '');
            setEmail(response.data.email || '');
            setMobileNo(response.data.mobileNo || '');
            setWhatsappNo(response.data.whatsappNo || '');
            setOneTimePassword(response.data.oneTimePassword || '');
            setOfficeNo(response.data.officeNo || '');
            setExtension(response.data.extension || '');
            setNationality(response.data.nationality || '');
            setPassportNo(response.data.passportNo || '');
            setDrivingLicenseNo(response.data.drivingLicenseNo || '');
            setLocalId(response.data.localId || '');
          }
          // Update state with fetched address details
         
          setemployeeNo(response.data.employeeNo || '');
          setcompanyCodeId(response.data.companyCodeId || '');
          setdepartment(response.data.department || '');
          setvalidFrom(response.data.validFrom || '');
          setvalidTo(response.data.validTo || '');
          setresignationDate(response.data.resignationDate || '');
          setjoiningDate(response.data.joiningDate || '');
          setLoginMethod(response.data.loginMethod || '');
          

          
        }
      } catch (error) {
        console.error("Error fetching address details:", error);
        setAlert({
          severity: "error",
          message: "An error occurred while fetching address details.",
        });
      }
    };
    const fetchUserPrefernceDetailsByCountry = async () => {
      try {
        const data = { country: countryCode };
        console.log("Request Data:", data);
        
        const response = await UserService.saveContactDetails(
          `${process.env.REACT_APP_BASE_URL}/get-edit-user-preference-details-by-country`,
          data
        );
        
        console.log("API Response:", response);
        console.log("Response Data:", response.data);
      
        if (response.data) {
          // Update state with fetched address details
          setlanguage(response.data.language || '');
          setdateFormat(response.data.dateFormat || '');
          setamountFormat(response.data.amountFormat || '');
          setthousandSeparator(response.data.thousandSeparator || '');
          setdecimalSeparator(response.data.decimalSeparator || '');
          setacceleratorThousands(response.data.acceleratorForThousands || '');
          setacceleratorMillions(response.data.acceleratorForMillions || '');
          setacceleratorLakhs(response.data.acceleratorForLakhs || '');
          settimeFormat(response.data.timeFormat || '');
          settimeZone(response.data.timeZone || '');
  
          setreceiveSystemMail(response.data.receiveSystemMail || '');
          setdownloadData(response.data.downloadData || '');
          setchangesInCashflow(response.data.changesInCashflow || '');
          setupdateCountry(response.data.updateCountry || '');
          setchangeVarient(response.data.changeVarient || '');
          setdeleteAllRecords(response.data.deleteAllRecords || '');
          setchangeAccountingCode(response.data.changeAccountingCode || '');
        
         
        }
      } catch (error) {
        console.error("Error fetching address details:", error);
        setAlert({
          severity: "error",
          message: "An error occurred while fetching address details.",
        });
      }
    };

    const fetchUserPrefernceDetails = async () => {
      try {
        const data = { userId: UserId };
        console.log("Request Data:", data);
        
        const response = await UserService.saveContactDetails(
          `${process.env.REACT_APP_BASE_URL}/get-edit-user-preference-details`,
          data
        );
        
        console.log("API Response:", response);
        console.log("Response Data from user preference details:", response.data);
      
        if (response.data) {
          // Update state with fetched address details
          console.log("Language get",response.data.language);
          setlanguage(response.data.language || '');
          setdateFormat(response.data.dateFormat || '');
          setamountFormat(response.data.amountFormat || '');
          setthousandSeparator(response.data.thousandSeparator || '');
          setdecimalSeparator(response.data.decimalSeparator || '');
          setacceleratorThousands(response.data.acceleratorForThousands || '');
          setacceleratorMillions(response.data.acceleratorForMillions || '');
          setacceleratorLakhs(response.data.acceleratorForLakhs || '');
          settimeFormat(response.data.timeFormat || '');
          settimeZone(response.data.timeZone || '');
  
          setreceiveSystemMail(response.data.receiveSystemMail || '');
          setdownloadData(response.data.downloadData || '');
          setchangesInCashflow(response.data.changesInCashflow || '');
          setupdateCountry(response.data.updateCountry || '');
          setchangeVarient(response.data.changeVarient || '');
          setdeleteAllRecords(response.data.deleteAllRecords || '');
          setchangeAccountingCode(response.data.changeAccountingCode || '');
        
         
        }
      } catch (error) {
        console.error("Error fetching address details:", error);
        setAlert({
          severity: "error",
          message: "An error occurred while fetching address details.",
        });
      }
    };

  const fetchLanguages = async () => {
    try {
      const response = await LanguageServices.getAllLanguages(`${process.env.REACT_APP_BASE_URL}/get-language`);
      if (response && response.data) {
        console.log("response data for language",response.data.data);
        setlanguages(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching Language data', error);
    } 
  };

    // Use effect to update validTo when resignationDate changes
    useEffect(() => {
      if (resignationDate) {
        setvalidTo(resignationDate);
      }
    }, [resignationDate]);

    


    // Fetch user types from the API
    const fetchUserTypes = async () => {
      try {
        const response = await UserService.getUsers(
          `${process.env.REACT_APP_BASE_URL}/get-user-type`
          
        );
        setUserTypes(response.data.data);
      } catch (error) {
        console.error("Error fetching user types:", error);
      }
    };

    const fetchcountry = async () => {
      try {
        const response = await UserService.getUsers(`${process.env.REACT_APP_BASE_URL}/get-country`);
        console.log("country data fetch",response.data.data);
    setCountries(response.data.data);
  } catch (error) {
    console.error("Error fetching countries:", error);
  }
};

const handleSearchChange = (e) => {
  setSearchQuery(e.target.value);
};

const handleSearchChangeState = (e) => {
  setSearchQueryState(e.target.value);
};

const handleSearchChangeCompanyCode  = (e) => {
  setSearchQueryCompanyCode(e.target.value);
};

const fetchstateprovice = async () => {
  try {
    const data = { Country_Code: countryCode };
console.log('fetching data country',countryCode);
    console.log("fetch country in state", countryCode);

    const response = await UserService.saveContactDetails(
          `${process.env.REACT_APP_BASE_URL}/get-stateprovince-by-country`,
          data
        );
        // console.log("API Response:", response);
        console.log("state province Response Data:", response.data);

    setStateProvinces(response.data);
} catch (error) {
console.error("Error fetching countries:", error);
}

}

const fetchCompanyCode = async () => {
  try {
    const response = await UserService.getUsers(
              `${process.env.REACT_APP_BASE_URL}/get-Company-code-details`);
            // console.log("API Response:", response);
            console.log("Company code Response Data:", response.data.data);

            setCompanyCodes(response.data.data);
    } catch (error) {
    console.error("Error fetching countries:", error);
    }
  }

  const fetchAssignee = async () => {
    try {
      const userDetailString = sessionStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          userId: userDetail.userId || "",
          clientId: userDetail.clientId || "",
          assigner: userDetail.username || "",
        };
        
        setAssignee(assigneeData);
        
        // Log the id and assigner values to the console
        console.log("Assignee ID:", assigneeData.id);
        console.log("Assigner:", assigneeData.assigner);
        console.log("Assigner clientId:", assigneeData.clientId);
        console.log("Assigner userId:", assigneeData.userId);
        
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  const handleValidFromChange = (date) => {
    setUnsavedChanges(true);

    if (date && validTo && new Date(date) >= new Date(validTo)) {
      setErrors({
        ...errors,
        validFrom: "Valid From date cannot be after Valid To date",
      });
    } else {
      setErrors({ ...errors, validFrom: "" });
      setvalidFrom(date);
    }
  };

  const handleJoiningChange = (date) => {
    if (date && validFrom && new Date(date) <= new Date(validFrom)) {
      setErrors({
        ...errors,
        validTo: "Valid To date cannot be before Valid From date",
      });
    } else {
      setErrors({ ...errors, validTo: "" });
      setvalidTo(date);
    }
  };

  const handleValidToChange = (date) => {
    setUnsavedChanges(true);

    if (date && validFrom && new Date(date) <= new Date(validFrom)) {
      setErrors({
        ...errors,
        validTo: "Valid To date cannot be before Valid From date",
      });
    } else {
      setErrors({ ...errors, validTo: "" });
      setvalidTo(date);
    }
  };


  const checkDuplicateUseremail = async (name) => {
    try {
      const response = await UserService.checkDuplicateRoleName(
        `${process.env.REACT_APP_BASE_URL}/check-useremail-duplicate`,
        { email: name }  // Sending roleName to backend
      );
      
      console.log("Response from server:", response);
  
      if (response.message === "User email already exists") {
        // Check if it's a duplicate
      console.log("Response from internal server:", response);
  setErrors({ email: ' ' });

   toast("error", "User email already exists.");
       
      }
      console.log("Response from external server:", response);

    } catch (error) {
      console.error("Error checking duplicate role name:", error);
      // Handle potential errors when making the API call
      setErrors(prev => ({ ...prev, email: "Error checking role name" }));
    }
  };

  const handleThousandChange = (e) => {
    setUnsavedChanges(true);

    const value = e.target.value;
    // Only allow '.' or ',' in the thousand separator field
    if (value === '' || value === '.' || value === ',') {
      setthousandSeparator(value);
      setError((prevError) => ({ ...prevError, thousand: false }));  // Reset thousand separator error

      // Check if thousand and decimal separators are the same
      if (value === decimalSeparator && value !== '') {
        setError((prevError) => ({ ...prevError, same: true }));
      } else {
        setError((prevError) => ({ ...prevError, same: false }));
      }
    } else {
      setError((prevError) => ({ ...prevError, thousand: true }));
    }
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex pattern

  const handleEmailChange = (e) => {
    setUnsavedChanges(true);
    const value = e.target.value;
    setEmail(value);

    setErrors(prev => ({ ...prev, userId: "" }));
  
    // Check for duplicate role names when typing
    if (value.trim() !== "") {
      // checkDuplicateUseremail(value);
    }


    // Validate email
    if (value.length > 80) {
      setErrors({ email: 'Email must not exceed 80 characters.' });
    } else if (!emailRegex.test(value)) {
      setErrors({ email: 'Please enter a valid email address.' });
    } else {
      setErrors({ email: '' });
    }
  };


  const handleDecimalChange = (e) => {
    setUnsavedChanges(true);

    const value = e.target.value;
    // Only allow '.' or ',' in the decimal separator field
    if (value === '' || value === '.' || value === ',') {
      setdecimalSeparator(value);
      setError((prevError) => ({ ...prevError, decimal: false }));  // Reset decimal separator error

      // Check if thousand and decimal separators are the same
      if (value === thousandSeparator && value !== '') {
        setError((prevError) => ({ ...prevError, same: true }));
      } else {
        setError((prevError) => ({ ...prevError, same: false }));
      }
    } else {
      setError((prevError) => ({ ...prevError, decimal: true }));
    }
  };

  const handleChangeforMillions = (e) => {
    setUnsavedChanges(true);

    let value = e.target.value;

    // Extract numeric part and any 'K' or 'T'
    const numericPart = value.replace(/[^\d]/g, ''); // Remove non-numeric characters
    const suffixPart3 = value.replace(/\d/g, ''); // Extract suffix (K or T)

    // Only allow 'K', 'T', or empty input for the suffix
    if (suffixPart3 === '' || suffixPart3 === 'M') {
      setSuffixForMillions(suffixPart3);
      setErrorMillions(false);
    } else {
      setErrorMillions(true);
    }

    setacceleratorMillions(numericPart);
  };

  const handleChangeforLakhs = (e) => {
    setUnsavedChanges(true);

    let value = e.target.value;

    // Extract numeric part and any 'K' or 'T'
    const numericPart = value.replace(/[^\d]/g, ''); // Remove non-numeric characters
    const suffixPart2 = value.replace(/\d/g, ''); // Extract suffix (K or T)

    // Only allow 'K', 'T', or empty input for the suffix
    if (suffixPart2 === '' || suffixPart2 === 'L') {
      setSuffixForLakhs(suffixPart2);
      setErrorLakhs(false);
    } else {
      setErrorLakhs(true);
    }

    setacceleratorLakhs(numericPart);
  };
  
  
  const handleChangeforThousands = (e) => {
    setUnsavedChanges(true);

    let value = e.target.value;

    // Extract numeric part and any 'K' or 'T'
    const numericPart = value.replace(/[^\d]/g, ''); // Remove non-numeric characters
    const suffixPart = value.replace(/\d/g, ''); // Extract suffix (K or T)

    // Only allow 'K', 'T', or empty input for the suffix
    if (suffixPart === '' || suffixPart === 'K' || suffixPart === 'T') {
      setSuffixForThousands(suffixPart);
      setErrorThousands(false);
    } else {
      setErrorThousands(true);
    }

    setacceleratorThousands(numericPart);
  };

  const validateFields = () => {
  setIsValidationTriggered(true);

    const newErrors = {};
  
    const isAddressSectionFilled = addressLine1 || addressLine2 || addressLine3;
    const isBuildingSectionFilled = doorNo || floorNo || buildingNo || buildingName || landMark;
    const isStreetSectionFilled = streetNo || streetName || roadNo || roadName;
    const isAreaSectionFilled = areaBlock || areaName;
    const isBuildingStreetAreaSectionFilled = isBuildingSectionFilled || isStreetSectionFilled || isAreaSectionFilled;
  
    if (!userId) newErrors.userId = " ";
    if (!username) newErrors.username = " ";
    if (!firstName) newErrors.firstName = " ";
    if (!lastName) newErrors.lastName = " ";
    if (!email) newErrors.email = " ";
    if (!mobileNo) newErrors.mobileNo = " Mobile Number Required";
    if (!nationality) newErrors.nationality = " ";
    if (!validFrom) newErrors.validFrom = " ";
    if (!validTo) newErrors.validTo = " ";
  
    if (!isAddressSectionFilled && !isBuildingStreetAreaSectionFilled) {
      newErrors.addressSection = "Either Address Section or Building, Street, and Area Section must be entered";
    }
  
    // if (!countryId) newErrors.countryId = " ";
    if(stateProvinces.length > 0 ){
      console.log("State prv is show error");
      if (!state) newErrors.state = " ";
    }
    if (!countryCode) newErrors.countryCode = " ";
    if (!language) newErrors.language = " ";
    if (!dateFormat) newErrors.dateFormat = " ";
    if (!timeFormat) newErrors.timeFormat = " ";
  
    setErrors(newErrors);
  
    // Show toast only if there are errors
    if (Object.keys(newErrors).length > 0) {
      toast("warning", "Missing required fields.");
      return false;
    }
  
    return true;
  };

  
const handleSaveContinueDetails = async () => {
if (!validateFields()) return;
if(isEditMode){
  try {
    console.log("entered");
    const data = {
      userId:UserId,
      username,
      accountingSystemId,
      salute,
      userType,
      firstName,
      middleName,
      surname,
      lastName,

      // module: 'Admin',
      // icon: 'User',
      // action: 'Edit',
      changedBy: assignee.userId,
      oneTimePassword: oneTimePassword || 0,
      // createdBy: assignee.userId,
    
      email, mobileNo, whatsappNo ,officeNo,extension,
      passportNo, drivingLicenseNo,localId,nationality,
      language, dateFormat,thousandSeparator,decimalSeparator,
      acceleratorForThousands: acceleratorForThousands + suffixForThousands,
      acceleratorForMillions: acceleratorForMillions + suffixForMillions,
      acceleratorForLakhs: acceleratorForLakhs + suffixForLakhs,
      timeFormat,timeZone,receiveSystemMail,downloadData,changesInCashflow,updateCountry,changeVarient,deleteAllRecords,changeAccountingCode,
      streetName, addressLine1, addressLine2,addressLine3, zipCode, postalCode,
      employeeNo, companyCodeId,department,validFrom,validTo,joiningDate,resignationDate,
      loginMethod, 
      doorNo,floorNo,buildingNo,buildingName,landMark,streetNo,roadNo,roadName,areaBlock,areaName,countryCode,state,poBox, district
    };
    const response = await UserService.saveAllUserDetails(
      `${process.env.REACT_APP_BASE_URL}/edit-user`,
      data
    );
    console.log("response data", data);
    console.log("response", response);
    console.log("response status", response.status);
    if (response.status === 200) {
      setAlert({
        severity: "success",
        message: "Basic Details saved successfully.",
      });

        // Wait for the success message to be displayed before navigating
        toast("success", "User Edited successfully");
        handleTabClick("basicDetail");  // Navigate to loginMethod Details tab
    } else {
      setAlert({ severity: "error", message: "Not saved" });
    }
  } catch (error) {
    console.error("Error saving basic details:", error);
    setAlert({
      severity: "error",
      message: "An error occurred while saving.",
    });
  }
}
else{
  try {
    console.log("entered");
    const data = {
      userId,
      username,
      accountingSystemId,
      salute,
      userType,
      firstName,
      middleName,
      surname,
      lastName,
      oneTimePassword: oneTimePassword || 0,
      clientId: assignee.clientId,
      createdBy: assignee.userId,
      changedBy:null,
      changedDate: null, 
      // changedTime:null,
      // module: 'Admin',
      // icon: 'User',
      // action: 'Add',
      email, mobileNo, whatsappNo ,officeNo,extension,
      passportNo, drivingLicenseNo,localId,nationality,
      language, dateFormat,thousandSeparator,decimalSeparator,amountFormat,
      acceleratorForThousands: acceleratorForThousands + suffixForThousands,
    acceleratorForLakhs: acceleratorForLakhs + suffixForLakhs,
    acceleratorForMillions: acceleratorForMillions + suffixForMillions,
      
      timeFormat,timeZone,receiveSystemMail,downloadData,changesInCashflow,changeVarient,deleteAllRecords,changeAccountingCode,updateCountry,
      streetName, addressLine1, addressLine2,addressLine3,countryId, zipCode, postalCode,
      employeeNo, companyCodeId,department,validFrom,validTo,joiningDate,resignationDate,
      loginMethod, 
      doorNo,floorNo,buildingNo,buildingName,landMark,streetNo,roadNo,roadName,areaBlock,areaName,countryCode,state,poBox, district
    };
    const response = await UserService.saveAllUserDetails(
      `${process.env.REACT_APP_BASE_URL}/add-new-user`,
      data
    );
    console.log("response data", data);
    console.log("response", response);
    console.log("response status", response.status);
    if (response.status === 200) {
      setAlert({
        severity: "success",
        message: "Basic Details saved successfully.",
      });

        // Wait for the success message to be displayed before navigating
        toast("success", "User successfully Added");
        window.location.reload();
        // handleTabClick("basicDetail");  // Navigate to loginMethod Details tab
    } else {
      setAlert({ severity: "error", message: "Not saved" });
    }
  } catch (error) {
    console.error("Error saving basic details:", error);
    setAlert({
      severity: "error",
      message: "An error occurred while saving.",
    });
  }
}

  };

  // const nextTabMoveToMethod = async () => {
  //   handleTabClick("loginMethod"); // Navigate to contactDetails tab 
  // }

  // const nextTabMoveToPreferance = async () => {
  //   handleTabClick("userPreferences"); // Navigate to contactDetails tab 
  // }

  // const nextTabMoveToAddress = async () => {
  //   handleTabClick("addressDetails"); // Navigate to contactDetails tab 
  // }
  // const nextTabMoveToOrganization = async () => {
  //   handleTabClick("organization"); // Navigate to contactDetails tab 
  // }
  // const nextTabMoveToIdentity = async () => {
  //   handleTabClick("identity"); // Navigate to contactDetails tab 
  // }
  // const nextTabMoveToContact =async () => {
  //             handleTabClick("contactDetails"); // Navigate to contactDetails tab   
  // }

  const handleSaveBasicDetails = async () => {
    if (!validateFields()) return;
    if(isEditMode){
    try {
      const data = {
        userId:UserId,
        username,
        accountingSystemId,
        salute,
        userType,
        firstName,
        middleName,
        surname,
        lastName,
        // module: 'Admin',
        // icon: 'User',
        // action: 'Edit',
        changedBy: assignee.userId,
        oneTimePassword: oneTimePassword || 0,
        // createdBy: assignee.userId,
      
        email, mobileNo, whatsappNo ,officeNo,extension,
        passportNo, drivingLicenseNo,localId,nationality,
        language, dateFormat,thousandSeparator,decimalSeparator,amountFormat,
        acceleratorForThousands: acceleratorForThousands + suffixForThousands,
        acceleratorForMillions: acceleratorForMillions + suffixForMillions,
        acceleratorForLakhs: acceleratorForLakhs + suffixForLakhs,
        timeFormat,timeZone,receiveSystemMail,downloadData,changesInCashflow,updateCountry,changeVarient,deleteAllRecords,changeAccountingCode,
        streetName, addressLine1, addressLine2,addressLine3, zipCode, postalCode,
        employeeNo, companyCodeId,department,validFrom,validTo,joiningDate,resignationDate,
        loginMethod, 
        doorNo,floorNo,buildingNo,buildingName,landMark,streetNo,roadNo,roadName,areaBlock,areaName,countryCode,state,poBox, district
      };
      const response = await UserService.saveAllUserDetails(
        `${process.env.REACT_APP_BASE_URL}/edit-user`,
        data
      );
      console.log("response", response);
      console.log("response status", response.status);
      if (response.status === 200) {
        setAlert({
          severity: "success",
          message: "Basic Details saved successfully.",
        });

          // Wait for the success message to be displayed before navigating
          toast("success", "User Edited successfully");
            navigate("/14/admin/108/userscreen"); // Navigate to loginMethod Details tab
      }
      else if (response.status === 400) {
        // setAlert({
        //   severity: "warning",
        //   message: "Missing required fields.",
        // });

          // Wait for the success message to be displayed before navigating
          toast("warning", "Missing required fields.");
      } 
      else if (response.status === 409) {
        setAlert({
          severity: "warning",
          message: "Email already exists.",
        });

          // Wait for the success message to be displayed before navigating
          toast("warning", "Email already exists.");
      } 
       else {
        setAlert({ severity: "error", message: "Not saved" });
      }
    } catch (error) {
      console.error("Error saving basic details:", error);
      setAlert({
        severity: "error",
        message: "An error occurred while saving.",
      });
    }

    }
    else{
      try {
        console.log("entered");
        const data = {
          userId,
          username,
          accountingSystemId,
          salute,
          userType,
          firstName,
          middleName,
          surname,
          clientId: assignee.clientId,
          // module: 'Admin',
          // icon: 'User',
          // action: 'Add',
          
          lastName,
          oneTimePassword: oneTimePassword || 0,
          createdBy: assignee.userId,
          changedBy: null,
          changedDate: null, 
          // changedTime: null,
          email, mobileNo, whatsappNo ,officeNo,extension,
          passportNo, drivingLicenseNo,localId,nationality,
          language, dateFormat,thousandSeparator,decimalSeparator,amountFormat,
          acceleratorForThousands: acceleratorForThousands + suffixForThousands,
        acceleratorForLakhs: acceleratorForLakhs + suffixForLakhs,
        acceleratorForMillions: acceleratorForMillions + suffixForMillions,
          timeFormat,timeZone,receiveSystemMail,downloadData,changesInCashflow,changeVarient,deleteAllRecords,changeAccountingCode,updateCountry,
          streetName, addressLine1, addressLine2,addressLine3 ,countryId, zipCode, postalCode,
          employeeNo, companyCodeId,department,validFrom,validTo,joiningDate,resignationDate,
          loginMethod, 
          doorNo,floorNo,buildingNo,buildingName,landMark,streetNo,roadNo,roadName,areaBlock,areaName,countryCode,state,poBox, district
        };

        console.log("user save request",data);
        const response = await UserService.saveAllUserDetails(
          `${process.env.REACT_APP_BASE_URL}/add-new-user`,
          data
        );
        console.log("response data", data);
        console.log("response", response);
        console.log("response status", response.status);
        if (response.status === 200) {
          setAlert({
            severity: "success",
            message: "Basic Details saved successfully.",
          });
  
            // Wait for the success message to be displayed before navigating
            toast("success", "User successfully Added");
              navigate("/14/admin/108/userscreen"); // Navigate to loginMethod Details tab
        } 
        else if (response.status === 400) {
          // setAlert({
          //   severity: "warning",
          //   message: "Missing required fields.",
          // });
  
            // Wait for the success message to be displayed before navigating
            toast("warning", "Missing required fields.");
        } 
        else if (response.status === 409) {
          setAlert({
            severity: "warning",
            message: "Email already exists.",
          });
  
            // Wait for the success message to be displayed before navigating
            toast("warning", "Email already exists.");
        } 
        
        else {
          setAlert({ severity: "error", message: "Not saved" });
          toast("warning", "Not saved.");
  
        }
      } catch (error) {
        console.error("Error saving basic details:", error);
        setAlert({
          severity: "error",
          message: "An error occurred while saving.",
        });
      }
    }
   
  };


  const checkDuplicateUserId = async (name) => {
    try {
      const response = await UserService.checkDuplicateRoleName(
        `${process.env.REACT_APP_BASE_URL}/check-userId-duplicate`,
        { userId: name,
          clientId: assignee.clientId,
         }  // Sending roleName to backend
      );
      
      console.log("Response from server:", response);
  
      if (response.message === "User ID already exists") {
        // Check if it's a duplicate
      console.log("Response from internal server:", response);
  setErrors({ userId: ' ' });

   toast("error", "User ID already exists.");
       
      }
      console.log("Response from external server:", response);

    } catch (error) {
      console.error("Error checking duplicate role name:", error);
      // Handle potential errors when making the API call
      setErrors(prev => ({ ...prev, roleName: "Error checking role name" }));
    }
  };

  const handleUserIdChange = (e) => {
    setUnsavedChanges(true);
    const name = e.target.value;
    setUserId(name);
    
    // Clear previous errors when typing
    setErrors(prev => ({ ...prev, userId: "" }));
  
    // Check for duplicate role names when typing
    if (name.trim() !== "") {
      checkDuplicateUserId(name);
    }
  };

  const tabStyle = (tab) => {
    const hasErrorsInBasicDetail = errors.userId || errors.username || errors.firstName || errors.lastName;
    const hasErrorsInContactTab = errors.email || errors.mobileNo; // Assuming contact tab fields
    const hasErrorInIdentityTab = errors.nationality;
    const hasErrorInOrganizationTab = errors.validFrom || errors.validTo;
    const hasErrorInAddressDetailTab = errors.addressSection;
    const hasErrorInUserPreference = errors.language || errors.dateFormat || errors.timeFormat;
    const isActiveTab = activeTab === tab;
  
    // Conditionally applying error styles based on the tab and errors in corresponding fields
    const tabHasError = (tab === "basicDetail" && hasErrorsInBasicDetail) || 
                        (tab === "contactDetails" && hasErrorsInContactTab) ||
                        (tab === "identity" && hasErrorInIdentityTab)||
                        (tab === "organization" && hasErrorInOrganizationTab)||
                        (tab === "addressDetails" && hasErrorInAddressDetailTab)||
                        (tab === "userPreferences" && hasErrorInUserPreference);

  
    return {
      backgroundColor: isActiveTab ? "white" : "transparent",
      color: isActiveTab ? "grey" : "white",
      padding: "2px 10px",
      cursor: "pointer",
      borderRadius: "5px",
      border: tabHasError ? "2px solid red" : "none", // Apply red border if there are errors in the tab
    };
  };
  const errorss = {
    basicDetail: !userId || !username || !firstName || !lastName, // Replace with your validation logic
    contactDetails: !email || !mobileNo,
    identity: !nationality,
    organization: !validFrom || !validTo,
    addressDetails: !addressLine1,
    userPreferences: !language || !dateFormat || !timeFormat,
  };
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'Admin') {
      console.log("Navigating to /Admin");
      navigate('/14/admin');
    } else if (crumb.title === 'User & SOD') {
      console.log("Navigating to User & SOD");
      navigate('/14/admin');
    }
    else if (crumb.title === 'User') {
      console.log("Navigating to /User");
      navigate('/14/admin/108/userscreen');
    } 
    else if (crumb.title === 'Add') {
      console.log("Navigating to /User");
      navigate('/useradd');
    } 
    else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };

  const tabs = [
    { value: "basicDetail", label: "Basic Details" },
    { value: "contactDetails", label: "Contact details" },
    { value: "identity", label: "Identity" },
    { value: "organization", label: "Organization" },
    { value: "addressDetails", label: "Address Details" },
    { value: "userPreferences", label: "User Preferences" },
    // { value: "loginMethod", label: "Login Method" },
  ];

  const handlesTabClick = (tab) => {
    setActiveTab(tab);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenForState, setIsModalOpenForState] = useState(false);
  const [isModalOpenForCompany, setIsModalOpenForCompany] = useState(false);
  const [isModalOpenForStateName, setIsModalOpenForStateName] = useState(false);
  const [isModalOpenForCountryName, setIsModalOpenForCountryName] = useState(false);
  const [islanguageModalOpen, setIslanguageModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // const toggleModalForCompanyCode = () => {
  //   setIsModalOpenForCompany(!isModalOpenForCompany);
  // }

  const toggleModalForState = () => {
    setIsModalOpenForState(!isModalOpenForState);

  }
  const toggleModalForStateName = () => {
    setIsModalOpenForStateName(!isModalOpenForStateName);

  }

  const toggleModalforCountryName = () => {
    setIsModalOpenForCountryName(!isModalOpenForCountryName);

  }
  const [countryName, setCountryName] = useState(""); // Country Name
  
const handleCheckboxChange = (event) => {
  setUnsavedChanges(true);

  const selectedCode = event.target.value;
  console.log("selected country code",selectedCode);
  console.log("state apear or not",state);
  setCountryCode(selectedCode);
  setSelectedCountry(selectedCode);
  if(state){
    setstate('');
    setStateName('');
  }
  
  const selectedCountryData = countries.find(country => country.country === selectedCode);
  if (selectedCountryData) {
    setCountryName(selectedCountryData.countryName);
  }
};

const handleCheckboxChangeforCountryName = (event) => {
  setUnsavedChanges(true);
  const selectedCode = event.target.value;
  console.log("selected country name",selectedCode);
  setCountryCode(selectedCode);
  setSelectedCountry(selectedCode);

  if(state){
    setstate('');
    setStateName('');
  }

  const selectedCountryData = countries.find(country => country.country === selectedCode);
  if (selectedCountryData) {
    setCountryName(selectedCountryData.countryName);

  }
};

const handleLanguageCheckBoxChange = (event) => {
  setUnsavedChanges(true);

  console.log(`this is the language ${languages}`)
setUnsavedChanges(true);
  const selectedName = event.target.value; // Get the selected country name
  setSelectedLanguae(selectedName);

  const selectedCountryData = languages.find(le => le.LE === selectedName);
  if (selectedCountryData) {
    setSelectedLanguae(selectedCountryData.LE); // Set the country code
setlanguage(selectedCountryData.LE);
    // Filter states by the selected country code
    // const filteredStates = stateProvinces.filter(state => state.Country_Code === selectedCountryData.country);
    // setFilteredStateProvinces(filteredStates); // Assuming you have state to hold the filtered states
  }
};

const handleCheckboxChangeForState = (event) =>{
  setUnsavedChanges(true);
  const selectedCode = event.target.value;
  console.log("selected State code",selectedCode);
  setSelectedState(selectedCode);
  setstate(selectedCode);
  
  const selectedCountryData = stateProvinces.find(stateP => stateP.state === selectedCode);
  console.log('selected state country data',selectedCountryData);
  if (selectedCountryData) {
    console.log('check selected country data',selectedCountryData.State_Name);
    setStateName(selectedCountryData.State_Name);
  }
};

const handleToggle = (dropdownId) => {
  setOpenDropdown((prev) => (prev === dropdownId ? null : dropdownId)); // Toggle dropdown
};

const saluteOptions = [
  { value: "", label: "Select Salute" },
  { value: "Dr.", label: "Dr." },
  { value: "Miss", label: "Miss" },
  { value: "Mr.", label: "Mr." },
  { value: "Mrs.", label: "Mrs." },
  { value: "Ms.", label: "Ms." },
];

const dateFormatOptions = [
  { value: "", label: "Select Date Format" },
  { value: "DD/MM/YYYY", label: "DD/MM/YYYY" },
  { value: "MM/DD/YYYY", label: "MM/DD/YYYY" },
  { value: "DD/MM/YY", label: "DD/MM/YY" },
  { value: "MM/DD/YY", label: "MM/DD/YY" },
  { value: "DD.MM.YYYY", label: "DD.MM.YYYY" },
  { value: "DDMMYY", label: "DDMMYY" },
];

const amountFormatOptions = [
  { value: "", label: "Select Amount Format" },
  { value: "1,234,567.89", label: "1,234,567.89" },
  { value: "1.234.567,890", label: "1.234.567,890" },
  { value: "12,34,567.890", label: "12,34,567.890" },

];

const timeFormatOptions = [
  { value: "", label: "Select Time Format" },
  { value: "24 hours", label: "24 hours" },
  { value: "12 hours", label: "12 hours" },

];

const handleCheckboxChangeForCompanyCode = (event) =>{
  setUnsavedChanges(true);
  const selectedCode = event.target.value;
  console.log("selected Company code",selectedCode);
  setSelectedCompany(selectedCode);
  setcompanyCodeId(selectedCode);
  
};

const getApprovedIcon = () => {
  let color = "black"; // Default color
  // Determine the color based on the approved status
  if (approved == 3) {
    color = "#29CB00"; // Green
  } else if (approved == 0) {
    color = "gray"; // Gray
  } else if (approved == 1) {
    color = "yellow"; // Yellow
  } else if (approved == 2) {
    color = "orange"; // Orange
  } else if (approved == 4) {
    color = "red"; // Red
  }

  return <TbFlag3Filled style={{ color, marginLeft: '8px' }} />; // Return the icon with the determined color
};

  return (
  <div>
      <div>
        {/* <NoActionLayout title={"Admin > User & SOD > User > Add"} /> */}
        <Header title={`Admin > User & SOD > User >  ${isViewMode ? 'View' : isEditMode ? 'Edit' : 'Add'}`}
        onBreadcrumbClick={handleBreadcrumbClick}
        hasUnsavedChanges={unsavedChanges}
        extraContent={isViewMode ? getApprovedIcon() : ''} 

      />
      </div>
      <div>
      <Tabs tabs={tabs} activeTab={activeTab} onTabClick={handlesTabClick} errors={errorss} isValidationTriggered={isValidationTriggered}/>

      </div>

      {activeTab === "basicDetail" && (
        <div className="m-6 border border-gray-200 p-5  rounded-lg">
          <div className="p-6">
          <div className="grid grid-cols-2 gap-x-8 gap-y-8 ">
          <label className="flex items-center group">
            <span className="w-48 text-sm font-medium text-gray-500">
              User ID <span className="text-red-500 ml-1">*</span>
            </span>
            <span className="ml-4">:</span>
            <input
              type="text"
              value={userId}
              // onChange={(e) => setUserId(e.target.value)}
            onChange={handleUserIdChange}

              className={`border p-1.5 rounded w-72 h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 
                ${errors.userId ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
              placeholder="Enter main head"
              required
            disabled={isViewMode || isEditMode}

            />
            {errors.userId && (
              <p className="text-red-500 text-sm ml-4">{errors.userId}</p>
            )}
          </label>

            <label className="flex items-center group">
              <span className="w-48 text-sm font-medium text-gray-500">
                Username <span className="text-red-500 ml-1">*</span>{" "}
              </span>
              <span className="ml-4">:</span>
              <input
                type="text"
                value={username}
                onChange={(e) => {setUserName(e.target.value);
                  setUnsavedChanges(true);
                }}
                className={`border border-gray-300 p-1.5 rounded w-72 h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0
                ${errors.username ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
                placeholder="Enter sub heads"
            disabled={isViewMode}

              />
              {errors.username && (
                <p className="text-red-500 text-sm ml-4">{errors.username}</p>
              )}
            </label>

            {/*  */}

            <label className="flex items-center group"> 
  <span className="w-48 text-sm font-medium text-gray-500">
    User Type
  </span>
  <span className="ml-4">:</span>
  <select
    value={userType}
    onChange={(e) => {
      const value = e.target.value;
      setUserType(value == "0" ? null : value); // Set to null if value is "0"
    }}
    disabled
    className="border border-gray-300 p-1 rounded w-72 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 bg-gray-100"
  >
    <option value="0">Select user type</option>
    {userTypes.map((type) => (
      <option key={type.id} value={type.id}>
        {type.name}
      </option>
    ))}
  </select>
</label>


            <label className="flex items-center group">
              <span className="w-48 text-sm font-medium text-gray-500">
                Accounting System ID 
              </span>
              <span className="ml-4">:</span>
              <input
                type="text"
                value={accountingSystemId}
                onChange={(e) => {setAccountingSystemId(e.target.value);
                setUnsavedChanges(true);
                }}
                className={`border border-gray-300 p-1.5 rounded w-72 h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`}
            disabled={isViewMode}

              />
                 {/* {errors.accountingSystemId && (
                <p className="text-red-500 text-sm ml-4">{errors.accountingSystemId}</p>
              )} */}
            </label> 
        </div>
        <div className="flex flex-col   gap-y-6 mt-6">
            <label className="flex items-center group">
              <span className="w-48 text-sm font-medium text-gray-500">
                Salute 
              </span>
              <span className="ml-4">:</span>
              {/* <select
                  value={salute}
            disabled={isViewMode}
            onChange={(e) => setSalute(e.target.value)}
                  className={`border border-gray-300 p-1 rounded w-72 h-8 ${isViewMode ? 'text-gray-500 bg-gray-100':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 `}
                >
                  <option value="">Select Salute</option>
                  <option value="Dr.">Dr.</option>
                  <option value="Miss">Miss</option>
                  <option value="Mr.">Mr.</option>
                  <option value="Mrs.">Mrs.</option>
                  <option value="Ms.">Ms.</option>
                </select>
               */}
               
               <CustomDropdown
        value={salute}
        options={saluteOptions}
        onChange={(value) => {
          setSalute(value);
            setUnsavedChanges(true); // Mark as unsaved
            setOpenDropdown(false);
          }}        isOpen={openDropdown === "amountFormat"}
        onToggle={()=> handleToggle("amountFormat")}
        isDisabled={isViewMode}
        setUnsavedChanges={setUnsavedChanges}
        width="w-72"

        className="ml-4"
        placeholder="Select Salute"
      />

            </label>
            
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Surname
              </span>
              <span className="ml-3">:</span>
              <input
                type="text"
                value={surname}
                onChange={(e) => {setSurname(e.target.value);
                setUnsavedChanges(true);
                }}
            disabled={isViewMode}
            required
                className={`border border-gray-300 p-1.5 rounded w-72 h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none hover:border-blue-400 ml-5 peer placeholder-transparent peer-focus:placeholder-opacity-0`}
              />
             
            </label>

            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500 ">
                First Name<span className="text-red-500 ml-1">*</span>
              </span>
              <span className="ml-3">:</span>
              <input
                type="text"
                value={firstName}
                onChange={(e) => {setFirstName(e.target.value);
                setUnsavedChanges(true);
                }
              }
            disabled={isViewMode}
            required
                className={`border border-gray-300 p-1.5 rounded w-72 h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none hover:border-blue-400 ml-5
                  ${errors.firstName ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
              />
              {errors.firstName && (
                <p className="text-red-500 text-sm ml-4">{errors.firstName}</p>
              )}
            </label>
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Middle Name
              </span>
              <span className="ml-3">:</span>
              <input
                type="text"
                value={middleName}
                onChange={(e) => {setMiddleName(e.target.value);
                setUnsavedChanges(true);

                }}
            disabled={isViewMode}
            required
                className={`border border-gray-300 p-1.5 rounded w-72 h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none hover:border-blue-400 ml-5 peer placeholder-transparent peer-focus:placeholder-opacity-0 `}
                />
             
            </label>

            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Last Name <span className="text-red-500 ml-1">*</span>
              </span>
              <span className="ml-3">:</span>
              <input
                type="text"
                value={lastName}
                onChange={(e) => {setLastName(e.target.value);
                setUnsavedChanges(true);

                }}
                className={`border border-gray-300 p-1.5 rounded w-72 h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none hover:border-blue-400 ml-5 peer placeholder-transparent peer-focus:placeholder-opacity-0
                  ${errors.lastName ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
            disabled={isViewMode}
            required
                />
              {errors.lastName && (
                <p className="text-red-500 text-sm ml-4">{errors.lastName}</p>
              )}
            </label>


          </div>
          </div>
        </div>
      )}

      {activeTab === "contactDetails" && (
        <div className="m-6 border border-gray-200 p-5  rounded-lg">
          <div className="flex flex-col space-y-8 col-span-1 p-6">
            {errors.userId && (
              <p className="text-red-500 text-sm ml-4">{errors.userId}</p>
            )}
 
 <label className="flex items-center">
  <span className="w-48 text-sm font-medium text-gray-500">
    Email ID <span className="text-red-500 ml-1">*</span>
  </span>
  <span className="ml-3">:</span>
  <input
    type="email"
    value={email}
    onChange={handleEmailChange}
            disabled={isViewMode}
            maxLength={80}
    className={`border border-gray-300 p-1.5 rounded w-72 h-9 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0
      ${errors.email ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
  />
  {email.length === 80 && (
    <p className="text-yellow-500 text-sm ml-4">You have used the maximum character limit.</p>
  )}
  {errors.email && (
    <p className="text-red-500 text-sm ml-4">{errors.email}</p>
  )}
</label>
  
<label className="flex flex-col items-start">
  <span className="flex items-center">
  <span className="w-48 text-sm font-medium text-gray-500">
    Mobile No <span className="text-red-500 ml-1">*</span>
  </span>
<span className="ml-20">:</span>
  
  <PhoneInput
    className={`ml-4 ${isViewMode ? 'text-gray-500':'text-customGray'} ${
      errors.mobileNo ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'
    }`}
    country={'in'} // Default country
            disabled={isViewMode}
            value={mobileNo}
            
    onChange={(phone) => {setMobileNo(phone);
      setUnsavedChanges(true);

    }} // This already returns the full number with country code
  />
</span>

   {errors.mobileNo && (
    <p className="text-red-500 text-sm mt-1">{errors.mobileNo}</p>
  )}
</label>




<label className="flex flex-col items-start">
<span className="flex items-center">

        <span className="w-48 text-sm font-medium text-gray-500">
          Whatsapp No
        </span>
        <span className="ml-20">:</span>
        
        <PhoneInput
        className={`ml-4 ${isViewMode ? 'text-gray-500':'text-customGray'}`}
          country={'in'} // Default country
            disabled={isViewMode}
            value={whatsappNo}
          onChange={(phone) => {setWhatsappNo(phone);
            setUnsavedChanges(true);

          }}
          // inputClass={`border border-gray-300 p-1.5 rounded  h-8 focus:outline-none hover:border-blue-400 peer placeholder-transparent peer-focus:placeholder-opacity-0           `}
        />
</span>

      </label>
      
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Office No
              </span>
              <span className="ml-3">:</span>
              <input
                type="text"
            disabled={isViewMode}
            value={officeNo}
                onChange={(e) => {setOfficeNo(e.target.value);
                setUnsavedChanges(true);

                }}
                className={`border border-gray-300 p-1.5 rounded w-72 h-9 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`}
              />
            </label>
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Extension
              </span>
              <span className="ml-3">:</span>
              <input
                type="text"
            disabled={isViewMode}
            value={extension}
                onChange={(e) => {setExtension(e.target.value);
                setUnsavedChanges(true);

                }}
                className={`border border-gray-300 p-1.5 rounded w-72 h-9 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`}
              />
            </label>
          </div>
          <div className="flex flex-row mt-12 ">

          {/* <button 
             onClick={nextTabMoveToIdentity}
             className=" mt-4 bg-customBlue text-sm text-white py-1 px-2   rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ">
    Next
  </button> */}

  </div>
        </div>
      )}

      {activeTab === "identity" && (
        <div className="m-6 border border-gray-200 p-5  rounded-lg">
          <div className="flex flex-col space-y-8 col-span-1 p-6">
            {errors.userId && (
              <p className="text-red-500 text-sm ml-4">{errors.userId}</p>
            )}

            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Passport No
              </span>
              <span className="ml-3">:</span>
              <input
                type="text"
            disabled={isViewMode}
            value={passportNo}
                onChange={(e) => {setPassportNo(e.target.value);
                setUnsavedChanges(true);

                }}
                className={`border border-gray-300 p-1.5 rounded w-72 h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`}
              />
            </label>

            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Driving Licence No
              </span>
              <span className="ml-3">:</span>
              <input
                type="text"
            disabled={isViewMode}
            value={drivingLicenseNo}
                onChange={(e) => {setDrivingLicenseNo(e.target.value);
                setUnsavedChanges(true);

                }}
                className={`border border-gray-300 p-1.5 rounded w-72 h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`}
              />
            </label>
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Local ID
              </span>
              <span className="ml-3">:</span>
              <input
                type="text"
            disabled={isViewMode}
            value={localId}
                onChange={(e) => {setLocalId(e.target.value);
                setUnsavedChanges(true);

                }}
                className={`border border-gray-300 p-1.5 rounded w-72 h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`}
              />
            </label>
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Nationality<span className="text-red-500 ml-1">*</span>
              </span>
              <span className="ml-3">:</span>
              <input
                type="text"
            disabled={isViewMode}
            value={nationality}
                onChange={(e) => {setNationality(e.target.value);
                setUnsavedChanges(true);

                }}
                className={`border border-gray-300 p-1.5 rounded w-72 h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0
                   ${errors.nationality ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}

              />
              {errors.nationality && (
                <p className="text-red-500 text-sm ml-4">
                  {errors.nationality}
                </p>
              )}
            </label>
          </div>
          <div className="flex flex-row mt-12">

          {/* <button 
             onClick={nextTabMoveToOrganization}
             className=" mt-4 bg-customBlue text-sm text-white py-1 px-2   rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ">
    Next
  </button> */}

          </div>
        </div>
      )}

      {activeTab === "organization" && (
        <div className="m-6 border border-gray-200 p-5  rounded-lg">
          <div className="flex flex-col space-y-8 col-span-1 p-6">
            {/* {errors.userId && (
              <p className="text-red-500 text-sm ml-4">{errors.userId}</p>
            )} */}

            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Employee No
              </span>
              <span className="ml-3">:</span>
              <input
                type="text"
            disabled={isViewMode}
            value={employeeNo}
                onChange={(e) => {setemployeeNo(e.target.value);
                setUnsavedChanges(true);

                }}
                className={`border border-gray-300 p-1.5 rounded w-72 h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`}
              />
            </label>

            
            <LabelWithButton
        label="Company Code"
        isDisabled={isViewMode}
        onClick={ ()=> {setIsModalOpenForCompany(true);}   
        }
        buttonText={companyCodeId || 'Select Company Code'}
        error={errors.companyCodeId}
        width="w-72"        // Set custom width
        marginLeft="ml-4"   // Set custom margin-left
      />


<ReusableModal
  title="Select Company Code"
  isOpen={isModalOpenForCompany}
  onClose={() => {
    setIsModalOpenForCompany(false);
    setSearchQueryCompanyCode('');
  }}
  onSave={() => {
    setIsModalOpenForCompany(false);
    setSearchQueryCompanyCode('');
  }}
  searchPlaceholder="Search Company Code..."
  searchQuery={searchQueryCompanyCode}
  onSearchChange={handleSearchChangeCompanyCode}
  isViewMode={isViewMode}
>
{companyCodes.length > 0 ? (
                    // <div className="grid grid-cols-3 gap-4">
                      // {
                        companyCodes
                      .filter(
                        (company) =>
                        // company.State_Name.toLowerCase().includes(searchQueryCompanyCode.toLowerCase()) ||
                      company.companyCode.toLowerCase().includes(searchQueryCompanyCode.toLowerCase())
                      )
                      .map((company, index) => (
                        <div key={company.id} className={`flex items-center ${index % 2 === 0 ? 'bg-sky-blue-200' : ''}`}>
                          <input
                            type="radio"
                            name="country"
                            value={company.companyCode}
                            checked={selectedCompany === company.companyCode}
                            onChange={handleCheckboxChangeForCompanyCode}
                            disabled={isViewMode}
                            className="mr-2 accent-customBlue"
                          />
                          <label>{company.companyCode}</label>
                        </div>
                      ))
                    // }
                    // </div>
                  ) : (
                    <p>Company Code Not Found...</p>
                  )}

</ReusableModal>


            {/* <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">Company Code</label>
            <span className="ml-4">:</span>
              <button
            onClick={toggleModalForCompanyCode}
            className={`border border-gray-300 p-1 rounded w-72 h-8 ${isViewMode ? 'text-gray-500 bg-gray-100':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4
             text-left flex items-center justify-between `}
             disabled={ isViewMode}
          >
            <span>{companyCodeId || 'Select Company Code'}</span>
            <RxDropdownMenu className="ml-2" />
          </button>
          {isModalOpenForCompany && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white p-0 w-[800px] h-[400px] flex flex-col rounded-2xl overflow-hidden">
                <div className="flex justify-between items-center p-4 shadow shadow-blue-100 bg-white sticky top-0 z-10">
                  <h2 className="text-lg font-semibold text-customGray">Select Company Code</h2>

                      <input
              type="text"
              placeholder="Search Company Code..."
              value={searchQueryCompanyCode}
              onChange={handleSearchChangeCompanyCode}
              className="border border-gray-300 p-1 rounded text-customGray w-80 ml-60 placeholder-gray-500 focus:outline-none focus:border-customBlue focus:ring-1 focus:ring-customBlue"
            />
                  <button onClick={toggleModalForCompanyCode} className="text-gray-500 hover:text-gray-700 text-2xl">&times;</button>
                </div>
                <div className="flex-1 overflow-auto p-4">

                  {companyCodes.length > 0 ? (
                    <div className="grid grid-cols-3 gap-4">
                      {companyCodes
                      .filter((company) =>
                        // company.State_Name.toLowerCase().includes(searchQueryCompanyCode.toLowerCase()) ||
                      company.companyCode.toLowerCase().includes(searchQueryCompanyCode.toLowerCase())
                      )
                      .map((company, index) => (
                        <div key={company.id} className={`flex items-center ${index % 2 === 0 ? 'bg-sky-blue-200' : ''}`}>
                          <input
                            type="radio"
                            name="country"
                            value={company.companyCode}
                            checked={selectedCompany === company.companyCode}
                            onChange={handleCheckboxChangeForCompanyCode}
                            className="mr-2"
                          />
                          <label className="text-customGray">{company.companyCode}</label>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>Company Code Not Found...</p>
                  )}
                </div>
                
                  <div className="flex justify-end p-2 bg-white sticky bottom-0 border-t">
                  <button
                    onClick={toggleModalForCompanyCode}
                    className="mt-2 bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 mr-20"
                  >
                    Save
                  </button>
                </div>
              
                
              </div>
            </div>
          )}
            </div> */}

            
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Department
              </span>
              <span className="ml-3">:</span>
              <input
                type="text"
            disabled={isViewMode}
            value={department}
                onChange={(e) => {setdepartment(e.target.value);
                setUnsavedChanges(true);
                }}
                className={`border border-gray-300 p-1.5 rounded w-72 h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`}
              />
            </label>
           
       
            <label className="flex items-center">
  <span className="w-48 text-sm font-medium text-gray-500">
    Valid From<span className="text-red-500 ml-1">*</span>
  </span>
  <span className="ml-3">:</span>
  <DatePickerComponent
    selectedDate={validFrom}
    onChange={handleValidFromChange}
    placeholder="dd.mm.yyyy"
    disabled={isViewMode}
  />
  {errors.validFrom && <p className="text-red-500 text-sm ml-4">{errors.validFrom}</p>}
</label>





{/* <CustomDatePicker
        label="Valid From"
        isRequired={true}
        isViewMode={isViewMode}
        validFrom={validFrom}
        setvalidFrom={setvalidFrom}
        errors={errors}
        setErrors={setErrors}
      /> */}



<label className="flex items-center">
  <span className="w-48 text-sm font-medium text-gray-500">
    Valid To<span className="text-red-500 ml-1">*</span>
  </span>
  <span className="ml-3">:</span>
  <DatePickerComponent
          selectedDate={validTo}
          onChange={handleValidToChange}
          placeholder="dd.mm.yyyy"
          disabled={isViewMode}
        />
        {errors.validTo && <p className="text-red-500 text-sm ml-4">{errors.validTo}</p>}
     
</label>


{/* <CustomDatePicker
        label="Valid To"
        isRequired={true}
        isViewMode={isViewMode}
        validTo={validTo}
        setvalidTo={setvalidTo}
        errors={errors}
        setErrors={setErrors}
      /> */}

            <label className="flex items-center mt-4">
              <span className="w-48 text-sm font-medium text-gray-500">
                Joining Date
              </span>
              <span className="ml-3">:</span>
              {/* <input
                type="date"
                value={joiningDate}
                onChange={(e) => {
                  setjoiningDate(e.target.value);
                  setErrors({ ...errors, joiningDate: '' });
                }}
                max={validFrom ? validFrom : ''} // Set the minimum date for Joining Date to Valid From
                className={`border border-gray-300 p-1.5 rounded w-72 h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0
                  ${errors.joiningDate ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
                disabled={!validFrom || isViewMode} // Disable the Joining Date input if Valid From is not set
                
              /> */}

<DatePickerComponent
  selectedDate={joiningDate}
  onChange={(date) => {
    setjoiningDate(date);
    setUnsavedChanges(true);
    setErrors({ ...errors, joiningDate: "" });
  }}
  placeholder="dd.mm.yyyy"
  disabled={!validFrom || isViewMode}
  maxDate={validFrom ? new Date(validFrom.split('.').reverse().join('-')) : null} // Pass the maxDate prop
/>



              {errors.joiningDate && (
                <p className="text-red-500 text-sm ml-4">{errors.joiningDate}</p>
              )}
            </label>

            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Resignation date
              </span>
              <span className="ml-3">:</span>
              {/* <input
                type="date"
            disabled={isViewMode}
            value={resignationDate}
                onChange={(e) => setresignationDate(e.target.value)}
                className={`border border-gray-300 p-1.5 rounded w-72 h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`}
              /> */}
              
              <DatePickerComponent
                selectedDate={resignationDate}
                onChange={(date) => {setresignationDate(date);
                setUnsavedChanges(true);

                }}
                placeholder="dd.mm.yyyy"
                disabled={isViewMode}
              />


            </label>
          </div>
     
        </div>
      )}

      {activeTab === "addressDetails" && (
        <div className="m-6 border border-gray-200 p-5  rounded-lg">
        <TableWrapper maxHeight="70vh">

        <div className="p-1">
          {/* Building Section */}
          <div className="mb-6 border border-gray-300 p-5 rounded-lg">
            <h3 className="text-lg font-semibold text-gray-500 mb-4">Building</h3>
            
            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">Door No </label>
                <span className="ml-4">:</span>
                <input type="text" 
                disabled={isViewMode}
                value={doorNo}
                 onChange={(e) => {setdoorNo(e.target.value);
                setUnsavedChanges(true);

                 }}
                className={`border border-gray-300 p-1.5 rounded w-80 h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`} />
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">Floor No </label>
                <span className="ml-4">:</span>
                <input type="text" 
            disabled={isViewMode}
            value={floorNo}
                onChange={(e) => {setfloorNo(e.target.value);
                setUnsavedChanges(true);

                }}
                placeholder="4" className={`border border-gray-300 p-1.5 rounded w-80 h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`} />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">Building No </label>
                <span className="ml-4">:</span>
                <input type="text"
            disabled={isViewMode}
            value={buildingNo}
                onChange={(e) => {setbuildingNo(e.target.value);
                setUnsavedChanges(true);

                }}
                 placeholder="2A" className={`border border-gray-300 p-1.5 rounded w-80 h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`} />
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">Building Name </label>
                <span className="ml-4">:</span>
                <input type="text"
            disabled={isViewMode}
            value={buildingName}
                onChange={(e) => {setbuildingName(e.target.value);
                setUnsavedChanges(true);

                }}
                 placeholder="B name" className={`border border-gray-300 p-1.5 rounded w-80 h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`} />
              </div>
            </div>

            <div className="flex items-center mt-4">
              <label className="w-48 text-sm font-medium text-gray-500">Landmark</label>
              <span className="ml-4">:</span>
              <input type="text" 
            disabled={isViewMode}
            value={landMark}
               onChange={(e) => {setlandMark(e.target.value);
                setUnsavedChanges(true);

               }}
              
              placeholder="" className={`border border-gray-300 p-1.5 rounded w-80 h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`} />
            </div>
          </div>

          {/* Street Section */}
          <div className="mb-6 border border-gray-300 p-5 rounded-lg">
            <h3 className="text-lg font-semibold text-gray-500 mb-4">Street</h3>
            
            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">Street No </label>
                <span className="ml-4">:</span>
                <input 
            disabled={isViewMode}
            value={streetNo}
                  onChange={(e) => {setstreetNo(e.target.value);
                setUnsavedChanges(true);

                  }}    
                type="text" placeholder="2/626" className={`border border-gray-300 p-1.5 rounded w-80 h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`} />
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">Street Name</label>
                <span className="ml-4">:</span>
                <input type="text"  value={streetName} 
            disabled={isViewMode}
            onChange={(e) => {setstreetName(e.target.value);
              setUnsavedChanges(true);

            }}
              className={`border border-gray-300 p-1.5 rounded w-80 h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`} />

              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">Road No </label>
                <span className="ml-4">:</span>
                <input type="text" 
            disabled={isViewMode}
            value={roadNo}
                 onChange={(e) => {setroadNo(e.target.value);
                setUnsavedChanges(true);

                 }}
                placeholder="2A" className={`border border-gray-300 p-1.5 rounded w-80 h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`} />
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">Road Name </label>
                <span className="ml-4">:</span>
                <input
                  type="text"
            disabled={isViewMode}
            value={roadName}
                  onChange={(e) => {setRoadName(e.target.value);
                setUnsavedChanges(true);

                  }}
                  placeholder="B name"
                  className={`border border-gray-300 p-1.5 rounded w-80 h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`}
                />
              </div>
            </div>
          </div>

            {/* Area Section */}
          <div className="mb-6 border border-gray-300 p-5 rounded-lg">
            <h3 className="text-lg font-semibold text-gray-500 mb-4">Area</h3>
            
            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">Block </label>
                <span className="ml-4">:</span>
                <input type="text" 
            disabled={isViewMode}
            value={areaBlock}
                 onChange={(e) => {setareaBlock(e.target.value);
                setUnsavedChanges(true);

                 }}
                placeholder="2/626" className={`border border-gray-300 p-1.5 rounded w-80 h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`} />
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">Area Name </label>
                <span className="ml-4">:</span>
                <input type="text" 
            disabled={isViewMode}
            value={areaName}
                 onChange={(e) => {setareaName(e.target.value);
                setUnsavedChanges(true);

                 }}
                placeholder="4" className={`border border-gray-300 p-1.5 rounded w-80 h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`} />
              </div>
            </div>
          </div>
            {/* Display the error message for address section */}
            {errors.addressSection && (
                <p className="text-red-500 text-sm mb-4">{errors.addressSection}</p>
              )}

            {/* Address Section */}
            <div className="mb-6 border border-gray-300 p-5 rounded-lg">
            <h3 className="text-lg font-semibold text-gray-500 mb-4">Address</h3>
            <div className="grid grid-cols-1 gap-4">
              <div className="flex items-center">
                <label className="w-56 text-sm font-medium text-gray-500">Address 1 </label>
                <span className="ml-4">:</span>
                <input type="text" value={addressLine1}  
            disabled={isViewMode}
            onChange={(e) => {setaddressLine1(e.target.value);
              setUnsavedChanges(true);

            }}
                placeholder="2/626"           
                 className={`border border-gray-300 p-1.5 rounded w-full h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`}
                />
              </div>
            </div>

            <div className="grid grid-cols-1 gap-4 mt-4">
            <div className="flex items-center">
                <label className="w-56 text-sm font-medium text-gray-500">Address 2 </label>
                <span className="ml-4">:</span>
                <input type="text"  value={addressLine2} 
            disabled={isViewMode}
            onChange={(e) => {setaddressLine2(e.target.value);
              setUnsavedChanges(true);

            }}
                placeholder="4" className={`border border-gray-300 p-1.5 rounded w-full h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`} />
              </div>
              
            </div>

            <div className="grid grid-cols-1 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-56 text-sm font-medium text-gray-500">Address 3 </label>
                <span className="ml-4">:</span>
                <input type="text"  value={addressLine3} 
            disabled={isViewMode}
            onChange={(e) => {setaddressLine3(e.target.value);
              setUnsavedChanges(true);

            }}
                placeholder="2A"  className={`border border-gray-300 p-1.5 rounded w-full h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`} />
              </div>
              
            </div>
          </div>


            {/* Country Section */}
          <div className="border border-gray-300 p-5 rounded-lg">
            <h3 className="text-lg font-semibold text-gray-500 mb-4">Country</h3>
         

      <div className="grid grid-cols-2 gap-4">
       
   
   <LabelWithButton
        label="Country Code"
        isRequired={true}
        isDisabled={isViewMode}
        onClick={ ()=> {setIsModalOpen(true);}   
        }
        buttonText={countryCode || 'Select Country Code'}
        error={errors.countryCode}
        marginLeft="ml-4"   // Set custom margin-left
      />

<ReusableModal
  title="Select Country Code"
  isOpen={isModalOpen}
  onClose={() => {
    setIsModalOpen(false);
    setSearchQuery('');
  }}
  onSave={() => {
    setIsModalOpen(false);
    setSearchQuery('');
  }}
  searchPlaceholder="Search Country Code..."
  searchQuery={searchQuery}
  onSearchChange={handleSearchChange}
  isViewMode={isViewMode}
>
{countries.length > 0 ? (
                    // <div className="grid grid-cols-3 gap-4">
                    //   {
                      countries
                      .filter((country) =>
                       
                        country.country.toLowerCase().includes(searchQuery.toLowerCase())
                      )
                      .map((country, index) => (
                        <div key={country.id} className={`flex items-center ${index % 2 === 0 ? 'bg-sky-blue-200' : ''}`}>
                          <input
                            type="radio"
                            name="country"
                            value={country.country}
                            checked={selectedCountry === country.country}
                            onChange={handleCheckboxChange}
                            className="mr-2 text-blue-200"
                          />
                          <label className="text-customGray">{country.country}</label>
                        </div>
                      ))
                    //   }
                    // </div>
                  ) : (
                    <p>Countries Not Found...</p>
                  )}

</ReusableModal>

               
   <LabelWithButton
        label="Country Name"
        isRequired={true}
        isDisabled={isViewMode}
        onClick={ ()=> {setIsModalOpenForCountryName(true);}   
        }
        buttonText={countryName || 'Select Country Name'}
        error={errors.countryName}
        marginLeft="ml-4"   // Set custom margin-left
      />
<ReusableModal
  title="Select Country Name"
  isOpen={isModalOpenForCountryName}
  onClose={() => {
    setIsModalOpenForCountryName(false);
    setSearchQuery('');
  }}
  onSave={() => {
    setIsModalOpenForCountryName(false);
    setSearchQuery('');
  }}
  searchPlaceholder="Search Country Name..."
  searchQuery={searchQuery}
  onSearchChange={handleSearchChange}
  isViewMode={isViewMode}
>
{countries.length > 0 ? (
                    // <div className="grid grid-cols-3 gap-4">
                    //   {
                      countries
                      .filter((country) =>
                        country.country.toLowerCase().includes(searchQuery.toLowerCase())
                      )
                      .map((country, index) => (
                        <div key={country.id} className={`flex items-center ${index % 2 === 0 ? 'bg-sky-blue-200' : ''}`}>
                          <input
                            type="radio"
                            name="country"
                            value={country.country}
                            checked={selectedCountry === country.country}
                            onChange={handleCheckboxChangeforCountryName}
                            className="mr-2 text-blue-200"
                          />
                          <label className="text-customGray">{country.countryName}</label>
                        </div>
                      ))
                    //   }
                    // </div>
                  ) : (
                    <p>Countries Not Found...</p>
                  )}
</ReusableModal>

      </div>
        
      <div className="grid grid-cols-2 gap-4 mt-4">         
          <LabelWithButton
        label="State / Province"
        isRequired={true}
        isDisabled={!countryCode || isViewMode}
        onClick={ ()=> {setIsModalOpenForState(true);}   
        }
        buttonText={state || 'Select State / Province Code'}
        error={errors.state}
        marginLeft="ml-4"   // Set custom margin-left
      />


<ReusableModal
  title="Select State / Province Code"
  isOpen={isModalOpenForState}
  onClose={() => {
    setIsModalOpenForState(false);
    setSearchQueryState('');
  }}
  onSave={() => {
    setIsModalOpenForState(false);
    setSearchQueryState('');
  }}
  searchPlaceholder="Search State / Province Code..."
  searchQuery={searchQueryState}
  onSearchChange={handleSearchChangeState}
  isViewMode={isViewMode}
>
{stateProvinces.length > 0 ? (
                    // <div className="grid grid-cols-3 gap-4">
                    //   {
                      stateProvinces
                      .filter((stateP) =>
                      stateP.state.toLowerCase().includes(searchQueryState.toLowerCase())
                      )
                      .map((stateP, index) => (
                        <div key={stateP.id} className={`flex items-center ${index % 2 === 0 ? 'bg-sky-blue-200' : ''}`}>
                          <input
                            type="radio"
                            name="country"
                            value={stateP.state}
                            checked={selectedState === stateP.state}
                            onChange={handleCheckboxChangeForState}
                            className="mr-2"
                          />
                          <label className="text-customGray">{stateP.state}</label>
                        </div>
                      ))
                    //   }
                    // </div>
                  ) : (
                    <p>Countries Not Found...</p>
                  )}
</ReusableModal>


        {/* <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">State / Province Name</label>
          <span className="ml-4">:</span>
           <button
            onClick={toggleModalForStateName}
            disabled={!countryCode || isViewMode}

            className={`border border-gray-300 p-1 rounded w-80 h-8 ${isViewMode|| !countryCode ? 'text-gray-500 bg-gray-100':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between 
            ${errors.stateName ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
          >
            <span>{stateName || 'Select State / Province Name'}</span>
            <RxDropdownMenu className="ml-2" />
          </button>
          {isModalOpenForStateName && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white p-0 w-[800px] h-[400px] flex flex-col rounded-2xl overflow-hidden">
                <div className="flex justify-between items-center p-4 shadow shadow-blue-100 bg-white sticky top-0 z-10">
                  <h2 className="text-lg font-semibold text-customGray">Select State / Province Name</h2>

                      <input
              type="text"
              placeholder="Search state province..."
              value={searchQueryState}
              onChange={handleSearchChangeState}
              className="border border-gray-300 p-1 rounded text-customGray w-80 ml-60 placeholder-gray-500 focus:outline-none focus:border-customBlue focus:ring-1 focus:ring-customBlue"
            />
                  <button onClick={toggleModalForStateName} className="text-gray-500 hover:text-gray-700 text-2xl">&times;</button>
                </div>
                <div className="flex-1 overflow-auto p-4">

                  {stateProvinces.length > 0 ? (
                    <div className="grid grid-cols-3 gap-4">
                      {stateProvinces
                      .filter((stateP) =>
                        stateP.State_Name.toLowerCase().includes(searchQueryState.toLowerCase()) 
                      // || stateP.state.toLowerCase().includes(searchQueryState.toLowerCase())
                      )
                      .map((stateP, index) => (
                        <div key={stateP.id} className={`flex items-center ${index % 2 === 0 ? 'bg-sky-blue-200' : ''}`}>
                          <input
                            type="radio"
                            name="country"
                            value={stateP.state}
                            checked={selectedState === stateP.state}
                            onChange={handleCheckboxChangeForState}
                            className="mr-2"
                          />
                          <label className="text-customGray">{stateP.State_Name}</label>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>Countries Not Found...</p>
                  )}
                </div>
                
                  <div className="flex justify-end p-2 bg-white sticky bottom-0 border-t">
                  <button
                    onClick={toggleModalForStateName}
                    className="mt-2 bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 mr-20"
                  >
                    Save
                  </button>
                </div>
              
                
              </div>
            </div>
          )}

        </div> */}

<LabelWithButton
        label="State / Province Name"
        isRequired={true}
        isDisabled={!countryCode || isViewMode}
        onClick={ ()=> {setIsModalOpenForStateName(true);}   
        }
        buttonText={stateName || 'Select State / Province Name'}
        error={errors.stateName}
        marginLeft="ml-4"   // Set custom margin-left
      />


<ReusableModal
  title="Select State / Province Name"
  isOpen={isModalOpenForStateName}
  onClose={() => {
    setIsModalOpenForStateName(false);
    setSearchQueryState('');
  }}
  onSave={() => {
    setIsModalOpenForStateName(false);
    setSearchQueryState('');
  }}
  searchPlaceholder="Search State / Province Name..."
  searchQuery={searchQueryState}
  onSearchChange={handleSearchChangeState}
  isViewMode={isViewMode}
>
{stateProvinces.length > 0 ? (
                    // <div className="grid grid-cols-3 gap-4">
                    //   {
                      stateProvinces
                      .filter((stateP) =>
                        stateP.State_Name.toLowerCase().includes(searchQueryState.toLowerCase()) 
                      // || stateP.state.toLowerCase().includes(searchQueryState.toLowerCase())
                      )
                      .map((stateP, index) => (
                        <div key={stateP.id} className={`flex items-center ${index % 2 === 0 ? 'bg-sky-blue-200' : ''}`}>
                          <input
                            type="radio"
                            name="country"
                            value={stateP.state}
                            checked={selectedState === stateP.state}
                            onChange={handleCheckboxChangeForState}
                            className="mr-2"
                          />
                          <label className="text-customGray">{stateP.State_Name}</label>
                        </div>
                      ))
                    //   }
                    // </div>
                  ) : (
                    <p>Countries Not Found...</p>
                  )}

</ReusableModal>

      </div>


            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">District</label>
                <span className="ml-4">:</span>
                <input type="text"
            disabled={isViewMode}
            value={district}
                 onChange={(e) => {setdistrict(e.target.value);
                setUnsavedChanges(true);

                 }}
                 placeholder="2A" 
                 className={`border border-gray-300 p-1.5 rounded w-80 h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`} />
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">Postal Code</label>
                <span className="ml-4">:</span>
                <input type="text"
            disabled={isViewMode}
            value={postalCode}
                onChange={(e) => {setpostalCode(e.target.value);
                setUnsavedChanges(true);

                }}
                 placeholder="B name" className={`border border-gray-300 p-1.5 rounded w-80 h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`} />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">Zip Code</label>
                <span className="ml-4">:</span>
                <input type="text" 
            disabled={isViewMode}
            value={zipCode}
                onChange={(e) => {setzipCode(e.target.value);
                setUnsavedChanges(true);

                }}
                placeholder="2A" className={`border border-gray-300 p-1.5 rounded w-80 h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`} />
              </div>
              
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">PO Box</label>
                <span className="ml-4">:</span>
                <input type="text" 
            disabled={isViewMode}
            value={poBox}
                onChange={(e) => {setpoBox(e.target.value);
                setUnsavedChanges(true);

                }}
                placeholder="B name" className={`border border-gray-300 p-1.5 rounded w-80 h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`} />
              </div>

            </div>

          </div>

        
        </div>
        </TableWrapper>
        </div>
      )}
 {/* <div className="flex-grow"> */}
      {activeTab === "userPreferences" && (
          <div className="flex flex-col ">
          <div className="m-6 mb-6 border border-gray-200 p-5  rounded-lg ">
            <TableWrapper maxHeight="80vh">
            <div className="flex-grow p  ">
          <div className="grid grid-cols-2 gap-x-8 gap-y-8 mt-6">
            <div className="flex flex-col space-y-6">
              {errors.userId && (
                <p className="text-red-500 text-sm ml-4">{errors.userId}</p>
              )}


<LabelWithButton
        label="Language "
        isDisabled={isViewMode}
        onClick={ ()=> {setIslanguageModalOpen(true);}   
        }
        buttonText={language || 'Select language '}
        error={errors.language}
        width="w-72"        // Set custom width
        marginLeft="ml-4"   // Set custom margin-left
      />

                <ReusableModal
  title="Select Language Code"
  isOpen={islanguageModalOpen}
  onClose={() => {
    setIslanguageModalOpen(false);
    setSearchQuery('');
  }}
  onSave={() => {
    setIslanguageModalOpen(false);
    setSearchQuery('');
  }}
  searchPlaceholder="Search Language..."
  searchQuery={searchQuery}
  onSearchChange={handleSearchChange}
  isViewMode={isViewMode}
>
           {languages.length > 0 ? (
                            languages
                              .filter(
                                (language) =>
                                  language.LE &&
                                  language.LE.toLowerCase().includes(
                                    searchQuery.toLowerCase()
                                  )
                              )
                              .map((language, index) => (
                                <div
                                  key={language.id}
                                  className={`flex items-center ${
                                    index % 2 === 0 ? "bg-sky-blue-200" : ""
                                  }`}
                                >
                                  <input
                                    type="radio"
                                    name="language"
                                    value={language.LE}
                                    checked={
                                      selectedlanguage ===
                                      language.LE
                                    }
                                    onChange={handleLanguageCheckBoxChange}
                                    className="mr-2 accent-customBlue"
                                    disabled={isViewMode}
                                  />
                                  <label>{language.LE}</label>
                                </div>
                              ))
                          
                        ) : (
                          <p>Loading Currencies...</p>
                        )}
</ReusableModal>


              <label className="flex items-center">
  <span className="w-48 text-sm font-medium text-gray-500">
    Date Format<span className="text-red-500 ml-1">*</span>
  </span>
  <span className="ml-3">:</span>
  {/* <select
            disabled={isViewMode}
            value={dateFormat}
    onChange={(e) => setdateFormat(e.target.value)}
    className={`border border-gray-300 p-1 rounded w-72 h-8 ${isViewMode ? 'text-gray-500 bg-gray-100':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4
        ${errors.dateFormat ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
  >
    <option value="">Select Date Format</option>

    <option value="1">DD/MM/YYYY</option>
    <option value="2">MM/DD/YYYY</option>
    <option value="3">DD/MM/YY</option>
    <option value="4">MM/DD/YY</option>

    <option value="5">DD.MM.YYYY</option>
    <option value="6">DDMMYY</option>

  </select> */}

<CustomDropdown
        value={dateFormat}
        options={dateFormatOptions}
        onChange={(value) => {
          setdateFormat(value);
            setUnsavedChanges(true); // Mark as unsaved
            setOpenDropdown(false);
          }}         isDisabled={isViewMode}
        isOpen={openDropdown === "dateFormat"}
        onToggle={()=> handleToggle("dateFormat")}
        width="w-72"


        setUnsavedChanges={setUnsavedChanges}
        className="ml-4"
        placeholder="Select Date Format"
      />

  {errors.dateFormat && (
    <p className="text-red-500 text-sm ml-4">
      {errors.dateFormat}
    </p>
  )}
</label>

<label className="flex items-center">
        <span className="w-48 text-sm font-medium text-gray-500">
          Amount Format
        </span>
        <span className="ml-3">:</span>

         {/* <select
                value={amountFormat}
            disabled={isViewMode}
            onChange={(e) => setamountFormat(e.target.value)}
                className={`border border-gray-300 p-1 rounded w-72 h-8 ${isViewMode ? 'text-gray-500 bg-gray-100':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4`}
              >
                <option value="">Select Amount Format</option>
            
                <option value="'1,234,567.89">'1,234,567.89</option>
                <option value="1.234.567,890">1.234.567,890</option>
                <option value="12,34,567.890">12,34,567.890</option>            
              </select> */}

<CustomDropdown
        value={amountFormat}
        options={amountFormatOptions}
        onChange={(value) => {
            setamountFormat(value);
            setUnsavedChanges(true); // Mark as unsaved
            setOpenDropdown(false);
          }}        isOpen={openDropdown === "amountFormat"}
        onToggle={()=> handleToggle("amountFormat")}
        isDisabled={isViewMode}
        setUnsavedChanges={setUnsavedChanges}
        width="w-72"

        className="ml-4"
        placeholder="Select Amount Format"
      />

      </label>


<label className="flex items-center">
        <span className="w-48 text-sm font-medium text-gray-500">
          Thousand Separator
        </span>
        <span className="ml-3">:</span>
        <input
          type="text"
            disabled={isViewMode}
            value={thousandSeparator}
          onChange={handleThousandChange}
          className={`border p-1.5 rounded w-72 h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none ml-4 ${
            error.thousand || error.same ? 'border-red-500' : 'border-gray-300 hover:border-blue-400'
          }`}
        />
      </label>
      {error.thousand && (
        <p className="text-red-500 text-sm mt-1">
          Only '.' and ',' are allowed for thousand separator.
        </p>
      )}

      <label className="flex items-center mt-4">
        <span className="w-48 text-sm font-medium text-gray-500">
          Decimal Separator
        </span>
        <span className="ml-3">:</span>
        <input
          type="text"
            disabled={isViewMode}
            value={decimalSeparator}
          onChange={handleDecimalChange}
          className={`border p-1.5 rounded w-72 h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none ml-4 ${
            error.decimal || error.same ? 'border-red-500' : 'border-gray-300 hover:border-blue-400'
          }`}
        />
      </label>
      {error.decimal && (
        <p className="text-red-500 text-sm mt-1">
          Only '.' and ',' are allowed for decimal separator.
        </p>
      )}

      {error.same && (
        <p className="text-red-500 text-sm mt-1">
          Thousand and decimal separators cannot be the same.
        </p>
      )}


<label className="flex items-center">
        <span className="w-48 text-sm font-medium text-gray-500">
          Accelerator for Thousands
        </span>
        <span className="ml-3">:</span>
        <input
          type="text"
            disabled={isViewMode}
            value={acceleratorForThousands + suffixForThousands} // Append current suffix ('K' or 'T')
          onChange={handleChangeforThousands}
          className={`border p-1.5 rounded w-72 h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none ml-4 ${
            errorThousands  ? 'border-red-500' : 'border-gray-300 hover:border-blue-400'
          }`}
        />
      </label>
      {errorThousands  && (
        <p className="text-red-500 text-sm mt-1">
          Only 'K' or 'T' are allowed.
        </p>
      )}


              <label className="flex items-center">
                <span className="w-48 text-sm font-medium text-gray-500">
                  Accelerator for Lakhs
                </span>
                <span className="ml-3">:</span>
                <input
                  type="text"
            disabled={isViewMode}
            value={acceleratorForLakhs + suffixForLakhs}
                  onChange={handleChangeforLakhs}
                  className={`border border-gray-300 p-1.5 rounded w-72 h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4`}
                />
              </label>
              {errorLakhs  && (
        <p className="text-red-500 text-sm mt-1">
          Only 'L' are allowed.
        </p>
      )}

              <label className="flex items-center">
                <span className="w-48 text-sm font-medium text-gray-500">
                  Accelerator for Millions
                </span>
                <span className="ml-3">:</span>
                <input
            disabled={isViewMode}
                  type="text"
                  value={acceleratorForMillions + suffixForMillions}
                  onChange={handleChangeforMillions}
                  className={`border border-gray-300 p-1.5 rounded w-72 h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4`}
                />
              </label>
              {errorMillions  && (
        <p className="text-red-500 text-sm mt-1">
          Only 'M' are allowed.
        </p>
      )}

              <label className="flex items-center">
                <span className="w-48 text-sm font-medium text-gray-500">
                  Time Format <span className="text-red-500 ml-1">*</span>
                </span>
                <span className="ml-3">:</span>
                {/* <select
                  value={timeFormat}
            disabled={isViewMode}
            onChange={(e) => settimeFormat(e.target.value)}
                  className={`border border-gray-300 p-1.5 rounded w-72 h-8 ${isViewMode ? 'text-gray-500 bg-gray-100':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4
                      ${errors.timeFormat ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
                >
                  <option value="2">24 Hours</option>
                  <option value="1">12 Hours</option>
                  <option value="">Select Time Format</option>
                </select> */}

<CustomDropdown
        value={timeFormat}
        options={timeFormatOptions}
        onChange={(value) => {
            settimeFormat(value);
            setUnsavedChanges(true); // Mark as unsaved
            setOpenDropdown(false);
          }}         isDisabled={isViewMode}
          width="w-72"

        isOpen={openDropdown === "timeFormat"}
        onToggle={()=> handleToggle("timeFormat")}
        setUnsavedChanges={setUnsavedChanges}
        className="ml-4"
        placeholder="Select Time Format"
      />

                {errors.timeFormat && (
                  <p className="text-red-500 text-sm ml-4">
                    {errors.timeFormat}
                  </p>
                )}
              </label>


              <label className="flex items-center">
                <span className="w-48 text-sm font-medium text-gray-500">
                  Time Zone
                </span>
                <span className="ml-3">:</span>
                <input
                  type="text"
                  value={timeZone}
            disabled={isViewMode}
            onChange={(e) => {settimeZone(e.target.value);
              setUnsavedChanges(true);

            }}
                  className={`border border-gray-300 p-1.5 rounded w-72 h-8 ${isViewMode ? 'text-gray-500':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4`}
                />
              </label>
            </div>

            <div className="flex flex-col space-y-6">
              <label className="flex items-center">
                <input
            disabled={isViewMode}
                  type="checkbox"
                  checked={receiveSystemMail === 1} // Check if the value is 1
              onChange={(e) => {setreceiveSystemMail(e.target.checked ? 1 : 0);
                setUnsavedChanges(true);

              }} // Set value to 1 if checked, otherwise 0
                  className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500 "
                />
                <p className="ml-4 font-small text-gray-600">
                  Don't want to receive System emails
                </p>
              </label>

              <label className="flex items-center">
                <input
            disabled={isViewMode}
                  type="checkbox"
                  checked={downloadData === 1} // Check if the value is 1
                  onChange={(e) => {setdownloadData(e.target.checked ? 1 : 0);
                setUnsavedChanges(true);

                  }} // Set value to 1 if checked, otherwise 0
                  className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
                />
                <p className="ml-4 font-small text-gray-600">
                  Download the Data
                </p>
              </label>

              <label className="flex items-center">
                <input
            disabled={isViewMode}
                  type="checkbox"
                  checked={changesInCashflow === 1} // Check if the value is 1
                  onChange={(e) => {setchangesInCashflow(e.target.checked ? 1 : 0);
                setUnsavedChanges(true);

                  }} // Set value to 1 if checked, otherwise 0
                  className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
                />
                <p className="ml-4 font-small text-gray-600">
                  Change the Cash flow code
                </p>
              </label>

              <label className="flex items-center">
                <input
            disabled={isViewMode}
                  type="checkbox"
                  checked={changeVarient === 1} // Check if the value is 1
              onChange={(e) => {setchangeVarient(e.target.checked ? 1 : 0);
                setUnsavedChanges(true);

              }} // Set value to 1 if checked, otherwise 0
                  className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
                />
                <p className="ml-4 font-small text-gray-600">
                  Can Change the varient
                </p>
              </label>

              <label className="flex items-center">
                <input
            disabled={isViewMode}
                  type="checkbox"
                  checked={deleteAllRecords === 1} // Check if the value is 1
                  onChange={(e) => {setdeleteAllRecords(e.target.checked ? 1 : 0);
                setUnsavedChanges(true);

                  }} // Set value to 1 if checked, otherwise 0
                  className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
                />
                <p className="ml-4 font-small text-gray-600">
                  Can Delete all records
                </p>
              </label>

              <label className="flex items-center">
                <input
            disabled={isViewMode}
                  type="checkbox"
                  checked={changeAccountingCode === 1} // Check if the value is 1
                  onChange={(e) => {setchangeAccountingCode(e.target.checked ? 1 : 0);
                setUnsavedChanges(true);

                  }} // Set value to 1 if checked, otherwise 0
                  className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
                />
                <p className="ml-4 font-small text-gray-600">
                  Change the Accounting Code
                </p>
              </label>
              <label className="flex items-center">
                <input
            disabled={isViewMode}
                  type="checkbox"
                  checked={updateCountry === 1} // Check if the value is 1
                  onChange={(e) => {setupdateCountry(e.target.checked ? 1 : 0);
                setUnsavedChanges(true);

                  }} // Set value to 1 if checked, otherwise 0
                  className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
                />
                <p className="ml-4 font-small text-gray-600">
                  Do Not Update Country
                </p>
              </label>
            </div>
          </div>
        </div>
        </TableWrapper> 
        </div>
        </div>
      )}
        {/* </div> */}

      {activeTab === "loginMethod" && (
        <div className="p-12">
          {/* <div className="mt-8 space-y-8"> */}
            {errors.userId && (
              <p className="text-red-500 text-sm ml-4">{errors.userId}</p>
            )}

            {/* <h3 className="text-customGray">Login Method</h3> */}
            <div className="flex flex-col space-y-4">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="loginMethod"
                  value="0" // Value mapped to 0 for Password
                  checked={loginMethod === 0}
                  onChange={handleLoginMethodChange}
                  className="form-radio text-blue-500 w-4 h-4"
                />
                <span className="ml-2 text-customGray">Password</span>
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="loginMethod"
                  value="1" // Value mapped to 1 for Multi-Factor Authentication
                  checked={loginMethod === 1}
                  disabled
                  onChange={handleLoginMethodChange}
                  className="form-radio text-blue-500 w-4 h-4"
                />
                <span className="ml-2 text-customGray">Multi-Factor Authentication</span>
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="loginMethod"
                  disabled
                  value="2" // Value mapped to 2 for OTP
                  checked={loginMethod === 2}
                  onChange={handleLoginMethodChange}
                  className="form-radio text-blue-500 w-4 h-4"
                />
                <span className="ml-2 text-customGray">OTP</span>
              </label>
            </div>
          {/* </div> */}
          <div className="flex flex-row mt-12">
         
          </div>
        </div>
      )}

<Footer>
{(!isViewMode && (
     <ButtonGroup
        onSave={handleSaveBasicDetails}
        onSaveContinue={handleSaveContinueDetails}
      /> 
    ))}

</Footer>

  </div>

    
  );
}
