import RestService from "./RestServices";

const BankServices = {
  getAllBank: async (path) => {
    try {
      const response = await RestService.GetAllData(path);
      return response;
    } catch (error) {
      console.error('Error fetching Bank data:', error);
      return { error: true, message: "Error fetching Bank data" };
    }
  },
  getBankById: (id) => {
    return RestService.GetByIdData(`/get-bank-id`, id);
  },
  deleteBank:(id)=>{
    return RestService.DeleteData("/delete-bank",id);
      },
  addBank: async (path, data) => {
    try {
      console.log('Sending data:', data);
      const response = await RestService.CreateData(path, data);
      console.log('Response from Server:', response);
      return response;
    } catch (error) {
      console.error('Error adding Bank:', error);
      return { error: true, message: "Error adding Bank" };
    }
  },
  getAllbankwithPage: (pageSize, page, searchQuery = "") => {
    const searchParam = searchQuery ? `&searchQuery=${encodeURIComponent(searchQuery)}` : "";
    return RestService.GetAllData(
      `/get-bank-page?limit=${pageSize}&offset=${(page - 1) * pageSize}${searchParam}`
    );
  },
  checkDuplicateBankcode: async (path, roleData) => {
    try {
      const response = await RestService.CreateData(path, roleData);
      console.log("Response from server:", response);

      return response;
    } catch (error) {
      console.error("Error fetching Bank code:", error);
      return { error: true, message: "Error fetching Bank code" };
    }
  },
  
};



export default BankServices;
