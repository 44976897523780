import React, { useCallback, useEffect, useState } from 'react'
import Header from '../../../../../components/Header'
import { useNavigate, useParams } from 'react-router-dom';
import SearchInput from '../../../../../components/SearchInput';
import PrimaryActions from '../../Components/ActionButton';
import { PrimaryActionsConfig } from '../../Components/ActionButtonConfig';
import { IoAddCircleOutline, IoSettingsOutline } from 'react-icons/io5';
import { MdOutlineFileDownload, MdOutlineFileUpload } from 'react-icons/md';
import { IoMdCheckmark, IoMdClose, IoMdCopy, IoMdCreate, IoMdRefresh, IoMdTime, IoMdTrash } from 'react-icons/io';
import LegalEntityServices from '../../../../../rest-services/LegalEntityServices';
import CustomeUpLoader from '../../../../../components/CustomeLoader';
import ColumnSettingsPopup from '../../Components/ColumnSettingPopup';
import ReTable from '../../../../../components/Table';
import * as XLSX from 'xlsx';
import BankBranchServices from '../../../../../rest-services/BankBranchServices';
import { TbDeviceDesktopCancel, TbFlag3Filled } from 'react-icons/tb';
import { GiSettingsKnobs } from 'react-icons/gi';
import { useToast } from "../../../../../components/toast/toast";
import CustomButton from '../../../../../components/CustomButton';
import HoverButton from '../../../../../components/HoverButton';
import { RxDropdownMenu } from 'react-icons/rx';
import { VscVmActive } from 'react-icons/vsc';
import ConfirmationDialog from '../../../../../components/Deletionpopup';
import Pagination from '../../../../../components/PageNation';
import { debounce } from 'lodash';

export default function BankBranch() {
  const { iconId } = useParams(); 

    const columns = [
      { Header: <TbFlag3Filled style={{ color: "#ffff" }} />, 
      accessor: "status", 
      visible: true, 
      width: 200,
      fixed:true,
      Cell: ({ value }) => {
        switch (value) {
          case 3:
            return <TbFlag3Filled style={{ color: "#29CB00" }} />; // Green for status 3
          case 0:
            return <TbFlag3Filled style={{ color: "gray" }} />; // Gray for status 0
          case 1:
            return <TbFlag3Filled style={{ color: "yellow" }} />; // Yellow for status 1
          case 2:
            return <TbFlag3Filled style={{ color: "orange" }} />; // Orange for status 2
          case 4:
            return <TbFlag3Filled style={{ color: "red" }} />; // Red for status 4
          default:
            return <TbFlag3Filled style={{ color: "black" }} />; // Default black flag
        }
      },
    },
        { Header: "Bank Branch", accessor: "bankBranch", visible: true, width: 200 ,alignment: "left"},
        { Header: "Branch Name", accessor: "branchName", visible: true, width: 200 ,alignment: "left"},
        { Header: "Bank ID", accessor: "BankId", visible: true, width: 200 ,alignment: "left"},
        { Header: "Bank Name", accessor: "BankName", visible: true, width: 200 ,alignment: "left"},
        { Header: "Bank ", accessor: "Bank", visible: true, width: 200 ,alignment: "left"},
        { Header: "DoorNo ", accessor: "addresses[0].doorNo", visible: true, width: 200 ,alignment: "left"},
        { Header: "FloorNo ", accessor: "addresses[0].floorNo", visible: true, width: 200 ,alignment: "left"},
        { Header: "Building No ", accessor: "addresses[0].buildingNo", visible: true, width: 200 ,alignment: "left"},
        { Header: "Building Name ", accessor: "addresses[0].buildingName", visible: true, width: 200 ,alignment: "left" },
        { Header: "LandMark ", accessor: "addresses[0].landMark", visible: true, width: 200 ,alignment: "left"},
        { Header: "Street No ", accessor: "addresses[0].streetNo", visible: true, width: 200 ,alignment: "left"},
        { Header: "Street Name ", accessor: "addresses[0].streetName", visible: true, width: 200 ,alignment: "left"},
        { Header: "Road No ", accessor: "addresses[0].roadNo", visible: true, width: 200 ,alignment: "left" },
        { Header: "Road Name ", accessor: "addresses[0].roadName", visible: true, width: 200 ,alignment: "left"},
        { Header: "Block ", accessor: "addresses[0].areaBlock", visible: true, width: 200 ,alignment: "left"},
        { Header: "Area Name ", accessor: "addresses[0].areaName", visible: true, width: 200 ,alignment: "left"},
        { Header: "Address 1 ", accessor: "addresses[0].addressLine1", visible: true, width: 200  ,alignment: "left"},
        { Header: "Address 2 ", accessor: "addresses[0].addressLine2", visible: true, width: 200 ,alignment: "left"},
        { Header: "Address 3 ", accessor: "addresses[0].addressLine3", visible: true, width: 200 ,alignment: "left"},
        { Header: "Country Code ", accessor: "addresses[0].countryCode", visible: true, width: 200  ,alignment: "left"},
        { Header: "State  ", accessor: "addresses[0].state", visible: true, width: 200  ,alignment: "left"},
        { Header: "District ", accessor: "addresses[0].district", visible: true, width: 200 ,alignment: "left"},
        { Header: "Postal Code ", accessor: "addresses[0].postalCode", visible: true, width: 200 ,alignment: "left"},
        { Header: "Zip Code ", accessor: "addresses[0].zipCode", visible: true, width: 200 ,alignment: "left"},
        { Header: "PO Box ", accessor: "addresses[0].poBox", visible: true, width: 200 ,alignment: "left"},
        { Header: "Bank BIC ", accessor: "identifier[0].bankBIC", visible: true, width: 200 ,alignment: "left"},
        { Header: "Country Code ", accessor: "identifier[0].country_Code", visible: true, width: 200 ,alignment: "left"},
        { Header: "Country Code Type ", accessor: "identifier[0].countryCodeType", visible: true, width: 200 ,alignment: "left"},
        { Header: "Customer Code ", accessor: "identifier[0].customerCode", visible: true, width: 200 ,alignment: "left" },
        { Header: "MICR ", accessor: "identifier[0].micr", visible: true, width: 200 ,alignment: "left"},
        { Header: "Bank Code ", accessor: "identifier[0].bankCode", visible: true, width: 200 ,alignment: "left"},
        { Header: "Branch Code ", accessor: "identifier[0].BranchCode", visible: true, width: 200  ,alignment: "left"},
        { Header: "Bank Account Min Length ", accessor: "specificchecks[0].bankAccountMinLength", visible: true, width: 200 ,alignment: "left"},
        { Header: "Bank Account Max Length ", accessor: "specificchecks[0].bankAccountMaxLength", visible: true, width: 200 ,alignment: "left"},





        { Header: "Created By", accessor: "created_by", visible: true, width: 200 ,alignment: "left" },
        {
          Header: "Created Date",
          accessor: "creationDate",
          visible: true,
          width: 200,
          Cell: ({ value }) => {
            if (!value) return "";
            const date = new Date(value);
            return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
          },
        },
        {
          Header: "Created Time",
          accessor: "creationTime",
          visible: true,
          width: 200,
          Cell: ({ value }) => {
            if (!value) return "";
            const date = new Date(value);
            return date.toLocaleTimeString("en-GB", {
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            });
          },
        },
        { Header: "Updated By", accessor: "changed_by", visible: true, width: 200 ,alignment: "left" },
        {
          Header: "Updated Date",
          accessor: "changedDate",
          visible: true,
          width: 200,
          Cell: ({ value }) => {
            if (!value) return "";
            const date = new Date(value);
            return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
          },
        },
        {
          Header: "Updated Time",
          accessor: "changedTime",
          visible: true,
          width: 200,
          // Cell: ({ value }) => {
          //   if (!value) return "";
          //   const date = new Date(value);
          //   return date.toLocaleTimeString("en-GB", {
          //     hour: "2-digit",
          //     minute: "2-digit",
          //     second: "2-digit",
          //     hour12: true,
          //   });
          // },
        },
      ];
      
    const [bankBranch, setBankBranch] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(null);
    const navigate = useNavigate();
    // const { toast } = useToast();
    const [bankBranchId, setBankBranchId] = useState(null);
    const [filteredbankBranch, setfilteredBankBranch] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [showExtraIcons, setShowExtraIcons] = useState(false);
    const [columnSettings, setColumnSettings] = useState(columns);
    const [isSettingsPopupOpen, setIsSettingsPopupOpen] = useState(false);
    const { toast } = useToast();
    const [assignee, setAssignee] = useState({ id: "", assigner: "" }); // State to store assignee
    const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false);
  const [isbankBranchPopupOpen, setIsbankBranchPopupOpen] = useState(false); // Controls popup visibility
  const [isbankBranchbankidPopupOpen, setIsbankBranchbankidPopupOpen] = useState(false); // Controls popup visibility
  const [isbankBranchbanksPopupOpen, setIsbankBranchbanksPopupOpen] = useState(false); // Controls popup visibility
  const [isUpdatedByPopupOpen, setIsUpdatedByPopupOpen] = useState(false);
  const [isCreatedByPopupOpen, setIsCreatedByPopupOpen] = useState(false);
  const [filterType, setFilterType] = useState("1"); // 'createdBy' or 'updatedBy'
  const [isCountryPopupOpen, setIsCountryPopupOpen] = useState(false); // Controls popup visibility
  const [filters, setFilters] = useState({
    status: "",
    bankBranch: [],
    bankName:[],
    bank:[],
    countryCode: [],
    updated_by: "",
    updated_date_from: "",
    updated_date_to: "",
    created_by: "",
    created_date_from: "",
    created_date_to: "",
  });
  const [selectedbankbranches, setSelectedbankbranches] = useState([]);
  const [selectedbankbranchbankid, setSelectedbankbranchbankid] = useState([]);
  const [selectedbankbranchbanks, setSelectedbankbranchbanks] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedCreatedBy, setSelectedCreatedBy] = useState([]);
  const [selectedUpdatedBy, setSelectedUpdatedBy] = useState([]);
  const [bankBranches, setbankBranches] = useState([]); // For fetched company codes
  const [bankBranchbankidies, setbankBranchbankidies] = useState([]); // For fetched company codes
  const [bankBranchbanks, setbankBranchbanks] = useState([]); // For fetched company codes
  const [filteredbankBranches, setFilteredbankBranches] = useState([]); 
  const [bankBranchesearchQuery, setbankBranchesearchQuery] = useState("");
  const [filteredbankBranchbankidies, setFilteredbankBranchbankidies] = useState([]); 
  const [bankBranchbankidsearchQuery, setbankBranchbankidsearchQuery] = useState("");
  const [filteredbankBranchbanks, setFilteredbankBranchbanks] = useState([]); 
  const [bankBranchbankssearchQuery, setbankBranchbankssearchQuery] = useState("");
  const [countryCodes, setcountryCodes] = useState([]); // For fetched company codes
  const [filteredCountryCodes, setFilteredCountryCodes] = useState([]); 
  const [countryCodeSearchQuery, setCountryCodeSearchQuery] = useState("");
  const [updatedbydata, setupdatedbydata] = useState([]); // For fetched company codes
  const [filteredupdatedbydata, setfilteredupdatedbydata] = useState([]); 
  const [updatedbysearchquery, setupdatedbysearchquery] = useState("");
    const [createdbydatas, setcreatedbydatas] = useState([]); // For fetched company codes
  const [filteredcreatedbydatas, setFilteredcreatedbydatas] = useState([]); 
  const [createdbydatasearchQuery, setcreatedbydatasearchQuery] = useState("");
  const [tempSelectedbankbranches, setTempSelectedbankbranches] = useState(selectedbankbranches);
  const [tempSelectedbankbranchbankid, setTempSelectedbankbranchbankid] = useState(selectedbankbranches);
  const [tempSelectedbankbranchbank, setTempSelectedbankbranchbank] = useState(selectedbankbranches);
  const [tempSelectedcountries, setTempSelectedcountries] = useState(selectedCountries);
  const [tempSelectedupdatedby, setTempSelectedupdatedby] = useState(selectedUpdatedBy);
  const [tempSelectedcreatedby, setTempSelectedcreatedby] = useState(selectedCreatedBy);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
    
    

    useEffect(() => {
      fetchBankBranch();
      fetchAssignee();
      fetchBankBranches();
      fetchBankBranchBankidies();
      fetchBankBranchBanks();

      fetchCountryCodes();
      fetchCreatedbydata();
      fetchupdatedbydata();
    }, []);
    
   
    useEffect(() => {
      if (bankBranchesearchQuery.trim() === "") {
        setFilteredbankBranches(bankBranches); // Show all if search query is empty
      } else {
        const filtered = bankBranches.filter((code) =>
          code.bankBranch
            .toLowerCase()
            .includes(bankBranchesearchQuery.toLowerCase())
        );
        setFilteredbankBranches(filtered);
      }
    }, [bankBranchesearchQuery, bankBranches]);
    useEffect(() => {
      if (bankBranchbankidsearchQuery.trim() === "") {
        setFilteredbankBranchbankidies(bankBranchbankidies); // Show all if search query is empty
      } else {
        const filtered = bankBranchbankidies.filter((code) =>
          code.BankId
            .toLowerCase()
            .includes(bankBranchbankidsearchQuery.toLowerCase())
        );
        setFilteredbankBranchbankidies(filtered);
      }
    }, [bankBranchbankidsearchQuery, bankBranchbankidies]);
    useEffect(() => {
      if (bankBranchbankssearchQuery.trim() === "") {
        setFilteredbankBranchbanks(bankBranchbanks); // Show all if search query is empty
      } else {
        const filtered = bankBranchbanks.filter((code) =>
          code.Bank
            .toLowerCase()
            .includes(bankBranchbankssearchQuery.toLowerCase())
        );
        setFilteredbankBranchbanks(filtered);
      }
    }, [bankBranchbankssearchQuery, bankBranchbanks]);
    useEffect(() => {
      if (countryCodeSearchQuery.trim() === "") {
        setFilteredCountryCodes(countryCodes); // Show all if search query is empty
      } else {
        const filtered = countryCodes.filter((code) =>
          code.toLowerCase().includes(countryCodeSearchQuery.toLowerCase())
        );
        setFilteredCountryCodes(filtered);
      }
    }, [countryCodeSearchQuery, countryCodes]);
    useEffect(() => {
      if (createdbydatasearchQuery.trim() === "") {
        setFilteredcreatedbydatas(createdbydatas); // Show all if search query is empty
      } else {
        const filtered = createdbydatas.filter((code) =>
          code.created_by
            .toLowerCase()
            .includes(createdbydatasearchQuery.toLowerCase())
        );
        setFilteredcreatedbydatas(filtered);
      }
    }, [createdbydatasearchQuery, createdbydatas]);
    useEffect(() => {
      if (updatedbysearchquery.trim() === "") {
        setfilteredupdatedbydata(updatedbydata); // Show all if search query is empty
      } else {
        const filtered = updatedbydata.filter((code) =>
          code.changed_by
            .toLowerCase()
            .includes(updatedbysearchquery.toLowerCase())
        );
        setfilteredupdatedbydata(filtered);
      }
    }, [updatedbysearchquery, updatedbydata]);
   
    const fetchBankBranches = async () => {
      setLoading(true);
      try {
        const response = await BankBranchServices.getAllBankBranch(`/getallbankbranches`);
        const responseData = response.data;
  
        if (responseData.error) {
          setAlert({ severity: "error", message: responseData.message });
          return;
        }
  
        if (responseData && Array.isArray(responseData)) {
          setbankBranches(responseData); // assuming responseData contains an array of legal entities
          setfilteredBankBranch(responseData);
  
        }
      } catch (error) {
        setAlert({
          severity: "error",
          message: `Error fetching Bank Branches: ${error.message}`,
        });
      } finally {
        setLoading(false);
      }
    };
    const fetchBankBranchBankidies = async () => {
      setLoading(true);
      try {
        const response = await BankBranchServices.getAllBankBranch(`/getallbankbranchbankid`);
        const responseData = response.data;
  
        if (responseData.error) {
          setAlert({ severity: "error", message: responseData.message });
          return;
        }
  
        if (responseData && Array.isArray(responseData)) {
          setbankBranchbankidies(responseData); // assuming responseData contains an array of legal entities
          setFilteredbankBranchbankidies(responseData);
  
        }
      } catch (error) {
        setAlert({
          severity: "error",
          message: `Error fetching Bank Branches: ${error.message}`,
        });
      } finally {
        setLoading(false);
      }
    };    
    const fetchBankBranchBanks = async () => {
      setLoading(true);
      try {
        const response = await BankBranchServices.getAllBankBranch(`/getallbankbranchbanks`);
        const responseData = response.data;
  
        if (responseData.error) {
          setAlert({ severity: "error", message: responseData.message });
          return;
        }
  
        if (responseData && Array.isArray(responseData)) {
          setbankBranchbanks(responseData); // assuming responseData contains an array of legal entities
          setFilteredbankBranchbanks(responseData);
  
        }
      } catch (error) {
        setAlert({
          severity: "error",
          message: `Error fetching Bank Branches: ${error.message}`,
        });
      } finally {
        setLoading(false);
      }
    };
    const fetchCountryCodes = async () => {
      setLoading(true);
      try {
        const response = await BankBranchServices.getAllBankBranch(`/getallbankbranchcountrycode`);
        const responseData = response.data;
  
        if (responseData.error) {
          setAlert({ severity: "error", message: responseData.message });
          return;
        }
  
        if (responseData && Array.isArray(responseData)) {
          setcountryCodes(responseData); // assuming responseData contains an array of legal entities
          setFilteredCountryCodes(responseData);
        }
      } catch (error) {
        setAlert({
          severity: "error",
          message: `Error fetching legal entities: ${error.message}`,
        });
      } finally {
        setLoading(false);
      }
    };
    const fetchCreatedbydata = async () => {
      setLoading(true);
      try {
        const response = await BankBranchServices.getAllBankBranch(`/getallbankbranchcreatedby`);
        const responseData = response.data;
  
        if (responseData.error) {
          setAlert({ severity: "error", message: responseData.message });
          return;
        }
  
        if (responseData && Array.isArray(responseData)) {
          setcreatedbydatas(responseData); // assuming responseData contains an array of legal entities
          setFilteredcreatedbydatas(responseData);
  
        }
      } catch (error) {
        setAlert({
          severity: "error",
          message: `Error fetching legal entities: ${error.message}`,
        });
      } finally {
        setLoading(false);
      }
    };
    const fetchupdatedbydata = async () => {
      setLoading(true);
      try {
        const response = await BankBranchServices.getAllBankBranch(`/getallbankbranchupdatedby`);
        const responseData = response.data;
  
        if (responseData.error) {
          setAlert({ severity: "error", message: responseData.message });
          return;
        }
  
        if (responseData && Array.isArray(responseData)) {
          setupdatedbydata(responseData); // assuming responseData contains an array of legal entities
          setfilteredupdatedbydata(responseData);
  
        }
      } catch (error) {
        setAlert({
          severity: "error",
          message: `Error fetching legal entities: ${error.message}`,
        });
      } finally {
        setLoading(false);
      }
    };
    const fetchAssignee = async () => {
      try {
        const userDetailString = sessionStorage.getItem("userDetail");
        if (userDetailString) {
          const userDetail = JSON.parse(userDetailString);
          const assigneeData = {
            id: userDetail.id || "",
            assigner: userDetail.username || "",
          };
          setAssignee(assigneeData);
        } else {
          setAlert({
            severity: "error",
            message: "No user detail found in session storage.",
          });
        }
      } catch (error) {
        setAlert({
          severity: "error",
          message: `Error fetching user details: ${error.message}`,
        });
      }
    };
     
    const pageSize = 20;

    const fetchBankBranch = async (page, searchQuery = "") => {
      setLoading(true);
      try {
        const response = await BankBranchServices.getAllgetallbankbranchbypagewithPage(
          pageSize,
          page,
          searchQuery
        );
        let { bankBranch, totalCount } = response.data.data;
        if (!bankBranch) {
          bankBranch = [];
        } else if (!Array.isArray(bankBranch)) {
          bankBranch = [bankBranch];
        }
        setBankBranch(bankBranch);
        setfilteredBankBranch(bankBranch);
        setTotalCount(totalCount);
        setTotalPages(Math.ceil(totalCount / pageSize));
      } catch (error) {
        console.error("Error fetching bankbranch data", error);
      } finally {
        setLoading(false);
      }
    };
  
    // Debounced fetch function (to avoid too many API calls)
    const debouncedFetchbankbarnch = useCallback(
      debounce((page, query) => {
        fetchBankBranch(page, query);
      }, 500), // 500ms debounce delay
      []
    );
  
    // UseEffect to trigger API call on query or page change
    useEffect(() => {
      debouncedFetchbankbarnch(currentPage, searchQuery);
      // Cleanup debounce on unmount
      return () => debouncedFetchbankbarnch.cancel();
    }, [currentPage, searchQuery, debouncedFetchbankbarnch]);
      const modifiedColumns = columnSettings
      .filter((col) => col.visible)
      .map((col) => ({
        ...col,
        width: col.width,
      }));
      const handleApproveClick = async () => {
        if (selectedRows.length > 0) {
          try {
             // Assuming selectedRows contains the selected bank branch IDs
            
            // Pass the array of selected IDs to the approval service
            const response = await BankBranchServices.saveapproved(
              `${process.env.REACT_APP_BASE_URL}/approvebranch`,
              { id: selectedRows ,created_by: assignee.assigner }  // Pass selectedBankBranchIds in the body
            );
            
            console.log("Response status:", response);
      
            if (response.status === 200) {
              
              toast("success", "Branch successfully Approved");

              // Success logic, refresh the bank branches
               fetchBankBranch();
            }  else if (response.status === 400) {
              toast("warning", "Branch is unable to approve.");
             } 
             else if (response.status === 550) {
              toast("warning", "Branch Email is Not Sent.");
             } 
             else {
              console.log("Error updating Branchs.");
            }
          } catch (error) {
            console.error("Error approving bank branches:", error);
          }
        } else {
          console.log("Please select at least one bank branch to approve.");
        }
      };
      
      const handleRejectClick = async () => {
        if (selectedRows.length > 0) {
          try {
             // Assuming selectedRows contains the selected bank branch IDs
            
            // Pass the array of selected IDs to the approval service
            const response = await BankBranchServices.saveapproved(
              `${process.env.REACT_APP_BASE_URL}/rejectbranch`,
              { id: selectedRows ,created_by: assignee.assigner }  // Pass selectedBankBranchIds in the body
            );
            
            console.log("Response status:", response.status);
      
            if (response.status === 200) {
              toast("error", "Branch successfully Rejected");
              fetchBankBranch();
            }  else if (response.status === 400){
              toast("warning", "Branch is unable to Reject.");
            } else {
              console.log("Error updating Branchs.");
            }
          } catch (error) {
            console.error("Error approving bank branches:", error);
          }
        } else {
          console.log("Please select at least one bank branch to approve.");
        }
      };
      const handleDelete = async () => {
        if (selectedRows.length === 0) return; // No rows selected to delete
        setShowConfirmation(true); // Show the confirmation dialog
      };
      
      const confirmDeletion = async () => {
        setShowConfirmation(false); // Close the confirmation dialog
        setLoading(true);
      
        try {
          // Call the new backend endpoint for bulk deletion
          const response = await BankBranchServices.deleteBankBranch({ ids: selectedRows });
      
          if (response.status === 200) {
            toast("success", "Selected BankBranches deleted successfully.");
            setSelectedRows([]); // Clear the selected rows
            fetchBankBranch(); // Refresh the list to reflect changes
          } else if (response.data.invalidIds?.length > 0) {
            // Handle cases where some branches couldn't be deleted
            toast(
              "error",
              `Failed to delete some BankBranches due to their statuses: ${response.data.invalidIds.join(", ")}`
            );
          }
        } catch (error) {
          console.error("Error deleting BankBranch(es):", error);
          toast("error", "Failed to delete one or more BankBranch(es).");
        } finally {
          setLoading(false);
        }
      };
      
      const cancelDeletion = () => {
        setShowConfirmation(false); // Close the dialog
      };
      
      
      const handleInactiveClick = async () => {
        if (selectedRows.length > 0) {
          try {
             // Assuming selectedRows contains the selected bank branch IDs
            
            // Pass the array of selected IDs to the approval service
            const response = await BankBranchServices.saveapproved(
              `${process.env.REACT_APP_BASE_URL}/inactivebranch`,
              { id: selectedRows ,created_by: assignee.assigner }  // Pass selectedBankBranchIds in the body
            );
            
            console.log("Response status:", response);
      
            if (response.status ===  200) {
              toast("success", "Branch successfully Change Status.");
              fetchBankBranch();
            } 
            else if (response.status === 400){
              toast("warning", "The Branch cannot be deactivated at this time.");
            } else {
              console.log("Error approving bank branches.");
            }
          } catch (error) {
            console.error("Error approving bank branches:", error);
          }
        } else {
          console.log("Please select at least one bank branch to approve.");
        }
      };
      const handleAuditTrail =() => {
        if (selectedRows.length === 1) {
          const selectedUser = filteredbankBranch.find((branch) => branch.id === selectedRows[0]);
          console.log('user id', selectedUser);
     
          if (selectedUser) {
            // Show the selected user's details using toast
            // toast("info", `Selected User: \nID: ${selectedUser.userId}\nName: ${selectedUser.username}`);
           
            // Navigate to the edit screen, passing the selected user as state
            navigate(`/audit-trail-branch/${11}/${selectedRows[0]}`);
          } else {
            toast("warning", "Selected user not found.");
          }
        } else {
          toast("warning", "Please select exactly one user to edit.");
        }
      };
      const handleAddcopyClick = () => {
        if (selectedRows.length === 1) {
          const selectedUser = filteredbankBranch.find((bu) => bu.id === selectedRows[0]);
          console.log('Selected Bu group to copy:', selectedUser);
      
          if (selectedUser) {
            // Navigate to the add page with the selected user data
            navigate(`/bankbranch-add/${bankBranchId}`, { state: { copiedData: selectedUser } });
          } else {
            toast("warning", "Selected user not found.");
          }
        } else {
          toast("warning", "Please select exactly one user to copy.");
        }
      };
      const handleApplyFilter = async () => {
        try {
          const response = await BankBranchServices.getFilterBankbranch(
            "/getfilterbankbranch",
            filters
          );
          if (!response.error) {
            setfilteredBankBranch(response.data);
          } else {
            console.error("Error:", response.message);
          }
        } catch (error) {
          console.error("Error fetching filtered companies:", error);
        }
      };
    const extraButtons = [
      {
        icon: IoMdCheckmark, // Pass the component itself
        onClick: handleApproveClick,
        title: "Approve",
        disabled: selectedRows.length === 0,
        
      },
      {
        icon: IoMdClose,
        onClick: handleRejectClick,
        title: "Reject",
        disabled: selectedRows.length === 0,
      },
      {
        icon: IoMdCreate,
        onClick: () => bankBranchId && navigateToForm(bankBranchId),
        title:"Edit",
        disabled: selectedRows.length === 0,  
  
      },
      {
        icon: IoMdCopy,
        onClick: handleAddcopyClick,
        title: "Copy",
        disabled: selectedRows.length === 0,
      },
      {
        icon: IoMdTime,
        onClick: handleAuditTrail,
        title: "Audit Trail",
        disabled: selectedRows.length === 0,
      },
      {
        icon: IoMdTrash,
        onClick: handleDelete,
        title: "Delete",
        disabled: selectedRows.length === 0,
      },
      {
        icon: VscVmActive,
        onClick: handleInactiveClick,
        title: "Inactive/Active",
        disabled: selectedRows.length === 0,
      },
       
      ];
      const navigateToForm = (id) => {
        navigate(`/bankbranch-add/${id}`);
      };

    const handleBreadcrumbClick = (crumb) => {
        console.log("Breadcrumb clicked:", crumb.title);
        if (crumb.title === "Company & Bank Data") {
          navigate("/master-data");
        } else if (crumb.title === "BankBranch") {
          navigate("/2/bankbranch");
        } else if (crumb.title === "Primary Data") {
          navigate("/master-data");
        } else if (crumb.path) {
          navigate(crumb.path); 
        }
      };
      const handleSettingsButtonClick = () => {
        setIsSettingsPopupOpen(true);
      };
      const handleDownload = () => {
        const dataToExport = filteredbankBranch.map((bankbranch) => ({
          "Status": bankbranch.status === 0 ? "Created" :
          bankbranch.status === 1 ? "Waiting for Approval" :
          bankbranch.status === 2 ? "Modified" :
          bankbranch.status === 3 ? "Approved" :
          bankbranch.status === 4 ? "Rejected" : "Inactive",
          "Branch": bankbranch.bankBranch || "",
          "Branch Name": bankbranch.branchName || "",
          "Bank Id": bankbranch.BankId || "",
          "Bank": bankbranch.Bank || "", // Handle Bank being null
          "Bank Name": bankbranch.BankName || "", // Handle BankName being null
          "Door No": bankbranch.addresses?.[0]?.doorNo || "", // Ensure addresses array exists
          "Floor No": bankbranch.addresses?.[0]?.floorNo || "",
          "Building No": bankbranch.addresses?.[0]?.buildingNo || "", // Ensure addresses array exists
          "Building Name": bankbranch.addresses?.[0]?.buildingName || "", // Ensure addresses array exists
          "Land Mark Name": bankbranch.addresses?.[0]?.landMark || "",
          "Street No": bankbranch.addresses?.[0]?.streetNo || "",
          "Street Name": bankbranch.addresses?.[0]?.streetName || "",
          "Road No": bankbranch.addresses?.[0]?.streetName || "",
          "Road Name": bankbranch.addresses?.[0]?.streetName || "",
          "Street Name": bankbranch.addresses?.[0]?.streetName || "",
          "Street Name": bankbranch.addresses?.[0]?.streetName || "",
          "Area Block": bankbranch.addresses?.[0]?.areaBlock || "", // Ensure addresses array exists
          "Area Name": bankbranch.addresses?.[0]?.areaName || "",
          "Address Line1": bankbranch.addresses?.[0]?.addressLine1 || "",
          "Address Line2": bankbranch.addresses?.[0]?.addressLine2 || "",
          "Address Line3": bankbranch.addresses?.[0]?.addressLine3 || "",
          "Country Code": bankbranch.identifier?.[0]?.country_Code || "", 
          "State Name": bankbranch.addresses?.[0]?.stateName || "", // Ensure state name from addresses
          "District": bankbranch.addresses?.[0]?.district || "",
          "Postal Code": bankbranch.addresses?.[0]?.postalCode || "",
          "Zip Code": bankbranch.addresses?.[0]?.zipCode || "",
          "Po Box": bankbranch.addresses?.[0]?.poBox || "",
          "Bank BIC": bankbranch.identifier?.[0]?.bankBIC || "",
          "Country Code": bankbranch.identifier?.[0]?.country_Code || "",
          "Country Code Type": bankbranch.identifier?.[0]?.countryCodeType || "",
          "Customer Code": bankbranch.identifier?.[0]?.customerCode || "",
          "MICR": bankbranch.identifier?.[0]?.micr || "", 
          "Bank Code": bankbranch.identifier?.[0]?.bankCode || "",
          "Branch Code": bankbranch.identifier?.[0]?.BranchCode || "",
          "Branch Code": bankbranch.specificchecks?.[0]?.bankAccountMinLength || "",
          "Branch Code": bankbranch.specificchecks?.[0]?.bankAccountMaxLength || "",
          "Created By": bankbranch.created_by || "",
          "Created Date": bankbranch.creationDate
            ? new Date(bankbranch.creationDate).toLocaleDateString()
            : "",
          "Created Time": bankbranch.creationTime
            ? new Date(bankbranch.creationTime).toLocaleTimeString()
            : "",
          "Updated By": bankbranch.changed_by || "", // Handle null values
          "Updated Date": bankbranch.changedDate
            ? new Date(bankbranch.changedDate).toLocaleDateString()
            : "",
          "Updated Time": bankbranch.changedTime
            ? new Date(bankbranch.changedTime).toLocaleTimeString()
            : "",
        }));
      
        console.log(dataToExport); // Log to verify
      
        const worksheet = XLSX.utils.json_to_sheet(dataToExport);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "BankBranch");
        XLSX.writeFile(workbook, "BankBranch.xlsx");
      };
      const handleReload = async () => {
        setLoading(true);  // Start loading
        try {
          await fetchBankBranch();  // Fetch Branchs
        } catch (error) {
          console.error('Error reloading Branchs:', error);
        } finally {
          setLoading(false);  // Stop loading
        }
      };
      const toggleFilterPopup = () => {
        setIsFilterPopupOpen(!isFilterPopupOpen);
      };
      const existingButtons = PrimaryActionsConfig.screen2.map((button) => ({
        ...button,
        title:
          button.icon === IoAddCircleOutline
            ? "Add Country"
            : button.icon === GiSettingsKnobs
            ? "Filter"
            : button.icon === MdOutlineFileDownload
            ? "Download"
             : button.icon === MdOutlineFileUpload
            ? "Upload"
            : button.icon === IoSettingsOutline
            ? "Settings"
             : button.icon === IoMdRefresh
      ? "Refresh"
      : button.title|| "", 
        onClick:
          button.icon === IoAddCircleOutline
            ? () => handleNavigate("/bankbranch-add")
            : button.icon === MdOutlineFileDownload 
      ? handleDownload
      : button.icon === IoSettingsOutline
      ? handleSettingsButtonClick
      : button.icon === IoMdRefresh
      ? handleReload
      : button.icon === GiSettingsKnobs
      ?toggleFilterPopup
      : button.onClick || (() => {}),
      }));
      const actionButtons = existingButtons.concat(
        extraButtons.map((button) => ({
          ...button,
          disabled: selectedRows.length === 0, 
          onClick: selectedRows.length > 0 ? button.onClick : null, 
        }))
      );
 
      
      const handleNavigate = (path) => {
        navigate(path);
      };
      const handleSelectRow = (id) => {
        handleCheckboxChange(id);
      };
      const handleSelectAll = () => {
        handleSelectAllChange();
      };
      const handleDoubleClick = (id) => {
        navigate(`/bankbranch-view/${id}`, { state: { mode: "view" } });
      };
      const handleCheckboxChange = (id) => {
        const updatedSelectedRows = selectedRows.includes(id)
          ? selectedRows.filter((rowId) => rowId !== id)
          : [...selectedRows, id];
    
        setSelectedRows(updatedSelectedRows);
        setBankBranchId(id);
        setShowExtraIcons(updatedSelectedRows.length > 0);
      };
    const handleUpdateColumnSettings = (updatedSettings) => {
        setColumnSettings(updatedSettings);
      };

   
    const handleSelectAllChange = () => {
        if (isAllSelected) {
          setSelectedRows([]);
        } else {
          const allIds = bankBranch.map((item) => item.id);
          setSelectedRows(allIds);
        }
        setIsAllSelected(!isAllSelected);
      };
      if (loading)
        return (
          <div>
          </div>
        );
        const handleFilterChange = (e) => {
          setFilters((prevFilters) => ({
            ...prevFilters,
            status: e.target.value,
          }));
        };
      
        const handleFilterDateFrom = (e) => {
          console.log(filterType);
          if (filterType === "1") {
            setFilters((prevFilters) => ({
              ...prevFilters,
              created_date_from: e.target.value,
            }));
          } else {
            setFilters((prevFilters) => ({
              ...prevFilters,
              updated_date_from: e.target.value,
            }));
          }
        };
        const handleFilterDateto = (e) => {
          if (filterType === "1") {
            setFilters((prevFilters) => ({
              ...prevFilters,
              created_date_to: e.target.value,
            }));
          } else {
            setFilters((prevFilters) => ({
              ...prevFilters,
              updated_date_to: e.target.value,
            }));
          }
        };
       
      
      
        const handleResetFilters = () => {
          setFilters({
            status: "",
            bankBranch: [],
            BankId: [],
            Bank: [],

            countryCode: [],
            updated_by: "",
            updated_date_from: "",
            updated_date_to: "",
            created_by: "",
            created_date_from: "",
            created_date_to: "",
          });
          setSelectedCountries([]);
          setSelectedbankbranchbanks([]);
          setSelectedbankbranchbankid([]);

          setSelectedbankbranches([]);
          setSelectedCreatedBy([]);
          setSelectedUpdatedBy([]);
          // ithu apply click panni than rest pannanum na intha function call theva illa
          fetchBankBranch();
        };
      
        const handlebankBranchesSelection = (bankbranch) => {
          setTempSelectedbankbranches((prev) => {
            // Add or remove company from temporary selection list
            return prev.includes(bankbranch)
              ? prev.filter((item) => item !== bankbranch) // Remove if already selected
              : [...prev, bankbranch]; // Add if not selected
          });
        };
      
        // Reset temporary selection when resetting popup
        const handlebankbranchReset = () => {
          setTempSelectedbankbranches([]); // Reset selection
        };
      
        // Apply filter and close the popup
        const handleApplybankbranch = () => {
          setSelectedbankbranches(tempSelectedbankbranches); // Apply temporary selection to actual selection state
          setFilters((prevFilters) => ({
            ...prevFilters,
            bankBranch: tempSelectedbankbranches, // Set the filter
          }));
          setIsbankBranchPopupOpen(false); // Close the popup
        };
      
        const handlebankBranchBankidSelection = (bankid) => {
          setTempSelectedbankbranchbankid((prev) => {
            // Add or remove company from temporary selection list
            return prev.includes(bankid)
              ? prev.filter((item) => item !== bankid) // Remove if already selected
              : [...prev, bankid]; // Add if not selected
          });
        };
      
        // Reset temporary selection when resetting popup
        const handlebankbranchbankidReset = () => {
          setTempSelectedbankbranchbankid([]); // Reset selection
        };
      
        // Apply filter and close the popup
        const handleApplybankbranchbankid = () => {
          setSelectedbankbranchbankid(tempSelectedbankbranchbankid); // Apply temporary selection to actual selection state
          setFilters((prevFilters) => ({
            ...prevFilters,
            BankId: tempSelectedbankbranchbankid, // Set the filter
          }));
          setIsbankBranchbankidPopupOpen(false); // Close the popup
        };
        const handlebankBranchBankSelection = (bank) => {
          setTempSelectedbankbranchbank((prev) => {
            // Add or remove company from temporary selection list
            return prev.includes(bank)
              ? prev.filter((item) => item !== bank) // Remove if already selected
              : [...prev, bank]; // Add if not selected
          });
        };
      
        // Reset temporary selection when resetting popup
        const handlebankbranchBankReset = () => {
          setTempSelectedbankbranchbank([]); // Reset selection
        };
      
        // Apply filter and close the popup
        const handleApplybankbranchbank = () => {
          setSelectedbankbranchbanks(tempSelectedbankbranchbank); // Apply temporary selection to actual selection state
          setFilters((prevFilters) => ({
            ...prevFilters,
            Bank: tempSelectedbankbranchbank, // Set the filter
          }));
          setIsbankBranchbanksPopupOpen(false); // Close the popup
        };
      
        const handleCountrySelection = (countryCode) => {
          setTempSelectedcountries((prev) => {
            // Add or remove company from temporary selection list
            return prev.includes(countryCode)
              ? prev.filter((item) => item !== countryCode) // Remove if already selected
              : [...prev, countryCode]; // Add if not selected
          });
        };
      
        // Reset temporary selection when resetting popup
        const handleCountryReset = () => {
          setTempSelectedcountries([]); // Reset selection
        };
      
        // Apply filter and close the popup
        const handleApplyCountry = () => {
          setSelectedCountries(tempSelectedcountries); // Apply temporary selection to actual selection state
          setFilters((prevFilters) => ({
            ...prevFilters,
            countryCode: tempSelectedcountries, // Set the filter
          }));
          setIsbankBranchPopupOpen(false); // Close the popup
        };
      
        const handleCreatedBySelection = (createdBy) => {
          setTempSelectedcreatedby((prev) => {
            // Add or remove company from temporary selection list
            return prev.includes(createdBy)
              ? prev.filter((item) => item !== createdBy) // Remove if already selected
              : [...prev, createdBy]; // Add if not selected
          });
        };
      
        // Reset temporary selection when resetting popup
        const handleCreatedbyReset = () => {
          setTempSelectedcreatedby([]); // Reset selection
        };
      
        // Apply filter and close the popup
        const handleApplyCreatedby = () => {
          setSelectedCreatedBy(tempSelectedcreatedby); // Apply temporary selection to actual selection state
          setFilters((prevFilters) => ({
            ...prevFilters,
            created_by: tempSelectedcreatedby, // Set the filter
          }));
          setIsCreatedByPopupOpen(false); // Close the popup
        };
        
        
      
      
        const handleUpdateBySelection = (changedby) => {
          setTempSelectedupdatedby((prev) => {
            // Add or remove company from temporary selection list
            return prev.includes(changedby)
              ? prev.filter((item) => item !== changedby) // Remove if already selected
              : [...prev, changedby]; // Add if not selected
          });
        };
      
        // Reset temporary selection when resetting popup
        const handleUpdatedbyReset = () => {
          setTempSelectedupdatedby([]); // Reset selection
        };
      
        // Apply filter and close the popup
        const handleApplyUpdatedby = () => {
          setSelectedUpdatedBy(tempSelectedupdatedby); // Apply temporary selection to actual selection state
          setFilters((prevFilters) => ({
            ...prevFilters,
            updated_by: tempSelectedupdatedby, // Set the filter
          }));
          setIsUpdatedByPopupOpen(false); // Close the popup
        };
        const handlePageChange = (page) => {
          setCurrentPage(page);
        };
  return (
    <div>
        <Header
        title={"Primary Data > Company & Bank Data > Bank Branch"}
        onBreadcrumbClick={handleBreadcrumbClick}
      />
         <div>
        <div className="flex justify-between items-center -ml-1 pl-4">
        <div className="flex items-center">

        <SearchInput
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              placeholder="Search by Bank id"
            />


          <PrimaryActions
            buttons={actionButtons}
            selectedCount={selectedRows.length}
            isExtraButtonsVisible={showExtraIcons}
          />
          </div>
          <div className="flex items-center mr-4">

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            totalCount={totalCount}
          />
          
        </div>
        </div>

        <div>
         
        </div>
        <ReTable
          data={filteredbankBranch}
          columns={modifiedColumns} // Use modified columns based on visibility
          selectedRows={selectedRows}
          onSelectRow={handleSelectRow}
          onSelectAll={handleSelectAll}
          onRowDoubleClick={handleDoubleClick}
        />
     
      {isSettingsPopupOpen && (
        <ColumnSettingsPopup
          columns={columnSettings}
          onClose={() => setIsSettingsPopupOpen(false)}
          onUpdate={handleUpdateColumnSettings}
        />
      )}
      {isFilterPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
          <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-3xl z-50">
            <div className="flex justify-between items-center mt-2">
              <h2 className="text-customGray font-semibold mb-5">
                Apply Filters
              </h2>
              <HoverButton
                onClick={handleResetFilters}
                title={"Reset Filter"}
              />
            </div>

            {/* Grid Layout */}
            <div className="grid grid-cols-2 gap-4">
              {/* Left-Side Single Column Fields */}
              <div className="col-span-2 sm:col-span-1">
                <div className="flex items-center mb-2">
                  <label className="text-customGray mr-2 w-[140px]">
                    Status:
                  </label>
                  <select
                    name="status"
                    value={filters.status}
                    onChange={handleFilterChange}
                    className="text-customGray border border-gray-300 pl-1 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400 "
                  >
                    <option value="" className=" text-blue-500">
                      Select Status
                    </option>
                    <option value="0" className=" text-gray-500">
                      Created
                    </option>
                    <option value="2" className=" text-orange-400">
                     Modified
                    </option>
                    <option value="1 " className=" text-yellow-500">
                      Change
                    </option>
                    <option value="3" className=" text-[#29CB00]">
                      Approved
                    </option>
                    <option value="4" className=" text-red-500">
                      Rejected
                    </option>
                    <option value="5" className=" text-black">
                      Inactive
                    </option>
                  </select>
                </div>
                <div className="flex items-center mb-2">
                  <label className="text-customGray mr-2 w-[140px]">
                    Bank Branch:
                  </label>
                  <button
                    className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer"
                    onClick={() => {
                      setTempSelectedbankbranches(selectedbankbranches);
                      setIsbankBranchPopupOpen(true);
                      // Reset filteredBuGroup to show all groups when the popup is opened
                    }}
                  >
                    <span className="truncate">
                      {selectedbankbranches.length > 0
                        ? selectedbankbranches.join(", ")
                        : "Bank Branch"}
                    </span>
                    <RxDropdownMenu className="ml-2 text-lg" />
                  </button>
                </div>

                {isbankBranchPopupOpen && (
                  <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
                    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-xl z-50">
                      <div className="flex justify-between items-center mb-4">
                        <h2 className="text-customGray font-semibold">
                          Select Bank Branch
                        </h2>
                        <HoverButton
                          // onClick={resetSelectedBuGroups}
                          title={"Reset"}
                          onClick={handlebankbranchReset}
                        />
                      </div>

                      {/* Search Input */}
                      <input
                        type="text"
                        className="border border-gray-300 p-2 rounded-md mb-4 w-full"
                        placeholder="Search Branch"
                        value={bankBranchesearchQuery}
                        onChange={(e) => setbankBranchesearchQuery(e.target.value)} // Filter BU Groups
                      />

                      {/* List of BU Groups */}
                      <div className="space-y-2 max-h-80 overflow-y-auto">
                        {filteredbankBranches.map((bankbranch) => (
                          <div key={bankbranch.id} className="flex items-center">
                            <input
                              type="checkbox"
                              checked={tempSelectedbankbranches.includes(
                                bankbranch.bankBranch
                              )} // Adjusted to use the correct variable
                              onChange={() =>
                                handlebankBranchesSelection(bankbranch.bankBranch)
                              }
                            />
                            <label className="ml-2">
                              {bankbranch.bankBranch}
                            </label>{" "}
                            {/* Adjusted to match `company` */}
                          </div>
                        ))}
                      </div>

                      {/* Apply Filter Button */}
                      <div className="flex justify-end mt-4">
                        <HoverButton
                          title={"Cancel"}
                          className={"mr-2"}
                          onClick={() => setIsbankBranchPopupOpen(false)}
                        />
                        <CustomButton
                          onClick={() => {
                            handleApplybankbranch();
                            setIsbankBranchPopupOpen(false);
                          }}
                          title={"Apply"}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="flex items-center mb-2">
                  <label className="text-customGray mr-2 w-[140px]">
                    Bank Id:
                  </label>
                  <button
                    className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer"
                    onClick={() => {
                      setTempSelectedbankbranchbankid(selectedbankbranchbankid);
                      setIsbankBranchbankidPopupOpen(true);
                      // Reset filteredBuGroup to show all groups when the popup is opened
                    }}
                  >
                    <span className="truncate">
                      {selectedbankbranchbankid.length > 0
                        ? selectedbankbranchbankid.join(", ")
                        : "Bank Id"}
                    </span>
                    <RxDropdownMenu className="ml-2 text-lg" />
                  </button>
                </div>

                {isbankBranchbankidPopupOpen && (
                  <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
                    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-xl z-50">
                      <div className="flex justify-between items-center mb-4">
                        <h2 className="text-customGray font-semibold">
                          Select Bank Id
                        </h2>
                        <HoverButton
                          // onClick={resetSelectedBuGroups}
                          title={"Reset"}
                          onClick={handlebankbranchbankidReset}
                        />
                      </div>

                      {/* Search Input */}
                      <input
                        type="text"
                        className="border border-gray-300 p-2 rounded-md mb-4 w-full"
                        placeholder="Search Branch"
                        value={bankBranchbankidsearchQuery}
                        onChange={(e) => setbankBranchbankidsearchQuery(e.target.value)} // Filter BU Groups
                      />

                      {/* List of BU Groups */}
                      <div className="space-y-2 max-h-80 overflow-y-auto">
                        {filteredbankBranchbankidies.map((bankid) => (
                          <div key={bankid.id} className="flex items-center">
                            <input
                              type="checkbox"
                              checked={tempSelectedbankbranchbankid.includes(
                                bankid.BankId
                              )} // Adjusted to use the correct variable
                              onChange={() =>
                                handlebankBranchBankidSelection(bankid.BankId)
                              }
                            />
                            <label className="ml-2">
                              {bankid.BankId}
                            </label>{" "}
                            {/* Adjusted to match `company` */}
                          </div>
                        ))}
                      </div>

                      {/* Apply Filter Button */}
                      <div className="flex justify-end mt-4">
                        <HoverButton
                          title={"Cancel"}
                          className={"mr-2"}
                          onClick={() => setIsbankBranchbankidPopupOpen(false)}
                        />
                        <CustomButton
                          onClick={() => {
                            handleApplybankbranchbankid();
                            setIsbankBranchbankidPopupOpen(false);
                          }}
                          title={"Apply"}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="flex items-center mb-2">
                  <label className="text-customGray mr-2 w-[140px]">
                    Bank :
                  </label>
                  <button
                    className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer"
                    onClick={() => {
                      setTempSelectedbankbranchbank(selectedbankbranchbanks);
                      setIsbankBranchbanksPopupOpen(true);
                      // Reset filteredBuGroup to show all groups when the popup is opened
                    }}
                  >
                    <span className="truncate">
                      {selectedbankbranchbanks.length > 0
                        ? selectedbankbranchbanks.join(", ")
                        : "Bank "}
                    </span>
                    <RxDropdownMenu className="ml-2 text-lg" />
                  </button>
                </div>

                {isbankBranchbanksPopupOpen && (
                  <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
                    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-xl z-50">
                      <div className="flex justify-between items-center mb-4">
                        <h2 className="text-customGray font-semibold">
                          Select Bank 
                        </h2>
                        <HoverButton
                          // onClick={resetSelectedBuGroups}
                          title={"Reset"}
                          onClick={handlebankbranchBankReset}
                        />
                      </div>

                      {/* Search Input */}
                      <input
                        type="text"
                        className="border border-gray-300 p-2 rounded-md mb-4 w-full"
                        placeholder="Search Branch"
                        value={bankBranchbankssearchQuery}
                        onChange={(e) => setbankBranchbankssearchQuery(e.target.value)} // Filter BU Groups
                      />

                      {/* List of BU Groups */}
                      <div className="space-y-2 max-h-80 overflow-y-auto">
                        {filteredbankBranchbanks.map((bank) => (
                          <div key={bank.id} className="flex items-center">
                            <input
                              type="checkbox"
                              checked={tempSelectedbankbranchbank.includes(
                                bank.Bank
                              )} // Adjusted to use the correct variable
                              onChange={() =>
                                handlebankBranchBankSelection(bank.Bank)
                              }
                            />
                            <label className="ml-2">
                              {bank.Bank}
                            </label>{" "}
                            {/* Adjusted to match `company` */}
                          </div>
                        ))}
                      </div>

                      {/* Apply Filter Button */}
                      <div className="flex justify-end mt-4">
                        <HoverButton
                          title={"Cancel"}
                          className={"mr-2"}
                          onClick={() => setIsbankBranchbanksPopupOpen(false)}
                        />
                        <CustomButton
                          onClick={() => {
                            handleApplybankbranchbank();
                            setIsbankBranchbanksPopupOpen(false);
                          }}
                          title={"Apply"}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="flex items-center mb-2">
                  <label className="text-customGray mr-2 w-[140px]">
                    Country :
                  </label>
                  <button
                    className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer"
                    onClick={() => {
                      setTempSelectedcountries(selectedCountries);
                      setIsCountryPopupOpen(true);
                      // Reset filteredBuGroup to show all groups when the popup is opened
                    }}
                  >
                    <span className="truncate">
                      {selectedCountries.length > 0
                        ? selectedCountries.join(", ")
                        : "Country"}
                    </span>
                    <RxDropdownMenu className="ml-2 text-lg" />
                  </button>
                </div>

                {isCountryPopupOpen && (
                  <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
                    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-xl z-50">
                      <div className="flex justify-between items-center mb-4">
                        <h2 className="text-customGray font-semibold">
                          Select Country
                        </h2>
                        <HoverButton
                          onClick={handleCountryReset}
                          title={"Reset"}
                        />
                      </div>

                      {/* Search Input */}
                      <input
                        type="text"
                        className="border border-gray-300 p-2 rounded-md mb-4 w-full"
                        placeholder="Search Country"
                        value={countryCodeSearchQuery}
                        onChange={(e) => setCountryCodeSearchQuery(e.target.value)} // Filter BU Groups
                      />

                      {/* List of BU Groups */}
                      <div className="space-y-2 max-h-80 overflow-y-auto">
  {filteredCountryCodes.map((code) => (
    <div key={code} className="flex items-center">
      <input
        type="checkbox"
        checked={tempSelectedcountries.includes(code)}
        onChange={() => handleCountrySelection(code)}
      />
      <label className="ml-2">{code}</label>
    </div>
  ))}
</div>


                      {/* Apply Filter Button */}
                      <div className="flex justify-end mt-4">
                        <HoverButton
                          title={"Cancel"}
                          className={"mr-2"}
                          onClick={() => setIsCountryPopupOpen(false)}
                        />
                        <CustomButton
                          onClick={() => {
                            handleApplyCountry();
                            setIsCountryPopupOpen(false);
                          }}
                          title={"Apply"}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="flex items-center">
                  {/* Dropdown for selecting 'Created By' or 'Updated By' */}
                  <select
                    name="filterType"
                    value={filterType} // State to track the selected filter option
                    onChange={(e) => {
                      setFilterType(e.target.value); // Update the selected filter
                    }}
                    className="text-customGray border border-gray-300 p-2 rounded-md w-[132px] h-[40px] focus:outline-none hover:border-blue-400"
                  >
                    <option value="1">Created By</option>
                    <option value="2">Updated By</option>
                  </select>

                  {/* Button to open the popup */}
                  <button
                    className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer ml-4"
                    onClick={() => {
                      if (filterType === "1") {
                        setIsCreatedByPopupOpen(true); 
                        setTempSelectedcreatedby(selectedCreatedBy);// Open the Created By popup
                      } else if (filterType === "2") {
                        setIsUpdatedByPopupOpen(true); // Open the Updated By popup
                        setTempSelectedupdatedby(selectedUpdatedBy); // Set the selected data correctly before opening the popup
                      }
                     
                    }}
                    disabled={!filterType} // Disable button if no filter is selected
                  >
                    <span className="truncate">
                      {filterType === "1"
                        ? selectedCreatedBy.length > 0
                          ? selectedCreatedBy.join(", ")
                          : "Created By"
                        : filterType === "2"
                        ? selectedUpdatedBy.length > 0
                          ? selectedUpdatedBy.join(", ")
                          : "Updated By"
                        : "Select Filter"}
                    </span>
                    <RxDropdownMenu className="ml-2 text-lg" />
                  </button>
                </div>
                {isCreatedByPopupOpen && (
                  <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
                    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-xl z-50">
                      <div className="flex justify-between items-center mb-4">
                        <h2 className="text-customGray font-semibold">
                          Select Created By
                        </h2>
                        <HoverButton
                           onClick={handleCreatedbyReset}
                          title={"Reset"}
                        />
                      </div>
                      {/* Search and Filter */}
                      <input
                        type="text"
                        className="border border-gray-300 p-2 rounded-md mb-4 w-full"
                        placeholder="Search Created By"
                        value={createdbydatasearchQuery}
                        onChange={(e) => setcreatedbydatasearchQuery(e.target.value)}
                      />
                      <div className="space-y-2 max-h-80 overflow-y-auto">
                        {filteredcreatedbydatas.map((created) => (
                          <div key={created.id} className="flex items-center">
                            <input
                              type="checkbox"
                              checked={tempSelectedcreatedby.includes(
                                created.created_by
                              )}
                              onChange={() =>
                                handleCreatedBySelection(created.created_by)
                              }
                            />
                            <label className="ml-2">{created.created_by}</label>
                          </div>
                        ))}
                      </div>

                      {/* Actions */}
                      <div className="flex justify-end mt-4">
                        <HoverButton
                          title={"Cancel"}
                          className={"mr-2"}
                          onClick={() => setIsCreatedByPopupOpen(false)}
                        />
                        <CustomButton
                          onClick={() => {
                            setIsCreatedByPopupOpen(false);
                             handleApplyCreatedby()  ;                          // applyCreatedByFilter(); // Apply the selected filter
                          }}
                          title={"Apply"}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {/* Updated By Popup */}
                {isUpdatedByPopupOpen && (
                  <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
                    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-xl z-50">
                      <div className="flex justify-between items-center mb-4">
                        <h2 className="text-customGray font-semibold">
                          Select Updated By
                        </h2>
                        <HoverButton
                           onClick={handleUpdatedbyReset}
                          title={"Reset"}
                        />
                      </div>
                      {/* Search and Filter */}
                      <input
                        type="text"
                        className="border border-gray-300 p-2 rounded-md mb-4 w-full"
                        placeholder="Search Updated By"
                        value={updatedbysearchquery}
                        onChange={(e) => setupdatedbysearchquery(e.target.value)}
                      />
                      <div className="space-y-2 max-h-80 overflow-y-auto">
                        {filteredupdatedbydata.map((updated) => (
                          <div key={updated.id} className="flex items-center">
                            <input
                              type="checkbox"
                              checked={tempSelectedupdatedby.includes(updated.changed_by)} // Ensure it's checking the correct temporary selection state
                              onChange={() => handleUpdateBySelection(updated.changed_by)}
                            />
                            <label className="ml-2">{updated.changed_by}</label>
                          </div>
                        ))}
                      </div>
                      {/* Actions */}
                      <div className="flex justify-end mt-4">
                        <HoverButton
                          title={"Cancel"}
                          className={"mr-2"}
                          onClick={() => setIsUpdatedByPopupOpen(false)}
                        />
                        <CustomButton
                          onClick={() => {
                            handleApplyUpdatedby();
                            setIsUpdatedByPopupOpen(false);
                            // applyUpdatedByFilter(); // Apply the selected filter
                          }}
                          title={"Apply"}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {filterType && (
                <div className="col-span-2 flex justify-between items-center">
                  <div className="flex items-center">
                    <label className="text-customGray mr-2 w-[140px]">
                      {filterType === "createdBy"
                        ? " Date From:"
                        : " Date From:"}
                    </label>
                    <input
                      type="date"
                      name="dateFrom"
                      value={
                        filterType === "1"
                          ? filters.created_date_from
                          : filters.updated_date_from
                      }
                      onChange={(e) => handleFilterDateFrom(e)}
                      className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400"
                    />
                  </div>
                  <div className="flex items-center">
                    <label className="text-customGray mr-2 w-[140px]">
                      {filterType === "createdBy" ? " Date To:" : " Date To:"}
                    </label>
                    <input
                      type="date"
                      name="dateTo"
                      value={
                        filterType === "1"
                          ? filters.created_date_to
                          : filters.updated_date_to
                      }
                      onChange={(e) => handleFilterDateto(e)}
                      className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400"
                    />
                  </div>
                </div>
              )}
            </div>

            {/* Buttons */}
            <div className="mt-6 flex justify-end space-x-3">
              <HoverButton
                onClick={toggleFilterPopup}
                title={"Cancel"}
              />
              <CustomButton
                title={"Apply"}
                onClick={() => {
      handleApplyFilter(); 
      toggleFilterPopup(); 
    }}
              
              />
            </div>
          </div>
        </div>
      )}
      <ConfirmationDialog
        open={showConfirmation}
        title="Confirm Deletion"
        message="Are you sure you want to delete the selected Bank Branch?"
        onConfirm={confirmDeletion}
        onCancel={cancelDeletion}
      />
    </div>
    </div>
  )
}
