import React from "react";

const FilterDropdown = ({ name, value, onChange, options, placeholder, className }) => {
  return (
    <div className="relative">
      <select
        name={name}
        value={value}
        onChange={onChange}
        className={`text-customGray border border-gray-300 pl-1 rounded-md w-[165px] h-[40px] focus:outline-none ${className}`}
      >
        {/* Placeholder Option */}
        <option value="" disabled>
          {placeholder || "Select an option"}
        </option>

        {/* Dynamically Render Options */}
        {options.map((option) => (
          <option key={option.value} value={option.value} className={option.className}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default FilterDropdown;
