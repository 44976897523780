import React, { useState } from 'react';

export default function FilterComponent({ onFilter, onReset }) {
  const [filters, setFilters] = useState({
    createdTime: '',
    changedBy: '',
    changeDate: '',
    changeTime: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleReset = () => {
    setFilters({
      createdTime: '',
      changedBy: '',
      changeDate: '',
      changeTime: '',
    });
    onReset();
  };

  const handleFilter = () => {
    onFilter(filters);
  };

  return (
    <div className="bg-gray-100 p-4 rounded-md shadow-md mb-2">
      <div className="grid grid-cols-4 gap-4 mb-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Created time</label>
          <input
            type="date"
            name="createdTime"
            value={filters.createdTime}
            onChange={handleInputChange}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Changed by</label>
          <input
            type="text"
            name="changedBy"
            value={filters.changedBy}
            onChange={handleInputChange}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Change date</label>
          <input
            type="date"
            name="changeDate"
            value={filters.changeDate}
            onChange={handleInputChange}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Change time</label>
          <input
            type="time"
            name="changeTime"
            value={filters.changeTime}
            onChange={handleInputChange}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
          />
        </div>
      </div>

      <div className="flex justify-end space-x-2">
        <button
          onClick={handleReset}
          className="bg-gray-200 text-customBlue border border-customBlue px-4 py-2 rounded-md hover:bg-gray-300"
        >
          Reset
        </button>
        <button
          onClick={handleFilter}
          className="bg-customBlue text-white px-4 py-2 rounded-md hover:bg-blue-600"
        >
          Filter
        </button>
      </div>
    </div>
  );
}
